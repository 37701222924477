import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default class IndustriesCorporateComms extends React.Component<any, any> {
  render() {
    const { allBrandsJson } = this.props.data;

    const brands = allBrandsJson.edges;

    return (
      <Layout location={this.props.location}>
        <main id="main" className="marketing-content container-fluid">
          <Helmet
            bodyAttributes={{
              class: "body-shorthand body-marketing body-marketing-secondary body-marketing-features body-light hide-elevio",
            }}
          ></Helmet>
          <div className="row section-title title-light">
            <div className="col-11 col-sm-8  mx-auto section-inner">
              <h1>
                <span className="shorthand-logo dark" aria-hidden="true">
                  Shorthand
                </span>
                Corporate Communications
              </h1>
              <h2>Transform your corporate communications with immersive digital stories.</h2>
            </div>
          </div>
          <section className="row section-brands section-border-top">
            <div className="brands-list">
              <ul>
                <li>
                  <img src="/media/logos/clients/lg-relx.png" loading="lazy" width="348" height="241" alt="RELX logo" />
                </li>
                <li>
                  <img src="/media/logos/clients/lg-cinven.png" loading="lazy" width="348" height="241" alt="Cinven logo" />
                </li>
                <li>
                  <img src="/media/logos/clients/lg-sky-group.png" loading="lazy" width="348" height="241" alt="Sky Group logo" />
                </li>
              </ul>
            </div>
          </section>

          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Beautiful internal comms</h3>
                  <p>
                    Engage your staff, shareholders, and board with internal communications as beautiful as the best digital stories on
                    the web.
                  </p>
                  <ul className="features-list">
                    <li>
                      Shorthand is built for communications professionals. Get started immediately &mdash; no developers required.
                    </li>

                    <li>Add rich imagery, video, and no-code animations with our built in story sections.</li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/industries/culture-right-grey.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-right">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Secure single sign-on</h3>
                  <p>Publish beautiful internal communications behind your corporate authentication system.</p>
                  <ul className="features-list">
                    <li>
                      Shorthand supports a growing range of services for SSO, including Google, Okta, Auth0, Microsoft Azure Active
                      Directory, Centrify, and GitHub.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/industries/sign-on-white.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Measure engagement</h3>
                  <p>
                    Use Shorthand’s built-in analytics engine, or plug in your own third-party tool to track and measure employee
                    engagement with your stories.
                  </p>
                  <ul className="features-list">
                    <li>
                      With Shorthand Analytics, track page views, time-on-page, and story completion in a human-friendly interface.
                    </li>
                    <li>
                      Optionally add code for your third-party analytics platform &mdash; such as Google Analytics &mdash; to measure
                      Shorthand alongside your other CMS content.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/industries/analytics-ipad-grey.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-right">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>A secure solution</h3>
                  <p>
                    Trusted by some of the world’s largest companies, Shorthand has proven data security and privacy policies and
                    procedures.
                  </p>
                  <a className="btn btn-secondary" href="/features/security/">
                    Learn more about security
                  </a>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/industries/padlock-left-white.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section  section-strip section-strip-quote ">
            <div className="container container-sm">
              <div className="section-strip-col-single section-strip-single-quote">
                <div className="section-strip-quote-inner">
                  <blockquote>
                    &ldquo;The stories we’re publishing now look simply beautiful. And now we're getting as many as 15 times the number
                    of reads that we used to get. There's a general genuine buzz in the team about what we’re publishing. Shorthand has
                    been transformational.&rdquo;
                  </blockquote>

                  <cite>Paul Abrahams | Head of Corporate Communications | RELX</cite>
                </div>
              </div>
            </div>
          </section>

          <section className="row section  section-strip-stories section-border-top">
            <div className="container flex-column section-inner text-center">
              <h3>Take a look at these great stories, built with Shorthand</h3>
              <div className="featured-stories">
                <ul>
                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://stories.relx.com/relx-environmental-challenge/index.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/relx-environment-desktop.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        Environmental Challenge
                        <span className="featured-story-brand">RELX</span>
                      </h4>
                    </a>
                  </li>

                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://www.tripadvisor.com/Covid19WhitepaperMay2020"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/tripadvisor-covid.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        Beyond COVID-19
                        <span className="featured-story-brand">Tripadvisor</span>
                      </h4>
                    </a>
                  </li>

                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://weareorbis.shorthandstories.com/Equal/index.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/orbis-weare.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        We are Orbis
                        <span className="featured-story-brand">Orbis</span>
                      </h4>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="row section section-dark section-half section-trial !tw-bg-[rgba(249,249,249,0.3)]">
            <div className="container section-inner text-center">
              <h2 className="!tw-text-black">
                Ready to try the digital storytelling platform used by Honda, University of Cambridge, and the BBC?
              </h2>

              <div className="cta-container">
                <a href="https://app.shorthand.com/signup" className="btn btn-lg !tw-bg-black hover:!tw-opacity-70">
                  Start creating
                </a>
              </div>
              <div className="brands">
                <div className="brands-container">
                  <h3 className="as-used">Join these iconic brands</h3>
                  <ul>
                    {brands.map((brand, index) => (
                      <li key={`brand-${brand.node.label}-${index}`} className={`brand`}>
                        <img src={brand.node.logo} alt={`${brand.node.label} logo`} />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div aria-hidden="true" className="background-media background-media-fixed">
              <img alt="" src="/media/home/stories.jpg" className="ObjectFit--cover" />
            </div>
          </section>

          <Footer className="mt-0" />
        </main>
      </Layout>
    );
  }
}

export const query = graphql`
  query IndustriesCorporateCommsQuery {
    site {
      siteMetadata {
        name
        title
        tagline
        description
      }
    }
    allBrandsJson {
      edges {
        node {
          logo
          name
          label
        }
      }
    }
  }
`;
