import { filterListByCurrentIndustryCode } from "../../Utils";
import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { RelatedCaseStudies } from "../../components/RelatedCaseStudies";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default function CaseStudy({ data, location }) {
  return (
    <Layout location={location}>
      <main id="main" className="marketing-content container-fluid">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-secondary body-marketing-case-studies hide-elevio",
          }}
        />
        <div className="row section-title">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto  section-inner">
            <h1>
              <span className="shorthand-logo">Shorthand</span>
              <span className="subsection-label">Case Study:</span>
              Arizent
            </h1>
            <h2>Arizent are using Shorthand to create engaging editorial for their flagship digital business and media brands</h2>
          </div>
          <div aria-hidden="true" className="background-media">
            <img
              src="/media/case-studies/case-studies-arizent-title.jpg"
              data-object-fit="cover"
              data-autoscale-content-ratio="1.77777777778"
              alt=""
            />
          </div>
        </div>
        <div className="row section">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto marketing-main-column">
            <h3 className="intro">
              The B2B digital media and marketing services company has been using Shorthand since September 2017 to increase speed to
              market, audience engagement - and even win awards.
            </h3>
            <div className="inline-image featured">
              <img
                src="/media/case-studies/case-studies-arizent.jpg"
                alt="Pay Day, by Arizent, renders responsively across all devices"
              />
            </div>
            {/* <figure className="block quote-block offset right">
            <blockquote>
              <p>
                "Shorthand allows our designers to create immersive online stories with a much shorter turnaround time and with the
                flexibility of pushing the creative boundaries outside of our CMS."
              </p>
            </blockquote>
            <figcaption className="quote-cite">
              Michael Chu &ndash; Executive director for content operations & creative services, Arizent
            </figcaption>
          </figure> */}
            <p>
              “The Content team had been looking for an efficient and effective immersive solution for publishing complex and important
              feature-length work,” said Michael Chu, executive director for content operations and creative services at Arizent.
            </p>

            <p>
              “We immediately saw the potential of utilizing Shorthand to allow us to design and publish our integrated media content
              with more speed and flexibility, and to expand the range of our users’ engagement.”
            </p>

            <p>
              “Shorthand allows our designers to create immersive online stories with a much shorter turnaround time and with the
              flexibility of pushing the creative boundaries outside of our CMS,” explained Michael.
            </p>

            <div className="photo-grid flex featured">
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-arizent-sml-01.jpg"
                alt="A section from one of Arizent's stories"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-arizent-sml-02.jpg"
                alt="A section from one of Arizent's stories"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-arizent-sml-04.jpg"
                alt="A section from one of Arizent's stories"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-arizent-sml-03.jpg"
                alt="A section from one of Arizent's stories"
              />
            </div>
            <div className="caption">A selection of sections from Arizent's stories</div>

            <h3 className="subheading">Ever-evolving stories and uses</h3>

            <p>
              All of Arizent’s art directors are proficient in using the tool and, as time has gone on, the studio has been tapped for
              more and more Shorthand stories. And not just for editorial content, but also for business development pitches and
              internal presentations.
            </p>

            <p>
              Their Shorthand stories are continuously growing in terms of complexity and creativity. The tool enables them to easily
              add custom features, such as dynamic RSS feeds, animated data visualizations, CTAs and ads - all paced to suit the flow
              of content.
            </p>
            <figure className="block quote-block offset left">
              <blockquote>
                <p>
                  "Shorthand allows our editors and art directors the flexibility to dream up, execute and design beautiful multimedia
                  stories and projects – data-driven features, complex investigations and analyses – without relying on developers,"
                </p>
              </blockquote>
              <figcaption className="quote-cite">Michael Chu</figcaption>
            </figure>
            <p>
              “Shorthand allows our editors and art directors the flexibility to dream up, execute and design beautiful multimedia
              stories and projects – data-driven features, complex investigations and analyses – without relying on developers,”
              Michael continued.
            </p>
            <p>
              “The editors are excited; they see that they have a real platform for storytelling. Projects they’ve invested significant
              time in, where they have assets in mixed formats and media, can now be fully realized in an integrated experience.”
            </p>
            <p>
              One story the team are particularly proud of is{" "}
              <a
                href="https://www.financial-planning.com/news/pay-day-breakdown-of-financial-advisor-salaries-and-perks"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                Pay Day
              </a>
              . a piece for Financial Planning sponsored by Commonwealth Financial Network. Not only did this story get high user
              engagement, it was also recognised with the 2018 GDUSA American Web Design Award.
            </p>

            <p>
              Other Arizent Shorthand features have also won praise, including finalist spots for Financial Planning and American
              Banker in the Folio: Eddie and Ozzie Awards.
            </p>
            <p>
              Arizent use both Google Analytics and&nbsp;
              <a href="https://parse.ly/" target="_blank" rel="noopener noreferrer" className="btn-link">
                Parse.ly
              </a>
              &nbsp;to track the performance of their content and have seen a significant increase in pageviews and active engagement
              time.
            </p>
            <p>
              “Shorthand stories, when promoted effectively, drive deeper engagement – particularly time on page – than equivalent CMS
              article pages,” Michael added.
            </p>
            <div className="related-stories">
              <h4>Read more from Arizent:</h4>
              <ul>
                <li>
                  <a
                    href="https://www.financial-planning.com/news/lpls-largest-termination-ever"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-link"
                  >
                    From sealed transcripts, the inside saga of LPL Financial’s largest termination ever
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.americanbanker.com/news/why-chinas-mobile-payments-revolution-matters-for-us-bankers"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-link"
                  >
                    Lessons learned from a mobile payments revolution
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.americanbanker.com/news/the-crisis-isnt-over"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-link"
                  >
                    The crisis isn’t over
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.financial-planning.com/news/keep-quiet"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-link"
                  >
                    Keep quiet
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <RelatedCaseStudies studies={filterListByCurrentIndustryCode(data, "arizent")} />
        <Footer />
      </main>
    </Layout>
  );
}

export const query = graphql`
  query ArizentQuery {
    allCaseStudiesJson {
      edges {
        node {
          jsonId
          image
          industry
          industryCode
          organisation
          summary
        }
      }
    }
  }
`;
