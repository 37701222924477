import * as React from "react";

interface SectionHeadingProps {
  children: React.ReactNode;
}

export const SectionHeading = ({ children }: SectionHeadingProps): JSX.Element => {
  return (
    <h2 className="!tw-pb-1 !tw-font-CircularXXSub !tw-text-[36px] !tw-font-medium tw-text-grey_800 max-sm:!tw-text-[24px]">
      {children}
    </h2>
  );
};
