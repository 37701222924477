import { filterListByCurrentIndustryCode } from "../../Utils";
import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { RelatedCaseStudies } from "../../components/RelatedCaseStudies";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default function CaseStudy({ data, location }) {
  return (
    <Layout location={location}>
      <div id="main" className="marketing-content container-fluid">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-secondary body-marketing-case-studies hide-elevio",
          }}
        />
        <div className="row section-title">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6 mx-auto section-inner">
            <h1>
              <span className="shorthand-logo">Shorthand</span>
              <span className="subsection-label">Case Study:</span>
              BCM Group
            </h1>
            <h2>How BCM Group uses Shorthand</h2>
          </div>
          <div aria-hidden="true" className="background-media">
            <img
              src="/media/case-studies/case-studies-bcm-title.jpg"
              data-object-fit="cover"
              data-autoscale-content-ratio="1.77777777778"
              alt=""
            />
          </div>
        </div>
        <div className="row section">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto marketing-main-column">
            <h2 className="intro">
              How does a leading content agency experiment with design, meet tight deadlines, and still amaze their clients?
            </h2>
            <div className="inline-image featured">
              <img
                alt="Think STEM, by the Queensland Univeristy of Technology renders responsively across all devices"
                src="/media/case-studies/case-studies-bcm.jpg"
              />
            </div>
            <p>
              We asked this question of Pete Newsome, Tech Lead and Digital Producer, and Majella Edwards, Experience Design Lead, at{" "}
              <a href="https://www.bcm.com.au/" target="_blank" rel="noopener noreferrer">
                BCM Group
              </a>
              .
            </p>
            <p>
              The BCM Group is a fully integrated agency, with digital, media, PR and creative specialists. They are partnering with
              Shorthand to create immersive visual stories to delight their clients and grow their agency business.
            </p>
            <p>
              Pete explains that the juggle of deadlines, budgets, and client expectations is never easy. Before using Shorthand, they
              faced the same challenge as many agencies — that of building and maintaining bespoke content experiences.
            </p>
            <p>
              “Not every client has the budget for us to build those sorts of experiences from scratch,” Pete says. “But they still
              want to deliver an exceptional experience that looks really, really impressive.”
            </p>
            <p>
              Using Shorthand, the BCM Group have delivered world-beating reading experiences to their clients, without writing the
              custom code that would need to be maintained into the future.
            </p>
            <h3 className="subheading">A platform for experimentation</h3>
            <figure className="block quote-block offset right">
              <blockquote>
                <p>“The features that Shorthand has built make it really easy to create great work.”</p>
              </blockquote>
              <figcaption className="quote-cite">Pete Newsome</figcaption>
            </figure>
            <p>
              For Majella, one of the most impressive aspects of Shorthand was its ease of use. As she explains, this simplicity
              allowed the team at BCM Group to deliver client projects more quickly. But it also — crucially — allowed for greater
              experimentation during the creative process.
            </p>
            <p>
              “I like the ‘drag-and-drop’ nature of Shorthand. You can do a lot of experimentation within the platform, without needing
              to write code or start from scratch each time. Our projects typically have plenty of photos and a lot of copy to present.
              Most of the time, we decide on the best layout through trial and error.
            </p>
            <p>
              “This is the great thing about Shorthand, which allows us to really play with our assets and get the best from what they
              have to offer.”
            </p>
            <p>
              Pete agrees, saying that this level of experimentation and testing is a core tenet of BCM’s approach to visual
              storytelling.
            </p>
            <p>
              “The digital team at BCM approach projects with a somewhat experimental outlook. While the business has been around for
              over 40 years, our team bring a startup mindset, where we don’t mind trying a few different approaches to see what works.
              And Shorthand really allows us to do that.”
            </p>
            <p>
              Pete explains that part of the benefit of Shorthand is that it offers a shortcut for the design and development process.
            </p>
            <p>
              “The features that Shorthand has built make it really easy to create great work. A lot of the functionality Shorthand
              provides out-of-the-box would otherwise require custom coding, which would make experimentation almost impossible.”
            </p>
            <h3 className="subheading">A true partnership</h3>
            <figure className="block quote-block offset left">
              <blockquote>
                <p>
                  “The Shorthand team made the whole process completely seamless and really, really easy. We actually felt like we were
                  a part of something.”
                </p>
              </blockquote>
              <figcaption className="quote-cite">Pete Newsome</figcaption>
            </figure>
            <p>
              According to Pete, the partnership with Shorthand was never just about Shorthand’s technology. He tells the story of
              their work with Inland Rail, a 1700km freight rail network that will connect Melbourne and Brisbane via regional
              Victoria, New South Wales, and Queensland.
            </p>
            <p>
              “We had to figure out how to tell an amazing story in a very short timeframe, with a limited budget — as quickly as
              possible.”
            </p>
            <p>
              As expected, Shorthand helped the BCM Group team deliver to their client quickly, and on budget. But his team was looking
              for more than just a platform — they were looking for a real partnership.
            </p>
            <p>
              “That was the challenge. We knew we could build something completely from scratch. But that would take time, and that
              would be expensive. So we needed a platform that could be flexible enough to work with us and alongside us to help us
              through that journey.
            </p>
            <p>
              “Shorthand ticked every one of our boxes. The Shorthand team made the whole process completely seamless and really,
              really easy. We actually felt like we were a part of something — and not just purchasing software. I felt like we were
              actually exploring how we could collectively work on this together.”
            </p>
            <div className="photo-grid flex featured">
              <img
                alt="A section from one of BCM Group's stories"
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-bcm-sml-01.jpg"
              />
              <img
                alt="A section from one of BCM Group's stories"
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-bcm-sml-04.jpg"
              />
              <img
                alt="A section from one of BCM Group's stories"
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-bcm-sml-03.jpg"
              />
              <img
                alt="A section from one of BCM Group's stories"
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-bcm-sml-02.jpg"
              />
            </div>
            <div className="caption">A selection of sections from BCM Group's stories</div>
            <h3 className="subheading">“Wow, that’s really cool”</h3>
            <figure className="block quote-block offset right">
              <blockquote>
                <p>“It’s a platform that helps us deliver projects very, very quickly.”</p>
              </blockquote>
              <figcaption className="quote-cite">Majella Edwards</figcaption>
            </figure>

            <p>
              What have the results been so far? According to Majella, their client stories have exceeded expectations. “The Shorthand
              stories we’ve published so far have excellent dwell time and great engagement,” Majella says.
            </p>
            <p>
              “Beyond the public engagement, we’re also seeing Shorthand help streamline our internal processing. It’s a platform that
              helps us deliver projects very, very quickly.”
            </p>
            <p>
              Pete adds that, above and beyond the quantitative metrics, he has been struck by the anecdotal responses he’s seen to
              their Shorthand stories. These qualitative insights have spoken to the depth of engagement the team at BCM are seeing
              with their client stories.
            </p>
            <p>
              “When people see Shorthand stories that we’ve built, they typically respond by saying, ‘wow, that's really, really cool.’
              That speaks to the impact that Shorthand has had for us. It's just that excitement from our audience.”
            </p>
            <h3 className="subheading">An exciting opportunity</h3>
            <p>
              The BCM Group has been focusing so far on what Majella calls “people stories” — multimedia client stories told through
              video, photography, and quotes from an individual person.
            </p>
            <p>
              The BCM team is particularly proud of the{" "}
              <a
                className="btn-link"
                href="https://bcmdigital.com.au/indigenous-recruitment-campaign-2020/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Indiginous Recruitment Campaign
              </a>
              , a story they produced for QUT.
            </p>
            <p>
              Majella explains that the story came together “really, really beautifully. We were able to use a lot of different
              sections within the platform itself to showcase different visual assets. I think it came together really nicely.
              Traditionally, long stories can display like a wall of text — but using Shorthand, we were able to make something
              visually exciting with some interactive elements.”
            </p>
            <p>
              Other impressive stories include{" "}
              <a
                className="btn-link"
                href="https://bcmdigital.com.au/think-stem-think-qut-campaign-2020/index.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Think STEM Think QUT
              </a>{" "}
              and the{" "}
              <a
                className="btn-link"
                href="https://bcmdigital.com.au/your-passport-to-queensland-app/index.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Your Passport to Queensland App
              </a>
              .
            </p>
            <p>
              From this use case, the team are looking at other uses for Shorthand, including brand work and supplementary content for
              their pitch-decks.
            </p>
            <p>
              Pete is excited to continue to grow their partnership with Shorthand. “It’s been great to work with a team that's
              flexible and willing to work with us around some of our requirements. It’s really refreshing and it's been a great
              journey engaging with you guys. It does feel really collaborative for us, and not a typical software vendor
              relationship.”
            </p>
            <p>
              Majella is looking forward to using Shorthand to delight even more clients, saying they anticipate becoming a ‘super
              user.’ Creatively she is looking forward to pushing the envelope a little further and seeing where they can take visual
              storytelling in the future.
            </p>
            <p>
              Pete agrees. “It’s a really great partnership we’ve built. I’ve really loved the collaborative approach and your support
              team has been fantastic. We want to continue to evolve and grow with Shorthand. It’s a really, really exciting
              opportunity.”
            </p>
          </div>
        </div>
        <RelatedCaseStudies studies={filterListByCurrentIndustryCode(data, "bcm-group")} />
        <Footer />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query BcmGroupQuery {
    allCaseStudiesJson {
      edges {
        node {
          jsonId
          image
          industry
          industryCode
          organisation
          summary
        }
      }
    }
  }
`;
