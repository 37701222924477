export { Christies } from "./Christies";
export { Bbc } from "./BBC";
export { Nature } from "./Nature";
export { Honda } from "./Honda";
export { Penguin } from "./Penguin";
export { SaveTheChildren } from "./SaveTheChildren";
export { DowJones } from "./DowJones";
export { TripAdvisor } from "./TripAdvisor";
export { ManchesterCity } from "./ManchesterCity";
export { Greepeace } from "./Greepeace";
export { EnglishHeritage } from "./EnglishHeritage";
export { Unicef } from "./Unicef";
export { OxfordUni } from "./OxfordUni";
export { Nikkei } from "./Nikkei";

export type SVGLogoProps = {
  className?: string;
};
