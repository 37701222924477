import { IPlan, IPriceStructure } from "./";

// 16/OCT/23 Copied from dylan and is not the single source of truth

export const business: IPlan = {
  id: "business",
  name: "Business",
  description:
    "Per active user. Minimum of one active user. Includes 5 published stories. Additional published stories: $25/month for the next 50 stories then $5/month.",
  chargebee: new Set([
    "business-monthly-aud",
    "business-monthly-usd",
    "business-monthly-eur",
    "business-monthly-gbp",
    "business-annual-aud",
    "business-annual-usd",
    "business-annual-eur",
    "business-annual-gbp",
  ]),
  features: {
    "organisation-manage-publish-configurations": true,
    "organisation-team-leader-view": true,
    "organisation-team-limit": 5,
    "organisation-member-limit": 200,
    "organisation-team-member-limit": 200,
    "organisation-sites": true,
    "page-settings-amp-enabled": false,
    "page-settings-analytics-ga": true,
    "page-settings-override-social-cover-url": true,
    "shorthand-analytics-tracking": true,
    "story-credits-required": false,
    "story-ga": true,
    "themes-demo": false,
    "publish-embed-ui": true,
    "story-comments": true,
    "theme-builder": true,
    "page-settings-password-protect-previews": true,
    "custom-hosting-limit": 3,
    "publish-configurations-download": true,
    "page-settings-analytics-gtm": true,
    "developer-tools": true,
    "page-settings-custom-head": true,
    "shorthand-secure-hosting-enabled": true,
  },
  marketing: {
    label: "Creative control with unlimited publishing option",
    className: "plan-business",
    benefits: [
      "Volume publishing discounts",
      "Self-hosted publishing options",
      "Secure publishing destinations",
      "Advanced integrations & 3rd party analytics",
      "Workspace analytics exports",
      "Custom HTML & developer tools",
      "Bespoke custom branded theme",
      "Team onboarding",
      "Story reviews",
    ],
    prices: {
      planPrice: {
        monthly: { USD: 750, AUD: 980, GBP: 600, EUR: 680 },
        yearly: { USD: 7500, AUD: 9800, GBP: 6000, EUR: 6800 },
      } /** YEARLY DISCOUNTED FIGURES ARE ASSUMED FROM MONTHLY FIGURES*/,
      userAddonPrice: {
        monthly: [{ quantity: 11, price: { USD: 95, AUD: 125, GBP: 75, EUR: 85 } }],
        yearly: [{ quantity: 11, price: { USD: 950, AUD: 1250, GBP: 750, EUR: 850 } }],
      },
      publishAddonPrice: {
        monthly: [
          { quantity: 11, price: { USD: 75, AUD: 98, GBP: 60, EUR: 68 } },
          { quantity: 11, price: { USD: 50, AUD: 65, GBP: 40, EUR: 45 } },
          { quantity: 21, price: { USD: 5, AUD: 7, GBP: 4, EUR: 5 } },
        ],
        yearly: [
          { quantity: 11, price: { USD: 750, AUD: 980, GBP: 600, EUR: 680 } },
          { quantity: 11, price: { USD: 500, AUD: 650, GBP: 400, EUR: 450 } },
          { quantity: 21, price: { USD: 50, AUD: 70, GBP: 40, EUR: 50 } },
        ],
      },
    } as IPriceStructure,
  },
};
