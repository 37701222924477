import { GatsbyImage, IGatsbyImageData, StaticImage, getImage } from "gatsby-plugin-image";
import * as React from "react";

export default function ImageText({
  title = "",
  content = "",
  img,
  video,
  firstContentType = "image",
  mediaType = "image",
}: {
  title: string;
  content: string;
  img?: { url: IGatsbyImageData; alt: string };
  video?: any;
  firstContentType?: "image" | "text";
  mediaType?: "image" | "video";
}) {
  return (
    <>
      {firstContentType === "image" && (
        <div
          className=" tw-grid tw-grid-cols-[minmax(0,55fr)_minmax(0,45fr)] tw-items-center tw-gap-x-[10%]
      max-lg:tw-grid-cols-1 max-lg:tw-gap-y-10 max-lg:tw-text-center
        "
        >
          <div
            className="max-lg:tw-order-1"
            data-sal="slide-up"
            data-sal-duration="500"
            data-sal-delay="100"
            data-sal-easing="ease-out"
          >
            {img && <Media mediaType={mediaType} mediaSource={img} />}
            {video && <Media mediaType={mediaType} mediaSource={video} />}
          </div>
          <div className="">
            <h2 className="tw-h2 tw-mb-6 ">{title}</h2>
            <div className="tw-rte " dangerouslySetInnerHTML={{ __html: content }}></div>
          </div>
        </div>
      )}
      {firstContentType === "text" && (
        <div
          className="tw-grid tw-grid-cols-[minmax(0,45fr)_minmax(0,55fr)] tw-items-center tw-gap-x-[10%]
       max-lg:tw-grid-cols-1 max-lg:tw-gap-y-10 max-lg:tw-text-center
         "
        >
          <div className="">
            <h2 className="tw-h2 tw-mb-6 ">{title}</h2>
            <div className="tw-rte" dangerouslySetInnerHTML={{ __html: content }}></div>
          </div>
          <div
            className="max-lg:tw-order-1"
            data-sal="slide-up"
            data-sal-duration="500"
            data-sal-delay="100"
            data-sal-easing="ease-out"
          >
            {img && <Media mediaType={mediaType} mediaSource={img} />}
            {video && <Media mediaType={mediaType} mediaSource={video} />}
          </div>
        </div>
      )}
    </>
  );
}

const Media = ({ mediaType, mediaSource }) => {
  return (
    <>
      {mediaType === "image" && <Image img={mediaSource} />}
      {mediaType === "video" && <Video videoSource={mediaSource} />}
    </>
  );
};

const Image = ({ img }) => {
  return (
    <div>
      {img && <GatsbyImage image={getImage(img.url)} alt={img.alt} className="tw-w-full max-lg:tw-w-[70%] max-md:tw-w-[80%] " />}
    </div>
  );
};

const Video = ({ videoSource }) => {
  if (videoSource.frame === "pad") {
    return (
      <div
        className="tw-relative tw-h-[380px] tw-rounded-lg tw-border-[10px] tw-border-black max-lg:tw-mx-auto
      max-lg:tw-w-[65%]
      max-md:tw-h-[38vw]
      max-sm:tw-h-[50vw]
      max-sm:tw-w-[80%] max-sm:tw-border-[6px]
      "
      >
        <video
          id="video-top-ls"
          className="tw-mb-[-1px] tw-h-full tw-w-full tw-object-cover"
          autoPlay={true}
          loop={true}
          muted={true}
          playsInline={true}
          width="600"
          height="840"
        >
          <source src={videoSource.videoWebm?.publicURL} type="video/webm" />
          <source src={videoSource.videoMp4?.publicURL} type="video/mp4" />
        </video>
      </div>
    );
  } else {
    return (
      <div className="tw-relative">
        <StaticImage
          src="../../../static/media/home/laptop.png"
          alt=""
          aria-hidden="true"
          className="tw-w-full max-lg:tw-w-[70%] max-md:tw-w-[80%] "
          objectFit="contain"
          loading="lazy"
          width={555}
          height={365}
        />
        <div
          className="tw-absolute 
        tw-left-[12%] 
        tw-top-[-6px] tw-h-[94%] tw-w-[77%] tw-overflow-hidden
        max-xl:tw-left-[9.5%] max-xl:tw-top-[15px] max-xl:tw-h-[80%] max-xl:tw-w-[82%]
        max-lg:tw-left-[21.7%] max-lg:tw-top-[-6px] max-lg:tw-h-[94%] max-lg:tw-w-[57%]
        max-md:tw-left-[15.8%] max-md:tw-top-[17px] max-md:tw-h-[80%] max-md:tw-w-[69%]
        max-sm:tw-left-[18%] max-sm:tw-top-[-6px] max-sm:tw-h-[94%] max-sm:tw-w-[65%]
        
          "
        >
          <video
            id="video-top-ls"
            className="tw-h-full tw-w-full"
            autoPlay={true}
            loop={true}
            muted={true}
            playsInline={true}
            width="600"
            height="840"
          >
            <source src={videoSource.videoWebm?.publicURL} type="video/webm" />
            <source src={videoSource.videoMp4?.publicURL} type="video/mp4" />
          </video>
        </div>
      </div>
    );
  }
};
