import { Video } from "../Templates/HeroContent";
import * as React from "react";
import { twMerge } from "tailwind-merge";

export const SectionVideo = (props: { video: Video; className?: string; aspectRatio?: number }): JSX.Element => {
  const { video, aspectRatio = 16 / 9 } = props;
  const [videoWidth, setVideoWidth] = React.useState<number>(0);

  function setVideoSize(): void {
    const windowWidth = window.innerWidth;

    let width = windowWidth * 0.47;

    if (windowWidth < 620) {
      width = windowWidth * 0.9;
    }

    if (windowWidth > 620 && windowWidth < 1281) {
      width = windowWidth * 0.65;
    }

    if (windowWidth > 2100) {
      width = (2100 / 2) * 0.9;
    }

    setVideoWidth(Math.floor(width));
  }

  React.useEffect(() => {
    setVideoSize();
    const handleResize = () => setVideoSize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      style={{
        minWidth: `${videoWidth}px`,
        width: `${videoWidth}px`,
        height: `${videoWidth / aspectRatio}px`,
      }}
      className={twMerge(`safari-ios-overflow-fix`, "tw-bg-black", props.className)}
    >
      <video autoPlay={true} loop={true} muted={true} playsInline={true} preload="auto" poster={video.poster.src} width={videoWidth}>
        <source src={video.webM?.publicURL} type="video/webm" />
        <source src={video.mp4?.publicURL} type="video/mp4" />
        <track label="English" kind="captions" srcLang="en" src="" />
      </video>
    </div>
  );
};
