import { Footer } from "../components/Footer";
import { Layout } from "../components/Layout";
import { Link, graphql } from "gatsby";
import { DateTime } from "luxon";
import * as React from "react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

export default function Awards({ location }) {
  // countdown
  const EndDateTime = DateTime.fromObject(
    {
      year: 2023,
      month: 4,
      day: 13,
      hour: 21,
      minute: 43,
    },
    { zone: "Australia/Brisbane" }
  );

  const [hasPassed, setHasPassed] = useState(null);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      const currentDateTime = DateTime.now();

      if (EndDateTime < currentDateTime) {
        setHasPassed(true);

        clearInterval(interval);
      } else {
        setHasPassed(false);
        const difference = EndDateTime.diff(currentDateTime, ["days", "hours", "minutes", "seconds"]).toObject(); //=> { months: 1, days: 2 }
        setDays(difference.days);
        setHours(difference.hours);
        setMinutes(difference.minutes);
        setSeconds(Math.floor(difference.seconds));
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  // end countdown

  return (
    <Layout location={location}>
      <main id="main" className="marketing-content container-fluid">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-secondary body-marketing-awards hide-elevio",
          }}
        ></Helmet>
        <section className="row section-title">
          <div className="col-11 col-xs-8 col-lg-6  mx-auto section-inner">
            <h1>
              <span className="shorthand-logo" aria-hidden="true" />
              Shorthand Awards
            </h1>
            <h2>Every year, we celebrate the best-of-the-best digital content on the web.</h2>
          </div>
          <div aria-hidden="true" className="background-media">
            <video
              id="video-top-ls"
              className="section-background-video title-ls"
              autoPlay={true}
              loop={false}
              muted={true}
              playsInline={true}
              poster={"/media/awards/title-bg.jpg"}
              width="1920"
              height="1080"
            >
              <source src="/media/awards/title-bg.mp4" type="video/mp4" />
            </video>

            <div className="background-overlay" />
          </div>
        </section>
        {/* countdown */}
        {hasPassed === false && (
          <>
            <div className="row section ">
              <div className="container  tw-text-center">
                <div className="col-sm-11 col-lg-8  tw-mx-auto">
                  <h3 className="tw-mb-5 tw-font-CircularXXSub ">Check out our finalists</h3>
                  <p className="tw-mb-10 tw-text-black">
                    The Shorthand Awards celebrate the best digital stories published on the web and the hard work of the writers,
                    designers, and content creators who build them.
                  </p>
                  <Link
                    to="https://shorthand.com/the-craft/7th-shorthand-awards-finalists/index.html"
                    target="_blank"
                    className="btn btn-lg btn-primary !tw-mb-0"
                  >
                    See the finalists
                  </Link>
                </div>
              </div>
            </div>
            <div className="row section tw-border-b tw-border-b-[rgba(0,_0,_0,_0.05)] tw-bg-[#F1F2F2]">
              <div className="container tw-text-center">
                <div className="col-sm-11 col-lg-8  tw-mx-auto">
                  <h3 className="tw-mb-4 tw-font-CircularXXSub ">The 7th annual Shorthand Award winners are coming soon</h3>
                  <p className="tw-mb-12 tw-text-black max-md:tw-mb-6">Our judges are judging and the winners will be announced in:</p>
                  <div className="tw-mx-auto tw-grid tw-w-[80%] tw-grid-cols-4 tw-rounded-[10px] tw-bg-white tw-px-12 tw-py-10 max-xl:tw-w-full max-md:tw-px-2 max-md:tw-py-6">
                    <div className="tw-border-r tw-border-[#A4A4A4] tw-text-center">
                      <span className="tw-block tw-font-CircularXXSub tw-text-[2.75rem] tw-font-semibold tw-leading-[130%] tw-text-text_black max-md:tw-text-3xl">
                        {days}
                      </span>
                      <span className="tw-block tw-font-CircularXXSub tw-text-sm tw-font-semibold tw-uppercase tw-tracking-[0.5rem] tw-text-[#A4A4A4] max-md:tw-tracking-normal">
                        Days
                      </span>
                    </div>

                    <div className="tw-border-r tw-border-[#A4A4A4] tw-text-center">
                      <span className="tw-block tw-font-CircularXXSub tw-text-[2.75rem] tw-font-semibold tw-leading-[130%] tw-text-text_black max-md:tw-text-3xl">
                        {hours}
                      </span>
                      <span className="tw-block tw-font-CircularXXSub tw-text-sm tw-font-semibold tw-uppercase tw-tracking-[0.5rem] tw-text-[#A4A4A4] max-md:tw-tracking-normal">
                        Hours
                      </span>
                    </div>

                    <div className="tw-border-r tw-border-[#A4A4A4] tw-text-center">
                      <span className="tw-block tw-font-CircularXXSub tw-text-[2.75rem] tw-font-semibold tw-leading-[130%] tw-text-text_black max-md:tw-text-3xl">
                        {minutes}
                      </span>
                      <span className="tw-block tw-font-CircularXXSub tw-text-sm tw-font-semibold tw-uppercase tw-tracking-[0.5rem] tw-text-[#A4A4A4] max-md:tw-tracking-normal">
                        Mins
                      </span>
                    </div>

                    <div className="tw-text-center">
                      <span className="tw-block tw-font-CircularXXSub tw-text-[2.75rem] tw-font-semibold tw-leading-[130%] tw-text-text_black max-md:tw-text-3xl">
                        {seconds}
                      </span>
                      <span className="tw-block tw-font-CircularXXSub tw-text-sm tw-font-semibold tw-uppercase tw-tracking-[0.5rem] tw-text-[#A4A4A4] max-md:tw-tracking-normal">
                        Secs
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {hasPassed === true && (
          <div className="row section tw-border-b tw-border-b-[rgba(0,_0,_0,_0.05)] tw-bg-[#F1F2F2]">
            <div className="container tw-text-center">
              <div className="col-sm-11 col-lg-8  tw-mx-auto">
                <h3 className="tw-mb-4 tw-font-CircularXXSub ">Shorthand Award winners</h3>
                <p className="tw-mb-12 tw-text-black max-md:tw-mb-6">
                  2023 was a bumper year for entries to the Shorthand Awards, with spectacular stories coming in from around the globe.
                  Congratulations to the winners, your talent and commitment to the craft of digital storytelling is truely inspiring.
                </p>
                <Link
                  to="https://shorthand.com/the-craft/7th-shorthand-awards-winners/index.html"
                  className="btn btn-lg btn-primary !tw-mb-0"
                >
                  See the winners of the 7th annual Shorthand Awards
                </Link>
              </div>
            </div>
          </div>
        )}

        {/* end countdown */}

        <section className="row section section-categories">
          <div className="container container-sm text-center tw-font-CircularXXSub">
            <div className="row">
              <div className="col-sm-12 col-md-10 col-lg-8  mx-auto marketing-main-column">
                <h3>Award categories</h3>
              </div>
              <div className="col-sm-12 mx-auto mb-4">
                <div className="features features-cards features-cards-x5">
                  <ul>
                    <li>
                      <div className="feature-card card-shadow">
                        <img src="/media/awards/icn-editorial.svg" alt="" />
                        <div className="feature-card-text">
                          <h4>Best editorial story</h4>
                          <p>This category champions the best news and editorial content, from longform analysis to urgent op-eds</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="feature-card card-shadow">
                        <img src="/media/awards/icn-brand.svg" alt="" />
                        <div className="feature-card-text">
                          <h4>Best brand story</h4>
                          <p>Nominate exciting, visual marketing stories that tell brand narratives well</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="feature-card card-shadow">
                        <img src="/media/awards/icn-nfp.png" alt="" />
                        <div className="feature-card-text">
                          <h4>Best not-for-profit story</h4>
                          <p>
                            For the best examples of non-profit organisations moving hearts and minds with digital content on the web
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="feature-card card-shadow">
                        <img src="/media/awards/icn-business.svg" alt="" />
                        <div className="feature-card-text">
                          <h4>Best business story</h4>
                          <p>
                            Written a stunning report? Gone the extra mile with B2B stories or corporate comms? We want to see them!
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="feature-card card-shadow">
                        <img src="/media/awards/icn-education.svg" alt="" />
                        <div className="feature-card-text">
                          <h4>Best education story</h4>
                          <p>Celebrating engaging, empowering, and vivid stories from across the education sectors</p>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="feature-card card-shadow">
                        <img src="/media/awards/icn-art.svg" alt="" />
                        <div className="feature-card-text">
                          <h4>Best arts & culture story</h4>
                          <p>Show us the most beautiful stories across the realms of arts, culture, and heritage</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="feature-card card-shadow">
                        <img src="/media/awards/icn-agency.svg" alt="" />
                        <div className="feature-card-text">
                          <h4>Best agency/partner story</h4>
                          <p>Submit your proudest stories made in collaboration with other teams</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="feature-card card-shadow">
                        <img src="/media/awards/icn-innovation.svg" alt="" />
                        <div className="feature-card-text">
                          <h4>Most innovative story</h4>
                          <p>For stories pushing the boundaries of traditional web content</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="feature-card card-shadow">
                        <img src="/media/awards/icn-craft.svg" alt="" />
                        <div className="feature-card-text">
                          <h4>The Craft award</h4>
                          <p>Give some love to the crafters: nominate a designer, writer, or leader who’s the best in their field</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="feature-card card-shadow card-inverse">
                        <img src="/media/awards/icn-community.svg" alt="" />
                        <div className="feature-card-text">
                          <h4>The community choice award</h4>
                          <p className="pb-3">
                            Nominated by the Shorthand Team, this cross-category Award will be selected by an open public poll
                          </p>
                          <div className="btn btn-sm btn-primary mb-4 btn-disabled">Voting closed</div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="row">
                  <div className="col-sm-12 mx-auto marketing-main-column">
                    <p className="mt-4">
                      To nominate your own &mdash; or a colleague’s &mdash; story for one of the categories above, click the button
                      below and fill out our form. You can submit multiple nominations, but all nominated stories must have been
                      published in 2022.
                    </p>
                  </div>
                  <div className="col-sm-12 mx-auto">
                    <div className="d-flex align-items-center justify-content-center flex-wrap  mt-4">
                      <a
                        href="https://shorthand.com/the-craft/shorthand-awards-nomination-questions/index.html"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="btn btn-lg btn-secondary"
                      >
                        Nomination questions
                      </a>
                      <a
                        href="https://shorthand.com/the-craft/shorthand-awards-meet-the-judges/index.html"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="btn btn-lg btn-secondary"
                      >
                        Meet the judges
                      </a>
                      <div className="btn btn-lg btn-primary btn-disabled">Submissions now closed</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="row section section-newsletter">
          <div className="container container-sm text-center tw-font-CircularXXSub">
            <div className="row">
              <div className="col-sm-12 mx-auto marketing-main-column">
                <img className="logo mx-auto" src="/media/logos/shorthand-logo.svg" alt="" />
                <h3 className="mt-4">About the Shorthand Awards</h3>
                <p>
                  The Shorthand Awards were founded to recognise the best-of-the-best digital web content from around the world. The
                  awards celebrate the craft of digital content creation &mdash; both the incredible stories and the people that create
                  them.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="row section section-past">
          <div className="container container-sm text-center tw-font-CircularXXSub">
            <div className="row">
              <div className="col-sm-12 mx-auto">
                <h3>Congratulations to all of our previous Shorthand Award winners!</h3>
                <div className="features features-cards features-cards-x3">
                  <ul>
                    <li>
                      <div className="feature-card card-shadow">
                        <a href="https://shorthand.com/the-craft/shorthand-awards-winners/" target="_blank" rel="nofollow noreferrer">
                          <div className="featured-story-media">
                            <img
                              src="/media/awards/awards-2021.webp"
                              loading="lazy"
                              width="505"
                              height="361"
                              alt=""
                              aria-hidden="true"
                            />
                          </div>
                          <h4>2021 winners</h4>
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="feature-card card-shadow">
                        <a
                          href="https://shorthand.com/the-craft/5th-annual-shorthand-award-winners/"
                          target="_blank"
                          rel="nofollow noreferrer"
                        >
                          <div className="featured-story-media">
                            <img
                              src="/media/awards/awards-2020.webp"
                              loading="lazy"
                              width="505"
                              height="361"
                              alt=""
                              aria-hidden="true"
                            />
                          </div>
                          <h4>2020 winners</h4>
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="feature-card card-shadow">
                        <a
                          href="https://shorthand.com/the-craft/the-2019-shorthand-award-winners/index.html"
                          target="_blank"
                          rel="nofollow noreferrer"
                        >
                          <div className="featured-story-media">
                            <img
                              src="/media/awards/awards-2019.webp"
                              loading="lazy"
                              width="505"
                              height="361"
                              alt=""
                              aria-hidden="true"
                            />
                          </div>
                          <h4>2019 winners</h4>
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="feature-card card-shadow">
                        <a
                          href="https://shorthand.com/the-craft/The-winners-of-the-third-annual-Shorthand-Awards-are/index.html"
                          target="_blank"
                          rel="nofollow noreferrer"
                        >
                          <div className="featured-story-media">
                            <img
                              src="/media/awards/awards-2018.webp"
                              loading="lazy"
                              width="505"
                              height="361"
                              alt=""
                              aria-hidden="true"
                            />
                          </div>
                          <h4>2018 winners</h4>
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="feature-card card-shadow">
                        <a
                          href="https://shorthand.com/the-craft/the-winners-of-the-2017-shorthand-awards-are/"
                          target="_blank"
                          rel="nofollow noreferrer"
                        >
                          <div className="featured-story-media">
                            <img
                              src="/media/awards/awards-2017.webp"
                              loading="lazy"
                              width="505"
                              height="361"
                              alt=""
                              aria-hidden="true"
                            />
                          </div>
                          <h4>2017 winners</h4>
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="feature-card card-shadow">
                        <a
                          href="https://shorthand.com/the-craft/announcing-the-winners-of-the-shorthand-awards/"
                          target="_blank"
                          rel="nofollow noreferrer"
                        >
                          <div className="featured-story-media">
                            <img
                              src="/media/awards/awards-2016.webp"
                              loading="lazy"
                              width="505"
                              height="361"
                              alt=""
                              aria-hidden="true"
                            />
                          </div>
                          <h4>2016 winners</h4>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer className="mt-0" />
      </main>
    </Layout>
  );
}

export const query = graphql`
  query AwardsQuery {
    site {
      siteMetadata {
        name
        title
        tagline
        description
      }
    }
  }
`;
