import { filterListByCurrentIndustryCode } from "../../Utils";
import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { RelatedCaseStudies } from "../../components/RelatedCaseStudies";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default function CaseStudy({ data, location }) {
  return (
    <Layout location={location}>
      <main id="main" className="marketing-content container-fluid">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-secondary body-marketing-case-studies hide-elevio",
          }}
        >
          <title>University of Cambridge | Visual storytelling</title>
          <meta
            name="description"
            content="The UK's iconic university has been using Shorthand since 2017 to create impactful and visually compelling stories about its world-changing research and 800-year history"
          />
        </Helmet>
        <div className="row section-title">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto  section-inner">
            <h1>
              <span className="shorthand-logo">Shorthand</span>
              <span className="subsection-label">Case Study:</span>
              University of Cambridge
            </h1>
            <h2>
              The University of Cambridge is using Shorthand to engage alumni and attract the best students and researchers with its
              engaging visual stories
            </h2>
          </div>
          <div aria-hidden="true" className="background-media">
            <img
              src="/media/case-studies/case-studies-university-of-cambridge-title.jpg"
              data-object-fit="cover"
              data-autoscale-content-ratio="1.77777777778"
              alt=""
            />
          </div>
        </div>
        <div className="row section">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto marketing-main-column">
            <h3 className="intro">
              The UK's iconic university has been using Shorthand since 2017 to create impactful and visually compelling stories about
              its world-changing research and 800-year history
            </h3>
            <div className="inline-image featured">
              <img
                src="/media/case-studies/case-studies-university-of-cambridge.jpg"
                alt="The Lost Words, by the University of Cambridge, renders responsively across all devices"
              />
            </div>
            <p>
              The University of Cambridge has plenty of stories to tell. “Cambridge is the most amazing place. The ideas and research
              that come from Cambridge help to save lives and change the way we think about the world – almost every day,” says Stuart
              Roberts, head of communications at Cambridge University Library. “However, everyone is busy and there is more content out
              there than you can possibly imagine.”
            </p>
            <p>
              Stuart knows that in order to attract the best students and researchers to Cambridge to continue that work, they need to
              not only tell those stories, but tell them in a meaningful and memorable way. That&apos;s why churning out content using
              a standard Content Management System just doesn&apos;t cut it.
            </p>
            <p>
              “Shorthand has been important for us because it allows us to grab people by the scruff of the neck and urge them to read
              on,” explains Stuart, who was formerly deputy head of research communications at the university.
            </p>
            <figure className="block quote-block offset left">
              <blockquote>
                <p>
                  "From the very start in your first Title section, whether it&apos;s a full screen image or autoplay video, you can
                  grab peoples&apos; attention. That, in terms of storytelling, is really crucial."
                </p>
              </blockquote>
              <figcaption className="quote-cite">Stuart Roberts</figcaption>
            </figure>
            <p>
              “From the very start in your first&nbsp;
              <a href="https://support.shorthand.com/en/articles/6" target="_blank" rel="noopener noreferrer" className="btn-link">
                Title section
              </a>
              , whether it&apos;s a full screen image or autoplay video you can grab peoples&apos; attention. That, in terms of
              storytelling, is really crucial. If it doesn&apos;t look great and dynamic, then they will just go somewhere where they
              can get that.”
            </p>

            <p>
              The first Shorthand story Cambridge published was&nbsp;
              <a href="https://www.cam.ac.uk/tryst_with_destiny" target="_blank" rel="noopener noreferrer" className="btn-link">
                A Tryst with Destiny
              </a>
              &nbsp;in 2017, which went on to be shortlisted for the Shorthands Awards that year.
            </p>
            <p>
              “We had amazing images in our Centre of South Asian Studies about the partition of India in 1947, and India and Pakistan
              becoming sovereign states. We wouldn&apos;t have been able to tell that story properly if we only had our website,”
              Stuart says.
            </p>
            <p>
              “It was essential for us to use full-screen images and utilise Shorthand&apos;s&nbsp;
              <a
                href="https://shorthand.com/the-craft/reveal-masterclass/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                Reveal section
              </a>
              . We had to let the amazing pictures speak for themselves.”
            </p>
            <div className="case-study-video-inline-container">
              <video className="case-study-video-inline" muted loop playsInline autoPlay>
                <source src="/media/case-studies/case-studies-university-of-cambridge.mp4" type="video/mp4" />
              </video>
            </div>
            <p>
              Six months later, when one of the most world-renowned and respected professors of science sadly passed away, the
              University of Cambridge shared a{" "}
              <a href="https://www.cam.ac.uk/stephenhawking" target="_blank" rel="noopener noreferrer" className="btn-link">
                fitting tribute
              </a>
              .
            </p>

            <figure className="block quote-block offset right">
              <blockquote>
                <p>
                  "We&apos;ve always had people read our stories and send emails in, but I think as we&apos;ve used Shorthand it has
                  become much more engaging. That&apos;s evident in the engagement stats we&apos;ve seen and comments we&apos;ve had."
                </p>
              </blockquote>
              <figcaption className="quote-cite">Stuart Roberts</figcaption>
            </figure>

            <p>
              The late Stephen Hawking was associated with the university for most of his life and career. Cambridge&apos;s obituary of
              the professor remains their most-read Shorthand story to date.
            </p>
            <p>
              “Professor Hawking was iconic and beloved. People admired the work he did and the struggles he overcame. I think being
              able to tell that story in that visual way made readers feel more a part of that particular moment in time,” Stuart says.
            </p>
            <p>
              Readers engaged with it in a profound way, and continue to do so, spending an average of 10 minutes reading about
              Hawking&apos;s time at the university and contacting the creators to tell them how much they love the tribute.
            </p>
            <p>
              “We&apos;ve always had people read our stories and send emails in, but I think as we&apos;ve used Shorthand it has become
              much more engaging. That&apos;s evident in the engagement stats we&apos;ve seen and comments we&apos;ve had,” Stuart
              adds.
            </p>

            <h3 className="subheading">Growing audiences</h3>

            <p>
              As well as engagement, another target Stuart has been striving to hit is audience growth. Cambridge is thinking more like
              a publisher, taking inspiration from the likes of BBC News and Stuff in New Zealand.
            </p>

            <p>
              As well as tributes and archive showcases, they are also producing exhibition write-ups, research pieces, alumni
              outreach, interviews and so much more.
            </p>

            <p>
              “We&apos;ve tried to provide content that people will want to see directly on the University of Cambridge website; not
              just relying on the media or press releases to find an audience, but to actually bring an audience directly to us and
              grow that over time.”
            </p>
            <div className="photo-grid flex featured">
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-university-of-cambridge-sml-01.jpg"
                alt="Background Scrollmation section from Professor Stephen Hawking, by the University of Cambridge"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-university-of-cambridge-sml-02.jpg"
                alt="Text Over Media section from Gorillas Found To Live In &#039;Complex&#039; Societies, by the University of Cambridge"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-university-of-cambridge-sml-04.jpg"
                alt="Text Over Media section taken from Why We Just Can't Stop Eating, by the University of Cambridge"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-university-of-cambridge-sml-03.jpg"
                alt="Title section from Releasing The Imagination, by the University of Cambridge"
              />
            </div>
            <div className="caption">A selection of sections from The University of Cambridge's stories</div>
            <p>
              As a result, many — if not all — of the features on their&nbsp;
              <a href="https://www.cam.ac.uk/" target="_blank" rel="noopener noreferrer" className="btn-link">
                homepage carousel
              </a>
              &nbsp;are Shorthand stories. “That&apos;s usually where visitors click first, according to heat maps. We really want that
              to be our finest offering,” he says.
            </p>

            <p>
              Stuart insists there is a need for organisations to act this way, not only because everyone is busy, but because the
              media environment has changed. “There are fewer journalists than ten years ago. It&apos;s harder to get your stuff out
              there. You have to think more creatively.”
            </p>
            <p>
              Adopting a new tool can help its users embrace new ways of working. Stuart says if you have great imagery “you&apos;re
              halfway there”, but the team need to adapt when using Shorthand for stories with a lack of commissioned visuals.
            </p>

            <p>
              An example of this is a story about a book on{" "}
              <a href="https://www.cam.ac.uk/unmarried_mothers" target="_blank" rel="noopener noreferrer" className="btn-link">
                unmarried mothers
              </a>{" "}
              from the 1700 and 1800s. “We didn&apos;t have pictures to go with it. However, it was a great story and we wanted people
              to engage with it,” Stuart explains.
            </p>
            <p>
              “Because we are a not-for-profit we managed to use pictures from the British Museum archives for free. That turned what
              would have been a straightforward CMS story into what I think and hope was a compelling Shorthand story. I heard from a
              lot of people who said they had interacted with and enjoyed that story.”
            </p>
            <p>
              Promotion and maximising the discoverability of stories has been another area of learning for the team. (You can read
              Stuart&apos;s tips about promotion in this{" "}
              <a
                href="https://shorthand.com/the-craft/3-tips-for-promoting-content"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                <em>Craft</em> post
              </a>
              ).
            </p>
            <figure className="block quote-block offset left">
              <blockquote>
                <p>
                  "There is nothing to fear with Shorthand. There&apos;s no mystery to it. The ease of use and its intuitiveness are
                  why people love it."
                </p>
              </blockquote>
              <figcaption className="quote-cite">Stuart Roberts</figcaption>
            </figure>
            <p>
              Stuart wishes he could create a whole team solely dedicated to creating Shorthand stories! While that&apos;s not possible
              right now, he is actively encouraging increased usage across the university, from Cambridge University Library and the
              University of Cambridge Museums, to faculties and departments, too.
            </p>

            <p>
              “We realise Shorthand is now the natural way to tell our stories,” he says. “The platform also allows us to think about
              how we are going to expand and tell the whole story, rather than just parts of it, which I think we have been limited to
              in the past.”
            </p>

            <p>
              The breadth, frequency and dwell time on Cambridge&apos;s Shorthand stories continue to grow, as does Stuart&apos;s
              praise for the tool.
            </p>
            <p>
              “There is nothing to fear with Shorthand. There&apos;s no mystery to it. The ease of use and its intuitiveness are why
              people love it. Pretty quickly you can turn a standard piece of content into something much more meaningful and engaging.
              We often spend a lot of time on our stories and we want people to read and love our storytelling.”
            </p>
            <div className="related-stories">
              <h4>Read more from The University of Cambridge:</h4>
              <ul>
                <li>
                  <a href="https://www.cam.ac.uk/thelostwords" target="_blank" rel="noopener noreferrer" className="btn-link">
                    The Lost Words
                  </a>
                </li>
                <li>
                  <a href="https://www.cam.ac.uk/tryst_with_destiny" target="_blank" rel="noopener noreferrer" className="btn-link">
                    A Tryst with Destiny
                  </a>
                </li>
                <li>
                  <a href="https://www.cam.ac.uk/stephenhawking" target="_blank" rel="noopener noreferrer" className="btn-link">
                    Professor Stephen Hawking
                  </a>
                </li>
                <li>
                  <a href="https://www.cam.ac.uk/cantstopeating" target="_blank" rel="noopener noreferrer" className="btn-link">
                    Why We Just Can&apos;t Stop Eating
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <RelatedCaseStudies studies={filterListByCurrentIndustryCode(data, "cambridge")} />
        <Footer />
      </main>
    </Layout>
  );
}

export const query = graphql`
  query CambridgeQuery {
    allCaseStudiesJson {
      edges {
        node {
          jsonId
          image
          industry
          industryCode
          organisation
          summary
        }
      }
    }
  }
`;
