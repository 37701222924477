import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { Link, graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default class IndustriesEducation extends React.Component<any, any> {
  render() {
    const { allBrandsJson } = this.props.data;

    const brands = allBrandsJson.edges;

    return (
      <Layout location={this.props.location}>
        <main id="main" className="marketing-content container-fluid">
          <Helmet
            bodyAttributes={{
              class: "body-shorthand body-marketing body-marketing-secondary body-marketing-features body-light hide-elevio",
            }}
          ></Helmet>
          <div className="row section-title title-light">
            <div className="col-11 col-sm-8  mx-auto section-inner">
              <h1>
                <span className="shorthand-logo dark" aria-hidden="true">
                  Shorthand
                </span>
                Higher Education
              </h1>
              <h2>Publish stunning digital magazines, research communication, and alumni outreach.</h2>
            </div>
          </div>
          <section className="row section-brands section-border-top">
            <div className="brands-list">
              <ul>
                <li>
                  <img src="/media/logos/clients/lg-uq.png" loading="lazy" width="348" height="241" alt="UQ logo" />
                </li>
                <li>
                  <img
                    src="/media/logos/clients/lg-imperial.png"
                    loading="lazy"
                    width="348"
                    height="241"
                    alt="Imperial College logo"
                  />
                </li>
                <li>
                  <img src="/media/logos/clients/lg-emory.png" loading="lazy" width="348" height="241" alt="Emory logo" />
                </li>
                <li>
                  <img
                    src="/media/logos/clients/lg-houston.png"
                    loading="lazy"
                    width="348"
                    height="241"
                    alt="University of Houston logo"
                  />
                </li>

                <li>
                  <img src="/media/logos/clients/lg-utah.png" loading="lazy" width="348" height="241" alt="University of Utah logo" />
                </li>
              </ul>
            </div>
          </section>

          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Scale your digital publishing</h3>
                  <p>Shorthand is a proven solution for all forms of university and college publishing.</p>
                  <ul className="features-list">
                    <li>
                      Publish digital magazines, research findings, fundraising campaigns, annual reports, internal communications,
                      event guides, class notes, and more.
                    </li>
                    <li>On applicable plans, publish stories to multiple subdomains across your institution.</li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/industries/edu-grid-1-grey.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-right">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Built for your team</h3>
                  <p>
                    Shorthand is built for marketing and communications professionals. Get started in minutes &mdash; no code required.
                  </p>
                  <ul className="features-list">
                    <li>Publish on your schedule &mdash; without the bottlenecks caused by busy developers.</li>
                    <li>
                      Training comes free! Join us for regular free live training webinars and masterclasses, or select a plan that
                      includes personalised onboarding with white-glove support from our industry-leading customer success team.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/industries/culture-left-white.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Proven results</h3>
                  <p>
                    Shorthand stories are a proven solution with measurable outcomes &mdash; fewer bounces, more engagement, higher
                    dwell-times, and better click-through-rates.
                  </p>
                  <ul className="features-list">
                    <li>Measure the impact of your digital stories with Shorthand’s built-in analytics system.</li>
                    <li>Easily integrate with your existing analytics platforms, such as Google Analytics.</li>
                    <li>
                      <Link to="/case-studies/" className="btn-link">
                        Read our case studies
                      </Link>
                      &nbsp;to find out more about the real-world impact of Shorthand.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/industries/analytics-ipad-grey.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-right">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Digitally native</h3>
                  <p>
                    Say goodbye to flipbook magazines and PDFs. Fully responsive and SEO-friendly, Shorthand is built for the modern
                    web.
                  </p>
                  <ul className="features-list">
                    <li>Replace your first-generation digital magazine with stories people love to read &mdash; on any device.</li>
                    <li>
                      Maintain the design standards and visual identity of your print magazine as you transition to digital publishing.
                    </li>
                    <li>Integrate with your existing analytics solution and measure results alongside content from your CMS.</li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/industries/sharks1.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Free for communications and journalism educators</h3>
                  <p>
                    Shorthand is free for teaching journalism and comms classes at all accredited educational institutions, including
                    schools, colleges, and universities.
                  </p>
                  <ul className="features-list">
                    <li>
                      Introduce your students to the next generation of digital publishing, used by media, marketing, and
                      communications teams at leading brands.
                    </li>
                    <li>Add unlimited students and classes, and create unlimited stories.</li>
                  </ul>
                  <a className="btn btn-secondary" href="https://shorthandstories.typeform.com/to/Fhq7Ye">
                    Apply for our teaching plan
                  </a>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/industries/chaos-grey.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section  section-strip section-strip-quote ">
            <div className="container container-sm">
              <div className="section-strip-col-single section-strip-single-quote">
                <div className="section-strip-quote-inner">
                  <blockquote>
                    &ldquo;Our Shorthand stories are performing on every metric that we've been measuring ourselves on. The targets and
                    stretch targets have been met on all our content.&rdquo;
                  </blockquote>

                  <cite>Andrew Youngson | News and Campaigns Content Manager | Imperial College London</cite>
                </div>
              </div>
            </div>
          </section>

          <section className="row section  section-strip-stories section-border-top">
            <div className="container flex-column section-inner text-center">
              <h3>Take a look at these great stories from university marketing teams, built with Shorthand</h3>
              <div className="featured-stories">
                <ul>
                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://utah-health.shorthandstories.com/they-emerge-transformed/index.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/houston-transformed.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        They emerge transformed
                        <span className="featured-story-brand">University of Utah Health</span>
                      </h4>
                    </a>
                  </li>

                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://www.cam.ac.uk/aerialphotography"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/cambridge-air.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        Britain from the Air
                        <span className="featured-story-brand">University of Cambridge</span>
                      </h4>
                    </a>
                  </li>

                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://www.imperial.ac.uk/stories/mission-to-the-sun/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/imperial-mission.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        Mission to the Sun
                        <span className="featured-story-brand">Imperial College London</span>
                      </h4>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="row section section-dark section-half section-trial !tw-bg-[rgba(249,249,249,0.3)]">
            <div className="container section-inner text-center">
              <h2 className="!tw-text-black">
                Ready to try the digital storytelling platform used by Honda, University of Cambridge, and the BBC?
              </h2>

              <div className="cta-container">
                <a href="https://app.shorthand.com/signup" className="btn btn-lg !tw-bg-black hover:!tw-opacity-70">
                  Start creating
                </a>
              </div>
              <div className="brands">
                <div className="brands-container">
                  <h3 className="as-used">Join these iconic brands</h3>
                  <ul>
                    {brands.map((brand, index) => (
                      <li key={`brand-${brand.node.label}-${index}`} className={`brand`}>
                        <img src={brand.node.logo} alt={`${brand.node.label} logo`} />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div aria-hidden="true" className="background-media background-media-fixed">
              <img src="/media/home/stories.jpg" className="ObjectFit--cover" alt="" />
            </div>
          </section>

          <Footer className="mt-0" />
        </main>
      </Layout>
    );
  }
}

export const query = graphql`
  query IndustriesEducationQuery {
    site {
      siteMetadata {
        name
        title
        tagline
        description
      }
    }
    allBrandsJson {
      edges {
        node {
          logo
          name
          label
        }
      }
    }
  }
`;
