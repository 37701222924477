import { filterListByCurrentIndustryCode } from "../../Utils";
import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { RelatedCaseStudies } from "../../components/RelatedCaseStudies";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default function CaseStudy({ data, location }) {
  return (
    <Layout location={location}>
      <div id="main" className="marketing-content container-fluid">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-secondary body-marketing-case-studies hide-elevio",
          }}
        />
        <div className="row section-title">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6 mx-auto section-inner">
            <h1>
              <span className="shorthand-logo">Shorthand</span>
              <span className="subsection-label">Case Study:</span>
              Nese Media
            </h1>
            <h2>How Nese Media uses Shorthand</h2>
          </div>
          <div aria-hidden="true" className="background-media">
            <img
              src="/media/case-studies/case-studies-nese-media-title.jpg"
              data-object-fit="cover"
              data-autoscale-content-ratio="1.77777777778"
              alt=""
            />
          </div>
        </div>
        <div className="row section">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto marketing-main-column">
            <h2 className="intro">
              <a target="_blank" className="btn-link" rel="noopener noreferrer" href="https://www.nesemedia.no/">
                Nese Media
              </a>{" "}
              started out as a hobby for Lars Ørjan Nese in 2017.
            </h2>
            <p>
              Soon, though, he quit his day job to work full time at his company and, while he’s still Nese Media’s only employee, he
              produces digital content of exceptional quality to a wide range of customers — from drone photography to webinars to
              online concerts.
            </p>
            <p>We sat down with Lars to discuss how Nese Media uses Shorthand as part of their complete web and content solutions.</p>
            <h3 className="subheading">Quality production for quality stories</h3>
            <figure className="block quote-block offset left">
              <blockquote>
                <p>
                  “I came across Shorthand, tried it, and I was amazed because it was so simple and it was so rich in functions and
                  possibilities.”
                </p>
              </blockquote>
              <figcaption className="quote-cite">Lars Ørjan Nese</figcaption>
            </figure>
            <p>
              Lars’ mission is to create high-quality content, including live-streaming, video, podcasts, and photographs. “It’s the
              whole spectrum,” he says, laughing. “I have many, many arms everywhere.”
            </p>
            <p>
              His setup reflects this commitment and focus on quality, from his recording equipment through to his choice of
              storytelling platform.
            </p>
            <p>
              Inspired by the longform stories he was reading in Norwegian media, he began searching for a way to replicate them with
              his own skill set. It wasn’t something he could do with software such as “Squarespace or WordPress,” he says. So he
              researched alternatives.
            </p>
            <p>
              Shorthand wasn’t the first tool he found. “I tried something else,” Lars explains, “but it was too difficult, it took so
              much time. And then I came across Shorthand, tried it, and I was amazed because it was so simple and it was so rich in
              functions and possibilities.” He didn’t even spend much time trialling it. “I was in love at first sight.”
            </p>
            <div className="photo-grid flex featured">
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/case-studies-nese-media-sml-01.jpg" />
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/case-studies-nese-media-sml-02.jpg" />
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/case-studies-nese-media-sml-03.jpg" />
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/case-studies-nese-media-sml-04.jpg" />
            </div>
            <div className="caption">Examples of Nese Media's use of Shorthand.</div>

            <h3 className="subheading">Beautiful stories, fast</h3>
            <figure className="block quote-block offset right">
              <blockquote>
                <p>“I feel it’s simpler to tell a story with Shorthand than it was with writing articles before.”</p>
              </blockquote>
              <figcaption className="quote-cite">Lars Ørjan Nese</figcaption>
            </figure>
            <p>Since Lars started using Shorthand, his whole workflow has changed.</p>
            <p>
              Because his stories lean on visuals, he spends less time working on text. For him, “Form beats content when you use
              Shorthand… I feel it’s simpler to tell a story with Shorthand than it was with writing articles before.” Shorthand’s
              editor makes breaking up text with visual details intuitive, and Lars’ whole process has become more efficient and
              effective.
            </p>
            <p>
              Plus, Shorthand’s global team means that “there’s no delays” when he has questions or problems. “It's a beautiful thing,
              because I send questions in the evening in Norway, and then when I wake up, I get the answer. So I love that.”
            </p>
            <p>
              These days, Lars’ main clients are in ecommerce, so he uses Shorthand to pitch mock-ups to them. “They are blown away by
              how good it looks,” he says. And from his end, he loves that he doesn’t need to code. “Everything is lined up so I can do
              everything for myself,” he says. “I can focus on the content and make it look really good.”
            </p>
            <h3 className="subheading">Super-powered visuals</h3>

            <p>
              While many of his customers are in tech, he also works with local government and even with a logging company. When
              describing the ideal verticals he likes to work with, Lars says Nese Media best serves clients with opportunities for
              strong visual work.
            </p>
            <p>
              The beauty of Shorthand, for him, is that he can leave behind big blocks of text, and lean more into immersive, engaging
              visuals. “You can fill a story with images and small text boxes, and it's so simple and nice to tell a short story in a
              beautiful way.”
            </p>
            <p>
              He describes Shorthand’s Reveal function as “the killer factor” that turned his love-at-first-sight into an enduring
              relationship. Scrollpoints was another function that provides exciting opportunities; he describes creating a map for a
              local event in Norway’s summer. “People said, ‘what is this?’ They can scroll and find places on the map, where they
              should go... We got a nice way to highlight the area.”
            </p>
            <p>
              It’s clear that Nese Media is the result of Lars’ passion, enthusiasm, and integrity. The quality of his work — and he
              really does create works of art — comes from investing in his ideas and using the best tools to produce beautiful,
              high-calibre storytelling.
            </p>
            <p>And how does he know that he’s been successful? Lars says it’s simple: customers keep coming back.</p>
          </div>
        </div>
        <RelatedCaseStudies studies={filterListByCurrentIndustryCode(data, "nese-media")} />
        <Footer />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query NeseMediaQuery {
    allCaseStudiesJson {
      edges {
        node {
          jsonId
          image
          industry
          industryCode
          organisation
          summary
        }
      }
    }
  }
`;
