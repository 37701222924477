import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default class FeaturesPublishingAndHosting extends React.Component<any, any> {
  render() {
    const { allBrandsJson } = this.props.data;

    const brands = allBrandsJson.edges;

    return (
      <Layout location={this.props.location}>
        <main id="main" className="marketing-content container-fluid">
          <Helmet
            bodyAttributes={{
              class: "body-shorthand body-marketing body-marketing-secondary body-marketing-features body-light hide-elevio",
            }}
          ></Helmet>
          <div className="row section-title title-light">
            <div className="col-11 col-sm-8  mx-auto section-inner">
              <h1>
                <span className="shorthand-logo dark" aria-hidden="true">
                  Shorthand
                </span>
                Publishing & Hosting
              </h1>
              <h2>Host Shorthand stories on your servers &mdash; or ours.</h2>
            </div>
          </div>

          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>One-click S3 publishing</h3>
                  <p>
                    Publish your Shorthand story to an Amazon S3 bucket with a single click. Simply configure your S3 settings in
                    Shorthand.
                  </p>
                  {/* <ul className="features-list">
                  <li>
                    Create rich no-code data visualisations, including interactive maps, charts, and graphs that animate as the reader
                    scrolls.
                  </li>
                  <li>Bring your media to life with rich full-screen image transitions.</li>
                </ul> */}
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/publish-aws.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>
          <section className="row section section-strip section-strip-right">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Full story export</h3>
                  <p>
                    Download your story as a zip archive &mdash; including code, media, and a full JSON representation of your story
                    &mdash; and host it wherever you like.
                  </p>
                  {/* <ul className="features-list">
                  <li>Choose from a range of flexible and easy-to-use section types. </li>
                  <li>Anyone can build a story from scratch, including writers, marketers, editors, designers, and developers.</li>
                  <li>
                    Don’t worry about fonts and formatting &mdash; the Shorthand editor will make your copy consistent, on-brand, and
                    responsive.
                  </li>
                </ul> */}
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/publish-export.png" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>
          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Host with Shorthand</h3>
                  <p>
                    Shorthand-hosted stories can be published to a domain or subdomain of your choice &mdash; including at
                    shorthandstories.com.
                  </p>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/publish-host.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Story embed</h3>
                  <p>
                    Embed your Shorthand story into any web page. This is as simple as copying and pasting a code snippet that's
                    automatically generated for you.
                  </p>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/publish-launch.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section  section-strip-features-sm section-gray-lighest">
            <div className="container section-inner text-center">
              <div className="features features-cards icon-cards">
                <ul>
                  <li>
                    <div className="feature-card icon-card card-border card-shadow">
                      <div className="card-icon">
                        <i className="fas fa-server"></i>
                      </div>
                      <div className="card-text">
                        <h3>Asset server</h3>
                        <p>Host your images and videos separately from your story HTML.</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="feature-card icon-card card-border card-shadow">
                      <div className="card-icon">
                        <i className="fas fa-puzzle-piece"></i>
                      </div>
                      <div className="card-text">
                        <h3>WordPress & Drupal</h3>
                        <p>Use our plugins to tightly integrate Shorthand with your WordPress or Drupal CMS.</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="feature-card icon-card card-border card-shadow">
                      <div className="card-icon">
                        <i className="fas fa-plug"></i>
                      </div>
                      <div className="card-text">
                        <h3>Story API</h3>
                        <p>Use the Story API to integrate with Content Management Systems and custom plugins.</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <section className="row section  section-strip section-strip-quote ">
            <div className="container container-sm">
              <div className="section-strip-col-single section-strip-single-quote">
                <div className="section-strip-quote-inner">
                  <blockquote>
                    &ldquo;Net Promoter Scores within RELX have soared. Across the businesses, we've had a 20 point increase in people
                    saying they would recommend RELX as a place to work. Those are record levels.&rdquo;
                  </blockquote>
                  <cite>Paul Abrahams | Head of Corporate Communications | RELX</cite>
                </div>
              </div>
            </div>
          </section>

          <section className="row section  section-strip-stories section-border-top">
            <div className="container flex-column section-inner text-center">
              <h3>Immersive storytelling in action</h3>
              <div className="featured-stories">
                <ul>
                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://projects.cafod.org.uk/uganda/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/cafod-journey.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        A journey in the dark <span className="featured-story-brand">CAFOD</span>
                      </h4>
                    </a>
                  </li>

                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://www.nbcnews.com/specials/-covid-chronicles-vol-5-locked-down-in-wuhan/index.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/nbc-wuhan.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        Lockdown in Wuhan <span className="featured-story-brand">NBC</span>
                      </h4>
                    </a>
                  </li>

                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://interactives.stuff.co.nz/2019/07/407-and-rising/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/stuff-407.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        407… and rising <span className="featured-story-brand">Stuff</span>
                      </h4>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="row section section-dark section-half section-trial !tw-bg-[rgba(249,249,249,0.3)]">
            <div className="container section-inner text-center">
              <h2 className="!tw-text-black">
                Ready to try the digital storytelling platform used by Honda, University of Cambridge, and the BBC?
              </h2>

              <div className="cta-container">
                <a href="https://app.shorthand.com/signup" className="btn btn-lg !tw-bg-black hover:!tw-opacity-70">
                  Start creating
                </a>
              </div>
              <div className="brands">
                <div className="brands-container">
                  <h3 className="as-used">Join these iconic brands</h3>
                  <ul>
                    {brands.map((brand, index) => (
                      <li key={`brand-${brand.node.label}-${index}`} className={`brand`}>
                        <img src={brand.node.logo} alt={`${brand.node.label} logo`} />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div aria-hidden="true" className="background-media background-media-fixed">
              <img src="/media/home/stories.jpg" className="ObjectFit--cover" alt="" />
            </div>
          </section>
          <section className="row section section-gray-lighest section-all-features">
            <div className="container section-inner text-center">
              <h2>
                <a className="unstyled" href="/features/">
                  More Shorthand features
                </a>
              </h2>

              <div className="features features-cards">
                <ul>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/scrollytelling/">
                      <div className="card-text">
                        <h3>Scrollytelling</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/visual-storytelling/">
                      <div className="card-text">
                        <h3>Visual Storytelling</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/collaboration/">
                      <div className="card-text">
                        <h3>Collaboration</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/customisation/">
                      <div className="card-text">
                        <h3>Customisation & Integration</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/branding/">
                      <div className="card-text">
                        <h3>Themes & Branding</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/sharing-and-analytics/">
                      <div className="card-text">
                        <h3>Sharing & Analytics</h3>
                      </div>
                    </a>
                  </li>
                  {/* <li>
                  <a className="feature-card card-border card-shadow unstyled" href="/features/publishing-and-hosting/">
                    <div className="card-text">
                      <h3>Publishing & Hosting</h3>
                    </div>
                  </a>
                </li> */}
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/support/">
                      <div className="card-text">
                        <h3>Support</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/security/">
                      <div className="card-text">
                        <h3>Security</h3>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <Footer className="mt-0" />
        </main>
      </Layout>
    );
  }
}

export const query = graphql`
  query FeaturesPublishingAndHostingQuery {
    site {
      siteMetadata {
        name
        title
        tagline
        description
      }
    }
    allBrandsJson {
      edges {
        node {
          logo
          name
          label
        }
      }
    }
  }
`;
