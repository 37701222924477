import { StyledLink } from "../Atoms";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

export default function FooterBanner({
  title = "",
  cta = { text: "", link: "" },
}: {
  title: string;
  cta: { text: string; link: string };
}) {
  // const image = getImage(img)
  return (
    <div className="tw-text-center">
      <div
        className="tw-mx-auto tw-w-[750px]
      max-md:tw-w-[100%]
      "
      >
        <StaticImage
          src="../../../static/media/logos/logo-black.svg"
          alt=""
          aria-hidden="true"
          placeholder="blurred"
          width={198}
          height={150}
          className="tw-h-[115px]"
          objectFit="contain"
        />
        <p
          className="tw-h2 tw-my-12 tw-font-semibold
        max-lg:tw-my-8
      
        "
        >
          {title}
        </p>
        <StyledLink cta={{ link: cta.link, text: cta.text }} />
      </div>
    </div>
  );
}
