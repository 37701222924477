import { Enquiry } from "../Enquiry";
import { Theme } from "../NavBar";
import { Desktop } from "../NavBar/Desktop";
import { Mobile } from "../NavBar/Mobile";
import * as styles from "./Header.module.scss";
import * as React from "react";
import * as ReactDOM from "react-dom";

const sharedLeftNavs = [
  {
    title: "Features",
    path: "/features/",
  },
  {
    title: "Inspiration",
    id: "inspiration",
    dropdown: [
      {
        title: "Use Cases",
        description: "Shorthand is perfect for a wide range of content types",
        path: "/use-cases/",
        className: "nav-dropdownItem nav-dropdownItem--01",
      },
      {
        title: "Case Studies",
        description: "Success stories from leading content creators",
        path: "/case-studies/",
        className: "nav-dropdownItem nav-dropdownItem--02",
      },
      {
        title: "Templates",
        description: "Accelerate your visual storytelling",
        path: "/templates/",
      },
      {
        title: "Example stories",
        description: "Be inspired by the best digital content on the web",
        path: "/featured/stories/",
        isExternal: true,
        className: "nav-dropdownItem nav-dropdownItem--03",
      },
    ],
  },
  {
    title: "Learn",
    id: "learn",
    dropdown: [
      {
        title: "The Craft magazine",
        description: "Tips, insights, and inspiration on the craft of digital storytelling",
        path: "/the-craft/",
        isExternal: true,
        className: "nav-dropdownItem nav-dropdownItem--01",
      },
      {
        title: "The Craft newsletter",
        description: "The best stories on the web in your inbox, every other week",
        path: "/the-craft/add-the-craft-to-your-inbox/",
        isExternal: true,
        className: "nav-dropdownItem nav-dropdownItem--02",
      },
      {
        title: "Shorthand Community",
        description: "Connect, learn, and share with an inspiring crowd in Master the Craft",
        path: "https://community.shorthand.com",
        isExternal: true,
        className: "nav-dropdownItem nav-dropdownItem--03",
      },
      {
        title: "Webinars",
        description: "Free introductions to digital storytelling from our expert team",
        path: "/training/",
        className: "nav-dropdownItem nav-dropdownItem--04",
      },

      {
        title: "Help docs",
        description: "Learn how to build and publish stunning stories with Shorthand",
        path: "https://support.shorthand.com",
        isExternal: true,
        className: "nav-dropdownItem nav-dropdownItem--05",
      },
    ],
  },
  {
    title: "Enterprise",
    id: "enterprise",
    dropdown: [
      {
        title: "Overview",
        description: "Learn about the gold standard for mission-critical web content",
        path: "/enterprise/",
        className: "nav-dropdownItem nav-dropdownItem--01",
      },
      {
        title: "Security & compliance",
        description: "Find out how Shorthand protects your content and data",
        path: "/features/security/",
        className: "nav-dropdownItem nav-dropdownItem--02",
      },
      {
        title: "Partners",
        description: "Join our partner program or find a partner to work with",
        path: "/partners/",
        className: "nav-dropdownItem nav-dropdownItem--03",
      },
      {
        title: "Visual content strategy",
        description: "Our expert Customer Success team provides industry-leading support and strategy",
        path: "/features/support/",
        className: "nav-dropdownItem nav-dropdownItem--04",
      },
    ],
  },
  {
    title: "Pricing",
    path: "/pricing/",
  },
];

export const Header = ({ theme = "light-theme" }: Theme): JSX.Element => {
  const [showPortal, setShowPortal] = React.useState(false);

  const sharedClassNames = "navbar-marketing";

  const navs = {
    left: sharedLeftNavs,
    right: [
      {
        title: "Start creating",
        path: "https://app.shorthand.com/signup",
        isExternal: true,
        className: "btn btn-primary btn-sm",
      },
      {
        title: "Contact sales",
        path: "#",
        onClick: () => {
          setShowPortal(true);
        },
        className: "btn btn-secondary btn-inverse btn-sm",
      },
      {
        title: "Sign in",
        path: "/signin",
        isExternal: true,
        className: "btn btn-secondary btn-inverse btn-sm",
      },
    ],
  };

  const portalProps = {
    type: "general-enquiry",
    subject: "general-enquiry",
    onClose: () => setShowPortal(false),
  };

  return (
    <>
      {showPortal && ReactDOM.createPortal(<Enquiry {...portalProps} />, document.getElementById("modal"))}
      <div className={styles.desktop}>
        <Desktop className={`${sharedClassNames}`} brand={{ title: "Shorthand", path: "/" }} navs={navs} theme={theme} />
      </div>
      <div className={styles.mobile}>
        <Mobile className={`${sharedClassNames}`} brand={{ title: "Shorthand", path: "/" }} navs={navs} theme={theme} />
      </div>
    </>
  );
};
