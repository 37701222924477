import { GradientOverlay } from "../ImageGradientOverlay";
import { Link } from "gatsby-link";
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image";
import * as React from "react";

export default function Card({
  title,
  description,
  cta,
  img,
}: {
  readonly title: string;
  readonly description?: string;
  readonly cta: {
    readonly text?: string;
    readonly link: string;
  };
  readonly img: {
    readonly alt: string;
    readonly url: IGatsbyImageData;
  };
}) {
  const linkContent = (
    <>
      <GradientOverlay>
        <GatsbyImage image={getImage(img.url)} alt={img.alt} className="tw-h-[200px] " />
      </GradientOverlay>
      <div className="tw-p-6">
        <h3 className="tw-h3 tw-mb-0 hover:!tw-text-black">{title}</h3>
        {description && <p className="tw-p tw-m-0 tw-mt-3 tw-p-0 tw-font-CircularXXSub !tw-leading-[150%]">{description}</p>}
      </div>
    </>
  );

  if (cta.link.startsWith("http")) {
    return (
      <a
        href={cta.link}
        target="_blank"
        className="tw-relative tw-overflow-hidden tw-rounded-[10px]  !tw-no-underline tw-shadow hover:!tw-text-black hover:tw-opacity-70"
      >
        {linkContent}
      </a>
    );
  }
  return (
    <Link
      to={cta.link}
      className="tw-relative tw-overflow-hidden tw-rounded-[10px]  !tw-no-underline tw-shadow hover:!tw-text-black hover:tw-opacity-70"
    >
      {linkContent}
    </Link>
  );
}
