import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default class FeaturesAmp extends React.Component<any, any> {
  render() {
    const { allBrandsJson } = this.props.data;

    const brands = allBrandsJson.edges;

    return (
      <Layout location={this.props.location}>
        <main id="main" className="marketing-content container-fluid">
          <Helmet
            bodyAttributes={{
              class: "body-shorthand body-marketing body-marketing-secondary body-marketing-features body-light hide-elevio",
            }}
          ></Helmet>
          <div className="row section-title title-light">
            <div className="col-11 col-sm-8  mx-auto section-inner">
              <h1>
                <span className="shorthand-logo dark" aria-hidden="true">
                  Shorthand
                </span>
                AMP
              </h1>
              <h2>Enjoy lightning fast digital stories with our AMP add-on.</h2>
            </div>
          </div>

          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Faster load times</h3>
                  <p>AMP means that pages load quicker — and that means fewer people bounce from your site after clicking.</p>
                  {/* <ul className="features-list">
                  <li>Add social sharing metadata, including social card title, description and image.</li>
                  <li>
                    Support for Twitter and Facebook’s Open Graph standard, supported by all major sharing platforms, including
                    LinkedIn, Teams, and Slack.
                  </li>
                </ul> */}
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/amp-speed.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>
          <section className="row section section-strip section-strip-right">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Better engagement</h3>
                  <p>
                    Shorthand AMP makes it possible to get the best of both worlds. Get the performance benefits of AMP, while
                    maintaining the superior engagement of premium digital stories.
                  </p>
                  {/* <ul className="features-list">
                  <li>Twitter buttons will create a pre-filled tweet to streamline social sharing.</li>
                  <li>Add additional buttons with your theme, with common additions including LinkedIn and Pinterest.</li>
                </ul> */}
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/amp-engage.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>
          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Higher search rankings</h3>
                  <p>
                    Google rewards faster load times with higher search rankings &mdash; which is why AMP-optimised pages do better in
                    search and get more traffic to your site.
                  </p>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/amp-analytics.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-right">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>More conversions</h3>
                  <p>
                    With faster load times, better experience, and mobile-optimisation, AMP stories promise more conversions &mdash;
                    whether your KPI is sales, donations, subscriptions or clicks.
                  </p>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/share-amp.png" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>No developers necessary</h3>
                  <p>
                    Enjoy AMP support out-of-the-box. Get all the benefits of AMP-optimised feature stories, without investing in
                    bespoke, ongoing web development.
                  </p>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/brand-code.png" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section section-dark section-half section-trial !tw-bg-[rgba(249,249,249,0.3)]">
            <div className="container section-inner text-center">
              <h2 className="!tw-text-black">
                Ready to try the digital storytelling platform used by Honda, University of Cambridge, and the BBC?
              </h2>

              <div className="cta-container">
                <a href="https://app.shorthand.com/signup" className="btn btn-lg !tw-bg-black hover:!tw-opacity-70">
                  Start creating
                </a>
              </div>
              <div className="brands">
                <div className="brands-container">
                  <h3 className="as-used">Join these iconic brands</h3>
                  <ul>
                    {brands.map((brand, index) => (
                      <li key={`brand-${brand.node.label}-${index}`} className={`brand`}>
                        <img src={brand.node.logo} alt={`${brand.node.label} logo`} />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div aria-hidden="true" className="background-media background-media-fixed">
              <img src="/media/home/stories.jpg" className="ObjectFit--cover" alt="" />
            </div>
          </section>
          <section className="row section section-gray-lighest section-all-features">
            <div className="container section-inner text-center">
              <h2>
                <a className="unstyled" href="/features/">
                  More Shorthand features
                </a>
              </h2>

              <div className="features features-cards">
                <ul>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/scrollytelling/">
                      <div className="card-text">
                        <h3>Scrollytelling</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/visual-storytelling/">
                      <div className="card-text">
                        <h3>Visual Storytelling</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/collaboration/">
                      <div className="card-text">
                        <h3>Collaboration</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/customisation/">
                      <div className="card-text">
                        <h3>Customisation & Integration</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/branding/">
                      <div className="card-text">
                        <h3>Themes & Branding</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/sharing-and-analytics/">
                      <div className="card-text">
                        <h3>Sharing & Analytics</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/publishing-and-hosting/">
                      <div className="card-text">
                        <h3>Publishing & Hosting</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/support/">
                      <div className="card-text">
                        <h3>Support</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/security/">
                      <div className="card-text">
                        <h3>Security</h3>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <Footer className="mt-0" />
        </main>
      </Layout>
    );
  }
}

export const query = graphql`
  query FeaturesAmpQuery {
    site {
      siteMetadata {
        name
        title
        tagline
        description
      }
    }
    allBrandsJson {
      edges {
        node {
          logo
          name
          label
        }
      }
    }
  }
`;
