import { Enquiry } from "../components/Enquiry";
import { Footer } from "../components/Footer";
import { Layout } from "../components/Layout";
import { G2EasiestSetup, G2HighestUserAdoption, G2Leader } from "../components/SecurityBadges/SecurityBadges";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import * as ReactDOM from "react-dom";
// icon
import { ChevronDown } from "react-feather";
import { Helmet } from "react-helmet";
import { twMerge } from "tailwind-merge";

interface IPartnerProps {
  data: any;
  location: Record<any, string>;
}

interface IPartnerState {
  filter: string;
  activePartners: boolean[];
  activeForm: boolean;
  showPortal: boolean;
}

export default class PartnersView extends React.Component<IPartnerProps, IPartnerState> {
  constructor(props: IPartnerProps, context: any) {
    super(props, context);
    const isActiveForm = false;
    this.state = {
      filter: null,
      showPortal: false,
      activeForm: isActiveForm,
      activePartners: [...Array(7)].map(_ => false),
    };
    this.openContact = this.openContact.bind(this);
  }

  componentDidMount() {
    if (this.state.activeForm) {
      setTimeout(() => this.openContact(), 500);
    }
  }

  openContact() {
    this.setState(() => ({ showPortal: true }));
  }

  renderContactButton() {
    return (
      <div className="plan-cta">
        <button className="btn btn-secondary btn-inverse btn-lg" onClick={this.openContact}>
          Get in touch
        </button>
      </div>
    );
  }
  render() {
    const portalProps = {
      type: "partner-enquiry",
      subject: "partner-enquiry",
      source: "marketing/partners",
      onClose: () => this.setState(() => ({ showPortal: false })),
    };

    return (
      <Layout location={this.props.location} theme="transparent-dark-theme">
        <main id="main" className="marketing-content container-fluid">
          <Helmet
            bodyAttributes={{
              class: "body-shorthand body-marketing body-marketing-secondary  body-marketing-partners hide-elevio",
            }}
          />
          {this.state.showPortal && ReactDOM.createPortal(<Enquiry {...portalProps} />, document.getElementById("modal"))}
          <section className="row section-title">
            <div className="col-12 col-sm-8  mx-auto section-inner">
              <h1>
                <span className="shorthand-logo" aria-hidden="true">
                  Shorthand
                </span>{" "}
                Partner with Shorthand
              </h1>
              <h2>Create stunning digital content for your clients in record time &mdash; and grow your business.</h2>
            </div>

            <div className="col-12 col-sm-11 mx-auto section-inner">
              <ul className="plans flex justify-content-center">
                <li className="plan-wrapper partner-type">
                  <div className="plan">
                    <div className="plan-label">
                      <h3>Agency Partner</h3>
                    </div>
                    <div className="d-flex flex-column justify-content-between">
                      <div className="plan-summary">
                        <p>
                          For agencies of all shapes and sizes — from agencies of record and project-based creative agencies to
                          consultants and service providers.
                        </p>
                      </div>
                      <div className="plan-cta">{this.renderContactButton()}</div>
                    </div>
                  </div>
                </li>
                <li className="plan-wrapper partner-type">
                  <div className="plan">
                    <div className="plan-label">
                      <h3>Product Partner</h3>
                    </div>
                    <div className="d-flex flex-column justify-content-between">
                      <div className="plan-summary">
                        <p>
                          For product teams, developers, content management systems, and integrators. Scale your business by
                          integrating or building custom solutions with the Shorthand Platform.
                        </p>
                      </div>
                      {this.renderContactButton()}
                    </div>
                  </div>
                </li>
                <li className="plan-wrapper partner-type">
                  <div className="plan">
                    <div className="plan-label">
                      <h3>Referral Partner</h3>
                    </div>
                    <div className="d-flex flex-column justify-content-between">
                      <div className="plan-summary">
                        <p>
                          For advisors, freelancers, or influencers. Recommend your colleagues and clients to Shorthand and earn a
                          commission with every successful subscription.
                        </p>
                      </div>
                      {this.renderContactButton()}
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div aria-hidden="true" className="background-media background-media-fixed">
              <StaticImage src="../../static/media/partners/partners-bg.jpg" alt="" aria-hidden="true" />
            </div>
          </section>

          <section className="row section section-strip section-strip-right">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>A true partnership</h3>
                  <p>Our product, resources, and team are here to help you succeed. </p>
                  <ul className="features-list">
                    <li>
                      Get access to a Partner Manager and regional sales reps who will assist you with plan design, positioning, and
                      how to sell Shorthand to your clients.
                    </li>
                    <li>Access a growing library of live training webinars.</li>
                    <li>
                      Get early access to Shorthand’s product roadmap, and be the first to know about the future of immersive digital
                      content.
                    </li>
                    <li>Let us promote you. We’ll showcase your stunning content to our global network of content teams.</li>
                  </ul>
                </div>
              </div>

              <div className="section-strip-col">
                <StaticImage
                  src="../../static/media/partners/partnership.jpg"
                  alt=""
                  aria-hidden="true"
                  imgStyle={{ objectFit: "CONTAIN" }}
                />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-left bg-gray-lightest">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>A world-beating storytelling platform</h3>
                  <p>Create immersive digital stories that your readers will love — code and web-design optional.</p>
                  <ul className="features-list">
                    <li>Build multimedia scrollytelling stories, with animation effects triggered as the reader scrolls.</li>
                    <li>
                      Don’t worry about fonts and formatting — the Shorthand editor will make your copy consistent, on-brand, and
                      responsive.
                    </li>
                    <li>Integrate third party maps, charts, or data visualisations to create a truly interactive story.</li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <StaticImage
                  src="../../static/media/partners/platform.jpg"
                  alt=""
                  aria-hidden="true"
                  imgStyle={{ objectFit: "CONTAIN" }}
                />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-right">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>A proven content solution</h3>
                  <p>
                    Shorthand’s customers include the biggest content creators on the planet, because stories built with Shorthand get
                    better results.
                  </p>
                  <ul className="features-list">
                    <li>Shorthand stories routinely boast better dwell time, scroll depth, click-through-rates, and conversions.</li>
                    <li>
                      Our customers include the BBC, Honda, the UN, the WHO, Adweek, and the University of Houston.{" "}
                      <Link className="btn-link" to="/case-studies/">
                        Check out our growing library of customer stories
                      </Link>
                      .
                    </li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <StaticImage
                  src="../../static/media/case-studies/case-studies-opera-aus.jpg"
                  alt=""
                  aria-hidden="true"
                  imgStyle={{ objectFit: "CONTAIN" }}
                />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-left bg-gray-lightest">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Expert white-glove support</h3>
                  <p>
                    We’ll never leave you hanging. Shorthand’s team will get you up and running quickly, and will give you all the
                    tools and ongoing support you need to delight your clients.
                  </p>
                  <ul className="features-list">
                    <li>
                      We get you set up for a running start, with same day plan activation and {"<"}72 hour custom theme development.
                    </li>
                    <li>
                      Our team of Editorial Success Managers are best in class, and will give you all the support you need to be
                      successful (don’t trust us —{" "}
                      <a
                        className="btn-link"
                        href="https://www.g2.com/products/shorthand/reviews"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        read what our customers say on G2
                      </a>
                      ).
                    </li>
                  </ul>
                  <div className="g2-badge-container g2-badge-container-alt justify-content-center">
                    <a
                      className="g2-badge"
                      title="Shorthand is rated as 'Easiest To Use' on G2"
                      href="https://www.g2.com/products/shorthand/reviews?utm_source=rewards-badge"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <G2HighestUserAdoption width={90} height={116} />
                    </a>

                    <a
                      className="g2-badge"
                      title="Shorthand is a leader in Content Creation on G2"
                      href="https://www.g2.com/products/shorthand/reviews?utm_source=rewards-badge"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <G2Leader width={90} height={116} />
                    </a>

                    <a
                      className="g2-badge"
                      title="Shorthand is rated as having 'Fastest Implementation' on G2"
                      href="https://www.g2.com/products/shorthand/reviews?utm_source=rewards-badge"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <G2EasiestSetup width={90} height={116} />
                    </a>
                  </div>
                </div>
              </div>
              <div className="section-strip-col">
                <StaticImage
                  src="../../static/media/partners/support.jpg"
                  alt=""
                  aria-hidden="true"
                  imgStyle={{ objectFit: "CONTAIN" }}
                />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-right">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Custom plans</h3>
                  <p>We build tailored packages, specified to the needs of your organisation and clients.</p>
                  <ul className="features-list">
                    <li>
                      Shorthand’s expert team will build a custom theme at your direction — with client fonts, colors, styles, default
                      header logos, and more.
                    </li>
                    <li>
                      We offer flexible publishing solutions. Export your stories to S3, embed in your CMS, export a ZIP, or publish
                      directly to your Shorthand subdomain.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <StaticImage
                  src="../../static/media/partners/plans.jpg"
                  alt=""
                  aria-hidden="true"
                  imgStyle={{ objectFit: "CONTAIN" }}
                />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-quote bg-gray-lightest">
            <div className="container container-sm flex-column">
              <div className="section-strip-col-single section-strip-single-quote quote-carousel">
                <div className="section-strip-quote-inner quote-carousel--item">
                  <blockquote>
                    &ldquo;I've often thought, what is it that makes Shorthand different and special? I always come back to the fact
                    that so much good work has been put in by the Shorthand development team on making the tool as good as it is. It's
                    the best there is.&rdquo;
                  </blockquote>

                  <cite>&mdash; Giles Wilson, Founder, Harpoon</cite>
                </div>
              </div>
            </div>
          </section>

          <section className="row section">
            <div className="col-12 col-sm-11 mx-auto text-center">
              <h3>Partner case studies</h3>
              <p className="standfirst">Our amazing partners can help you create stories your audience will love.</p>
            </div>
            <ul className="col-11 flex partner-grid">
              <li className="card card-case-study text-center col-sm-12 col-md agency">
                <Link to="/case-studies/harpoon/" className="bordered unstyled">
                  <StaticImage src="../../static/media/case-studies/case-studies-harpoon-index.jpg" alt="" />
                  <div className="text-container">
                    <h3>Harpoon Productions</h3>
                    <p>
                      The award-winning founder and editor of BBC News Magazine, Giles Wilson, leads this London-based digital agency.
                    </p>
                  </div>
                </Link>
              </li>
              <li className="card card-case-study text-center col-sm-12 col-md agency">
                <a href="/case-studies/bcm-group/" className="bordered unstyled">
                  <StaticImage src="../../static/media/case-studies/case-studies-bcm-index.jpg" alt="" />
                  <div className="text-container">
                    <h3>BCM Group</h3>
                    <p>
                      How does a leading content agency experiment with design, meet tight deadlines, and still amaze their clients?
                    </p>
                  </div>
                </a>
              </li>
              <li className="card card-case-study text-center col-sm-12 col-md agency">
                <a href="/case-studies/bell-media-group/" className="bordered unstyled">
                  <StaticImage src="../../static/media/case-studies/case-studies-bmg-index.png" alt="" />
                  <div className="text-container">
                    <h3>Bell Media Group</h3>
                    <p>
                      How does an ambitious marketing agency use immersive visual storytelling to delight their clients and get
                      excellent results?
                    </p>
                  </div>
                </a>
              </li>
              <li className="card card-case-study text-center col-sm-12 col-md agency">
                <a href="/case-studies/nese-media/" className="bordered unstyled">
                  <StaticImage src="../../static/media/case-studies/case-studies-nese-media-index.jpg" alt="" />
                  <div className="text-container">
                    <h3>Nese Media</h3>
                    <p>How Nese Media uses Shorthand as part of their complete web and content solutions.</p>
                  </div>
                </a>
              </li>
              <li className="card card-case-study text-center col-sm-12 col-md agency">
                <Link to="/case-studies/smarthinking/" className="bordered unstyled">
                  <StaticImage src="../../static/media/case-studies/smarthinking-05.png" alt="" />
                  <div className="text-container">
                    <h3>Smarthinking Inc</h3>
                    <p>How does a brand marketing agency achieve overwhelming success for their clients?</p>
                  </div>
                </Link>
              </li>
            </ul>
          </section>
          <section className="row section bg-gray-lightest">
            <div className="col-12 col-sm-11 mx-auto text-center">
              <h3>Agency Partners</h3>
              <p className="standfirst">
                Our partners include the world's <em>most innovative</em> digital agencies
              </p>
            </div>
            <ul className="col-11 col-sm-10 flex partner-logos mx-auto text-center">
              <li>
                <a href="https://www.harpoon.productions/" target="_blank" rel="noopener noreferrer">
                  <StaticImage src="../../static/media/partners/logo-harpoon.png" alt="Harpoon logo" />
                </a>
              </li>
              <li>
                <a href="https://www.bcm.com.au/" target="_blank" rel="noopener noreferrer">
                  <StaticImage src="../../static/media/partners/logo-bcm.png" alt="BCM Group logo" />
                </a>
              </li>
              <li>
                <a href="https://www.smarthinkinginc.com/" target="_blank" rel="noopener noreferrer">
                  <StaticImage src="../../static/media/partners/logo-smarthinking.png" alt="Smarthinking Inc. logo" />
                </a>
              </li>
              <li>
                <a href="https://wonderly.agency/" target="_blank" rel="noopener noreferrer">
                  <StaticImage src="../../static/media/partners/logo-wonderly.png" alt="Wonderly logo" />
                </a>
              </li>
              <li>
                <a href="https://www.bellmediagrp.com/" target="_blank" rel="noopener noreferrer">
                  <StaticImage src="../../static/media/partners/logo-bmg.png" alt="Bell Media Group logo" />
                </a>
              </li>
              <li>
                <a href="https://www.imageseven.com.au/" target="_blank" rel="noopener noreferrer">
                  <StaticImage src="../../static/media/partners/logo-imageseven.png" alt="imageseven logo" />
                </a>
              </li>

              <li>
                <a href="https://www.bigo.ie/" target="_blank" rel="noopener noreferrer">
                  <StaticImage src="../../static/media/partners/logo-bigo.png" alt="bigO logo" />
                </a>
              </li>
              <li>
                <a href="https://trajectoryco.com/" target="_blank" rel="noopener noreferrer">
                  <StaticImage src="../../static/media/partners/logo-trajectory.png" alt="Trajectory Brands logo" />
                </a>
              </li>

              <li>
                <a href="https://iriscreative.com/" target="_blank" rel="noopener noreferrer">
                  <StaticImage src="../../static/media/partners/logo-iris.png" alt="Iris creative logo" />
                </a>
              </li>

              <li>
                <a href="https://www.nar8tive.com/" target="_blank" rel="noopener noreferrer">
                  <StaticImage src="../../static/media/partners/logo-nar8tive.png" alt="nar8tive logo" />
                </a>
              </li>

              <li>
                <a href="https://scene.nesemedia.no/om-oss/index.html" target="_blank" rel="noopener noreferrer">
                  <StaticImage src="../../static/media/partners/logo-nese.png" alt="Nese Media logo" />
                </a>
              </li>

              <li>
                <a href="https://thinkpublishing.co.uk/" target="_blank" rel="noopener noreferrer">
                  <StaticImage src="../../static/media/partners/logo-think.png" alt="Think Publishing logo" />
                </a>
              </li>
              <li>
                <a href="https://klats-klats.com/" target="_blank" rel="noopener noreferrer">
                  <StaticImage src="../../static/media/partners/logo-klats.png" alt="Klats Klats logo" />
                </a>
              </li>
              <li>
                <a href="https://rostrum.agency/" target="_blank" rel="noopener noreferrer">
                  <StaticImage src="../../static/media/partners/logo-rostrum.png" alt="Rostrum logo" />
                </a>
              </li>
            </ul>
          </section>
          <section className="row section bg-gray">
            <div className="col-12 col-sm-11 mx-auto text-center">
              <h3>Product Partners</h3>
              <p className="standfirst">2000+ integrations &mdash; some of the most popular:</p>
            </div>
            <ul className="col-11 col-sm-10 flex partner-logos partner-logos-sm mx-auto text-center">
              <li>
                <StaticImage src="../../static/media/partners/logo-wordpress.svg" alt="Wordpress logo" />
              </li>
              <li>
                <StaticImage src="../../static/media/partners/logo-aws.svg" alt="AWS logo" />
              </li>
              <li>
                <StaticImage src="../../static/media/partners/logo-drupal.svg" alt="Drupal logo" />
              </li>
              <li>
                <StaticImage src="../../static/media/partners/logo-unsplash.svg" alt="Unsplash logo" />
              </li>
              <li>
                <StaticImage src="../../static/media/partners/acquia.svg" alt="acquia logo" height={80} />
              </li>
              <li>
                <StaticImage src="../../static/media/partners/logo-youtube.svg" alt="Youtube logo" />
              </li>
              <li>
                <StaticImage src="../../static/media/partners/logo-flourish.svg" alt="Flourish logo" />
              </li>
              <li>
                <StaticImage src="../../static/media/partners/logo-mixpanel.svg" alt="Mixpanel logo" />
              </li>
              <li>
                <StaticImage src="../../static/media/partners/logo-canva.svg" alt="Canva logo" />
              </li>
              <li>
                <StaticImage src="../../static/media/partners/logo-spotify.svg" alt="Spotify logo" />
              </li>
              <li>
                <StaticImage src="../../static/media/partners/logo-bi.svg" alt="Microsoft Business Intelligence logo" />
              </li>
              <li>
                <StaticImage src="../../static/media/partners/logo-typeform.svg" alt="Typeform logo" />
              </li>
              <li>
                <StaticImage src="../../static/media/partners/logo-ga.svg" alt="Google Analytics logo" />
              </li>
              <li>
                <StaticImage src="../../static/media/partners/logo-soundcloud.svg" alt="Soundcloud logo" />
              </li>
              <li>
                <StaticImage src="../../static/media/partners/logo-surveymonkey.svg" alt="Survey Monkey logo" />
              </li>
              <li>
                <StaticImage src="../../static/media/partners/logo-tableau.svg" alt="Tableau logo" />
              </li>
              <li>
                <StaticImage src="../../static/media/partners/logo-vimeo.svg" alt="Vimeo logo" />
              </li>
              <li>
                <StaticImage src="../../static/media/partners/logo-commonninja.png" alt="Common Ninja logo" />
              </li>
            </ul>
          </section>
          <section className="section section-reseller row bg-gray-dark">
            <div className="col-12 col-sm-11 mx-auto text-center">
              <h3>Reseller &amp; System Integrator Partners</h3>
              <p className="standfirst">Trusted partners offering security &amp; compliance solutions</p>
            </div>

            <div className="row col-12 col-sm-11 col-xl-9 mx-auto tw-mt-6 max-sm:tw-mt-0">
              <div className="tw-mb-5 tw-grid tw-w-full tw-grid-cols-4 tw-items-start tw-gap-5 max-xl:tw-grid-cols-2 max-sm:tw-grid-cols-1">
                {/* partner block */}
                <div className="tw-rounded-lg tw-bg-text_black">
                  <div className="tw-flex tw-items-center tw-justify-center tw-border-b tw-border-white/10 tw-py-8 max-xl:tw-py-5 ">
                    <a className="" href="https://www.sensisagency.com/government-services" target="_blank" rel="noopener noreferrer">
                      <StaticImage
                        src="../../static/media/partners/logo-sensis-white.png"
                        alt="Sensis logo"
                        imgStyle={{ objectFit: "CONTAIN" }}
                        height={64}
                        layout="fixed"
                      />
                    </a>
                  </div>
                  {/* content */}
                  <div
                    className={twMerge(
                      "tw-border-b tw-border-white/10 tw-p-4",
                      this.state.activePartners[0] ? "tw-block" : "tw-hidden"
                    )}
                  >
                    <div className="">
                      <p className="!tw-mb-0 !tw-font-CircularXXSub !tw-text-base !tw-leading-6">
                        SBA Small Disadvantaged Business (SDB)
                      </p>
                    </div>
                    <div className="mt-4">
                      <p className="!tw-mb-0 !tw-font-CircularXXSub !tw-text-base !tw-font-bold !tw-leading-6">Contract Vehicles:</p>
                      <ul className="!tw-mb-0">
                        <li
                          className="tw-relative !tw-mb-0  
                          !tw-pl-4 !tw-font-CircularXXSub !tw-text-base !tw-leading-6
                        after:tw-absolute after:tw-left-1 after:tw-top-2 after:tw-h-1 after:tw-w-1 after:tw-rounded-full after:tw-bg-white"
                        >
                          GSA Schedule 541 - (AIMS) #GS-07F-0615W
                        </li>
                        <li
                          className="tw-relative !tw-mb-0  
                         !tw-pl-4 !tw-font-CircularXXSub !tw-text-base !tw-leading-6
                       after:tw-absolute after:tw-left-1 after:tw-top-2 after:tw-h-1 after:tw-w-1 after:tw-rounded-full after:tw-bg-white"
                        >
                          GSA 8(a) STARS ll (GWAC) #GS-06F-0985Z
                        </li>
                        <li
                          className="tw-relative !tw-mb-0  
                         !tw-pl-4 !tw-font-CircularXXSub !tw-text-base !tw-leading-6
                       after:tw-absolute after:tw-left-1 after:tw-top-2 after:tw-h-1 after:tw-w-1 after:tw-rounded-full after:tw-bg-white"
                        >
                          CMAS Contract Holder #4-17-03-0542B
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* content */}
                  <button
                    className="tw-w-full tw-py-3 tw-text-center"
                    onClick={() => {
                      let temp = [...this.state.activePartners];
                      temp[0] = !this.state.activePartners[0];
                      this.setState({ activePartners: temp });
                    }}
                  >
                    <p className="!tw-mb-0 tw-flex tw-items-center tw-justify-center tw-gap-2 !tw-font-CircularXXSub !tw-text-base !tw-leading-6 tw-underline">
                      {this.state.activePartners[0] ? <span>Show less</span> : <span>More information</span>}
                      <ChevronDown className={twMerge(this.state.activePartners[0] && "tw-rotate-180")} />
                    </p>
                  </button>
                </div>
                {/* end partner block */}
                {/* partner block */}
                <div className="tw-rounded-lg tw-bg-text_black">
                  <div className="tw-flex tw-items-center tw-justify-center tw-border-b tw-border-white/10 tw-py-8 max-xl:tw-py-5 ">
                    <a className="" href="https://www.shi.com/" target="_blank" rel="noopener noreferrer">
                      <StaticImage
                        src="../../static/media/partners/logo-shi-white.png"
                        alt="SHI logo"
                        imgStyle={{ objectFit: "CONTAIN" }}
                        height={64}
                        layout="fixed"
                      />
                    </a>
                  </div>
                  {/* content */}
                  <div
                    className={twMerge(
                      "tw-border-b tw-border-white/10 tw-p-4",
                      this.state.activePartners[1] ? "tw-block" : "tw-hidden"
                    )}
                  >
                    <div className="">
                      <p className="!tw-mb-0 !tw-font-CircularXXSub !tw-text-base !tw-font-bold !tw-leading-6">Certifications:</p>
                      <ul className="!tw-mb-0">
                        <li
                          className="tw-relative !tw-mb-0  
                          !tw-pl-4 !tw-font-CircularXXSub !tw-text-base !tw-leading-6
                        after:tw-absolute after:tw-left-1 after:tw-top-2 after:tw-h-1 after:tw-w-1 after:tw-rounded-full after:tw-bg-white"
                        >
                          ISO 9001
                        </li>
                      </ul>
                    </div>
                    <div className="mt-4">
                      <p className="!tw-mb-0 !tw-font-CircularXXSub !tw-text-base !tw-font-bold !tw-leading-6">Contract Vehicles:</p>
                      <ul className="!tw-mb-0">
                        <li
                          className="tw-relative !tw-mb-0  
                          !tw-pl-4 !tw-font-CircularXXSub !tw-text-base !tw-leading-6
                        after:tw-absolute after:tw-left-1 after:tw-top-2 after:tw-h-1 after:tw-w-1 after:tw-rounded-full after:tw-bg-white"
                        >
                          Proven systems integrator with end-to-end solutions for every use case
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* content */}
                  <button
                    className="tw-w-full tw-py-3 tw-text-center"
                    onClick={() => {
                      let temp = [...this.state.activePartners];
                      temp[1] = !this.state.activePartners[1];
                      this.setState({ activePartners: temp });
                    }}
                  >
                    <p className="!tw-mb-0 tw-flex tw-items-center tw-justify-center tw-gap-2 !tw-font-CircularXXSub !tw-text-base !tw-leading-6 tw-underline">
                      {this.state.activePartners[1] ? <span>Show less</span> : <span>More information</span>}
                      <ChevronDown className={twMerge(this.state.activePartners[1] && "tw-rotate-180")} />
                    </p>
                  </button>
                </div>
                {/* end partner block */}
                {/* partner block */}
                <div className="tw-rounded-lg tw-bg-text_black">
                  <div className="tw-flex tw-items-center tw-justify-center tw-border-b tw-border-white/10 tw-py-8 max-xl:tw-py-5 ">
                    <a className="" href="https://golden-tech-systems.com/" target="_blank" rel="noopener noreferrer">
                      <StaticImage
                        src="../../static/media/partners/logo-golden-tech-white.png"
                        alt="GoldenTech logo"
                        imgStyle={{ objectFit: "CONTAIN" }}
                        height={64}
                        layout="fixed"
                      />
                    </a>
                  </div>
                  {/* content */}
                  <div
                    className={twMerge(
                      "tw-border-b tw-border-white/10 tw-p-4",
                      this.state.activePartners[2] ? "tw-block" : "tw-hidden"
                    )}
                  >
                    <div className="">
                      <p className="!tw-mb-0 !tw-font-CircularXXSub !tw-text-base !tw-font-bold !tw-leading-6">Certifications:</p>
                      <ul className="!tw-mb-0">
                        <li
                          className="tw-relative !tw-mb-0  
                          !tw-pl-4 !tw-font-CircularXXSub !tw-text-base !tw-leading-6
                        after:tw-absolute after:tw-left-1 after:tw-top-2 after:tw-h-1 after:tw-w-1 after:tw-rounded-full after:tw-bg-white"
                        >
                          CMMI LEV 3 DEV
                        </li>
                        <li
                          className="tw-relative !tw-mb-0  
                         !tw-pl-4 !tw-font-CircularXXSub !tw-text-base !tw-leading-6
                       after:tw-absolute after:tw-left-1 after:tw-top-2 after:tw-h-1 after:tw-w-1 after:tw-rounded-full after:tw-bg-white"
                        >
                          ISO 9001
                        </li>
                        <li
                          className="tw-relative !tw-mb-0  
                         !tw-pl-4 !tw-font-CircularXXSub !tw-text-base !tw-leading-6
                       after:tw-absolute after:tw-left-1 after:tw-top-2 after:tw-h-1 after:tw-w-1 after:tw-rounded-full after:tw-bg-white"
                        >
                          ISO 27001
                        </li>
                        <li
                          className="tw-relative !tw-mb-0  
                         !tw-pl-4 !tw-font-CircularXXSub !tw-text-base !tw-leading-6
                       after:tw-absolute after:tw-left-1 after:tw-top-2 after:tw-h-1 after:tw-w-1 after:tw-rounded-full after:tw-bg-white"
                        >
                          ISO 20000-1:2018
                        </li>
                        <li
                          className="tw-relative !tw-mb-0  
                         !tw-pl-4 !tw-font-CircularXXSub !tw-text-base !tw-leading-6
                       after:tw-absolute after:tw-left-1 after:tw-top-2 after:tw-h-1 after:tw-w-1 after:tw-rounded-full after:tw-bg-white"
                        >
                          Security Clearance (USA)
                        </li>
                      </ul>
                    </div>
                    <div className="mt-4">
                      <p className="!tw-mb-0 !tw-font-CircularXXSub !tw-text-base !tw-font-bold !tw-leading-6">Contract Vehicles:</p>
                      <ul className="!tw-mb-0">
                        <li
                          className="tw-relative !tw-mb-0  
                          !tw-pl-4 !tw-font-CircularXXSub !tw-text-base !tw-leading-6
                        after:tw-absolute after:tw-left-1 after:tw-top-2 after:tw-h-1 after:tw-w-1 after:tw-rounded-full after:tw-bg-white"
                        >
                          SBA 8(a) Direct Award
                        </li>
                        <li
                          className="tw-relative !tw-mb-0  
                         !tw-pl-4 !tw-font-CircularXXSub !tw-text-base !tw-leading-6
                       after:tw-absolute after:tw-left-1 after:tw-top-2 after:tw-h-1 after:tw-w-1 after:tw-rounded-full after:tw-bg-white"
                        >
                          GS IT MAS #47QTCA20D008F
                        </li>
                        <li
                          className="tw-relative !tw-mb-0  
                         !tw-pl-4 !tw-font-CircularXXSub !tw-text-base !tw-leading-6
                       after:tw-absolute after:tw-left-1 after:tw-top-2 after:tw-h-1 after:tw-w-1 after:tw-rounded-full after:tw-bg-white"
                        >
                          GSA STARS3 # 47QTCB21D0208
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* content */}
                  <button
                    className="tw-w-full tw-py-3 tw-text-center"
                    onClick={() => {
                      let temp = [...this.state.activePartners];
                      temp[2] = !this.state.activePartners[2];
                      this.setState({ activePartners: temp });
                    }}
                  >
                    <p className="!tw-mb-0 tw-flex tw-items-center tw-justify-center tw-gap-2 !tw-font-CircularXXSub !tw-text-base !tw-leading-6 tw-underline">
                      {this.state.activePartners[2] ? <span>Show less</span> : <span>More information</span>}
                      <ChevronDown className={twMerge(this.state.activePartners[2] && "tw-rotate-180")} />
                    </p>
                  </button>
                </div>
                {/* end partner block */}
                {/* partner block */}
                <div className="tw-rounded-lg tw-bg-text_black">
                  <div className="tw-flex tw-items-center tw-justify-center tw-border-b tw-border-white/10 tw-py-8 max-xl:tw-py-5 ">
                    <a
                      className=""
                      href="https://www.atea.dk/om-atea/vores-ansvar/compliance/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <StaticImage
                        src="../../static/media/partners/logo-atea-white.png"
                        alt="ATEA logo"
                        imgStyle={{ objectFit: "CONTAIN" }}
                        height={64}
                        layout="fixed"
                      />
                    </a>
                  </div>
                  {/* content */}
                  <div
                    className={twMerge(
                      "tw-border-b tw-border-white/10 tw-p-4",
                      this.state.activePartners[3] ? "tw-block" : "tw-hidden"
                    )}
                  >
                    <div className="">
                      <p className="!tw-mb-0 !tw-font-CircularXXSub !tw-text-base !tw-font-bold !tw-leading-6">Certifications:</p>
                      <ul className="!tw-mb-0">
                        <li
                          className="tw-relative !tw-mb-0  
                          !tw-pl-4 !tw-font-CircularXXSub !tw-text-base !tw-leading-6
                        after:tw-absolute after:tw-left-1 after:tw-top-2 after:tw-h-1 after:tw-w-1 after:tw-rounded-full after:tw-bg-white"
                        >
                          ISO 37001
                        </li>
                      </ul>
                    </div>
                    <div className="mt-4">
                      <p className="!tw-mb-0 !tw-font-CircularXXSub !tw-text-base !tw-font-bold !tw-leading-6">Contract Vehicles:</p>
                      <ul className="!tw-mb-0">
                        <li
                          className="tw-relative !tw-mb-0  
                          !tw-pl-4 !tw-font-CircularXXSub !tw-text-base !tw-leading-6
                        after:tw-absolute after:tw-left-1 after:tw-top-2 after:tw-h-1 after:tw-w-1 after:tw-rounded-full after:tw-bg-white"
                        >
                          A full-service IT infrastructure partner to support your digital transformation
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* content */}
                  <button
                    className="tw-w-full tw-py-3 tw-text-center"
                    onClick={() => {
                      let temp = [...this.state.activePartners];
                      temp[3] = !this.state.activePartners[3];
                      this.setState({ activePartners: temp });
                    }}
                  >
                    <p className="!tw-mb-0 tw-flex tw-items-center tw-justify-center tw-gap-2 !tw-font-CircularXXSub !tw-text-base !tw-leading-6 tw-underline">
                      {this.state.activePartners[3] ? <span>Show less</span> : <span>More information</span>}
                      <ChevronDown className={twMerge(this.state.activePartners[3] && "tw-rotate-180")} />
                    </p>
                  </button>
                </div>
                {/* end partner block */}
              </div>
              <div className="mx-auto tw-grid tw-w-[75%] tw-grid-cols-3 tw-items-start tw-gap-5 max-xl:tw-w-full max-xl:tw-grid-cols-2 max-sm:tw-grid-cols-1">
                {/* partner block */}
                <div className="tw-rounded-lg tw-bg-text_black">
                  <div className="tw-flex tw-items-center tw-justify-center tw-border-b tw-border-white/10 tw-py-8 max-xl:tw-py-5 ">
                    <a className="" href="https://fedresources.com/" target="_blank" rel="noopener noreferrer">
                      <StaticImage
                        src="../../static/media/partners/logo-fed-resources-white.png"
                        alt="fedresources logo"
                        imgStyle={{ objectFit: "CONTAIN" }}
                        height={64}
                        layout="fixed"
                      />
                    </a>
                  </div>
                  {/* content */}
                  <div
                    className={twMerge(
                      "tw-border-b tw-border-white/10 tw-p-4",
                      this.state.activePartners[4] ? "tw-block" : "tw-hidden"
                    )}
                  >
                    <div className="">
                      <p className="!tw-mb-0 !tw-font-CircularXXSub !tw-text-base !tw-font-bold !tw-leading-6">Certifications:</p>
                      <ul className="!tw-mb-0">
                        <li
                          className="tw-relative !tw-mb-0  
                          !tw-pl-4 !tw-font-CircularXXSub !tw-text-base !tw-leading-6
                        after:tw-absolute after:tw-left-1 after:tw-top-2 after:tw-h-1 after:tw-w-1 after:tw-rounded-full after:tw-bg-white"
                        >
                          Security Clearance (USA)
                        </li>
                      </ul>
                    </div>
                    <div className="mt-4">
                      <p className="!tw-mb-0 !tw-font-CircularXXSub !tw-text-base !tw-font-bold !tw-leading-6">Contract Vehicles:</p>
                      <ul className="!tw-mb-0">
                        <li
                          className="tw-relative !tw-mb-0  
                          !tw-pl-4 !tw-font-CircularXXSub !tw-text-base !tw-leading-6
                        after:tw-absolute after:tw-left-1 after:tw-top-2 after:tw-h-1 after:tw-w-1 after:tw-rounded-full after:tw-bg-white"
                        >
                          NASA SEWP V - #NNG15SC61B
                        </li>
                        <li
                          className="tw-relative !tw-mb-0  
                         !tw-pl-4 !tw-font-CircularXXSub !tw-text-base !tw-leading-6
                       after:tw-absolute after:tw-left-1 after:tw-top-2 after:tw-h-1 after:tw-w-1 after:tw-rounded-full after:tw-bg-white"
                        >
                          GSA IT-70 Schedule: GS-35F-0585T
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* content */}
                  <button
                    className="tw-w-full tw-py-3 tw-text-center"
                    onClick={() => {
                      let temp = [...this.state.activePartners];
                      temp[4] = !this.state.activePartners[4];
                      this.setState({ activePartners: temp });
                    }}
                  >
                    <p className="!tw-mb-0 tw-flex tw-items-center tw-justify-center tw-gap-2 !tw-font-CircularXXSub !tw-text-base !tw-leading-6 tw-underline">
                      {this.state.activePartners[4] ? <span>Show less</span> : <span>More information</span>}
                      <ChevronDown className={twMerge(this.state.activePartners[4] && "tw-rotate-180")} />
                    </p>
                  </button>
                </div>
                {/* end partner block */}
                {/* partner block */}
                <div className="tw-rounded-lg tw-bg-text_black">
                  <div className="tw-flex tw-items-center tw-justify-center tw-border-b tw-border-white/10 tw-py-8 max-xl:tw-py-5 ">
                    <a className="" href="https://nachonacho.com/" target="_blank" rel="noopener noreferrer">
                      <StaticImage
                        src="../../static/media/partners/logo-nacho-nacho-white.png"
                        alt="nacho nacho logo"
                        imgStyle={{ objectFit: "CONTAIN" }}
                        height={64}
                        layout="fixed"
                      />
                    </a>
                  </div>
                  {/* content */}
                  <div
                    className={twMerge(
                      "tw-border-b tw-border-white/10 tw-p-4",
                      this.state.activePartners[5] ? "tw-block" : "tw-hidden"
                    )}
                  >
                    <div className="">
                      <p className="!tw-mb-0 !tw-font-CircularXXSub !tw-text-base !tw-leading-6">
                        A Fintech enabled marketplace for B2B SaaS.
                      </p>
                    </div>
                  </div>
                  {/* content */}
                  <button
                    className="tw-w-full tw-py-3 tw-text-center"
                    onClick={() => {
                      let temp = [...this.state.activePartners];
                      temp[5] = !this.state.activePartners[5];
                      this.setState({ activePartners: temp });
                    }}
                  >
                    <p className="!tw-mb-0 tw-flex tw-items-center tw-justify-center tw-gap-2 !tw-font-CircularXXSub !tw-text-base !tw-leading-6 tw-underline">
                      {this.state.activePartners[5] ? <span>Show less</span> : <span>More information</span>}
                      <ChevronDown className={twMerge(this.state.activePartners[5] && "tw-rotate-180")} />
                    </p>
                  </button>
                </div>
                {/* end partner block */}
                {/* partner block */}
                <div className="tw-rounded-lg tw-bg-text_black">
                  <div className="tw-flex tw-items-center tw-justify-center tw-border-b tw-border-white/10 tw-py-8 max-xl:tw-py-5 ">
                    <a className="" href="https://www.sirc.net/" target="_blank" rel="noopener noreferrer">
                      <StaticImage
                        src="../../static/media/partners/logo-sirc.png"
                        alt="sirc logo"
                        imgStyle={{ objectFit: "CONTAIN" }}
                        height={64}
                        layout="fixed"
                      />
                    </a>
                  </div>
                  {/* content */}
                  <div
                    className={twMerge(
                      "tw-border-b tw-border-white/10 tw-p-4",
                      this.state.activePartners[6] ? "tw-block" : "tw-hidden"
                    )}
                  >
                    <div className="">
                      <p className="!tw-mb-0 !tw-font-CircularXXSub !tw-text-base !tw-font-bold !tw-leading-6">Contract Vehicles:</p>
                      <ul className="!tw-mb-0">
                        <li
                          className="tw-relative !tw-mb-0  
                          !tw-pl-4 !tw-font-CircularXXSub !tw-text-base !tw-leading-6
                        after:tw-absolute after:tw-left-1 after:tw-top-2 after:tw-h-1 after:tw-w-1 after:tw-rounded-full after:tw-bg-white"
                        >
                          NASA SEWP
                        </li>
                        <li
                          className="tw-relative !tw-mb-0  
                         !tw-pl-4 !tw-font-CircularXXSub !tw-text-base !tw-leading-6
                       after:tw-absolute after:tw-left-1 after:tw-top-2 after:tw-h-1 after:tw-w-1 after:tw-rounded-full after:tw-bg-white"
                        >
                          Department of Labor Tableau BPA
                        </li>
                        <li
                          className="tw-relative !tw-mb-0  
                         !tw-pl-4 !tw-font-CircularXXSub !tw-text-base !tw-leading-6
                       after:tw-absolute after:tw-left-1 after:tw-top-2 after:tw-h-1 after:tw-w-1 after:tw-rounded-full after:tw-bg-white"
                        >
                          CSOSA/PSA
                        </li>
                        <li
                          className="tw-relative !tw-mb-0  
                         !tw-pl-4 !tw-font-CircularXXSub !tw-text-base !tw-leading-6
                       after:tw-absolute after:tw-left-1 after:tw-top-2 after:tw-h-1 after:tw-w-1 after:tw-rounded-full after:tw-bg-white"
                        >
                          DCSS Contract Number: CW38102 – IT EQUIP
                        </li>
                        <li
                          className="tw-relative !tw-mb-0  
                         !tw-pl-4 !tw-font-CircularXXSub !tw-text-base !tw-leading-6
                       after:tw-absolute after:tw-left-1 after:tw-top-2 after:tw-h-1 after:tw-w-1 after:tw-rounded-full after:tw-bg-white"
                        >
                          DCSS Contract Number: CW44467 – MOBIS
                        </li>
                        <li
                          className="tw-relative !tw-mb-0  
                         !tw-pl-4 !tw-font-CircularXXSub !tw-text-base !tw-leading-6
                       after:tw-absolute after:tw-left-1 after:tw-top-2 after:tw-h-1 after:tw-w-1 after:tw-rounded-full after:tw-bg-white"
                        >
                          DC Health Exchange
                        </li>
                        <li
                          className="tw-relative !tw-mb-0  
                         !tw-pl-4 !tw-font-CircularXXSub !tw-text-base !tw-leading-6
                       after:tw-absolute after:tw-left-1 after:tw-top-2 after:tw-h-1 after:tw-w-1 after:tw-rounded-full after:tw-bg-white"
                        >
                          DLA JETS
                        </li>
                        <li
                          className="tw-relative !tw-mb-0  
                         !tw-pl-4 !tw-font-CircularXXSub !tw-text-base !tw-leading-6
                       after:tw-absolute after:tw-left-1 after:tw-top-2 after:tw-h-1 after:tw-w-1 after:tw-rounded-full after:tw-bg-white"
                        >
                          DOJ BPA
                        </li>
                        <li
                          className="tw-relative !tw-mb-0  
                         !tw-pl-4 !tw-font-CircularXXSub !tw-text-base !tw-leading-6
                       after:tw-absolute after:tw-left-1 after:tw-top-2 after:tw-h-1 after:tw-w-1 after:tw-rounded-full after:tw-bg-white"
                        >
                          FBI IDIQ
                        </li>
                        <li
                          className="tw-relative !tw-mb-0  
                         !tw-pl-4 !tw-font-CircularXXSub !tw-text-base !tw-leading-6
                       after:tw-absolute after:tw-left-1 after:tw-top-2 after:tw-h-1 after:tw-w-1 after:tw-rounded-full after:tw-bg-white"
                        >
                          GSA Schedule IT 70 – GS-35F – 0149N
                        </li>
                        <li
                          className="tw-relative !tw-mb-0  
                         !tw-pl-4 !tw-font-CircularXXSub !tw-text-base !tw-leading-6
                       after:tw-absolute after:tw-left-1 after:tw-top-2 after:tw-h-1 after:tw-w-1 after:tw-rounded-full after:tw-bg-white"
                        >
                          SPAWAR BPA
                        </li>
                        <li
                          className="tw-relative !tw-mb-0  
                         !tw-pl-4 !tw-font-CircularXXSub !tw-text-base !tw-leading-6
                       after:tw-absolute after:tw-left-1 after:tw-top-2 after:tw-h-1 after:tw-w-1 after:tw-rounded-full after:tw-bg-white"
                        >
                          State of Maryland Hardware PA
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* content */}
                  <button
                    className="tw-w-full tw-py-3 tw-text-center"
                    onClick={() => {
                      let temp = [...this.state.activePartners];
                      temp[6] = !this.state.activePartners[6];
                      this.setState({ activePartners: temp });
                    }}
                  >
                    <p className="!tw-mb-0 tw-flex tw-items-center tw-justify-center tw-gap-2 !tw-font-CircularXXSub !tw-text-base !tw-leading-6 tw-underline">
                      {this.state.activePartners[6] ? <span>Show less</span> : <span>More information</span>}
                      <ChevronDown className={twMerge(this.state.activePartners[6] && "tw-rotate-180")} />
                    </p>
                  </button>
                </div>
                {/* end partner block */}
              </div>
            </div>
          </section>
          <section className="row section section-end-cta-dark">
            <div className="container section-inner text-center">
              <h2>Interested in becoming a Shorthand Partner?</h2>
              <p className="standfirst">Get more information about our partner programmes and how we can support your business.</p>
              <ul className="plans flex justify-content-center">
                <li className="plan-wrapper partner-type">
                  <div className="plan">
                    <div className="plan-label">
                      <h3>Agency Partner</h3>
                    </div>
                    <div className="d-flex flex-column justify-content-between">
                      <div className="plan-summary">
                        <p>
                          For agencies of all shapes and sizes — from agencies of record and project-based creative agencies to
                          consultants and service providers.
                        </p>
                      </div>
                      {this.renderContactButton()}
                    </div>
                  </div>
                </li>
                <li className="plan-wrapper partner-type">
                  <div className="plan">
                    <div className="plan-label">
                      <h3>Product Partner</h3>
                    </div>
                    <div className="d-flex flex-column justify-content-between">
                      <div className="plan-summary">
                        <p>
                          For product teams, developers, content management systems, and integrators. Scale your business by
                          integrating or building custom solutions with the Shorthand Platform.
                        </p>
                      </div>
                      {this.renderContactButton()}
                    </div>
                  </div>
                </li>
                <li className="plan-wrapper partner-type">
                  <div className="plan">
                    <div className="plan-label">
                      <h3>Referral Partner</h3>
                    </div>
                    <div className="d-flex flex-column justify-content-between">
                      <div className="plan-summary">
                        <p>
                          For advisors, freelancers, or influencers. Recommend your colleagues and clients to Shorthand and earn a
                          commission with every successful subscription.
                        </p>
                      </div>
                      {this.renderContactButton()}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div aria-hidden="true" className="background-media background-media-fixed">
              <StaticImage src="../../static/media/partners/partners-bg.jpg" alt="" />
            </div>
            <Footer className="footer-dark mt-0" />
          </section>
        </main>
      </Layout>
    );
  }
}
