import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image";
import * as React from "react";

export default function Logos({ title = "", imgs = [] }: { title: string; imgs: { url: IGatsbyImageData; alt: string }[] }) {
  // const image = getImage(img)
  return (
    <div className="tw-rounded-[10px] tw-bg-[#F1F2F2] tw-px-12 tw-py-10   max-sm:tw-px-5">
      <p className="tw-mb-10 tw-text-center tw-text-lg">{title}</p>
      <ul
        className={`tw-grid 
      ${imgs.length === 7 ? "tw-grid-cols-7 tw-gap-x-[6%] max-sm:tw-grid-cols-4" : ""} 
      ${imgs.length === 6 ? "tw-grid-cols-6 tw-gap-x-[8%] max-sm:tw-grid-cols-3" : ""} 
      ${imgs.length === 5 ? "tw-grid-cols-5 tw-gap-x-[8%]  max-sm:tw-grid-cols-3" : ""} 
      ${imgs.length === 4 ? "tw-grid-cols-4 tw-gap-x-[8%]  max-sm:tw-grid-cols-4" : ""} 
       tw-items-center tw-justify-center tw-gap-y-6`}
      >
        {imgs.map((img: any, index: number) => {
          return (
            <li key={index}>
              <GatsbyImage
                image={getImage(img.url)}
                alt={img.alt}
                objectFit="contain"
                className="tw-max-h-[64px] tw-w-auto max-sm:tw-max-h-[30px]"
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}
