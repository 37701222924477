import { IPlan, IPriceStructure } from "./";

// 16/OCT/23 Copied from dylan and is not the single source of truth
export const team: IPlan = {
  id: "team",
  name: "Team",
  description: "Per active user. Minimum of one active user. Includes 3 published stories. Additional published stories: $10/month.",
  chargebee: new Set([
    "team-monthly-aud",
    "team-monthly-usd",
    "team-monthly-eur",
    "team-monthly-gbp",
    "team-annual-aud",
    "team-annual-usd",
    "team-annual-eur",
    "team-annual-gbp",
  ]),
  features: {
    "organisation-manage-publish-configurations": true,
    "organisation-team-leader-view": true,
    "organisation-team-limit": 5,
    "organisation-member-limit": 200,
    "organisation-team-member-limit": 200,
    "organisation-sites": true,
    "page-settings-amp-enabled": false,
    "page-settings-analytics-ga": true,
    "page-settings-override-social-cover-url": true,
    "shorthand-analytics-tracking": true,
    "story-credits-required": false,
    "story-ga": true,
    "themes-demo": false,
    "publish-embed-ui": true,
    "story-comments": true,
    "theme-builder": true,
    "page-settings-password-protect-previews": true,
    "custom-hosting-limit": 3,
    "publish-configurations-download": true,
  },
  marketing: {
    label: "Professional publishing with theme builder",
    className: "plan-team",
    benefits: [
      "Downloadable story archives",
      "Password-protected previews",
      "Google Analytics",
      "Story analytics exports",
      "Basic theme builder",
      "Email support",
    ],
    prices: {
      planPrice: {
        monthly: { USD: 125, AUD: 165, GBP: 100, EUR: 115 },
        yearly: { USD: 1250, AUD: 1650, GBP: 1000, EUR: 1150 },
      },
      userAddonPrice: {
        monthly: [{ quantity: 4, price: { USD: 30, AUD: 39, GBP: 24, EUR: 27 } }],
        yearly: [{ quantity: 4, price: { USD: 300, AUD: 390, GBP: 240, EUR: 270 } }],
      },
      publishAddonPrice: {
        monthly: [
          { quantity: 6, price: { USD: 25, AUD: 33, GBP: 20, EUR: 23 } },
          { quantity: 6, price: { USD: 10, AUD: 13, GBP: 8, EUR: 9 } },
          { quantity: 21, price: { USD: 7.5, AUD: 10, GBP: 6, EUR: 7 } },
        ],
        yearly: [
          { quantity: 5, price: { USD: 250, AUD: 330, GBP: 200, EUR: 230 } },
          { quantity: 6, price: { USD: 100, AUD: 130, GBP: 80, EUR: 90 } },
          { quantity: 21, price: { USD: 75, AUD: 100, GBP: 60, EUR: 70 } },
        ],
      },
    } as IPriceStructure,
  },
};
