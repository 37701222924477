import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { LegalNav } from "../../components/LegalNav";
import { Link } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default class CookiePolicyView extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <main id="main" className="marketing-content container-fluid">
          <Helmet
            bodyAttributes={{
              class: "body-shorthand body-marketing body-marketing-secondary body-light body-marketing-legal hide-elevio",
            }}
          >
            <title>Cookie Policy | Shorthand</title>
            <meta name="description" content="Shorthand terms of service." />
          </Helmet>
          <div className="row section-title title-light">
            <div className="col-12 col-sm-10 col-lg-8 col-xxl-6 mx-auto">
              <h1>
                <span className="shorthand-logo dark" aria-hidden="true">
                  Shorthand
                </span>{" "}
                Cookie Policy
              </h1>
              <p>
                <small className="small-text text-muted">Effective: 24 January 2022</small>
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="d-none d-lg-block col-lg-2">
              <LegalNav />
            </div>
            <div className="col-12 col-sm-10 col-lg-8 col-xxl-6">
              <section>
                <p className="intro">
                  Our online digital content creation and collaboration platform and all associated services{" "}
                  <strong>(Platform)</strong> uses cookies and similar technologies (including tracking pixels) to, for example,
                  distinguish you from other users of the Platform. This helps us to provide you with a good experience when you use
                  our Platform and also allows us to improve the Platform.
                </p>
                <p>
                  In addition, any content created via the Platform may use cookies and similar technologies as a result of the
                  arrangement between us and a customer. This helps us improve the experience of our Customers’ audience
                </p>
                <p>
                  A cookie is a small text file that we send to your web browser to enable certain features of our Platform. Some
                  cookies will be removed automatically when you close your browser. Other cookies will be stored by your browser until
                  they are deleted or until they expire.
                </p>
                <p>The cookies and similar technologies we use fall into the following categories:</p>
                <ul>
                  <li>
                    <strong>Strictly necessary.</strong> These are cookies that are required for the operation of the Platform. They
                    include, for example, cookies that enable you to log into secure areas of the Platform.
                  </li>
                  <li>
                    <strong>Analytical or performance.</strong> These allow us to recognise and count the number of visitors and to see
                    how visitors move around the Platform when they are using it. This helps us to improve the way the Platform works,
                    for example, by ensuring that users are finding what they are looking for easily.
                  </li>
                  <li>
                    <strong>Functionality or preferences.</strong> These are used to recognise you when you return to the Platform.
                    This enables us to personalise our content for you, greet you by name and remember your preferences (for example,
                    your choice of language or region).
                  </li>
                  <li>
                    <strong>Marketing.</strong> These cookies record your visit to the Platform, the pages you have visited and the
                    links you have followed. We will use this information to make the Platform and any advertising that we may display
                    on it more relevant to your interests.
                  </li>
                </ul>
                <p>
                  You can find more information about the individual cookies and any similar technologies we use and the purposes for
                  which we use them in our{" "}
                  <Link className="btn-link" to="/legal/cookie-table/">
                    cookie table
                  </Link>
                  .
                </p>
                <p>
                  You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies.
                  However, if you disable or refuse cookies, please note that some parts of the Platform may become inaccessible or not
                  function properly.
                </p>
              </section>
            </div>
          </div>
          <Footer />
        </main>
      </Layout>
    );
  }
}
