import { Footer } from "../components/Footer";
import { Layout } from "../components/Layout";
import Select from "../components/Select";
import { trackEnquiry, trackEvent } from "../services/Tracking";
import { getAuthCredentials, getAuthToken, getHostname } from "../services/Utils";
import axios from "axios";
import cx from "classnames";
import { Link } from "gatsby";
import * as objectPath from "object-path";
import * as React from "react";
import { Helmet } from "react-helmet";

/*
 * From app/src/Services/Utils/index.ts
 */
const EMAIL_RE =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const isValidEmail = (email: string): boolean => EMAIL_RE.test(email);

const locations = [
  { label: "Americas", value: "americas" },
  { label: "Europe/Middle East/Africa", value: "emea" },
  { label: "Asia Pacific", value: "apac" },
  { label: "Other", value: "other" },
];

const interestedInOptions = [
  {
    label: "plans and pricing",
    value: "plans-and-pricing",
  },
  {
    label: "a demo",
    value: "demo",
  },
  {
    label: "getting help with a technical or customer support issue",
    value: "support",
  },
  { label: "partnership opportunities", value: "partnership" },
  {
    label: "billing and invoicing help",
    value: "billing-and-invoicing",
  },
  {
    label: "other",
    value: "other",
  },
];

interface IProps {
  location: any;
}

export default function EnquiryView(props: IProps): JSX.Element | null {
  const [form, setForm] = React.useState({
    organisationId: "",
    id: "new",
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    position: "",
    number: "",
    hearAbout: "",
    message: "",
    interestedIn: "",
    location: "",
  });

  const [isProcessing, setIsProcessing] = React.useState(false);
  const [hasLoaded, setHasLoaded] = React.useState(false);
  const [hasSubmitted, setHasSubmitted] = React.useState(false);
  const [submitErr, setSubmitErr] = React.useState("");
  const [validEmail, setValidEmail] = React.useState(true);
  React.useEffect(() => {
    const urlParams = new URLSearchParams(props.location.search);
    const interestedInValue = urlParams.get("interest");
    const helpWithValue = urlParams.get("message");

    const interestExist = Object.values(interestedInOptions).some(option => option.value === interestedInValue);
    setForm({
      ...form,
      message: helpWithValue !== null ? helpWithValue : "",
      interestedIn: interestExist ? interestedInValue : "",
    });
  }, []);

  React.useEffect(() => {
    const credentials = getAuthCredentials();

    setHasSubmitted(false);
    setSubmitErr("");

    async function fetchCredentials(): Promise<void> {
      try {
        const res = await axios.get(`${getHostname("api")}/users/${credentials.id}`, {
          headers: {
            Authorization: `Bearer ${getAuthToken()}`,
          },
        });
        const user = objectPath.get(res, "data.data.users.0");
        if (user) {
          setForm({
            ...form,
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
          });
        }
      } catch (err) {
        console.log(err, "err");
      } finally {
        setHasLoaded(true);
      }
    }

    if (credentials) {
      fetchCredentials();
    } else {
      setHasLoaded(true);
    }
  }, []);

  const { organisationId, id, firstName, lastName, email, company, position, number, hearAbout, message, interestedIn, location } =
    form;

  function setSelectValue(name: string, value: string): void {
    setForm({
      ...form,
      [name]: value,
    });
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    /*
     * Remove any errors from the email field while user is typing. This
     * matches the in-app enquiry form behaviour.
     */
    if (name === "email") {
      setValidEmail(true);
    }

    setForm({
      ...form,
      [name]: value,
    });
  }

  /*
   * Validate email when the email input loses focus. This matches the in-app
   * enquiry form behaviour.
   */
  function emailBlur(event: React.ChangeEvent<HTMLInputElement>): void {
    const value = event.target.value;
    setValidEmail(isValidEmail(value));
  }

  async function handleSubmit(e: React.FormEvent): Promise<void> {
    e.preventDefault();
    setIsProcessing(true);

    const subject = "General Enquiry";
    const type = "general-enquiry-learn-more";
    const fields = [
      "id",
      "firstName",
      "lastName",
      "email",
      "company",
      "number",
      "position",
      "hearAbout",
      "message",
      "interestedIn",
      "location",
    ];

    const enquiry = Object.assign({ subject, type }, ...fields.map(prop => ({ [prop]: form[prop] })));
    const uri = `${getHostname("api")}/enquiry/${enquiry.id}`;
    const windowGlobal = typeof window !== "undefined" && window;
    const token = windowGlobal && windowGlobal.localStorage.getItem("token");
    const options: any = {};
    if (token) {
      options.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    const location = locations.find(l => l.value === enquiry.location)?.label ?? "";

    try {
      const res = await axios.post(uri, { ...enquiry, location }, options);
      setHasSubmitted(true);
      setSubmitErr("");
      trackEnquiry({
        email: enquiry.email,
        hearAbout: enquiry.hearAbout,
        firstName: enquiry.firstName,
        lastName: enquiry.lastName,
        company: enquiry.company,
        contactPhone: enquiry.number,
        jobTitle: enquiry.position,
        enquiryType: enquiry.type,
        interestedIn: enquiry.interestedIn,
        text: enquiry.message,
        location,
      });
      trackEvent(enquiry.type, "User");
      return res.data;
    } catch (err) {
      if (err.response) {
        setSubmitErr(err.response.data?.message);
      } else {
        setSubmitErr("There was an error submitting the enquiry");
      }
    } finally {
      setIsProcessing(false);
    }
  }

  const isComplete = interestedIn && firstName && lastName && isValidEmail(email) && hearAbout && location;

  const isEmailErr = (typeof submitErr === "string" && submitErr.toLowerCase().includes("email")) || (email && !validEmail);

  if (!hasLoaded) {
    return null; // need to load user first
  }

  return (
    <Layout location={props.location}>
      <main id="main" className="marketing-content container-fluid">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-light body-marketing-secondary body-marketing-enquiry hide-elevio",
          }}
        />
        <div className="row enquiry-form">
          <div className="col-12 col-lg-5 enquiry-column enquiry-text-column text-center">
            <div className="container-fluid">
              <h3 className="enquiry-text-column-title">
                <div className="shorthand-logo">
                  <img src="/media/logos/logo-white.svg" alt="" />
                </div>
                How can we help?
              </h3>
              <p>
                Contact us to learn more about creating stunning, immersive reading experiences in record time; to subscribe to a
                Shorthand plan; or to discuss partnership opportunities.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-7 enquiry-column enquiry-form-column mx-auto">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 col-sm-8 offset-sm-2 col-md-10 offset-md-1 col-lg-9 col-xxl-8">
                  {hasSubmitted ? (
                    <div className="alert alert-success alert-lg text-center">
                      <i className="fas fa-thumbs-up" style={{ fontSize: "30px" }}></i>
                      <h3 className="alert-title">Thanks for your enquiry {firstName}, we'll be in touch shortly!</h3>
                      <Link to="/" className="btn btn-success">
                        Go to the Shorthand homepage
                      </Link>
                    </div>
                  ) : (
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="interestedIn" className="control-label">
                              I'm interested in:
                            </label>
                            <Select
                              className="Select--single"
                              options={interestedInOptions}
                              inputId="interestedIn"
                              onChange={option => {
                                if (!option) {
                                  return setSelectValue("interestedIn", "");
                                }
                                setSelectValue("interestedIn", option.value);
                              }}
                              isClearable
                              value={interestedInOptions.find(option => option.value === interestedIn)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group is-empty">
                            <label htmlFor="company" className="control-label">
                              I'd like help with: <span>Optional</span>
                            </label>
                            <textarea
                              id="message"
                              name="message"
                              className="form-control"
                              required={false}
                              value={message}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label htmlFor="firstName" className="control-label">
                              First Name
                            </label>
                            <input
                              type="text"
                              id="firstName"
                              name="firstName"
                              className="form-control"
                              value={firstName}
                              onChange={handleChange}
                              placeholder="Paige"
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label htmlFor="lastName" className="control-label">
                              Last Name
                            </label>
                            <input
                              type="text"
                              id="lastName"
                              name="lastName"
                              className="form-control"
                              value={lastName}
                              onChange={handleChange}
                              placeholder="Turner"
                              required={true}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label htmlFor="email" className="control-label">
                              Email
                            </label>
                            <input
                              type="text"
                              id="email"
                              name="email"
                              className={cx("form-control", {
                                "has-error": isEmailErr,
                              })}
                              value={email}
                              onChange={handleChange}
                              onBlur={emailBlur}
                              placeholder="name@company.com"
                              required={true}
                            />
                            {isEmailErr && (
                              <div className="help-block" role="alert">
                                Please enter a valid email address
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group is-empty">
                            <label htmlFor="number" className="control-label">
                              Contact Number <span>Optional</span>
                            </label>
                            <input
                              type="text"
                              id="number"
                              name="number"
                              className="form-control"
                              value={number}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div className="form-group is-empty">
                            <label htmlFor="company" className="control-label">
                              Company Name <span>Optional</span>
                            </label>
                            <input
                              type="text"
                              id="company"
                              name="company"
                              className="form-control"
                              value={company}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group is-empty">
                            <label htmlFor="position" className="control-label">
                              Job Title <span>Optional</span>
                            </label>
                            <input
                              type="text"
                              id="position"
                              name="position"
                              className="form-control"
                              value={position}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="location" className="control-label">
                              Your location
                            </label>
                            <Select
                              className="Select--single"
                              inputId="location"
                              options={locations}
                              isClearable
                              onChange={option => {
                                if (!option) {
                                  return setSelectValue("location", "");
                                }
                                setSelectValue("location", option.value);
                              }}
                              value={locations.find(option => option.value === location)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <div className="form-group is-empty">
                            <label htmlFor="hearAbout" className="control-label">
                              How did you hear about us?
                            </label>
                            <textarea
                              id="hearAbout"
                              name="hearAbout"
                              className="form-control"
                              required={true}
                              value={hearAbout}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 text-center">
                          <button
                            id="send-enquiry"
                            type="submit"
                            onClick={handleSubmit}
                            disabled={!isComplete || isProcessing}
                            className={cx("btn btn-primary", {
                              disabled: !isComplete || isProcessing,
                            })}
                          >
                            Get in touch
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer className="mt-0" />
      </main>
    </Layout>
  );
}
