import { filterListByCurrentIndustryCode } from "../../Utils";
import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { RelatedCaseStudies } from "../../components/RelatedCaseStudies";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default function CaseStudy({ data, location }) {
  return (
    <Layout location={location}>
      <main id="main" className="marketing-content container-fluid">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-secondary body-marketing-case-studies hide-elevio",
          }}
        />
        <div className="row section-title">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto  section-inner">
            <h1>
              <span className="shorthand-logo">Shorthand</span>
              <span className="subsection-label">Case Study:</span>
              MSC
            </h1>
            <h2>How the Marine Stewardship Council Uses Shorthand</h2>
          </div>
          <div aria-hidden="true" className="background-media">
            <img
              src="/media/case-studies/case-studies-msc-title.jpg"
              data-object-fit="cover"
              data-autoscale-content-ratio="1.77777777778"
              alt=""
            />
          </div>
        </div>
        <div className="row section">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto marketing-main-column">
            <h3 className="intro">
              How did a digital cookbook of sustainable recipes get over 1 million pageviews and over 500,000 unique users?
            </h3>
            <div className="inline-image featured">
              <img
                alt="The Blue Cookbook, by The Marine Stewardship Council, renders responsively across all devices"
                src="/media/case-studies/case-studies-msc.jpg"
              />
            </div>
            <p>
              To answer this question, we spoke with Stephanie Poey, Senior Campaigns Manager at the Marine Stewardship Council (MSC).
            </p>
            <p>
              The Marine Stewardship Council is a non-profit organisation on a mission to end overfishing and restore fish stocks for
              future generations.
            </p>
            <p>
              Most famously, MSC is responsible for the “blue fish tick”, which helps consumers make better choices when buying
              seafood.
            </p>
            <p>
              For the last four years, the global MSC team has been using Shorthand to create impactful digital stories, focusing on
              sustainable fishing and consumer guides.
            </p>
            <h3 className="subheading">Impactful digital storytelling</h3>
            <p>
              The Marine Stewardship Council started using Shorthand to create immersive digital stories in 2017, to help commemorate
              their 20th anniversary.
            </p>
            <p>
              As Stephanie explains, “For this 20th anniversary, we were running a big global campaign. We were looking for an
              interactive way to create an emotional link between our results, our progress in 20 years, and our core mission.”
            </p>
            <p>
              The problem for the MSC team, though, was that their website lacked the ability to create these sorts of interactive and
              immersive reading experiences.
            </p>
            <p>
              “Before 2017, we had a really out of date website. Also, because we are an international organisation, our website is
              quite complex. We have scientific content, consumer content, certification content, and more — and multiple regional
              versions in many different languages.”
            </p>
            <p>
              To solve this problem, the team worked on a new website and also turned to Shorthand. The result was{" "}
              <a href="http://20-years.msc.org/" target="_blank" rel="noopener noreferrer" className="btn-link">
                Sustainable Seafood: the first 20 years
              </a>
              , a beautiful longform history of the highs and lows of MSC’s previous twenty years.
            </p>
            <p>They haven’t looked back since.</p>
            {/* <div className="case-study-video-container">
              <video className="case-study-video-inline" muted loop playsInline autoPlay>
                <source src="/media/case-studies/case-studies-msc.mp4" type="video/mp4" />
              </video>
            </div> */}
            <h3 className="subheading">Beautiful stories of sustainability</h3>
            <figure className="block quote-block offset right">
              <blockquote>
                <p>
                  “Shorthand has enabled us to do longform stories justice and the take up across our regional offices has been great.”
                </p>
              </blockquote>
              <figcaption className="quote-cite">Andrew Bowman</figcaption>
            </figure>
            <p>
              From this first campaign, the MSC team started producing more stories on sustainable fisheries, such as this piece on{" "}
              <a href="http://astorias-octopus-stories.msc.org/" target="_blank" rel="noopener noreferrer" className="btn-link">
                the world’s first MSC certified Octopus fishery
              </a>
              .
            </p>
            <p>
              These stories all shared the same high production values, with stunning design, embedded video, and powerful
              testimonials.
            </p>
            <p>
              Other highlights include{" "}
              <a href="http://new-zealand-hoki.msc.org/" target="_blank" rel="noopener noreferrer" className="btn-link">
                New Zealand Hoki
              </a>
              , described as “a little known fish loved by millions around the world.” This story also uses video and photography to
              show the drama of fishing in the wild waters of the South Pacific.
            </p>
            <p>
              Another great example of immersive digital storytelling is{" "}
              <a href="https://fish-for-good-squid-stories.msc.org/" target="_blank" rel="noopener noreferrer" className="btn-link">
                The Cape’s Hope
              </a>
              , on South Africa’s squid fishers. The piece uses illustrations, scrollytelling effects, and — typically for MSC stories
              — hugely evocative photography.
            </p>
            <p>
              Andrew Bowman is the Web Content Manager at MSC. As he explains, Shorthand has also been used by regional MSC teams
              around the world.
            </p>
            <p>
              “When we started using Shorthand it was a great solution to a key organisational need: we want to highlight the stories
              of the people who work in the sustainable seafood industry, from fishers to processors to chefs.
            </p>
            <p>
              “Our website at the time didn’t really give us the ability to present engaging longform content. Shorthand has enabled us
              to do these stories justice and the take up across our regional offices has been great.”
            </p>
            <div className="photo-grid flex featured">
              <img
                alt="A section from one of the Marine Stewardship Council's stories"
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-msc-sml-01.jpg"
              />
              <img
                alt="A section from one of the Marine Stewardship Council's stories"
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-msc-sml-02.jpg"
              />
              <img
                alt="A section from one of the Marine Stewardship Council's stories"
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-msc-sml-03.jpg"
              />
              <img
                alt="A section from one of the Marine Stewardship Council's stories"
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-msc-sml-04.jpg"
              />
            </div>
            <div className="caption">A selection of sections from The msc's stories</div>
            <h3 className="subheading">Collaborative global storytelling</h3>
            <figure className="block quote-block offset left">
              <blockquote>
                <p>
                  “Shorthand stories are really easy to use to duplicate and translate. As an international organisation, this is
                  really, really helpful.”
                </p>
              </blockquote>
              <figcaption className="quote-cite">Stephanie Poey</figcaption>
            </figure>
            <p>
              Shortly after celebrating their twentieth anniversary, the MSC team realised that Shorthand could be used for other kinds
              of content, such as reports.
            </p>
            <p>
              “For example,” Stephanie says, “we're creating global annual reports, impact reports to explain our environmental impact
              as a global organization or tuna reports that we can build with Shorthand.”
            </p>
            <p>
              While some of MSC’s major campaigns are produced and managed by their central team, regional teams also use Shorthand for
              their own local reports or campaigns.
            </p>
            <p>
              Stephanie and the team, for example, are basing their story on another story about tuna produced by the German MSC team.
            </p>
            <p>
              “Sometimes we are developing story templates, and then we’re seeing our regional colleagues in different countries
              getting inspired and creating their own.”
            </p>
            <p>
              As Stephanie explains, “Shorthand stories are really easy to use to duplicate and translate. As an international
              organisation, this is really, really helpful. Shorthand makes it possible to easily duplicate and repurpose content by
              all our teams, which is really important when you need to translate content into multiple languages.”
            </p>
            <h3 className="subheading">Educational storytelling</h3>
            <p>MSC are also using Shorthand to create immersive stories for their education programme.</p>
            <p>
              “We have an education programme for teachers of children from 10 to around 15 years of age. For them, we've developed
              some lesson plans and other educational assets that they can use in the classroom.”
            </p>
            <p>
              One example is{" "}
              <a href="https://dad-fishes-for-the-future.msc.org/" target="_blank" rel="noopener noreferrer" className="btn-link">
                How my dad fishes for the future
              </a>
              , a story based on a short educational film of the same title.
            </p>
            <p>
              The story offers additional explanatory content and guidance for teachers using the film in class. It includes discussion
              questions, links to interactive media, and beautifully presented data on the state of the world’s fishing stocks.
            </p>
            <h3 className="subheading">The Blue Cookbook</h3>
            <figure className="block quote-block offset right">
              <blockquote>
                <p>
                  “We wanted a template that each region could use and adapt. And Shorthand gives us this possibility. And that's
                  really something that is great.”
                </p>
              </blockquote>
              <figcaption className="quote-cite">Stephanie Poey</figcaption>
            </figure>
            <p>
              In 2020, the MSC team embarked on their most ambitious project yet:{" "}
              <a href="https://stories.msc.org/en/blue-cookbook/" target="_blank" rel="noopener noreferrer" className="btn-link">
                The Blue Cookbook
              </a>
              .
            </p>
            <p>
              As Stephanie explains, “the objective of the cookbook was to gather ten ambassadors from around the world, and create a
              platform to highlight their commitment to sustainable fishing and seafood.”
            </p>
            <p>
              Working with digital agency{" "}
              <a href="https://www.harpoon.productions/" target="_blank" className="btn-link" rel="noopener noreferrer">
                Harpoon
              </a>
              , MSC built the first-ever cookbook with Shorthand. Translated into 15 languages, each recipe includes great photography,
              illustrations, videos, and information about the sustainability of each species involved.
            </p>
            <p>
              “We wanted a template that each region could use and adapt — because every region has a different culture and market,”
              Stephanie says. “So they each need this flexibility. And Shorthand gives us this possibility. And that's really something
              that is great.”
            </p>
            <p>
              An example of this flexibility comes from the Australian MSC team. At the time of the launch of the Blue Cookbook, the
              Australian team were working on their annual Christmas campaign.
            </p>
            <p>
              “As part of this campaign, they had lots of recipes from local ambassadors and influencers. So they decided not only to
              duplicate our international cookbook, but to create their own from our template in Shorthand.”
            </p>
            <p>
              This international collaboration meant that the original Blue Cookbook — which initially featured ten chefs selected by
              the global MSC team — now includes more than 29. The Blue Cookbook itself has been recreated 20 different times in 15
              languages.
            </p>
            <p>
              The Blue Cookbook campaign has been an enormous success, with nearly 1 million pageviews, and half a million unique
              users.
            </p>
            <h3 className="subheading">The road ahead</h3>
            <figure className="block quote-block offset right">
              <blockquote>
                <p>
                  “We’ve started to do more campaign-type material recently and our big plan is to integrate Shorthand with our site.”
                </p>
              </blockquote>
              <figcaption className="quote-cite">Andrew Bowman</figcaption>
            </figure>
            <p>Following the success of The Blue Cookbook, the team at MSC plan to use Shorthand for even more types of content.</p>
            <p>
              As Andrew explains, “until now we’ve nearly always used Shorthand for people-focussed stories and linked out to them from
              our main website. We’ve started to do more campaign-type material recently and our big plan is to integrate Shorthand
              with our site.”
            </p>
            <p>
              As part of this work, the team is working to bring the design of their Shorthand stories much more in line with their
              website.
            </p>
            <p>“This will mean we can embed Shorthand seamlessly. We’re going to be using Shorthand a lot more.”</p>
            {/* <div className="related-stories">
              <h4>More from the msc:</h4>
              <ul>
                <li>
                  <a href="https://sites.msc.org.uk/introducingmichaelclark/" target="_blank" rel="noopener noreferrer">
                    Michael Clark: Cosmic Dancer exhibition
                  </a>
                </li>
                <li>
                  <a href="https://sites.msc.org.uk/soundhouse/" target="_blank" rel="noopener noreferrer">
                    Soundhouse
                  </a>
                </li>
                <li>
                  <a href="https://sites.msc.org.uk/justynaszymanska/" target="_blank" rel="noopener noreferrer">
                    Instagrammer in Residence: Justyna Szymańska
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
        <RelatedCaseStudies studies={filterListByCurrentIndustryCode(data, "msc")} />
        <Footer />
      </main>
    </Layout>
  );
}

export const query = graphql`
  query mscQuery {
    allCaseStudiesJson {
      edges {
        node {
          jsonId
          image
          industry
          industryCode
          organisation
          summary
        }
      }
    }
  }
`;
