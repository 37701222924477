import * as colorStyles from "../PricingPlan.module.scss";
import * as styles from "./Byline.module.scss";
import * as React from "react";

let classNames = require("classnames");

interface BylineProps {
  text: string;
  theme?: "light" | "dark";
}

export const Byline = (props: BylineProps): JSX.Element => {
  return (
    <span
      className={classNames(
        styles.byLine,
        { [colorStyles.planDarkByline]: props.theme === "dark" },
        { [colorStyles.planLightByline]: props.theme === "light" }
      )}
    >
      {props.text}
    </span>
  );
};
