import * as React from "react";

type CtaCellProps = {
  handleFunction: () => void;
  ctaText: string;
};

export const CtaCell = (props: CtaCellProps): JSX.Element => {
  const { handleFunction, ctaText } = props;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        paddingTop: "24px",
      }}
    >
      <button
        className="btn btn-secondary btn-inverse tw-mt-8 !tw-w-full !tw-rounded-xl !tw-px-4 !tw-py-3 !tw-text-base !tw-text-white  max-md-alt:!tw-text-sm"
        onClick={handleFunction}
      >
        {ctaText}
      </button>
    </div>
  );
};
