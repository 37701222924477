// extracted by mini-css-extract-plugin
export var btnPrimary = "PricingPlan-module--btn-primary--86612";
export var btnSecondary = "PricingPlan-module--btn-secondary--3868b";
export var btnWhite = "PricingPlan-module--btn-white--cbd5c";
export var plan = "PricingPlan-module--plan--60564";
export var planButton = "PricingPlan-module--planButton--791ca";
export var planDark = "PricingPlan-module--plan--dark--f440d";
export var planDarkByline = "PricingPlan-module--plan--dark--byline--9008a";
export var planDarkDescription = "PricingPlan-module--plan--dark--description--3320d";
export var planDarkFeaturesListItem = "PricingPlan-module--plan--dark--features--listItem--79812";
export var planDarkFeaturesTitle = "PricingPlan-module--plan--dark--features--title--3812d";
export var planDarkPayCycle = "PricingPlan-module--plan--dark--payCycle--6b29a";
export var planDarkPerStoryPrice = "PricingPlan-module--plan--dark--perStoryPrice--44eec";
export var planDarkPrice = "PricingPlan-module--plan--dark--price--813b1";
export var planDarkSubTitle = "PricingPlan-module--plan--dark--subTitle--d07dc";
export var planDarkTitle = "PricingPlan-module--plan--dark--title--462f7";
export var planLight = "PricingPlan-module--plan--light--a2a6b";
export var planLightByline = "PricingPlan-module--plan--light--byline--93c6d";
export var planLightDescription = "PricingPlan-module--plan--light--description--f8aab";
export var planLightFeaturesListItem = "PricingPlan-module--plan--light--features--listItem--fab37";
export var planLightFeaturesTitle = "PricingPlan-module--plan--light--features--title--ed439";
export var planLightPayCycle = "PricingPlan-module--plan--light--payCycle--de6bb";
export var planLightPerStoryPrice = "PricingPlan-module--plan--light--perStoryPrice--b12cd";
export var planLightPrice = "PricingPlan-module--plan--light--price--cb4fb";
export var planLightSubTitle = "PricingPlan-module--plan--light--subTitle--59d81";
export var planLightTitle = "PricingPlan-module--plan--light--title--6e5f7";
export var subTitle = "PricingPlan-module--subTitle--7e8c0";