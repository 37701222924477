import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { LegalNav } from "../../components/LegalNav";
import * as React from "react";
import { Helmet } from "react-helmet";

export default class CopyrightAndTrademarkPolicyView extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <main id="main" className="marketing-content container-fluid">
          <Helmet
            bodyAttributes={{
              class: "body-shorthand body-marketing body-marketing-secondary body-light body-marketing-legal hide-elevio",
            }}
          >
            <title>Copyright and Trademark Policy | Shorthand</title>
            <meta name="description" content="Shorthand terms of service." />
          </Helmet>
          <div className="row section-title title-light">
            <div className="col-12 col-sm-10 col-lg-8 col-xxl-6 mx-auto">
              <h1>
                <span className="shorthand-logo dark" aria-hidden="true">
                  Shorthand
                </span>{" "}
                Copyright and Trademark Policy
              </h1>
              <p>
                <small className="small-text text-muted">Effective: 24 January 2022</small>
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="d-none d-lg-block col-lg-2">
              <LegalNav />
            </div>
            <div className="col-12 col-sm-10 col-lg-8 col-xxl-6">
              <section>
                <p className="intro">
                  Shorthand Pty Ltd ABN 88 162 625 470 (we, us or our) respects the rights of copyright and trademark holders, as
                  described in this policy. We take the intellectual property rights of others seriously and require our Customers and
                  their Users to do the same.
                </p>
              </section>
              <section>
                <h2>Copyright</h2>
                <p>
                  We do not permit copyright infringing activities on our online platform and all associated services (our “
                  <strong>Platform</strong>”). We will remove a party’s data or content from the Platform if properly notified that
                  such data or content infringes another party’s copyright rights. We reserve the right to terminate Your account if
                  you are suspected of infringing copyright without further notice, at our sole discretion.
                </p>
                <p>
                  If you believe that any content on our Platform violates your copyright, you should notify us in writing and ensure
                  your notice includes the following:
                  <ol className="letters">
                    <li>
                      A description of copyrighted work that you believe is being infringed, or, if multiple copyrighted works are
                      covered by the notice, a representative list of such works.
                    </li>
                    <li>
                      A description of the item that you think is infringing and which is to be removed or access to which is to be
                      disabled, and include sufficient information about where the material is located (including which website) so
                      that we can find it (such as the item’s URL).
                    </li>
                    <li>Your address, telephone number, and/or email address.</li>
                    <li>
                      The following statement: “I believe in good faith that the use of the copyrighted material I am complaining about
                      is not authorised by the copyright owner, its agent or the law (e.g. as a fair use)”.
                    </li>
                    <li>
                      The following statement: “The information in this notice is accurate and, under penalty of perjury, I am the
                      owner, or authorised to act on behalf of the owner, of the copyright or of an exclusive right that is allegedly
                      infringed”.
                    </li>
                    <li>Your physical or electronic signature.</li>
                  </ol>
                </p>
                <p>
                  We will promptly notify the alleged infringer that you have claimed ownership of the rights in this content and that
                  we have complied with your takedown notice for the content.
                </p>
                <p>Our designated copyright agent to receive such claims can be reached as follows:</p>
                <h3>By post:</h3>
                <p>
                  Attn: Copyright Agent
                  <br />
                  PO Box 2151
                  <br />
                  New Farm, QLD, 4005 <br /> Australia
                </p>
                <h3>By phone:</h3>
                <p>(07) 3185 4080</p>
                <h3>By email:</h3>
                <p>
                  <a className="btn-link" href="mailto:legal@shorthand.com">
                    legal@shorthand.com
                  </a>
                </p>
              </section>

              <section>
                <h2>Trademark</h2>
                <p>
                  If you are a trademark owner and you believe in good faith that any content on our Platform infringes on your
                  trademark rights, please inform us in writing at the contact details set out above. Your notice must include:
                </p>

                <ol className="letters">
                  <li>
                    Identification of the trademark(s) claimed to have been infringed, and, if registered with the United States Patent
                    and Trademark Office or similar foreign entity, the registration number of the mark(s).
                  </li>
                  <li>
                    Identification of the material claimed to be infringing and information sufficient to permit us to locate the
                    material, such as the specific URL where the trademark appears on the Platform.
                  </li>
                  <li>
                    A statement that the complaining party has a good faith belief that use of the trademark in the manner complained
                    of is an infringement of the rights granted under United States or foreign trademark law.
                  </li>
                  <li>
                    A statement that the information in the letter is accurate, and under penalty of perjury, that the complaining
                    party is authorised to act on behalf of the owner of the allegedly infringed trademark.
                  </li>
                  <li>
                    A physical or electronic signature of a person authorised to act on behalf of the owner of the trademark that is
                    allegedly infringed.
                  </li>
                </ol>

                <p>
                  Once you submit the report to us, our team will begin a brief investigation and will take action that it deems
                  appropriate under the circumstances. If more information is needed from you, we will reach out via email.
                </p>
                <p>
                  Please note that we are not in a position to adjudicate complicated trademark disputes between third parties.
                  Therefore, we are not in a position to act on reports that require a complex legal analysis or factual investigation.
                  In those instances, we encourage you to contact the third party directly to try and resolve the matter.
                </p>
                <p>
                  If we decide to take down content in response to your report, please note that we will provide your report and
                  contact information to the affected party, who may contact you directly regarding the matter.
                </p>
              </section>
            </div>
          </div>
          <Footer />
        </main>
      </Layout>
    );
  }
}
