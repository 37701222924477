import { Enquiry } from "../components/Enquiry";
import { Footer } from "../components/Footer";
import { Layout } from "../components/Layout";
import { BrandsTrust } from "../components/Sections/Brandstrust";
import { G2EasiestToDoBusiness, G2HighestUserAdoption, G2Leader } from "../components/SecurityBadges/SecurityBadges";
import { Link, graphql } from "gatsby";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Helmet } from "react-helmet";

interface IProps {
  data: any;
  location: any;
}

export default class Enterprise extends React.Component<IProps, {}> {
  state = { showPortal: false };
  private window: any = typeof window !== "undefined" && window;

  constructor(props: IProps, context: any) {
    super(props, context);
    this.openContact = this.openContact.bind(this);
  }

  openContact() {
    this.setState(() => ({ showPortal: true }));
  }

  render() {
    const portalProps = {
      type: "general-enquiry",
      subject: "general-enquiry",
      source: "marketing/enterprise",
      onClose: () => this.setState(() => ({ showPortal: false })),
    };

    const { allEnterpriseFeaturedStoriesJson } = this.props.data;
    const featuredStories = allEnterpriseFeaturedStoriesJson.nodes;

    return (
      <Layout location={this.props.location}>
        <main id="main" className="marketing-content home">
          <Helmet
            bodyAttributes={{
              class: "body-shorthand body-marketing body-marketing-home body-marketing-enterprise body-light hide-elevio",
            }}
          />
          {this.state.showPortal && ReactDOM.createPortal(<Enquiry {...portalProps} />, document.getElementById("modal"))}

          <section className="section section-title ">
            <div className=" container tw-relative  tw-z-10 tw-flex tw-items-center tw-justify-center tw-pt-[126px] max-xl:!tw-min-h-0 max-lg:tw-pt-[32px]">
              <div className="section-wrapper text-center">
                <StaticImage
                  src="../../static/media/logos/logo-black.svg"
                  alt=""
                  aria-hidden="true"
                  placeholder="blurred"
                  width={198}
                  height={150}
                  className="tw-mb-8 !tw-hidden tw-h-[65px] max-lg:!tw-block"
                  objectFit="contain"
                />
                <h1 className="!tw-text-[72px] !tw-font-semibold !tw-leading-[110%] !tw-text-tones_darkest_grey max-md:!tw-text-[65px] max-sm:!tw-text-[36px] min-xl:!tw-text-[72px] min-lg:!tw-text-[66px]">
                  Stunning <span className="tw-font-PassengerSerif tw-italic">mission-critical</span> content at scale
                </h1>
                <p>
                  Shorthand keeps your production values high and production overheads low across your most important stakeholder
                  communications and interactive stories.
                </p>
                <div className="cta-container">
                  <a
                    className="btn btn-primary btn-lg"
                    title="Editor overview video"
                    href="https://www.youtube.com/watch_popup?v=mh1C-QhOBGQ&vq=hd1080&autoplay=1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Watch a demo
                  </a>
                  <button className="btn btn-secondary btn-lg" onClick={this.openContact}>
                    Contact sales
                  </button>
                </div>
                <div className="tw-mb-20 max-sm:!tw-mb-12 min-xxxxl:!tw-mb-40">
                  <BrandsTrust />
                </div>
              </div>

              <div className="g2-badge-container g2-badge-container-alt  tw-bottom-[-2.5em] tw-justify-center">
                <a
                  className="g2-badge"
                  title="G2 badge — Shorthand is a 'leader in Content Creation' on G2"
                  href="https://www.g2.com/products/shorthand/reviews?utm_source=rewards-badge"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <G2Leader width={90} height={116} />
                </a>
                <a
                  className="g2-badge"
                  title="G2 badge — Shorthand is rated as 'Easiest To Do Business With' on G2"
                  href="https://www.g2.com/products/shorthand/reviews?utm_source=rewards-badge"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <G2EasiestToDoBusiness width={90} height={116} />
                </a>
                <a
                  className="g2-badge"
                  title="G2 badge — Shorthand is rated as having 'Fastest Implementation' on G2"
                  href="https://www.g2.com/products/shorthand/reviews?utm_source=rewards-badge"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <G2HighestUserAdoption width={90} height={116} />
                </a>
              </div>
            </div>

            <div aria-hidden="true" className="background-media">
              <video
                id="video-top-ls"
                className="section-background-video title-ls"
                autoPlay={true}
                loop={true}
                muted={true}
                playsInline={true}
                poster={"/media/home/stories.jpg"}
                width="1920"
                height="1080"
              >
                <source src="/media/home/stories.webm" type="video/webm" />
                <source src="/media/home/stories.mp4" type="video/mp4" />
              </video>
              <div className="background-overlay" />
            </div>
          </section>

          <section className="section section-features bg-gray-lightest">
            <div className="container">
              <div className="features features-cards">
                <ul>
                  <li>
                    <div
                      className="card-border-top"
                      data-sal="slide-up"
                      data-sal-duration="500"
                      data-sal-delay="100"
                      data-sal-easing="ease-out"
                    >
                      <h4>On-domain &amp; on-brand</h4>
                      <p>
                        Access our powerful publishing options to publish to your primary domain, leveraging your existing domain
                        authority to increase visibility of your public Shorthand stories. Use our simple theme builder or get in touch
                        to have a pixel-perfect, professionally hand-crafted theme made specially for you.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div
                      className="card-border-top"
                      data-sal="slide-up"
                      data-sal-duration="500"
                      data-sal-delay="300"
                      data-sal-easing="ease-out"
                    >
                      <h4>Reduced reliance on technical resources</h4>
                      <p>
                        Shorthand frees up your web designers and developers to work on other projects that can't be done out of the
                        box. Shorthand enables you to work at a higher level than web design and development tools, delivering the
                        emotion and critical information you need to connect with your audience and other stakeholders.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div
                      className="card-border-top"
                      data-sal="slide-up"
                      data-sal-duration="500"
                      data-sal-delay="500"
                      data-sal-easing="ease-out"
                    >
                      <h4>Rock-solid security &amp; compliance</h4>
                      <p>
                        Shorthand is <Link to="/features/security/">enterprise-ready</Link>, with ISO27001 certification, SOC2
                        compliance and authentication options including SSO, SAML and SCIM. Shorthand is trusted by the largest, most
                        security conscious organizations on the planet, including governments and those in highly regulated industries
                        like financial services.
                      </p>
                      <div className="security-badges">
                        <a
                          href="https://cvs.babcert.com/babcert.asp?c=234513&amp;v=53r3k1irp2"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <StaticImage
                            src="../../static/media/features/badge-iso27001.png"
                            alt="ISO27001 badge"
                            height={80}
                            width={80}
                            placeholder="blurred"
                            layout="fixed"
                            className="tw-mr-5 [&_img]:!tw-m-0 [&_img]:!tw-max-w-[none]"
                          />
                        </a>
                        <StaticImage
                          src="../../static/media/features/badge-soc2.png"
                          alt="SOC2 badge"
                          width={80}
                          height={80}
                          placeholder="blurred"
                          layout="fixed"
                          className="[&_img]:!tw-m-0 [&_img]:!tw-max-w-[none]"
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <section className="section section-scale">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12 col-md-6">
                  <div className="featureImg--container featureImg--honda">
                    <StaticImage src="../../static/media/home/tablet.png" alt="" aria-hidden="true" />

                    <div className="featureImg--video">
                      <video
                        id="video-top-ls"
                        className=""
                        autoPlay={true}
                        loop={true}
                        muted={true}
                        playsInline={true}
                        poster={"/media/enterprise/honda-poster.jpg"}
                        width="600"
                        height="840"
                      >
                        <source src="/media/enterprise/honda.webm" type="video/webm" />
                        <source src="/media/enterprise/honda.mp4" type="video/mp4" />
                      </video>
                    </div>
                  </div>
                </div>
                <div
                  className="col-12 col-md-6"
                  data-sal="slide-up"
                  data-sal-duration="500"
                  data-sal-delay="100"
                  data-sal-easing="ease-out"
                >
                  <h3>High-end visual communications at scale</h3>
                  <p className="standfirst">
                    Every team in every function of your organization needs to communicate regularly with internal and external
                    stakeholders. It can seem impossible to enforce quality and consistency across all of that content.
                  </p>
                  <p className="standfirst">
                    From annual reports to high-frequency feature stories, Shorthand scales across your organisation, putting the power
                    of our extreme web innovations into the hands of every team that has important information to share, while ensuring
                    your brand is represented consistently everywhere.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="section section-results bg-gray-lightest">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="section-results--screens">
                    <div
                      className="featureImg--container featureImg--container-tablet"
                      data-sal="slide-up"
                      data-sal-duration="500"
                      data-sal-delay="100"
                      data-sal-easing="ease-out"
                    >
                      <picture>
                        <source srcSet="/media/enterprise/tablet.webp" type="image/webp" />
                        <source srcSet="/media/enterprise/tablet.png" type="image/png" />
                        <img className="featureImg" src="/media/home/tablet.png" alt="" aria-hidden="true" />
                      </picture>
                      <div className="featureImg--video">
                        <video
                          id="video-top-ls"
                          className=""
                          autoPlay={true}
                          loop={true}
                          muted={true}
                          playsInline={true}
                          width="420"
                          height="600"
                        >
                          <source src="/media/enterprise/results-tablet.webm" type="video/webm" />
                          <source src="/media/enterprise/results-tablet.mp4" type="video/mp4" />
                        </video>
                      </div>
                    </div>
                    <div
                      className="featureImg--container featureImg--container-phone"
                      data-sal="slide-up"
                      data-sal-duration="500"
                      data-sal-delay="300"
                      data-sal-easing="ease-out"
                    >
                      <picture>
                        <source srcSet="/media/enterprise/phone.webp" type="image/webp" />
                        <source srcSet="/media/enterprise/phone.png" type="image/png" />
                        <img src="/media/enterprise/phone.png" alt="" aria-hidden="true" />
                      </picture>
                      <div className="featureImg--video">
                        <video
                          id="video-top-ls"
                          className=""
                          autoPlay={true}
                          loop={true}
                          muted={true}
                          playsInline={true}
                          width="280"
                          height="550"
                        >
                          <source src="/media/enterprise/results-phone.webm" type="video/webm" />
                          <source src="/media/enterprise/results-phone.mp4" type="video/mp4" />
                        </video>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex col-12 col-md-6 align-items-center">
                  <div>
                    <h3>Move the metrics that matter</h3>
                    <p className="standfirst">
                      Your audience wants immersive, cinematic experiences — not static PDFs or boring posts from your clunky CMS. You
                      want to keep production values high and production costs low.
                    </p>
                    <p className="standfirst">
                      With Shorthand, create truly stunning digital magazines, reports, feature stories, content hubs, memos, and more.
                      Delight your readers and your KPIs, whatever your metric.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section section-brands">
            <div className="container text-center">
              <div data-sal="slide-up" data-sal-duration="500" data-sal-delay="100" data-sal-easing="ease-out">
                <h3>Governments and enterprises everywhere trust Shorthand</h3>
                <p className="standfirst">The biggest brands communicate courageously with Shorthand. Come join them!</p>
              </div>
              <div className="brands-list">
                <ul>
                  <li>
                    <StaticImage
                      src="../../static/media/logos/clients/lg-adweek.png"
                      loading="lazy"
                      width={348}
                      height={241}
                      placeholder="blurred"
                      alt="Adweek logo"
                    />
                  </li>
                  <li>
                    <StaticImage
                      src="../../static/media/logos/clients/lg-bbc.png"
                      loading="lazy"
                      width={348}
                      height={241}
                      placeholder="blurred"
                      alt="BBC logo"
                    />
                  </li>
                  <li>
                    <StaticImage
                      src="../../static/media/logos/clients/lg-dow-jones.png"
                      loading="lazy"
                      width={348}
                      height={241}
                      placeholder="blurred"
                      alt="Dow Jones logo"
                    />
                  </li>
                  <li>
                    <StaticImage
                      src="../../static/media/logos/clients/lg-sky.png"
                      loading="lazy"
                      width={348}
                      height={241}
                      placeholder="blurred"
                      alt="Sky News logo"
                    />
                  </li>
                  <li className="hide-sm-pt">
                    <StaticImage
                      src="../../static/media/logos/clients/lg-bi.png"
                      loading="lazy"
                      width={348}
                      height={241}
                      placeholder="blurred"
                      alt="Business Insider logo"
                    />
                  </li>
                  <li>
                    <StaticImage
                      src="../../static/media/logos/clients/lg-uni-houston.png"
                      loading="lazy"
                      width={348}
                      height={241}
                      placeholder="blurred"
                      alt="University of Houston logo"
                    />
                  </li>
                  <li className="hide-sm-pt">
                    <StaticImage
                      src="../../static/media/logos/clients/lg-uni-queensland.png"
                      loading="lazy"
                      width={348}
                      height={241}
                      placeholder="blurred"
                      alt="University of Queensland logo"
                    />
                  </li>
                  <li className="hide-sm-pt">
                    <StaticImage
                      src="../../static/media/logos/clients/lg-tripadvisor.png"
                      loading="lazy"
                      width={348}
                      height={241}
                      placeholder="blurred"
                      alt="TripAdvisor"
                    />
                  </li>
                  <li>
                    <StaticImage
                      src="../../static/media/logos/clients/lg-salesforce.png"
                      loading="lazy"
                      width={348}
                      height={241}
                      placeholder="blurred"
                      alt="Salesforce logo"
                    />
                  </li>
                  <li className="hide-sm-pt">
                    <StaticImage
                      src="../../static/media/logos/clients/lg-save-the-children.png"
                      loading="lazy"
                      width={348}
                      height={241}
                      placeholder="blurred"
                      alt="Save The Children logo"
                    />
                  </li>

                  <li>
                    <StaticImage
                      src="../../static/media/logos/clients/lg-undp.png"
                      loading="lazy"
                      width={348}
                      height={241}
                      placeholder="blurred"
                      alt="UNDP logo"
                    />
                  </li>
                  <li className="hide-sm-pt">
                    <StaticImage
                      src="../../static/media/logos/clients/lg-unicef.png"
                      loading="lazy"
                      width={348}
                      height={241}
                      placeholder="blurred"
                      alt="UNICEF logo"
                    />
                  </li>
                  <li>
                    <StaticImage
                      src="../../static/media/logos/clients/lg-wv.png"
                      loading="lazy"
                      width={348}
                      height={241}
                      placeholder="blurred"
                      alt="World Vision logo"
                    />
                  </li>
                  <li className="hide-sm-pt">
                    <StaticImage
                      src="../../static/media/logos/clients/lg-uk-parliament.png"
                      loading="lazy"
                      width={348}
                      height={241}
                      placeholder="blurred"
                      alt="UK Parliament logo"
                    />
                  </li>
                  <li className="hide-sm-pt">
                    <StaticImage
                      src="../../static/media/logos/clients/lg-european.png"
                      loading="lazy"
                      width={348}
                      height={241}
                      placeholder="blurred"
                      alt="European Council logo"
                    />
                  </li>
                  <li className="hide-sm-pt">
                    <StaticImage
                      src="../../static/media/logos/clients/lg-oxford.png"
                      loading="lazy"
                      width={348}
                      height={241}
                      placeholder="blurred"
                      alt="University of Oxford logo"
                    />
                  </li>
                  <li>
                    <StaticImage
                      src="../../static/media/logos/clients/lg-who.png"
                      loading="lazy"
                      width={348}
                      height={241}
                      placeholder="blurred"
                      alt="World Health Organisation logo"
                    />
                  </li>
                  <li>
                    <StaticImage
                      src="../../static/media/logos/clients/lg-honda.png"
                      loading="lazy"
                      width={348}
                      height={241}
                      placeholder="blurred"
                      alt="Honda logo"
                    />
                  </li>
                  <li>
                    <StaticImage
                      src="../../static/media/logos/clients/lg-relx.png"
                      loading="lazy"
                      width={348}
                      height={241}
                      placeholder="blurred"
                      alt="RELX logo"
                    />
                  </li>
                  <li>
                    <StaticImage
                      src="../../static/media/logos/clients/lg-penguin.png"
                      loading="lazy"
                      width={348}
                      height={241}
                      placeholder="blurred"
                      alt="Penguin logo"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <section className="section section-speed bg-gray-lightest">
            <div className="container">
              <div className="row align-items-center">
                <div
                  className="col-12 col-md-5"
                  data-sal="slide-up"
                  data-sal-duration="500"
                  data-sal-delay="100"
                  data-sal-easing="ease-out"
                >
                  <h3>Customer support that finds solutions</h3>
                  <p className="standfirst">
                    We're more than the world's leading product for premium visual content on the web &mdash; we're a trusted partner
                    that delivers solutions to your digital publishing challenges.
                  </p>
                  <p className="standfirst">
                    Our expert team of success managers will work with you to meet your mission-critical content goals, no matter how
                    ambitious.
                  </p>

                  <button className="btn btn-primary btn-lg" onClick={this.openContact}>
                    Contact sales
                  </button>
                </div>
                <div className="col-12 col-md-7">
                  <div className="featureImg--container featureImg--editor">
                    <StaticImage src="../../static/media/home/laptop.png" alt="" aria-hidden="true" />
                    <div className="featureImg--video">
                      <video
                        id="video-top-ls"
                        className=""
                        autoPlay={true}
                        loop={true}
                        muted={true}
                        playsInline={true}
                        poster={"/media/home/editor-poster.jpg"}
                        width="745"
                        height="480"
                        loading="lazy"
                      >
                        <source src="/media/home/editor.webm" type="video/webm" />
                        <source src="/media/home/editor.mp4" type="video/mp4" />
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section section-stories pb-0 !tw-pt-[80px]">
            <div
              className="container text-center"
              data-sal="slide-up"
              data-sal-duration="500"
              data-sal-delay="100"
              data-sal-easing="ease-out"
            >
              <h3>Inspiring examples</h3>
              <p className="standfirst">
                See how iconic organizations connect with their audiences using high-end content produced with Shorthand.
              </p>
            </div>
            <div className="featured-stories">
              <ul className="!tw-grid !tw-w-[80%] !tw-grid-cols-3 !tw-grid-rows-2">
                {featuredStories.map((story: any) => (
                  <li key={story.title}>
                    <a className="unstyled text-center" href={story.linkUrl} target="_blank" rel="noopener noreferrer">
                      <div className="featured-story-media">
                        <GatsbyImage
                          image={getImage(story.imageUrl.src)}
                          alt={story.imageUrl.alt}
                          className="list-item-transition  tw-w-[100%]"
                          objectFit="contain"
                        />
                      </div>
                      <div className="featured-story-title">{story.title}</div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </section>

          <section className="section section-quotes">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <figure
                    className="featureQuote"
                    data-sal="slide-up"
                    data-sal-duration="500"
                    data-sal-delay="100"
                    data-sal-easing="ease-out"
                  >
                    <img className="featureQuote__logo" src="/media/logos/clients/logo-honda.svg" alt="" />
                    <div className="featureQuote__inner">
                      <blockquote>
                        Shorthand delivers amazing results for Honda. In our first year, our dwell time was up 85%, and the
                        click-through rate was up 47%.
                      </blockquote>
                      <figcaption>Nick Bennett | Honda UK</figcaption>
                    </div>
                  </figure>
                </div>
                <div className="col-12 col-lg-6">
                  <figure
                    className="featureQuote"
                    data-sal="slide-up"
                    data-sal-duration="500"
                    data-sal-delay="300"
                    data-sal-easing="ease-out"
                  >
                    <img className="featureQuote__logo" src="/media/logos/clients/logo-stuff.svg" alt="" />
                    <div className="featureQuote__inner">
                      <blockquote>
                        Shorthand is just a one-stop shop for us. A designer can create a great story without any knowledge of code.
                      </blockquote>
                      <figcaption>Aaron Wood | Stuff</figcaption>
                    </div>
                  </figure>
                </div>
                <div className="col-12 text-center">
                  <Link to="/case-studies/" className="btn btn-lg btn-primary">
                    Read our Case Studies
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className="section section-end-cta-dark">
            <div
              className="container section-inner text-center"
              data-sal="slide-up"
              data-sal-duration="500"
              data-sal-delay="100"
              data-sal-easing="ease-out"
            >
              <h2>
                <Link to="/" className="shorthand-logo">
                  Shorthand
                </Link>
                Beautiful is built <br />
                with Shorthand
              </h2>
              <div className="cta-container">
                <a href="https://app.shorthand.com/signup" className="btn btn-lg btn-secondary btn-inverse">
                  Start creating
                </a>
              </div>
            </div>
            <div aria-hidden="true" className="background-media background-media-fixed">
              <StaticImage
                src="../../static/media/home/footer-bg.jpg"
                className="ObjectFit--cover"
                alt=""
                aria-hidden="true"
                placeholder="blurred"
                layout="fixed"
                width={1920}
                height={1080}
              />
            </div>
            <Footer className="footer-dark" />
          </section>
        </main>
      </Layout>
    );
  }
}

export const query = graphql`
  query EnterpriseQuery {
    site {
      siteMetadata {
        name
        title
        tagline
        description
      }
    }
    allEnterpriseFeaturedStoriesJson {
      nodes {
        title
        linkUrl
        imageUrl {
          alt
          src {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 600, height: 830)
            }
            publicURL
          }
        }
      }
    }
  }
`;
