import { filterListByCurrentIndustryCode } from "../../Utils";
import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { RelatedCaseStudies } from "../../components/RelatedCaseStudies";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default function CaseStudy({ data, location }) {
  return (
    <Layout location={location}>
      <div id="main" className="marketing-content container-fluid">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-secondary body-marketing-case-studies hide-elevio",
          }}
        />
        <div className="row section-title">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6 mx-auto section-inner">
            <h1>
              <span className="shorthand-logo">Shorthand</span>
              <span className="subsection-label">Case Study:</span>
              Bell Media Group
            </h1>
            <h2>How Bell Media Group uses Shorthand</h2>
          </div>
          <div aria-hidden="true" className="background-media">
            <img
              src="/media/case-studies/case-studies-bmg-title.png"
              data-object-fit="cover"
              data-autoscale-content-ratio="1.77777777778"
              alt=""
            />
          </div>
        </div>
        <div className="row section">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto marketing-main-column">
            <h2 className="intro">
              How does an ambitious marketing agency use immersive visual storytelling to delight their clients and get excellent
              results?
            </h2>
            <p>
              <a target="_blank" className="btn-link" rel="noopener noreferrer" href="https://www.bellmediagrp.com/">
                Bell Media Group
              </a>{" "}
              (BMG) is a brand storytelling and social media agency, based in Reading, Pennsylvania.
            </p>
            <p>
              The team at BMG have been working with Shorthand since 2021, and are currently using Shorthand with a client in the
              education and nonprofit sector called Total Experience Learning® at Albright College.
            </p>
            <p>
              We chatted with Kyra Denlinger, the co-founder and CEO of BMG, about why the team chose Shorthand, and the secrets to
              their recent success.
            </p>
            <h3 className="subheading">The power of storytelling</h3>

            <p>As Kyra explains, storytelling is a core part of BMG’s approach to their marketing work.</p>
            <p>
              “Part of our agency's philosophy is built on the power of storytelling. It's worked into our process. It's part of what
              we do with every client, whether it's a big project, a small project, an annual recurring, whatever else. It always
              starts with a storytelling session.”
            </p>
            <p>
              In 2021, Kyra and the team were looking for software to support this focus on storytelling — and came across Shorthand.
              After trialing Shorthand for a few months, they knew Shorthand would be a great fit.
            </p>
            <p>
              As Kyra says, Shorthand was simple for her to use, even though she isn’t a designer or developer. “Shorthand is friendly
              enough and supportive enough and intuitive enough that even I, as a non-coder, can still get in there and make things
              look pretty good.”
            </p>
            <p>Importantly, Shorthand was also a great fit for BMG’s graphic designer.</p>
            <p>
              “Anthony DiPaolo, our graphic designer, does coding and much more intensive design work than I do. So if he also likes it
              then we know we really have a winning product because we come from two different ability levels.”
            </p>
            <div className="photo-grid flex featured">
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/case-studies-bmg-sml-01.jpg" />
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/case-studies-bmg-sml-02.jpg" />
            </div>
            <div className="caption">Examples of BMG's use of Shorthand at Albright College.</div>

            <h3 className="subheading">More unique, more interactive</h3>
            <p>BMG is currently using Shorthand to promote the work of Total Experience Learning® at Albright College.</p>
            <p>
              “This program is doing some really phenomenal stuff. They're really making a splash locally. They've been on the local
              news a lot. They're definitely an innovator in our town,” Kyra says.
            </p>
            <p>
              One of the first activities BMG supported for Total Experience Learning® was an exclusive VIP gala for prospective
              donors. Initially, the BMG team provided the webpage, registration, the invitations, and signage — but they wanted to do
              something more impactful.
            </p>
            <p>
              “We thought, the best way to get these donors to really understand Total Experience Learning® is to get them to interact
              with the kids. So we organized the kids to be on-site. But then we wanted to show off their projects. Let me tell you,
              these kids are doing some phenomenal projects.
            </p>
            <p>
              “So that's where Shorthand came in. We blew up QR codes that our VIPs could scan at each station where the students were.
              That QR code went into a deeper dive of that student's story — built with Shorthand.”
            </p>
            <h3 className="subheading">A PDF can only go so far</h3>
            <figure className="block quote-block offset left">
              <blockquote>
                <p>
                  “A PDF version can only go so far. It's not as visually impactful as a Shorthand. There are limitations to what it
                  can do and can't do.”
                </p>
              </blockquote>
              <figcaption className="quote-cite">Kyra Denlinger</figcaption>
            </figure>
            <p>
              As Kyra explains, the team could have produced other kinds of media to show off Total Experience Learning®’s work — but
              they felt like a Shorthand story was the most impactful option.
            </p>
            <p>
              “We wanted to give them something of the student stories to remember. We didn't want to give them flyers. We didn't want
              to give them PDFs. We really wanted something that was more unique, more interactive, something that was going to stand
              out and leave a mark.”
            </p>
            <p>
              Kyra says that PDFs are a common choice for marketing collateral at events, but that they simply aren’t impactful as
              immersive content built using a digital storytelling platform like Shorthand.
            </p>
            <p>
              “A PDF version can only go so far. It's not as visually impactful as a Shorthand. There are limitations to what it can do
              and can't do.”
            </p>

            <h3 className="subheading">Raving about Shorthand stories</h3>
            <figure className="block quote-block offset right">
              <blockquote>
                <p>“They’re a happy client. They’re thrilled and proud of what we did with Shorthand.”</p>
              </blockquote>
              <figcaption className="quote-cite">Kyra Denlinger</figcaption>
            </figure>
            <p>How have the clients reacted to their Shorthand stories?</p>
            <p>“Overall, they continue to rave about their Shorthand stories. They say they use them all the time.”</p>
            <p>
              Kyra says that Total Experience Learning® even uses their Shorthand stories for teacher training. “The Science Research
              Institute team has teachers come into their building for different graduate level courses or to learn about their
              methodology. They'll put the Shorthands up on the big screens in the labs and they'll go through the students' Shorthands
              and talk about the kids' stories.”
            </p>
            <p>
              For Kyra, the project has been a great success. “They’re a happy client. They’re thrilled and proud of what we did.
              They’re showing off our work and happy to put their name on it. They’re using it all the time, which is the ideal result
              for us.”
            </p>

            <h3 className="subheading">“It’s a huge deal”</h3>
            <figure className="block quote-block offset left">
              <blockquote>
                <p>
                  “The design, the flexibility, and the interactive elements of Shorthand allow us to make something really, really
                  special.”
                </p>
              </blockquote>
              <figcaption className="quote-cite">Kyra Denlinger</figcaption>
            </figure>
            <p>
              Kyra says that many of her clients are limited by the functionality of their website CMS. Shorthand makes it possible to
              create immersive visual content without hiring an expensive – and time-consuming – developer.
            </p>
            <p>
              “For many of our clients, their websites aren't in a place they want them to be. And they don't have the money to develop
              an immersive, visual story from scratch, because it would take a lot of development effort. They don't have the time to
              do that.
            </p>
            <p>
              “The fact that Shorthand can integrate directly to that website — without having to pay for development, without having
              to massively change the setup of the website, without having to do a lot of additional training of folks internally or
              externally — is a huge deal. That is a big value-add and something that I think that our clients really appreciate.”
            </p>
            <p>For the team at Bell Media, Shorthand also strikes a balance between visual and written media.</p>
            <p>
              “Shorthand still relies on story. It still does rely on written word. We feel like content is something that we're pretty
              strong at in our digital agency. The design, the flexibility, and the interactive elements of Shorthand allow us to show
              off the quality of our content, and allows us to make something really, really special.”
            </p>

            <h3 className="subheading">The future</h3>

            <p>Over time, Bell Media’s use of Shorthand has grown increasingly ambitious.</p>
            <p>
              “I started off using the drag-and-drop features, which were very easy to use, and it worked really well. I was really
              pleased with what I could do. As I become more experienced with the platform, I am also really pleased with the level of
              tutorials that are there,” Kyra says.
            </p>
            <p>
              “I would say we were using it very traditionally when we first started. But when our graphic designer, Anthony, dived
              into the platform, he began to play with the custom CSS and HTML. That flexibility means a lot to him. He’s been able to
              take our visuals to a level that I wasn't able to get them myself.”
            </p>
            <p>
              Bell Media Group has been taking on larger and larger clients, including a promising relationship with their city’s
              chamber of commerce, who are using BMG’s storytelling services with Shorthand to showcase their members’ stories of
              innovation and collaboration. BMG rolled out five feature stories in 2022 with a plan to execute eight more in 2023, and
              can’t wait to make use of Shorthand’s new Agency features in the coming months and years.
            </p>
          </div>
        </div>
        <RelatedCaseStudies studies={filterListByCurrentIndustryCode(data, "bell-media-group")} />
        <Footer />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query BellMediaGroupQuery {
    allCaseStudiesJson {
      edges {
        node {
          jsonId
          image
          industry
          industryCode
          organisation
          summary
        }
      }
    }
  }
`;
