// extracted by mini-css-extract-plugin
export var bbc = "LogosSection-module--bbc--beade";
export var christies = "LogosSection-module--christies--e3497";
export var colStyles = "LogosSection-module--colStyles--da02a";
export var desktopFirstRow = "LogosSection-module--desktopFirstRow--1ab73";
export var desktopSecondRow = "LogosSection-module--desktopSecondRow--b1bf8";
export var desktopWrapper = "LogosSection-module--desktopWrapper--c7f98";
export var dowJones = "LogosSection-module--dowJones--ab069";
export var englishHeritage = "LogosSection-module--englishHeritage--7424a";
export var greenPeace = "LogosSection-module--greenPeace--f8b62";
export var honda = "LogosSection-module--honda--9a6c1";
export var logosParagraph = "LogosSection-module--logosParagraph--c95a8";
export var manchesterCity = "LogosSection-module--manchesterCity--3eda0";
export var nature = "LogosSection-module--nature--d4963";
export var nikkei = "LogosSection-module--nikkei--d7e88";
export var oxfordUni = "LogosSection-module--oxfordUni--90288";
export var paragraph = "LogosSection-module--paragraph--100f0";
export var penguin = "LogosSection-module--penguin--566f6";
export var saveTheChildren = "LogosSection-module--saveTheChildren--89b7d";
export var svgStyles = "LogosSection-module--svgStyles--b2af9";
export var tripAdvisor = "LogosSection-module--tripAdvisor--d55c9";
export var unicef = "LogosSection-module--unicef--84da2";