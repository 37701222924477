// import { Link } from "gatsby";
import { Link } from "../Atoms";
import cx from "classnames";
import * as React from "react";
import { ArrowRight } from "react-feather";

export default function UnderlineLink({
  arrow = false,
  cta = {
    link: "",
    text: "",
  },
  className = "",
  ...props
}: {
  arrow?: boolean;
  className?: string;
  cta?: { link?: string; text: string };
}) {
  const sharedClasses =
    "tw-inline-block tw-text-[20px] tw-font-semibold tw-font-CircularXXSub tw-leading-5 tw-border-b  tw-py-[2px] tw-border-text_black  tw-capitalize !tw-no-underline";
  return (
    <>
      {/* TODO: remove hover */}
      {cta.link ? (
        <Link to={cta.link} className={cx(sharedClasses, className)} {...props}>
          <LinkContent text={cta.text} arrow={arrow} />
        </Link>
      ) : (
        <span className={cx(sharedClasses, className)} {...props}>
          <LinkContent text={cta.text} arrow={arrow} />
        </span>
      )}
    </>
  );
}

const LinkContent = ({ arrow, text }: { arrow?: boolean; text: string }) => {
  return (
    <>
      {arrow && (
        <>
          <span>{text}</span>
          <ArrowRight size={20} className="tw-ml-1 tw-inline-block" />
        </>
      )}
      {arrow === false && <>{text}</>}
    </>
  );
};
