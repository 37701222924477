import { filterListByCurrentIndustryCode } from "../../Utils";
import { Footer } from "../../components/Footer";
import { LayoutNew } from "../../components/LayoutNew";
import { RelatedCaseStudies } from "../../components/RelatedCaseStudies";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default function CaseStudy({ data, location }) {
  return (
    <LayoutNew location={location} data={data}>
      <div id="main" className="marketing-content container-fluid">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-secondary body-marketing-case-studies hide-elevio",
          }}
        />
        <div className="row section-title">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6 mx-auto section-inner">
            <h1>
              <span className="shorthand-logo">Shorthand</span>
              How El Periódico uses Shorthand
            </h1>
          </div>
          <div aria-hidden="true" className="background-media">
            <img
              src="/media/case-studies/case-studies-el-periodico-blur.jpg"
              data-object-fit="cover"
              data-autoscale-content-ratio="1.77777777778"
              alt=""
            />
          </div>
        </div>
        <div className="row section">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto marketing-main-column">
            <h2 className="intro">
              How this Catalonian newspaper sidesteps slow web development to publish impressive digital feature stories with
              Shorthand.
            </h2>
            <div className="inline-image featured">
              <img alt="" src="/media/case-studies/case-studies-el-periodico.png" />
              <div className="caption">
                A selection of sections from an <i>El Periódico</i> story.
              </div>
            </div>
            <p>
              <i>El Periódico</i> is a Catalonian newspaper that publishes daily news in both Spanish and Catalan. Established 1978,
              the newspaper is known for its bold integration of photographs, comics, and other images in its print reporting.
            </p>
            <h3 className="subheading after:!tw-bg-[#D9D9D9]">The challenge of publishing high-quality digital stories</h3>
            <figure className="block quote-block offset left">
              <blockquote className="!tw-text-[#303030] !tw-opacity-70">
                <p>
                  <i>
                    "It was very hard to be original or different. It took us six to eight months to think about what we wanted to do,
                    and talk to designers and programmers about how to build it."
                  </i>
                </p>
              </blockquote>
              <figcaption className="quote-cite !tw-not-italic !tw-text-[#333333]">Xurxo Martínez</figcaption>
            </figure>
            <p>
              As the <i>El Periódico</i> team pivoted to producing more digital content, they faced a problem: the very thing that made
              their print edition iconic wasn’t translating to the screen. <i>El Periódico</i> were keenly aware that reading online
              was distinct from reading print and they didn’t want to copy and paste what had worked in their print edition.
            </p>
            <p>
              However, HTML templates that supported a wide range of effects tended to live outside their CMS and programming was
              achingly slow. Xurxo Martínez says, "It was very hard to be original or different. It took us six to eight months to
              think about what we wanted to do, and talk to designers and programmers about how to build it." They could make a
              template easily enough, but that meant settling for visually repetitive stories, going completely against their
              commitment to making “something singular.”
            </p>
            <p>
              The struggle wasn’t just one of design, it also made workflows in the newsroom frustratingly complex and slow.{" "}
              <i>El Periódico</i> wanted their journalists to be empowered to “work on their pieces without having to rely on
              developers or on tech teams, and have the fullest control of their work in the end.”
            </p>
            <p>
              Their current production process wasn’t working, but <i>El Periódico</i> refused to settle.
            </p>

            <div className="photo-grid flex featured">
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/el-periodico-01.jpg" />
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/el-periodico-02.jpg" />
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/el-periodico-03.jpg" />
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/el-periodico-04.jpg" />
            </div>
            <div className="caption">
              A selection of sections from an <i>El Periódico</i> article.
            </div>

            <h3 className="subheading after:!tw-bg-[#D9D9D9]">The hunt for the right software</h3>
            <p>
              <i>El Periódico</i> needed a tool to enable both magical storytelling and independence for their journalists. In-house
              solutions felt like “halfway with everything”, and products like Vev, “didn't seem like a tool that had a real newsroom
              in their mind,” Martínez says.
            </p>
            <p>
              When the team discovered Shorthand, they realised it was the perfect software to retain <i>El Periódico</i>’s innovative
              visuals as well as make sure their stories would rank highly in Google. With an easy-to-understand pricing model and an
              editor that “any journalist” could use, Shorthand gave <i>El Periódico</i> more control over their own processes.
            </p>
            <p>
              Shorthand has transformed the way these journalists think about storytelling and digital journalism. Instead of just
              writing a story and then choosing how to illustrate it, Martínez says that Shorthand’s editor allows his team to think
              about the story as a cohesive, interactive whole, instead of an article that is broken down into separate visual and
              written elements.
            </p>
            <p>
              Not all <i>El Periódico</i>’s news stories become ‘Shorthands’, but the ones that do have “something special”, Martinez
              says.
            </p>

            <h3 className="subheading after:!tw-bg-[#D9D9D9]">A strategic paywall</h3>

            <figure className="block quote-block offset left">
              <blockquote className="!tw-text-[#303030] !tw-opacity-70">
                <p>
                  <i>“We have had very good feedback about how special it is to read these stories.”</i>
                </p>
              </blockquote>
              <figcaption className="quote-cite !tw-not-italic !tw-text-[#333333]">Xurxo Martínez</figcaption>
            </figure>
            <p>
              These days, <i>El Periódico</i>’s subscription model means all their premium content built with Shorthand is behind a
              hard paywall. Martínez says, while it’s a shame more people can’t see what they do, they have “very good feedback about
              how special it is to read these stories.”
            </p>
            <p>
              This sense of exclusivity and quality is a powerful retention strategy: subscribers feel like their investment pays off,
              and there’s a sense of “Only I can see this thing that is so well done.”
            </p>

            <h3 className="subheading after:!tw-bg-[#D9D9D9]">Glowing feedback from readers and subscribers</h3>

            <figure className="block quote-block offset right">
              <blockquote className="!tw-text-[#303030] !tw-opacity-70">
                <p>
                  <i>
                    “We get one quarter of our subscriptions from Shorthand stories, and we are doing six Shorthand stories a week,
                    while we publish 250 articles a day. I think that gives you an idea of how important it is for us.”
                  </i>
                </p>
              </blockquote>
              <figcaption className="quote-cite !tw-not-italic !tw-text-[#333333]">Xurxo Martínez</figcaption>
            </figure>
            <p>
              <i>El Periódico</i>’s Shorthand features have a longer shelf life than breaking news, and subscribers say that they like
              to read many of the stories more than once. Plus, people tend to read all the way through instead of “just skipping to
              see the last paragraph in order to see the answer to the title.”
            </p>
            <p>
              In terms of sheer numbers, Martínez says, “We get one quarter of our subscriptions from Shorthand stories, and we are
              doing six Shorthand stories a week, while we publish 250 articles a day. I think that gives you an idea of how important
              it is for us.”
            </p>
            <p>
              For readers, every subscriber who has contributed to <i>El Periódico</i>’s feedback process has talked about the stories
              built with Shorthand stories. “They say ‘the special stories’ or ‘the things that you scroll and things happen!’” says
              Martínez. “It really works and is one of the things that identifies <i>El Periódico</i>. It’s what we used to achieve in
              the print version.”
            </p>

            <div className="inline-image featured">
              <img alt="" src="/media/case-studies/el-periodico-05.jpg" />
              <div className="caption">
                A selection of sections from an <i>El Periódico</i> article.
              </div>
            </div>
            <h3 className="subheading after:!tw-bg-[#D9D9D9]">Empowered journalists</h3>

            <p>
              In <i>El Periódico</i>’s busy newsroom, Shorthand has empowered the journalists to feel proud of their digital stories
              and a strong sense of ownership over the process of producing these well-received articles. And, while the actual content
              isn’t repetitive, using Shorthand has meant the team’s workflows are more repeatable, sustainable, and logical.
            </p>
            <p>
              The way of making stories with Shorthand isn’t about recreating a print experience on the web, it’s “this new narrative,
              this new storytelling,” says Martínez.
            </p>
          </div>
        </div>
        <RelatedCaseStudies studies={filterListByCurrentIndustryCode(data, "smarthinking")} />
        <Footer />
      </div>
    </LayoutNew>
  );
}

export const query = graphql`
  query smarthinkingQuery {
    allCaseStudiesJson {
      edges {
        node {
          jsonId
          image
          industry
          industryCode
          organisation
          summary
        }
      }
    }
  }
`;
