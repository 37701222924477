import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

export const IconList = (): JSX.Element => {
  return (
    <>
      <li>
        <StaticImage
          src="../../../static/media/home/logos/logo_adweek.png"
          alt="adweek logo"
          placeholder="blurred"
          layout="fixed"
          height={58}
          loading="eager"
        />
      </li>
      <li>
        <StaticImage
          src="../../../static/media/home/logos/logo_bbc.png"
          alt="bbg logo"
          placeholder="blurred"
          layout="fixed"
          height={58}
          loading="eager"
        />
      </li>
      <li>
        <StaticImage
          src="../../../static/media/home/logos/logo_christies.png"
          alt="christies logo"
          placeholder="blurred"
          layout="fixed"
          height={58}
          loading="eager"
        />
      </li>
      <li>
        <StaticImage
          src="../../../static/media/home/logos/logo_dowjones.png"
          alt="dowjones logo"
          placeholder="blurred"
          layout="fixed"
          height={58}
          loading="eager"
        />
      </li>
      <li>
        <StaticImage
          src="../../../static/media/home/logos/logo_elperiodico.png"
          alt="elperiodico logo"
          placeholder="blurred"
          layout="fixed"
          height={58}
          loading="eager"
        />
      </li>
      <li>
        <StaticImage
          src="../../../static/media/home/logos/logo_europoeancouncil.png"
          alt="europoeancouncil logo"
          placeholder="blurred"
          layout="fixed"
          height={58}
          loading="eager"
        />
      </li>
      <li>
        <StaticImage
          src="../../../static/media/home/logos/logo_greenpeace.png"
          alt="greenpeace logo"
          placeholder="blurred"
          layout="fixed"
          height={58}
          loading="eager"
        />
      </li>
      <li>
        <StaticImage
          src="../../../static/media/home/logos/logo_honda.png"
          alt="honda logo"
          placeholder="blurred"
          layout="fixed"
          height={58}
          loading="eager"
        />
      </li>
      <li>
        <StaticImage
          src="../../../static/media/home/logos/logo_marinestewardshipcouncil.png"
          alt="marinestewardshipcouncil logo"
          placeholder="blurred"
          layout="fixed"
          height={58}
          loading="eager"
        />
      </li>
      <li>
        <StaticImage
          src="../../../static/media/home/logos/logo_nikkei.png"
          alt="nikkei logo"
          placeholder="blurred"
          layout="fixed"
          height={58}
          loading="eager"
        />
      </li>
      <li>
        <StaticImage
          src="../../../static/media/home/logos/logo_penguin.png"
          alt="penguin logo"
          placeholder="blurred"
          layout="fixed"
          height={58}
          loading="eager"
        />
      </li>
      <li>
        <StaticImage
          src="../../../static/media/home/logos/logo_savethechildren.png"
          alt="savethechildren logo"
          placeholder="blurred"
          layout="fixed"
          height={58}
          loading="eager"
        />
      </li>
      <li>
        <StaticImage
          src="../../../static/media/home/logos/logo_skynews.png"
          alt="skynews logo"
          placeholder="blurred"
          layout="fixed"
          height={58}
          loading="eager"
        />
      </li>
      <li>
        <StaticImage
          src="../../../static/media/home/logos/logo_stuff.png"
          alt="stuff logo"
          placeholder="blurred"
          layout="fixed"
          height={58}
          loading="eager"
        />
      </li>
      <li>
        <StaticImage
          src="../../../static/media/home/logos/logo_sydneyoperahouse.png"
          alt="sydneyoperahouse logo"
          placeholder="blurred"
          layout="fixed"
          height={58}
          loading="eager"
        />
      </li>
      <li>
        <StaticImage
          src="../../../static/media/home/logos/logo_tripadvisor.png"
          alt="tripadvisor logo"
          placeholder="blurred"
          layout="fixed"
          height={58}
          loading="eager"
        />
      </li>
      <li>
        <StaticImage
          src="../../../static/media/home/logos/logo_unicef.png"
          alt="unicef logo"
          placeholder="blurred"
          layout="fixed"
          height={58}
          loading="eager"
        />
      </li>
      <li>
        <StaticImage
          src="../../../static/media/home/logos/logo_universityofcambridge.png"
          alt="universityofcambridge logo"
          placeholder="blurred"
          layout="fixed"
          height={58}
          loading="eager"
        />
      </li>
      <li>
        <StaticImage
          src="../../../static/media/home/logos/logo_uq.png"
          alt="uq logo"
          placeholder="blurred"
          layout="fixed"
          height={58}
          loading="eager"
        />
      </li>
      <li>
        <StaticImage
          src="../../../static/media/home/logos/logo_worldbankgroup.png"
          alt="worldbankgroup logo"
          placeholder="blurred"
          layout="fixed"
          height={58}
          loading="eager"
        />
      </li>
      <li>
        <StaticImage
          src="../../../static/media/home/logos/logo_worldhealthorganization.png"
          alt="nature logo"
          placeholder="blurred"
          layout="fixed"
          height={58}
          loading="eager"
        />
      </li>
      <li>
        <StaticImage
          src="../../../static/media/home/logos/logo_relx.png"
          alt="relx logo"
          placeholder="blurred"
          layout="fixed"
          height={58}
          loading="eager"
        />
      </li>
    </>
  );
};
