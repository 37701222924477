import { filterListByCurrentIndustryCode } from "../../Utils";
import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { RelatedCaseStudies } from "../../components/RelatedCaseStudies";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default function CaseStudy({ data, location }) {
  return (
    <Layout location={location}>
      <div id="main" className="marketing-content container-fluid">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-secondary body-marketing-case-studies hide-elevio",
          }}
        />
        <div className="row section-title">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6 mx-auto section-inner">
            <h1>
              <span className="shorthand-logo">Shorthand</span>
              <span className="subsection-label">Case Study:</span>
              Retail Week
            </h1>
            <h2>How Retail Week uses Shorthand</h2>
          </div>
          <div aria-hidden="true" className="background-media">
            <img
              src="/media/case-studies/case-studies-retail-week-title.jpg"
              data-object-fit="cover"
              data-autoscale-content-ratio="1.77777777778"
              alt=""
            />
          </div>
        </div>
        <div className="row section">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto marketing-main-column">
            <h2 className="intro">
              How did a leading industry publication move away from print and PDFs — and still delight its audience?
            </h2>
            <div className="inline-image featured">
              <img alt="" src="/media/case-studies/case-studies-retail-week.jpg" />
            </div>
            <p>
              <a href="https://www.retail-week.com/" target="_blank" className="btn-link" rel="noopener noreferrer">
                Retail Week
              </a>{" "}
              is a London-based title covering the retail industry, primarily in the UK. Published by Ascential, Retail Week provides
              news, analysis, events, and data across the retail industry.
            </p>
            <p>Since late 2019, Retail Week has been using Shorthand to produce countless stories, reports, and sponsored content.</p>
            <p>
              We chatted to Stephen Eddie, Production Editor at Retail Week, about their use of Shorthand to build immersive content
              and what their plans are for the future.
            </p>
            <h3 className="subheading">Sponsored content</h3>
            <figure className="block quote-block offset left">
              <blockquote>
                <p>“From the end of 2020, almost every sponsored content report we’ve produced has been with Shorthand.”</p>
              </blockquote>
              <figcaption className="quote-cite">Stephen Eddie</figcaption>
            </figure>
            <p>The decision by Retail Week to use Shorthand was driven by requests from clients.</p>
            <p>
              As Stephen explains, “Clients were coming to us wanting bolder and more complex content. The team here did some research
              and many of the examples the clients had shared were created using Shorthand. And even when they weren’t, Shorthand could
              be used to recreate what the client was looking for.”
            </p>
            <p>
              Since subscribing to Shorthand in late 2019, the team at Retail Week have created a range of beautifully produced
              sponsored-content pieces. A particular speciality for the team is research reports.
            </p>
            <p>“From the end of 2020, almost every sponsored content report we’ve produced has been with Shorthand.”</p>
            <h3 className="subheading">Flagship content</h3>
            <p>
              After establishing the use of Shorthand for sponsored content, the team at Retail Week expanded their use to editorial
              content.
            </p>
            <p>
              “We also wanted to refresh our editorial content and make it look more premium – especially our longform interviews, or
              big deep-dive features and reports,” Stephen says.
            </p>
            <p>“We started using Shorthand for some editorial content as well. Our use of Shorthand has steadily ramped up.”</p>
            <p>
              Some of this editorial content includes flagship reports, such as the{" "}
              <a href="https://reports.retail-week.com/retail-100-2021" target="_blank" className="btn-link" rel="noopener noreferrer">
                Retail 100
              </a>
              . Other reports built using Shorthand include annual reports on topics such as{" "}
              <a
                href="https://reports.retail-week.com/supply-chain-2022/index.html"
                target="_blank"
                className="btn-link"
                rel="noopener noreferrer"
              >
                supply chain
              </a>
              , retail technology and the gig economy.
            </p>
            <div className="photo-grid flex featured">
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/case-studies-retail-week-sml-01.jpg" />
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/case-studies-retail-week-sml-03.jpg" />
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/case-studies-retail-week-sml-04.jpg" />
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/case-studies-retail-week-sml-02.jpg" />
            </div>
            <div className="caption">A selection of sections from Retail Week's stories</div>

            <h3 className="subheading">The pivot from PDFs</h3>
            <figure className="block quote-block offset right">
              <blockquote>
                <p>“A PDF simply doesn’t look as good as it does when using a platform like Shorthand.”</p>
              </blockquote>
              <figcaption className="quote-cite">Stephen Eddie</figcaption>
            </figure>
            <p>
              Retail Week has an illustrious history. Founded in 1988 as a print magazine, it has long been a touchstone for industry
              news and insights.
            </p>
            <p>
              Like other print publications, Retail Week made the jump to digital in the early 2000s. That journey continued in March
              2020, when it shuttered its print publication — followed by stopping the digital PDF edition in June 2021 — to focus
              exclusively on its online content.
            </p>
            <p>
              “The decision to move away from producing a PDF-style digital magazine was twofold. One, it's still labour-intensive.
              Even though it's not going to the printer’s, everything up until that point follows the same process as producing a
              magazine.
            </p>
            <p>
              “And then, PDFs are not really digitally native, so it's quite hard to create a satisfying user experience in that format
              — and it simply doesn’t look as good as it does when using a platform like Shorthand.”
            </p>
            <p>
              An additional benefit of working with a platform like Shorthand is that the software is under constant development and
              expansion.
            </p>
            <p>
              “When it's a platform like Shorthand that lots of brands use, there's an incentive for the platform to update things and
              offer new features, because it's useful for multiple customers.”
            </p>
            <h3 className="subheading">Accessible and responsive</h3>

            <p>
              Another reason the Retail Week team wanted to move away from PDFs was that the format isn’t typically accessible or
              responsive.
            </p>
            <p>
              As Stephen explains, “While it’s technically possible to make PDFs accessible, it's a lot easier to do with a purely
              digital platform like Shorthand.”
            </p>
            <p>
              PDFs also aren’t responsive to different screen sizes. “Our Shorthand content will adjust depending on the device you’re
              using, but obviously a PDF format doesn't do that. So having something that made our reports more responsive so that
              people could read them across devices was important as well.”
            </p>
            <p>
              This was especially important to a publication like Retail Week, which has a specific focus on making content as
              accessible and user-friendly as possible.
            </p>
            <p>
              “We run a programme called Be Inspired, which is all about helping retail businesses be inclusive and diverse. We want to
              practise what we're trying to promote, so it’s really important we get it right for our own content – and that means
              moving away from PDFs.”
            </p>

            <h3 className="subheading">For the entire team</h3>
            <figure className="block quote-block offset left">
              <blockquote>
                <p>“Shorthand support is always super prompt and helpful.”</p>
              </blockquote>
              <figcaption className="quote-cite">Stephen Eddie</figcaption>
            </figure>
            <p>
              Today, Shorthand is deeply embedded in Retail Week’s content production process. As Stephen explains, “Now, the whole
              production team is trained up on Shorthand. Even freelancers we work with frequently know how to use it.”
            </p>
            <p>
              What does the wider team think of using Shorthand? “Everyone comments on how intuitive Shorthand is to use. Once you've
              done the introductory training, you can really take it away.”
            </p>
            <p>
              Shorthand’s collaboration features have also been important to the team. “They mentioned how easy it is to collaborate on
              projects. We can quickly try different features or different layouts, see the results, get feedback and move quickly.”
            </p>
            <p>
              Stephen has also been happy with how easy it is to integrate other tools, such as the{" "}
              <a href="https://flourish.studio/" target="_blank" className="btn-link" rel="noopener noreferrer">
                data visualisation app Flourish
              </a>
              , with Shorthand.
            </p>
            <p>
              “We were able to combine Shorthand and Flourish to create last year’s Retail 100, which was our top-performing commercial
              sponsored campaign that year. Without being able to integrate Shorthand and Flourish, we just wouldn't have been able to
              make something as engaging as that.”
            </p>
            <p>
              More broadly, Stephen says he’s delighted with the support given by Shorthand’s Editorial Success Managers. “The thing
              I've really appreciated is the engagement from the Shorthand team. The support is always super prompt and helpful. This
              means that if there is something we don't know how to solve ourselves, we can find out pretty quickly.”
            </p>

            <h3 className="subheading">“It’s been really positive”</h3>

            <p>
              Now that Retail Week doesn't produce a print product, it has the freedom to expand into new digital content formats. The
              use of Shorthand has grown from sponsored reports to include “power lists, big interviews and deep dives”.
            </p>
            <p>
              Retail Week is also using Shorthand for its content portals on special retail topics. “We organise weeks that are
              dedicated to a topic or a subject such as tech, stores or ecommerce. And Shorthand acts like a homepage or microsite for
              those.”
            </p>
            <p>What about feedback from clients and other team members?</p>
            <p>
              “It’s been really positive,” Stephen says. “Clients have definitely noticed the quality of the content we’re producing.”
            </p>
          </div>
        </div>
        <RelatedCaseStudies studies={filterListByCurrentIndustryCode(data, "retail-week")} />
        <Footer />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query RetailWeekQuery {
    allCaseStudiesJson {
      edges {
        node {
          jsonId
          image
          industry
          industryCode
          organisation
          summary
        }
      }
    }
  }
`;
