export const filterListByCurrentIndustryCode = (data: any, studyId: string) => {
  const { allCaseStudiesJson } = data;
  const { edges } = allCaseStudiesJson;
  const current = edges.find(f => f.node.jsonId === studyId);
  return edges.filter(f => f.node.industryCode === current.node.industryCode);
};

export const sentenceCaseText = (text: string) => {
  const str = text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  const newText = str.replace(new RegExp("shorthand", "gi"), match => match.charAt(0).toUpperCase() + match.slice(1).toLowerCase());
  return newText;
};
