import { Byline } from "../ByLine/ByLine";
import { ColorBar } from "../ColorBar/ColorBar";
import { Features } from "../Features/Features";
import { FormLink } from "../FormLink/FormLink";
import { PricingPlanProps } from "../PricingPlan";
import { Title } from "../Title/Title";
import * as styles from "./PlanAccordion.module.scss";
import * as Accordion from "@radix-ui/react-accordion";
import * as React from "react";
import { Waypoint } from "react-waypoint";

let classNames = require("classnames");

export const PlanAccordion = ({
  planSelected,
  plan,
  featuresHeadline,
  ctaText,
  formLink,
  isCurrentPlan,
  isFeaturePlan,
  isDefaultPlan = false,
  tooltips,
  ctaFunction,
  setDefaultOpenCallBack,
  children,
  id,
}: Readonly<
  PricingPlanProps & {
    id: string;
    isDefaultPlan: boolean;
    planSelected: string;
    setDefaultOpenCallBack?: (id: string) => void;
  }
>): JSX.Element => {
  const { name, marketing } = plan;

  function handleCtaClicked() {
    ctaFunction && ctaFunction(plan.id);
  }

  function handleWaypointFirstTimeEnter(id: string) {
    setDefaultOpenCallBack && setDefaultOpenCallBack(id);
  }

  const accordionTriggerStyles = styles.AccordionTrigger;
  const theme = isFeaturePlan ? "dark" : "light";

  let planThemeStyles = styles.planLight;
  if (theme === "dark") {
    planThemeStyles = styles.planDark;
  }

  return (
    <Accordion.Item key={id} id={id} value={id} className={classNames(styles.plan, planThemeStyles, styles.AccordionItem)}>
      <Accordion.Header className={styles.AccordionHeader}>
        <Title title={name} theme={theme} />
        <ColorBar id={plan.id} />
        <Byline text={marketing.label} theme={theme} />
        {children}
        <button
          onClick={handleCtaClicked}
          data-testid="plan-cta"
          className={classNames(
            [styles.button],
            { [styles.buttonWhite]: isFeaturePlan },
            { [styles.buttonPrimary]: !isCurrentPlan },
            { [styles.buttonSecondary]: isCurrentPlan }
          )}
        >
          {ctaText}
        </button>
        {isDefaultPlan && (
          // when user scrolls to here we open the accordion if we are the default plan
          <Waypoint scrollableAncestor={null} onEnter={() => handleWaypointFirstTimeEnter(id)} />
        )}
        <Accordion.Trigger className={classNames(styles.AccordionTrigger, accordionTriggerStyles)}>
          <div
            role="button"
            data-testid="plan-cta"
            className={classNames([styles.button], [styles.buttonPrimaryReversed], { [styles.buttonWhite]: isFeaturePlan })}
          >
            <span>{planSelected === id ? "Hide " : "See "}Features</span>
          </div>
        </Accordion.Trigger>
      </Accordion.Header>

      <Accordion.Content className={styles.AccordionContent}>
        <div style={{ marginTop: "2rem" }}>
          <Features title={featuresHeadline} features={marketing.benefits} theme={theme} tooltips={tooltips} />
        </div>
        {formLink && <FormLink formLink={formLink} />}
      </Accordion.Content>
    </Accordion.Item>
  );
};
