import * as React from "react";

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({ children, to, className, ...other }: { children?: any; to: any; className?: string }) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to);

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      // <GatsbyLink to={to} className={className} target="_blank" {...other}>
      //   {children}
      // </GatsbyLink>
      <a href={to} className={className} {...other} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }
  return (
    <a href={to} className={className} {...other} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

export default Link;
