import * as colorStyles from "../PricingPlan.module.scss";
import * as styles from "./Title.module.scss";
import * as React from "react";

let classNames = require("classnames");

interface TitleProps {
  title: string;
  theme?: "light" | "dark";
}

export const Title = ({ title, theme }: TitleProps): JSX.Element => {
  return (
    <span
      className={classNames(styles.title, {
        [colorStyles.planDarkTitle]: theme === "dark",
        [colorStyles.planLightTitle]: theme === "light",
      })}
    >
      {title}
    </span>
  );
};
