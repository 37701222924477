import * as colorStyles from "../PricingPlan.module.scss";
import * as styles from "./Price.module.scss";
import * as React from "react";

let classNames = require("classnames");

interface PriceProps {
  duration: "monthly" | "yearly";
  userCurrency: { name: string; label: string };
  theme?: "light" | "dark";
  dynamicPrice?: number;
  unitPrice?: string;
  children?: React.ReactNode;
}

function humanisePrice(price: number): string {
  return price > 10000 ? price.toLocaleString() : price.toString();
}

export const symbolForPlanCurrency = {
  USD: "$",
  AUD: "$",
  GBP: "£",
  EUR: "€",
};

export const Price = ({ duration, userCurrency, theme, dynamicPrice, unitPrice, children }: PriceProps): JSX.Element => {
  const payCycle = { ["monthly"]: "month", ["yearly"]: "year" }[duration];

  let payCycleStyles = colorStyles.planLightPayCycle;
  if (theme == "dark") {
    payCycleStyles = colorStyles.planDarkPayCycle;
  }
  return (
    <div>
      <div className={styles.wrapper}>
        <span
          className={classNames(styles.price, {
            [colorStyles.planDarkPrice]: theme == "dark",
          })}
        >
          {symbolForPlanCurrency[userCurrency.label]}
          {humanisePrice(dynamicPrice)}
        </span>
        <span className={classNames(styles.payCycle, payCycleStyles)}>/ {payCycle}</span>
      </div>
      {unitPrice ? (
        <div
          className={classNames(styles.perStoryPrice, {
            [colorStyles.planDarkPerStoryPrice]: theme == "dark",
          })}
        >
          {children}
        </div>
      ) : null}
    </div>
  );
};
