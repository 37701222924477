import { Footer } from "../components/Footer";
import { Layout } from "../components/Layout";
import * as React from "react";
import { Helmet } from "react-helmet";

export default class AboutView extends React.Component<any, any> {
  public state = {
    hasLoaded: false,
  };

  constructor(props: any, context: any) {
    super(props, context);
  }

  componentDidMount() {
    this.setState(() => ({ hasLoaded: true }));
  }

  render() {
    if (!this.state.hasLoaded) {
      return null; // need to load user first
    }

    return (
      <Layout location={this.props.location}>
        <main id="main" className="marketing-content container-fluid">
          <Helmet
            bodyAttributes={{
              class: "body-shorthand body-marketing body-marketing-secondary body-light  body-marketing-about hide-elevio",
            }}
          />
          <div className="row section-title title-light">
            <div className="col-11 col-sm-10 col-lg-8 mx-auto">
              <h1>
                <span className="shorthand-logo dark" aria-hidden="true">
                  Shorthand
                </span>{" "}
                About Us
              </h1>
            </div>
          </div>
          <div className="row section section-about-video">
            <div className="about-divider">
              <i className="fa fa-lightbulb" />
            </div>
            <div className="col col-sm-10 mx-auto section-about-inner">
              <h2>Learn why Shorthand is the visual storytelling platform for iconic brands</h2>
              <div className={"video-container"}>
                <video
                  autoPlay={false}
                  controls={true}
                  loop={false}
                  muted={false}
                  playsInline={true}
                  poster="/media/video/shorthand-compressed.jpg"
                  tabIndex={0}
                  aria-label="video about shorthand"
                >
                  <source src="/media/video/shorthand-compressed.mp4" type="video/mp4" />
                  <img src="/media/video/shorthand-compressed.jpg" alt="" />
                </video>
              </div>
            </div>
            <div aria-hidden="true" className="background-media background-media-fixed">
              <img src="/media/about/about-video.jpg" alt="" />
            </div>
          </div>
          <div className="row section section-about-01">
            <div className="about-divider">
              <i className="fa fa-bolt" />
            </div>
            <div className="col col-sm-10 col-xl-8 mx-auto section-about-inner">
              <h2>
                Shorthand is the platform that brands, publishers, non-profits, universities and others use to create the world's most
                engaging stories, annual reports, case studies and other content.
              </h2>
              <p>
                Trusted by everyone from the BBC to Business Insider, Manchester City to Honda, Amnesty International to the Red Cross,
                and Cambridge to Harvard, Shorthand elevates your stories and communications from the everyday to the exceptional.
              </p>
            </div>
            <div aria-hidden="true" className="background-media background-media-fixed">
              <img src="/media/about/about-dash.jpg" alt="" />
            </div>
          </div>
          <div className="row section section-about-02">
            <div className="about-divider">
              <i className="fa fa-heart" />
            </div>
            <div className="col col-sm-10 col-xl-8 mx-auto section-about-inner">
              <h2>Committed to the exceptional</h2>
              <p>
                With best-in-the-business time on page metrics, as measured by Chartbeat and our own customers, Shorthand stories are
                arguably the most engaging content type on the web today. Our product is thoughtfully designed to enable publishers,
                agencies, corporate communications teams and others to create memorable reading experiences that audiences can’t
                ignore, and that move them to action.
              </p>
              <p>
                We promote style in support of substance, gravitas above gimmickry. We encourage our customers to strive for the
                extraordinary, and we support them in that pursuit.
              </p>
            </div>
            <div aria-hidden="true" className="background-media background-media-fixed">
              <img src="/media/about/about-stories.jpg" alt="" />
            </div>
          </div>
          <div className="row section section-about-03">
            <div className="about-divider">
              <i className="fa fa-question" />
            </div>
            <div className="col col-sm-10 col-xl-8 mx-auto section-about-inner">
              <h2>
                Our beliefs <em>&amp;</em> mission
              </h2>
              <p>
                We believe a world informed by powerful stories and linked by compelling everyday communications is a smarter, more
                progressive, more resilient world. So our mission is to connect the world's iconic brands to their audiences, their
                people and each other. We achieve this mission by undertaking extreme web innovation to create the leading platform for
                epic storytelling and collaboration for digital publishing teams.
              </p>
            </div>
            <div aria-hidden="true" className="background-media background-media-fixed">
              <img src="/media/about/about-app.jpg" alt="" />
            </div>
          </div>
          <div className="row section section-about-story">
            <div className="about-divider">
              <i className="fa fa-book" />
            </div>
            <div className="col col-sm-10 col-xl-8 mx-auto section-about-inner">
              <h2>Our story</h2>
              <p>
                Born in Brisbane, Australia, but now a truly global citizen, Shorthand is a band of geeks with art and heart. We are an
                intensely product- and customer-focused team of designers, software engineers, computer scientists, and, yes,
                storytellers. With customers on every continent barring Antarctica (one day!), we decided their needs could be best met
                by organizing our team to reflect that reality. So today we are a fully remote team, distributed across four continents
                and six cities, including New York, London, Seattle, Fukuoka and Sydney.
              </p>
            </div>
            <div aria-hidden="true" className="background-media background-media-fixed">
              <img src="/media/about/about-zoom.jpg" alt="" />
            </div>
          </div>
          <div className="row section section-about-locations">
            <div className="about-divider">
              <i className="fa fa-globe" />
            </div>
            <div className="col col-md-11 mx-auto section-about-inner">
              <h2>Our Locations</h2>
              <ul className="section-about-locations-list">
                <li className="section-about-location">
                  <address>
                    <strong>Brisbane</strong>
                    Level 38
                    <br />
                    Central Plaza One
                    <br />
                    345 Queen Street
                    <br />
                    Brisbane, QLD, 4000
                    <br />
                    Australia
                  </address>
                  <div className="section-about-map">
                    <img src="/media/about/about-map.jpg" alt="Brisbane Map" />
                  </div>
                </li>
                <li className="section-about-location">
                  <address>
                    <strong>London</strong>
                    5 Fleet Place
                    <br />
                    London, EC4M 7RD
                    <br />
                    England
                  </address>
                  <div className="section-about-map">
                    <img src="/media/about/about-map-ldn.jpg" alt="London Map" />
                  </div>
                </li>
                <li className="section-about-location">
                  <address>
                    <strong>New York</strong>
                    276 5th Avenue
                    <br />
                    Suite 704 #928
                    <br />
                    New York, NY 10001
                    <br />
                    USA
                  </address>
                  <div className="section-about-map">
                    <img src="/media/about/about-map-nyc.jpg" alt="New York Map" />
                  </div>
                </li>
                <li className="section-about-location">
                  <address>
                    <strong>Postal Address</strong>
                    PO Box 2151
                    <br />
                    New Farm, QLD, 4005
                    <br />
                    Australia
                  </address>
                  <div className="section-about-map">
                    <img src="/media/about/about-map-mail.jpg" alt="New Farm, Brisbane Map" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <Footer />
        </main>
      </Layout>
    );
  }
}
