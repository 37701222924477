import VideoMobileMp4 from "../../../static/media/home/main-video-mobile.mp4";
import VideoMobileWebm from "../../../static/media/home/main-video-mobile.webm";
import PosterMobile from "../../../static/media/home/main-video-poster-mobile.webp";
import Poster from "../../../static/media/home/main-video-poster.webp";
import VideoMp4 from "../../../static/media/home/main-video.mp4";
import VideoWebm from "../../../static/media/home/main-video.webm";
import { useIsMobile } from "../../hooks/useIsMobile";
import * as React from "react";

export const MainVideo = (): JSX.Element => {
  const [videoWidth, setVideoWidth] = React.useState<number>(0);
  const aspectRatio = 16 / 9;
  const isMobile = useIsMobile(620);

  function setVideoSize(): void {
    const windowWidth = window.innerWidth;

    let width = windowWidth * 0.59;

    if (windowWidth < 900) {
      width = windowWidth * 0.9;
    }

    if (windowWidth > 901 && windowWidth < 1600) {
      width = windowWidth * 0.67;
    }
    if (windowWidth > 1600) {
      width = 1048;
    }

    setVideoWidth(Math.floor(width));
  }

  React.useEffect(() => {
    setVideoSize();
    const handleResize = () => setVideoSize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="tw-relative tw-flex tw-flex-col tw-items-center">
      <div
        style={{
          minWidth: `${videoWidth}px`,
        }}
        className={`safari-ios-overflow-fix !tw-overflow-hidden !tw-rounded-lg`}
      >
        <div
          style={{
            minWidth: `${videoWidth}px`,
            height: `${videoWidth / aspectRatio}px`,
          }}
          className={`tw-bg-[#010101]`}
        >
          <video
            autoPlay={true}
            loop={true}
            muted={true}
            playsInline={true}
            preload="auto"
            poster={isMobile ? PosterMobile : Poster}
            width={videoWidth}
            height={videoWidth / aspectRatio}
          >
            <source src={isMobile ? VideoMobileWebm : VideoWebm} type="video/webm" />
            <source src={isMobile ? VideoMobileMp4 : VideoMp4} type="video/mp4" />s
            <track label="English" kind="captions" srcLang="en" src="" />
          </video>
        </div>
      </div>
    </div>
  );
};
