import { Link } from ".";
import * as React from "react";
import { ArrowRight } from "react-feather";
import { twMerge } from "tailwind-merge";

export default function StyledLink({
  colour = "black",
  arrow = false,
  cta = {
    link: "",
    text: "",
  },
  className = "",
  ...props
}: {
  arrow?: boolean;
  colour?: "black" | "white";
  className?: string;
  cta?: { link: string; text: string };
}) {
  return (
    <>
      <Link
        to={cta.link}
        className={twMerge(
          "tw-button transition ease-in-out tw-rounded-xl",
          arrow ? "tw-inline-flex" : "",
          colour === "black" &&
            "tw-border !tw-border-black tw-bg-black tw-text-white tw-duration-300 hover:!tw-border-black hover:!tw-bg-white hover:!tw-text-black",
          colour === "white" &&
            "tw-border !tw-border-white !tw-bg-white !tw-text-black hover:!tw-border-black hover:!tw-bg-black hover:!tw-text-white",
          className
        )}
        {...props}
      >
        <LinkContent cta={cta} arrow={arrow} />
      </Link>
    </>
  );
}

const LinkContent = ({ arrow, cta }: { arrow?: boolean; cta: { link: string; text: string } }) => {
  return (
    <>
      {arrow && (
        <>
          <span>{cta.text}</span>
          <ArrowRight size={22} className="tw-relative tw-top-[-2px] tw-ml-2 tw-inline-block" />
        </>
      )}
      {arrow === false && <>{cta.text}</>}
    </>
  );
};
