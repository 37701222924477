import { useIsMobile } from "../../hooks/useIsMobile";
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image";
import * as React from "react";
import { twMerge } from "tailwind-merge";

export default function FooterBanner2({
  title,
  description,
  images,
  mobileImage,
  className,
}: {
  title: string;
  description: string;
  images: {
    url: IGatsbyImageData;
    alt: string;
  }[];
  mobileImage: {
    url: IGatsbyImageData;
    alt: string;
  };
  className: string;
}) {
  const isMobile = useIsMobile(1265);
  return (
    <div className={twMerge("", className)}>
      <div className="tw-container">
        <h2
          className="tw-h1 !tw-my-0 tw-text-black max-lg:tw-text-center"
          data-sal="slide-up"
          data-sal-duration="500"
          data-sal-delay="100"
          data-sal-easing="ease-out"
        >
          {title}
        </h2>
        <p className="tw-h4 !tw-my-0 !tw-mt-2 tw-text-black max-lg:!tw-mt-0 max-lg:tw-text-center">{description}</p>
      </div>

      {isMobile === false ? (
        <div
          className="tw-mt-12 tw-flex tw-h-[750px]  tw-overflow-hidden
          tw-bg-[#F1F2F2] max-container:tw-h-[700px]
         "
        >
          <div className="tw-container tw-grid tw-grid-cols-3 tw-items-end tw-justify-between tw-gap-x-[3%] max-lg:tw-relative max-lg:tw-block">
            {/* mobile */}
            {images &&
              images.length > 0 &&
              images.map((image, index) => {
                return (
                  <div
                    key={index}
                    className={twMerge(
                      "tw-relative tw-overflow-hidden  tw-rounded-2xl tw-bg-text_black tw-p-[7px] before:tw-absolute  before:tw-left-0 before:tw-top-0 before:tw-z-10 before:tw-h-full before:tw-w-full before:tw-border-[8px] before:tw-border-text_black",
                      " max-lg:tw-absolute max-lg:tw-bottom-0 max-lg:tw-left-[50%] max-lg:tw-top-[50%] max-lg:tw-max-h-fit  max-lg:tw-w-[40%] max-lg:tw-max-w-fit max-lg:tw-translate-x-[-50%] max-lg:tw-translate-y-[-50%]",
                      " max-sm:tw-w-[60%]",
                      index === 0 && "tw-bottom-[138px]",
                      index === 1 && "tw-bottom-[54px]",
                      index === 2 && "tw-bottom-[-20px]"
                    )}
                    data-sal="slide-up"
                    data-sal-duration="500"
                    data-sal-delay="100"
                    data-sal-easing="ease-out"
                  >
                    <GatsbyImage
                      image={getImage(image.url)}
                      alt={image.alt}
                      className="tw-rounded-2xl  max-sm:tw-h-full  max-sm:tw-w-[500px] max-sm:tw-overflow-auto
                    "
                    />
                  </div>
                );
              })}
            {/* end mobile */}
          </div>
        </div>
      ) : (
        <div className="tw-mt-12 tw-bg-[#F1F2F2]">
          <GatsbyImage
            image={getImage(mobileImage.url)}
            alt={mobileImage.alt}
            className="tw-w-full"
            data-sal="slide-up"
            data-sal-duration="500"
            data-sal-delay="100"
            data-sal-easing="ease-out"
          />
        </div>
      )}
    </div>
  );
}
