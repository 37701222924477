/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import "./src/styles/global.css";
import { getUTMData } from "./src/services/Tracking";

// You can delete this file if you're not using it
// Gatsby doesn't let you add most external JS unless you do it this way.
const addScript = url => {
  const script = document.createElement("script");
  script.src = url;
  script.async = true;
  document.body.appendChild(script);
};

export const onClientEntry = () => {
  window.onload = () => {
    addScript("https://public.our-trace.com/scripts/trace-badge.js");
  };
};


export const onRouteUpdate = async () => {
  function checkGoals() {
    window.setTimeout(function () {
      window._conv_q = window._conv_q || [];
      window._conv_q.push(["run", "true"]);
    }, 50);
  }

  if (window.convert) {
    checkGoals();
  }
  getUTMData();
};
