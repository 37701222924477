import { filterListByCurrentIndustryCode } from "../../Utils";
import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { RelatedCaseStudies } from "../../components/RelatedCaseStudies";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default function CaseStudy({ data, location }) {
  return (
    <Layout location={location}>
      <main id="main" className="marketing-content container-fluid">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-secondary body-marketing-case-studies hide-elevio",
          }}
        />
        <div className="row section-title">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6 mx-auto  section-inner">
            <h1>
              <span className="shorthand-logo">Shorthand</span>
              <span className="subsection-label">Case Study:</span>
              Cambridge Assessment International Education
            </h1>
            <h2>How Shorthand is being used to create resources for the professional development of teachers around the globe</h2>
          </div>
          <div aria-hidden="true" className="background-media">
            <img
              src="/media/case-studies/case-studies-caie-title.jpg"
              data-object-fit="cover"
              data-autoscale-content-ratio="1.77777777778"
              alt=""
            />
          </div>
        </div>
        <div className="row section">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto marketing-main-column">
            <h3 className="intro">
              When it comes to popularity, time on page and overall customer feedback,&nbsp;
              <a href="https://www.cambridgeinternational.org/" target="_blank" rel="noopener noreferrer" className="btn-link">
                Cambridge Assessment International Education’s
              </a>
              &nbsp;Shorthand stories are getting full marks all round.
            </h3>

            <div className="inline-image featured">
              <img
                src="/media/case-studies/case-studies-caie.jpg"
                alt="Getting started with Language Awareness, by Cambridge International, renders responsively across all devices"
              />
            </div>

            <p>
              “The risk of trying Shorthand is low, while the reward is high,” Chris Brown, head of online professional learning at
              Cambridge International, was keen to reveal. “It’s really easy to put together our resources; they look professional,
              polished and it only takes a short time to create them.”
            </p>
            <p>
              Cambridge International’s qualifications are recognised by the world’s best universities and employers, giving students a
              wide range of options in their education and career.
            </p>

            <figure className="quote-block offset left">
              <blockquote>
                <p>
                  “When we introduced Shorthand, it really was a whole new look and it worked brilliantly on tablet and mobile. Our
                  customers enjoy how accessible the resources are.”
                </p>
              </blockquote>
              <figcaption className="quote-cite">Chris Brown</figcaption>
            </figure>

            <p>
              Not only does it help to educate school students of all ages, it also provides training and resources for teachers across
              160 countries - and that’s what they wanted to use Shorthand for.
            </p>
            <p>
              “We develop key areas of teaching and learning practice; explore the theory, discuss the benefits and consider some
              practical examples as an introduction to teaching,” Chris explained. “We were looking for a new way to teach ideas and
              professional development.”
            </p>
            <p>
              After coming across a story created with Shorthand by a major publisher in 2015, Chris decided to sign up for a
              subscription. He then got to work on creating a series of resources entitled Getting started with…, covering a range of
              topics from mentoring to language awareness, that they could host on their own website.
            </p>
            <div className="photo-grid flex featured">
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-caie-sml-01.jpg"
                alt="A section from one of Cambridge Assessment International Education's stories"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-caie-sml-02.jpg"
                alt="A section from one of Cambridge Assessment International Education's stories"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-caie-sml-04.jpg"
                alt="A section from one of Cambridge Assessment International Education's stories"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-caie-sml-03.jpg"
                alt="A section from one of Cambridge Assessment International Education's stories"
              />
            </div>
            <div className="caption">A selection of sections from Cambridge Assessment International Education's stories</div>
            <p>
              “Our stories are quite wordy pieces; occasionally we use interactive graphics. More so, we present text alongside images
              and embed videos. We often include interviews with experts, for example,” Chris continued.
            </p>

            <p>
              “When we introduced Shorthand, it really was a whole new look and it worked brilliantly on tablet and mobile. Our
              customers enjoy how accessible the resources are.”
            </p>

            <h3 className="subheading">Scoring high on engagement</h3>

            <figure className="block quote-block offset right">
              <blockquote>
                <p>
                  “We don’t have engagement levels like that on any other areas of our website… It’s amazing we’ve got that kind of
                  engagement.”
                </p>
              </blockquote>
              <figcaption className="quote-cite">Chris Brown</figcaption>
            </figure>

            <p>
              Chris and his team have been blown away by the stats they’ve collected on their Shorthand stories using Google Analytics,
              which is easily integrated directly into the editor.
            </p>
            <p>
              The average time on page for{" "}
              <a
                href="https://www.cambridge-community.org.uk/professional-development/gswla/index.html"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                Getting started with… Language Awareness
              </a>
              &nbsp;is an impressive 20 minutes, while the other resources created with the visual storytelling tool all average at 10
              minutes.
            </p>

            <p>
              “We don’t have engagement levels like that on any other areas of our website!” Chris exclaimed. “It’s amazing we’ve got
              that kind of engagement.”
            </p>
            <p>
              And, the high marks don’t stop there.{" "}
              <a
                href="https://cambridge-community.org.uk/professional-development/gswafl/index.html"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                Getting started with… Assessment for Learning
              </a>
              &nbsp;has had over 75,000 views in 18 months, and when you search the web for ‘assessment learning’ it steals one of the
              top spots.
            </p>

            <figure className="block quote-block offset left">
              <blockquote>
                <p>
                  “The content is good, it’s easy to use and it looks great on all devices, but we’re still astonished at how popular
                  they have been and continue to be.”
                </p>
              </blockquote>
              <figcaption className="quote-cite">Chris Brown</figcaption>
            </figure>

            <p>
              “The content is good, it’s easy to use and it looks great on all devices, but we’re still astonished at how popular they
              have been and continue to be,” said Chris. “Our assessment learning resource had 6,000 visits alone in October 2017. It
              was created 18 months ago, but it’s as relevant now as it was then.”
            </p>
            <p>
              By setting up their own branded theme and a template style, it has been easy for Chris to produce a number of these
              resources, as well as run courses based on them.
            </p>

            <p>
              Now, Chris and his team are considering how Shorthand can be utilised for more within the organisation. “We are thinking
              about using it for instruction manuals, rather than using bland PDFs for example,” he said.
            </p>
            <p>
              “It’s easy to iteratively design content. You can mock it up, send the link round and get feedback, then setup the design
              cycles to produce the final product. The user interface is so straightforward.”
            </p>
          </div>
        </div>
        <RelatedCaseStudies studies={filterListByCurrentIndustryCode(data, "caie")} />
        <Footer />
      </main>
    </Layout>
  );
}

export const query = graphql`
  query caieQuery {
    allCaseStudiesJson {
      edges {
        node {
          jsonId
          image
          industry
          industryCode
          organisation
          summary
        }
      }
    }
  }
`;
