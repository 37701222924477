import Select from "../../../components/Select";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { currencyCodes } from "../../../pages/Pricing";
import { PricingSlider } from "../PricingPlan/PricingSlider/PricingSlider";
import { currencyOptions } from "../PricingPlans";
import * as styles from "./style.module.scss";
import cx from "classnames";
import * as React from "react";

type Option = { label: string; value: string };

interface ToolbarProps {
  isSticky: boolean;
  setSliderValue: (value: number) => void;
  setSelectedCurrency: (value: Option) => void;
  sliderValue: number;
  selectedCurrency: Option;
}

export const SLIDER_MAX = 30;
export const ToolBar = (props: ToolbarProps): JSX.Element => {
  const { setSliderValue, setSelectedCurrency, sliderValue, selectedCurrency, isSticky } = props;

  const isMobile = useIsMobile(1265);

  const value = sliderValue + 1 === 31 ? "30+" : sliderValue + 1;
  const leftPart = React.useMemo(() => {
    return (
      <div className={styles.leftPart}>
        <div>
          <span className={styles.storyCountText}>Total published stories:</span> <span className={styles.storyCount}>{value}</span>{" "}
        </div>
        <div className={styles.subHeader}>The number of stories in your workspace with the "published" status.</div>
      </div>
    );
  }, [sliderValue]);

  const middlePart = React.useMemo(() => {
    return (
      <div className={styles.sliderWrapper}>
        <div className={styles.slider}>
          <PricingSlider max={SLIDER_MAX} sliderValue={sliderValue} setSliderValue={setSliderValue} />
        </div>
      </div>
    );
  }, [sliderValue]);

  const rightPart = React.useMemo(() => {
    return (
      <div className={styles.rightPart}>
        <Select
          aria-label="currency-select"
          className={styles.currencySelect}
          value={selectedCurrency || currencyCodes[0]}
          onChange={currencySelected => {
            setSelectedCurrency(currencySelected);
          }}
          isSearchable={false}
          options={currencyOptions}
          styles={{
            container: (provided, state) => ({
              ...provided,
              fontSize: "14px",
              fontWeight: "bold",
              backgroundColor: state.isFocused ? "#FFFFFF" : "none",
              width: "80px",
              cursor: "pointer",
              color: "#424242",
            }),
          }}
        />
      </div>
    );
  }, [selectedCurrency]);

  return (
    <div
      className={cx(styles.toolbarContainer, {
        [styles.toolbarSticky]: isMobile && isSticky,
      })}
    >
      {leftPart}
      {middlePart}
      {rightPart}
    </div>
  );
};
