import { filterListByCurrentIndustryCode } from "../../Utils";
import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { RelatedCaseStudies } from "../../components/RelatedCaseStudies";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default function CaseStudy({ data, location }) {
  return (
    <Layout location={location}>
      <div id="main" className="marketing-content container-fluid">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-secondary body-marketing-case-studies hide-elevio",
          }}
        />
        <div className="row section-title">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6 mx-auto section-inner">
            <h1>
              <span className="shorthand-logo">Shorthand</span>
              <span className="subsection-label">Case Study:</span>
              Opera Australia
            </h1>
            <h2>How Opera Australia uses Shorthand</h2>
          </div>
          <div aria-hidden="true" className="background-media">
            <img
              src="/media/case-studies/case-studies-opera-aus-title.jpg"
              data-object-fit="cover"
              data-autoscale-content-ratio="1.77777777778"
              alt=""
            />
          </div>
        </div>
        <div className="row section">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto marketing-main-column">
            <h2 className="intro">How does a leading cultural institution produce visually rich and immersive stories?</h2>
            <div className="inline-image featured">
              <img
                alt="Discover your 2022 Season, by Opera Australia renders responsively across all devices"
                src="/media/case-studies/case-studies-opera-aus.jpg"
              />
            </div>
            <p>
              Opera Australia is the principal opera company in Australia, performing out of the legendary Sydney Opera House and Arts
              Centre Melbourne.
            </p>
            <p>
              Opera Australia started using Shorthand towards the end of 2019 in order to produce beautiful, visually rich stories
              about their performances.
            </p>
            <p>
              Since then, they have gone on to produce a stunning range of content, from behind-the-scenes stories and retrospectives
              to beautiful digital brochures.
            </p>
            <p>
              We caught up with Ben Neutze, the marketing content specialist at Opera Australia, to talk about the growth of Opera
              Australia’s impressive content programme, starting with his impressions of using Shorthand.
            </p>
            <h3 className="subheading">An intuitive platform</h3>
            <figure className="block quote-block offset right">
              <blockquote>
                <p>“The further that we've gone on, the more possibilities we've discovered.”</p>
              </blockquote>
              <figcaption className="quote-cite">Ben Neutze</figcaption>
            </figure>
            <p>
              Ben describes Shorthand as “a really easy and intuitive platform. It's great to use and spend time discovering all of the
              different things that you can do.”
            </p>
            <p>
              As Ben explains, each of Shorthand’s section types has a “really clear purpose for what it’s for.” But there’s also room
              for experimentation and creativity.
            </p>
            <p>
              “You can play around with it a bit and see what you can make out of each section by just adjusting some of the settings.
              It's easy to use and it's fun to discover the new functions as they come.”
            </p>
            <p>
              This has led to Ben and the team exploring many more different use cases. “I think when we were first using it, we had a
              really clear idea of a few pieces of content that we wanted to be able to build with Shorthand.
            </p>
            <p>
              “And then the further that we've gone on, the more possibilities we've discovered. Certainly throughout lockdown when we
              had to be really agile and find new ways of presenting old content. That's when we really discovered a lot of the
              capabilities of it.”
            </p>
            <h3 className="subheading">Cheat sheets</h3>
            <figure className="block quote-block offset left">
              <blockquote>
                <p>
                  “By building them in Shorthand, we can ensure that the digital experience is in keeping with the experience that they
                  get at the theatre.”
                </p>
              </blockquote>
              <figcaption className="quote-cite">Ben Neutze</figcaption>
            </figure>
            <p>Opera Australia’s first use case for Shorthand was the digital version of the company’s ‘cheat sheets.’</p>
            <p>
              As Ben explains, “Cheat sheets are a really quick and audience-friendly guide to each opera that we’re performing.
              They’re sent out to our audiences in a pre-show email.
            </p>
            <p>
              “The cheat sheet includes a rundown of who the composer is, who the main characters are, what the songs are that the
              audience might know, and some funny and interesting stories about how the opera was written.”
            </p>
            <p>
              Prior to Shorthand, the cheat sheets were produced using Opera Australia’s CMS, which was, as Ben explains, “just a lot
              more rudimentary, with limited capabilities for visual storytelling.
            </p>
            <p>
              “We made it work as well as it possibly could, but obviously it's capabilities were very limited for that sort of
              content. While there were images, it was a much more basic presentation.”
            </p>
            <p>
              The same cannot be said for Opera Australia’s cheat sheets built with Shorthand, which are visually rich and immersive.
              One great example is{" "}
              <a
                href="https://features.opera.org.au/la-boheme-cheat-sheet/index.html"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                the cheat sheat for the opera La Bohème
              </a>
              , which combines historical images with an easily digestible introduction to the production.
            </p>
            <p>
              Ben says that the cheat sheets built with Shorthand are “essential to our entire customer journey. Those cheat sheets
              allow us to communicate a lot of information about an opera. They allow us to have that relationship with a customer
              before they even set foot in the theatre.
            </p>
            <p>
              “By building them in Shorthand, we can ensure that the digital experience is in keeping with the experience that they get
              when they actually arrive at the theatre.”
            </p>
            <div className="photo-grid flex featured">
              <img
                alt="A section from one of Opera Australia's stories"
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-opera-aus-sml-01.jpg"
              />
              <img
                alt="A section from one of Opera Australia's stories"
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-opera-aus-sml-02.jpg"
              />
              <img
                alt="A section from one of Opera Australia's stories"
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-opera-aus-sml-03.jpg"
              />
              <img
                alt="A section from one of Opera Australia's stories"
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-opera-aus-sml-04.jpg"
              />
            </div>
            <div className="caption">A selection of sections from Opera Australia's stories</div>
            <h3 className="subheading">Page to Stage</h3>
            <p>The Opera Australia team also use Shorthand to produce a regular feature called ‘Page to Stage.’</p>{" "}
            <p>
              Ben says that Page to Stage gives a behind-the-scenes look at how a show is brought to production. “This shows costume
              and set sketches, and then the final product with production photos.”
            </p>
            <p>
              With this range of visual media, Ben and the team are able to use Shorthand’s visual storytelling features — such as
              Scrollmation and Reveal image transitions — to bring the story to life.
            </p>
            <p>
              One impressive example is{" "}
              <a
                href="https://features.opera.org.au/aida-page-to-stage/index.html"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                Page to Stage: Aida
              </a>
              , which juxtaposes key elements of the production process — such as sketches from costume design — with photos from the
              live production.
            </p>
            <h3 className="subheading">Bringing an archive to life</h3>
            <p>
              Over the last two years, Opera Australia hasn’t been able to perform nearly as many shows — which has meant fewer
              behind-the-scenes stories.
            </p>
            <p>Instead, Ben and the team have explored the company’s rich archives.</p>
            <p>
              “Obviously, when we were in lockdown, we weren’t on stage and it was really difficult to produce new content. But we did
              have access to a really great archive of images,” Ben says.
            </p>
            <p>
              One story published from this archive was the spectacular retrospective,{" "}
              <a
                href="https://features.opera.org.au/50-years-of-opera-australia--part-one/index.html"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                50 years of Opera Australia
              </a>
              . With evocative photos and historical anecdotes, the story brings the history of the opera company to life.
            </p>
            <p>
              Ben and the team have also used Shorthand to showcase the historical performances of a specific opera. One great example
              is{" "}
              <a
                href="https://features.opera.org.au/opera-through-the-years-the-marriage-of-figaro/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                The Marriage of Figaro
              </a>
              , which was the first opera the company performed, back in 1956.
            </p>
            <h3 className="subheading">The digital brochure</h3>
            <figure className="block quote-block offset right">
              <blockquote>
                <p>“Shorthand is just a really obvious answer to the problem that we faced.”</p>
              </blockquote>
              <figcaption className="quote-cite">Ben Neutze</figcaption>
            </figure>
            <p>
              Each year, Opera Australia launches a new season of performances. At part of the launch, they send a physical brochure to
              subscribers and update their website with performance details.
            </p>
            <p>
              In 2021, Ben and the team decided to add a third element to their season launch:{" "}
              <a
                href="https://features.opera.org.au/sydney-2022/index.html"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                A digital brochure
              </a>
              .
            </p>
            <p>
              “We have been thinking for a while about the possibility of doing a digital brochure. There are over a dozen performances
              each season — and each of them always has their own production page on the website.
            </p>
            <p>
              “But the user experience of jumping from production page to production page is not the best way of discovering a full
              season quickly. With the digital brochure, we wanted to help people discover the full breadth of performances for a
              season.”
            </p>
            <p>
              The team had been thinking about producing a digital brochure for several years, but were concerned about the costs of
              bespoke development and design. As Ben says, “we were concerned that it could turn out to be quite expensive to invest in
              producing.”
            </p>
            <p>
              With Shorthand, the Opera Australia team were able to produce a great digital experience, without investing in additional
              custom development.
            </p>
            <p>
              As Ben explains, “there was a point a couple of months out from launch where I just said, we have a platform that can do
              all of the things that we want to do.
            </p>
            <p>
              “And Shorthand is just a really obvious answer to the problem that we faced. And I made a mock up and sent it across to
              the team. Everybody was really happy with it, and we just went from there.”
            </p>
            <p>
              The results? “The digital brochure has basically been how the vast majority of our audience has explored the season this
              year, while we’ve been waiting for our physical brochure to land in mailboxes. And given that the results have been so
              strong, we'll definitely be doing it again next year.”
            </p>
            <h3 className="subheading">The default for digital storytelling</h3>
            <p>
              Opera Australia’s use of Shorthand has grown hugely over the last two years, and Ben is confident that the team will
              continue to experiment.
            </p>
            <p>“We're continuing to develop and push the new features rolled out by Shorthand.”</p>
            <p>
              Following the success of their recent content, Ben explains that Shorthand has become central to implement the company’s
              content strategy.
            </p>
            <p>
              “Shorthand is now the default for digital storytelling. Unless we can think of a reason why it should live somewhere
              else, it lives on Shorthand.”
            </p>
          </div>
        </div>
        <RelatedCaseStudies studies={filterListByCurrentIndustryCode(data, "opera-australia")} />
        <Footer />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query OperaAustraliaQuery {
    allCaseStudiesJson {
      edges {
        node {
          jsonId
          image
          industry
          industryCode
          organisation
          summary
        }
      }
    }
  }
`;
