import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default class FeaturesVisualStorytelling extends React.Component<any, any> {
  render() {
    const { allBrandsJson } = this.props.data;

    const brands = allBrandsJson.edges;

    return (
      <Layout location={this.props.location}>
        <main id="main" className="marketing-content container-fluid">
          <Helmet
            bodyAttributes={{
              class: "body-shorthand body-marketing body-marketing-secondary body-marketing-features body-light hide-elevio",
            }}
          ></Helmet>
          <div className="row section-title title-light">
            <div className="col-11 col-sm-8  mx-auto section-inner">
              <h1>
                <span className="shorthand-logo dark" aria-hidden="true">
                  Shorthand
                </span>
                Visual Storytelling
              </h1>
              <h2>Create beautiful visual stories for all devices.</h2>
            </div>
          </div>

          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>We handle the hard part</h3>
                  <p>
                    Shorthand creates multiple versions of your uploaded images and ensures the most appropriate version is served for
                    each screen size.
                  </p>
                  <ul className="features-list">
                    <li>Have confidence that your scroll-based animations work on all phones, tablets, and monitors.</li>
                    <li>Set ‘focus points’ to make sure that the most important parts of your images are always shown.</li>
                    <li>For bespoke illustrations, optionally create separate treatments for mobile screens.</li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/visual-responsive.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>
          <section className="row section section-strip section-strip-right">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Media Gallery</h3>
                  <p>Incorporate galleries of images and video into your story.</p>
                  <ul className="features-list">
                    <li>Showcase multiple images and video in a fully responsive grid.</li>
                    <li>Drag-and-drop multiple media files into the Shorthand editor and resize and arrange them as needed.</li>
                    <li>Your readers can select individual images or video to view in a full-screen lightbox.</li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/visual-gallery.gif" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>
          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Simple testing</h3>
                  <p>
                    Streamline your QA and easily test your stories on all screen sizes prior to publication with a simple preview link
                    or QR code.
                  </p>
                  <ul className="features-list">
                    <li>Remove the guess-work from testing on mobile and tablet screens.</li>
                    <li>
                      Get feedback and sign-off from your colleagues and collaborators &mdash; even those outside of your Shorthand
                      organisation.{" "}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/visual-test.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section  section-strip section-strip-quote ">
            <div className="container container-sm">
              <div className="section-strip-col-single section-strip-single-quote">
                <div className="section-strip-quote-inner">
                  <blockquote>
                    &ldquo;Shorthand removes all the technical complexities involved with digital storytelling. The platform allows
                    non-coders to build a creative that has that deep, immersive feel — and in a fraction of the time.&rdquo;
                  </blockquote>
                  <cite>Scott Peakcocke | Senior editor for data journalism | AJC</cite>
                </div>
              </div>
            </div>
          </section>

          <section className="row section  section-strip-stories section-border-top">
            <div className="container flex-column section-inner text-center">
              <h3>Visual Storytelling in action</h3>
              <div className="featured-stories">
                <ul>
                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://www.consilium.europa.eu/en/eu-budget-story/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/eu-blueprint.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        A blueprint for the future <span className="featured-story-brand">Council for the European Union</span>
                      </h4>
                    </a>
                  </li>

                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://news.sky.com/story/d-day-the-story-of-24-hours-that-changed-the-world-11732907"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/sky-reckoning.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        Day of Reckoning <span className="featured-story-brand">Sky News</span>
                      </h4>
                    </a>
                  </li>

                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://www.bbc.com/news/extra/vdb6u1mrrv/the-boat-that-disappeared"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/bbc-boat.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        The boat that disappeared <span className="featured-story-brand">BBC</span>
                      </h4>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="row section section-dark section-half section-trial !tw-bg-[rgba(249,249,249,0.3)]">
            <div className="container section-inner text-center">
              <h2 className="!tw-text-black">
                Ready to try the digital storytelling platform used by Honda, University of Cambridge, and the BBC?
              </h2>

              <div className="cta-container">
                <a href="https://app.shorthand.com/signup" className="btn btn-lg !tw-bg-black hover:!tw-opacity-70">
                  Start creating
                </a>
              </div>
              <div className="brands">
                <div className="brands-container">
                  <h3 className="as-used">Join these iconic brands</h3>
                  <ul>
                    {brands.map((brand, index) => (
                      <li key={`brand-${brand.node.label}-${index}`} className={`brand`}>
                        <img src={brand.node.logo} alt={`${brand.node.label} logo`} />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div aria-hidden="true" className="background-media background-media-fixed">
              <img src="/media/home/stories.jpg" className="ObjectFit--cover" alt="" />
            </div>
          </section>
          <section className="row section section-gray-lighest section-all-features">
            <div className="container section-inner text-center">
              <h2>
                <a className="unstyled" href="/features/">
                  More Shorthand features
                </a>
              </h2>

              <div className="features features-cards">
                <ul>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/scrollytelling/">
                      <div className="card-text">
                        <h3>Scrollytelling</h3>
                      </div>
                    </a>
                  </li>
                  {/* <li>
                  <a className="feature-card card-border card-shadow unstyled" href="/features/visual-storytelling/">
                    <div className="card-text">
                      <h3>Visual Storytelling</h3>
                    </div>
                  </a>
                </li> */}
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/collaboration/">
                      <div className="card-text">
                        <h3>Collaboration</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/customisation/">
                      <div className="card-text">
                        <h3>Customisation & Integration</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/branding/">
                      <div className="card-text">
                        <h3>Themes & Branding</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/sharing-and-analytics/">
                      <div className="card-text">
                        <h3>Sharing & Analytics</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/publishing-and-hosting/">
                      <div className="card-text">
                        <h3>Publishing & Hosting</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/support/">
                      <div className="card-text">
                        <h3>Support</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/security/">
                      <div className="card-text">
                        <h3>Security</h3>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <Footer className="mt-0" />
        </main>
      </Layout>
    );
  }
}

export const query = graphql`
  query FeaturesVisualStorytellingQuery {
    site {
      siteMetadata {
        name
        title
        tagline
        description
      }
    }
    allBrandsJson {
      edges {
        node {
          logo
          name
          label
        }
      }
    }
  }
`;
