export type FeatureValue = string | number | null | boolean;
type Label = { value: string; tooltip: string };

export type Feature = {
  label: Label;
  starter: FeatureValue;
  team: FeatureValue;
  business: FeatureValue;
  enterprise: FeatureValue;
};

export const storyFeatures: Feature[] = [
  {
    label: {
      value: "Core visual storytelling section types",
      tooltip: "Our magical section types make Shorthand beautifully simple to use, and your stories simply beautiful.",
    },
    starter: true,
    team: true,
    business: true,
    enterprise: true,
  },
  {
    label: {
      value: "Maximum sections per story",
      tooltip: "Your stories have a section limit. Our Business and Enterprise plans now have a higher limit!",
    },
    starter: 25,
    team: 25,
    business: 40,
    enterprise: "40+",
  },
  {
    label: {
      value: "Custom HTML section and inline block",
      tooltip: "Add custom components and layouts that we don't support natively with HTML sections and inline HTML.",
    },
    starter: false,
    team: false,
    business: true,
    enterprise: true,
  },
  {
    label: {
      value: "Professionally crafted story templates",
      tooltip: "Start from one of our beautiful, handcrafted story templates for feature stories, annual reports, proposals and more.",
    },
    starter: true,
    team: true,
    business: true,
    enterprise: true,
  },
  {
    label: {
      value: "Embed (nearly) anything",
      tooltip:
        "Paste the URL of a video, 3D model, survey, or just about anything else into Shorthand and it will render beautifully and responsively.",
    },
    starter: true,
    team: true,
    business: true,
    enterprise: true,
  },
  {
    label: {
      value: "Access over 6 million free photos and images",
      tooltip: "Find the right image for your story using our built-in image catalogue provided by Unsplash.",
    },
    starter: true,
    team: true,
    business: true,
    enterprise: true,
  },
  {
    label: {
      value: "Upload video in any format",
      tooltip: "Shorthand supports .mov, mp4, .flv or just about any other video format.",
    },
    starter: true,
    team: true,
    business: true,
    enterprise: true,
  },
  {
    label: {
      value: "Video transcoding (WebM/MP4 at 1080p)",
      tooltip:
        "Regardless of what kind of video you upload, Shorthand will transcode it automatically into a high-quality web-friendly format for use within your story to provide an optimal experience for your audience.",
    },
    starter: true,
    team: true,
    business: true,
    enterprise: true,
  },
  {
    label: {
      value: "Maximum length per video",
      tooltip: "Each plan has a limit on the maximum video length. Videos longer than the limit will be trimmed.",
    },
    starter: "5 mins",
    team: "5 mins",
    business: "10 mins",
    enterprise: "10+ mins",
  },
  {
    label: {
      value: "Built-in responsive previewer",
      tooltip: "Preview your story on desktop, tablet and mobile from within the editor.",
    },
    starter: true,
    team: true,
    business: true,
    enterprise: true,
  },
  {
    label: {
      value: "Multi-story Projects",
      tooltip:
        "Projects enable you to manage and publish your stories as a single unit. Apply a theme across all your stories, and provide intuitive navigation between your stories.",
    },
    starter: true,
    team: true,
    business: true,
    enterprise: true,
  },
  {
    label: {
      value: "Collections/Rich Links",
      tooltip:
        "Create a list of rich media links to anything on the web (including other Shorthand stories). Great for linking out to non-Shorthand content on other parts of your website.",
    },
    starter: false,
    team: false,
    business: true,
    enterprise: true,
  },
  {
    label: {
      value: "Developer Tools (CSS/Javascript)",
      tooltip: "Add custom CSS and JavaScript for complete control over styling and integrations.",
    },
    starter: false,
    team: false,
    business: true,
    enterprise: true,
  },
];

export const brandFeatures: Feature[] = [
  {
    label: {
      value: "Built-in standard themes",
      tooltip:
        "Shorthand ships with a range of beautiful built-in themes that you can apply to your stories and Projects with a click.",
    },
    starter: true,
    team: true,
    business: true,
    enterprise: true,
  },
  {
    label: {
      value: "Theme builder",
      tooltip:
        "Keep your content on-brand and visually cohesive by creating reusable basic themes. Add your choice of Google fonts, colours and logos.",
    },
    starter: false,
    team: true,
    business: true,
    enterprise: true,
  },
  {
    label: {
      value: "Handcrafted bespoke themes",
      tooltip: "A handcrafted theme that styles all the standard Shorthand elements according to your brand guidelines.",
    },
    starter: false,
    team: false,
    business: true,
    enterprise: true,
  },
  {
    label: {
      value: "White labelling",
      tooltip: "Remove all Shorthand branding from your content, including the 'Built with Shorthand' footer",
    },
    starter: false,
    team: false,
    business: false,
    enterprise: true,
  },
];

export const publishingFeatures: Feature[] = [
  {
    label: {
      value: "Public Shorthand hosting on a custom domain",
      tooltip:
        "Publishing is simple with Shorthand Hosting. Use a custom subdomain (e.g., stories.yourdomain.com) or a shorthandstories.com subdomain.",
    },
    starter: true,
    team: true,
    business: true,
    enterprise: true,
  },
  {
    label: {
      value: "Self-hosted publishing options",
      tooltip: "Host your own content via Drupal and WordPress plugins, Amazon S3, SFTP, API publishing, and more.",
    },
    starter: false,
    team: false,
    business: "Annual only",
    enterprise: "Annual only",
  },
  {
    label: {
      value: "Story embed",
      tooltip: "Easily embed your content in any web page by copying and pasting the embed code snippet.",
    },
    starter: true,
    team: true,
    business: true,
    enterprise: true,
  },
  {
    label: {
      value: "Restricted access Shorthand hosting",
      tooltip:
        "Control who views your content with restricted-access publishing. Grant access to readers via email, and they'll be able to view the content using a One-Time Password.",
    },
    starter: false,
    team: false,
    business: true,
    enterprise: true,
  },
  {
    label: {
      value: "Purchase more publishing configurations",
      tooltip:
        "A publishing configuration controls where and how your stories are published. Each plan starts with one configuration. Additional configurations incur an extra fee. Get in touch with us to discuss your options.",
    },
    starter: false,
    team: true,
    business: true,
    enterprise: true,
  },
];

export const analyticsFeatures: Feature[] = [
  {
    label: {
      value: "Shorthand Analytics",
      tooltip: "Track story views and completions with simple, built-in analytics.",
    },
    starter: "Last 90 days",
    team: "Last 90 days",
    business: "Unlimited",
    enterprise: "Unlimited",
  },
  {
    label: {
      value: "Shorthand Analytics monthly digest",
      tooltip: "Receive a helpful monthly summary of your story performance by email.",
    },
    starter: true,
    team: true,
    business: true,
    enterprise: true,
  },
  {
    label: {
      value: "Analytics export per story",
      tooltip: "Export Shorthand Analytics data for individual stories in CSV format for reporting and analysis purposes.",
    },
    starter: false,
    team: true,
    business: true,
    enterprise: true,
  },
  {
    label: {
      value: "Analytics export per workspace",
      tooltip:
        "Understand the big picture of how your content is performing with workspace-level CSV data exports. Compile reports and perform analysis in 3rd party tools with ease.",
    },
    starter: false,
    team: false,
    business: true,
    enterprise: true,
  },
  {
    label: {
      value: "Google Analytics",
      tooltip: "Add Google Analytics 4 tracking to stories and projects.",
    },
    starter: false,
    team: true,
    business: true,
    enterprise: true,
  },
  {
    label: {
      value: "3rd-party analytics",
      tooltip: "Add 3rd party analytics and tracking codes, and marketing pixels.",
    },
    starter: false,
    team: false,
    business: true,
    enterprise: true,
  },
  {
    label: {
      value: "Tag managers, cookie managers & other integrations",
      tooltip:
        "Add tag managers, cookie consent managers, and any other code you require to integrate with existing software and tools.",
    },
    starter: false,
    team: false,
    business: true,
    enterprise: true,
  },
];

export const collaborationFeatures: Feature[] = [
  {
    label: {
      value: "Concurrent editing",
      tooltip: "Visual storytelling is a team sport — work on stories together with your colleagues in real time.",
    },
    starter: true,
    team: true,
    business: true,
    enterprise: true,
  },
  {
    label: {
      value: "In-editor comments",
      tooltip: "Ask your colleagues for help or leave feedback for them in the editor. Use @mentions to ensure they get notified!",
    },
    starter: true,
    team: true,
    business: true,
    enterprise: true,
  },
  {
    label: {
      value: "Preview sharing",
      tooltip: "Use Preview links to share your draft stories with colleagues or clients for their comments and sign-off.",
    },
    starter: true,
    team: true,
    business: true,
    enterprise: true,
  },
  {
    label: {
      value: "Teams",
      tooltip: "Arrange your stories and people into Teams to keep everything neat.",
    },
    starter: 1,
    team: 3,
    business: "Unlimited",
    enterprise: "Unlimited",
  },
];

export const technicalSupportFeatures: Feature[] = [
  {
    label: {
      value: "Email support",
      tooltip: "Use our in-app ticketing system or email help@shorthand.com to submit questions to our support team.",
    },
    starter: true,
    team: true,
    business: true,
    enterprise: true,
  },
  {
    label: {
      value: "Priority live support",
      tooltip: "Jump to the head of our support queue when you need our help.",
    },
    starter: false,
    team: false,
    business: true,
    enterprise: true,
  },
  {
    label: {
      value: "Service Level Agreements",
      tooltip: "We offer uptime guarantees and support response and resolution time obligations.",
    },
    starter: false,
    team: false,
    business: false,
    enterprise: true,
  },
  {
    label: {
      value: "Custom technical support tiers",
      tooltip: "Select a support offering with a priority response and tailored SLA.",
    },
    starter: false,
    team: false,
    business: false,
    enterprise: true,
  },
  {
    label: {
      value: "Custom integration development",
      tooltip:
        "We can work with you to build custom integrations for your CMS or to bring data into Shorthand from CRMs and other data sources.",
    },
    starter: false,
    team: false,
    business: false,
    enterprise: true,
  },
];

export const adminSecurityFeatures: Feature[] = [
  {
    label: {
      value: "Password protected previews",
      tooltip: "Control access to previews of unpublished content.",
    },
    starter: false,
    team: false,
    business: true,
    enterprise: true,
  },
  {
    label: {
      value: "Role-based access control",
      tooltip: "Assign roles to users to control who can view, edit, and publish stories, and who can configure the workspace.",
    },
    starter: false,
    team: false,
    business: true,
    enterprise: true,
  },
  {
    label: {
      value: "Downloadable story archives",
      tooltip: "Download your stories in ZIP format for archival purposes.",
    },
    starter: false,
    team: "Annual only",
    business: "Annual only",
    enterprise: true,
  },
  {
    label: {
      value: "SAML Single Sign-on",
      tooltip: "Enable your team members to sign in with your existing authentication system.",
    },
    starter: false,
    team: false,
    business: true,
    enterprise: true,
  },
  {
    label: {
      value: "SCIM",
      tooltip:
        "Streamline the onboarding and offboarding of your users onto Shorthand with System for Cross-Domain Identity Management.",
    },
    starter: false,
    team: false,
    business: false,
    enterprise: true,
  },
  {
    label: {
      value: "ISO 27001 certificate",
      tooltip:
        "Shorthand is ISO 27001 compliant. We are assessed for compliance by an accredited ISO 27001 body each year. Our certificate is publicly available for review.",
    },
    starter: true,
    team: true,
    business: true,
    enterprise: true,
  },
  {
    label: {
      value: "SOC 2 report",
      tooltip:
        "Shorthand is audited annually against the SOC 2 Trust Services Criteria. Our SOC 2 attestation report is available to Enterprise customers upon request.",
    },
    starter: false,
    team: false,
    business: false,
    enterprise: true,
  },
];

export const customerSuccessFeatures: Feature[] = [
  {
    label: {
      value: "Team onboarding session",
      tooltip: "Schedule a kick off with one of our expert Editorial Success Managers to help you get started with Shorthand.",
    },
    starter: false,
    team: "30 mins",
    business: "1 hour",
    enterprise: true,
  },
  {
    label: {
      value: "Story reviews",
      tooltip: "An Editorial Success Manager will be assigned to review and provide advice on your first story.",
    },
    starter: false,
    team: "1 review/year",
    business: "5 reviews/year",
    enterprise: true,
  },
  {
    label: {
      value: "Standard webinars & training",
      tooltip: "Learn how to get the most from Shorthand by attending our regular webinars and training sessions.",
    },
    starter: true,
    team: true,
    business: true,
    enterprise: true,
  },
  {
    label: {
      value: "Custom success plan & training",
      tooltip:
        "Select a customer success tier with tailored onboarding, training, workshops, and quarterly progress reviews for your team.",
    },
    starter: false,
    team: false,
    business: false,
    enterprise: true,
  },
  {
    label: {
      value: "Dedicated Success Partner",
      tooltip: "Partner with one of our expert Success Managers to ensure Shorthand is helping you meet your strategic initiatives.",
    },
    starter: false,
    team: false,
    business: false,
    enterprise: true,
  },
  {
    label: {
      value: "The Shorthand Community",
      tooltip: "The community for visual storytelling on the web.",
    },
    starter: true,
    team: true,
    business: true,
    enterprise: true,
  },
];
