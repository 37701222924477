import * as styles from "./Badge.module.scss";
import cx from "classnames";
import * as React from "react";

type BadgeProps = { children: React.ReactNode; highlight: boolean };

export const Badge = (props: BadgeProps): JSX.Element => {
  return (
    <div className={cx("tw-text-grey_scale-700", styles.badge, { [styles.badgeHighlight]: props.highlight })}>{props.children}</div>
  );
};
