import { Footer } from "../components/Footer";
import { Layout } from "../components/Layout";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";
import sal from "sal.js";

export default class Careers extends React.Component<any, any> {
  componentDidMount() {
    sal();
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <main id="main" className="marketing-content container-fluid">
          <Helmet
            bodyAttributes={{
              class: "body-shorthand body-marketing body-marketing-secondary body-marketing-careers body-light hide-elevio",
            }}
          ></Helmet>
          <section className="row section-title title-light pb-0">
            <div className="col-11 col-xs-8  mx-auto section-inner">
              <h1>
                <span className="shorthand-logo dark" aria-hidden="true">
                  Shorthand
                </span>
                Work with Shorthand
              </h1>
              <h2>We’re on a mission to revolutionise storytelling on the web, and we need your help.</h2>
              <a
                href="https://shorthand.bamboohr.com/jobs/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-lg btn-primary"
              >
                View open roles
              </a>
            </div>
          </section>
          <section className="row section section-intro pt-0">
            <div className="intro-media" aria-hidden="true">
              <div className="map-placemarker au">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className="map-placemarker nz">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className="map-placemarker ph">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className="map-placemarker sa">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className="map-placemarker uk">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className="map-placemarker us">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className="map-image">
                <img src="/media/careers/map.png" width="1331px" height="527px" alt="World map showing locations of Shorthand staff" />
              </div>
            </div>
            <div className="container container-sm text-center tw-font-CircularXXSub">
              <div className="row">
                <div className="col-sm-12 col-md-10 col-lg-8  mx-auto marketing-main-column">
                  <h3>Fully remote since 2017</h3>
                  <p>
                    Shorthand is an experienced remote company, with every team member working remotely, including the CEO. Founded in
                    Brisbane but growing globally, we’re a truly international team, and have mature processes and policies to make
                    sure everyone is successful and happy at work.
                  </p>
                  <p>
                    Our team comes from a range of cultures, backgrounds, and countries.{" "}
                    <em>We’re committed to building a diverse, safe, and enjoyable workplace for everyone on our team</em>.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="row section section-values">
            <div className="container container-sm text-center tw-font-CircularXXSub">
              <div aria-hidden="true" className="background-media background-media-fixed">
                <img src="/media/careers/careers-bg.jpeg" aria-hidden="true" alt="" />
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-10 col-lg-8  mx-auto marketing-main-column">
                  <h3>Our values</h3>
                  <p>
                    At Shorthand, we're creating a place where a group of extraordinary people — a band of geeks with art and heart —
                    can do their life's best work, achieve a sense of fulfilment while they're doing it, and ultimately help our
                    customers to move mountains.
                  </p>
                  <p>
                    <em>Here’s how we do it.</em>
                  </p>
                </div>
                <div className="col-sm-12 col-xl-10 mx-auto">
                  <div className="features features-dark features-cards mt-5">
                    <ul>
                      <li>
                        <div className="feature-card card-icon card-shadow">
                          <i className="fa fa-thumbtack" />
                          <h4>We simplify</h4>
                          <p>
                            We do the hard work of making complicated things simple for the benefit of our customers and our teammates.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="feature-card card-icon card-shadow">
                          <i className="fa fa-users" />
                          <h4>We work as a team</h4>
                          <p>
                            We listen first with the intent to understand, and bring critical thinking and a positive attitude to our
                            team interactions.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="feature-card card-icon card-shadow">
                          <i className="fa fa-brain" />
                          <h4>We think &mdash; then act</h4>
                          <p>We are comfortable with rapid experimentation and careful deliberation.</p>
                        </div>
                      </li>
                      <li>
                        <div className="feature-card card-icon card-shadow">
                          <i className="fa fa-globe" />
                          <h4>We’re diverse</h4>
                          <p>We bring to work our individual spirit and the diversity and new ideas that come with it.</p>
                        </div>
                      </li>
                      <li>
                        <div className="feature-card card-icon card-shadow">
                          <i className="fa fa-magic" />
                          <h4>We make magic</h4>
                          <p>We enchant our customers with our product and in every interaction.</p>
                        </div>
                      </li>
                      <li>
                        <div className="feature-card card-icon card-shadow">
                          <i className="fa fa-bolt" />
                          <h4>We create impact</h4>
                          <p>
                            We take pride in the results we achieve &mdash; not by our roles, titles, or the number of hours we work.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="row section section-pics">
            <img
              src="/media/careers/careers-pics.jpg"
              width="2357px"
              height="1000px"
              loading="lazy"
              alt="Photos of Shorthand team members"
            />
          </section>
          <section className="row section section-perks">
            <div className="container container-sm text-center tw-font-CircularXXSub">
              <div className="row">
                <div className="col-sm-12 col-md-10 col-lg-8  mx-auto marketing-main-column">
                  <h3>Working at Shorthand</h3>
                  <p>
                    Shorthand is a fast-growing company that provides a digital publishing platform to many of the world’s best known
                    publishers, brands, not-for-profits, and universities. With customers on every continent (except Antarctica!),
                    <em> there’s never been a more exciting time to join Shorthand</em>.
                  </p>
                </div>
                <div className="col-sm-12 col-xl-10 mx-auto">
                  <div className="features features-cards mt-5">
                    <ul>
                      <li>
                        <div className="feature-card card-icon card-shadow">
                          <i className="fa fa-bicycle" />
                          <h4>Health and Wellness</h4>
                          <p>Annual allowance to help you keep your mind and body healthy.</p>
                        </div>
                      </li>
                      <li>
                        <div className="feature-card card-icon card-shadow">
                          <i className="fa fa-cat" />
                          <h4>Home Office</h4>
                          <p>We help you get your home office sorted with our desk and chair allowance.</p>
                        </div>
                      </li>
                      <li>
                        <div className="feature-card card-icon card-shadow">
                          <i className="fa fa-laptop" />
                          <h4>Hardware</h4>
                          <p>We’ll set you up with an Apple Mac and any peripherals you need to do your job.</p>
                        </div>
                      </li>
                      <li>
                        <div className="feature-card card-icon card-shadow">
                          <i className="fa fa-gift" />
                          <h4>Welcome Gift</h4>
                          <p>We’ll post you a little parcel of goodies to welcome you to the team.</p>
                        </div>
                      </li>
                      <li>
                        <div className="feature-card card-icon card-shadow">
                          <i className="fa fa-shapes" />
                          <h4>Employee Options</h4>
                          <p>After passing probation, all staff are entitled to join our Employee Share Plan.</p>
                        </div>
                      </li>
                      <li>
                        <div className="feature-card card-icon card-shadow">
                          <i className="fa fa-umbrella-beach" />
                          <h4>Uncapped Leave</h4>
                          <p>We trust you to take as much leave as you need.</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <a
                    href="https://shorthand.bamboohr.com/jobs/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-lg btn-primary mt-4"
                  >
                    View open roles
                  </a>
                  <div className="alert text-center mt-5">
                    Don’t see anything that suits? We’re always on the lookout for talented people to join our team. Send an email with
                    your CV to <a href="mailto:careers@shorthand.com">careers@shorthand.com</a>.
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer className="mt-0" />
        </main>
      </Layout>
    );
  }
}

export const query = graphql`
  query CareersQuery {
    site {
      siteMetadata {
        name
        title
        tagline
        description
      }
    }
  }
`;
