import {
  Bbc,
  Christies,
  DowJones,
  EnglishHeritage,
  Greepeace,
  Honda,
  ManchesterCity,
  Nature,
  Nikkei,
  OxfordUni,
  Penguin,
  SaveTheChildren,
  TripAdvisor,
  Unicef,
} from "../SVGLogos";
import * as styles from "./LogosSection.module.scss";
import cx from "classnames";
import * as React from "react";

/**
 * 
 * @returns WHY ARE WE USING inline SVGs?
 * because we can't use gatsby-image for SVGs without the quality being badly impacted
// https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-plugin-image/#formats
*/
const FirstRow = () => {
  return (
    <>
      <Christies className={`${cx(styles.svgStyles, styles.christies)}`} />
      <Bbc className={cx(styles.svgStyles, styles.bbc)} />
      <Nature className={cx(styles.svgStyles, styles.nature)} />
      <Penguin className={cx(styles.svgStyles, styles.penguin)} />
      <Honda className={cx(styles.svgStyles, styles.honda)} />
      <TripAdvisor className={cx(styles.svgStyles, styles.tripAdvisor)} />
      <SaveTheChildren className={cx(styles.svgStyles, styles.saveTheChildren)} />
    </>
  );
};

const SecondRow = () => {
  return (
    <>
      <DowJones className={cx(styles.svgStyles, styles.dowJones)} />
      <ManchesterCity className={cx(styles.svgStyles, styles.manchesterCity)} />
      <Greepeace className={cx(styles.svgStyles, styles.greenPeace)} />
      <EnglishHeritage className={cx(styles.svgStyles, styles.englishHeritage)} />
      <Unicef className={cx(styles.svgStyles, styles.unicef)} />
      <OxfordUni className={cx(styles.svgStyles, styles.oxfordUni)} />
      <Nikkei className={cx(styles.svgStyles, styles.nikkei)} />
    </>
  );
};
export const LogosSection = ({ heading }: { heading?: string }): JSX.Element => {
  return (
    <>
      {heading && <p className={styles.logosParagraph}>{heading}</p>}
      <div className={styles.colStyles}>
        <FirstRow />
        <SecondRow />
      </div>
      <div className={styles.desktopWrapper}>
        <div className={styles.desktopFirstRow}>
          <FirstRow />
        </div>
        <div className={styles.desktopSecondRow}>
          <SecondRow />
        </div>
      </div>
    </>
  );
};
