import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default class FeaturesCollaboration extends React.Component<any, any> {
  render() {
    const { allBrandsJson } = this.props.data;

    const brands = allBrandsJson.edges;

    return (
      <Layout location={this.props.location}>
        <main id="main" className="marketing-content container-fluid">
          <Helmet
            bodyAttributes={{
              class: "body-shorthand body-marketing body-marketing-secondary body-marketing-features body-light hide-elevio",
            }}
          ></Helmet>
          <div className="row section-title title-light">
            <div className="col-11 col-sm-8  mx-auto section-inner">
              <h1>
                <span className="shorthand-logo dark" aria-hidden="true">
                  Shorthand
                </span>
                Collaboration
              </h1>
              <h2>Build stories with your entire team.</h2>
            </div>
          </div>

          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Concurrent editing</h3>
                  <p>
                    Shorthand supports concurrent editing by multiple users, giving your team the ability to work on the same story at
                    the same time. Gain visibility into who is actively editing specific story sections by the presence of their
                    avatar.
                  </p>
                  <ul className="features-list">
                    <li>
                      Train and upskill new team members with&nbsp;
                      <a className="btn-link" href="https://shorthand.com/training/">
                        Shorthand’s free webinars
                      </a>
                      .
                    </li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/collab-team.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>
          <section className="row section section-strip section-strip-right">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Clear permissions</h3>
                  <p>
                    Account administrators can easily audit and manage seat permissions. When staff leave, remove permissions with a
                    click.
                  </p>
                  <ul className="features-list">
                    <li>
                      Give your colleagues rights to access all stories in a team &mdash; or assign permissions on a story-by-story
                      basis.
                    </li>
                    <li>
                      Manage authentication with a growing range of services for SSO, including Google, Okta, Auth0, Microsoft Azure
                      Active Directory, Centrify, and GitHub (applicable plans only).
                    </li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/collab-permissions.png" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>
          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Story previews</h3>
                  <p>Share draft stories using our preview URL, making it easy to collaborate and QA stories before they go live. </p>
                  <ul className="features-list">
                    <li>
                      Allow colleagues and collaborators &mdash; including those outside of your Shorthand team &mdash; to view and
                      check your story.
                    </li>
                    <li>Showcase stories to stakeholders and managers before publishing them to your readers.</li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/visual-test.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section  section-strip-features-sm section-gray-lighest">
            <div className="container section-inner text-center">
              <div className="features features-cards icon-cards">
                <ul>
                  <li>
                    <div className="feature-card icon-card card-border card-shadow">
                      <div className="card-icon">
                        <i className="fas fa-layer-group"></i>
                      </div>
                      <div className="card-text">
                        <h3>Multiple workspaces</h3>
                        <p>Manage multiple Shorthand workspaces across your teams and brands.</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="feature-card icon-card card-border card-shadow">
                      <div className="card-icon">
                        <i className="fas fa-hand-pointer"></i>
                      </div>
                      <div className="card-text">
                        <h3>One-click invite</h3>
                        <p>Easily invite new team members to collaborate on stories.</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="feature-card icon-card card-border card-shadow">
                      <div className="card-icon">
                        <i className="fas fa-user-ninja"></i>
                      </div>
                      <div className="card-text">
                        <h3>Custom avatars</h3>
                        <p>Give your workspaces (and yourself) an avatar to make Shorthand feel like home.</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <section className="row section  section-strip section-strip-quote ">
            <div className="container container-sm">
              <div className="section-strip-col-single section-strip-single-quote">
                <div className="section-strip-quote-inner">
                  <blockquote>
                    &ldquo;We're getting 15 times the number of reads that we used to get. There's a general genuine buzz in the team
                    about what we’re publishing. There’s no way that was happening on the old website. Shorthand has been
                    transformational.&rdquo;
                  </blockquote>
                  <cite>Paul Abrahams | Head of Corporate Communications | RELX</cite>
                </div>
              </div>
            </div>
          </section>

          <section className="row section  section-strip-stories section-border-top">
            <div className="container flex-column section-inner text-center">
              <h3>Immersive storytelling in action</h3>
              <div className="featured-stories">
                <ul>
                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://www.honda.co.uk/engineroom/cars/civic-the-iconic-hatchback/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/honda-civic.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        Civic: The iconic hatchback <span className="featured-story-brand">Honda</span>
                      </h4>
                    </a>
                  </li>

                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://news.sky.com/story/better-for-brexit-how-uk-has-changed-since-leave-vote-11920143"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/sky-brexit.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        Brexit by numbers <span className="featured-story-brand">Sky News</span>
                      </h4>
                    </a>
                  </li>

                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://interactives.stuff.co.nz/2020/06/unemployment-crisis-covid-economic-recovery/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/stuff-deep.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        Deep trouble <span className="featured-story-brand">Stuff</span>
                      </h4>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="row section section-dark section-half section-trial !tw-bg-[rgba(249,249,249,0.3)]">
            <div className="container section-inner text-center">
              <h2 className="!tw-text-black">
                Ready to try the digital storytelling platform used by Honda, University of Cambridge, and the BBC?
              </h2>

              <div className="cta-container">
                <a href="https://app.shorthand.com/signup" className="btn btn-lg !tw-bg-black hover:!tw-opacity-70">
                  Start creating
                </a>
              </div>
              <div className="brands">
                <div className="brands-container">
                  <h3 className="as-used">Join these iconic brands</h3>
                  <ul>
                    {brands.map((brand, index) => (
                      <li key={`brand-${brand.node.label}-${index}`} className={`brand`}>
                        <img src={brand.node.logo} alt={`${brand.node.label} logo`} />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div aria-hidden="true" className="background-media background-media-fixed">
              <img src="/media/home/stories.jpg" className="ObjectFit--cover" alt="" />
            </div>
          </section>
          <section className="row section section-gray-lighest section-all-features">
            <div className="container section-inner text-center">
              <h2>
                <a className="unstyled" href="/features/">
                  More Shorthand features
                </a>
              </h2>

              <div className="features features-cards">
                <ul>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/scrollytelling/">
                      <div className="card-text">
                        <h3>Scrollytelling</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/visual-storytelling/">
                      <div className="card-text">
                        <h3>Visual Storytelling</h3>
                      </div>
                    </a>
                  </li>
                  {/* <li>
                  <a className="feature-card card-border card-shadow unstyled" href="/features/collaboration/">
                    <div className="card-text">
                      <h3>Collaboration</h3>
                    </div>
                  </a>
                </li> */}
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/customisation/">
                      <div className="card-text">
                        <h3>Customisation & Integration</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/branding/">
                      <div className="card-text">
                        <h3>Themes & Branding</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/sharing-and-analytics/">
                      <div className="card-text">
                        <h3>Sharing & Analytics</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/publishing-and-hosting/">
                      <div className="card-text">
                        <h3>Publishing & Hosting</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/support/">
                      <div className="card-text">
                        <h3>Support</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/security/">
                      <div className="card-text">
                        <h3>Security</h3>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <Footer className="mt-0" />
        </main>
      </Layout>
    );
  }
}

export const query = graphql`
  query FeaturesCollaborationQuery {
    site {
      siteMetadata {
        name
        title
        tagline
        description
      }
    }
    allBrandsJson {
      edges {
        node {
          logo
          name
          label
        }
      }
    }
  }
`;
