import { IconList } from "./IconList";
import * as React from "react";

export const BrandsTrust = ({ heading }: { heading?: string }): JSX.Element => {
  return (
    <div className="tw-mx-auto !tw-w-full ">
      {heading && <h2 className="!tw-text-[24px] !tw-font-medium !tw-text-dark_grey max-sm:tw-hidden">{heading}</h2>}
      <h2 className="tw-hidden tw-text-center !tw-text-[24px] !tw-font-medium !tw-text-dark_grey max-sm:tw-block">Trusted by</h2>
      <div className="tw-mt-6 tw-inline-flex tw-w-full tw-flex-nowrap tw-overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_white_128px,_white_calc(100%-200px),transparent_100%)]">
        <ul className="md:tw-justify-start [&_img]:max-w-none  tw-flex tw-animate-scroll tw-items-center tw-justify-center tw-gap-x-14 ">
          <IconList />
        </ul>
        <ul
          className="md:tw-justify-start [&_img]:max-w-none tw-ml-14  tw-ml-14  tw-flex tw-animate-scroll tw-items-center tw-justify-center tw-gap-x-14"
          aria-hidden="true"
        >
          <IconList />
        </ul>
      </div>
    </div>
  );
};
