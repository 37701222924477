import { IPlan, ITier, business, enterprise, starter, team } from "../../lib/src/common/plans-old";
import { RichTextDescriptions } from "./PricingPlan/Description/Description";
import { PlanAccordion } from "./PricingPlan/PlanAccordion/";
import { Price, symbolForPlanCurrency } from "./PricingPlan/Price/Price";
import { PricingPlan } from "./PricingPlan/PricingPlan";
import * as planStyles from "./PricingPlan/PricingPlan.module.scss";
import * as styles from "./PricingPlans.module.scss";
import { SLIDER_MAX } from "./Toolbar";
import { CONTACT_SALES, CONTACT_SALES_CTA, FREE_TRIAL, HEADLINES } from "./mockData";
import { businessTooltips, enterpriseTooltips, starterTooltips, teamTooltips } from "./tooltipsData";
import * as Accordion from "@radix-ui/react-accordion";
import * as React from "react";
import { currencyCodes } from "src/pages/Pricing";

let classNames = require("classnames");
type IBillingCycle = "monthly" | "yearly";

interface PlansProps {
  period: IBillingCycle;
  currency: { name: string; label: string };
  sliderValue: number;
  openContact: () => void;
  handleTeamClick: () => void;
  handleEnterpriseClick: () => void;
  handleBusinessClick: () => void;
}

export interface IActiveCurrency {
  name: string;
  symbol: string;
  label: string;
}

export const currencyOptions = [
  { label: "USD", value: "USD" },
  { label: "GBP", value: "GBP" },
  { label: "EUR", value: "EUR" },
  { label: "AUD", value: "AUD" },
];

export const PricingPlans = (props: PlansProps): JSX.Element => {
  const { currency, period, sliderValue, openContact, handleTeamClick, handleEnterpriseClick, handleBusinessClick } = props;
  const [isMobile, setIsMobile] = React.useState(false);
  const [defaultValue, setDefaultValue] = React.useState<string>("");
  const currencySymbol = symbolForPlanCurrency[currency?.label];

  React.useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1265) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function handleStarterClick(value: string) {
    window.location.assign("https://app.shorthand.com/signup");
  }

  function getPlanAveragedUnitPrice(plan: IPlan): string {
    return (getPriceForPlan(includedStories, plan.name, currency.label, period) / includedStories).toFixed(2);
  }

  function getUserAddonPrice(plan: IPlan): number {
    return plan.marketing.prices.userAddonPrice.monthly[0]?.price[currency?.label].toString();
  }

  function getPublishAddonPriceForTier(plan: IPlan, tier: number): string {
    return plan.marketing.prices.publishAddonPrice.monthly[tier]?.price[currency?.label].toString();
  }

  const desscriptionToolTips = {
    starter: [
      "Publish your first story for free, then pay for additional published stories as needed.",
      "Experiment as much as you like with draft content. You'll only be billed for published stories.",
      "One Shorthand-hosted publishing destination included. Bring your own subdomain or chose a shorthandstories.com subdomain.",
      "Add your colleagues for free.",
      "Work with your collaborators in one team, and upgrade when you need more teams to organise your collaborators and content.",
      "Deliver up to 10 GB of Shorthand story content to your audience each month. We'll let you know if you go over.",
    ],
    team: [
      `Five published stories included in the base price. Additional published stories billed monthly at ${currencySymbol}${getPublishAddonPriceForTier(
        team,
        1
      )}/story/month up to 20 stories, then ${currencySymbol}${getPublishAddonPriceForTier(
        team,
        2
      )}/story/month for the 21st and subsequent stories.`,
      "Experiment as much as you like with draft content. You'll only be billed for published stories.",
      "One Shorthand-hosted publishing destination included. Bring your own subdomain or chose a shorthandstories.com subdomain.",
      `Three users included in the base price. Additional users billed at ${currencySymbol}${getUserAddonPrice(team)}/user/month.`,
      "Work with your collaborators in 3 teams, and upgrade when you need more teams to organise your collaborators and content.",
      "Deliver up to 200 GB of Shorthand story content to your audience each month. We'll let you know if you go over.",
    ],
    business: [
      `Ten published stories included in the base price. Additional published stories billed at ${currencySymbol}${getPublishAddonPriceForTier(
        business,
        1
      )}/story/month up to 20 stories, then ${currencySymbol}${getPublishAddonPriceForTier(
        business,
        2
      )}/story/month for the 21st and subsequent stories.`,
      "Experiment as much as you like with draft content. You'll only be billed for published stories.",
      "Five Shorthand-hosted publishing destinations included, plus options for self-hosting and secure, password-protected publishing.",
      `Five users included in the base price. Additional users billed at ${currencySymbol}${getUserAddonPrice(business)}/user/month.`,
      "Work with your collaborators in 5 teams, and upgrade when you need more teams to organise your collaborators and content.",
      "Deliver up to 500 GB of Shorthand story content to your audience each month. We'll let you know if you go over.",
    ],
    enterprise: [
      "We'll tailor a package to meet your publishing needs.",
      "Experiment as much as you like with draft content. You'll only be billed for published stories.",
      "We'll tailor a package to meet your publishing needs. Choose from Shorthand-hosted or self-hosted publishing methods — or a combination of the two.",
      "We'll tailor a package to meet your needs.",
      "We'll tailor a package to meet your needs.",
      "We'll work with you to customise bandwidth inclusions that are just right for your audience size.",
    ],
  };

  function dynamicPriceForPlan(planName: "Starter" | "Team" | "Business" | "Enterprise"): number {
    return getPriceForPlan(includedStories, planName, currency?.label, period);
  }

  const includedStories = sliderValue + 1;
  const showStarterFromLabel = sliderValue >= SLIDER_MAX;
  const showTeamFromLabel = sliderValue + 1 >= 20;
  const showBusinessFromLabel = sliderValue + 1 >= SLIDER_MAX;

  const starterDynamicPrice = dynamicPriceForPlan("Starter");
  const teamDynamicPrice = showTeamFromLabel ? getPriceForPlan(20, "Team", currency?.label, period) : dynamicPriceForPlan("Team");
  const businessDynamicPrice = showBusinessFromLabel
    ? getPriceForPlan(30, "Business", currency?.label, period)
    : dynamicPriceForPlan("Business");
  const enterpriseStaticPrice = enterprise.marketing.prices.planPrice["monthly"][currency?.label];

  const sharedStyles = {
    marginBlockEnd: 0,
    marginBlockStart: 0,
    minHeight: "16.796875px",
    height: "16.796875px",
  };

  const sharedFonts = "tw-font-CircularXXSub tw-text-sm tw-font-bold";
  return (
    <div className={styles.planRow}>
      {!isMobile && <div className={styles.balloons} />}
      {isMobile ? (
        <Accordion.Root
          type="single"
          collapsible
          value={defaultValue}
          onValueChange={setDefaultValue}
          className={styles.AccordionRoot}
        >
          {/* Starter */}
          <PlanAccordion
            setDefaultOpenCallBack={defaultValue === "" ? setDefaultValue : undefined}
            planSelected={defaultValue}
            isDefaultPlan
            tooltips={starterTooltips}
            ctaText={"get started"}
            featuresHeadline={HEADLINES.marketing}
            plan={starter}
            ctaFunction={handleStarterClick}
            id="item-1"
          >
            <div
              style={{
                ...sharedStyles,
              }}
              className={sharedFonts}
            >
              {showStarterFromLabel && "From "}
            </div>
            <Price
              duration={period}
              userCurrency={currency}
              theme={"light"}
              dynamicPrice={starterDynamicPrice}
              unitPrice={getPlanAveragedUnitPrice(starter)}
            >
              <span style={{ marginTop: "15px", display: "block" }}>Billed monthly</span>
            </Price>

            <RichTextDescriptions
              tooltips={desscriptionToolTips.starter}
              plan={starter}
              theme={"light"}
              dynamicStoryCount={sliderValue}
            />
          </PlanAccordion>
          {/* Team */}
          <PlanAccordion
            isDefaultPlan={false}
            planSelected={defaultValue}
            tooltips={teamTooltips}
            ctaText={FREE_TRIAL}
            featuresHeadline={HEADLINES.starter}
            plan={team}
            ctaFunction={handleTeamClick}
            id="item-2"
          >
            <div
              className={sharedFonts}
              style={{
                ...sharedStyles,
              }}
            >
              {showTeamFromLabel && "From "}
            </div>
            <Price
              duration={period}
              userCurrency={currency}
              theme={"light"}
              dynamicPrice={teamDynamicPrice}
              unitPrice={includedStories < 6 ? getPublishAddonPriceForTier(team, 0) : getPlanAveragedUnitPrice(team)}
            >
              <span style={{ marginTop: "15px", display: "block" }}>Billed monthly</span>
            </Price>
            <RichTextDescriptions tooltips={desscriptionToolTips.team} plan={team} theme={"light"} dynamicStoryCount={sliderValue} />
          </PlanAccordion>
          {/* Business */}
          <PlanAccordion
            isDefaultPlan={false}
            planSelected={defaultValue}
            tooltips={businessTooltips}
            ctaText={FREE_TRIAL}
            formLink={{
              text: CONTACT_SALES,
              ctaFunction: openContact,
            }}
            featuresHeadline={HEADLINES.business}
            plan={business}
            ctaFunction={handleBusinessClick}
            isFeaturePlan={true}
            id="item-3"
          >
            <div>
              <div
                className={sharedFonts}
                style={{
                  ...sharedStyles,
                  color: "#fff",
                }}
              >
                {showBusinessFromLabel && "From "}
              </div>
              <Price
                duration={period}
                userCurrency={currency}
                dynamicPrice={businessDynamicPrice}
                theme={"dark"}
                unitPrice={includedStories < 11 ? getPublishAddonPriceForTier(business, 0) : getPlanAveragedUnitPrice(business)}
              >
                <span style={{ marginTop: "15px", display: "block" }}>Billed annually</span>
              </Price>
            </div>
            <RichTextDescriptions
              tooltips={desscriptionToolTips.business}
              plan={business}
              theme={"dark"}
              dynamicStoryCount={sliderValue}
            />
          </PlanAccordion>
          {/* Enterprise */}
          <PlanAccordion
            isDefaultPlan={false}
            planSelected={defaultValue}
            tooltips={enterpriseTooltips}
            ctaText={CONTACT_SALES_CTA}
            featuresHeadline={HEADLINES.enterprise}
            plan={enterprise}
            ctaFunction={handleEnterpriseClick}
            id="item-4"
          >
            <div>
              <div>
                <div
                  className={sharedFonts}
                  style={{
                    ...sharedStyles,
                  }}
                >
                  From{" "}
                </div>
                <Price duration={period} userCurrency={currency} dynamicPrice={enterpriseStaticPrice} theme={"light"} />

                <span
                  style={{
                    marginTop: "22px",
                    marginBottom: "22px",
                    display: "block",
                    fontSize: "0.875rem",
                  }}
                >
                  Billed annually
                </span>
              </div>
            </div>
            <RichTextDescriptions plan={enterprise} theme={"light"} tooltips={desscriptionToolTips.enterprise} />
          </PlanAccordion>
        </Accordion.Root>
      ) : (
        <>
          {/* Starter */}
          <PricingPlan
            tooltips={starterTooltips}
            ctaText={"get started"}
            featuresHeadline={HEADLINES.marketing}
            plan={starter}
            ctaFunction={handleStarterClick}
          >
            <div>
              <div>
                <div
                  className={sharedFonts}
                  style={{
                    ...sharedStyles,
                  }}
                >
                  {" "}
                  {showStarterFromLabel && "From "}
                </div>

                <Price
                  duration={period}
                  userCurrency={currency}
                  theme={"light"}
                  dynamicPrice={starterDynamicPrice}
                  unitPrice={getPlanAveragedUnitPrice(starter)}
                >
                  <span style={{ marginTop: "15px", display: "block" }}>Billed monthly</span>
                </Price>
              </div>
            </div>
            <RichTextDescriptions
              tooltips={desscriptionToolTips.starter}
              plan={starter}
              theme={"light"}
              dynamicStoryCount={sliderValue}
            />
          </PricingPlan>
          {/* Team */}
          <PricingPlan
            tooltips={teamTooltips}
            ctaText={FREE_TRIAL}
            featuresHeadline={HEADLINES.starter}
            plan={team}
            ctaFunction={handleTeamClick}
          >
            <div>
              <div>
                <div
                  className={sharedFonts}
                  style={{
                    ...sharedStyles,
                  }}
                >
                  {" "}
                  {showTeamFromLabel && "From "}
                </div>
                <Price
                  duration={period}
                  userCurrency={currency}
                  theme={"light"}
                  dynamicPrice={teamDynamicPrice}
                  unitPrice={includedStories < 6 ? getPublishAddonPriceForTier(team, 0) : getPlanAveragedUnitPrice(team)}
                >
                  <span style={{ marginTop: "18px", display: "block" }}>Billed monthly</span>
                </Price>
              </div>
            </div>
            <RichTextDescriptions tooltips={desscriptionToolTips.team} plan={team} theme={"light"} dynamicStoryCount={sliderValue} />
          </PricingPlan>
          {/* Business */}
          <PricingPlan
            tooltips={businessTooltips}
            ctaText={FREE_TRIAL}
            formLink={{
              text: CONTACT_SALES,
              ctaFunction: openContact,
            }}
            featuresHeadline={HEADLINES.business}
            plan={business}
            ctaFunction={handleBusinessClick}
            isFeaturePlan={true}
          >
            <div>
              <div>
                <div
                  className={sharedFonts}
                  style={{
                    ...sharedStyles,
                    color: "#fff",
                  }}
                >
                  {showBusinessFromLabel && "From "}
                </div>
                <Price
                  duration={period}
                  userCurrency={currency}
                  dynamicPrice={businessDynamicPrice}
                  theme={"dark"}
                  unitPrice={includedStories < 11 ? getPublishAddonPriceForTier(business, 0) : getPlanAveragedUnitPrice(business)}
                >
                  <span style={{ marginTop: "15px", display: "block" }}>Billed annually</span>
                </Price>
              </div>
            </div>
            <RichTextDescriptions
              tooltips={desscriptionToolTips.business}
              plan={business}
              theme={"dark"}
              dynamicStoryCount={sliderValue}
            />
          </PricingPlan>
          {/* Enterprise */}
          <PricingPlan
            tooltips={enterpriseTooltips}
            ctaText={CONTACT_SALES_CTA}
            featuresHeadline={HEADLINES.enterprise}
            plan={enterprise}
            ctaFunction={handleEnterpriseClick}
          >
            <div>
              <div>
                <div
                  className={sharedFonts}
                  style={{
                    ...sharedStyles,
                  }}
                >
                  From{" "}
                </div>
                <Price duration={period} userCurrency={currency} dynamicPrice={enterpriseStaticPrice} theme={"light"} />
                <span
                  style={{
                    marginTop: "15px",
                    display: "block",
                    fontSize: "0.875rem",
                  }}
                >
                  Billed annually
                  <br />
                </span>
              </div>
            </div>
            <RichTextDescriptions plan={enterprise} theme={"light"} tooltips={desscriptionToolTips.enterprise} />
          </PricingPlan>
        </>
      )}
    </div>
  );
};

/**
 * get the price for each plan
 *
 * @param liveStories
 * @param planName
 * @param currency
 * @param billingCycle
 * @returns price for the current plan and publish tier
 */
export function getPriceForPlan(liveStories: number, planName: string, currency: string, billingCycle: IBillingCycle): number {
  const planMapping: Record<string, IPlan> = {
    Business: business,
    Enterprise: enterprise,
    Starter: starter,
    Team: team,
  };

  const plan = planMapping[planName];
  const currencyCode = currency as keyof typeof currencyCodes;
  const basePrice = plan.marketing.prices.planPrice[billingCycle][currencyCode];
  const publishTiers = plan.marketing.prices.publishAddonPrice[billingCycle] as ITier[];
  const includedStories = publishTiers[0].quantity - 1;
  if (liveStories <= includedStories) return basePrice;

  return publishTiers.length === 1
    ? basePrice + publishTiers[0].price[currencyCode] * (liveStories - includedStories)
    : basePrice + calculateTierPricing(liveStories, publishTiers, currencyCode);
}

/**
 * calculate pricing for different publishing tiers
 * @param totalCount
 * @param tiers
 * @returns
 */
export function calculateTierPricing(totalCount: number, tiers: ITier[], currency: string): number {
  let totalPrice = 0;
  let remainingItems = totalCount - (tiers[0].quantity - 1);

  for (const [index, tier] of tiers.entries()) {
    const nextTierQuantity = (tiers[index + 1]?.quantity || totalCount + 1) - tier.quantity;
    const tierItems = Math.min(remainingItems, nextTierQuantity);

    totalPrice += tierItems * tier.price[currency];
    remainingItems -= tierItems;

    if (remainingItems <= 0) break;
  }

  return totalPrice;
}

export default PricingPlans;
