import { IPlan, IPriceStructure } from "./";

// 16/OCT/23 Copied from dylan and is not the single source of truth
export const enterprise: IPlan = {
  id: "enterprise",
  name: "Enterprise",
  description: "Enterprise",
  chargebee: new Set([
    "enterprise-monthly-aud",
    "enterprise-monthly-usd",
    "enterprise-monthly-eur",
    "enterprise-monthly-gbp",
    "enterprise-annual-aud",
    "enterprise-annual-usd",
    "enterprise-annual-eur",
    "enterprise-annual-gbp",
  ]),
  features: {
    "organisation-manage-publish-configurations": true,
    "organisation-team-leader-view": true,
    "organisation-team-limit": 50,
    "organisation-member-limit": 1000,
    "organisation-team-member-limit": 250,
    "organisation-sites": true,
    "page-settings-amp-enabled": false,
    "page-settings-analytics-ga": true,
    "page-settings-override-social-cover-url": true,
    "shorthand-analytics-tracking": true,
    "story-credits-required": false,
    "story-ga": true,
    "themes-demo": false,
    "publish-embed-ui": true,
    "story-comments": true,
    "theme-builder": true,
    "page-settings-password-protect-previews": true,
    "custom-hosting-limit": 5,
    "publish-configurations-download": true,
    "page-settings-analytics-gtm": true,
    "developer-tools": true,
    "page-settings-custom-head": true,
    "shorthand-secure-hosting-enabled": true,
    "story-hide-footer": true,
  },
  marketing: {
    label: "Mission-critical content at scale, tailored to your needs",
    className: "plan-enterprise",
    benefits: [
      "Bespoke custom branded theme",
      "White labelling",
      "SAML Single Sign-On (SSO)",
      "Custom technical support tiers",
      "Custom strategic success tiers",
    ],
    prices: {
      planPrice: {
        monthly: { USD: 2250, AUD: 3150, GBP: 1800, EUR: 2025 },
        yearly: { USD: 22500, AUD: 31500, GBP: 18000, EUR: 20250 },
      },
      userAddonPrice: {
        monthly: [
          { quantity: 21, price: { USD: 150, AUD: 150, GBP: 150, EUR: 150 } },
        ] /** assumed 1:1 mapping for each currency  as it was unclear if we are supporting this for enterprise*/,
        yearly: [
          {
            quantity: 21,
            price: { USD: 1500, AUD: 1500, GBP: 1500, EUR: 1500 },
          },
        ] /** assumed 1:1 mapping for each currency  as it was unclear if we are supporting this for enterprise*/,
      },
      publishAddonPrice: {
        monthly: [
          {
            quantity: 11,
            price: { USD: 100, AUD: 100, GBP: 100, EUR: 100 },
          } /** assumed 1:1 mapping for each currency  as it was unclear if we are supporting this for enterprise*/,
          {
            quantity: 51,
            price: { USD: 2.5, AUD: 2.5, GBP: 2.5, EUR: 2.5 },
          } /** assumed 1:1 mapping for each currency */,
        ],
        yearly: [
          {
            quantity: 11,
            price: { USD: 1000, AUD: 1000, GBP: 1000, EUR: 1000 },
          } /** assumed 1:1 mapping for each currency  as it was unclear if we are supporting this for enterprise*/,
          {
            quantity: 51,
            price: { USD: 25, AUD: 25, GBP: 25, EUR: 25 },
          } /** assumed 1:1 mapping for each currency  as it was unclear if we are supporting this for enterprise*/,
        ],
      },
    } as IPriceStructure,
  },
};
