import { useIsMobile } from "../../hooks/useIsMobile";
import { CTA_TEXT } from "../../pages/";
import { Heading } from "../Heading/Heading";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

export const SignupCTA = ({ heading }: { heading?: React.ReactNode }): JSX.Element => {
  const defaultHeading = (
    <>
      Beautifully{` `}
      <span className="tw-font-PassengerSerif tw-italic">simple </span> <br />
      storytelling
    </>
  );

  const isMobile = useIsMobile(450);

  return (
    <section className="tw-relative tw-max-h-[535px] !tw-min-h-[535px]  tw-w-full  tw-bg-black tw-text-white max-sm:!tw-h-auto max-sm:tw-p-0 ">
      <div className="tw-absolute tw-left-0 tw-z-20 tw-w-full max-container:!tw-top-[40%] max-container:!-tw-translate-y-[45%] max-sm:!tw-top-[20%] max-sm:!tw-pt-[20px] min-container:!tw-top-[50%] min-container:!tw-w-1/2 min-container:!-tw-translate-y-[45%]">
        <div className="tw-flex tw-h-full tw-w-full tw-gap-y-11  max-container:!tw-justify-center min-container:!tw-ml-[18%]">
          <div>
            {isMobile ? (
              <Heading headerType={2} className="!tw-text-[52px] !tw-font-semibold">
                <>
                  Beautifully <br />
                  <span className="tw-font-PassengerSerif tw-italic">simple</span> <br />
                  storytelling
                </>
              </Heading>
            ) : (
              <Heading headerType={2} className="!tw-text-[52px] !tw-font-semibold ">
                {heading || defaultHeading}
              </Heading>
            )}
            <div>
              <a
                href="https://app.shorthand.com/signup"
                className="btn btn-lg btn-gradient !tw-m-0 !tw-font-bold max-xl:!tw-text-lg max-sm:!tw-text-sm min-xl:!tw-mt-[44px]"
              >
                {CTA_TEXT}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="tw-absolute tw-relative tw-right-0 tw-z-10 max-container:!tw-left-0  max-container:!tw-scale-[120%] max-sm:!tw-scale-[75%] max-sm:!tw-pt-[150px] min-container:!tw-top-[-100px] ">
        <div className="tw-relative tw-flex tw-justify-end tw-gap-9 max-container:!tw-left-0  max-container:!tw-mt-[-180px]  max-container:!tw-justify-center max-sm:tw-gap-4">
          <div className="tw-flex tw-flex-col tw-gap-9">
            <div className="tw-aspect-square tw-w-[367px] tw-rounded-full tw-bg-brand_scale-950"></div>
            <div className="tw-aspect-square tw-w-[367px] tw-overflow-hidden tw-rounded-[50px]">
              <StaticImage
                alt="Graphical image"
                src="../../../static/media/home/footer-image.png"
                className="ObjectFit--cover"
                aria-hidden="true"
                placeholder="blurred"
                layout="fixed"
              />
            </div>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-y-7 ">
            <div className="tw-mt-[58px] tw-aspect-square tw-w-[367px]  tw-rounded-[50px] tw-bg-secondary-Bossanova"></div>
            <div className="tw-aspect-square tw-w-[367px] tw-rounded-full tw-bg-secondary-Bronze"></div>
          </div>
        </div>
      </div>
      <div className="tw-absolute tw-z-10 tw-min-h-[400px] tw-w-full tw-bg-gradient-to-b tw-from-black/[0.95] tw-from-[53%] max-container:!tw-top-[-78px] max-container:!tw-h-[400px] max-sm:!tw-top-[-100px] min-container:!tw-top-[-218px] min-container:!tw-top-[-300px] "></div>
    </section>
  );
};
