import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { Link, graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default class FeaturesSecurity extends React.Component<any, any> {
  render() {
    const { allBrandsJson } = this.props.data;

    const brands = allBrandsJson.edges;

    return (
      <Layout location={this.props.location}>
        <div id="main" className="marketing-content container-fluid">
          <Helmet
            bodyAttributes={{
              class: "body-shorthand body-marketing body-marketing-secondary body-marketing-features body-light hide-elevio",
            }}
          ></Helmet>
          <div className="row section-title title-light">
            <div className="col-11 col-sm-8  mx-auto section-inner">
              <h1>
                <span className="shorthand-logo dark" aria-hidden="true">
                  Shorthand
                </span>
                Security
              </h1>
              <h2>Enterprise-grade security for your digital publishing.</h2>
            </div>
          </div>

          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Host on your infrastructure</h3>
                  <p>Publish stories directly to your own infrastructure, ensuring that you manage your media assets.</p>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/security-host.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>
          <section className="row section section-strip section-strip-right">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>SAML-based SSO</h3>
                  <p>
                    Authenticate your team with your own enterprise SSO, including Google, Okta, Auth0, Microsoft Azure Active
                    Directory, Centrify, and GitHub.
                  </p>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/security-sso.png" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>
          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>ISO27001 and SOC2 compliant</h3>
                  <p>
                    Shorthand's{" "}
                    <a href="https://cvs.babcert.com/babcert.asp?c=234513&v=53r3k1irp2" target="_blank" rel="noopener noreferrer">
                      ISO27001 certification
                    </a>{" "}
                    and SOC2 compliance will give your team confidence about our security policies and{" "}
                    <Link to="/security/">practices</Link>, and streamline your procurement of Shorthand.
                  </p>
                  <div className="security-badges">
                    <a href="https://cvs.babcert.com/babcert.asp?c=234513&v=53r3k1irp2" target="_blank" rel="noopener noreferrer">
                      <img src="/media/features/badge-iso27001.png" alt="ISO27001 badge" />
                    </a>
                    <img src="/media/features/badge-soc2.png" alt="SOC2 badge" />
                  </div>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/security-caiq.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-right">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Accessible for all users</h3>
                  <p>
                    Shorthand works to ensure everyone can access and use our services. We are Section 508 compliant, and continually
                    update our product to meet the current WCAG and ensure AA compliance. You can find all the details in our
                    current&nbsp;
                    <a href="/media/security/shorthand-vpat-2023.pdf" target="_blank" rel="noopener noreferrer">
                      VPAT
                    </a>
                    .
                  </p>
                  <div className="security-badges">
                    <img src="/media/security/wcag2.1AA-blue-v.svg" alt="" aria-hidden="true" />
                  </div>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/security-a11y.png" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section  section-strip section-strip-quote ">
            <div className="container container-sm">
              <div className="section-strip-col-single section-strip-single-quote">
                <div className="section-strip-quote-inner">
                  <blockquote>
                    &ldquo;We had a lot of specific requirements, but the team at Shorthand bent over backwards for us so many
                    times.&rdquo;
                  </blockquote>
                  <cite>Andrew Youngson | News and Campaigns Content Manager | Imperial College London</cite>
                </div>
              </div>
            </div>
          </section>

          <section className="row section  section-strip-stories section-border-top">
            <div className="container flex-column section-inner text-center">
              <h3>Our customers build the world’s best digital stories</h3>
              <div className="featured-stories">
                <ul>
                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://stories.relx.com/relx-environmental-challenge/index.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/relx-environment.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        Environmental Challenge <span className="featured-story-brand">RELX</span>
                      </h4>
                    </a>
                  </li>

                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://www.honda.co.uk/engineroom/cars/honda-e-listening/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/honda-listen.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        Say hello to the all new Honda-E <span className="featured-story-brand">Honda</span>
                      </h4>
                    </a>
                  </li>

                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://www.mancity.com/features/rise-of-raheem-sterling/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/man-raheem.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        The rise of Raheem Stirling <span className="featured-story-brand">Manchester City</span>
                      </h4>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="row section section-dark section-half section-trial !tw-bg-[rgba(249,249,249,0.3)]">
            <div className="container section-inner text-center">
              <h2 className="!tw-text-black">
                Ready to try the digital storytelling platform used by Honda, University of Cambridge, and the BBC?
              </h2>

              <div className="cta-container">
                <a href="https://app.shorthand.com/signup" className="btn btn-lg !tw-bg-black hover:!tw-opacity-70">
                  Start creating
                </a>
              </div>
              <div className="brands">
                <div className="brands-container">
                  <h3 className="as-used">Join these iconic brands</h3>
                  <ul>
                    {brands.map((brand, index) => (
                      <li key={`brand-${brand.node.label}-${index}`} className={`brand`}>
                        <img src={brand.node.logo} alt={`${brand.node.label} logo`} />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div aria-hidden="true" className="background-media background-media-fixed">
              <img src="/media/home/stories.jpg" className="ObjectFit--cover" alt="" />
            </div>
          </section>
          <section className="row section section-gray-lighest section-all-features">
            <div className="container section-inner text-center">
              <h2>
                <a className="unstyled" href="/features/">
                  More Shorthand features
                </a>
              </h2>

              <div className="features features-cards">
                <ul>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/scrollytelling/">
                      <div className="card-text">
                        <h3>Scrollytelling</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/visual-storytelling/">
                      <div className="card-text">
                        <h3>Visual Storytelling</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/collaboration/">
                      <div className="card-text">
                        <h3>Collaboration</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/customisation/">
                      <div className="card-text">
                        <h3>Customisation & Integration</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/branding/">
                      <div className="card-text">
                        <h3>Themes & Branding</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/sharing-and-analytics/">
                      <div className="card-text">
                        <h3>Sharing & Analytics</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/publishing-and-hosting/">
                      <div className="card-text">
                        <h3>Publishing & Hosting</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/support/">
                      <div className="card-text">
                        <h3>Support</h3>
                      </div>
                    </a>
                  </li>
                  {/* <li>
                  <a className="feature-card card-border card-shadow unstyled" href="/features/security/">
                    <div className="card-text">
                      <h3>Security</h3>
                    </div>
                  </a>
                </li> */}
                </ul>
              </div>
            </div>
          </section>
          <Footer className="mt-0" />
        </div>
      </Layout>
    );
  }
}

export const query = graphql`
  query FeaturesSecurityQuery {
    site {
      siteMetadata {
        name
        title
        tagline
        description
      }
    }
    allBrandsJson {
      edges {
        node {
          logo
          name
          label
        }
      }
    }
  }
`;
