import { Enquiry } from "../components/Enquiry";
import { FAQAccordion } from "../components/FAQ";
import { Footer } from "../components/Footer";
import { Layout } from "../components/Layout";
import { Discounts } from "../components/PricingPlans-old/Discounts";
import { PricingPlans } from "../components/PricingPlans-old/PricingPlans";
import { ToolBar } from "../components/PricingPlans-old/Toolbar";
import { BrandsTrust } from "../components/Sections/Brandstrust";
import { getHostname } from "../services/Utils";
import axios from "axios";
import { Link } from "gatsby";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import { Waypoint } from "react-waypoint";

enum Discount {
  Teaching = "TEA100",
  NotForProfit = "NFP20",
  Education = "EDU50",
}

export const currencyCodes = {
  USD: { name: "USD", label: "USD" },
  GBP: { name: "GBP", label: "GBP" },
  EUR: { name: "EUR", label: "EUR" },
  AUD: { name: "AUD", label: "AUD" },
};

const periods = ["monthly", "yearly"];

interface GeoData {
  chargeCurrency: string;
  countring: string;
  currency: string;
}

export default class Plans extends React.Component<any, any> {
  constructor(props: any, context: any) {
    super(props, context);
    const isActiveForm = false;
    this.state = {
      stickyToolbar: false,
      enableWaypoint: false,
      sliderValue: 0,
      activeForm: isActiveForm,
      isFeaturesPaneVisible: false,
      selectedCurrency: currencyCodes.AUD,
      period: periods[0],
      prePopulatedContactFormFields: {},
    };
    this.openContact = this.openContact.bind(this);
    this.setSelectedCurrency = this.setSelectedCurrency.bind(this);
    this.setSliderValue = this.setSliderValue.bind(this);
    this.setPeriod = this.setPeriod.bind(this);
    this.handleTeamClick = this.handleTeamClick.bind(this);
    this.handleBusinessClick = this.handleBusinessClick.bind(this);
    this.handleEnterpriseClick = this.handleEnterpriseClick.bind(this);
  }

  async fetchGeoData(): Promise<void> {
    try {
      const res: any = await axios.get(`${getHostname("api")}/geo-data`);
      if (res?.data?.chargeCurrency && Object.keys(currencyCodes).includes(res?.data?.chargeCurrency)) {
        this.setState({ currency: res.data.chargeCurrency });
      } else {
        this.setState({ currency: "USD" });
      }
    } catch (err) {
      // Fallback to show USD
      this.setState({ currency: "USD" });
    }
  }

  handleTeamClick(): void {
    const prePopulatedFields = {
      interestedIn: "setting-up-a-trial",
      message: "Starting a trial of the Team plan",
    };
    this.setState(() => ({
      showPortal: true,
      prePopulatedContactFormFields: prePopulatedFields,
    }));
  }

  handleBusinessClick(): void {
    const prePopulatedFields = {
      interestedIn: "setting-up-a-trial",
      message: "Starting a trial of the Business plan",
    };
    this.setState(() => ({
      showPortal: true,
      prePopulatedContactFormFields: prePopulatedFields,
    }));
  }

  handleEnterpriseClick(): void {
    const prePopulatedFields = {
      interestedIn: "plans-and-pricing",
      message: "Learning more about the Enterprise plan",
    };
    this.setState(() => ({
      showPortal: true,
      prePopulatedContactFormFields: prePopulatedFields,
    }));
  }

  openContact() {
    this.setState(() => ({
      showPortal: true,
      prePopulatedContactFormFields: {},
    }));
  }

  setSliderValue(value: number) {
    this.setState(() => ({
      sliderValue: value,
    }));
  }

  componentDidMount() {
    this.fetchGeoData();
    if (this.state.activeForm) {
      setTimeout(() => this.openContact(), 500);
    }
    if (typeof window !== "undefined") {
      this.setState({ enableWaypoint: true });
    }
  }

  _handleSubNaveWayPointEnter = () => {
    this.setState({ stickyToolbar: false });
  };

  _handleSubNaveWayPointLeave = () => {
    this.setState({ stickyToolbar: true });
  };

  setSelectedCurrency(selectedValue: { label: string; value: string }): void {
    this.setState(() => ({
      selectedCurrency: selectedValue,
    }));
  }

  setPeriod(value: string) {
    this.setState({ period: value });
  }

  render() {
    const portalProps = {
      type: "pricing-enquiry",
      subject: "pricing-enquiry",
      source: "marketing/plans",
      onClose: () => this.setState(() => ({ showPortal: false })),
    };

    return (
      <Layout location={this.props.location}>
        <main id="main" className="marketing-content container-fluid">
          <Helmet
            bodyAttributes={{
              class: "body-shorthand body-marketing body-marketing-secondary body-light body-marketing-pricing hide-elevio",
            }}
          />
          {this.state.showPortal &&
            ReactDOM.createPortal(
              <Enquiry {...portalProps} prePopulatedFields={this.state.prePopulatedContactFormFields} />,
              document.getElementById("modal")
            )}
          <div className="row section-title title-light">
            <div className="col-12 col-sm-8  mx-auto section-inner">
              <h1 className="pricing-header">
                <Link to="/" className="shorthand-logo dark">
                  Shorthand
                </Link>
                Storytelling at any scale
              </h1>
              <h2>Use the slider to estimate your costs.</h2>
            </div>
          </div>
          <Waypoint scrollableAncestor={null} onEnter={this._handleSubNaveWayPointEnter} onLeave={this._handleSubNaveWayPointLeave} />
          <ToolBar
            isSticky={this.state.stickyToolbar}
            sliderValue={this.state.sliderValue}
            setSliderValue={this.setSliderValue}
            selectedCurrency={this.state.selectedCurrency}
            setSelectedCurrency={this.setSelectedCurrency}
          />

          <PricingPlans
            currency={this.state.selectedCurrency}
            period={this.state.period}
            sliderValue={this.state.sliderValue}
            openContact={this.openContact}
            handleTeamClick={this.handleTeamClick}
            handleEnterpriseClick={this.handleEnterpriseClick}
            handleBusinessClick={this.handleBusinessClick}
          />
          <div className="!tw-mt-9 tw-border-t tw-border-black/5 tw-text-center ">
            <div className="tw-py-14">
              <BrandsTrust heading="Trusted by the world’s leading brands" />
            </div>
          </div>
          <Discounts openContact={this.openContact} />
          <FAQAccordion heading="Frequently Asked Questions" questionGroup="pricing" theme="light-theme" />
          <div className="tw-flex tw-justify-center tw-bg-white tw-p-[40px]">
            <button className="btn btn-secondary mx-auto" onClick={this.openContact}>
              More questions? Contact us!
            </button>
          </div>
          <Footer />
        </main>
      </Layout>
    );
  }
}
