import { business, enterprise, starter, team } from "../../lib/src/common/plans";
import { Price, symbolForPlanCurrency } from "./PricingPlan/Price/Price";
import { PricingPlan } from "./PricingPlan/PricingPlan";
import * as styles from "./PricingPlans.module.scss";
import * as React from "react";

export type IBillingCycle = "monthly" | "yearly";
export type Option = { label: string; value: string };
type PlansProps = {
  discount: string;
  showDiscount: boolean;
  period: IBillingCycle;
  currency: { name: string; label: string };
  notForProfitActivated: boolean;
  handleTeamClick: () => void;
  handleStarterClick: () => void;
  handleEnterpriseClick: () => void;
  handleBusinessClick: () => void;
  handlePeriodChange: (value: boolean) => void;
};

export const PricingPlans = (props: PlansProps): JSX.Element => {
  const {
    currency,
    period,
    discount,
    handleTeamClick,
    handleStarterClick,
    handleEnterpriseClick,
    handleBusinessClick,
    handlePeriodChange,
    showDiscount,
    notForProfitActivated,
  } = props;

  const getPlanPrice = (plan, priceType, period, currency, notForProfitActivated) => {
    return !notForProfitActivated
      ? plan.marketing.prices[priceType][period][currency.label]
      : plan.marketing.prices[priceType].nfp[currency.label];
  };

  const starterPrice = getPlanPrice(starter, "planPrice", period, currency, notForProfitActivated);
  const teamPrice = getPlanPrice(team, "planPrice", period, currency, notForProfitActivated);
  const businessPrice = getPlanPrice(business, "planPrice", period, currency, notForProfitActivated);

  const starterAdditionalUserPrice = starter.marketing.prices.userAddonPrice[period][currency.label];
  const teamAdditionalUserPrice = getPlanPrice(team, "userAddonPrice", period, currency, notForProfitActivated);
  const businessAdditionalUserPrice = getPlanPrice(business, "userAddonPrice", period, currency, notForProfitActivated);

  const teamAdditionalStoryPrice = getPlanPrice(team, "publishAddonPrice", period, currency, notForProfitActivated);
  const businessAdditionalStoryPrice = getPlanPrice(business, "publishAddonPrice", period, currency, notForProfitActivated);

  const symbol = symbolForPlanCurrency[currency.label];
  const periodLabel = "month";

  return (
    <div className={styles.planRow}>
      <PricingPlan
        theme="dark"
        showDiscount={showDiscount}
        discount={discount}
        ctaText={"Get started"}
        period={period}
        plan={starter}
        ctaFunction={handleStarterClick}
        switchFunction={handlePeriodChange}
      >
        <div>
          <Price period={"monthly"} userCurrency={currency} theme={"dark"} dynamicPrice={starterPrice}></Price>
          <p className={styles.paragraph}>Includes:</p>
          <p className={styles.paragraph}>
            1 person <br />({symbol}
            {starterAdditionalUserPrice} / {periodLabel} per additional person)
          </p>
          <p className={styles.paragraph}>Unlimited published stories</p>
        </div>
      </PricingPlan>
      <PricingPlan
        showDiscount={showDiscount}
        theme="dark"
        discount={discount}
        ctaText={"Request free trial"}
        period={period}
        plan={team}
        ctaFunction={handleTeamClick}
        switchFunction={handlePeriodChange}
      >
        <div>
          <Price period={"monthly"} userCurrency={currency} theme={"dark"} dynamicPrice={teamPrice}></Price>
          <p className={styles.paragraph}>Includes everything in Starter plus:</p>
          <p className={styles.paragraph}>
            3 people <br />({symbol}
            {teamAdditionalUserPrice} / {periodLabel} per additional person)
          </p>
          <p className={styles.paragraph}>
            5 published stories
            <br />({symbol}
            {teamAdditionalStoryPrice} / {periodLabel} per additional story)
          </p>
          <p className={styles.paragraph}>Get in touch for annual billing and pricing for unlimited stories.</p>
        </div>
      </PricingPlan>
      <PricingPlan
        theme="dark"
        showDiscount={showDiscount}
        discount={discount}
        ctaText={"Request free trial"}
        period={period}
        plan={business}
        ctaFunction={handleBusinessClick}
        switchFunction={handlePeriodChange}
      >
        <div>
          <Price period={"monthly"} userCurrency={currency} theme={"dark"} dynamicPrice={businessPrice}></Price>
          <p className={styles.paragraph}>Includes everything in Team plus:</p>
          <p className={styles.paragraph}>
            6 people <br />({symbol}
            {businessAdditionalUserPrice} / {periodLabel} per additional person)
          </p>
          <p className={styles.paragraph}>
            5 published stories
            <br />({symbol}
            {businessAdditionalStoryPrice} / {periodLabel} per additional story)
          </p>
          <p className={styles.paragraph}>Get in touch for annual billing and pricing for unlimited stories.</p>
        </div>
      </PricingPlan>

      <PricingPlan
        showDiscount={showDiscount}
        ctaText="Contact sales"
        discount={discount}
        theme="dark"
        period={period}
        plan={enterprise}
        ctaFunction={handleEnterpriseClick}
        switchFunction={handlePeriodChange}
      >
        <div>
          <p className={styles.contactUs}>Contact Us</p>
          <p className={styles.paragraph}>Includes everything in Business plus:</p>
          <p className={styles.paragraph}>A plan and terms tailored to suit your requirements.</p>
          <p className={styles.paragraph}>
            Battle-tested security and compliance. We work with highly regulated enterprises and with governments everywhere.
          </p>
        </div>
      </PricingPlan>
    </div>
  );
};
