import { IPlan, IPriceStructure } from "./";

// 16/OCT/23 Copied from dylan and is not the single source of truth
export const starter: IPlan = {
  id: "starter",
  name: "Starter",
  description: "Unlimited Users. \nIncludes 1 live story. \nAdditional live stories $1/month",
  chargebee: new Set([
    "starter-monthly-aud",
    "starter-monthly-usd",
    "starter-monthly-eur",
    "starter-monthly-gbp",
    "starter-annual-aud",
    "starter-annual-usd",
    "starter-annual-eur",
    "starter-annual-gbp",
  ]),
  features: {
    "organisation-manage-publish-configurations": true,
    "organisation-team-leader-view": true,
    "organisation-team-limit": 1,
    "organisation-member-limit": 200,
    "organisation-team-member-limit": 200,
    "organisation-sites": true,
    "preview-banner": true,
    "page-settings-amp-enabled": false,
    "page-settings-analytics-ga": false,
    "page-settings-override-social-cover-url": true,
    "shorthand-analytics-tracking": true,
    "story-credits-required": false,
    "story-ga": true,
    "themes-demo": false,
    "publish-embed-ui": true,
    "story-comments": true,
  },
  marketing: {
    label: "Explore premium story design. First story free",
    className: "plan-starter",
    benefits: ["Shorthand hosting", "Story embedding", "Shorthand Analytics", "Webinars and training"],
    prices: {
      planPrice: {
        monthly: { USD: 0, AUD: 0, GBP: 0, EUR: 0 },
        yearly: { USD: 0, AUD: 0, GBP: 0, EUR: 0 },
      },
      userAddonPrice: { monthly: 0, yearly: 0 },
      publishAddonPrice: {
        monthly: [{ quantity: 2, price: { USD: 1, AUD: 1, GBP: 1, EUR: 1 } }],
        yearly: [{ quantity: 2, price: { USD: 10, AUD: 10, GBP: 10, EUR: 10 } }],
      },
    } as IPriceStructure,
  },
};
