import { Enquiry } from "../components/Enquiry";
import { Heading } from "../components/Heading/Heading";
import { Layout } from "../components/Layout";
import { SharedLayout } from "../components/Templates";
import { getHostname } from "../services/Utils";
import axios from "axios";
import { format } from "date-fns";
import { Link } from "gatsby";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Helmet } from "react-helmet";

const HELMET_PROPS = {
  class: "body-shorthand body-marketing body-marketing-secondary body-marketing-training hide-elevio",
};

export default class TrainingView extends React.Component<
  {},
  {
    isLoading: boolean;
    webinars: any;
    err: string;
    showPortal: boolean;
  }
> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      webinars: null,
      err: null,
      showPortal: false,
    };
    this.openContact = this.openContact.bind(this);
  }

  openContact() {
    this.setState(() => ({ showPortal: true }));
  }

  componentDidMount() {
    const uri = `${getHostname("api")}/webinars?hostId=MgsB4Kg5SOKysZmxxbBh0w&pageSize=6`;
    axios
      .get(uri)
      .then(res => {
        this.setState(() => ({ isLoading: false, webinars: res.data }));
      })
      .catch(err => {
        let message = "Webinars are not available at this time.";
        if (err && err.response && err.response.data && err.response.data.message) {
          message = err.response.data.message;
        } else if (err && err.message) {
          message = err.message;
        }
        this.setState(() => ({
          isLoading: false,
          webinars: null,
          err: message,
        }));
      });
  }

  render() {
    const { isLoading, webinars, err } = this.state;

    const portalProps = {
      type: "training-enquiry",
      subject: "training-enquiry",
      source: "marketing/training",
      onClose: () => this.setState(() => ({ showPortal: false })),
    };

    const hasWebinars = !isLoading && webinars && webinars.length > 0;

    return (
      <Layout location={this.props.location} theme={"dark-theme"}>
        <main id="main" className="marketing-content container-fluid container-training tw-bg-black">
          <Helmet bodyAttributes={HELMET_PROPS} />
          <div className="row section-title title-small">
            <div className="col-11 col-sm-8  mx-auto section-inner">
              <h1>
                <Link to="/" className="shorthand-logo">
                  Shorthand
                </Link>{" "}
                Webinars &amp; Training
              </h1>
              <Heading
                headerType={2}
                className="tw-w-full tw-pt-[18px] !tw-text-center !tw-text-2xl !tw-font-normal !tw-text-grey_scale-200 max-sm:!tw-w-full max-sm:!tw-text-xl"
              >
                Discover how to get the most out of Shorthand with best practice tips, community events &amp; inspiring examples.
                Register now &mdash; it’s free!
              </Heading>
            </div>
          </div>
          {this.state.showPortal && ReactDOM.createPortal(<Enquiry {...portalProps} />, document.getElementById("modal"))}
          <div className="row section-webinars justify-content-center">
            <div className="col-11 col-sm-10 mx-auto webinars">
              <div className="intro-text webinars-panel" />
              {isLoading && (
                <div className="webinars-panel error-text text-center">
                  <i className="fa fa-circle-notch fa-spin" aria-hidden="true" />
                </div>
              )}
              {!isLoading && (!hasWebinars || err) && (
                <div className="webinars-panel error-text text-center">
                  {err ? err : "There are currently no scheduled training sessions"}.{" "}
                  <button className="btn-link" onClick={this.openContact}>
                    Please contact us to discuss training.
                  </button>
                </div>
              )}
              {hasWebinars && (
                <div className="webinars-panel">
                  <ul id="webinars">
                    {webinars.map(webinar => (
                      <Webinar key={webinar.id} webinar={webinar} />
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </main>
        <SharedLayout theme="dark-theme" />
      </Layout>
    );
  }
}

const Webinar = ({ webinar }) => (
  <li className="webinars-panel-item">
    <div className="webinars-panel-time">
      <div className="webinars-panel-start">
        <i className="fa fa-calendar" aria-hidden="true" />
        {format(new Date(webinar.start_time), "MMM d, yyyy h:mm a")}
      </div>
      <div className="webinars-panel-duration">
        <i className="fa fa-clock-o" aria-hidden="true" />
        {webinar.duration} mins
      </div>
    </div>
    <div className="webinars-panel-inner">
      <h3 className="webinars-panel-title">{webinar.topic}</h3>

      <div className="webinars-panel-agenda">{webinar.agenda}</div>
      <a className="btn btn-primary" href={webinar.join_url} target="_blank" rel="noopener noreferrer">
        Register now
      </a>
    </div>
  </li>
);
