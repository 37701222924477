import { RadixSelect } from "../PricingPageSelect/Select";
import * as styles from "./SelectSection.module.scss";
import * as React from "react";

export type Option = { label: string; value: string };

type SelectSectionProps = {
  setSelectedOption: (string) => void;
  selectedOption: Option;
  options: Option[];
  label: string;
  ariaLabel: string;
};

export const SelectSection = (props: SelectSectionProps): JSX.Element => {
  const { setSelectedOption, selectedOption, options, label } = props;

  return (
    <label className={styles.selectLabel}>
      {label}

      <RadixSelect
        {...props}
        options={options}
        value={selectedOption}
        onValueChange={value => {
          setSelectedOption(options.find(option => option.value === value));
        }}
      />
    </label>
  );
};
