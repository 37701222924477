import { sentenceCaseText } from "../../Utils";
import { Paragraph } from "../Text";
import * as React from "react";
import { twMerge } from "tailwind-merge";

interface FlexibleTextDisplayProps {
  heading?: string;
  text: string | string[];
  classNames?: {
    heading?: string;
    text?: string;
  };
}
/**
 * Show a heading with single or multi paragraph text
 *
 * override text styles using classNames prop
 * @param heading
 * @param text
 * @param classNames: { heading?: string , text?:string}
 * @returns JSX.Element
 */
export const FlexibleTextDisplay = ({ heading, text, classNames }: FlexibleTextDisplayProps): JSX.Element => {
  return (
    <>
      {heading && <VisualHeading className={twMerge(classNames?.heading || "")} heading={heading} />}
      {Array.isArray(text) ? (
        text.map((item, index) => (
          <Paragraph
            key={item}
            htmlText={item}
            className={twMerge(
              "!tw-text-[18px] !tw-leading-7",
              classNames?.text || "",
              index < text.length - 1 ? "!tw-mb-4" : "!tw-mb-0"
            )}
          />
        ))
      ) : (
        <Paragraph htmlText={text} className={twMerge("!tw-text-[18px] !tw-leading-7", classNames?.text || "")} />
      )}
    </>
  );
};
/**
 * Show text styled as a heading
 *
 * override text styles using className prop
 * @param heading
 * @param className
 * @returns JSX.Element
 */
export const VisualHeading = ({ heading, className }: { heading: string; className?: string }): JSX.Element => {
  return (
    <div className={twMerge("tw-pb-[25px] tw-font-CircularXXSub tw-text-[24px] tw-font-semibold tw-text-grey_800", className || "")}>
      {sentenceCaseText(heading)}
    </div>
  );
};
