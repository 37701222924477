import { StyledLink } from "../Atoms";
import { IGatsbyImageData } from "gatsby-plugin-image";
import * as React from "react";

export default function FooterBannerWithLogos({
  title = "",
  cta = { text: "", link: "" },
  logos,
}: {
  title: string;
  cta: { text: string; link: string };
  logos: {
    alt: string;
    url: IGatsbyImageData;
  }[];
}) {
  return (
    <div
      className="tw-relative tw-overflow-hidden
        tw-py-32 before:tw-absolute before:tw-top-0 before:tw-z-10 before:tw-h-full before:tw-w-full before:tw-bg-[#F1F2F2]
        before:tw-opacity-80 after:tw-absolute after:tw-top-0 after:tw-h-full after:tw-w-full after:tw-bg-[url('../../static/media/home/stories.jpg')] after:tw-bg-center
      after:tw-blur-sm max-lg:tw-py-20"
    >
      <div className="tw-container tw-relative tw-z-20 tw-mx-auto tw-text-center max-md:tw-w-[90%]">
        <h2 className="tw-h2 tw-text-black">{title}</h2>
        <div className="my-4">
          <StyledLink
            cta={{
              text: cta.text,
              link: cta.link,
            }}
            className="btn btn-lg btn-primary !tw-m-0"
          />
        </div>
        <div className="">
          <p className="tw-text-xs tw-font-bold tw-uppercase tw-text-[#aaa]">Join these iconic brands</p>
          <div className="tw-flex tw-flex-wrap tw-justify-center tw-gap-y-5">
            {logos.map((brand: any, index: number) => {
              return (
                <img
                  key={brand.node.label}
                  src={brand.node.logo}
                  alt={brand.node.label}
                  className="tw-h-[50px] tw-opacity-50 max-sm:tw-h-[35px]"
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
