import cx from "classnames";
import * as React from "react";

interface HeadingProps {
  headerType?: 1 | 2 | 3;
  color?: string;
  size?: string;
  textAlign?: string;
  className?: string;
  children: React.ReactNode;
}

export const Heading = ({ headerType, size, color, textAlign, children, className }: HeadingProps): JSX.Element | null => {
  const modifiers = cx({ [color]: color }, { [textAlign]: textAlign }, { [size]: size }, className);

  switch (headerType) {
    case 1:
      return (
        <h1
          className={cx(
            "!tw-font-semibold !tw-leading-[110%] max-md:!tw-text-4xl max-sm:!tw-text-3xl  min-md:!tw-text-[64px]",
            modifiers
          )}
        >
          {children}
        </h1>
      );
    case 2:
      return <h2 className={modifiers}>{children}</h2>;
    case 3:
      return <h3 className={cx("!tw-pb-1 !tw-font-CircularXXSub tw-text-[40px]", modifiers)}>{children}</h3>;
    default:
      return null;
  }
};
