import { Footer } from "../components/Footer";
import { Layout } from "../components/Layout";
import { Card } from "../components/Organisms";
import { FooterBannerWithLogos } from "../components/Sections";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default class UseCases extends React.Component<any, any> {
  render() {
    const { allBrandsJson, allUseCasesLandingJson } = this.props.data;

    const allBrandsData = allBrandsJson.edges;
    const useCasesData = allUseCasesLandingJson.nodes;

    return (
      <Layout location={this.props.location}>
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-secondary body-marketing-features body-light hide-elevio",
          }}
        />
        <div>
          <div className="row section-title title-light">
            <div className="col-11 col-sm-8  mx-auto section-inner">
              <h1>
                <span className="shorthand-logo dark" aria-hidden="true">
                  Shorthand
                </span>
                Use Cases
              </h1>
              <h2>
                You can use Shorthand to build all of your visual content, from magazines and features to reports and … well, see for
                yourself!
              </h2>
            </div>
          </div>
          <div className="tw-container tw-pb-16">
            <div className="tw-grid tw-grid-cols-3 tw-gap-x-6 tw-gap-y-10 max-lg:tw-grid-cols-2 max-sm:tw-grid-cols-1 max-sm:tw-gap-y-5">
              {useCasesData.map((useCase: any, index: number) => {
                return (
                  <Card
                    key={index}
                    title={useCase.title}
                    description={useCase.description}
                    img={{ url: useCase.image.url, alt: useCase.image.alt }}
                    cta={{ link: useCase.url }}
                  />
                );
              })}
            </div>
          </div>
          <FooterBannerWithLogos
            title="Ready to create beautiful content with the platform used by Honda, University of Cambridge, and the BBC?"
            cta={{
              text: "Start creating",
              link: "https://app.shorthand.com/signup",
            }}
            logos={allBrandsData}
          />
          <Footer />
        </div>
      </Layout>
    );
  }
}

export const query = graphql`
  query MyQuery {
    allUseCasesLandingJson {
      nodes {
        title
        description
        image {
          alt
          url {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        url
      }
    }

    allBrandsJson {
      edges {
        node {
          logo
          name
          label
        }
      }
    }
  }
`;
