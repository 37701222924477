import { filterListByCurrentIndustryCode } from "../../Utils";
import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { RelatedCaseStudies } from "../../components/RelatedCaseStudies";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default function CaseStudy({ data, location }) {
  return (
    <Layout location={location}>
      <div id="main" className="marketing-content container-fluid">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-secondary body-marketing-case-studies hide-elevio",
          }}
        />
        <div className="row section-title">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6 mx-auto section-inner">
            <h1>
              <span className="shorthand-logo">Shorthand</span>
              <span className="subsection-label">Case Study:</span>
              The Doherty Institute
            </h1>
            <h2>How The Doherty Institute uses Shorthand</h2>
          </div>
          <div aria-hidden="true" className="background-media">
            <img
              src="/media/case-studies/case-studies-doherty-title.jpg"
              data-object-fit="cover"
              data-autoscale-content-ratio="1.77777777778"
              alt=""
            />
          </div>
        </div>
        <div className="row section">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto marketing-main-column">
            <h2 className="intro">
              How does a leading scientific research institute communicate complex, data-rich information to a general audience?
            </h2>
            <div className="inline-image featured">
              <img alt="" src="/media/case-studies/case-studies-doherty.jpg" />
            </div>
            <p>
              To answer this question, we talked to Natalie Booth, Marketing Communications Officer at The Peter Doherty Institute for
              Infection and Immunity (Doherty Institute).
            </p>
            <p>
              The Doherty Institute is a joint venture between the University of Melbourne and The Royal Melbourne Hospital, where
              leading scientists and clinicians collaborate to improve human health globally.
            </p>
            <p>
              As a purpose-built and conceived centre of excellence in infection and immunity, the Doherty Institute has been at the
              forefront of the global response to the COVID-19 pandemic. The Institute’s multidisciplinary activities include
              diagnostics, epidemiology, clinical management, and research into the immune response, potential treatments and a vaccine
              against SARS-CoV-2, the virus that causes COVID-19 disease.
            </p>
            <p>
              With such critical &mdash; and hugely public &mdash; work occurring within its walls, the Doherty Institute has put a
              premium on effective science communication. The team publishes a variety of reports, stories and long form, data-centric
              pieces, all aimed at making their research accessible to a broad audience.
            </p>
            <h3 className="subheading">Science communication for a general audience</h3>
            <figure className="block quote-block offset right">
              <blockquote>
                <p>“We want to make our research as accessible as possible for a general audience.”</p>
              </blockquote>
              <figcaption className="quote-cite">Natalie Booth</figcaption>
            </figure>
            <p>
              As Natalie explains, one of the difficulties with scientific research is that it can be highly technical. This can make
              it challenging to communicate with a broader audience.
            </p>
            <p>“The main idea for us in using Shorthand was to add a bit more life to long-form research pieces,” Natalie explains.</p>
            <p>
              “It can be difficult to disseminate really technical information. I think having something as engaging as Shorthand has
              helped bring a bit more life to some of those stories.”
            </p>
            <p>
              Natalie says that stories with various forms of media tend to be much more engaging than stories that are just text —
              which is critical for effective scientific communication.
            </p>
            <p>
              “Having the ability to add in imagery like graphs and animations is a really helpful way to give a little bit more detail
              and visual element for people.
            </p>
            <p>
              “We know that people do drop off reading stories that are just a wall of text. So, that's a big priority for us. We want
              to make our research as accessible as possible for a general audience.”
            </p>
            <h3 className="subheading">AusTrakka</h3>
            <p>
              One impressive example of the Doherty Institute’s science communication is{" "}
              <a
                href="https://www.doherty.edu.au/news-events/news/genomics-platform-enables-real-time-surveillance-of-pathogens-nationally"
                target="_blank"
                className="btn-link"
                rel="noopener noreferrer"
              >
                Genomics platform enables real-time surveillance of pathogens nationally
              </a>
              , which outlines the development of their AusTrakka platform.
            </p>
            <p>
              AusTrakka is a platform designed to enable real-time sharing, analysis and reporting of pathogen genomic data across
              public health labs in Australia.
            </p>
            <p>
              As Natalie explains, “AusTrakka is used as part of the COVID-19 public health response for rapid sharing and analysis of
              SARS-CoV-2 genomic sequences.It has been utilised by public health laboratories throughout the COVID-19 response to track
              and trace the virus across Australia and New Zealand.”
            </p>
            <p>
              The tool has been a critical part of the response to COVID-19 in Australia &mdash; but communicating this can be a
              challenge.
            </p>
            <p>
              “It's quite a technical platform. So it was great to tell this story in an innovative and visually interesting way, given
              the importance of the tool.”
            </p>
            <div className="photo-grid flex featured">
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/case-studies-doherty-sml-01.jpg" />
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/case-studies-doherty-sml-02.jpg" />
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/case-studies-doherty-sml-04.jpg" />
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/case-studies-doherty-sml-03.jpg" />
            </div>
            <div className="caption">A selection of sections from the Doherty Institute's stories</div>
            <h3 className="subheading">The people behind the science</h3>
            <figure className="block quote-block offset right">
              <blockquote>
                <p>
                  “We think having a more innovative way to showcase work means that people who're doing the really innovative work are
                  proud to share it.”
                </p>
              </blockquote>
              <figcaption className="quote-cite">Natalie Booth</figcaption>
            </figure>
            <p>
              In addition to communicating research, the Doherty Institute has showcased first-person stories of the experts driving
              the organisation’s work, in their{" "}
              <a href="https://www.doherty.edu.au/impact-report-2020/" target="_blank" className="btn-link" rel="noopener noreferrer">
                2020 Impact Report
              </a>
              .
            </p>
            <p>
              “We've noticed that when we've been promoting those sorts of people-centric stories, our staff and their family and
              friends are really proud to share them.,” Natalie says.
            </p>
            <p>
              “We think having a more innovative way to showcase work means that people who're doing the really innovative work are
              proud to share it.”
            </p>
            <p>
              One recent example is{" "}
              <a
                href="https://doherty.edu.au/impact-report-2020/destined-for-the-frontline/"
                target="_blank"
                className="btn-link"
                rel="noopener noreferrer"
              >
                Destined for the frontline
              </a>
              , a short piece by Julie McAuley, PhD, a Senior Research Officer at the Doherty Institute.
            </p>
            <p>
              Julie started 2020 uncertain of her career’s future, but very soon found herself deep in the trenches of coronavirus
              research. The Doherty Institute used Shorthand to publish the story, with an impactful contrast between two photos: one
              with Dr. McAuley in her PPE and one without.
            </p>
            <p>
              “Her expertise in viral infectious diseases—she previously worked on influenza—was put to work as part of the COVID
              response,” Natalie explains.
            </p>
            <p>
              “The pictures we used in the story were really powerful. They were taken by a local photographer, Phoebe Powell, who
              captured portraits of 52 people from across the medical precinct for the Metro Tunnel Creative Program in Parkville.
            </p>
            <p>
              “It's really simple. It was just flipping between two of the pictures, but it felt like it really worked telling Julie’s
              story.
            </p>
            <p>
              “At the end of the day, there are people behind all this PPE that are on the front lines, doing the hard work and helping
              us all.”
            </p>
            <h3 className="subheading">Cutting out the middleman</h3>
            <figure className="block quote-block offset left">
              <blockquote>
                <p>
                  “Shorthand just worked out-of -the-box. That cut out that middleman for us, which was ideal given we're a really
                  small team.”
                </p>
              </blockquote>
              <figcaption className="quote-cite">Natalie Booth</figcaption>
            </figure>
            <p>
              Before Shorthand, the Doherty Institute published their content using a generic CMS. Like most CMS’s, this didn’t allow
              for a lot of visual enhancements without enlisting a web developer.
            </p>
            <p>
              The other option for content creation was to hire an agency to build a bespoke visual experience, however this came at a
              price, and wouldn’t allow the Institute to publish at the same scale or velocity.
            </p>
            <p>
              Given the cost of developers and bespoke content from digital agencies, Shorthand was a much more economical choice for
              the Doherty Institute.
            </p>
            <p>
              As Natalie says, “Shorthand just worked out-of -the-box. That cut out that middleman for us, which was ideal given we're
              a really small team.”
            </p>
            <h3 className="subheading">Creativity and ownership</h3>

            <p>
              Not only is Shorthand the more viable option economically. As Natalie explains, her team has found that it’s also
              incredibly easy to use, even for those without a web development or animation background.
            </p>
            <p>
              By removing the middleman, Shorthand gives small teams autonomy &mdash; or “ownership of design,” as Natalie calls it
              &mdash; to make more creative decisions. This is because the folks on her team can build and experiment without having to
              outsource the design of complicated features, which slows down the creative process.
            </p>
            <p>
              One particular feature Natalie likes is the Scrollmation image transitions. “I like that you can animate without actually
              animating. The ability to upload images and make them appear to animate is an excellent feature.”
            </p>
            <p>
              Natalie also appreciates Shorthand’s Collection feature, which makes it possible to group and categorise stories. “This
              really worked for us because we have so many different themes and pillars of work. It was great to be able to showcase
              the breadth of work that's done at the Doherty Institute. And that's something that I think really made it especially
              appealing to us, because it showcased all our work equally.”
            </p>
            <h3 className="subheading">Our view of the future</h3>
            <figure className="block quote-block offset right">
              <blockquote>
                <p>“It just looks so much more engaging than what we were doing previously.”</p>
              </blockquote>
              <figcaption className="quote-cite">Natalie Booth</figcaption>
            </figure>
            <p>
              Natalie says she is delighted with the Doherty Institute’s use of Shorthand so far &mdash; and says they have plans to
              keep growing the use of Shorthand going forward.
            </p>
            <p>
              Compared to content produced with the Institute’s CMS, their Shorthand stories are much more visually immersive and
              appealing for their audience. As Natalie says, “It just looks so much more engaging than what we were doing previously.”
            </p>
            <p>Because of this success, Natalie and her team are republishing older, evergreen content using Shorthand.</p>
            <p>
              “It’s been great to see that additional traffic. And it’s also been great to see people reacting and getting used to the
              new visual style of our content.”
            </p>
            <p>
              Natalie looks forward to continuing to produce visual stories using Shorthand. “That’s our view of the future. We want to
              keep producing these highly visual explainer pieces using Shorthand.”
            </p>
          </div>
        </div>
        <RelatedCaseStudies studies={filterListByCurrentIndustryCode(data, "the-doherty-institute")} />
        <Footer />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query TheDohertyInstituteQuery {
    allCaseStudiesJson {
      edges {
        node {
          jsonId
          image
          industry
          industryCode
          organisation
          summary
        }
      }
    }
  }
`;
