import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { LegalNav } from "../../components/LegalNav";
import { Link } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default class PrivacyPolicyView extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <main id="main" className="marketing-content container-fluid">
          <Helmet
            bodyAttributes={{
              class: "body-shorthand body-marketing body-marketing-secondary body-light body-marketing-legal hide-elevio",
            }}
          >
            <title>Privacy Policy | Shorthand</title>
            <meta name="description" content="Shorthand terms of service." />
          </Helmet>
          <div className="row section-title title-light">
            <div className="col-12 col-sm-10 col-lg-8 col-xxl-6 mx-auto">
              <h1>
                <span className="shorthand-logo dark" aria-hidden="true">
                  Shorthand
                </span>{" "}
                Privacy Policy
              </h1>
              <p>
                <small className="small-text text-muted">Effective: 26 March 2024</small>
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="d-none d-lg-block col-lg-2">
              <LegalNav />
            </div>
            <div className="col-12 col-sm-10 col-lg-8 col-xxl-6">
              <section>
                <p className="intro">
                  Welcome to Shorthand’s privacy policy. We respect your privacy and are committed to protecting your personal data.
                  This privacy policy will inform you as to how we look after your personal data when you visit our website. This
                  includes our web application (which you may or may not have access to), which enables individuals and teams to create
                  and publish engaging online stories and other content. The policy also tells you about your privacy rights and how
                  the law protects you.
                </p>
                <p>
                  We are committed to complying with our obligations under the relevant data protection laws including but not limited
                  to, the EU General Data Protection Regulation (Regulation (EU) 2016/679) (if you are located in the European Union),
                  the UK GDPR and the Data Protection Act 2018 (UK) and regulations made thereunder (if you are located in the United
                  Kingdom) and the Privacy Act 1988 (Cth) and Australian Privacy Principles (if you are located in Australia). If you
                  are located in another jurisdiction, additional local requirements will apply. References to “you” or “your” are to
                  you as an individual, whether on behalf of yourself, or another individual, or organisation, using our website or
                  otherwise contacting us.
                </p>
              </section>
              <section>
                <h2>This privacy policy provides you with the following information:</h2>
                <ol>
                  <li>
                    <Link className="btn-link" to="#01">
                      Important information
                    </Link>
                  </li>
                  <li>
                    <Link className="btn-link" to="#02">
                      Who we are
                    </Link>
                  </li>
                  <li>
                    <Link className="btn-link" to="#03">
                      The data we collect about you
                    </Link>
                  </li>
                  <li>
                    <Link className="btn-link" to="#04">
                      How is your personal data collected?
                    </Link>
                  </li>
                  <li>
                    <Link className="btn-link" to="#05">
                      How we use your personal data
                    </Link>
                  </li>
                  <li>
                    <Link className="btn-link" to="#06">
                      Disclosures of your personal data
                    </Link>
                  </li>
                  <li>
                    <Link className="btn-link" to="#07">
                      International transfers
                    </Link>
                  </li>
                  <li>
                    <Link className="btn-link" to="#08">
                      Data security
                    </Link>
                  </li>
                  <li>
                    <Link className="btn-link" to="#09">
                      Data retention
                    </Link>
                  </li>
                  <li>
                    <Link className="btn-link" to="#10">
                      Your legal rights
                    </Link>
                  </li>
                  <li>
                    <Link className="btn-link" to="#11">
                      Glossary
                    </Link>
                  </li>
                </ol>
              </section>
              <section id="01">
                <h2>1. Important information</h2>
                <h3>Purpose of this privacy policy</h3>
                <p>
                  This privacy policy gives you information on how Shorthand collects and processes your personal data when you use our
                  services on this website or otherwise through your use of this website, including any data you may provide through
                  this website when you:
                </p>
                <ul>
                  <li>visit our website (say for example, as a visitor);</li>
                  <li>read content created via our website (for example, as a reader);</li>
                  <li>sign up to our newsletter;</li>
                  <li>purchase a product or service;</li>
                  <li>login to our web application; or</li>
                  <li>otherwise contact us.</li>
                </ul>
                <p>
                  It is important that you read this privacy policy, together with any other privacy policy or fair processing policy
                  we may provide on specific occasions when we are collecting or processing personal data about you, so that you are
                  fully aware of how and why we are using your personal data. This privacy policy supplements other notices and privacy
                  policies and is not intended to override them.
                </p>
                <p>
                  This website is not intended for individuals under the age of 16 and we do not knowingly collect data from
                  individuals under the age of 16.
                </p>

                <h3>Changes to the privacy policy and your duty to inform us of changes</h3>
                <p>
                  We keep our privacy policy under regular review. Historic versions can be obtained by contacting us at the details
                  set out below.
                </p>
                <p>We reserve the right to make amendments to our privacy policy at any time.</p>
                <p>
                  Unless otherwise provided in this privacy policy, it is important that the personal data we hold about you is
                  accurate and current. Please keep us informed if your personal data changes during your relationship with us.
                </p>
                <h3>Third party links</h3>
                <p>
                  This website may include links to third-party websites, plug-ins, and applications. The third-party tools that we use
                  on this website are listed at{" "}
                  <a className="btn-link" href="https://support.shorthand.com/en/articles/97">
                    https://support.shorthand.com/en/articles/97
                  </a>
                  .
                </p>
                <p>
                  You may also use other third-party tools when hosting your content via Shorthand (information about hosting via
                  Shorthand is available at{" "}
                  <a className="btn-link" href="https://support.shorthand.com/en/articles/60-shorthand-hosting">
                    https://support.shorthand.com/en/articles/60-shorthand-hosting
                  </a>
                  ).
                </p>
                <p>
                  Clicking on third-party links or enabling third-party connections may allow third parties to collect or share data
                  about you. We do not control these third-party websites and are not responsible for their privacy statements. When
                  you leave our website, we encourage you to read the privacy policy of every website you visit. When we do link to a
                  third party website, application or resource, this does not automatically imply that we endorse that website,
                  application, resource and their contents. Our privacy policy does not cover the use of cookies by any third parties.
                </p>
              </section>

              <section id="02">
                <h2>2. Who we are</h2>
                <h3>Data controller & controller of this website</h3>
                <p>
                  This privacy policy is issued on behalf of Shorthand Pty Ltd ACN 162 625 470 and each its subsidiaries (Shorthand
                  Group) and references to “Shorthand”, “we”, "us" or "our" in this privacy policy, we are referring to the relevant
                  company in the Shorthand Group responsible for processing your data. If Shorthand is not the controller, we will let
                  you know which entity will be the controller for your data when you purchase a product or service with us.
                </p>
                <p>Shorthand is the controller of your data and controller of this website.</p>
                <p>We are bound by applicable data protection laws in respect of the handling and collection of your personal data.</p>
                <p>
                  We have appointed a data privacy manager who is responsible for overseeing questions in relation to this privacy
                  policy.
                </p>
                <p>
                  If you have any questions about this privacy policy, or our privacy practices, including any requests to exercise
                  your legal rights, please contact the data privacy manager using the details set out below.
                </p>
                <ul>
                  <li>
                    <strong>Full name of legal entity:</strong> Shorthand Pty Ltd
                  </li>
                  <li>
                    <strong>Email address:</strong>{" "}
                    <a className="btn-link" href="mailto:privacy@shorthand.com">
                      privacy@shorthand.com
                    </a>
                  </li>
                  <li>
                    <strong>Postal address:</strong> Level 38, Central Plaza One, 345 Queen Street, Brisbane, QLD, 4000, Australia
                  </li>
                </ul>
                <h3>When we may not be a data controller</h3>
                <p>
                  Where we process your personal data as a result of your using our services on behalf of an organisation or another
                  individual, or as a reader of our customers’ content, we are not the data controller as we would be processing
                  personal data on behalf of our customer as a data processor.
                </p>
                <p>
                  Our customer has control over, and determines the nature of, the information and personal data collected from you.
                </p>
                <p>
                  Where we process your personal data on behalf of our customer, the applicable privacy policy is that of our customer.
                  Our customer’s privacy policy should be referred to if you wish to exercise any of your rights as a data subject
                  where we are processing your data on behalf of our customer as a data processor. If you are using Shorthand on behalf
                  of an organisation or another individual, and you do not wish for Shorthand to process your data, you should make a
                  request to that organisation or individual to have your Shorthand account deactivated or deleted.
                </p>
                <p>
                  Our customers may use third-party platforms to host their Shorthand content (information about hosting via Shorthand
                  is available at{" "}
                  <a className="btn-link" href="https://support.shorthand.com/en/articles/60-shorthand-hosting">
                    https://support.shorthand.com/en/articles/60-shorthand-hosting
                  </a>
                  ), and third-party tools to enhance their Shorthand content. In these cases we are not the data controller and may
                  not be a data processor of any data collected by or shared with these third parties as a result of the use of such
                  third-party platforms and tools. You should refer to our customer’s privacy policy for more information about how
                  your data will be used in this scenario.{" "}
                </p>
              </section>

              <section id="03">
                <h2>3. The data we collect about you</h2>
                <p>
                  Personal data, or personal information, means any information about an individual from which that person can be
                  identified. It does not include data where the identity has been removed, and it is not possible to identify the
                  individual (anonymous, de-identified or aggregated data).
                </p>
                <p>
                  We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as
                  follows:
                </p>
                <ul>
                  <li>
                    <strong>Identity Data</strong> includes first name, last name, username or similar identifier, title, job title,
                    and visual/audio/other identifiers (for example, profile photo).
                  </li>
                  <li>
                    <strong>Contact Data</strong> includes billing addresses, email addresses and telephone numbers.
                  </li>
                  <li>
                    <strong>Financial Data</strong> includes bank account and payment card details.
                  </li>
                  <li>
                    <strong>Transaction Data</strong> includes details about payments to and from you and other details of products and
                    services you have purchased from us.
                  </li>
                  <li>
                    <strong>Technical Data</strong> includes internet protocol (IP) addresses, URLs for referring pages, your login
                    data, browser type and version (including its resolution and aspect ratio), time zone setting and location, browser
                    plug-in types and versions, operating system and platform, other technology on the devices you use to access this
                    website and/or our emails, and information in or about the content/documents (including text, images, videos, HTML,
                    javascript, CSS and other files and data) you provide us/upload onto our web application (including metadata), such
                    as the location and/or the date the content/document was created.
                  </li>
                  <li>
                    <strong>Profile Data</strong> includes your username and password, purchases or orders made by you, your interests,
                    preferences, feedback, survey responses, Google Analytics tracking ID, social media data including
                    usernames/handles/application ID.
                  </li>
                  <li>
                    <strong>Usage Data</strong> includes information about how you use our website, products, and services which
                    includes the content (such as stories) that you’ve read/watched or published via our web application.
                  </li>
                  <li>
                    <strong>Marketing and Communications Data</strong> includes your preferences in receiving marketing from us and our
                    third parties and your communication preferences.
                  </li>
                </ul>
                <p>
                  We may also collect, use, and share <strong>Aggregated Data</strong> such as statistical or demographic data for any
                  purpose. Aggregated Data could be derived from your personal data but is not considered personal data in law as this
                  data will not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate
                  the percentage of users accessing a specific website feature. However, if we combine or connect Aggregated Data with
                  your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data
                  which will be used in accordance with this privacy policy.
                </p>
                <p>
                  We do not ask you for any <strong>Special Categories of Personal Data/ Sensitive Information</strong> about you (this
                  includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation,
                  political opinions, trade union membership, information about your health, and genetic and biometric data). Nor do we
                  ask you for any information about criminal convictions and offences.
                </p>
                <p>
                  You may choose to provide us with Special Categories of Personal Data/ Sensitive Information through your uploading
                  of content or other data on to the Website or when{" "}
                  <a className="btn-link" href="https://support.shorthand.com/en/articles/60-shorthand-hosting">
                    hosting your content via Shorthand
                  </a>
                  . Where you provide us with such Special Categories of Personal Data/ Sensitive Information , you are giving us your
                  explicit consent to process such data about you to the extent permitted by all applicable laws (including the UK
                  and/or EU data protection regime).
                </p>
                <p>
                  You may provide us with Special Categories of Personal Data/ Sensitive Information about persons other than yourself
                  for journalistic purposes, the purposes of academic, artistic or literary expression, and to pursue your right to
                  freedom of expression and information. In all other cases where you provide us with Special Categories of Personal
                  Data/ Sensitive Information about persons other than yourself, you must obtain each of their explicit written consent
                  for us to process their Special Categories of Personal Data/ Sensitive Information to the extent permitted by all
                  applicable laws (including the UK and/or EU data protection regime and Australian privacy laws) and in accordance
                  with this privacy policy. Their explicit written consent which you obtain must be supplied to us on our request. If a
                  person does not give explicit written consent to our processing of their Special Categories of Personal Data/
                  Sensitive Information , then their Special Categories of Personal Data must not be supplied to us. That is to say,
                  you must not upload any content containing Special Categories of Personal Data/ Sensitive Information of anyone who
                  you have not obtained explicit written consent from.
                </p>
                <p>
                  Special Categories of Personal Data/ Sensitive Information may be subject to special protections under applicable
                  laws (including UK and/or the EU data protection regime and Australian privacy laws).
                </p>
                <h3>If you fail to provide personal data</h3>
                <p>
                  Where we need to collect personal data by law, or under the terms of a contract we have with you, and you fail to
                  provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with
                  you (for example, to provide you with services). In this case, we may have to cancel a product or service you have
                  with us but we will notify you if this is the case at the time.{" "}
                </p>
              </section>

              <section id="04">
                <h2>4. How is your personal data collected?</h2>
                <p>We use different methods to collect data from and about you including through:</p>
                <ul>
                  <li>
                    <strong>Direct interactions.</strong> You may give us your Identity Data, Contact Data and Financial Data by
                    filling in forms, registering as a member, or by corresponding with us by post, phone, email or otherwise. This
                    includes personal data you provide when you:
                    <ul>
                      <li>create an account and register as a member on our website;</li>
                      <li>apply for our products or services;</li>
                      <li>upload content/documents onto our web application;</li>
                      <li>subscribe to our services or publications;</li>
                      <li>register for our newsletter;</li>
                      <li>request marketing to be sent to you; or</li>
                      <li>give us feedback or contact us.</li>
                    </ul>
                  </li>
                  <li>
                    <strong>Automated technologies or interactions.</strong> As you interact with our website or with us via email, we
                    will automatically collect Technical Data about your equipment, browsing actions and patterns. We collect this
                    personal data by using cookies, server logs and other similar technologies such as tracking pixels. We may also
                    receive Technical Data about you if you visit other websites employing our cookies. Please see our{" "}
                    <Link className="btn-link" to="/cookie-policy">
                      Cookie Policy
                    </Link>{" "}
                    for further details.
                  </li>
                  <li>
                    <strong>Third parties or publicly available sources.</strong> We will receive personal data about you from various
                    third parties (including public sources) worldwide (who may be based outside Australia, the UK, the EU and/or the
                    USA, as applicable) as set out below:
                    <ul>
                      <li>
                        Technical Data from parties such as:
                        <ul>
                          <li>analytics providers such as Google;</li>
                          <li>and advertising networks.</li>
                        </ul>
                      </li>
                      <li>Contact, Financial and Transaction Data from providers of technical and payment services.</li>
                      <li>
                        Identity and Contact Data from publicly available sources such as Companies House and the Electoral Register
                        based inside the UK, and the Australian Securities and Investments Commission register.
                      </li>
                    </ul>
                  </li>
                </ul>
              </section>
              <section id="05">
                <h2>5. How we use your personal data</h2>
                <p>
                  We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the
                  following circumstances:
                </p>
                <ul>
                  <li>Where we need to perform the contract we are about to enter into or have entered into with you.</li>
                  <li>
                    Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental
                    rights do not override those interests.
                  </li>
                  <li>Where we need to comply with a legal obligation.</li>
                </ul>
                <p>
                  See Glossary below to find out more about the types of lawful bases that we will rely on to process your personal
                  data.
                </p>
                <p>
                  Generally, we do not rely on consent as a legal basis for processing your personal data, save for the processing of
                  Special Categories of Personal Data/ Sensitive Information (where applicable) and our making automated decisions
                  (where applicable), although we will get your consent before sending third party direct marketing communications to
                  you via email or text message. You have the right to withdraw consent to marketing at any time by contacting us at
                  the contact details set out above.
                </p>
                <h3>(A) Purposes for which we will use your personal data</h3>
                <p>
                  We have set out below a description of all the ways we plan to use your personal data, and which of the legal bases
                  we rely on to do so. We have also identified what our legitimate interests are where appropriate.
                </p>
                <p>
                  Note that we may process your personal data for more than one lawful ground depending on the specific purpose for
                  which we are using your data. Please contact us if you need details about the specific legal ground we are relying on
                  to process your personal data where more than one ground has been set out below.
                </p>
                <p>
                  <strong>To register you as a member or a new customer</strong>
                </p>
                <p>The kinds of personal data we process for this purpose are Identity and Contact Data.</p>
                <p>
                  Our processing for this purpose is necessary for our legitimate interests (including to perform our legal obligations
                  to our customer) or for the performance of a contract with you.
                </p>
                <p>
                  <strong>To process and deliver your order/purchase</strong>
                </p>
                <p>
                  We may use your personal data for this purpose to manage payments, fees, and charges, and/or collect and recover
                  money owed to us.
                </p>
                <p>
                  The kinds of personal data we process for this purpose are Identity, Contact, Financial, Transaction and Marketing
                  and Communications Data.
                </p>
                <p>
                  Our processing for this purpose is necessary for our legitimate interests (including to recover debts due to us) or
                  for the performance of a contract with you.
                </p>
                <p>
                  <strong>
                    To deliver content (such as stories) that are optimised to render appropriately and quickly on your devices/browser
                  </strong>
                </p>
                <p>The kinds of personal data we process for this purpose are Technical and Usage Data.</p>
                <p>
                  Our processing for this purpose is necessary for our legitimate interests (including to study how members and
                  customers use our products/services) or for the performance of a contract with you.
                </p>
                <p>
                  <strong>To enable you to upload and/or edit your content/documents on our web application</strong>
                </p>
                <p>The kinds of personal data we process for this purpose are Identity, Technical, Usage and Profile Data.</p>
                <p>Our processing for this purpose is for the performance of a contract with you.</p>
                <p>
                  <strong>To manage our relationship with you</strong>
                </p>
                <p>
                  Managing our relationship with you would include our notifying you about changes to our terms or privacy policy
                  and/or asking you to provide feedback or to leave a review or take a survey.
                </p>
                <p>
                  The kinds of personal data we process for this purpose are Identity, Contact, Profile and Marketing and
                  Communications Data.
                </p>
                <p>
                  Our processing for this purpose is necessary for our legitimate interests (including to keep our records updated and
                  to study how visitors, members and customers use our website/products/services) or for the performance of a contract
                  with you or is necessary to comply with a legal obligation.
                </p>
                <p>
                  <strong>
                    To administer and protect our business and this website (including troubleshooting, data analysis, testing, system
                    maintenance, support, reporting and hosting of data)
                  </strong>
                </p>
                <p>The kinds of personal data we process for this purpose are Identity, Contact and Technical Data.</p>
                <p>
                  Our processing for this purpose is necessary for our legitimate interests (for example, for running our business,
                  provision of administration and IT services, network security, to prevent fraud and in the context of a business
                  reorganisation or group restructuring exercise) or is necessary to comply with a legal obligation.
                </p>
                <p>
                  <strong>
                    To use data analytics to improve our website, security, products/services, marketing, customer relationships and
                    experiences
                  </strong>
                </p>
                <p>
                  We use data analytics by, for example, keeping a log of when members/customers use our web application and how they
                  use it, as well as recording browser sessions.
                </p>
                <p>The kinds of personal data we process for this purpose are Technical and Usage Data.</p>
                <p>
                  Our processing for this purpose is necessary for our legitimate interests (for example, to define types of customers
                  for our products and services, to keep our website updated and relevant, to develop our business and to inform our
                  marketing strategy) and for member’s/customer’s legitimate interest (for example, to enable them to organise their
                  web applications for their ease of use).
                </p>
                <p>
                  <strong>To be as responsive as possible to you, for example, when answering your customer support queries</strong>
                </p>
                <p>
                  The kinds of personal data we process for this purpose are Identity, Contact, Profile, Technical, Usage and Marketing
                  and Communications Data.
                </p>
                <p>
                  Our processing for this purpose is necessary for our legitimate interests (for example, to provide you with good
                  service) and for your legitimate interest (for example, to receive assistance promptly).
                </p>
                <p>
                  <strong>
                    To deliver relevant website content and advertisements to you and measure or understand the effectiveness of the
                    advertising we serve to you
                  </strong>
                </p>
                <p>
                  The kinds of personal data we process for this purpose are Identity, Contact, Profile, Technical, Usage and Marketing
                  and Communications Data.
                </p>
                <p>
                  We may share anonymised data with ad providers who perform ad measurement services on our behalf. For example, we may
                  share hashed first-party data (such as hashed email addresses) in a way that safeguards privacy using a secure
                  one-way hashing algorithm such as SHA256.
                </p>
                <p>
                  Our processing for this purpose is necessary for our legitimate interests (for example, to study how visitors,
                  members, customers use our products/services, to develop them, to grow our business and to inform our marketing
                  strategy).
                </p>
                <p>
                  <strong>To make suggestions and recommendations to you about services that may be of interest to you</strong>
                </p>
                <p>
                  The kinds of personal data we process for this purpose are Identity, Contact, Profile, Technical, Usage and Marketing
                  and Communications Data.
                </p>
                <p>
                  Our processing for this purpose is necessary for our legitimate interests (including to develop our products/services
                  and grow our business).
                </p>
                <p>
                  <strong>
                    To integrate member’s/customer’s content (such as stories) with third party social media platforms or analyse
                    performance of the content
                  </strong>
                </p>
                <p>The kinds of personal data we process for this purpose are Profile and Technical Data.</p>
                <p>
                  Our processing for this purpose is necessary for our legitimate interests (including to keep our records updated and
                  to develop our products/services and grow our business) or for the performance of a contract with you.
                </p>
                <h3>(B) Marketing</h3>
                <p>We may send you marketing communications by email, telephone, or mail.</p>
                <p>
                  We strive to provide you with choices regarding certain personal data uses, particularly around marketing and
                  advertising.
                </p>
                <h3>(C) Promotional material from us</h3>
                <p>
                  We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we think you may want or
                  need, or what may be of interest to you. This is how we decide which products, services and offers may be relevant
                  for you (we call this marketing).
                </p>
                <p>
                  You may receive marketing communications from us if you have requested information from us or purchased services from
                  us and you have not opted out of receiving that marketing.
                </p>
                <h3>(D) Third-party marketing</h3>
                <p>We will obtain your consent before we share your personal data with any third party for marketing purposes.</p>
                <h3>(E) Opting out</h3>
                <p>
                  You can always ask us or third parties to stop sending you marketing messages at any time by following the opt-out
                  links on any marketing message sent to you or by contacting us at any time to exercise your right to object and
                  withdraw your consent.
                </p>
                <p>
                  Where you opt out of receiving marketing messages, this will not apply to personal data you provided to us as a
                  result of a product/service purchase, product/service experience or other transactions.
                </p>
                <p>
                  If you choose to object to the communications, unsubscribe or withdraw your consent, this will not make our
                  processing of your personal data before you withdrew your consent unlawful.
                </p>
                <h3>(F) Cookies and other similar technologies</h3>
                <p>
                  You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies.
                  If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function
                  properly. For more information about the cookies and other similar technologies we use, please see our{" "}
                  <Link className="btn-link" to="/cookie-policy">
                    Cookie Policy
                  </Link>
                  .
                </p>
                <h3>(G) Change of purpose</h3>
                <p>
                  We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we
                  need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an
                  explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us
                  at the details set out above.
                </p>
                <p>
                  If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis
                  which allows us to do so.
                </p>
                <p>
                  Please note that we may process your personal data without your knowledge or consent, in compliance with the above
                  rules, where this is required or permitted by law.
                </p>
              </section>
              <section id="06">
                <h2>6. Disclosures of your personal data</h2>
                <p>
                  For the purposes set out in the “Purposes for which we will use your personal data” section above, we may share your
                  personal data with certain parties as set out below:
                </p>
                <ul>
                  <li>
                    External third parties such as:
                    <ul>
                      <li>
                        service providers (which may be based in or outside of the UK and/or the EU) as listed at{" "}
                        <a className="btn-link" href="https://support.shorthand.com/en/articles/97">
                          https://support.shorthand.com/en/articles/97
                        </a>{" "}
                        who are acting as processors and/or sub-processors;
                      </li>
                      <li>
                        professional advisers acting as processors or joint controllers including lawyers, bankers, auditors, insurers
                        and employment and recruitment agencies based in Australia, the EU, the UK and the USA (or other relevant
                        jurisdictions) who provide consultancy, banking, legal, insurance, accounting, and recruitment services;
                      </li>
                      <li>
                        marketing, advertising and PR companies based in countries including Australia, the EU, the UK and the USA, for
                        example, Facebook, Twitter/X, LinkedIn, YouTube, Microsoft and Google; and
                      </li>
                      <li>
                        HM Revenue & Customs, regulators and other authorities based in the UK (or other relevant jurisdictions) who
                        require reporting of processing activities in certain circumstances.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Internal third parties which are the other companies in the Shorthand Group acting as joint controllers or
                    processors and who are based in the UK, Australia and the USA and provide services including IT and system
                    administrative services and undertake certain reporting functions.
                  </li>
                  <li>
                    Third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets. Alternatively,
                    we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new
                    owners may use your personal data in the same way as set out in this privacy policy.
                  </li>
                </ul>
                <p>
                  We require all third parties to respect the security of your personal data and to treat it in accordance with the
                  law. We do not allow our third-party service providers to use your personal data for their own purposes and only
                  permit them to process your personal data for specified purposes and in accordance with our instructions.
                </p>
              </section>
              <section id="07">
                <h2>7. International transfers</h2>
                <p>
                  We share your personal data within the Shorthand Group. This will involve transferring your data worldwide (including
                  outside Australia, the USA, the UK and/or the EU (as applicable)).
                </p>
                <p>
                  We may also transfer your data worldwide when the content (such as stories) published/created via our web application
                  is exported to you (at a member/customer’s request) so that this content can, for example, be hosted on your own
                  servers and/or published to our web hosting environment.
                </p>
                <p>
                  Our specific and external third parties are based in many countries so their processing of your personal data will
                  involve a transfer and/or storage of personal data worldwide (including outside Australia, the USA, the UK and/or the
                  EU (as applicable)).
                </p>
                <p>
                  Whenever we transfer your personal data out of the UK and/or the EU (as applicable), we ensure a similar degree of
                  protection is afforded to it by ensuring at least one of the following safeguards is implemented:
                </p>
                <ul>
                  <li>
                    We will only transfer your personal data to countries that have been deemed to provide an adequate level of
                    protection for personal data.
                  </li>
                  <li>
                    Where we use certain service providers outside of the UK and/or the EU (as applicable), we may use specific
                    contracts approved for use in the UK and/or the EU (as applicable) which give personal data the same protection it
                    has in the UK and/or the EU (as applicable).
                  </li>
                </ul>
                <p>
                  Whenever we transfer personal data outside of our operations in Australia we ensure we only transfer your personal
                  data to countries that have been deemed to provide an adequate level of protection for personal data in accordance
                  with the Australian Privacy Principles contained in the Privacy Act.
                </p>
                <p>
                  Please contact us if you want further information on the specific mechanism used by us when transferring your
                  personal data out of the UK and/or the EU, or Australia (as applicable).
                </p>
              </section>
              <section id="08">
                <h2>8. Data security</h2>
                <p>
                  We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used,
                  or accessed in an unauthorised way, altered, or disclosed.
                </p>
                <p>For example, amongst others:</p>
                <ul>
                  <li>we use SSL/TLS to secure data that is communicated between our servers and your browser;</li>
                  <li>
                    your passwords are subject to a cryptographic hashing measure before they are stored on our servers. This makes it
                    incredibly difficult for anyone to ascertain your password in the unlikely event that a malicious party were to
                    gain access to our servers where your data is stored;
                  </li>
                  <li>
                    we retain only a summary of your credit card details (which includes the last 4 digits of your payment card number)
                    and we do not keep a record of your entire payment card number and CVV on our server. We use Stripe for payment
                    processing. You may also ask for the removal of payment card details when you cancel your subscription/order with
                    us;
                  </li>
                  <li>
                    access to content published/created via our web application is protected by username and password provided by you
                    (as customers or members). That is to say, other than our having the ability to access your content to provide you
                    with assistance on support issues, only those who know the username and password of someone with legitimate editing
                    access can edit or alter content (such as stories) through our web application. It is therefore important that you
                    do not share passwords with anyone, and any colleagues who wish to edit or alter any content should have their own
                    accounts with us (and they can collaborate with you via our web application); and
                  </li>
                  <li>
                    content previews can be protected with passwords. Even if you decide not to set a password to a content preview,
                    the URLs for content previews are long and obfuscated with cryptographic hashing so that it is difficult for others
                    to guess the URL to a content preview.
                  </li>
                </ul>
                <p>
                  In addition, we limit access to your personal data to those employees, agents, contractors and other third parties
                  who have a business need to know. They will only process your personal data on our instructions and they are subject
                  to a duty of confidentiality.
                </p>
                <p>
                  We have put in place procedures to deal with any suspected personal data breach and will notify you and any
                  applicable regulator of a breach where we are legally required to do so.
                </p>
              </section>
              <section id="09">
                <h2>9. Data retention</h2>
                <p>
                  We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we collected it
                  for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements.
                </p>
                <p>
                  To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of
                  the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes
                  for which we process your personal data and whether we can achieve those purposes through other means, and the
                  applicable legal, regulatory, tax, accounting, or other requirements.
                </p>
                <p>
                  Where we have received payment from you, or in respect of you, for service we have provided you, we will generally
                  retain data for a period of 7 (seven) years, to ensure that we are able to assist should you have any questions or
                  feedback in relation to our services, or to protect or defend our legal rights. We may retain your personal data for
                  a longer period in the event of a complaint, or if we reasonably believe there is a prospect of litigation in respect
                  to our relationship with you.
                </p>
                <p>In some circumstances you can ask us to delete your data: see your legal rights below for further information.</p>
                <p>
                  In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for
                  research or statistical purposes, in which case we may use this information indefinitely without further notice to
                  you.
                </p>
              </section>
              <section id="10">
                <h2>10. Your legal rights</h2>
                <p>Under certain circumstances, you have rights under data protection laws in relation to your personal data.</p>
                <ul>
                  <li>
                    <strong>Request access</strong> to your personal data (commonly known as a “data subject access request”). This
                    enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing
                    it.
                  </li>
                  <li>
                    <strong>Request correction</strong> of the personal data that we hold about you. This enables you to have any
                    incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new
                    data you provide to us.
                  </li>
                  <li>
                    <strong>Request erasure</strong> of your personal data. This enables you to ask us to delete or remove personal
                    data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or
                    remove your personal data where you have successfully exercised your right to object to processing (see below),
                    where we may have processed your information unlawfully or where we are required to erase your personal data to
                    comply with local law. Note, however, that we may not always be able to comply with your request of erasure for
                    specific legal reasons which will be notified to you, if applicable, at the time of your request.
                    <br />
                    If you are a customer, erasure of certain information may also have irreversible impact on you and your member’s
                    ability to use the web application, and may also impact your reader’s ability to view content you have created and
                    published via our web application. We therefore take a personal and tailored approach to data erasure with the aim
                    that the result of the erasure meets your expectations and requirements.
                  </li>
                  <li>
                    <strong>Object to processing</strong> of your personal data where we are relying on a legitimate interest (or those
                    of a third party) and there is something about your particular situation which makes you want to object to
                    processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right
                    to object where we are processing your personal data for direct marketing purposes. In some cases, we may
                    demonstrate that we have compelling legitimate grounds to process your information which override your rights and
                    freedoms.
                  </li>
                  <li>
                    <strong>Request restriction of processing</strong> of your personal data. This enables you to ask us to suspend the
                    processing of your personal data in the following scenarios: (a) if you want us to establish the data’s accuracy;
                    (b) where our use of the data is unlawful but you do not want us to erase it; (c) where you need us to hold the
                    data even if we no longer require it as you need it to establish, exercise or defend legal claims; or (d) you have
                    objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.
                  </li>
                  <li>
                    <strong>Request the transfer</strong> of your personal data to you or to a third party. We will provide you, or a
                    third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that
                    this right only applies to automated information which you initially provided consent for us to use, or where we
                    used the information to perform a contract with you.
                  </li>
                  <li>
                    <strong>Withdraw consent at any time</strong> where we are relying on consent to process your personal data.
                    However, this will not affect the lawfulness of any processing carried out before you withdrew your consent. If you
                    withdraw your consent, we may not be able to provide certain services to you. We will advise you if this is the
                    case at the time you withdraw your consent.
                  </li>
                </ul>
                <p>If you wish to exercise any of the rights set out above, please contact us.</p>
                <h3>No fee usually required</h3>
                <p>
                  You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may
                  charge a reasonable fee if your request is clearly unfounded, repetitive, or excessive. Alternatively, we could
                  refuse to comply with your request in these circumstances.
                </p>
                <h3>What we may need from you</h3>
                <p>
                  We may need to request specific information from you to help us confirm your identity and ensure your right to access
                  your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data
                  is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further
                  information in relation to your request to speed up our response.
                </p>
                <h3>Time limit to respond</h3>
                <p>
                  We try to respond to all legitimate requests within one month. Occasionally it could take us longer than a month if
                  your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep
                  you updated.
                </p>
              </section>
              <section id="11">
                <h2>11. Complaints</h2>
                <p>
                  If you are in the EEA or the U.K. under the GDPR or UK GDPR (as applicable) you have the right to make a complaint to
                  the appropriate supervisory authority. If you are not satisfied with the response received or the actions taken by
                  our data privacy manager, or if you would like to make a complaint directly about Shorthand’s data practices, we
                  invite you to contact the supervisory authority in your country. For example, if you are in the U.K., you should
                  contact the Information Commissioner’s Office who is the supervisory authority.
                </p>
                <p>
                  If you are in Australia, and you are concerned that we may have breached the Australian Privacy Principles (set out
                  in the Privacy Act), please contact our data privacy manager in writing at the details set out above and we will work
                  with you to resolve the issue. If you are not satisfied with the actions taken by our data privacy manager, you can
                  make a complaint to the{" "}
                  <a className="btn-link" href="https://www.oaic.gov.au/privacy/privacy-complaints/">
                    Office of the Australian Information Commissioner
                  </a>
                  . For more information about privacy in general in Australia, you can visit the{" "}
                  <a className="btn-link" href="https://www.oaic.gov.au/">
                    Office of the Australian Information Commissioner’s website
                  </a>
                  .
                </p>
              </section>
              <section id="12">
                <h2>12. Glossary</h2>
                <h3>Lawful bases:</h3>
                <p>
                  <strong>Legitimate interest</strong> means conducting and managing our business to enable us to provide you with the
                  best service/product and the best and most secure online experience. We will consider and balance any potential
                  impact on you (both positive and negative) and your rights under the data protection laws before we process your
                  personal data for our legitimate interests. We do not use your personal data for activities which conflict with your
                  genuine interests, other than with your prior consent or otherwise as required or permitted by law).
                </p>
                <p>
                  <strong>Performance of contract</strong> means processing your data where it is necessary for the performance of a
                  contract to which you are a party or to take steps at your request before entering into such a contract.
                </p>
                <p>
                  <strong>Comply with a legal obligation</strong> means processing your personal data where it is necessary for
                  compliance with a legal obligation that we are subject to.
                </p>
              </section>
            </div>
          </div>
          <Footer />
        </main>
      </Layout>
    );
  }
}
