import * as React from "react";

export const BlackBg = (): JSX.Element => {
  return (
    <div
      id="black-bg"
      className="tw-h-[818px] !tw-w-full   max-xxxxl:!tw-h-[818px]  max-xxl:!tw-h-[696px] max-lg:!tw-h-[730px] max-sm:!tw-h-[556px]"
      style={{
        position: "absolute",
        zIndex: 1,
        top: 0,
        left: 0,
        background: "black",
      }}
    />
  );
};
