import cx from "classnames";
import * as React from "react";

export const Icon = ({ icon, className, role, onClick, children, ...other }: any): any => {
  return (
    <i
      role={role}
      onClick={onClick}
      className={cx({
        fa: true,
        [`fa-${icon}`]: icon,
        [className]: className,
      })}
      {...other}
    >
      {children}
    </i>
  );
};
