import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default class FeaturesSupport extends React.Component<any, any> {
  render() {
    const { allBrandsJson } = this.props.data;

    const brands = allBrandsJson.edges;

    return (
      <Layout location={this.props.location}>
        <main id="main" className="marketing-content container-fluid">
          <Helmet
            bodyAttributes={{
              class: "body-shorthand body-marketing body-marketing-secondary body-marketing-features body-light hide-elevio",
            }}
          ></Helmet>
          <div className="row section-title title-light">
            <div className="col-11 col-sm-8  mx-auto section-inner">
              <h1>
                <span className="shorthand-logo dark" aria-hidden="true">
                  Shorthand
                </span>
                Support
              </h1>
              <h2>Build amazing stories with our industry-leading support.</h2>
            </div>
          </div>

          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Editorial feedback</h3>
                  <p>
                    Our expert team can provide detailed feedback on your draft stories, so you can make the most of Shorthand’s rich
                    features.
                  </p>
                  <ul className="features-list">
                    <li>
                      On applicable plans, our team will help you publish your first stories, and ensure you’re ready and able to scale
                      your digital storytelling.
                    </li>
                    <li>
                      Published a great story? We can advise on promotion strategies &mdash; and help, where appropriate, with getting
                      your message out on social media or our{" "}
                      <a
                        href="https://shorthand.com/the-craft/add-the-craft-to-your-inbox/"
                        target="_blank"
                        rel="noopenner noreferrer"
                        className="btn-link"
                      >
                        email roundup
                      </a>{" "}
                      of the best digital stories on the web.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/support-editorial.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>
          <section className="row section section-strip section-strip-right">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Technical support</h3>
                  <p>Our engineering team has deep expertise in web development, and is on-hand to address any issues that arise.</p>
                  <ul className="features-list">
                    <li>
                      Our support is best in class according to reviewers on G2, with Shorthand a leader in our product category.{" "}
                    </li>
                  </ul>
                  <a
                    className="btn btn-secondary"
                    href="https://www.g2.com/products/shorthand/reviews"
                    target="_blank"
                    rel="noopenner noreferrer"
                  >
                    Read our reviews on G2
                  </a>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/brand-code.png" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>
          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Training</h3>
                  <p>
                    We provide free webinars to help your team get started quickly, with one-on-one consultations on applicable plans.
                  </p>
                  <a className="btn btn-secondary" href="/training/">
                    Join a webinar
                  </a>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/support-training.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section  section-strip-features-sm section-gray-lighest">
            <div className="container section-inner text-center">
              <div className="features features-cards icon-cards">
                <ul>
                  <li>
                    <div className="feature-card icon-card card-border card-shadow">
                      <div className="card-icon">
                        <i className="fas fa-lightbulb"></i>
                      </div>
                      <div className="card-text">
                        <h3>Get inspired</h3>
                        <p>
                          Our regular newsletter contains the best Shorthand stories of the week.{" "}
                          <a
                            href="https://shorthand.com/the-craft/add-the-craft-to-your-inbox/"
                            target="_blank"
                            rel="noopenner noreferrer"
                            className="btn-link"
                          >
                            Sign up
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="feature-card icon-card card-border card-shadow">
                      <div className="card-icon">
                        <i className="fas fa-question"></i>
                      </div>
                      <div className="card-text">
                        <h3>Knowledge base</h3>
                        <p>
                          Full reference documentation for Shorthand can be found at our support site.{" "}
                          <a href="https://support.shorthand.com/en" className="btn-link">
                            Visit Shorthand Support
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="feature-card icon-card card-border card-shadow">
                      <div className="card-icon">
                        <i className="fas fa-bolt"></i>
                      </div>
                      <div className="card-text">
                        <h3>Fast turnaround</h3>
                        <p>Our customers love our super-fast responses to help requests.</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <section className="row section  section-strip section-strip-quote ">
            <div className="container container-sm">
              <div className="section-strip-col-single section-strip-single-quote">
                <div className="section-strip-quote-inner">
                  <blockquote>
                    &ldquo;The team behind Shorthand is outstanding. From the sales process, to onboarding and continued support ⁠⁠—
                    they are knowledgeable, efficient and will do anything they can to help us succeed.&rdquo;
                  </blockquote>
                  <cite>Nick Bennet | Honda UK</cite>
                </div>
              </div>
            </div>
          </section>

          <section className="row section  section-strip-stories section-border-top">
            <div className="container flex-column section-inner text-center">
              <h3>Visual Storytelling in action</h3>
              <div className="featured-stories">
                <ul>
                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://stories.sydneyoperahouse.com/sydney-opera-house-at-rest/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/opera-rest.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        The Sydney Opera House at Rest <span className="featured-story-brand">Sydney Opera House</span>
                      </h4>
                    </a>
                  </li>

                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://www.bbc.co.uk/sport/extra/1g500y2qln/From-Trayvon-Martin-to-Colin-Kaepernick"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/bbc-blm.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        The story of Black Lives Matter in sport <span className="featured-story-brand">BBC Sport</span>
                      </h4>
                    </a>
                  </li>

                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://interactives.stuff.co.nz/2020/08/aotearoa-in-20/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/stuff-aot.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        Aotearoa in 20 <span className="featured-story-brand">Stuff</span>
                      </h4>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="row section section-dark section-half section-trial !tw-bg-[rgba(249,249,249,0.3)]">
            <div className="container section-inner text-center">
              <h2 className="!tw-text-black">
                Ready to try the digital storytelling platform used by Honda, University of Cambridge, and the BBC?
              </h2>

              <div className="cta-container">
                <a href="https://app.shorthand.com/signup" className="btn btn-lg !tw-bg-black hover:!tw-opacity-70">
                  Start creating
                </a>
              </div>
              <div className="brands">
                <div className="brands-container">
                  <h3 className="as-used">Join these iconic brands</h3>
                  <ul>
                    {brands.map((brand, index) => (
                      <li key={`brand-${brand.node.label}-${index}`} className={`brand`}>
                        <img src={brand.node.logo} alt={`${brand.node.label} logo`} />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div aria-hidden="true" className="background-media background-media-fixed">
              <img src="/media/home/stories.jpg" className="ObjectFit--cover" alt="" />
            </div>
          </section>
          <section className="row section section-gray-lighest section-all-features">
            <div className="container section-inner text-center">
              <h2>
                <a className="unstyled" href="/features/">
                  More Shorthand features
                </a>
              </h2>

              <div className="features features-cards">
                <ul>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/scrollytelling/">
                      <div className="card-text">
                        <h3>Scrollytelling</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/visual-storytelling/">
                      <div className="card-text">
                        <h3>Visual Storytelling</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/collaboration/">
                      <div className="card-text">
                        <h3>Collaboration</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/customisation/">
                      <div className="card-text">
                        <h3>Customisation & Integration</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/branding/">
                      <div className="card-text">
                        <h3>Themes & Branding</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/sharing-and-analytics/">
                      <div className="card-text">
                        <h3>Sharing & Analytics</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/publishing-and-hosting/">
                      <div className="card-text">
                        <h3>Publishing & Hosting</h3>
                      </div>
                    </a>
                  </li>
                  {/* <li>
                  <a className="feature-card card-border card-shadow unstyled" href="/features/support/">
                    <div className="card-text">
                      <h3>Support</h3>
                    </div>
                  </a>
                </li> */}
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/security/">
                      <div className="card-text">
                        <h3>Security</h3>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <Footer className="mt-0" />
        </main>
      </Layout>
    );
  }
}

export const query = graphql`
  query FeaturesSupportQuery {
    site {
      siteMetadata {
        name
        title
        tagline
        description
      }
    }
    allBrandsJson {
      edges {
        node {
          logo
          name
          label
        }
      }
    }
  }
`;
