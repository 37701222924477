import { Enquiry } from "../../components/Enquiry";
import { Footer } from "../../components/Footer";
import { Icon } from "../../components/Icon";
import { Layout } from "../../components/Layout";
import { Link, graphql } from "gatsby";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Helmet } from "react-helmet";

interface IProps {
  className?: string;
}

export default class IndustriesNgo extends React.Component<any, any> {
  state = { showPortal: false };

  constructor(props: IProps, context: any) {
    super(props, context);
    this.openContact = this.openContact.bind(this);
  }

  openContact() {
    this.setState(() => ({ showPortal: true }));
  }

  render() {
    const portalProps = {
      type: "general-enquiry",
      subject: "general-enquiry",
      source: "marketing/industries/ngo",
      onClose: () => this.setState(() => ({ showPortal: false })),
    };

    const { allBrandsJson } = this.props.data;

    const brands = allBrandsJson.edges;

    return (
      <Layout location={this.props.location}>
        <main id="main" className="marketing-content container-fluid">
          <Helmet
            bodyAttributes={{
              class: "body-shorthand body-marketing body-marketing-secondary body-marketing-features body-light hide-elevio",
            }}
          ></Helmet>
          {this.state.showPortal && ReactDOM.createPortal(<Enquiry {...portalProps} />, document.getElementById("modal"))}
          <div className="row section-title title-light">
            <div className="col-11 col-sm-8  mx-auto section-inner">
              <h1>
                <span className="shorthand-logo dark" aria-hidden="true">
                  Shorthand
                </span>
                Non-profit publishing
              </h1>
              <h2>Drive action on the stories that matter.</h2>
            </div>
          </div>
          <section className="row section-brands section-border-top">
            <div className="brands-list">
              <ul>
                <li>
                  <img src="/media/logos/clients/lg-msf.png" loading="lazy" width="348" height="241" alt="MSF logo" />
                </li>
                <li>
                  <img
                    src="/media/logos/clients/lg-world-vision.png"
                    loading="lazy"
                    width="348"
                    height="241"
                    alt="World Vision logo"
                  />
                </li>
                <li>
                  <img src="/media/logos/clients/lg-unicef.png" loading="lazy" width="348" height="241" alt="UNICEF logo" />
                </li>
                <li>
                  <img
                    src="/media/logos/clients/lg-msc.png"
                    loading="lazy"
                    width="348"
                    height="241"
                    alt="Marine Stewardship Council logo"
                  />
                </li>

                <li className="hide-sm">
                  <img src="/media/logos/clients/lg-m2m.png" loading="lazy" width="348" height="241" alt="Mothers2Mothers logo" />
                </li>
                <li>
                  <img src="/media/logos/clients/lg-oxfam.png" loading="lazy" width="348" height="241" alt="Oxfam logo" />
                </li>
              </ul>
            </div>
          </section>

          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Drive action</h3>
                  <p>Convert your readers into supporters, volunteers, and donors through visually rich, immersive feature stories.</p>
                  <ul className="features-list">
                    <li>Give powerful emotional first-hand accounts through integrated audio and video.</li>
                    <li>Make the most of your imagery with impactful full-screen images, galleries, and animation effects.</li>
                    <li>Incorporate subscription and donation forms within the context of your stories.</li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/industries/drive-action-grey.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-right">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>No developers necessary</h3>
                  <p>
                    Shorthand is built for marketers, writers, and content producers &mdash; not developers. Publish beautiful stories
                    in minutes.
                  </p>
                  <ul className="features-list">
                    <li>
                      Build beautiful stories at speed from within your existing team, without the need for expensive and
                      time-consuming web development.
                    </li>
                    <li>
                      Upskill your team with our free expert training. Join us for regular live training webinars and masterclasses.
                    </li>
                  </ul>
                  <a className="btn btn-secondary" href="https://shorthand.com/training/">
                    Join a free webinar
                  </a>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/industries/editorial-right-white.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Stunning reports and campaigns</h3>
                  <p>Capture and keep the attention of your audience with immersive digital stories.</p>
                  <ul className="features-list">
                    <li>Add rich imagery, video, and no-code animations with our built in story sections.</li>
                    <li>Show off your achievements and prove your impact to your stakeholders and community.</li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/industries/msf-right-grey.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-right">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Proven results</h3>
                  <p>
                    Shorthand stories are a proven solution with measurable outcomes &mdash; fewer bounces, more engagement, higher
                    dwell-times, and better click-through-rates.
                  </p>
                  <ul className="features-list">
                    <li>Measure the impact of your digital stories with Shorthand’s built-in analytics system.</li>
                    <li>Easily integrate with your existing analytics platforms, such as Google Analytics.</li>
                    <li>
                      <Link to="/case-studies/" className="btn-link">
                        Read our case studies
                      </Link>{" "}
                      to find out more about the real-world impact of Shorthand.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/industries/analytics-ipad-white.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Discounts for charities and NGOs</h3>
                  <p>
                    Shorthand offers plans for nonprofits of all sizes, ranging from small charities and community groups to well-known
                    international brands.
                  </p>
                  <p className="features-list">
                    Reach out to our team to discuss your needs and to learn about our discounts for registered charities and NGOs.
                  </p>
                  <button className="btn btn-secondary" onClick={this.openContact}>
                    Get in touch
                  </button>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/industries/transformed-right-grey.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section  section-strip section-strip-quote ">
            <div className="container container-sm">
              <div className="section-strip-col-single section-strip-single-quote">
                <div className="section-strip-quote-inner">
                  <blockquote>
                    &ldquo;For ease in creating custom multimedia storytelling experiences, Shorthand is the way to go. But what has
                    been as impressive as their platform is their customer support. They are always quick, efficient, and treat me and
                    my team with personal professionalism that is often lacking from tech companies.&rdquo;
                  </blockquote>
                  <div className="section-strip-quote-rating">
                    <ul>
                      <li>
                        <Icon icon="star" />
                      </li>
                      <li>
                        <Icon icon="star" />
                      </li>
                      <li>
                        <Icon icon="star" />
                      </li>
                      <li>
                        <Icon icon="star" />
                      </li>
                      <li>
                        <Icon icon="star" />
                      </li>
                    </ul>
                  </div>
                  <cite>Reviewer of G2</cite>
                  <a
                    href="https://www.g2.com/products/shorthand/reviews"
                    target="_blank"
                    rel="norefferer noopener"
                    className="btn btn-secondary"
                  >
                    Read more reviews on G2
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section className="row section  section-strip-stories section-border-top">
            <div className="container flex-column section-inner text-center">
              <h3>Take a look at these great non-profit stories, built with Shorthand</h3>
              <div className="featured-stories">
                <ul>
                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://www.dogstrust.org.uk/news-events/issues-campaigns/dog-welfare-crisis"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/dogs-covid.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        The COVID-19 crisis facing the UK’s dogs
                        <span className="featured-story-brand">Dogs Trust</span>
                      </h4>
                    </a>
                  </li>

                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://projects.cafod.org.uk/uganda/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/cafod-journey-desktop.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        A journey in the dark
                        <span className="featured-story-brand">CAFOD</span>
                      </h4>
                    </a>
                  </li>

                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://julien-pecheur.msc.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/msc-julien.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        Julien, pêcheur de homard
                        <span className="featured-story-brand">Marine Stewardship Council</span>
                      </h4>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="row section section-dark section-half section-trial !tw-bg-[rgba(249,249,249,0.3)]">
            <div className="container section-inner text-center">
              <h2 className="!tw-text-black">
                Ready to try the digital storytelling platform used by Honda, University of Cambridge, and the BBC?
              </h2>

              <div className="cta-container">
                <a href="https://app.shorthand.com/signup" className="btn btn-lg !tw-bg-black hover:!tw-opacity-70">
                  Start creating
                </a>
              </div>
              <div className="brands">
                <div className="brands-container">
                  <h3 className="as-used">Join these iconic brands</h3>
                  <ul>
                    {brands.map((brand, index) => (
                      <li key={`brand-${brand.node.label}-${index}`} className={`brand`}>
                        <img src={brand.node.logo} alt={`${brand.node.label} logo`} />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div aria-hidden="true" className="background-media background-media-fixed">
              <img src="/media/home/stories.jpg" className="ObjectFit--cover" alt="" />
            </div>
          </section>

          <Footer className="mt-0" />
        </main>
      </Layout>
    );
  }
}

export const query = graphql`
  query IndustriesNgoQuery {
    site {
      siteMetadata {
        name
        title
        tagline
        description
      }
    }
    allBrandsJson {
      edges {
        node {
          logo
          name
          label
        }
      }
    }
  }
`;
