import "../styles/index.scss";
import { Header } from "./Header";
import { Theme } from "./NavBar";
import { StaticQuery, graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export const DEFAULT_SEO = {
  title: "Start creating stunning visual web content for free | Shorthand",
  description: "Stunning visual web content, editorials, reports, proposals & more — no web design skills required.",
  image: "/media/seo/og_image_default.png",
  ogImage: "/media/seo/og_image_default.png",
  ogTitle: "Start creating stunning visual web content for free | Shorthand",
  ogDescription: "Stunning visual web content, editorials, reports, proposals & more — no web design skills required.",
  twitter: "Stunning visual web content, editorials, reports, proposals & more — no web design skills required.",
};

export function getMetaName(prop: string): string {
  switch (prop) {
    case "title":
      return prop;
    case "description":
      return prop;
    case "image":
      return "image";
    case "ogImage":
      return "og:image";
    case "ogTitle":
      return "og:title";
    case "ogDescription":
      return "og:description";
    case "twitter":
      return "twitter:description";
    default:
      return prop;
  }
}

export function getMetaFields(edge) {
  return [
    getMetaField("description", edge),
    getMetaField("image", edge, "https://shorthand.com"),
    getMetaField("ogImage", edge, "https://shorthand.com"),
    getMetaField("ogTitle", edge),
    getMetaField("ogDescription", edge),
    getMetaField("twitter", edge),
  ];
}

export function getMetaField(prop: string, edge: any, prefix: string = ""): any {
  if (edge && edge.node && edge.node[prop] && edge.node[prop] !== "") {
    return {
      name: getMetaName(prop),
      property: getMetaName(prop),
      content: `${prefix}${edge.node[prop]}`,
    };
  }
  return {
    name: getMetaName(prop),
    property: getMetaName(prop),
    content: `${prefix}${DEFAULT_SEO[prop]}`,
  };
}

export function getEdge(edges: any[], pathname: string): any {
  for (let index = 0; index < edges.length; index++) {
    const edge = edges[index];
    if (edge.node.pathname === pathname) {
      return edge;
    }
  }
}

export interface IMainTemplateProps extends Theme {
  /**
   * the data used in the layout
   */
  data?: any;
  /**
   * include the header optionally
   */
  header?: boolean;
  children?: React.ReactNode;
  location?: Record<any, string>;
}

export const Layout: React.FC<IMainTemplateProps> = ({ children, location, theme = "light-theme" }) => {
  return (
    <StaticQuery
      query={layoutQuery}
      render={data => {
        const edge = getEdge(data.allSeoJson.edges, location.pathname);
        return (
          <div>
            <Helmet title={getMetaField("title", edge).content} meta={getMetaFields(edge)} />

            <Header theme={theme} />
            {children}
          </div>
        );
      }}
    />
  );
};

const layoutQuery = graphql`
  query LayoutQuery {
    site {
      siteMetadata {
        name
        title
        tagline
        description
      }
    }
    allSeoJson {
      edges {
        node {
          pathname
          title
          description
          image
          ogImage
          ogTitle
          ogDescription
          twitter
        }
      }
    }
  }
`;
