import { Badge } from "../Badge/Badge";
import * as styles from "./BillingPeriods.module.scss";
import * as Switch from "@radix-ui/react-switch";
import cx from "classnames";
import * as React from "react";

export const billingLabelmap = {
  monthly: "monthly",
  yearly: "yearly",
};

type BillingPeriodsProps = {
  planName: string;
  period: "monthly" | "yearly";
  showDiscount: boolean;
  discount: string;
  handleFunction: (value: boolean) => void;
};
export const BillingPeriods = (props: BillingPeriodsProps): JSX.Element => {
  const { planName, showDiscount, period, discount, handleFunction } = props;

  function switchChange(value: boolean) {
    handleFunction(value);
  }

  function renderUi(label): JSX.Element {
    switch (label) {
      case "Team":
      case "Business":
        return (
          <>
            {discount && showDiscount ? (
              <Badge highlight={discount === "40%"}>
                <p className={styles.paragraph}>Save {discount}</p>
              </Badge>
            ) : null}
          </>
        );
      case "Enterprise":
        return (
          <>
            {discount === "40%" && showDiscount ? (
              <Badge highlight={discount === "40%"}>
                <p className={styles.paragraph}>Save {discount === "40%" ? "40%" : null}</p>
              </Badge>
            ) : null}
          </>
        );
      default:
        return null;
    }
  }

  const rootStyles = cx(
    styles.switchRoot,
    { [styles.switchRootCheckedStarter]: planName === "Starter" },
    { [styles.switchRootCheckedTeam]: planName === "Team" },
    { [styles.switchRootCheckedBusiness]: planName === "Business" }
  );
  return (
    <div className={styles.billingWrapper}>
      {planName !== "Enterprise" ? (
        <Switch.Root className={rootStyles} checked={period === "yearly"} onCheckedChange={switchChange}>
          <Switch.Thumb className={styles.switchThumb} />
        </Switch.Root>
      ) : null}
      <p className={styles.label}>Billed yearly</p>
      {renderUi(planName)}
    </div>
  );
};
