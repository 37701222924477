import { Layout } from "../components/Layout";
import { Link } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default class NotFoundPage extends React.Component<any, any> {
  render() {
    // Without browser check, all client side redirect are susceptible to blink  because the JavaScript code that
    // triggers the redirect will be managed by the browser itself.
    // https://stackoverflow.com/questions/66030755/how-to-prevent-404-page-flicker-on-redirect-outside-the-website
    // https://github.com/gatsbyjs/gatsby/issues/5329
    const browser = typeof window !== "undefined" && window;

    return (
      browser && (
        <Layout location={this.props.location}>
          <main id="main" className="marketing-content container-fluid">
            <Helmet
              bodyAttributes={{
                class: "body-shorthand body-marketing body-marketing-secondary body-light body-marketing-case-studies hide-elevio",
              }}
            />
            <div className="row section-title title-light title-small">
              <div className="col-11 col-sm-10 col-lg-8 mx-auto">
                <h1>
                  <span className="shorthand-logo dark" aria-hidden="true">
                    Shorthand
                  </span>{" "}
                  404 - Page Not Found
                </h1>
                <Link to="/" className="btn btn-success mt-4">
                  Go to the Shorthand homepage
                </Link>
              </div>
            </div>
          </main>
        </Layout>
      )
    );
  }
}
