// sections
import { SignupCTAAlt } from "../components/Homepage/";
import { LayoutNew } from "../components/LayoutNew";
import { Banner, GTwoReviews, ImageText, Logos, Testimonial } from "../components/Sections";
import { graphql } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";
import * as React from "react";
import { Helmet } from "react-helmet";

export default function Competitors({ data: { competitorsJson }, location }) {
  const {
    name,
    embedUrl: embedUrl,
    bannerSection: bannerSectionData,
    logosSection: logosSectionData,
    mainImageTextSection: mainImageTextSectionData,
    testimonial1Section: testimonial1SectionData,
    verticalScrollSection: verticalScrollSectionData,
    imageTextSection: imageTextSectionData,
    testimonial2Section: testimonial2SectionData,
  } = competitorsJson;

  const g2Reviews = mainImageTextSectionData.g2Reviews;
  const { shorthand, competitor } = g2Reviews;

  useEmbedScript(embedUrl);
  return (
    <LayoutNew location={location}>
      <main>
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-competitors",
          }}
        />
        <section className="tw-my-10">
          <div className="tw-container">
            <Banner
              title={bannerSectionData.title}
              description={bannerSectionData.description}
              cta={bannerSectionData.cta}
              img={bannerSectionData.img}
            />
          </div>
        </section>
        <section className="tw-container">
          <Logos title={logosSectionData.title} imgs={logosSectionData.imgs} />
        </section>
        <section className="tw-my-40 max-lg:tw-my-[6.25rem]">
          <div className="tw-container">
            <div
              className="tw-grid tw-grid-cols-[minmax(0,40fr)_minmax(0,45fr)] tw-items-center tw-gap-x-[10%] max-lg:tw-grid-cols-1
       max-lg:tw-gap-y-10 max-lg:tw-text-center max-sm:tw-grid-cols-[1fr]
         "
            >
              <div
                className="max-lg:tw-order-1 "
                data-sal="slide-up"
                data-sal-duration="500"
                data-sal-delay="100"
                data-sal-easing="ease-out"
              >
                <GTwoReviews leftCol={competitor} rightCol={shorthand} name={name} />
              </div>
              <div>
                <h2 className="tw-h2 tw-mb-6 ">{mainImageTextSectionData.title}</h2>
                <div
                  className="tw-rte"
                  dangerouslySetInnerHTML={{
                    __html: mainImageTextSectionData.content,
                  }}
                />
              </div>
            </div>
          </div>
        </section>
        <div id="embedTitle" className="text-center tw-flex tw-hidden tw-justify-center tw-bg-black tw-pt-16">
          <h2 className="tw-h2 tw-w-[70%] tw-text-white max-sm:tw-w-9/12 max-sm:!tw-text-3xl">{verticalScrollSectionData.title}</h2>
        </div>
        <div id="StoryEmbed"></div>
        <section className="tw-container tw-my-40 max-lg:tw-my-[6.25rem] max-sm:tw-my-20">
          <Testimonial
            firstContentType="testimonial"
            testimonials={testimonial1SectionData.testimonials}
            cta={testimonial1SectionData.cta}
            img={testimonial1SectionData.img}
          />
        </section>
        {imageTextSectionData.map(
          (
            section: {
              title: string;
              content: string;
              video: any;
              img: { alt: string; url: IGatsbyImageData };
            },
            index: number
          ) => {
            return (
              <section className="tw-container tw-my-40 max-lg:tw-my-[6.25rem]" key={section.title}>
                {index % 2 === 0 && (
                  <ImageText
                    mediaType={section.img ? "image" : "video"}
                    img={section.img}
                    video={section.video}
                    title={section.title}
                    content={section.content}
                  />
                )}
                {index % 2 !== 0 && (
                  <ImageText
                    firstContentType="text"
                    mediaType={section.img ? "image" : "video"}
                    img={section.img}
                    video={section.video}
                    title={section.title}
                    content={section.content}
                  />
                )}
              </section>
            );
          }
        )}
        <section className="tw-container tw-my-40 max-lg:tw-my-[6.25rem] max-sm:tw-my-20">
          <Testimonial
            firstContentType="image"
            testimonials={testimonial2SectionData.testimonials}
            cta={testimonial2SectionData.cta}
            img={testimonial2SectionData.img}
          />
        </section>
        <section
          className="section section-end-cta-dark"
          style={{
            minHeight: "auto",
          }}
        >
          <SignupCTAAlt />
        </section>
      </main>
    </LayoutNew>
  );
}

const useEmbedScript = (embedUrl: string) => {
  React.useEffect(() => {
    if (embedUrl) {
      const script = document.createElement("script");
      script.src = `${embedUrl}/embed.js`;
      script.defer = true;
      const embedTitle = document.getElementById("embedTitle");
      const element = document.getElementById("StoryEmbed");
      element.appendChild(script);
      embedTitle.classList.remove("tw-hidden");
      script.onload = () => {
        const header = document.querySelector("#article").childNodes[0];
        document.querySelector("#article").removeChild(header);
      };
    }
  }, [embedUrl]);
};

export const query = graphql`
  query ($slug: String!) {
    competitorsJson(page: { eq: $slug }) {
      name
      bannerSection {
        cta {
          link
          text
        }
        description
        title
        img {
          alt
          url {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      embedUrl
      logosSection {
        imgs {
          alt
          url {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        title
      }
      mainImageTextSection {
        title
        content
        g2Reviews {
          shorthand
          competitor
        }
        img {
          alt
          url {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      verticalScrollSection {
        title
        content {
          title
          content
          cta {
            link
            text
          }
        }
      }

      testimonial1Section {
        testimonials {
          content
          author
        }
        cta {
          link
          text
        }
        img {
          alt
          url {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      imageTextSection {
        title
        content
        video {
          videoWebm {
            publicURL
          }
          videoMp4 {
            publicURL
          }
        }
        img {
          alt
          url {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      testimonial2Section {
        testimonials {
          content
          author
        }
        cta {
          text
          link
        }
        img {
          alt
          url {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
