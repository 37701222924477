import { Link } from "gatsby";
import * as React from "react";

export const CaseStudy = ({ item }) => {
  let cardClass = `card card-case-study text-center col-sm-12 col-md-4`;
  cardClass = `${cardClass} ${item.industryCode}`;

  return (
    <li key={`more-case-studies-${item.jsonId}`} className={cardClass}>
      <Link to={`/case-studies/${item.jsonId}/`} className="bordered unstyled" aria-label={`Case study: ${item.summary}`}>
        <img width="1024" height="768" loading="lazy" src={item.image} alt="" />
        <div className="tw-text-lg` !tw-pt-4 tw-font-semibold">{item.organisation}</div>

        <div className="tw-p-2 tw-text-base">{item.summary}</div>
      </Link>
    </li>
  );
};
