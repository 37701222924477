import { Paragraph } from "../Text";
import * as React from "react";
import { twMerge } from "tailwind-merge";

type WrapperContainer = { className?: string; children: React.ReactNode };
type InfoSection = { children: React.ReactNode };
/**
 * `InfoSection`
 *
 * This component serves as a wrapper to facilitate styling reuse across similar layout sections on the homepage.
 */
export const InfoSection = (props: InfoSection): JSX.Element => {
  return (
    <div className="tw-flex tw-w-1/2 tw-items-center max-container:!tw-w-full max-container:!tw-justify-center">
      <div className="tw-relative tw-pl-[18%] max-container:!tw-pl-0 max-container:tw-pt-10 max-sm:tw-pt-6">
        <div className="tw-flex tw-flex-col tw-text-left max-container:tw-items-center max-container:!tw-text-center">
          {props.children}
        </div>
      </div>
    </div>
  );
};

/**
 * `InfoSectionTextContainer` This component serves as a wrapper to facilitate styling reuse across similar layout sections on the homepage.
 *
 * The intended use is to contain paragraph blocks.
 *
 * Use the className prop as an escape hatch to override the default styles.
 */
export const InfoSectionTextContainer = (props: WrapperContainer): JSX.Element => {
  const classNames = twMerge("tw-w-[90%] max-container:!tw-w-[70%] max-sm:!tw-w-[85%]", props.className);

  return <div className={classNames}>{props.children}</div>;
};

/**
 * `InfoSectionParagraph`
 *
 * This component serves as a wrapper to facilitate styling reuse across similar layout sections on the homepage.
 *
 * Use the className prop as an escape hatch to override the default styles.
 */
export const InfoSectionParagraph = (props: WrapperContainer): JSX.Element => {
  const classNames = twMerge("!tw-m-0 !tw-mt-[23px] !tw-text-2xl tw-text-grey_200  max-sm:!tw-text-base", props.className);

  return <Paragraph className={classNames}>{props.children}</Paragraph>;
};

/**
 * `InfoSectionCtaContainer`
 *
 * This component serves as a wrapper to facilitate styling reuse across similar layout sections on the homepage.
 *
 * Use the className prop as an escape hatch to override the default styles.
 */
export const InfoSectionCtaContainer = (props: WrapperContainer): JSX.Element => {
  const classNames = twMerge("tw-mt-[41px]", props.className);

  return <div className={classNames}>{props.children}</div>;
};

/**
 * `InfoSectionMediaContainer`
 *
 * This component serves as a wrapper to facilitate styling reuse across similar layout sections on the homepage.
 *
 * Use the className prop as an escape hatch to override the default styles.
 */
export const InfoSectionMediaContainer = (props: WrapperContainer): JSX.Element => {
  const classNames = twMerge("tw-h-full tw-w-1/2 max-container:!tw-w-[70%]  max-sm:!tw-w-[94%]", props.className);

  return <div className={classNames}>{props.children}</div>;
};
