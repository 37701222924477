import { filterListByCurrentIndustryCode } from "../../Utils";
import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { RelatedCaseStudies } from "../../components/RelatedCaseStudies";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default function CaseStudy({ data, location }) {
  return (
    <Layout location={location}>
      <main id="main" className="marketing-content container-fluid">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-secondary body-marketing-case-studies hide-elevio",
          }}
        />
        <div className="row section-title">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto  section-inner">
            <h1>
              <span className="shorthand-logo">Shorthand</span>
              <span className="subsection-label">Case Study:</span>
              Stuff
            </h1>
            <h2>How Stuff uses Shorthand</h2>
          </div>
          <div aria-hidden="true" className="background-media">
            <img
              src="/media/case-studies/case-studies-stuff-title.jpg"
              data-object-fit="cover"
              data-autoscale-content-ratio="1.77777777778"
              alt=""
            />
          </div>
        </div>
        <div className="row section">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto marketing-main-column">
            <h3 className="intro">
              With 1.7 million weekly readers online &mdash; from a population of 5 million &mdash; Stuff is New Zealand’s largest
              online news provider.
            </h3>
            <div className="inline-image featured">
              <img
                src="/media/case-studies/case-studies-stuff.jpg"
                alt="Deep Trouble, by Stuff, renders responsively across all devices"
              />
            </div>
            <p>Stuff is also a world-leading publisher of immersive, innovative longform stories.</p>
            <p>
              Since starting with Shorthand in early 2018, the Stuff team have produced over 100 stunning visual stories, including{" "}
              <a
                href="https://interactives.stuff.co.nz/2019/07/407-and-rising/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                407 and rising: The basics of the climate emergency
              </a>{" "}
              and{" "}
              <a
                href="https://interactives.stuff.co.nz/2020/06/unemployment-crisis-covid-economic-recovery/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                Deep Trouble
              </a>
              , a data-driven analysis on unemployment after the first COVID-19 lockdown in 2020.
            </p>
            <p>We chatted with the Stuff team to learn the secret behind their success.</p>
            <h3 className="subheading">Before Shorthand</h3>
            <figure className="block quote-block offset left">
              <blockquote>
                <p>“Shorthand is just a one-stop shop for us. A designer can create a great story without any knowledge of code.”</p>
              </blockquote>
              <figcaption className="quote-cite">Aaron Wood</figcaption>
            </figure>
            <p>
              Aaron Wood is a digital designer at Stuff, and has been with the company for over 25 years. As he explains, before
              starting with Shorthand, the Stuff team didn’t publish nearly as many immersive feature stories.
            </p>
            <p>
              The main reason for this was the cost &mdash; in time and money &mdash; of working with developers to build bespoke
              reading experiences.
            </p>
            <p>
              “Previously, you would need a developer to create the framework for you to then create the longform story. And then there
              would be a lot of to-ing and fro-ing as you created assets and the developer uploaded them to the framework.”
            </p>
            <p>
              This meant that Stuff simply didn’t publish many immersive longform stories. “Before Shorthand, I was mainly working on
              small infographics and interactives, and nothing longform.”
            </p>
            <p>
              When Stuff started using Shorthand in 2018, they were able to produce compelling longform stories in a much shorter
              period of time. “Shorthand is just a one-stop shop for us. A designer can create a great story without any knowledge of
              code.”
            </p>
            <p>
              Kathryn George &mdash; also an experienced digital designer at Stuff &mdash; agrees. “Shorthand just takes away all of
              that headache of working with developers.”
            </p>

            <div className="case-study-video-container">
              <video className="case-study-video-inline" muted loop playsInline autoPlay>
                <source src="/media/case-studies/case-studies-stuff.mp4" type="video/mp4" />
              </video>
            </div>
            <h3 className="subheading">“You could just do it”</h3>
            <figure className="block quote-block offset right">
              <blockquote>
                <p>
                  “I like that you can use the scrollytelling features to animate graphics as the reader scrolls down the page. This
                  makes stories very interactive. It's excellent. I love it.”
                </p>
              </blockquote>
              <figcaption className="quote-cite">Kathryn George</figcaption>
            </figure>
            <p>From the beginning, Kathryn found Shorthand an intuitive platform to use.</p>
            <p>“I’ve always liked the fact that you could just do it straight away, without any coding knowledge.”</p>
            <p>
              Kathryn adds that developers can still be involved in the production of immersive visual stories — but they use their
              expertise on higher value tasks. “I really think it's fantastic that people with coding knowledge can also use it to
              create more customised stories.”
            </p>
            <p>
              Aaron agrees. “You can make your Shorthand story as complicated or as simple as you want. You can start off with a simple
              project, and it will all look fine. But you can also extend the application to its fullest and create quite complex
              pieces with lots of sections.”
            </p>
            <p>
              While Shorthand is simple to use, Kathryn says that more experienced users will find ways to build ever more creative and
              innovative stories.
            </p>
            <p>
              “As you get more experience with Shorthand, you start to realize how creative you could be with all the different
              features, if you wanted to be.
            </p>
            <p>
              “I think that gives the product a lot of depth. I like that you can use the scrollytelling features to animate graphics
              as the reader scrolls down the page. This makes stories very interactive. It's excellent. I love it.”
            </p>

            <div className="photo-grid flex featured">
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-stuff-sml-01.jpg"
                alt="A section from one of Stuff's stories"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-stuff-sml-02.jpg"
                alt="A section from one of Stuff's stories"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-stuff-sml-03.jpg"
                alt="A section from one of Stuff's stories"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-stuff-sml-04.jpg"
                alt="A section from one of Stuff's stories"
              />
            </div>
            <div className="caption">A selection of sections from Stuff's stories</div>

            <h3 className="subheading">The pivot to trust</h3>
            <figure className="block quote-block offset left">
              <blockquote>
                <p>“Everything we do now is about building on levels of public trust.”</p>
              </blockquote>
              <figcaption className="quote-cite">Stuff CEO Sinead Boucher</figcaption>
            </figure>
            <p>
              Stuff’s investment in longform visual storytelling is part of a broader strategy to create a financially sustainable and
              impactful media company. As{" "}
              <a
                href="https://wan-ifra.org/2020/07/stuff-ceo-sinead-boucher-on-building-trust-in-local-media-during-the-covid-era/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                Sinead Boucher put it
              </a>{" "}
              to the World Association of Press Publishers, “Everything we do now is about building on levels of public trust.”
            </p>
            <p>
              These comments followed Boucher’s purchase of Stuff in May 2020 for $1 — that’s not a typo — bringing the company under
              local ownership for the first time.
            </p>
            <p>
              Not long afterward,{" "}
              <a
                href="https://www.stuff.co.nz/business/industries/122048365/stuff-stops-all-activity-on-facebook-in-trial-inspired-by-principle"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                Stuff stopped sharing stories on Facebook
              </a>
              . They also launched{" "}
              <a
                href="https://interactives.stuff.co.nz/2021/02/our-truth-history-aotearoa-new-zealand/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                Our Truth, Tā Mātou Pono
              </a>
              , an investigation into the true history of Aotearoa New Zealand. This involved an apology on the website and front pages
              of Stuff’s major newspapers for the company’s legacy of racist depictions of Māori.
            </p>
            <p>
              Unlike many other media companies around the world, Stuff does not currently have a paywall. Instead, Stuff have adopted
              a Guardian-style membership model, whereby regular readers are encouraged to support Stuff’s groundbreaking journalism.
            </p>
            <p>
              A key part of this model has been what{" "}
              <a
                href="https://thespinoff.co.nz/media/15-06-2021/a-year-on-from-near-death-and-the-1-deal-stuff-is-somehow-thriving/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                media commentator Duncan Grieve dubbed Stuff’s “pivot to trust”
              </a>
              . The production of high quality feature stories, many of which have been built with Shorthand, has become a major aspect
              of this pivot.
            </p>
            <h3 className="subheading">A history of stunning stories</h3>
            <figure className="block quote-block offset right">
              <blockquote>
                <p>“Shorthand stories published by Stuff usually have really high engagement.”</p>
              </blockquote>
              <figcaption className="quote-cite">Aaron Wood</figcaption>
            </figure>
            <p>
              As anyone who subscribes to{" "}
              <a
                href="https://shorthand.com/the-craft/add-the-craft-to-your-inbox/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                Shorthand’s newsletter
              </a>{" "}
              knows, Stuff routinely produces stunning visual stories.
            </p>
            <p>
              One of Kathryn’s favourite stories is{" "}
              <a
                href="https://interactives.stuff.co.nz/2020/08/aotearoa-in-20/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                Aotearoa in 20
              </a>
              , a story that drew from census data to illustrate the changes in Aotearoa New Zealand. (The story was recently featured
              in Shorthand’s round-up of{" "}
              <a
                href="https://shorthand.com/the-craft/impressive-digital-stories/index.html"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                15 stunning digital stories
              </a>
              .)
            </p>
            <p>
              “I worked with our developer John Harford and data journalist Felippe Rodrigues to create these attractive scrollmation
              charts. It came together nicely and resonated with quite a lot of people. Everything worked well together.”
            </p>
            <p>
              Kathryn is also proud of{" "}
              <a
                href="https://interactives.stuff.co.nz/2019/10/manutimori-james-cook-endeavour-history/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                Manutīmori and the Giant Bird
              </a>
              , a beautifully illustrated story about Aotearoa New Zealand before the arrival of Pākehā (Europeans). Alongside the
              illustrations, the story includes interactive text, which translates Māori words with a click or a tap.
            </p>
            <p>As Kathryn explains, “you could also click to hear the sounds of three extinct birds, including the Moa.”</p>
            <p>
              Aaron’s favourite story from recent years is{" "}
              <a
                href="https://interactives.stuff.co.nz/2020/coronavirus-covid-19-clusters/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                The Cluster Paradox
              </a>
              , a visually impressive investigation into the growth of a COVID-19 cluster, which also featured in Shorthand’s
              newsletter of best digital stories from around the web.
            </p>
            <p>As he explains, stories like these routinely perform very well.</p>
            <p>
              “Shorthand stories published by Stuff usually have really high engagement. Generally, we see two to three minutes, which
              is really high. And most of our stories also see over 70,000 pageviews — sometimes much more than that.”
            </p>
            {/* <div className="related-stories">
              <h4>More from Stuff:</h4>
              <ul>
                <li>
                  <a href="https://sites.stuff.org.uk/introducingmichaelclark/" target="_blank" rel="noopener noreferrer">
                    Michael Clark: Cosmic Dancer exhibition
                  </a>
                </li>
                <li>
                  <a href="https://sites.stuff.org.uk/soundhouse/" target="_blank" rel="noopener noreferrer">
                    Soundhouse
                  </a>
                </li>
                <li>
                  <a href="https://sites.stuff.org.uk/justynaszymanska/" target="_blank" rel="noopener noreferrer">
                    Instagrammer in Residence: Justyna Szymańska
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
        <RelatedCaseStudies studies={filterListByCurrentIndustryCode(data, "stuff")} />
        <Footer />
      </main>
    </Layout>
  );
}

export const query = graphql`
  query stuffQuery {
    allCaseStudiesJson {
      edges {
        node {
          jsonId
          image
          industry
          industryCode
          organisation
          summary
        }
      }
    }
  }
`;
