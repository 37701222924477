import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { LegalNav } from "../../components/LegalNav";
import { Link } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default class TermsView extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <main id="main" className="marketing-content container-fluid">
          <Helmet
            bodyAttributes={{
              class: "body-shorthand body-marketing body-marketing-secondary body-light body-marketing-legal hide-elevio",
            }}
          >
            <title>User terms of service | Shorthand</title>
            <meta name="description" content="Shorthand terms of service." />
          </Helmet>
          <div className="row section-title title-light">
            <div className="col-12 col-sm-10 col-lg-8 col-xxl-6 mx-auto">
              <h1>
                <span className="shorthand-logo dark" aria-hidden="true">
                  Shorthand
                </span>{" "}
                User terms of service
              </h1>
              <p>
                <small className="small-text text-muted">Effective: 26 March 2024</small>
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="d-none d-lg-block col-lg-2">
              <LegalNav />
            </div>
            <div className="col-12 col-sm-10 col-lg-8 col-xxl-6">
              <section>
                <p className="intro">
                  Hi! Thanks so much for choosing Shorthand! These Shorthand User Terms of Service <strong>(User Terms)</strong>{" "}
                  describe your rights and responsibilities as an end user of our online digital content creation and collaboration
                  platform and all associated services <strong>(Platform)</strong>. If you sign up for an account as an authorised
                  representative of your organisation, the{" "}
                  <Link className="btn-link" to="/legal/customer-terms">
                    Customer Terms of Service
                  </Link>{" "}
                  govern your access and use of the Platform, so please read those instead.
                </p>
              </section>
              <section>
                <h2>1. The beginning of a beautiful relationship (Effect of Terms)</h2>
                <ol className="letters">
                  <li>
                    These <strong>User Terms</strong>, together with our{" "}
                    <Link className="btn-link" to="/legal/privacy-policy">
                      Privacy Policy
                    </Link>
                    ,{" "}
                    <Link className="btn-link" to="/legal/acceptable-use-policy">
                      Acceptable Use Policy
                    </Link>
                    ,{" "}
                    <Link className="btn-link" to="/legal/copyright-and-trademark-policy">
                      Copyright and Trademark Policy
                    </Link>{" "}
                    (Our Policies), constitute a binding contract between you (<strong>User</strong> or <strong>you</strong>) and
                    Shorthand Pty Ltd ABN 88 162 625 470 (<strong>Shorthand</strong>, <strong>us</strong> or <strong>we</strong>
                    ).
                  </li>
                  <li>
                    By being invited to a workspace on the Platform or otherwise accessing, logging on or using the Platform, you
                    confirm you have read, understood and agree to these User Terms.
                  </li>
                </ol>
              </section>

              <section>
                <h2>2. Grant of Licence</h2>
                <ol>
                  <li>
                    In consideration for agreeing to abide by these User Terms, we grant you a non-exclusive, non-sub-licensable,
                    revocable and non-transferable licence to access and use the Platform (and any third-party software incorporated in
                    the Platform) on these User Terms.
                  </li>
                </ol>
              </section>

              <section>
                <h2>3. Your use of the Platform (Account)</h2>
                <h3>3.1 Authorised user</h3>
                <ol>
                  <li>
                    You acknowledge that you are accepting these User Terms in your capacity as an ‘Authorised User’ of the Platform
                    and that your use of the Platform is subject to any rights and consent of your employer or other authorised
                    representative who has entered into a binding contract with us <strong>(Customer)</strong>.
                  </li>
                </ol>
                <h3>3.2 Legal age</h3>
                <ol>
                  <li>
                    You acknowledge that you are over the age of 16 years and are the intended recipient of the Customer’s invitation
                    to access and use the Platform.
                  </li>
                </ol>
                <h3>3.3 Acceptable use</h3>
                <p>You must:</p>
                <ol className="letters">
                  <li>
                    comply with these User Terms, Our Policies and the terms contained in the customer terms agreed to between us and
                    the Customer which relates to the use of the Platform <strong>(Customer Terms)</strong>;
                  </li>
                  <li>comply with all applicable laws, regulations and codes;</li>
                  <li>comply with any reasonable directions issued by us from time to time; and</li>
                  <li>promptly report any errors, defects or malfunctions to us.</li>
                </ol>
                <h3>3.4 Restrictions on use</h3>
                <p>Except as expressly set out in these User Terms or as permitted by law, you must:</p>
                <ol className="letters">
                  <li>not attempt to make any copy of the Platform or any underlying source code;</li>
                  <li>comply with all applicable laws when accessing and using the Platform;</li>
                  <li>
                    not sell, transfer, rent, lease, sub-license, loan, translate, merge, adapt, vary, alter, modify or reverse
                    engineer, the whole or any part of the Platform nor permit the Platform or any part of it to be combined with, or
                    become incorporated in, any other materials or similar products;
                  </li>
                  <li>
                    not attempt to access or control the computer equipment on which any software relating to the Platform is
                    installed;
                  </li>
                  <li>
                    not provide, or otherwise make available, the Platform in any form, in whole or in part to any person without our
                    prior written consent;
                  </li>
                  <li>
                    not introduce or upload anything to the Platform that:
                    <ol className="roman">
                      <li>includes viruses or other malicious code;</li>
                      <li>
                        threatens the integrity and security of the Platform, the hosting infrastructure, the internet, or other users
                        of the Platform and their systems;
                      </li>
                      <li>is threatening, abusive, offensive, endangers any person, unlawfully discriminatory or immoral;</li>
                      <li>
                        contains any information that breaches any code of conduct, whether voluntary or otherwise, including any
                        advertising code of conduct, or industry standard;
                      </li>
                      <li>is false, misleading or deceptive or is likely to mislead or deceive; or</li>
                      <li>
                        breaches any third party's intellectual property rights or discloses a third party’s confidential information
                        without their consent; and
                      </li>
                    </ol>
                  </li>
                  <li>to use the Platform only for the purpose for which it has been developed.</li>
                </ol>
              </section>

              <section>
                <h2>4. What you need to do (Your obligations)</h2>
                <h3>4.1 Access and security</h3>
                <ol className="letters">
                  <li>
                    You are responsible for:
                    <ol className="roman">
                      <li>obtaining the network access which is necessary to use the Platform; and</li>
                      <li>
                        acquiring and updating compatible hardware or devices necessary to access and use the Platform and any updates
                        or upgrades to this hardware or these devices.
                      </li>
                    </ol>
                  </li>
                  <li>
                    You acknowledge that:
                    <ol className="roman">
                      <li>
                        we do not guarantee that the Platform, or any portion of the Platform, will function on any particular hardware
                        or devices;
                      </li>
                      <li>
                        the Platform may be subject to malfunctions and delays inherent in the use of the Internet and electronic
                        communications;
                      </li>
                      <li>
                        access to and use of the Platform may be impaired or prevented by a variety of factors that are beyond our
                        control, for instance defects in your computer system and problems with internet; and
                      </li>
                      <li>
                        we are not responsible for any problems with the connectivity between you and the network server and this does
                        not constitute defects in the Platform or its underlying Source Code.
                      </li>
                    </ol>
                  </li>
                </ol>
                <h3>4.2 Your Data</h3>
                <ol className="letters">
                  <li>
                    You are responsible for the content, accuracy and completeness of any data provided to us or other users under
                    these User Terms or which is inputted using the Platform, including personal information{" "}
                    <strong>(Your Data)</strong>.
                  </li>
                  <li>
                    We may copy, reproduce, use, store, communicate or adapt any of Your Data for the purposes of licensing the
                    Platform (and any related or ancillary services) and otherwise on the terms set out in our privacy policy.
                  </li>
                  <li>
                    You are responsible for obtaining any consents or permissions required with respect to Your Data and complying with
                    any applicable laws, including all privacy laws in respect of that information.
                  </li>
                </ol>
              </section>

              <section>
                <h2>5. Our stuff and your stuff (Intellectual Property Rights)</h2>
                <h3>5.1 Licence</h3>
                <p>
                  You agree and acknowledge that the intellectual property rights in the Platform (other than third party software)
                  shall remain our property and we reserve the right to grant a licence to use the Platform to any other parties.
                </p>
                <h3>5.2 Your Data</h3>
                <p>
                  All intellectual property rights in Your Data remain your property or of relevant third parties and nothing in these
                  User Terms shall be construed as giving us any rights to such intellectual property rights.
                </p>
                <h3>5.3 Third party Software</h3>
                <p>
                  All intellectual property rights in any third-party software which is incorporated in the Platform including any
                  improvements to such intellectual property rights, remain vested in the relevant third party supplier (as
                  applicable).
                </p>
                <h3>5.4 Feedback</h3>
                <p>
                  If you provide us with any feedback or suggestions regarding the Platform or any material or content on the Platform
                  <strong>(Feedback)</strong>, you grant us an irrevocable, perpetual, transferable, royalty-free licence to use any
                  Feedback for any purpose in connection with our business.
                </p>
              </section>

              <section>
                <h2>6. How the story ends (Termination)</h2>
                <ol className="letters">
                  <li>
                    We may terminate these User Terms immediately by written notice to you if:
                    <ol className="roman">
                      <li>the Customer’s subscription expires or has been terminated in accordance with the Customer Terms; or</li>
                      <li>your access to the Platform has otherwise been terminated by the Customer or us.</li>
                    </ol>
                  </li>
                  <li>
                    Upon termination of these User Terms for any reason:
                    <ol className="roman">
                      <li>all legal rights of a party arising from a breach that preceded termination survive; and</li>
                      <li>
                        these User Terms are otherwise at an end and all licenses granted in these User Terms are immediately
                        terminated.
                      </li>
                    </ol>
                  </li>
                </ol>
              </section>

              <section>
                <h2>7. Responsibilities when something goes wrong (Disclaimer)</h2>
                <p>To the maximum extent permitted by law, except as set out in these User Terms:</p>
                <ol className="letters">
                  <li>
                    we exclude all warranties, express or implied, including without limitation, implied warranties of merchantability,
                    fitness for a particular purpose, title, compatibility, security, accuracy or non-infringement;
                  </li>
                  <li>
                    we do not warrant or represent that the Platform will operate continuously, free from faults, errors or
                    interruptions; and we are never liable to you and you release us from any claim for all loss, damage, costs,
                    expenses or liability whatsoever and however caused.
                  </li>
                  <li>the Platform is provided “as is,” and we do not guarantee the functionality of the Platform;</li>
                  <li>we make no representations as to the availability of the Platform;</li>
                  <li>
                    we shall not be liable to you for any loss of use, lost or inaccurate data or data corruption, non-compliance with
                    any statutory or legal obligation or deadline, lost profits, failure of security mechanisms, interruption of
                    business, delays or any direct, indirect, special, incidental, reliance or consequential damages of any kind,
                    regardless of the form of action, whether in contract, tort (including negligence), strict liability or otherwise,
                    even if informed of the possibility of such damages in advance.
                  </li>
                </ol>
              </section>

              <section>
                <h2>8. What you and we guarantee (Warranties, disclaimers and indemnities)</h2>
                <h3>8.1 Warranties</h3>
                <p>
                  You warrant that you and Your Users will not use the Platform in a manner that infringes the rights of any other
                  person or breaches any applicable law, regulation or our Acceptable Use Policy.
                </p>
                <h3>8.2 Warranty disclaimer</h3>
                <p>
                  EXCEPT AS EXPRESSLY PROVIDED FOR IN THESE USER TERMS, TO THE EXTENT PERMITTED BY LAW, THE SERVICES AND ALL RELATED
                  COMPONENTS AND INFORMATION ARE PROVIDED ON AN ‘AS IS’ AND ‘AS AVAILABLE’ BASIS WITHOUT ANY WARRANTIES OF ANY KIND,
                  AND WE EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF
                  MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. YOU ACKNOWLEDGE THAT WE DO NOT WARRANT
                  THAT THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE.
                </p>
                <h3>8.3 Your indemnification of us</h3>
                <p>You indemnify us in respect of any loss or damage we incur which arises out of or in connection with:</p>
                <ol className="letters">
                  <li>your use of the Platform;</li>
                  <li>any breach by you of your obligations under these User Terms;</li>
                  <li>the unauthorised use of your account;</li>
                  <li>our use of Your Data; or</li>
                  <li>
                    any claim that Your Data, its use, storage, reproduction or communication, infringes another person’s rights.
                  </li>
                </ol>
                <h3>8.4 Our indemnification of you</h3>
                <p>
                  We will defend you against any claim brought against you by a third party alleging that the Platform, when used in
                  accordance with these User Terms, infringes or misappropriates any third-party Intellectual Property Right{" "}
                  <strong>(Claim)</strong>, and we will indemnify you and hold you harmless against any damages and costs finally
                  awarded on the Claim by a court of competent jurisdiction or agreed to via settlement executed by us (including
                  reasonable solicitors’ fees), provided that we have received from you:{" "}
                </p>
                <ol className="letters">
                  <li>
                    prompt written notice of the Claim (but in any event notice in sufficient time for us to respond without
                    prejudice);
                  </li>
                  <li>
                    reasonable assistance in the defence and investigation of the Claim, including providing us a copy of the Claim,
                    all relevant evidence in your possession, custody, or control, and cooperation with evidentiary discovery,
                    litigation, and trial, including making witnesses within your employ or control available for testimony; and
                  </li>
                  <li>
                    the exclusive right to control and direct the investigation, defence, and settlement (if applicable) of the Claim.
                  </li>
                </ol>
                <p>
                  If your use of the Platform is (or in your opinion is likely to be) enjoined, whether by court order or by
                  settlement, or if we determine such actions are reasonably necessary to avoid material liability, we may, at our
                  option and in our discretion:
                </p>
                <ol className="roman">
                  <li>procure the right for your continued use of the Platform in accordance with these Terms;</li>
                  <li>substitute a substantially functionally similar Platform; or</li>
                  <li>
                    terminate your right to continue using the Platform and refund any prepaid amounts for the terminated portion of
                    the Subscription Term.
                  </li>
                </ol>
                <p>Our indemnification obligations above do not apply:</p>
                <ol className="numbers">
                  <li>
                    if the total aggregate fees we receive with respect to your Subscription to the Platform in the twelve (12) month
                    period immediately preceding the Claim is less than US$50,000;
                  </li>
                  <li>
                    if the Platform is modified by any party other than us, but solely to the extent the alleged infringement is caused
                    by such modification;
                  </li>
                  <li>
                    if the Platform is used in combination with any third-party product, software, service or equipment, but solely to
                    the extent the alleged infringement is caused by such combination;
                  </li>
                  <li>to unauthorized use of the Platform;</li>
                  <li>
                    to any Claim arising as a result of (a) Your Data or circumstances covered by your indemnification obligations or
                    (b) any third-party deliverables or components contained with the Platform or
                  </li>
                  <li>if you settle or make any admissions with respect to a Claim without our prior written consent.</li>
                </ol>
                <p>
                  THIS CLAUSE 8.4 (Our indemnification of you) STATES OUR SOLE LIABILITY AND YOUR EXCLUSIVE REMEDY FOR ANY INFRINGEMENT
                  OF INTELLECTUAL PROPERTY RIGHTS IN CONNECTION WITH THE PLATFORM OR ANY CLOUD PRODUCT OR OTHER ITEMS WE PROVIDE UNDER
                  THESE USER TERMS.
                </p>
              </section>

              <section>
                <h2>9. Protecting personal data (Privacy)</h2>
                <p>
                  We will only use and disclose your personal data in accordance with our privacy policy (accessible on our website)
                  and otherwise in accordance with the Privacy Act 1988 (Cth) the Data Protection Acts of 1998 and 2018 (UK), the EU
                  General Data Protective Regulation (Regulation (EU) 2016/679), and all other laws, regulations, registered privacy
                  codes, privacy policies and contractual terms applicable in the jurisdiction where the Platform is being licenced
                  that relate to the processing of personal data. Where we transfer personal data of individuals, we will do so in
                  accordance with our standard Data Processing Agreement set out on our website, which is incorporated by reference to
                  these User Terms
                </p>
              </section>

              <section>
                <h2>10. Stranger things (Force Majeure)</h2>
                <p>
                  Neither party shall be liable for any delay or failure to perform its obligations pursuant to these User Terms if
                  such delay is due to an event outside of the reasonable control of a party, including an act of God, national
                  emergency, terrorist act, sabotage, flood, storm, earthquake, fire, explosion, civil disturbance, insurrection, riot,
                  war, or industrial action.
                </p>
              </section>

              <section>
                <h2>11. Other important stuff (General)</h2>
                <h3>11.1 Governing law</h3>
                <ol className="letters">
                  <li>
                    If you are located in the UK or Europe, the laws of England and Wales govern these User Terms and each party
                    irrevocably submits to the non-exclusive jurisdiction of the courts of England and Wales and courts competent to
                    hear appeals from those courts.
                  </li>
                  <li>
                    If you are located in the United States of America, California laws shall govern these User Terms, and each party
                    irrevocably submits to the non-exclusive jurisdiction of the courts of the State of California and courts competent
                    to hear appeals from those courts.
                  </li>
                  <li>
                    If you are located in Australia, or a jurisdiction not covered by subclauses (a) or (b) above, the laws of
                    Queensland shall govern these User Terms, and each party irrevocably submits to the non-exclusive jurisdiction of
                    the courts of Queensland, Australia and courts competent to hear appeals from those courts.
                  </li>
                  <li>
                    The terms of the United Nations Convention on Contracts for the Sale of Goods do not apply to these User Terms.
                  </li>
                </ol>
                <h3>11.2 Amendments</h3>
                <p>
                  We may amend or vary these User Terms at any time by notice to you. You may elect to terminate this licence rather
                  than agreeing to the updated User Terms, but continued use of the Platform will be subject to agreeing to the updated
                  User Terms, and if you continue to use the Platform after receiving notice of the updated User Terms.
                </p>
                <h3>11.3 Modifications</h3>
                <p>
                  We may modify these User Terms, including Our Policies, from time to time as our business evolves. We will provide
                  reasonable notice of any material changes to these User Terms in accordance with clause 12(b). Any material changes
                  to these User Terms will become effective upon the date given in our notice. Any other changes to these User Terms
                  will become effective upon the date of posting. If you or any of Your Users access the Platform after the effective
                  date of the changes, you will be deemed to have given your acceptance of the modified User Terms.
                </p>
                <h3>11.4 Assignment of rights</h3>
                <ol className="letters">
                  <li>
                    We may transfer our rights and obligations under these User Terms to another organisation. We will always tell you
                    in writing if this happens and we will ensure that the transfer will not affect your rights under these User Terms.
                  </li>
                  <li>
                    You may only transfer your rights or your obligations under these User Terms to another person if we agree in
                    writing.
                  </li>
                </ol>
                <h3>11.5 Severability</h3>
                <p>
                  Each of the clauses in these User Terms operates separately. If any court or relevant authority decides that any of
                  them are unlawful, the remaining clauses will remain in full force and effect.
                </p>
                <h3>11.6 Entire agreement</h3>
                <p>
                  These User Terms constitute the entire agreement between you and us on the subject matter, and merges and supersedes
                  all other or prior understandings, agreements and arrangements.
                </p>
                <h3>11.7 Further assurance</h3>
                <p>
                  Each party must do all things reasonably necessary to give effect to these User Terms and the transactions
                  contemplated by it.
                </p>
                <h3>11.8 Waiver</h3>
                <p>
                  A right under these User Terms may only be waived in writing signed by the party granting the waiver, and is
                  effective only to the extent specifically set out in that waiver.
                </p>
              </section>

              <section>
                <h2>12. Staying in touch (Communications)</h2>
                <ol className="letters">
                  <li>
                    If you wish to contact us in writing, or if any condition in these User Terms requires you to give us notice in
                    writing, please contact us at legal@shorthand.com. We will confirm receipt of this by contacting you in writing,
                    normally by email.
                  </li>
                  <li>
                    If we have to contact you or give you notice in writing, we will do so by email or by pre-paid post to the address
                    you provide or confirm to us.
                  </li>
                </ol>
              </section>
            </div>
          </div>
          <Footer />
        </main>
      </Layout>
    );
  }
}
