import { filterListByCurrentIndustryCode } from "../../Utils";
import { Footer } from "../../components/Footer";
import { LayoutNew } from "../../components/LayoutNew";
import { RelatedCaseStudies } from "../../components/RelatedCaseStudies";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default function CaseStudy({ data, location }) {
  return (
    <LayoutNew location={location} data={data}>
      <div id="main" className="marketing-content container-fluid">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-secondary body-marketing-case-studies hide-elevio",
          }}
        />
        <div className="row section-title">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6 mx-auto section-inner">
            <h1>
              <span className="shorthand-logo">Shorthand</span>
              How EU Neighbours East uses Shorthand
            </h1>
          </div>
          <div aria-hidden="true" className="background-media">
            <img
              src="/media/case-studies/eu-neighbours-east-blur.jpg"
              data-object-fit="cover"
              data-autoscale-content-ratio="1.77777777778"
              alt="EU Neighbours East"
            />
          </div>
        </div>
        <div className="row section">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto marketing-main-column">
            <h2 className="intro">
              Shorthand helps EU Neighbours East reach their target regional audiences and easily iterate on their content.
            </h2>
            <div className="inline-image featured">
              <img alt="EU Neighbours East logo" src="/media/case-studies/eu-neighbours-east-main.png" />
              <div className="caption">A selection of sections from an EU Neighbours East story.</div>
            </div>
            <p>
              Joanna Duchesne, Multimedia Director at the nonprofit EU Neighbours East, commissions and produces visual content that
              highlights the European Union's partnership with Armenia, Azerbaijan, Belarus, Georgia, Moldova, and Ukraine.{" "}
            </p>
            <p>
              Duchesne has been a champion of Shorthand since she first used the editor in 2020. Now, her team creates stories bursting
              with colour, culture, and politics — from critical economic news to a cookbook of recipes from their Young European
              Ambassadors programme.
            </p>
            <p>
              Shorthand has helped to streamline their content production workflow and bring out their team’s creative vision, so they
              can reach their specific regional audiences.
            </p>
            <h3 className="subheading after:!tw-bg-[#D9D9D9]">A limiting CMS</h3>
            <p>
              Before they started with Shorthand, EU Neighbours East created and published most of their digital content through their
              CMS. “Social media channels are a way of communicating to different audiences,” Duchesne says, “but our mothership was,
              and still is, the website.”
            </p>
            <p>
              {" "}
              EU Neighbours East had made several “special pages” — stories outside their normal news output that were rich in media
              and designed to be shared — before using Shorthand. Looking back on them, Duchesne says, “They can't compare to
              Shorthand. In terms of the resources and time, I think they probably cost about the same, but if you look at the results
              of those pages compared to one of the Shorthand stories, there's no comparison in terms of how Shorthand brings
              everything together.”
            </p>

            <div className="photo-grid flex featured">
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/eu-neighbours-east-01.jpg" />
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/eu-neighbours-east-02.jpg" />
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/eu-neighbours-east-03.jpg" />
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/eu-neighbours-east-04.jpg" />
            </div>
            <div className="caption">A selection of sections from an EU Neighbours East article.</div>

            <h3 className="subheading after:!tw-bg-[#D9D9D9]">More engagement and more traffic</h3>
            <p>It is this “bringing everything together” that’s invaluable for EU Neighbours East and their content production.</p>
            <p>
              Before Shorthand, much of the content EU Neighbours East produced was for social media, or single pages that they
              promoted through their newsletter or social media. Shorthand enabled them to make high-quality longform content and
              gather it on a central landing page. It’s an iterative process that allows them to link in older material and update
              longer stories or campaigns as they unfold — and it’s proved much more successful.
            </p>
            <p>
              For one of their recent Shorthand-made campaigns, the{" "}
              <a href="https://euneighbourseast.eu/photocontest/" target="_blank">
                Family Connections Photo Contest
              </a>
              , Duchesne says the story “generated more traffic and reached more target audiences than we’d hoped for. We got 82,000
              pageviews and 80% of that traffic was from Eastern European countries. So that was an enormous success for us.”
            </p>
            <figure className="block quote-block offset right">
              <blockquote className="!tw-text-[#303030] !tw-opacity-70">
                <p>
                  <i>
                    “We got 82,000 pageviews and 80% of that traffic was from Eastern European countries. So that was an enormous
                    success for us.”
                  </i>
                </p>
              </blockquote>
              <figcaption className="quote-cite !tw-not-italic !tw-text-[#333333]">Joanna Duchesne</figcaption>
            </figure>
            <p>
              While the competition was running, the Shorthand-built landing page evolved to reflect the stages of the competition, and
              the various languages of the countries involved.
            </p>
            <p>
              Now, the six countries’ shortlisted entries have been consolidated using Shorthand’s Collections feature, allowing
              readers to explore the vivid individual projects from a central page. Shorthand’s Media and Collection sections gave
              Duchesne and her team the tools to make these compelling visual stories shine. The Reveal section, too, allows the team
              to showcase information and data.
            </p>

            <h3 className="subheading after:!tw-bg-[#D9D9D9]">Shorthand inspires effective collaboration</h3>
            <p>
              Because the Shorthand editor allows different specialists to work together in one workspace, they can have a “more
              focussed approach” to their campaigning.
            </p>
            <figure className="block quote-block offset left">
              <blockquote className="!tw-text-[#303030] !tw-opacity-70">
                <p>
                  <i>“It's allowed us to create a digital space where the different aspects of our work can be showcased."</i>
                </p>
              </blockquote>
              <figcaption className="quote-cite !tw-not-italic !tw-text-[#333333]">Joanna Duchesne</figcaption>
            </figure>
            <p>
              “You have a website editor, for example, who's brilliant at writing,” Duchesne explains, then “you have the audiovisual
              production team who can actually bring the visuals together. You have the campaign manager who's got the strategic
              overview and has the ability to cut through what we're trying to say.
            </p>
            <p>
              So, all of those things enabled us to work together more practically and to bring out each other's strengths. It's
              allowed us to create a digital space where the different aspects of our work can be showcased.”
            </p>
            <p>
              Shorthand’s customer support also gets a shout out, providing a critical bedrock for people who — despite the beauty of
              their content — are not professional designers or coders. Duchesne says she loves that, “If we ever need anything, all I
              need to do is email you, and you’re so responsive.”
            </p>
            <div className="photo-grid flex featured">
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/eu-neighbours-east-05.jpg" />
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/eu-neighbours-east-06.jpg" />
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/eu-neighbours-east-07.jpg" />
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/eu-neighbours-east-08.jpg" />
            </div>
            <div className="caption">A selection of sections from an EU Neighbours East article.</div>
            <h3 className="subheading after:!tw-bg-[#D9D9D9]">Making a deeper impact with their readers</h3>
            <p>
              KPIs are always a core focus for content teams, and Duchesne says that building campaigns with Shorthand has increased
              the number of visits and repeat visitors to their website.
            </p>
            <p>
              Still, she says, there’s something more going on. “Obviously it's the visits, it's the amount of views, it's the amount
              of time people stay,” she says, “but I think as well it's anecdotal. People tell us whether they like something or not.”
              This audience engagement is core to their ethos, helping them craft stories that speak to a specific region and cultures.
            </p>
            <p>
              The photo contest was a perfect example of high metrics and high emotional engagement from people in the region they were
              targeting. “We were showcasing people in the region,” explains Duchesne, “and it was in their interest to link the page
              to what they were doing. We were able to showcase the photography projects, which people related to in the different
              countries. It was working with human nature in the best possible way.”
            </p>
            <h3 className="subheading after:!tw-bg-[#D9D9D9]">New opportunities and next steps</h3>
            <p>
              The whole EU Neighbours East team is thoroughly convinced by the success of their Shorthand stories, and Duchesne is
              looking forward to the opportunities the tool has unlocked.
            </p>
            <p>
              Shorthand helped Duchesne’s team to “think in innovative ways how to tell a story, not having to stick to traditional
              ways, and to show others how we could potentially tell stories.”
            </p>
            <figure className="block quote-block offset left">
              <blockquote className="!tw-text-[#303030] !tw-opacity-70">
                <p>
                  <i>“Shorthand was a breath of fresh air.”</i>
                </p>
              </blockquote>
              <figcaption className="quote-cite !tw-not-italic !tw-text-[#333333]">Joanna Duchesne</figcaption>
            </figure>
            <p>
              For Duchesne, Shorthand was “a breath of fresh air.” She’s always produced visual content, but Shorthand has enabled her
              to “find a new creative outlet, and to work with others on that.”
            </p>
            <p>
              As for what’s next? Duchesne is already plotting to update some of the material they made before Shorthand, as well as a
              raft of materials to promote and feature their{" "}
              <a href="https://euneighbourseast.eu/young-european-ambassadors/" target="_blank">
                Young European Ambassadors programme
              </a>
              .
            </p>
            <p>
              The EU Neighbours East team is also working on the next phase of a special campaign page aimed at younger audiences and
              teachers to raise awareness about renewable energy. The page features a special hamster called Ozzy, who shows how using
              clean energy is key to our future. “It is a page definitely worth visiting,” says Duchesne.
            </p>

            <div className="inline-image featured">
              <img alt="" src="/media/case-studies/eu-neighbours-east-09.jpg" />
              <div className="caption">A selection of sections from an EU Neighbours East article.</div>
            </div>
          </div>
        </div>
        <RelatedCaseStudies studies={filterListByCurrentIndustryCode(data, "smarthinking")} />
        <Footer />
      </div>
    </LayoutNew>
  );
}

export const query = graphql`
  query smarthinkingQuery {
    allCaseStudiesJson {
      edges {
        node {
          jsonId
          image
          industry
          industryCode
          organisation
          summary
        }
      }
    }
  }
`;
