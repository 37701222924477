import * as React from "react";

export const GradientOverlay = ({ children }: { children: React.ReactNode }): JSX.Element => {
  return (
    <div style={{ position: "relative" }}>
      {children}
      <div
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          zIndex: 100,
          background: "linear-gradient(0deg, rgba(13, 13, 13, 0.2) 0%, rgba(6, 6, 6, 0.04) 7%, rgba(6, 6, 6, 0) 100%)",
        }}
      />
    </div>
  );
};
