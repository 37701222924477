export { default as Banner } from "./Banner";
export { default as BannerSimple } from "./BannerSimple";
export { default as Logos } from "./Logos";
export { default as ImageText } from "./ImageText";
export { default as Testimonial } from "./Testimonial";
export { default as FooterBanner } from "./FooterBanner";
export { default as FooterBanner2 } from "./FooterBanner2";
export { default as FooterBannerWithLogos } from "./FooterBannerWithLogos";
export { default as Feature } from "./Feature";
export { default as Cards } from "./Cards";
export { default as GTwoReviews } from "./GTwoReviews";
export { default as ChooseShorthandReasons } from "./ChooseShorthandReasons";
