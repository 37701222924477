import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { LegalNav } from "../../components/LegalNav";
import * as React from "react";
import { Helmet } from "react-helmet";

export default class CookieTableView extends React.Component {
  componentDidMount(): void {
    function loadDeclaration(): void {
      const declaration = document.querySelector(".ch2-settings-declaration");
      if (declaration) {
        const target = document.querySelector(".cookiehub-declaration-target");
        target.removeChild(target.firstElementChild);
        target.appendChild(declaration.cloneNode(true));
      } else {
        setTimeout(loadDeclaration, 200);
      }
    }

    loadDeclaration();
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <main id="main" className="marketing-content container-fluid">
          <Helmet
            bodyAttributes={{
              class: "body-shorthand body-marketing body-marketing-secondary body-light body-marketing-legal hide-elevio",
            }}
          >
            <title>Cookie Table | Shorthand</title>
            <meta name="description" content="Shorthand terms of service." />
          </Helmet>
          <div className="row section-title title-light">
            <div className="col-12 col-sm-10 col-lg-8 col-xxl-6 mx-auto">
              <h1>
                <span className="shorthand-logo dark" aria-hidden="true">
                  Shorthand
                </span>{" "}
                Cookie Table
              </h1>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="d-none d-lg-block col-lg-2">
              <LegalNav />
            </div>
            <div className="col-12 col-sm-10 col-lg-8 col-xxl-6">
              <section>
                <div className="cookiehub-declaration-target">
                  <div className="shorthand-loader shorthand-loader-inline">
                    <div className="shorthand-loader-inner">
                      <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw" />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <Footer />
        </main>
      </Layout>
    );
  }
}
