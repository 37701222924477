import { Byline } from "./ByLine/ByLine";
import { ColorBar } from "./ColorBar/ColorBar";
import { Features } from "./Features/Features";
import { FormLink } from "./FormLink/FormLink";
import * as styles from "./PricingPlan.module.scss";
import { Title } from "./Title/Title";
import * as React from "react";
import { IPlan } from "src/lib/src/common/plans-old";

let classNames = require("classnames");

export interface IActiveCurrency {
  name: string;
  symbol: string;
  label: string;
}

export interface PricingPlanProps {
  /**
   * all plan details
   */
  plan: IPlan;
  /**
   * all the tooltips for a plan
   */
  tooltips?: string[];
  /**
   * the billing cycle for this plan
   */
  featuresHeadline: string;
  /**
   * text to go in the cta button
   */
  ctaText: string;
  /**
   * includes a contact form link
   */
  formLink?: { text: string; ctaFunction: () => void };
  /**
   * the current plan the user is on
   */
  isCurrentPlan?: boolean;
  /**
   * used style a plan if it is featured
   */
  isFeaturePlan?: boolean;
  /**
   * the function the Cta button calls
   */
  ctaFunction?: (planId: string) => void;
  children?: React.ReactNode;
}

export const PricingPlan = ({
  plan,
  featuresHeadline,
  ctaText,
  formLink,
  isCurrentPlan,
  isFeaturePlan,
  tooltips,
  ctaFunction,
  children,
}: PricingPlanProps): JSX.Element => {
  const { name, marketing } = plan;

  const theme = isFeaturePlan ? "dark" : "light";

  function handleCtaClicked() {
    ctaFunction(plan.id);
  }

  let planThemeStyles = styles.planLight;
  if (theme === "dark") {
    planThemeStyles = styles.planDark;
  }

  return (
    <div className={classNames(styles.plan, planThemeStyles)}>
      <Title title={name} theme={theme} />
      <ColorBar id={plan.id} />
      <Byline text={marketing.label} theme={theme} />
      {children}
      <button
        onClick={handleCtaClicked}
        data-testid="plan-cta"
        className={classNames(
          { [styles.btnWhite]: isFeaturePlan },
          { [styles.btnPrimary]: !isCurrentPlan },
          { [styles.btnSecondary]: isCurrentPlan }
        )}
      >
        {ctaText}
      </button>
      <Features title={featuresHeadline} features={marketing.benefits} theme={theme} tooltips={tooltips} />
      {formLink && <FormLink formLink={formLink} />}
    </div>
  );
};
