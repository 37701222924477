import { Layout } from "../components/Layout";
import * as React from "react";
import { Helmet } from "react-helmet";

export default class Styleguide extends React.Component<any, any> {
  render() {
    return (
      <Layout location={this.props.location}>
        <div className="container-fluid">
          <Helmet
            bodyAttributes={{
              class: "body-shorthand body-marketing body-marketing-secondary body-styleguide hide-elevio hide-gdpr",
            }}
          >
            <title>Shorthand Styleguide</title>
            <meta
              name="description"
              content="Shorthand is beautifully simple, but packed with an amazingly rich set of features. This page gives a complete listing of everything you'll find in Shorthand."
            />
            <meta name="robots" content="noindex" />
          </Helmet>

          <div className="row section-title title-light title-small">
            <div className="col-11 col-sm-10 col-lg-8 mx-auto">
              <h1>
                <span className="shorthand-logo dark">Shorthand</span> Styleguide
              </h1>
              <h2>
                Shorthand is a <em>simple</em> to use platform aimed at <em>professional</em> storytellers.
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="main col-11 col-sm-10 mx-auto">
              <div id="nav-anchor" />
              <div className="row">
                <nav className="d-none d-lg-block col-0 col-lg-2 left">
                  <ul>
                    <li>
                      <a href="#colours">Colours</a>
                    </li>
                    <li>
                      <a href="#typography">Typography</a>
                    </li>
                    <li>
                      <a href="#buttons">Buttons</a>
                    </li>
                    <li>
                      <a href="#icons">Icons</a>
                    </li>
                    <li>
                      <a href="#logo">Logo</a>
                    </li>
                    <li>
                      <a href="#imagery">Imagery</a>
                    </li>
                    <li>
                      <a href="https://inside.shorthand.com/craft-style-guide/index.html" target="_blank" rel="noopener noreferrer">
                        Language &amp; tone <i className="fa fa-external-link-alt" aria-hidden="true" />
                      </a>
                    </li>
                  </ul>
                </nav>
                <div className="col-12 col-lg-10 right">
                  <section id="guide-intro" className="section guide-intro">
                    <h2>Simple &amp; professional</h2>
                    <p className="sub-heading">
                      Shorthand makes it <em>very easy</em> to create beautiful, immersive stories. That is our core value. You don't
                      need to be a designer or developer, just someone with stories to tell.
                    </p>

                    <p className="sub-heading">
                      Shorthand stories are trusted by some of the world's best professional storytellers as a <em>proven solution</em>{" "}
                      to increase engagement, audience loyalty, and click-through rate.
                    </p>
                  </section>
                  <section id="colours" className="section colours">
                    <h2>Colours</h2>
                    <p className="sub-heading">
                      Shorthand has a very focussed colour palette. At its most simple it is teal &amp; dark grey. Other colours or
                      tones are used sparingly where needed.
                    </p>
                    <h3>Primary colour</h3>
                    <div className="flex">
                      <div className="column colour bordered primary">
                        <div className="swatch half">
                          <span className="colour-light" />
                        </div>
                        <div className="description">
                          <h6>Teal</h6>
                          <p>Main brand colour</p>
                          <code className="variable">
                            <pre>Variable</pre>
                            $shorthand-teal
                          </code>
                          <code className="hex">
                            <pre>Value</pre>
                            #12bf9c
                          </code>
                        </div>
                      </div>
                    </div>

                    <h3>Secondary colours</h3>
                    <div className="flex">
                      <div className="column colour bordered blue">
                        <div className="swatch half">
                          <span className="colour-light" />
                        </div>
                        <div className="description">
                          <h6>Blue</h6>
                          <p>Use sparingly</p>
                          <code className="variable">
                            <pre>Variable</pre>
                            $shorthand-blue
                          </code>
                          <code className="hex">
                            <pre>Value</pre>
                            #00a7cf
                          </code>
                        </div>
                      </div>
                      <div className="column colour bordered orange">
                        <div className="swatch half">
                          <span className="colour-light" />
                        </div>
                        <div className="description">
                          <h6>Orange</h6>
                          <p>Use sparingly</p>
                          <code className="variable">
                            <pre>Variable</pre>
                            $shorthand-orange
                          </code>
                          <code className="hex">
                            <pre>Value</pre>
                            #f2744d
                          </code>
                        </div>
                      </div>
                      <div className="column colour bordered gold">
                        <div className="swatch half">
                          <span className="colour-light" />
                        </div>
                        <div className="description">
                          <h6>Gold</h6>
                          <p>Use sparingly</p>
                          <code className="variable">
                            <pre>Variable</pre>
                            $shorthand-gold
                          </code>
                          <code className="hex">
                            <pre>Value</pre>
                            #ccb87e
                          </code>
                        </div>
                      </div>
                    </div>
                    <h3>Alert/notification colours</h3>
                    <div className="flex">
                      <div className="column colour bordered red">
                        <div className="swatch half">
                          <span className="colour-light" />
                        </div>
                        <div className="description">
                          <h6>Red</h6>
                          <p>Used for error states (in app)</p>
                          <code className="variable">
                            <pre>Variable</pre>
                            $shorthand-red
                          </code>
                          <code className="hex">
                            <pre>Value</pre>
                            #ec3f3f
                          </code>
                        </div>
                      </div>
                      <div className="column colour bordered green">
                        <div className="swatch half">
                          <span className="colour-light" />
                        </div>
                        <div className="description">
                          <h6>Green</h6>
                          <p>Used for success states (in app)</p>
                          <code className="variable">
                            <pre>Variable</pre>
                            $shorthand-green
                          </code>
                          <code className="hex">
                            <pre>Value</pre>
                            #8ac064
                          </code>
                        </div>
                      </div>
                    </div>
                    <h3>Tones</h3>
                    <div className="flex">
                      <div className="column colour bordered gray-darkest">
                        <div className="swatch" />
                        <div className="description">
                          <h6>Darkest grey / Text colour</h6>
                          <p>Primary text and menu colour across app. Always use instead of black.</p>
                          <code className="variable">
                            <pre>Variable</pre>
                            $gray-darkest
                          </code>
                          <code className="hex">
                            <pre>Value</pre>
                            #1c1c1c
                          </code>
                        </div>
                      </div>
                      <div className="column colour bordered gray-darker">
                        <div className="swatch" />
                        <div className="description">
                          <h6>Darker grey</h6>
                          <p>Secondary text colour across app. Use for captions and other secondary text functions.</p>
                          <code className="variable">
                            <pre>Variable</pre>
                            $gray-darker
                          </code>
                          <code className="hex">
                            <pre>Value</pre>
                            #292828
                          </code>
                        </div>
                      </div>
                      <div className="column colour bordered gray-dark">
                        <div className="swatch" />
                        <div className="description">
                          <h6>Dark grey</h6>
                          <p>Secondary text colour across app. Use for captions and other secondary text functions.</p>
                          <code className="variable">
                            <pre>Variable</pre>
                            $gray-dark
                          </code>
                          <code className="hex">
                            <pre>Value</pre>
                            #424242
                          </code>
                        </div>
                      </div>
                      <div className="column colour bordered gray">
                        <div className="swatch" />
                        <div className="description">
                          <h6>Mid grey</h6>
                          <p>Secondary text colour across app. Use for captions and other secondary text functions.</p>
                          <code className="variable">
                            <pre>Variable</pre>
                            $gray
                          </code>
                          <code className="hex">
                            <pre>Value</pre>
                            #777
                          </code>
                        </div>
                      </div>
                      <div className="column colour bordered gray-light">
                        <div className="swatch" />
                        <div className="description">
                          <h6>Light grey</h6>
                          <p>Use for placeholder text and other instances where actual text is less important</p>
                          <code className="variable">
                            <pre>Variable</pre>
                            $gray-light
                          </code>
                          <code className="hex">
                            <pre>Value</pre>
                            #aaa
                          </code>
                        </div>
                      </div>
                      <div className="column colour bordered gray-lighter">
                        <div className="swatch" />
                        <div className="description">
                          <h6>Lighter grey</h6>
                          <p>Use for placeholder text and other instances where actual text is less important</p>
                          <code className="variable">
                            <pre>Variable</pre>
                            $gray-lighter
                          </code>
                          <code className="hex">
                            <pre>Value</pre>
                            #eee
                          </code>
                        </div>
                      </div>
                      <div className="column colour bordered gray-lightest">
                        <div className="swatch" />
                        <div className="description">
                          <h6>Lightest grey / Background colour</h6>
                          <p>Used instead of white for app backgrounds</p>
                          <code className="variable">
                            <pre>Variable</pre>
                            $gray-lightest
                          </code>
                          <code className="hex">
                            <pre>Value</pre>
                            #f9f9f9
                          </code>
                        </div>
                      </div>
                      <div className="column colour bordered white">
                        <div className="swatch" />
                        <div className="description">
                          <h6>White</h6>
                          <p>Used for modals, form fields &amp; other areas where maximum text contrast is required</p>
                          <code className="hex">
                            <pre>Value</pre>
                            #fff
                          </code>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section id="typography" className="section typography">
                    <h2>Typography</h2>
                    <p className="sub-heading garamond">
                      Only two typefaces are used &mdash; Proxima Nova and Adobe Garamond Pro.{" "}
                      <em>Both of these are imported via Typekit.</em>
                    </p>

                    <h3>Some basic rules to follow:</h3>
                    <ol>
                      <li>
                        Generally a heading should be in Adobe Garamond regular. <em>Never use Adobe Garamond in bold</em>. Save
                        Proxima for paragraphs, small headings, buttons and other smaller text elements
                      </li>
                      <li>
                        Never use a drop-shadow on text unless there is no alternative. If you absolutely have to, it should be
                        EXTREMELY subtle.
                      </li>
                    </ol>

                    <div className="flex">
                      <div className="column typeface bordered garamond">
                        <h3>Adobe Garamond Pro</h3>
                        <p>
                          Adobe Garamond Pro is an elegant serif typeface that renders cleanly and with a general lightness of touch.{" "}
                          <em>It looks great in italics!</em>
                        </p>
                        <p>
                          It is primary suited for large headlines and for body text, and is therefore used more regularly on the
                          marketing site. In the app it is rarely used.
                        </p>
                        <p>
                          <em>
                            It should never be used in any weight other than regular as <strong>the bold variant is fugly</strong>!
                          </em>
                        </p>
                      </div>
                      <div className="column typeface bordered tw-font-CircularXXSub">
                        <h3>Proxima Nova</h3>
                        <p>
                          <strong>Proxima Nova is a modern sans-serif typeface with clearly defined letter forms.</strong>
                        </p>
                        <p>This makes it great for labels and subheadings, and is often used in all-caps.</p>
                        <p>
                          <em>
                            It can be used in regular and <strong>bold</strong>.
                          </em>
                        </p>
                      </div>
                    </div>
                  </section>

                  <section id="buttons" className="section buttons">
                    <h2>Buttons &amp; links</h2>
                    <p className="sub-heading garamond">Buttons are used wherever a clear call to action is needed.</p>

                    <h3>Button types</h3>
                    <p className="styleguide-rules">
                      In most cases the teal button size is used for primary, positive actions. For actions that are neutral or that
                      result in no change (Cancel, Revert etc) the 'Neutral action' button should be used. If an action results in
                      something being deleted or removed then use a red button, and if the scenario is an explicitly yes/no one then a
                      green button can be use in place of the standard teal one.
                    </p>
                    <div className="flex examples ">
                      <button className="btn btn-primary">Main, positive action</button>
                      <button className="btn btn-secondary">Neutral action</button>
                      <button className="btn btn-red">Destructive action</button>
                      <button className="btn btn-green">Yes</button>
                      <button className="btn btn-primary" disabled>
                        Inactive state
                      </button>
                      <button className="btn btn-primary" disabled>
                        <i className="fa fa-circle-o-notch fa-spin fa-fw" /> Processing...
                      </button>
                      <button className="btn btn-blue">Rarely used</button>
                      <button className="btn btn-orange">Rarely used</button>
                      <button className="btn btn-gold">Rarely used</button>
                    </div>
                    <h3>Dark backgrounds</h3>
                    <p className="styleguide-rules">
                      Some places in the app have a dark background, or potentially a photographic one. Most buttons can be used as per
                      the normal however the 'Neutral action' one is different:
                    </p>
                    <div className="flex examples inverse ">
                      <button className="btn btn-primary">Main, positive action</button>
                      <button className="btn btn-secondary btn-inverse">Neutral action</button>
                      <button className="btn btn-red">Destructive action</button>
                      <button className="btn btn-green">Yes</button>
                      <button className="btn btn-primary" disabled>
                        Inactive state
                      </button>
                      <button className="btn btn-primary" disabled>
                        <i className="fa fa-circle-o-notch fa-spin fa-fw" /> Processing...
                      </button>
                      <button className="btn btn-blue">Rarely used</button>
                      <button className="btn btn-orange">Rarely used</button>
                      <button className="btn btn-gold">Rarely used</button>
                    </div>
                    <h3>Button sizes</h3>
                    <p className="styleguide-rules">
                      In most cases the standard button size is used, however if the need dictates it there are a couple of
                      alternatives
                    </p>
                    <div className="flex examples ">
                      <button className="btn btn-lg btn-primary">This is a large button (rarely used)</button>
                      <button className="btn btn-primary">This is a standard button</button>
                      <button className="btn btn-sm btn-primary">This is a small button</button>
                      <button className="btn btn-xs btn-primary">This is an extra small button (rarely used)</button>
                    </div>
                  </section>

                  <section id="logo" className="section logo">
                    <h2>Logo</h2>
                    <p className="sub-heading garamond">
                      We have two main logo variations - stacked and horizontal. The stacked version is most commonly used, with the
                      horizontal one used only when there is not sufficient space for the stacked one.
                    </p>
                    <p className="sub-heading garamond">
                      <em>The logo should never be used for marketing purposes as just the S unless the available space is tiny.</em>
                    </p>
                    <div className="flex examples ">
                      <img alt="" src="/media/logos/logo-black.svg" />
                      <img alt="" src="/media/logos/logo-black-horiz.svg" />
                    </div>
                    <div className="flex examples inverse">
                      <img alt="" src="/media/logos/logo-white.svg" />
                      <img alt="" src="/media/logos/logo-white-horiz.svg" />
                    </div>
                    <h3>Try to avoid using the logo on any brand colours other than white or dark grey, for example:</h3>
                    <div className="flex examples bad-examples">
                      <div className="primary">
                        <img alt="" src="/media/logos/logo-white.svg" />
                      </div>
                      <div className="blue">
                        <img alt="" src="/media/logos/logo-white.svg" />
                      </div>
                      <div className="orange">
                        <img alt="" src="/media/logos/logo-white.svg" />
                      </div>
                      <div className="gold">
                        <img alt="" src="/media/logos/logo-white.svg" />
                      </div>
                    </div>
                    <p className="sub-heading garamond">
                      The logo <em>can be used</em> on photographic backgrounds &mdash; see the{" "}
                      <a href="#imagery">examples below&hellip;</a>
                    </p>
                  </section>

                  <section id="icons" className="section icons">
                    <h2>Icons</h2>
                    <p className="sub-heading garamond">
                      We primarily use <a href="https://www.fontawesome.io/icons/">Fontawesome</a> icons, with some custom ones (in a
                      similar style) where needed.
                    </p>
                    <div className="flex examples ">
                      <i className="fa fa-exclamation-triangle" aria-hidden="true" />
                      <i className="fa fa-pencil" aria-hidden="true" />
                      <i className="fa fa-paint-brush" aria-hidden="true" />
                      <i className="fa fa-ambulance" aria-hidden="true" />
                      <i className="fa fa-cog" aria-hidden="true" />
                      <i className="fa fa-archive" aria-hidden="true" />
                      <i className="fa fa-star" aria-hidden="true" />
                      <i className="fa fa-shield" aria-hidden="true" />
                      <i className="fa fa-trash" aria-hidden="true" />
                    </div>
                    <h3>The order of preference when considering whether to use an icon is:</h3>
                    <ol>
                      <li>An icon PLUS text is the clearest option for users.</li>
                      <li>Text is preferable to just an icon.</li>
                      <li>
                        An icon may be used if there is no alternative, or if it is something that's function is universally understood
                        (such as a trash can for delete). Ideally a tooltip should accompany the icon on hover.
                      </li>
                    </ol>
                  </section>

                  <section id="imagery" className="section imagery">
                    <h2>Imagery</h2>
                    <p className="sub-heading garamond">
                      Unless the image is an example of a Shorthand story or other screengrab, the style of photography is primarily
                      chosen for its use behind text. It should have a muted colour grading.
                    </p>
                    <p className="sub-heading garamond">
                      In some cases you may need to blur the image and/or add a slight overlay to provide enough contrast for the text
                      to be readable. As mentioned above, never use a dropshadow unless there is absolutely no alternative.
                    </p>
                    <div className="flex examples ">
                      <div className="example">
                        <div className="example-inner">
                          <img alt="" className="example-logo" src="/media/logos/logo-white.svg" />
                          <h5>This is an example of some text</h5>
                          <button className="btn btn-primary">Click here</button>
                        </div>
                        <img alt="" className="example-img" src="/media/pricing-bg.jpg" />
                      </div>
                      <div className="example">
                        <div className="example-inner">
                          <img alt="" className="example-logo" src="/media/logos/logo-white.svg" />
                          <h5>This is an example of some text</h5>
                          <button className="btn btn-primary">Click here</button>
                        </div>
                        <img alt="" className="example-img" src="/media/thankyou/thankyou-bg.jpg" />
                      </div>
                      <div className="example">
                        <div className="example-inner">
                          <img alt="" className="example-logo" src="/media/logos/logo-white.svg" />
                          <h5>This is an example of some text</h5>
                          <button className="btn btn-primary">Click here</button>
                        </div>
                        <img alt="" className="example-img" src="/media/support/training-bg.jpg" />
                      </div>
                      <div className="example">
                        <div className="example-inner">
                          <img alt="" className="example-logo" src="/media/logos/logo-white.svg" />
                          <h5>This is an example of some text</h5>
                          <button className="btn btn-primary">Click here</button>
                        </div>
                        <img alt="" className="example-img" src="/media/welcome-bg.jpg" />
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
