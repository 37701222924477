// extracted by mini-css-extract-plugin
export var AccordionChevron = "FAQ-module--AccordionChevron--5e15a";
export var AccordionContent = "FAQ-module--AccordionContent--0e149";
export var AccordionContentText = "FAQ-module--AccordionContentText--81dcf";
export var AccordionHeader = "FAQ-module--AccordionHeader--35816";
export var AccordionItem = "FAQ-module--AccordionItem--9e15f";
export var AccordionRoot = "FAQ-module--AccordionRoot--8ae7a";
export var AccordionTrigger = "FAQ-module--AccordionTrigger--c025c";
export var contentDark = "FAQ-module--contentDark--1732c";
export var contentLight = "FAQ-module--contentLight--a0b1e";
export var darkChevron = "FAQ-module--darkChevron--e8da2";
export var itemDark = "FAQ-module--itemDark--a100c";
export var itemLight = "FAQ-module--itemLight--18e09";
export var lightChevron = "FAQ-module--lightChevron--5feff";
export var slideDown = "FAQ-module--slideDown--a745c";
export var slideUp = "FAQ-module--slideUp--6c059";
export var triggerDark = "FAQ-module--triggerDark--4684f";
export var triggerLight = "FAQ-module--triggerLight--4f0e7";
export var wrapper = "FAQ-module--wrapper--35dc9";