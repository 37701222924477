export { starter } from "./Starter";
export { business } from "./Business";
export { team } from "./Team";
export { enterprise } from "./Enterprise";

//26-09-2024 THIS IS COPIED DIRECTLY FORM DYLAN AND IS NOT THE SOURCE OF TRUTH
export interface IFeatures {
  "page-settings-password-protect-previews": boolean;
  "story-envelope-no-metadata": boolean;
  "page-settings-always-show-publish-url": boolean;
  "story-amp": boolean;
  "media-cyclops": boolean;
  "organisation-is-bbc": boolean;
  "organisation-secure-previews": boolean;
  "page-settings-custom-colors": boolean;
  "publish-configurations-scorm": boolean;
  "akamai-netstorage-hosting-enabled": boolean;
  "publish-shadow-embed": boolean;
  "early-access-program": boolean;
  "custom-hosting-limit": number;
  "developer-tools": boolean;
  "hide-preview-feedback-footer": boolean;
  "iframely-external-cmp-control": boolean;
  "iframely-data-src": boolean;
  "internal-access-program": boolean;
  "organisation-collaboration": boolean;
  "organisation-manage-publish-configurations": boolean;
  "organisation-member-limit": number;
  "organisation-team-leader-view": boolean;
  "organisation-team-limit": number;
  "organisation-team-member-limit": number;
  "page-settings-amp-enabled": boolean;
  "page-settings-analytics-ga": boolean;
  "page-settings-analytics-gtm": boolean;
  "page-settings-custom-head": boolean;
  "page-settings-html-assets": boolean;
  "page-settings-override-social-cover-url": boolean;
  prime3: boolean;
  "preview-banner": boolean;
  "publish-configurations-download": boolean;
  "publish-configurations-pull": boolean;
  "publish-embed-ui": boolean;
  "s3-hosting-enabled": boolean;
  "sftp-hosting-enabled": boolean;
  "shorthand-analytics-tracking": boolean;
  "shorthand-hosting-custom-domains-enabled": boolean;
  "shorthand-hosting-enabled": boolean;
  "story-credits": number;
  "story-credits-required": boolean;
  "story-ga": boolean;
  "story-hide-footer": boolean;
  "story-promotional-footer": boolean;
  "story-publish-json": boolean;
  "story-publish-segments": boolean;
  "story-section-limit": number;
  "template-background-scrollmation": boolean;
  "template-collection": boolean;
  "template-grid": boolean;
  "template-media": boolean;
  "template-reveal": boolean;
  "template-scrollmation": boolean;
  "template-text": boolean;
  "template-text-over-media": boolean;
  "template-title": boolean;
  "themes-basic": boolean;
  "themes-demo": boolean;
  "theme-builder": boolean;
  "organisation-sites": boolean;
  "story-comments": boolean;
  "shorthand-secure-hosting-enabled": boolean;
}

export interface ITier {
  quantity: number;
  price: { USD: number; AUD: number; GBP: number; EUR: number };
}

export interface ICurrencyPrice {
  monthly: number | ITier[];
  yearly: number | ITier[];
}

type Price = { USD: number; AUD: number; GBP: number; EUR: number };

interface ItemPriceStructure {
  monthly: Price;
  yearly: Price;
  nfp?: Price;
}

export interface IPriceStructure {
  planPrice: ItemPriceStructure;
  userAddonPrice?: ItemPriceStructure;
  publishAddonPrice?: ItemPriceStructure;
}

export interface IMarketing {
  label: string;
  className: string;
  benefits: string[];
  prices?: IPriceStructure;
}

export interface IPlan {
  id: string;
  name: string;
  description: string;
  chargebee?: Set<string>;
  features: Partial<IFeatures>;
  marketing?: IMarketing;
}
