import { CaseStudy } from "../CaseStudy";
import { Link } from "gatsby";
import * as React from "react";

interface IRelatedCaseStudy {
  id: string;
  image: string;
  summary: string;
}

interface IRelatedCaseStudiesProps {
  studies: IRelatedCaseStudy[];
}

export const RelatedCaseStudies: React.SFC<IRelatedCaseStudiesProps> = ({ studies }) => {
  const randomItems = sampleSize<IRelatedCaseStudy>(studies, 3);

  return (
    <div className="row showcase text-center ">
      <div className="col-12 col-lg-10 col-xl-8 mx-auto">
        <h2>More case studies</h2>
        <ul className="flex case-studies-more">
          {randomItems.map(item => (
            <CaseStudy key={item.node.jsonId} item={item.node} />
          ))}
        </ul>
        <Link className="btn btn-primary" to="/case-studies">
          View all case studies
        </Link>
      </div>
    </div>
  );
};

/**
 * Pick <count> random items from <list>
 * @param list
 * @param count
 */
function sampleSize<T>(list: T[], count: number): T[] {
  if (list.length <= count) return list;

  // Make a copy so we can non-destructively remove elements as
  // we pick them
  list = [...list];
  const result: T[] = [];
  while (count-- > 0) {
    const index = Math.floor(Math.random() * list.length);
    result.push(list.splice(index, index + 1)[0]);
  }

  return result;
}
