import * as accordionStyles from "../../FAQ/FAQ.module.scss";
import * as toolTipStyles from "../FeaturesMatrix/LabelCell.module.scss";
import * as styles from "./FeaturesTable.module.scss";
import * as Tooltip from "@radix-ui/react-tooltip";
import { ColumnDef, flexRender, getCoreRowModel, getExpandedRowModel, useReactTable } from "@tanstack/react-table";
import { motion } from "framer-motion";
import * as React from "react";
import { ChevronDown } from "react-feather";
import { ChevronUp } from "react-feather";

type TableProps<T> = {
  columns: ColumnDef<T>[];
  tableData: T[];
  columnVisibility?: Record<string, boolean>;
  expand: boolean;
};
export const FeaturesTable = <T,>(props: TableProps<T>): JSX.Element => {
  const [expanded, setExpanded] = React.useState(false);
  const { columns, tableData: data, columnVisibility } = props;
  const duration = 0.3;

  React.useEffect(() => {
    setExpanded(props.expand);
  }, [props.expand]);

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    state: {
      columnVisibility,
    },
  });
  const headerGroups = table.getHeaderGroups();

  const labelButtonClasses =
    "tw-mt-7 tw-mb-7 tw-ml-4 tw-flex tw-items-center focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-brand_scale-500 tw-font-PassengerSerif tw-text-lg tw-font-bold tw-italic tw-text-grey_scale-300 hover:tw-text-brand_scale-500";

  const openButtonClasses =
    "tw-mt-2 tw-rounded-lg tw-p-4 hover:tw-bg-grey_scale-800 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-brand_scale-500";
  const memoHeaders = React.useMemo(() => {
    return (
      <thead>
        {headerGroups.map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers
              .map((header, index) => (
                <th
                  key={header.id}
                  style={{
                    visibility: expanded ? "visible" : index > 0 ? "hidden" : "visible",
                    borderBottom: expanded ? "none" : "1px solid grey",
                  }}
                >
                  {index === 0 && (
                    <div className="tw-flex tw-items-center tw-justify-between">
                      <Tooltip.Provider>
                        <Tooltip.Root>
                          <Tooltip.Trigger asChild>
                            <button onClick={() => setExpanded(!expanded)} className={labelButtonClasses}>
                              {!header.isPlaceholder && flexRender(header.column.columnDef.header, header.getContext())}
                              {expanded && <ChevronUp size={25} />}
                            </button>
                          </Tooltip.Trigger>
                          <Tooltip.Content side="bottom" sideOffset={0} className={toolTipStyles.TooltipContent}>
                            {expanded ? "Close features" : "Open features"}
                            <Tooltip.Arrow className={toolTipStyles.TooltipArrow} />
                          </Tooltip.Content>
                        </Tooltip.Root>
                      </Tooltip.Provider>
                      {!expanded && (
                        <button className={openButtonClasses} onClick={() => setExpanded(!expanded)}>
                          <ChevronDown size={20} />
                        </button>
                      )}
                    </div>
                  )}
                  {index > 0 && <>{!header.isPlaceholder && flexRender(header.column.columnDef.header, header.getContext())} </>}
                </th>
              ))
              .slice(0, expanded ? headerGroup.headers.length : 1)}
          </tr>
        ))}
      </thead>
    );
  }, [expanded, columnVisibility]);

  return (
    <div>
      <table className={styles.featuresTable}>
        {memoHeaders}
        {expanded && (
          <>
            <tbody className="tw-animate-fade-in">
              {table.getRowModel().rows.map((row, idx) => (
                <motion.tr initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration }} key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id} style={{ backgroundColor: idx % 2 === 0 ? "#232222" : "none" }}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </motion.tr>
              ))}
            </tbody>
            <tfoot>
              {table.getFooterGroups().map(footerGroup => (
                <tr key={footerGroup.id}>
                  {footerGroup.headers.map(header => (
                    <th key={header.id} style={{ width: "15%" }}>
                      {!header.isPlaceholder && flexRender(header.column.columnDef.footer, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </tfoot>
          </>
        )}
      </table>
    </div>
  );
};
