import { filterListByCurrentIndustryCode } from "../../Utils";
import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { RelatedCaseStudies } from "../../components/RelatedCaseStudies";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default function CaseStudy({ data, location }) {
  return (
    <Layout location={location}>
      <main id="main" className="marketing-content container-fluid">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-secondary body-marketing-case-studies hide-elevio",
          }}
        />
        <div className="row section-title">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6 mx-auto  section-inner">
            <h1>
              <span className="shorthand-logo">Shorthand</span>
              <span className="subsection-label">Case Study:</span>
              Leeds Beckett University
            </h1>
            <h2>How Leeds Beckett University use Shorthand to find new ways to showcase research &amp; reports</h2>
          </div>
          <div aria-hidden="true" className="background-media">
            <img
              src="/media/case-studies/case-studies-leeds-beckett-title.jpg"
              data-object-fit="cover"
              data-autoscale-content-ratio="1.77777777778"
              alt=""
            />
          </div>
        </div>
        <div className="row section">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto marketing-main-column">
            <h3 className="intro">
              Back in 2014 Leeds Beckett University set out with a new digital marketing strategy, to put storytelling front and centre
              in how they shared news on activities, research and reports.
            </h3>

            <div className="inline-image featured">
              <img
                src="/media/case-studies/case-studies-leeds-beckett.jpg"
                alt="Deconstructing Yorkshire, by Leeds Beckett University, renders responsively across all devices"
              />
            </div>

            <p>
              Educational and research organisations often have a fantastic resource of high-quality data, but like many face the
              challenge of how to turn that into a rich online narrative, with often limited development resource for special projects.
            </p>

            <p>
              So the timing was spot on when, armed with their new digital marketing strategy, and the impending deadline for their
              annual review for the academic year, Leeds Beckett discovered Shorthand.
            </p>

            <div className="photo-grid flex featured">
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-leeds-beckett-sml-01.jpg"
                alt="A section from one of Leeds Beckett University's stories"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-leeds-beckett-sml-02.jpg"
                alt="A section from one of Leeds Beckett University's stories"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-leeds-beckett-sml-04.jpg"
                alt="A section from one of Leeds Beckett University's stories"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-leeds-beckett-sml-03.jpg"
                alt="A section from one of Leeds Beckett University's stories"
              />
            </div>
            <div className="caption">A selection of sections from Leeds Beckett University's stories</div>

            <p>
              The team used compelling autoplay video to invite the reader in, and used clear visual markers to offer an
              easy-to-navigate structure.
            </p>

            <p>
              And unlike the PDFs of previous years, this time they were able to gather more data on how people engaged with the
              review. And the numbers didn't disappoint.
            </p>

            <p>
              As well as it being “really positively received” internally, the team recorded a “vastly improved” open rate, and also
              found it was being re-shared significantly more. Dwell time on the page itself was also strong.
            </p>

            <p>
              Since then Leeds Beckett has branched out in its use of Shorthand to transform its online marketing, from diving into
              areas of research to offering a visually-rich way to outline the Student Charter .
            </p>

            <figure className="block quote-block offset left">
              <blockquote>
                <p>
                  “With much of our news content being accessed online, we can no longer just rely on the traditional press release
                  format. Using Shorthand enables us to showcase stories in a more stimulating way.”
                </p>
              </blockquote>
              <figcaption className="quote-cite">Mark Dorey</figcaption>
            </figure>

            <p>
              Mark Dorey, external relations manager at Leeds Beckett, said the ease of use to create these experiences has not only
              inspired new stories to tell, but has also changed the way they would tell stories otherwise.
            </p>

            <p>
              Their recent ‘Research Selfie’ campaign, for example, was a story that would previously have been published via their
              standard web template. But using Shorthand made it easier to bring together elements from across the web.
            </p>

            <p>
              “The simplicity of [Shorthand] and ease of embedding content and adding text around it, meant that it actually was a
              better solution, and easier solution for us. And visually it looked better than it would have looked in our CMS.
            </p>

            <p>
              “With much of our news content being accessed online, we can no longer just rely on the traditional press release format.
              Using Shorthand enables us to showcase stories in a more stimulating way.”
            </p>

            <figure className="block quote-block offset right">
              <blockquote>
                <p>
                  “Having something that’s visually arresting to start with is a good hook to get people into something they might not
                  have an immediate interest in.”
                </p>
              </blockquote>
              <figcaption className="quote-cite">Mark Dorey</figcaption>
            </figure>

            <p>
              They have also experimented with much more image-led narratives, illustrated in their approach in ‘Our year at a glance’,
              where a look back at 2015 was built up over a series of full-screen photos, one per month of the year. The text was short
              &amp; snappy, referencing the achievement and linking out to more detail elsewhere on the Leeds Beckett website.
            </p>

            <p>
              But in particular, Mark is excited about the opportunities the tool offers to tell deeper, and richer stories around
              research, and invite in new audiences.
            </p>

            <p>
              “Having something that’s visually arresting to start with is a good hook to get people into something they might not have
              an immediate interest in.”
            </p>

            <p>And the strategy is to continue experimenting.</p>

            <p>
              “It still feels like we are only dipping our toes in the water of what’s possible using the platform and we are really
              excited to continue to innovate and create even richer and compelling content.”
            </p>
          </div>
        </div>
        <RelatedCaseStudies studies={filterListByCurrentIndustryCode(data, "leeds-beckett")} />
        <Footer />
      </main>
    </Layout>
  );
}

export const query = graphql`
  query leedsQuery {
    allCaseStudiesJson {
      edges {
        node {
          jsonId
          image
          industry
          industryCode
          organisation
          summary
        }
      }
    }
  }
`;
