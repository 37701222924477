import { CaseStudy } from "../components/CaseStudy";
import { Footer } from "../components/Footer";
import { Layout } from "../components/Layout";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

interface ICaseStudyProps {
  data: any;
}

interface ICaseStudyState {
  filter: string;
}

export default class CaseStudiesView extends React.Component<ICaseStudyProps, ICaseStudyState> {
  constructor(props: ICaseStudyProps, context: any) {
    super(props, context);
  }

  render() {
    const { allCaseStudiesJson } = this.props.data;
    const caseStudies = allCaseStudiesJson.edges;
    const items = caseStudies;

    return (
      <Layout location={this.props.location}>
        <main id="main" className="marketing-content container-fluid">
          <Helmet
            bodyAttributes={{
              class: "body-shorthand body-marketing body-marketing-secondary body-light body-marketing-case-studies hide-elevio",
            }}
          />
          <div className="row section-title title-light title-small">
            <div className="col-12 col-sm-8 mx-auto">
              <h1>Case Studies</h1>
              <h2>The world’s top brands, publishers and nonprofits use Shorthand. Find out why.</h2>
            </div>
          </div>

          <div className="row tw-mb-16">
            <ul className="col-11 col-sm-10 flex case-study-grid">
              {items.map(item => (
                <CaseStudy key={item.node.jsonId} item={item.node} />
              ))}
            </ul>
          </div>
          <Footer />
        </main>
      </Layout>
    );
  }
}

export const query = graphql`
  query CaseStudiesQuery {
    allCaseStudiesJson {
      edges {
        node {
          jsonId
          image
          industry
          industryCode
          organisation
          summary
        }
      }
    }
  }
`;
