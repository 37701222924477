import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image";
import * as React from "react";

type BannerProps = Readonly<{
  title: string;
  description: string;
  cta: { text: string; link: string };
  img: {
    alt: string;
    url: IGatsbyImageData;
  };
}>;

export default function Banner({ title = "", description = "", cta = { text: "", link: "" }, img }: BannerProps) {
  return (
    <div className="tw-grid tw-grid-cols-2 tw-items-center tw-gap-x-[10%] tw-text-left max-lg:tw-grid-cols-1">
      <div className="max-lg:tw-text-center">
        <h1 className="tw-h1">{title}</h1>
        <p
          className="tw-h4 tw-mt-5 tw-w-[90%] tw-text-text_black max-lg:tw-mx-auto"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <div className="tw-mt-10">
          <a href={cta.link} className="btn tw-button btn-primary !tw-m-0">
            {cta.text}
          </a>
        </div>
      </div>
      <div className="max-lg:tw-hidden">
        <GatsbyImage image={getImage(img.url)} alt={img.alt} />
      </div>
    </div>
  );
}
