import { StyledLink } from "../Atoms";
import { Card } from "../Organisms";
import { IGatsbyImageData } from "gatsby-plugin-image";
import * as React from "react";

type CardsProps = {
  title: string;
  cards: {
    title: string;
    cta: {
      text: string;
      link: string;
    };
    img: {
      alt: string;
      url: IGatsbyImageData;
    };
  }[];
  cta?: { text: string; link: string };
};
export default function Cards(props: Readonly<CardsProps>) {
  const { title, cards, cta } = props;
  return (
    <>
      <h2
        className="tw-h2 tw-mx-auto tw-w-[65%] tw-text-center
      max-lg:tw-w-full
      "
      >
        {title}
      </h2>
      <div
        className="tw-mt-14 tw-grid tw-grid-cols-3 tw-gap-x-6 tw-gap-y-6
      max-md:tw-grid-cols-2
      max-sm:tw-grid-cols-1
      "
      >
        {cards.map(card => {
          return (
            <div key={card.title} className="list-item-transition tw-overflow-hidden  tw-rounded-[10px]">
              <Card title={card.title} cta={card.cta} img={{ url: card.img.url, alt: card.img.alt }} />
            </div>
          );
        })}
      </div>
      {cta?.text && (
        <div className="tw-mt-14 tw-text-center">
          <StyledLink cta={cta} />
        </div>
      )}
    </>
  );
}
