import { Option } from "../PricingPlans";
import * as styles from "./Select.module.scss";
import * as Select from "@radix-ui/react-select";
import * as React from "react";
import { ChevronDown } from "react-feather";

type SelectProps = {
  options: Option[];
  value: Option;
  ariaLabel: string;
  onValueChange: (value: string) => void;
};
export const RadixSelect = (props: SelectProps) => {
  const { options, onValueChange, value, ariaLabel } = props;

  return (
    <Select.Root onValueChange={onValueChange} defaultValue={value.label || options[0].label}>
      <Select.Trigger
        className="tw-flex tw-w-full tw-items-center tw-justify-between tw-rounded-lg tw-border-[1px] tw-border-solid tw-border-grey_800 tw-p-2"
        aria-label={ariaLabel}
        id={ariaLabel}
      >
        <div className={styles.selectValue}>
          <Select.Value placeholder={options[0]?.label || "USD"} />
        </div>
        <Select.Icon>
          <ChevronDown className={styles.selectIconColor} size={20} />
        </Select.Icon>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content className={styles.selectContent}>
          <Select.Viewport className={styles.selectViewport}>
            <Select.Group>
              {options.map(option => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </Select.Group>
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};

type SelectItemProps = React.ComponentProps<typeof Select.Item> & {
  children: React.ReactNode;
  className?: string;
};

const SelectItem = React.forwardRef<HTMLDivElement, SelectItemProps>(({ children, className, ...props }, forwardedRef) => {
  return (
    <Select.Item className={styles.selectItem} value={props.value} ref={forwardedRef}>
      <Select.ItemText>{children}</Select.ItemText>
    </Select.Item>
  );
});
