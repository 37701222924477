import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { LegalNav } from "../../components/LegalNav";
import { Link } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default class CustomerTermsView extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <main id="main" className="marketing-content container-fluid">
          <Helmet
            bodyAttributes={{
              class: "body-shorthand body-marketing body-marketing-secondary body-light body-marketing-legal hide-elevio",
            }}
          >
            <title>Customer terms of service | Shorthand</title>
            <meta name="description" content="Shorthand terms of service." />
          </Helmet>
          <div className="row section-title title-light">
            <div className="col-12 col-sm-10 col-lg-8 col-xxl-6 mx-auto">
              <h1>
                <span className="shorthand-logo dark" aria-hidden="true">
                  Shorthand
                </span>{" "}
                Customer terms of service
              </h1>
              <p>
                <small className="small-text text-muted">Effective: 26 March 2024</small>
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="d-none d-lg-block col-lg-2">
              <LegalNav />
            </div>
            <div className="col-12 col-sm-10 col-lg-8 col-xxl-6">
              <section>
                <p className="intro">
                  Hi! Thanks so much for choosing Shorthand! These Shorthand Customer Terms of Service{" "}
                  <strong>(Customer Terms)</strong> describe your rights and responsibilities as a customer of our online digital
                  content creation and collaboration platform and all associated services <strong>(Platform)</strong>. If you are being
                  invited or added to the Platform by another person or entity, such as your employer, the{" "}
                  <Link className="btn-link" to="/legal/user-terms">
                    User Terms of Service
                  </Link>{" "}
                  govern your access and use of the Platform, so please read those instead.
                </p>
              </section>
              <section>
                <h2>1. The beginning of a beautiful relationship (Effect of Terms)</h2>
                <ol className="letters">
                  <li>
                    These <strong>Customer Terms</strong>, including Our Policies, constitute a binding contract between you (
                    <strong>Customer</strong> or <strong>you</strong>) and Shorthand Pty Ltd ABN 88 162 625 470 (
                    <strong>Shorthand</strong>, <strong>us</strong> or <strong>we</strong>).
                  </li>
                  <li>
                    If you are agreeing to these Customer Terms on behalf of a business entity, you represent to us that you have legal
                    authority to bind that entity and enter into a contract on its behalf. Note that if you sign up to the Platform
                    using an email address from your employer or other entity then you will be deemed to be acting on behalf of that
                    party. If you are an individual and not acting on behalf of your employer or another entity, then you are deemed to
                    be the Customer.
                  </li>
                  <li>
                    By signing an Order Form, purchasing a subscription, creating an online workspace, clicking a box indicating
                    acceptance, or otherwise accessing or using the Platform as the authorised representative for you or your
                    organisation, you confirm you have read, understood and agree to these Customer Terms in your capacity as a
                    Customer.
                  </li>
                </ol>
              </section>

              <section>
                <h2>2. You, your subscription and your users (Account)</h2>
                <h3>2.1 Customer</h3>
                <p>
                  By accepting these Customer Terms in your capacity as a ‘Customer’ you acknowledge that you have administrative
                  access for your account and are responsible for:
                </p>
                <ol className="letters">
                  <li>setting up a workspace on the Platform for yourself and all Your Users; and</li>
                  <li>
                    modifying and reassigning roles on the workspace, including granting administrative access to certain of Your
                    Users.
                  </li>
                </ol>
                <h3>2.2 Subscription</h3>
                <ol className="letters">
                  <li>
                    A subscription provides you and Your Users, for the subscription term, with access to an agreed configuration of
                    features, functionality and support that we will make available through the Platform. Your Subscription may be
                    procured through the Platform interface, via an Order Form or via email acceptance.
                  </li>
                  <li>
                    Your Subscription has associated fees and payment terms, which will be notified to you at the time of procurement
                    and renewal through the Platform or via email.
                  </li>
                  <li>
                    Upon signing an Order Form, or otherwise subscribing as a Customer on the Platform, you will create an account in
                    order for you and Your Users to access and use the Platform. You must ensure that any information you provide to
                    us, or we request from you as part of the set-up process is complete and accurate. You must advise us within five
                    (5) business days of any changes to this information.
                  </li>
                  <li>These Customer Terms apply to paid, free, and trial use of the Platform.</li>
                </ol>
                <h3>2.3 Your Users</h3>
                <ol className="letters">
                  <li>
                    As part of your Subscription, we may permit the creation of accounts and/or allow Your Users to access and use the
                    Platform, in accordance with the details of your Subscription.
                  </li>
                  <li>
                    You must ensure that each of Your Users is at least 16 years of age and strictly complies with the{" "}
                    <Link className="btn-link" to="/legal/user-terms">
                      User Terms
                    </Link>
                    ,{" "}
                    <Link className="btn-link" to="/legal/acceptable-use-policy">
                      Acceptable Use Policy
                    </Link>{" "}
                    and these Customer Terms as if they were a party to it.
                  </li>
                  <li>
                    Your Users may be your employees, contractors, affiliates, partners, or any other individuals you grant access to
                    your workspace under your Subscription.
                  </li>
                  <li>
                    Your Users’ acts and omissions are taken to be your acts and omissions and you agree to indemnify us for any loss
                    that we may suffer as a result of you or Your Users failing to comply with these Customer Terms.
                  </li>
                </ol>
              </section>

              <section>
                <h2>3. What you need to do (Your obligations)</h2>
                <h3>3.1 Your Data</h3>
                <p>You shall, and shall procure that each of Your Users utilising the Platform:</p>
                <ol className="letters">
                  <li>
                    provide all information and assistance reasonably required by us to allow us to make the Platform available to you;
                  </li>
                  <li>
                    review information, make decisions and, on an ongoing basis, provide us with the information necessary for us to
                    provide the Platform to you;
                  </li>
                  <li>
                    provide us with true and relevant information regarding you, your business and the intended use of the Platform;
                  </li>
                  <li>only use the Platform for the purpose in the manner intended;</li>
                  <li>obtain any consents or permissions required with respect to Your Data; and</li>
                  <li>comply with any applicable Privacy Legislation in respect of Your Data submitted to the Platform.</li>
                </ol>
                <h3>3.2 Restrictions on use</h3>
                <p>You acknowledge and agree that you will: </p>
                <ol className="letters">
                  <li>comply with all applicable laws, regulations and codes of conduct;</li>
                  <li>promptly report any errors, defects or malfunctions in relation to the Platform;</li>
                  <li>
                    not do anything which is intended or reasonably likely to damage, impair, interrupt or interfere with the provision
                    of the Platform;
                  </li>
                  <li>
                    not alter, modify, decompile, disassemble, reverse engineer, sublicense or change any software that underpins the
                    Platform or create a derivative work from the software by any means;
                  </li>
                  <li>not purport to grant to a third party any right to access or use the Platform except as permitted by us; and</li>
                  <li>
                    not remove, obfuscate or obscure any proprietary notices, logos or watermarks contained in the platform or in the
                    content created by you using the Platform.
                  </li>
                </ol>
                <h3>3.3 Monitoring, backup and protection of Your Data</h3>
                <p>You acknowledge that:</p>
                <ol className="letters">
                  <li>
                    unless you have entered into a separate mutually signed reseller agreement with us, you must not resupply the
                    Platform to third parties, or permit third parties to access the Platform, other than Your Users;
                  </li>
                  <li>
                    while we have no obligation to monitor any content uploaded to the Platform, we may (1) remove Your Data from the
                    Platform or (2) suspend your access to the Platform if we deem such action necessary based on your violation of
                    these Customer Terms, or in response to takedown requests that we receive following our guidelines for{" "}
                    <Link className="btn-link" to="/legal/copyright-and-trademark-policy">
                      Reporting Copyright and Trademark Violations
                    </Link>
                    . We will use reasonable efforts to provide you with advance notice of removals and suspensions when practicable,
                    but if we determine that your actions endanger the operation of the Platform or other users, we may suspend your
                    access or remove Your Data immediately without notice. We have no liability to you for removing or deleting Your
                    Data from or suspending your access to the Platform;
                  </li>
                  <li>
                    where you are hosting any part of the Platform, you must back up any of Your Data or any other data as often as is
                    necessary to ensure that it can be recovered following an outage of the Platform, and we are not responsible for
                    performing backups;
                  </li>
                  <li>you must ensure that your computer and telecommunications network are secure;</li>
                  <li>
                    you must ensure all usernames, passwords and other sensitive information associated with the Platform are kept
                    secure from unauthorised use or access at all times; and
                  </li>
                  <li>
                    you must notify us if you become aware that there is unauthorised access to the Platform or disclosure of sensitive
                    information including usernames and passwords to unauthorised persons.
                  </li>
                </ol>

                <h3>3.4 Acknowledgment</h3>
                <p>You acknowledge and agree that:</p>
                <ol className="letters">
                  <li>
                    the Platform is provided online on a subscription basis and that from time to time we may make changes to the
                    Platform and associated documentation in order to deliver an improved experience for all our customers, and that
                    such changes may include discontinuing certain features or functionality;
                  </li>
                  <li>the Platform may contain errors and not all errors or problems can or will be corrected;</li>
                  <li>
                    we are not responsible for the resilience or availability of the communications network over which the Platform is
                    supplied;
                  </li>
                  <li>it is your sole responsibility to determine that the Platform meet the needs of your business; and</li>
                  <li>
                    if you do not have the minimum hardware and communications infrastructure recommended by us, your ability to
                    utilise the Platform may be diminished.
                  </li>
                </ol>
              </section>

              <section>
                <h2>4. What we need to do (Our obligations)</h2>
                <ol className="letters">
                  <li>We agree to licence the Platform to you and Your Users in accordance with these Customer Terms.</li>
                  <li>
                    Whilst providing access to the Platform, we will use every reasonable effort to ensure that the Platform is
                    available for reasonable and normal use at least 95% of the time, calculated on a monthly basis.
                  </li>
                  <li>
                    We will provide you and Your Users with support services in accordance with the details of your Subscription.
                  </li>
                  <li>
                    We agree to implement and maintain physical, technical and administrative security measures designed to protect
                    Your Data from unauthorized access, destruction, use, modification, or disclosure. We also maintain a compliance
                    program that includes independent third-party audits and certifications.
                  </li>
                </ol>
              </section>

              <section>
                <h2>5. Other people’s products (Third Party Software and Services)</h2>
                <ol className="letters">
                  <li>
                    We may enable you to access and use Third Party Software through the integration of Third Party Software with the
                    Platform.
                  </li>
                  <li>
                    You acknowledge that:
                    <ol className="roman">
                      <li>
                        the Platform may incorporate components of Third Party Software but we do not make any representations or
                        provide any warranties whatsoever with respect to the functionality of any such Third Party Software; and
                      </li>
                      <li>
                        you are solely responsible for ensuring that you procure appropriate rights to access and use any such Third
                        Party Software in association with the Platform and comply with the terms and conditions applicable to their
                        use.
                      </li>
                    </ol>
                  </li>
                </ol>
              </section>

              <section>
                <h2>6. Getting all square (Fees and invoicing)</h2>
                <h3>6.1 Subscription Fees</h3>
                <p>
                  The Subscription Fees that you will be billed for will be notified to you via the Platform, the Order Form or via
                  email at the time you sign up to our Platform or at the time you wish to renew your Subscription. You are responsible
                  for reviewing the pricing schedule, features and limits associated with your Subscription.{" "}
                </p>
                <h3>6.2 Taxes</h3>
                <ol className="letters">
                  <li>
                    Your Subscription Fees are stated exclusive of any taxes. If a goods and services tax, value-added tax, sales tax,
                    or any similar tax <strong>(Sales Taxes)</strong> has application to any supply made under or in connection with
                    these Customer Terms, you must pay us the appropriate additional amount on account of such Sales Taxes.
                  </li>
                  <li>
                    <strong>Witholding Taxes.</strong> You will pay all fees net of any applicable withholding taxes. You and we will
                    work together to avoid any withholding tax if exemptions, or a reduced treaty withholding rate, are available. If
                    we qualify for a tax exemption, or a reduced treaty withholding rate, we will provide you with reasonable
                    documentary proof. You will provide us reasonable evidence that you have paid the relevant authority for the sum
                    withheld or deducted.
                  </li>
                </ol>
                <h3>6.3 Payment terms</h3>
                <ol className="letters">
                  <li>
                    Unless you are on a free subscription or a free trial, Subscription Fees must be paid in accordance with the terms
                    and due date specified by your Subscription. In accordance with subclause 2.2(b), such terms and due date will be
                    notified to you through the Platform or via email, or otherwise as notified by us from time to time.
                  </li>
                  <li>
                    Subscription Fees must be paid via a recurring credit/ debit card payment, direct debit facility or such other
                    electronic payment facility or platform as agreed in writing by us.
                  </li>
                  <li>
                    If you fail to make full payment of the Subscription Fees on or before the due date, we shall be entitled to
                    receive interest on overdue payments of 1% per month and shall be entitled to downgrade, withhold or suspend access
                    to the Platform.{" "}
                  </li>
                  <li>
                    We may, without limiting any other rights or remedies we may have, set off any amounts owed to us by you under
                    these Customer Terms against any amounts payable by us to you.
                  </li>
                </ol>
              </section>

              <section>
                <h2>7. Our stuff and your stuff (Intellectual Property Rights)</h2>
                <h3>7.1 Ownership</h3>
                <ol className="letters">
                  <li>
                    The Intellectual Property Rights in the Platform, including all improvements to the Platform, (excluding any Third
                    Party Software and Your Data), are and shall remain our property, or the relevant third party.
                  </li>
                  <li>
                    You must use reasonable endeavours to prevent any infringement of our Intellectual Property Rights in the Platform,
                    and must promptly report to us any such infringement that comes to its attention.
                  </li>
                </ol>
                <h3>7.2 Your Data</h3>
                <p>
                  All Intellectual Property Rights in Your Data remain your property or the property of Your Users or relevant third
                  parties and nothing in these Customer Terms shall be construed as giving us any rights to such Intellectual Property
                  Rights.
                </p>
                <h3>7.3 Licence</h3>
                <ol className="letters">
                  <li>
                    Subject to the terms of your Subscription, we grant to you and Your Users a non-exclusive, royalty-free (subject to
                    payment of the Subscription Fees), perpetual, non-transferable right to download and use any of our Intellectual
                    Property Rights contained within the content created by you using our Platform in accordance with these Customer
                    Terms. We specifically do not grant you and Your Users a licence to decouple anything that contains our
                    Intellectual Property Rights from your downloaded content or to create derivative works from our Intellectual
                    Property Rights.
                  </li>
                  <li>
                    You hereby grant to us a non-exclusive, non-transferable, royalty-free licence (including the right to grant
                    sub-licences to permitted subcontractors) to use Your Data for the purpose of us performing our obligations under
                    these Customer Terms.
                  </li>
                </ol>
                <h3>7.4 Feedback</h3>
                <p>
                  If you provide us with any feedback or suggestions regarding the Platform or any material or content on the Platform
                  (Feedback), you grant us (for yourself and each of Your Users), an irrevocable, perpetual, transferable, royalty-free
                  licence to use any Feedback for any purpose in connection with our business.
                </p>
              </section>

              <section>
                <h2>8. How the story ends (Term and termination)</h2>
                <h3>8.1 Term</h3>
                <ol className="letters">
                  <li>
                    The licences granted by us under these Customer Terms will commence on the Commencement Date and continue for the
                    subscription term agreed between the parties, unless terminated earlier in accordance with this clause 8.
                  </li>
                  <li>
                    Unless otherwise agreed in an Order Form, all paid subscriptions will automatically renew for the period of the
                    preceding subscription term, unless either party gives the other party at least 30 days prior notice of non-renewal
                    prior to the end of the current subscription term.
                  </li>
                </ol>
                <h3>8.2 Termination by notice</h3>
                <ol className="letters">
                  <li>
                    Either party may terminate the agreement incorporating these Customer Terms at the end of the current subscription
                    term by giving at least 30 days written notice to the other party prior to expiry of the current subscription term.
                  </li>
                  <li>Either party may terminate a free subscription or free trial of the Platform at any time without cause.</li>
                </ol>
                <h3>8.3 Termination for cause</h3>
                <ol className="letters">
                  <li>
                    We may cancel or suspend your account and access to our Platform at any time at our absolute discretion if:
                    <ol className="roman">
                      <li>
                        you or Your Users materially or consistently breach any provision of these Customer Terms or the{" "}
                        <Link className="btn-link" to="/legal/acceptable-use-policy">
                          Acceptable Use Policy
                        </Link>{" "}
                        on our website; or
                      </li>
                      <li>you are or threaten to become insolvent.</li>
                    </ol>
                  </li>
                  <li>
                    We may terminate your account and access to the Platform on provision of notice to you in which case your account
                    will be terminated on expiry of the current subscription term.
                  </li>
                </ol>
                <h3>8.4 Effect of termination</h3>
                <p>Where our obligation to provide the Platform to you is cancelled or terminated:</p>
                <ol className="letters">
                  <li>all legal rights of a party arising from a breach that preceded cancellation or termination survive; and</li>
                  <li>
                    our obligations to licence the Platform to you is at an end and all licenses granted to you under these Customer
                    Terms are immediately terminated;
                  </li>
                  <li>
                    you will not have access to Your Data and we may delete Your Data, so it is important that you export Your Data
                    from the Platform using the facilities available to you under your Subscription prior to termination.
                  </li>
                </ol>
                <h3>8.5 Obligations on termination</h3>
                <p>On termination of these Customer Terms you must:</p>
                <ol className="letters">
                  <li>
                    delete (or at our request, return) all Confidential Information of ours in your possession, and certify such
                    deletion upon our request;
                  </li>
                  <li>
                    cease to utilise the Platform and any of the Intellectual Property Rights of us licensed pursuant to these Customer
                    Terms; and
                  </li>
                  <li>
                    immediately pay any outstanding unpaid invoices and interest due to us and you shall pay these invoices immediately
                    on receipt, and no later than 7 days from the date of termination.
                  </li>
                </ol>
              </section>

              <section>
                <h2>9. Responsibilities when something goes wrong (Liability)</h2>
                <h3>9.1 Limitation of Liability</h3>
                <ol className="letters">
                  <li>
                    This clause sets out the entire financial liability of the parties (including any liability for the acts or
                    omissions of its employees, agents and subcontractors) to each other in respect of:
                    <ol className="roman">
                      <li>any breach of these Customer Terms;</li>
                      <li>any use made of the Platform; and</li>
                      <li>
                        any representation, statement or tortious act or omission (including negligence) arising under or in connection
                        with these Customer Terms.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Except for excluded claims covered by clause 9.2 and unless otherwise specified in these Customer Terms:
                    <ol className="roman">
                      <li>
                        Each party's total liability arising under or in connection with these Customer Terms will be limited in the
                        aggregate to the total Subscription Fees payable by you to us in the twelve (12) months preceding the claim.
                      </li>
                      <li>
                        you shall be solely responsible, as against us for any leads, opinions, recommendations, forecasts or other
                        conclusions made or actions taken by you, Your Users or any other third party based (wholly or in part) on any
                        recommendations provided by us or the results obtained from your use of the Platform;{" "}
                      </li>
                      <li>
                        we shall have no liability for any damage caused by errors or omissions in any:
                        <ol className="numbers">
                          <li>information or instructions provided to us by you or Your Users in connection with the Platform; or</li>
                          <li>
                            information, materials or instructions, including Your Data, provided to us by you or Your Users or a third
                            party in connection with the Platform.
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                </ol>
                <h3>9.2 Excluded Claims</h3>
                <p>Excluded claims means:</p>
                <ol className="letters">
                  <li>amounts owed by you to us;</li>
                  <li>either party's express indemnification obligations under these Customer Terms;</li>
                  <li>your breach of clause 3.2 (Restrictions on use); and</li>
                  <li>
                    all warranties, representations, conditions and all other terms of any kind whatsoever implied by statute or common
                    law are excluded from these Customer Terms.
                  </li>
                </ol>
                <h3>9.3 Consequential Loss</h3>
                <p>Neither party will be liable to the other or any other person for any Consequential Loss.</p>
                <h3>9.4 Mitigation</h3>
                <p>
                  Each party must mitigate any loss it suffers as a result of the breach by the other party to these Customer Terms or
                  any warranty or indemnity provided under these Customer Terms.
                </p>
                <h3>9.5 Consumer Law</h3>
                <p>
                  To the extent that any rights cannot be excluded under any relevant consumer laws then our liability will be limited
                  to, at our election, the re-supply of the services or the payment of the cost of having the services supplied again.
                </p>
              </section>

              <section>
                <h2>10. What you and we guarantee (Warranties, disclaimers and indemnities) </h2>
                <h3>10.1 Warranties</h3>
                <p>
                  You warrant that you and Your Users will not use the Platform in a manner that infringes the rights of any other
                  person or breaches any applicable law, regulation or our{" "}
                  <Link className="btn-link" to="/legal/acceptable-use-policy">
                    Acceptable Use Policy
                  </Link>
                  .
                </p>
                <h3>10.2 Warranty disclaimer</h3>
                <p>
                  EXCEPT AS EXPRESSLY PROVIDED FOR HEREIN, TO THE EXTENT PERMITTED BY LAW, THE SERVICES AND ALL RELATED COMPONENTS AND
                  INFORMATION ARE PROVIDED ON AN ‘AS IS’ AND ‘AS AVAILABLE’ BASIS WITHOUT ANY WARRANTIES OF ANY KIND, AND WE EXPRESSLY
                  DISCLAIM ANY AND ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY,
                  TITLE, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. THE CUSTOMER ACKNOWLEDGES THAT WE DO NOT WARRANT THAT
                  THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE.
                </p>
                <h3>10.3 Your indemnification of us</h3>
                <ol className="letters">
                  <li>
                    You indemnify us in respect of any loss or damage we incur which arises out of or in connection with:
                    <ol className="roman">
                      <li>your use of the Platform;</li>
                      <li>any breach by you of your obligations under these Customer Terms;</li>
                      <li>the unauthorised use of your account;</li>
                      <li>our use of Your Data; or</li>
                      <li>
                        any claim that Your Data, its use, storage, reproduction or communication, infringes another person’s rights
                      </li>
                    </ol>
                  </li>
                </ol>
                <h3>10.4 Our indemnification of you</h3>
                <p>
                  We will defend you against any claim brought against you by a third party alleging that the Platform, when used in
                  accordance with these User Terms, infringes or misappropriates any third-party Intellectual Property Right{" "}
                  <strong>(Claim)</strong>, and we will indemnify you and hold you harmless against any damages and costs finally
                  awarded on the Claim by a court of competent jurisdiction or agreed to via settlement executed by us (including
                  reasonable solicitors’ fees), provided that we have received from you:{" "}
                </p>
                <ol className="letters">
                  <li>
                    prompt written notice of the Claim (but in any event notice in sufficient time for us to respond without
                    prejudice);
                  </li>
                  <li>
                    reasonable assistance in the defence and investigation of the Claim, including providing us a copy of the Claim,
                    all relevant evidence in your possession, custody, or control, and cooperation with evidentiary discovery,
                    litigation, and trial, including making witnesses within your employ or control available for testimony; and
                  </li>
                  <li>
                    the exclusive right to control and direct the investigation, defence, and settlement (if applicable) of the Claim.
                  </li>
                </ol>
                <p>
                  If your use of the Platform is (or in your opinion is likely to be) enjoined, whether by court order or by
                  settlement, or if we determine such actions are reasonably necessary to avoid material liability, we may, at our
                  option and in our discretion:
                </p>
                <ol className="roman">
                  <li>procure the right for your continued use of the Platform in accordance with these Terms;</li>
                  <li>substitute a substantially functionally similar Platform; or</li>
                  <li>
                    terminate your right to continue using the Platform and refund any prepaid amounts for the terminated portion of
                    the Subscription Term.
                  </li>
                </ol>
                <p>Our indemnification obligations above do not apply:</p>
                <ol className="numbers">
                  <li>
                    if the total aggregate fees we receive with respect to your Subscription to the Platform in the twelve (12) month
                    period immediately preceding the Claim is less than US$50,000;
                  </li>
                  <li>
                    if the Platform is modified by any party other than us, but solely to the extent the alleged infringement is caused
                    by such modification;
                  </li>
                  <li>
                    if the Platform is used in combination with any third-party product, software, service or equipment, but solely to
                    the extent the alleged infringement is caused by such combination;
                  </li>
                  <li>to unauthorized use of the Platform;</li>
                  <li>
                    to any Claim arising as a result of (a) Your Data or circumstances covered by your indemnification obligations or
                    (b) any third-party deliverables or components contained with the Platform or
                  </li>
                  <li>if you settle or make any admissions with respect to a Claim without our prior written consent.</li>
                </ol>
                <p>
                  THIS CLAUSE 10.4 (Our indemnification of you) STATES OUR SOLE LIABILITY AND YOUR EXCLUSIVE REMEDY FOR ANY
                  INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS IN CONNECTION WITH THE PLATFORM OR ANY CLOUD PRODUCT OR OTHER ITEMS WE
                  PROVIDE UNDER THESE USER TERMS.
                </p>
              </section>

              <section>
                <h2>11. Shared secrets (Confidentiality)</h2>
                <h3>11.1 Information to be kept confidential</h3>
                <p>Each party agrees to, and shall ensure each of its officers, employees and contractors:</p>
                <ol className="letters">
                  <li>
                    take all reasonable measures to ensure that any Confidential Information is protected against loss, unauthorised
                    access, use, modification or disclosure and against other misuse;
                  </li>
                  <li>
                    not copy or reproduce any Confidential Information without our consent other than as contemplated by and subject to
                    these Customer Terms;
                  </li>
                  <li>
                    will not access, use, disclose or retain Confidential Information except as contemplated by and subject to these
                    Terms; and
                  </li>
                  <li>
                    not use any Confidential Information for any purpose other than as contemplated by and subject to these Customer
                    Terms.
                  </li>
                </ol>
                <h3>11.2 Permissible disclosure</h3>
                <p>Each party may:</p>
                <ol className="letters">
                  <li>
                    disclose any Confidential Information to any governmental or other authority or regulatory body to such extent only
                    as is required by law, subject to first informing the person or body in question that the Confidential Information
                    is confidential; and
                  </li>
                  <li>
                    use any Confidential Information for any purpose, or disclose it to any other person, to the extent only that it is
                    at the date of your acceptance of these Customer Terms, or at any time after that date becomes, public knowledge
                    through no fault of your own, provided that in doing so you do not disclose any part of that Confidential
                    Information which is not public knowledge.
                  </li>
                </ol>
                <h3>11.3 Publicity</h3>
                <p>
                  You acknowledge and agree that we may showcase your use of the Platform on our website and promotional material, and
                  you will not unreasonably withhold consent to identification of you as a user of the Platform in such promotional
                  materials. To withdraw your consent to identify you as a Shorthand customer in our promotional materials, please send
                  your request to{" "}
                  <a className="btn-link" href="mailto:success@shorthand.com">
                    success@shorthand.com
                  </a>
                  .
                </p>
              </section>

              <section>
                <h2>12. Protecting personal data (Privacy)</h2>
                <ol className="letters">
                  <li>Both parties must comply with all applicable requirements of the Privacy Legislation.</li>
                  <li>
                    Both parties shall take and implement all such technical and organisational security procedures and measures
                    necessary or appropriate to preserve the security and confidentiality of any personal data they control or process
                    under or in connection with these Customer Terms.
                  </li>
                  <li>
                    We will only use your personal information provided to us in accordance with our{" "}
                    <Link className="btn-link" to="/legal/privacy-policy">
                      Privacy Policy
                    </Link>{" "}
                    set out on our website. Where we transfer personal data of individuals, we will do so in accordance with our
                    standard Data Processing Agreement set out on our website, which is incorporated by reference to these Customer
                    Terms.{" "}
                  </li>
                </ol>
              </section>

              <section>
                <h2>13. Stranger things (Force Majeure)</h2>
                <p>
                  Neither party shall be liable for any delay or failure to perform its obligations pursuant to these Customer Terms if
                  such delay is due to an event of Force Majeure.
                </p>
              </section>

              <section>
                <h2>14. Other important stuff (General)</h2>
                <h3>14.1 Governing law</h3>
                <ol className="letters">
                  <li>
                    If you are located in the UK or Europe, the laws of England and Wales govern these Customer Terms and each party
                    irrevocably submits to the non-exclusive jurisdiction of the courts of England and Wales and courts competent to
                    hear appeals from those courts.
                  </li>
                  <li>
                    If you are located in the United States of America, California laws shall govern these Customer Terms, and each
                    party irrevocably submits to the non-exclusive jurisdiction of the courts of the State of California and courts
                    competent to hear appeals from those courts.
                  </li>
                  <li>
                    If you are located in Australia, or a jurisdiction not covered by subclauses (a) or (b) above, the laws of
                    Queensland shall govern these Customer Terms, and each party irrevocably submits to the non-exclusive jurisdiction
                    of the courts of Queensland, Australia and courts competent to hear appeals from those courts.
                  </li>
                  <li>The terms of the United Nations Convention on Contracts for the Sale of Goods do not apply to these Terms.</li>
                </ol>
                <h3>14.2 Modifications</h3>
                <p>
                  We may modify these Customer Terms, including Our Policies, from time to time as our business evolves. We will
                  provide reasonable notice of any material changes to these Customer Terms in accordance with Section 15(b). Any
                  material changes to these Customer Terms will become effective upon the date given in our notice. Any other changes
                  to these Customer Terms will become effective upon the date of posting. If you or any of Your Users access the
                  Platform after the effective date of the changes, you will be deemed to have given your acceptance of the modified
                  Customer Terms.
                </p>
                <h3>14.3 Assignment</h3>
                <p>
                  Neither party may assign, in whole or in part any of its rights and obligations under these Customer Terms without
                  the prior written consent of the other party.{" "}
                </p>
                <h3>14.4 Relationship between parties</h3>
                <p>
                  Nothing in these Customer Terms shall create, or be deemed to create, a partnership, the relationship of principal
                  and agent, or of employer and employee between you and us.
                </p>
                <h3>14.5 Severability</h3>
                <p>
                  A clause or part of a clause of these Customer Terms that is illegal or unenforceable may be severed from these
                  Customer Terms and the remaining clauses or parts of the clause of these Customer Terms continue in force.
                </p>
                <h3>14.6 Entire agreement</h3>
                <p>
                  These Customer Terms constitute the entire agreement between you and us on the subject matter, and merges and
                  supersedes all other or prior understandings, agreements and arrangements.
                </p>
                <h3>14.7 Further assurance</h3>
                <p>
                  Each party must do all things reasonably necessary to give effect to these Customer Terms and the transactions
                  contemplated by it.
                </p>
                <h3>14.8 Waiver</h3>
                <p>
                  A right under these Customer Terms may only be waived in writing signed by the party granting the waiver, and is
                  effective only to the extent specifically set out in that waiver.
                </p>
              </section>

              <section>
                <h2>15. Staying in touch (Communications)</h2>
                <ol className="letters">
                  <li>
                    Notices to Shorthand must be given in writing to the address nominated on the Order Form or to legal@shorthand.com.
                    Your notice will be deemed to have been given upon receipt. We will confirm receipt of this by contacting you in
                    writing, normally by email.
                  </li>
                  <li>
                    If we have to contact you or give you notice in writing, we will do so by email or through the Platform. Notice
                    will be deemed to have been given on the next business day.
                  </li>
                </ol>
              </section>

              <section>
                <h2>16. Special words (Defined terms & interpretation)</h2>
                <h3>16.1 Defined terms</h3>
                <p>In these Customer Terms:</p>
                <ol className="letters">
                  <li>
                    <strong>Commencement Date</strong> means the date of acceptance of these Customer Terms.
                  </li>
                  <li>
                    <strong>Confidential Information</strong> means any information relating to the business and affairs of a party, to
                    the customers, employees, sub-suppliers or other persons doing business with a party, to these terms, or to the
                    Intellectual Property Rights of a party, which is by its nature confidential, is designated as confidential by a
                    party, or which the other party knows or ought to know, is confidential, and includes all trade secrets, knowhow,
                    marketing, financial and customer information, forecasts, and strategies and any other commercially valuable
                    information of a party.
                  </li>
                  <li>
                    <strong>Consequential Loss</strong> means indirect, economic, special or consequential loss or damage, loss of
                    revenue, time, goodwill, data, anticipated savings, opportunity, loss of production and loss of profit.
                  </li>
                  <li>
                    <strong>Force Majeure</strong> means an event outside of the reasonable control of a party and includes an act of
                    God, national emergency, terrorist act, sabotage, flood, storm, earthquake, fire, explosion, civil disturbance,
                    insurrection, riot, war, or industrial action.
                  </li>
                  <li>
                    <strong>Intellectual Property Rights</strong> means all industrial and intellectual property rights throughout the
                    world, whether registered, unregistered or unregistrable, including all copyrights, patents, trademarks, service
                    marks, designs, confidential information, trade secrets, know how, data and databases, systems and domain names.
                  </li>
                  <li>
                    <strong>Our Policies</strong> means our{" "}
                    <Link className="btn-link" to="/legal/privacy-policy">
                      Privacy Policy
                    </Link>
                    ,{" "}
                    <Link className="btn-link" to="/legal/acceptable-use-policy">
                      Acceptable Use Policy
                    </Link>
                    ,{" "}
                    <Link className="btn-link" to="/legal/copyright-and-trademark-policy">
                      Copyright and Trademark Policy
                    </Link>
                    , and any other policies referenced in these Customer Terms.
                  </li>
                  <li>
                    <strong>Privacy Legislation</strong> means and includes (but is not limited to) the Privacy Act 1988 (Cth), the
                    Data Protection Acts of 1998 and 2018 (UK), the EU General Data Protective Regulation (Regulation (EU) 2016/679),
                    the California Consumer Privacy Act, and all other laws, regulations, registered privacy codes, privacy policies
                    and contractual terms applicable in the jurisdiction where the Platform is being licenced that relate to the
                    processing of personal data.
                  </li>
                  <li>
                    <strong>Subscription Fees</strong> means the fees for access to the Platform as set out in an Order Form, our
                    website or otherwise agreed between the parties.
                  </li>
                  <li>
                    <strong>Third Party Software</strong> means Intellectual Property Rights in any software or source code proprietary
                    to third parties (including open-source software) that form part of, or interact with, the Platform.
                  </li>
                  <li>
                    <strong>User Terms</strong> means the terms and conditions governing you and Your Users’ access and use of the
                    Platform as published by us from time to time.
                  </li>
                  <li>
                    <strong>Your Data</strong> means all information, materials and other data uploaded by you or Your Users to the
                    Platform or otherwise provided by you for the purposes of providing the services on the Platform to be stored,
                    transmitted, processed and/or retrieved by us.
                  </li>
                  <li>
                    <strong>Your Users</strong> means any of your employees, agents, contractors or other authorised individuals who
                    you have authorised to access and use the Platform under these Customer Terms.
                  </li>
                </ol>
              </section>
            </div>
          </div>
          <Footer />
        </main>
      </Layout>
    );
  }
}
