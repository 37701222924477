import * as colorStyles from "../PricingPlan.module.scss";
import * as styles from "./Features.module.scss";
import * as React from "react";

let classNames = require("classnames");

interface FeaturesProps {
  title: string;
  features: string[];
  /**
   * tooltips can be optional
   * use an empty string to skip a single or multiple tooltips
   * tooltips = ["","","a tooltip",""]
   */
  tooltips?: string[];
  /**
   * adds themeing to the tooltips and features text
   */
  theme?: "light" | "dark";
}

// This is based of the plan benefits and not the technical features data
export const Features = ({ title, features, tooltips, theme }: FeaturesProps): JSX.Element => {
  let featuresTitleStyles = colorStyles.planLightFeaturesTitle;
  let themeStyles = colorStyles.planLightFeaturesListItem;
  let toolTipColour = styles.lightToolTip;
  const hasToolTips = tooltips && tooltips.length > 0;

  if (theme === "dark") {
    featuresTitleStyles = colorStyles.planDarkFeaturesTitle;
    themeStyles = colorStyles.planDarkFeaturesListItem;
    toolTipColour = styles.darkToolTip;
  }

  return (
    <div>
      <div className={classNames(styles.title, featuresTitleStyles)}>{title}</div>
      <ul className={styles.list}>
        {features.map((feature, index) => {
          return (
            <li key={feature} tabIndex={0} className={classNames(styles.listItem, themeStyles)}>
              {hasToolTips && tooltips[index] != "" && (
                <span className={classNames(styles.toolTip, toolTipColour)}>{tooltips[index]}</span>
              )}

              <svg width={16} height={12} fill="none" style={{ position: "relative", top: "6px" }}>
                <path stroke="#12BF9C" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="m1 7 4 4L15 1" />
              </svg>

              <div>{feature}</div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
