import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image";
import * as React from "react";
import { ArrowRight, ChevronLeft, ChevronRight } from "react-feather";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { twMerge } from "tailwind-merge";

export default function Testimonial({
  testimonials = [],
  cta,
  img,
  firstContentType = "image",
}: Readonly<{
  testimonials: {
    content: string;
    author: string;
  }[];
  cta: {
    text: string;
    link: string;
  };
  img: { url: IGatsbyImageData; alt: string };
  firstContentType?: "image" | "testimonial";
}>): React.JSX.Element | null {
  const [prevEl, setPrevEl] = React.useState<HTMLElement | null>(null);
  const [nextEl, setNextEl] = React.useState<HTMLElement | null>(null);

  if (!testimonials) return null;

  /** memoized this to avoid max depth exceed in child component*/
  const memoizedTestimonials = React.useMemo(() => {
    return (
      <div>
        <TestimonialContent testimonials={testimonials} nextEl={nextEl} prevEl={prevEl} />
        <div className="tw-border-t tw-border-[#A4A4A4]" />
        <div
          className={`tw-flex tw-flex-col tw-flex-wrap tw-items-center tw-justify-center tw-gap-8 tw-pt-10 
    ${testimonials.length > 1 ? "max-lg:tw-mb-[50px]" : null} 
  `}
        >
          {testimonials.length > 1 && (
            <div className="tw-flex tw-gap-2">
              <TestimonialNav refFunction={setPrevEl}>
                <ChevronLeft size={20} />
              </TestimonialNav>
              <TestimonialNav refFunction={setNextEl}>
                <ChevronRight size={20} />
              </TestimonialNav>
            </div>
          )}
          <SignUpButton text={cta.text} />
        </div>
      </div>
    );
  }, [testimonials, cta, nextEl, prevEl]);

  return (
    <div className="tw-pt-[100px] max-xl:tw-pt-0">
      <div
        className={`tw-grid tw-grid-cols-2 tw-items-start tw-gap-x-[10%] tw-rounded-[10px] tw-bg-[#F1F2F2]
            tw-p-[100px]
            max-xl:tw-items-center
            ${testimonials.length > 1 ? "max-xl:tw-pb-[150px]" : null} 
            max-lg:tw-grid-cols-1 max-lg:tw-p-20
            max-lg:tw-text-center
            max-sm:tw-px-6 max-sm:tw-py-20
          `}
      >
        {firstContentType === "testimonial" ? (
          <>
            {memoizedTestimonials}
            <Image img={img} />
          </>
        ) : (
          <>
            <Image img={img} />
            {memoizedTestimonials}
          </>
        )}
      </div>
    </div>
  );
}

const TestimonialContent = ({
  testimonials,
  prevEl,
  nextEl,
}: {
  prevEl: HTMLElement | null;
  nextEl: HTMLElement | null;
  testimonials: { author: string; content: string }[];
}): JSX.Element => {
  const TestimonailCard = ({ testimonial }: { testimonial: { author: string; content: string } }) => {
    return (
      <div>
        <p
          className="tw-h2 !tw-text-[30px] !tw-font-medium tw-text-text_black max-sm:!tw-text-xl
"
        >
          “{testimonial.content}”
        </p>
        <p
          className="tw-h4  tw-mb-9 tw-pt-6 !tw-leading-[130%]  tw-text-text_black
   max-sm:!tw-text-lg [&>a]:!tw-font-CircularXXSub [&>a]:!tw-text-text_black  [&>a]:hover:!tw-text-text_black"
          dangerouslySetInnerHTML={{
            __html: testimonial.author,
          }}
        ></p>
      </div>
    );
  };

  if (testimonials.length === 1) {
    return <TestimonailCard testimonial={testimonials[0]} />;
  }

  return (
    <Swiper
      modules={[Navigation]}
      spaceBetween={15}
      slidesPerView={1}
      navigation={{
        prevEl,
        nextEl,
        disabledClass: "tw-opacity-50 tw-cursor-default",
      }}
    >
      {testimonials.map(indiTestimonial => {
        return (
          <SwiperSlide key={indiTestimonial.author}>
            <TestimonailCard testimonial={indiTestimonial} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

const Image = ({ img }: Readonly<{ img: { url: IGatsbyImageData; alt: string } }>): JSX.Element => {
  return (
    <div
      className="tw-relative max-lg:tw-hidden"
      data-sal="slide-up"
      data-sal-duration="500"
      data-sal-delay="100"
      data-sal-easing="ease-out"
    >
      <div className="tw-absolute tw-top-[-200px] max-xl:tw-top-[0] max-xl:tw-translate-y-[-50%]">
        <GatsbyImage
          image={getImage(img.url)}
          alt={img.alt}
          className="tw-h-[700px] tw-overflow-hidden tw-rounded-[10px] max-xl:tw-h-[600px] max-lg:tw-w-full"
        />
      </div>
    </div>
  );
};

const TestimonialNav = ({ refFunction, children }: { children: React.ReactNode; refFunction: (node: any) => void }): JSX.Element => {
  return (
    <div
      ref={node => refFunction(node)}
      className="tw-flex tw-h-[50px] tw-w-[50px] tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-full tw-bg-white"
    >
      {children}
    </div>
  );
};

export const SignUpButton = ({ text, className }: Readonly<{ text: string; className?: string }>): JSX.Element => {
  return (
    <a href="https://app.shorthand.com/signup" className={twMerge("btn btn-lg btn-primary !tw-m-0", className)}>
      <span>{text}</span>
      <ArrowRight size={22} className="tw-relative tw-top-[-2px] tw-ml-2 tw-inline-block" />
    </a>
  );
};
