import { ITemplate } from "./MobileView";
import * as React from "react";

export const DescriptionVideo = ({ descriptionVideo }: Pick<ITemplate["video"], "descriptionVideo">): JSX.Element => {
  return (
    <div className={"lightBorder safari-ios-overflow-fix tw-mt-[25px] tw-overflow-hidden tw-rounded-2xl !tw-shadow-default"}>
      <video
        id="video-top-ls"
        className="mx-auto"
        autoPlay={true}
        loop={true}
        muted={true}
        playsInline={true}
        poster={descriptionVideo.poster.src}
        width="100%"
        height="100%"
      >
        <source src={descriptionVideo.webM?.publicURL} type="video/webm" />
        <source src={descriptionVideo.mp4?.publicURL} type="video/mp4" />
        <track label="English" kind="captions" srcLang="en" src="" />
      </video>
    </div>
  );
};
