import { Footer } from "../components/Footer";
import { Layout } from "../components/Layout";
import { Link, graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default class Security extends React.Component<any, any> {
  render() {
    return (
      <Layout location={this.props.location}>
        <main id="main" className="marketing-content container-fluid">
          <Helmet
            bodyAttributes={{
              class: "body-shorthand body-marketing body-marketing-secondary body-light body-marketing-legal hide-elevio",
            }}
          />
          <div className="row section-title title-light">
            <div className="col-11 col-sm-8  mx-auto section-inner">
              <h1>
                <span className="shorthand-logo dark" aria-hidden="true">
                  Shorthand
                </span>
                Security practices
              </h1>
            </div>
            <span id="1" />
          </div>
          <div className="row justify-content-center">
            <div className="d-none d-lg-block col-lg-2">
              <aside className="legalNav">
                <nav className="legalNav-inner">
                  <ul>
                    <li>
                      <a href="#">Introduction</a>
                    </li>
                    <li>
                      <a href="#2">Infrastructure and network security</a>
                    </li>
                    <li>
                      <a href="#3">Product Security</a>
                    </li>
                    <li>
                      <a href="#4">Compliance</a>
                    </li>
                    <li>
                      <a href="#5">Business continuity and disaster recovery</a>
                    </li>
                  </ul>
                </nav>
              </aside>
            </div>
            <div className="col-12 col-sm-10 col-lg-8 col-xxl-6">
              <section>
                <p className="intro">
                  At Shorthand, our primary security focus is to safeguard our customers' data. This is why Shorthand has invested in
                  resources and controls to facilitate effective risk management through our comprehensive security programme and
                  governance processes.
                </p>
                <p>
                  To formalise our commitment, we are{" "}
                  <a href="https://cvs.babcert.com/babcert.asp?c=234513&v=53r3k1irp2" target="_blank" rel="noopener noreferrer">
                    ISO27001 certified
                  </a>{" "}
                  and SOC2 Type II compliant. A copy of our SOC2 report is available on request.
                </p>
                <h3>What data does Shorthand store?</h3>
                <p>
                  When you create a Shorthand account, you must provide personal information such as your email address and full name.
                  We may also store your profile picture.
                </p>
                <p>
                  While using our services, the data you enter into your Shorthand story may vary — you may upload or directly enter
                  any data such as text, images, audio, video, or other files.
                </p>
                <p>
                  Additional data that we may collect is covered in more detail in our{" "}
                  <Link to="https://shorthand.com/legal/privacy-policy/">Privacy Policy</Link>.
                </p>

                <h3>Where does Shorthand store data?</h3>
                <p>
                  We store your story data in Amazon Web Services (AWS), our primary infrastructure provider. All production data is
                  stored in AWS us-east-1 region located in North Virginia, United States.
                </p>
                <p>
                  Where we use third party tools or sub-processors, these are listed on our{" "}
                  <a
                    href="https://support.shorthand.com/en/articles/97-data-privacy-sub-processors-and-third-party-tools"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data privacy: sub-processors and third party tools
                  </a>{" "}
                  page with details about the data we use and how it is processed.
                </p>
                <span id="2" />
              </section>
              <section>
                <h2>
                  <strong>Infrastructure and network security</strong>
                </h2>
                <p>
                  Shorthand is powered by AWS, the leading provider of secure computing infrastructure. We chose AWS because of their
                  stringent security measures and industry-recognised certifications, including ISO27001 certification and SOC2 audit
                  compliance — the full list of compliance resources is available on the{" "}
                  <a href="https://aws.amazon.com/compliance/programs/" target="_blank" rel="noopener noreferrer">
                    AWS Compliance Programs
                  </a>{" "}
                  page.
                </p>

                <h3>Feature-specific infrastructure</h3>
                <p>
                  Shorthand also uses secondary cloud infrastructure providers to process data for specific features of the web
                  application, such as image processing. Data is sent to{" "}
                  <a
                    href="https://support.shorthand.com/en/articles/97-data-privacy-sub-processors-and-third-party-tools"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    these providers
                  </a>{" "}
                  temporarily and stored for a brief period of time to perform the functionality of the feature, and is then
                  permanently deleted after the functionality has been performed.
                </p>
                <p>No data is permanently stored or hosted within these infrastructure providers.</p>

                <h3>Shorthand hosting</h3>
                <p>
                  Shorthand Hosting is an extremely reliable publishing method and uses a high traffic capable AWS S3 and AWS
                  CloudFront content delivery network. Amazon’s S3 infrastructure is designed to provide 99.99% availability. If you
                  would like to know more about AWS CloudFront’s performance, you can read about it{" "}
                  <a href="https://aws.amazon.com/cloudfront/" target="_blank" rel="noopener noreferrer">
                    on the product page
                  </a>
                  .
                </p>
                <p>
                  Customers also have the ability to host stories directly on their own servers and have full control over their
                  hosting environment.
                </p>

                <h3>Access controls</h3>
                <p>
                  Shorthand employees have limited access to Shorthand infrastructure, with access always provided on a minimum
                  required and least privileged basis. Access is only granted on a need-to-use basis, based on the responsibilities and
                  duties of the employees. Access is reviewed half-yearly at minimum, and access rights updated accordingly.
                </p>
                <p>
                  Access to infrastructure configurations and logs is managed by the AWS IAM (Identity and Access Management) service.
                  Roles are used in conjunction with named user accounts to give fine-grained control of user rights to any resources.
                  Multi-factor authentication controls are enforced on all user accounts. All access attempts to the hosting platform
                  or servers are logged and monitored. Alerts are in place to expose any abnormal activity.
                </p>
                <p>
                  Shorthand uses AWS for its primary infrastructure. AWS data centers feature a layered security model, with physical
                  and environmental controls implemented as{" "}
                  <a href="https://aws.amazon.com/compliance/data-center/controls/" target="_blank" rel="noopener noreferrer">
                    outlined by AWS
                  </a>
                  .
                </p>
                <p>Shorthand employees do not have physical access to AWS data centers, servers, network equipment, or storage.</p>

                <h3>Penetration testing</h3>
                <p>
                  Shorthand is reviewed yearly by a well-respected third party security consultancy, and any findings are actioned
                  within a time frame as determined based on the severity assessment of the issue.
                </p>
                <p>
                  The testing represents a point-in-time assessment of the Shorthand application against potential or existing
                  vulnerabilities that may lead to compromise of the environment. Testing is based on best practice methodologies such
                  as the Open Web Application Security Project (OWASP) and CWE/SANS Top 25 Most Dangerous Software Errors.
                </p>

                <h3>Vulnerability management</h3>
                <p>
                  Shorthand uses automated testing to ensure new features do not weaken our application security model. Automated
                  patching is in place for all servers in our hosting platform with the latest available patches from relevant vendors.
                </p>
                <p>
                  New security threats and emerging vulnerabilities are continually being found through automated dependency and
                  security scans, and we monitor bulletins to identify threats that could affect our platform. Shorthand defines the
                  severity of an issue using Common Vulnerability Scoring System (CVSS) scores, which is an industry recognised
                  standard.
                </p>
                <p>
                  Once vulnerabilities or threats are found, findings are actively investigated and remedied based on their severity.
                  Compliance of vulnerability resolution times are enforced via compliance software,{" "}
                  <a href="https://www.vanta.com" target="_blank" rel="noopener noreferrer">
                    Vanta
                  </a>
                  , and tracked with Linear.
                </p>

                <h3>Intrusion detection and prevention</h3>
                <p>
                  Shorthand's network security design uses a defense in depth approach, with network firewalls (AWS Web Application
                  Firewall) supplemented by Virtual Private Cloud network segmentation rules and per environment security groups, and
                  IAM policies.
                </p>
                <p>
                  Shorthand uses CrowdStrike Falcon for Endpoint Detection and Response (EDR). Amazon GuardDuty is used for Intrusion
                  Detection (IDS) and Intrusion Prevention (IPS). GuardDuty uses machine learning, anomaly detection, and integrated
                  threat intelligence to continuously monitor and identify potential threats.
                </p>

                <h3>Data encryption</h3>
                <p>
                  Clients access our services over the internet using a secure connection. All data is encrypted in transit using
                  current cipher suites and protocols which are reviewed regularly, and at rest using AES-256 encryption.
                </p>
                <p>
                  Communication between clients’ browsers and the application are transmitted over HTTPS (TLS 1.2, or TLS 1.3 if
                  available) and are secured using SHA-256 encryption. Any data leaving the data centre is transferred on an encrypted
                  channel.
                </p>

                <h3>Secure development lifecycle</h3>
                <p>
                  Shorthand is designed and developed in accordance with the principles of secure software development consistent with
                  industry best practices, including protection against commonly known vulnerabilities such as OWASP Top 10 for
                  web-facing applications. Internal security reviews are conducted on all new feature work, and as part of business as
                  usual.
                </p>
                <p>
                  Code reviews and automated code scanning tools are used before anything is released to production so that flaws and
                  human error can be identified before they can cause security concerns. When a third-party application or service
                  intends to replace existing or introduce new functionality, these are assessed and demonstrated to satisfy existing
                  business use cases without increasing the risk of exposing sensitive data to unauthorised third parties.
                </p>
                <p>
                  Separate Development, Test, and Staging environments are used to validate and test changes made to the production
                  code and infrastructure configuration. These environments resemble the configuration and makeup of the production
                  environment as closely as possible to allow effective testing and to reduce the effect of environmental differences.
                </p>

                <h3>Logical separation</h3>
                <p>
                  Shorthand uses a multi-tenant architecture where all customers share the same computing resources and customer story
                  data is stored and processed in a shared database. Logical segregation of data between customers and correct access
                  is enforced using program logic and unique customer identifiers.
                </p>

                <h3>Incident response</h3>
                <p>
                  Our team has a clear process to manage incidents through to resolution as outlined within our Incident Management
                  Plan. Incident reporting includes investigation of findings, scope of the data or services affected, and what
                  remedial actions will be taken to reduce the risk. In the event of a data breach, it will be reported to the relevant
                  authorities and the affected clients within 48 hours.
                </p>

                <h3>Logging and monitoring</h3>
                <p>Logs are configured to include, but are not limited to:</p>
                <ul>
                  <li>authentication activities, whether successful or not,</li>
                  <li>activities or occasions where the user's privilege/permission level changes,</li>
                  <li>administrative activities on the application or any of its components,</li>
                  <li>
                    transaction events related to interaction (read, change, copy, move, delete) with business-critical data and
                    sensitive data,
                  </li>
                  <li>detailed HTTP logs,</li>
                  <li>system information and errors, and</li>
                  <li>application errors.</li>
                </ul>
                <p>
                  Logs are scanned by automated tools and alerts are raised to the centralised alerting and monitoring system (AWS
                  Security Hub and Datadog) for identified issues or anomalies.
                </p>
                <p>
                  Sentry and Amazon CloudWatch logs are used for application logging and monitoring to assist in the diagnosis and
                  correction of issues within the Shorthand application. Amazon CloudWatch and CloudTrail are also used to log,
                  monitor, and alert on resource allocation and operational performance of the Shorthand infrastructure. Vanta is used
                  to enable governance, compliance, and operational risk auditing of operations, security related events, and
                  misconfigurations.
                </p>
                <p>
                  Logs are centrally stored for up to at least 2 years, with 3 months’ worth of logs readily available to assist in the
                  diagnosing of security issues.
                </p>
                <span id="3" />
              </section>
              <section>
                <h2>
                  <strong>Product Security</strong>
                </h2>

                <h3>Single sign-on and passwords</h3>
                <p>
                  Where possible for customers, we recommend the use of SAML-based single sign-on (SSO) or Google Sign-In to access our
                  platform. If this isn't a viable option for you, then user access is controlled by unique usernames and passwords
                  aligning with elements of the NIST Digital Identity Guidelines (800-63B).
                </p>
                <p>
                  Users can reset and manage their own passwords within the platform. These may not be blacklisted (where the blacklist
                  includes known compromised values in breach corpuses, dictionary words, repetitive or sequential characters), are
                  stored using one-way hashing with a per-user salt, and are never sent or able to be viewed by either the user or
                  Shorthand’s staff.
                </p>

                <h3>Session management</h3>
                <p>
                  Shorthand provides the ability for users to manage their active sessions where they are logged into their accounts.
                  Users can review their active sessions to proactively manage the security of their account and prevent unauthorised
                  access, including the option to end any active session at any time.
                </p>
                <span id="4" />
              </section>
              <section>
                <h2>
                  <strong>Compliance</strong>
                </h2>

                <h3>SOC 2 Type II</h3>
                <p>
                  Shorthand has received a SOC 2 Type II report demonstrating that appropriate controls are in place to mitigate the
                  risks related to security.
                </p>
                <p>
                  A SOC 2 audit report provides detailed information and assurance about Shorthand’s security, providing assurance
                  about the effectiveness of our controls. The report is the outcome of an external audit performed by an independent
                  third-party firm certified by the American Institute of CPAs (AICPA).
                </p>
                <p>
                  Shorthand was assessed against the AICPA’s Trust Service Criteria for Security (also known as Common Criteria). The
                  Type II audit proves that we have controls in place for a sustained period of time, with reliable and consistent
                  safeguards in place to protect our customer’s data.
                </p>
                <p>Our reporting period concludes in December each year and we are committed to carrying out an annual SOC 2 audit.</p>

                <h3>ISO 27001</h3>
                <p>
                  Shorthand is ISO/IEC 27001:2013 (also known as ISO 27001) certified. ISO 27001 is a security management standard that
                  specifies security management best practices and comprehensive security controls. The standard lays out a framework
                  and controls which allows Shorthand to ensure a comprehensive and continually improving model for security
                  management.
                </p>
                <p>
                  Shorthand’s Information Security Management System (ISMS) supports the operations underlying our product and is
                  governed by the implemented controls in accordance with the organisation’s Statement of Applicability.
                </p>
                <p>
                  Shorthand was certified following an external audit performed by an independent third-party firm accredited with
                  UKAS, the only government-backed body for ISO certification in the United Kingdom. Our certification was issued on 30
                  November 2021, and we are committed to carrying out an annual ISO 27001 audit.
                </p>

                <h3>PCI DSS</h3>
                <p>
                  All payments made to Shorthand are securely processed by Stripe.{" "}
                  <a href="https://stripe.com/docs/security/guide" target="_blank" rel="noopener noreferrer">
                    Stripe
                  </a>{" "}
                  has been audited by an independent PCI Qualified Security Assessor (QSA) and is certified as a PCI Level 1 Service
                  Provider. This is the most stringent level of certification available in the payments industry.
                </p>
                <span id="5" />
              </section>
              <section>
                <h2>
                  <strong>Business continuity and disaster recovery</strong>
                </h2>

                <h3>Backups</h3>
                <p>
                  Shorthand has documented application and data backup policies and procedures that ensure all systems supporting our
                  service are regularly backed up and securely stored.
                </p>
                <p>
                  Data is backed up using Amazon Web Services’ (AWS) Relational Database Service (RDS) backup and recovery solution.
                  Daily full backups are retained for 7 days, and transactional logs are taken every 5 minutes, with all backups
                  encrypted at rest. All story related assets are stored using Amazon Simple Storage Service (Amazon S3) with
                  versioning enabled.
                </p>
                <p>
                  All backup processes are tested periodically to ensure that backup data can be restored to a production environment.
                </p>

                <h3>Disaster recovery</h3>
                <p>
                  Shorthand has a structured disaster recovery plan with detailed procedures to recover service operations after a
                  disruption resulting from a disaster. A disaster is defined as a disruptive incident which has occurred which
                  significantly affects the delivery of the Shorthand application, making it unavailable to users. This may be due to
                  infrastructure or software failure, loss of services, a cyber incident or some other reason.
                </p>
                <p>
                  While Shorthand aims for zero data loss and high availability, we understand that this may be impractical as systems
                  can go wrong and such targets may be unattainable or extremely expensive. As part of our Business Continuity and
                  Disaster Recovery Plan, we set Recovery Time Objectives (RTO) and Recovery Point Objectives (RPO) that aim to strike
                  a balance between cost, benefit, and practicality.
                </p>
                <p>
                  RTO refers to the amount of time it takes to restore critical functionality to Shorthand during a period of
                  unavailability. While we aim to keep this as minimal as possible, there may be scenarios where this may take longer
                  than expected. As a result, we advise a RTO within 48 hours of failure.
                </p>
                <p>
                  RPO is the time an organisation accepts it may lose in a recovery operation. Full database backups are performed
                  every 24 hours, and database transaction logs are maintained. In an ideal scenario, this means we can restore our
                  database to within minutes of when service is interrupted, with minimal data loss, if any. Failing that, we expect to
                  be able to restore to a full database backup and as a result, we advise a RPO of 24 hours.
                </p>
                <p>
                  Coordinated testing and rehearsals of both business continuity and the disaster recovery plan are conducted annually.
                  This includes a retrospective to identify lessons learned and improvements to playbooks and operating procedures.
                </p>
              </section>
            </div>
          </div>
          <Footer />
        </main>
      </Layout>
    );
  }
}

export const query = graphql`
  query SecurityQuery {
    site {
      siteMetadata {
        name
        title
        tagline
        description
      }
    }
  }
`;
