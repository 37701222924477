import { Footer } from "../../components/Footer";
import { Icon } from "../../components/Icon";
import { Layout } from "../../components/Layout";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default class IndustriesMedia extends React.Component<any, any> {
  render() {
    const { allBrandsJson } = this.props.data;

    const brands = allBrandsJson.edges;

    return (
      <Layout location={this.props.location}>
        <main id="main" className="marketing-content container-fluid">
          <Helmet
            bodyAttributes={{
              class: "body-shorthand body-marketing body-marketing-secondary body-marketing-features body-light hide-elevio",
            }}
          ></Helmet>
          <div className="row section-title title-light">
            <div className="col-11 col-sm-8  mx-auto section-inner">
              <h1>
                <span className="shorthand-logo dark" aria-hidden="true">
                  Shorthand
                </span>
                Media & journalism
              </h1>
              <h2>Publish visually rich, immersive feature stories.</h2>
            </div>
          </div>
          <section className="row section-brands section-border-top">
            <div className="brands-list">
              <ul>
                <li>
                  <img src="/media/logos/clients/lg-bbc.png" loading="lazy" width="348" height="241" alt="BBC logo" />
                </li>
                <li>
                  <img src="/media/logos/clients/lg-stuff.png" loading="lazy" width="348" height="241" alt="Stuff logo" />
                </li>
                <li>
                  <img src="/media/logos/clients/lg-sky.png" loading="lazy" width="348" height="241" alt="Sky News logo" />
                </li>
                <li>
                  <img src="/media/logos/clients/lg-nzme.png" loading="lazy" width="348" height="241" alt="NZME logo" />
                </li>
                <li>
                  <img
                    src="/media/logos/clients/lg-irish-independent.png"
                    loading="lazy"
                    width="348"
                    height="241"
                    alt="Irish Independent logo"
                  />
                </li>
                <li>
                  <img src="/media/logos/clients/lg-news24.png" loading="lazy" width="348" height="241" alt="News24 logo" />
                </li>
                <li className="hide-sm">
                  <img
                    src="/media/logos/clients/lg-press-journal.png"
                    loading="lazy"
                    width="348"
                    height="241"
                    alt="Press and Journal logo"
                  />
                </li>
              </ul>
            </div>
          </section>

          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Storytelling is our speciality</h3>
                  <p>
                    Shorthand is built for journalists to tell more engaging, visually rich stories — without the need of a web
                    designer or developer.
                  </p>
                  <ul className="features-list">
                    <li>Reduce your bounce rates and increase dwell time with stunning no-code animation effects.</li>
                    <li>
                      Support your existing workflow with multiple teams, roles, and permissions for story sharing, collaboration, team
                      management, and publishing rights.
                    </li>
                    <li>Place Shorthand stories behind a paywall &mdash; or integrate advertising into your open access story.</li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/industries/bbc-grid-grey.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-right">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Media-rich, data-driven stories</h3>
                  <p>
                    Easily incorporate photography, illustrations, charts, graphs, maps, infographics, and other interactive content.
                  </p>
                  <ul className="features-list">
                    <li>
                      Speed up production by easily creating animation from static images using our powerful Reveal and Scrollmation
                      sections. Just drag & drop &mdash; no code or animation tools required.
                    </li>
                    <li>Custom sections give you the power to add your own HTML, CSS, and Javascript.</li>
                    <li>Integrate components from Tableau, Flourish, and hundreds of other third-party services.</li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/industries/hr-white.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Responsive by default, customisable by nature</h3>
                  <p>Your audience expects to reach you from wherever they are, on whatever screen they have to hand.</p>
                  <ul className="features-list">
                    <li>
                      Shorthand stories natively adapt to work across multiple device types and screen resolutions. But you also have
                      the flexibility to tailor the experience based on your reader’s screen size.
                    </li>
                    <li>
                      Before you publish, easily preview your stories on multiple simulated screen resolutions from within the
                      Shorthand editor, and on real devices through secure preview links.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/industries/responsive-grey.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-right">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Beautifully engaging sponsored stories</h3>
                  <p>Give advertisers more value with visually rich, immersive sponsored content.</p>
                  <ul className="features-list">
                    <li>Create sponsored content as engaging as the best stories on the web.</li>
                    <li>Share secure story preview links with clients to receive pre-publication feedback.</li>
                    <li>
                      Use Shorthand Analytics &mdash; or your own embedded third-party analytics platform &mdash; to measure results.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/industries/bi-left-white.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section  section-strip section-strip-quote ">
            <div className="container container-sm">
              <div className="section-strip-col-single section-strip-single-quote">
                <div className="section-strip-quote-inner">
                  <blockquote>
                    &ldquo;Shorthand radically changed how we think of content and what we can accomplish. With Shorthand you can stop
                    worrying about the software and focus on creating the best content.&rdquo;
                  </blockquote>
                  <div className="section-strip-quote-rating">
                    <ul>
                      <li>
                        <Icon icon="star" />
                      </li>
                      <li>
                        <Icon icon="star" />
                      </li>
                      <li>
                        <Icon icon="star" />
                      </li>
                      <li>
                        <Icon icon="star" />
                      </li>
                      <li>
                        <Icon icon="star" />
                      </li>
                    </ul>
                  </div>
                  <cite>&mdash; Helena C</cite>
                  <a
                    href="https://www.g2.com/products/shorthand/reviews"
                    target="_blank"
                    rel="norefferer noopener"
                    className="btn btn-secondary"
                  >
                    Read more reviews on G2
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section className="row section  section-strip-stories section-border-top">
            <div className="container flex-column section-inner text-center">
              <h3>Take a look at these great feature stories, built with Shorthand</h3>
              <div className="featured-stories">
                <ul>
                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://www.bbc.co.uk/news/extra/x2iutcqf1g/beirut-blast"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/bbc-inferno.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        The inferno and the mystery ship
                        <span className="featured-story-brand">BBC</span>
                      </h4>
                    </a>
                  </li>

                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://www.nbcnews.com/specials/superfund-sites-climate-change/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/nbc-toxic.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        A growing toxic threat
                        <span className="featured-story-brand">NBC News</span>
                      </h4>
                    </a>
                  </li>

                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://interactives.stuff.co.nz/2020/06/unemployment-crisis-covid-economic-recovery/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/stuff-deep.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        Deep trouble
                        <span className="featured-story-brand">Stuff</span>
                      </h4>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="row section section-dark section-half section-trial !tw-bg-[rgba(249,249,249,0.3)]">
            <div className="container section-inner text-center">
              <h2 className="!tw-text-black">
                Ready to try the digital storytelling platform used by Honda, University of Cambridge, and the BBC?
              </h2>

              <div className="cta-container">
                <a href="https://app.shorthand.com/signup" className="btn btn-lg !tw-bg-black hover:!tw-opacity-70">
                  Start creating
                </a>
              </div>
              <div className="brands">
                <div className="brands-container">
                  <h3 className="as-used">Join these iconic brands</h3>
                  <ul>
                    {brands.map((brand, index) => (
                      <li key={`brand-${brand.node.label}-${index}`} className={`brand`}>
                        <img src={brand.node.logo} alt={`${brand.node.label} logo`} />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div aria-hidden="true" className="background-media background-media-fixed">
              <img src="/media/home/stories.jpg" className="ObjectFit--cover" alt="" />
            </div>
          </section>

          <Footer className="mt-0" />
        </main>
      </Layout>
    );
  }
}

export const query = graphql`
  query IndustriesMediaQuery {
    site {
      siteMetadata {
        name
        title
        tagline
        description
      }
    }
    allBrandsJson {
      edges {
        node {
          logo
          name
          label
        }
      }
    }
  }
`;
