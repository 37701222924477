import { useIsMobile } from "../../hooks/useIsMobile";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";

export const Stories = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    query FeatureStories {
      allFeaturedStoriesJson {
        nodes {
          title
          linkUrl
          imageUrl {
            alt
            src {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 600, height: 830, quality: 80)
              }
              publicURL
            }
          }
        }
      }
    }
  `);

  const isMobile = useIsMobile(792);
  const [renderedList] = React.useState(data.allFeaturedStoriesJson.nodes);

  if (!renderedList) return;

  // only show one item from renderedList list if mobile
  return (
    <div className="section-stories pb-0 !tw-mt-8">
      <div className="featured-stories">
        {isMobile ? (
          <ul className="!tw-p-0 !tw-shadow-none max-md:!tw-w-[80%]">
            {renderedList.map(story => (
              <li key={story.title} className="!tw-shadow-none">
                <a className="unstyled text-center" href={story.linkUrl} target="_blank" rel="noopener noreferrer">
                  <div className="featured-story-media">
                    <GatsbyImage
                      image={getImage(story.imageUrl.src)}
                      alt={story.imageUrl.alt}
                      className="list-item-transition tw-w-[100%]"
                    />
                  </div>
                  <div className="featured-story-title tw-bg-white  tw-bg-opacity-10 !tw-text-white">{story.title}</div>
                </a>
              </li>
            ))}
          </ul>
        ) : (
          <ul className="min-container:!tw-max-w-[85%]">
            {renderedList.map(story => (
              <li key={story.title} className="!tw-max-w-[30%] !tw-shadow-none">
                <a className="unstyled text-center" href={story.linkUrl} target="_blank" rel="noopener noreferrer">
                  <div className="featured-story-media ">
                    <GatsbyImage
                      image={getImage(story.imageUrl.src)}
                      alt={story.imageUrl.alt}
                      className="list-item-transition  tw-w-[100%]"
                      objectFit="contain"
                    />
                  </div>
                  <div className="featured-story-title !tw-h-full  tw-bg-white tw-bg-opacity-10 !tw-text-white">{story.title}</div>
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
