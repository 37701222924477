import { filterListByCurrentIndustryCode } from "../../Utils";
import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { RelatedCaseStudies } from "../../components/RelatedCaseStudies";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default function CaseStudy({ data, location }) {
  return (
    <Layout location={location}>
      <main id="main" className="marketing-content container-fluid">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-secondary body-marketing-case-studies hide-elevio",
          }}
        />
        <div className="row section-title">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto  section-inner">
            <h1>
              <span className="shorthand-logo">Shorthand</span>
              <span className="subsection-label">Case Study:</span>
              The Barbican
            </h1>
            <h2>How the Barbican Use Shorthand</h2>
          </div>
          <div aria-hidden="true" className="background-media">
            <img
              src="/media/case-studies/case-studies-barbican-title.jpg"
              data-object-fit="cover"
              data-autoscale-content-ratio="1.77777777778"
              alt=""
            />
          </div>
        </div>
        <div className="row section">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto marketing-main-column">
            <h3 className="intro">
              Using Shorthand, the Barbican produces innovative digital experiences, with a recent virtual exhibition garnering over
              10,000 online visitors.
            </h3>

            <div className="inline-image featured">
              <img
                src="/media/case-studies/case-studies-barbican.jpg"
                alt="Soundhouse: Intimacy and Distance, by Barbican, renders responsively across all devices"
              />
            </div>

            <p>
              The Barbican is a world-renowned performing arts centre in London, which traditionally hosts in-person concerts,
              screenings, exhibitions, and theatre performances.
            </p>

            <p>
              The content team at the Barbican &mdash; Content Editor Suzanne Zhang and Content Marketing Manager Rachel Williams
              &mdash; started using Shorthand after facing limitations with their content management system (CMS).
            </p>

            <p>
              “We began using Shorthand at a time when our old website and blog were not able to accommodate our ambitions,” Rachel
              says. These ambitions were “to place visually striking storytelling at the heart of what we do.”
            </p>

            <p>
              As Rachel explains, “We use our digital channels, including Shorthand, to tell the stories of our programme to our
              audiences in an engaging and accessible way, whether they are able to visit the Barbican or not. Just as we want people
              to enjoy spending time within our building, we want them to spend time with us (or at least our content) online.”
            </p>

            <h3 className="subheading">Scaling with a small team</h3>

            <figure className="block quote-block offset left">
              <blockquote>
                <p>
                  “Using Shorthand, we’re able to have complete control over creating stories without needing external developer
                  support.”
                </p>
              </blockquote>
              <figcaption className="quote-cite">Rachel Williams</figcaption>
            </figure>
            <p>
              Shorthand allows the Barbican team to be ambitious with their digital content. “We are a small content team of two at the
              Barbican &mdash; but we’re an ambitious two! We are always looking for the best way to present our work with our
              audiences at the centre.”
            </p>
            <p>
              Shorthand is key to the ability of the content team to scale their content production, without relying on developers.
            </p>
            <p>
              “Using Shorthand, we’re able to have complete control over creating stories without needing external developer support.
              This is a huge benefit for us, as much of our work is very fast paced, sometimes reactive and often requires us to work
              independently &mdash; so flexibility is key to this production process.”
            </p>

            <p>
              Rachel explains that, as the use of Shorthand has expanded, the platform “has now almost become a fully-fledged extension
              of our website. It is our go-to platform for creating the longform, multimedia stories that we know our audiences enjoy
              as a way to learn more and engage with the Barbican’s programme.”
            </p>

            <div className="case-study-video-container">
              <video className="case-study-video-inline" muted loop playsInline autoPlay>
                <source src="/media/case-studies/case-studies-barbican.mp4" type="video/mp4" />
              </video>
            </div>

            <h3 className="subheading">Growing content online</h3>

            <figure className="block quote-block offset right">
              <blockquote>
                <p>“Shorthand has been a key part of the Barbican’s digital content strategy for many years.”</p>
              </blockquote>
              <figcaption className="quote-cite">Rachel Williams</figcaption>
            </figure>

            <p>
              The team initially used Shorthand for longform editorial pieces tied to their exhibitions. “Previously, we would use
              Shorthand for our long-form editorial content – often essays or deep-dives into our programme: for example, a biography
              of an artist being presented in our art gallery, or an introduction to a particular music genre or film style.”
            </p>
            <p>
              “Shorthand has been a key part of the Barbican’s digital content strategy for many years,” Rachel explains. “Longform
              editorial is a fantastic way for us to engage with our audiences, and continues to be one of our most popular and engaged
              content types.”
            </p>
            <p>
              One example of the team’s excellent longform editorial pieces is on dancer Michael Clark, which accompanies the
              Barbican’s&nbsp;
              <a
                href="https://sites.barbican.org.uk/introducingmichaelclark/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                Michael Clark: Cosmic Dancer
              </a>
              &nbsp; exhibition. With stunning images from the entire course of Clark’s career, the piece is gorgeous, immersive, and
              hugely readable.
            </p>
            <p>
              In recent years, the content team has been gradually expanding its use of Shorthand beyond editorial features. New use
              cases include their online learning and community activities, and stunning photographs from their talented&nbsp;
              <a
                href="https://sites.barbican.org.uk/collection-instagrammers/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                Instagrammers in Residence
              </a>
              . Today, the Barbican are producing some of the most innovative digital stories on the web.
            </p>
            <p>
              As their use of Shorthand has expanded, the content team has streamlined its publishing processes. “We have used
              Shorthand’s functionality to create a branded template to match the Barbican identity you see on our website and other
              online materials. We’ve also utilised one-click publishing to make it easier and smoother for us to create stories on
              demand.”
            </p>

            <div className="photo-grid flex featured">
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-barbican-sml-01.jpg"
                alt="A section from one of Barbican's stories"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-barbican-sml-02.jpg"
                alt="A section from one of Barbican's stories"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-barbican-sml-04.jpg"
                alt="A section from one of Barbican's stories"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-barbican-sml-03.jpg"
                alt="A section from one of Barbican's stories"
              />
            </div>
            <div className="caption">A selection of sections from The Barbican's stories</div>

            <h3 className="subheading">The making of Soundhouse: Intimacy and Distance</h3>

            <p>
              In 2020, the team faced a new challenge &mdash; bringing an intimate physical event into the digital space when the audio
              exhibition, Soundhouse: Intimacy & Distance was cancelled due to the pandemic.
            </p>
            <p>
              The original Soundhouse was a pop-up venue in the Barbican. "It was like a cinema for audio, and the initial plan was to
              do this again in 2020,” Rachel says. “However, with the Barbican closed due to the pandemic, we began to explore how to
              transform Soundhouse, a platform for creative radio and podcasting, into an online experience.”
            </p>
            <p>
              The team wanted to find a way to transform a typically solitary activity &mdash; such as listening to a podcast &mdash;
              into something that felt public and communal. The result was a series of three digital ‘listening rooms’, each featuring
              a radio player with audio and podcasts selected by a curator, a track list, and an introduction from the curator.
            </p>
            <p>
              “We wanted these rooms to feel like distinct spaces. As we mapped out the user journey, we imagined these rooms as pages,
              all created on Shorthand to allow us to use full bleed images and videos, subtle transitions and embedded media. This
              wasn’t possible on our website, and would have required significant developer time to build from scratch.”
            </p>

            <h3 className="subheading">“A fantastic success”</h3>

            <figure className="block quote-block offset left">
              <blockquote>
                <p>
                  “Feedback from audiences has been fantastic, with comments from all over the world sharing how they have been
                  enjoying the Soundhouse experience.”
                </p>
              </blockquote>
              <figcaption className="quote-cite">Rachel Williams</figcaption>
            </figure>

            <p>
              How did the project fare? According to Rachel, the{" "}
              <a href="https://sites.barbican.org.uk/soundhouse/" target="_blank" rel="noopener noreferrer" className="btn-link">
                Soundhouse project
              </a>
              &nbsp; has been “a fantastic success.”
            </p>
            <p>
              “We’ve seen over 10,000 people engage with Soundhouse, with listeners tuning in from around the world, including the
              United States, Canada, Japan, France, Italy, Germany and Australia.”
            </p>
            <p>
              The content team were delighted to be able to find a wide audience &mdash; something which Rachel notes would not have
              been possible with an on-site exhibition.
            </p>
            <p>
              “Being able to reach an international audience has been a wonderful result from this year’s Soundhouse, and something we
              wouldn’t have been able to do so widely in a physical installation at the Barbican.”
            </p>
            <p>
              The project has not only performed well on its metrics; the team have also received “wonderful feedback” from the
              audience, producers, and curators. “Feedback from audiences has been fantastic, with comments from all over the world
              sharing how they have been enjoying the Soundhouse experience, wherever they are.”
            </p>
            <p>
              The project was picked up by <em>The Guardian</em>, who awarded it one of their top picks in their ‘Hear Here’ column,
              saying that the project was “something that does work in our socially-distanced world… It is, finally, art as it was
              meant to be experienced.”
            </p>

            <h3 className="subheading">Looking ahead</h3>

            <p>
              With the success of Soundhouse, the Barbican team are looking at more ambitious projects to bring to life with Shorthand.
            </p>
            <p>
              “We are currently exploring how we can use Shorthand to present a poetry showcase &mdash; another project which should
              have been an on-site event and now brought online. This will be another multimedia collection and another navigation
              issue to solve!”
            </p>
            <p>
              The team is also keen to use Shorthand to bring educational resources online, without publishing them as PDFs. “We are
              continuing to explore how we can best present learning resources online to try and cut down on our use of PDFs and make
              our content more accessible.”
            </p>
            <div className="related-stories">
              <h4>More from the Barbican:</h4>
              <ul>
                <li>
                  <a
                    href="https://sites.barbican.org.uk/introducingmichaelclark/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-link"
                  >
                    Michael Clark: Cosmic Dancer exhibition
                  </a>
                </li>
                <li>
                  <a href="https://sites.barbican.org.uk/soundhouse/" target="_blank" rel="noopener noreferrer" className="btn-link">
                    Soundhouse
                  </a>
                </li>
                <li>
                  <a
                    href="https://sites.barbican.org.uk/justynaszymanska/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-link"
                  >
                    Instagrammer in Residence: Justyna Szymańska
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <RelatedCaseStudies studies={filterListByCurrentIndustryCode(data, "barbican")} />
        <Footer />
      </main>
    </Layout>
  );
}

export const query = graphql`
  query barbicanQuery {
    allCaseStudiesJson {
      edges {
        node {
          jsonId
          image
          industry
          industryCode
          organisation
          summary
        }
      }
    }
  }
`;
