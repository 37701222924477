import * as toolTipStyles from "../FeaturesMatrix/LabelCell.module.scss";
import * as styles from "./DiscountSwitch.module.scss";
import * as Switch from "@radix-ui/react-switch";
import * as Tooltip from "@radix-ui/react-tooltip";
import cx from "classnames";
import * as React from "react";

type DiscountSwitchProps = {
  id: string;
  label: string;
  value: boolean;
  labelPosition?: "left" | "right";
  handleFunction: (value: boolean) => void;
};
export const DiscountSwitch = (props: DiscountSwitchProps): JSX.Element => {
  const { id, label, labelPosition, handleFunction, value } = props;

  return (
    <div>
      <Tooltip.Provider delayDuration={800} skipDelayDuration={500}>
        <Tooltip.Root delayDuration={0}>
          <Tooltip.Trigger asChild>
            <div className={cx(styles.switchWrapper, { [styles.rowReverse]: labelPosition === "left" })}>
              <Switch.Root className={styles.switchRoot} id={id} checked={value === true} onCheckedChange={handleFunction}>
                <Switch.Thumb className={styles.switchThumb} />
              </Switch.Root>
              <label className="Label" htmlFor={id}>
                {label}
              </label>
            </div>
          </Tooltip.Trigger>
          <Tooltip.Content className={toolTipStyles.TooltipContent}>
            Registered not-for-profits that are non-governmental, non-commercial, and non-political save 40% on the price of Team,
            Business and Enterprise subscriptions when paying yearly.
            <Tooltip.Arrow className={toolTipStyles.TooltipArrow} />
          </Tooltip.Content>
        </Tooltip.Root>
      </Tooltip.Provider>
    </div>
  );
};
