import * as styles from "./Cell.module.scss";
import { Dash } from "./Dash";
import { Tick } from "./Tick";
import * as React from "react";

type CellValue = boolean | null | string | number;
type CellProps = { cellValue?: CellValue; planName: string };

export const Cell = (props: CellProps): JSX.Element => {
  const renderValue = () => {
    const property = props.cellValue;
    if (property === true) return <Tick planName={props.planName} />;
    if (property === null || property === false) return <Dash />;
    return property?.toString() ?? "";
  };

  return <div className={styles.cellWrapper}>{renderValue()}</div>;
};
