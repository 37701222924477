import { INav, INavBarProps } from "./";
import cx from "classnames";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

export function Desktop(props: INavBarProps): JSX.Element {
  const [state, setState] = React.useState<{
    current: null | string;
    scrolled: boolean;
  }>({
    current: null,
    scrolled: false,
  });

  const { brand, theme, className, disabled } = props;

  React.useEffect(() => {
    function onClick(): void {
      setState(s => ({ ...s, current: null }));
    }

    window.addEventListener("click", onClick);
    return () => {
      window.removeEventListener("click", onClick);
    };
  }, []);

  const SCROLLBREAK = 200;
  const isClient = (): boolean | Document => typeof window !== "undefined" && window.document;
  React.useEffect(() => {
    function onScroll(): void {
      if (isClient()) {
        const offset = window.pageYOffset;
        const breakpoint = props.scrolled || SCROLLBREAK;
        const isScrolled = state.scrolled;
        if (isScrolled && offset < (breakpoint as number)) {
          setState(s => ({ ...s, scrolled: false }));
        } else if (!isScrolled && offset > (breakpoint as number)) {
          setState(s => ({ ...s, scrolled: true }));
        }
      }
    }

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [props.scrolled, state.scrolled]);

  function onToggle(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string): void {
    e.stopPropagation();
    state.current === id ? setState({ ...state, current: null }) : setState({ ...state, current: id });
  }

  function renderNavigation(): JSX.Element {
    const { navs } = props;
    const leftNav = !Array.isArray(navs) ? navs.left : navs;
    const rightNav = !Array.isArray(navs) ? navs.right : [];
    return (
      <div className={"navbar-nav"}>
        {leftNav.map(renderNav)}
        {rightNav && <div className={"navbar-right clearfix"}>{rightNav.map(renderNav)}</div>}
      </div>
    );
  }

  function renderNav(item: INav): JSX.Element {
    if (item.render) {
      return item.render(item, state);
    }
    if (item.icon) {
      return renderIconItem(item);
    }
    if (item.dropdown) {
      return renderDropdown(item);
    }

    const navItemClasses = cx("nav-item", "nav-link", {
      disabled: item.disabled,
      [item.className]: item.className,
      [item.linkClassName]: item.linkClassName,
    });

    const key = `nav-item-${item.id || item.title}`;

    if (item.isExternal) {
      return (
        <a key={key} className={navItemClasses} href={item.path} target={item.target}>
          {item.title}
        </a>
      );
    }

    return (
      <Link key={key} to={item.path} onClick={item.onClick} className={navItemClasses}>
        {item.title}
      </Link>
    );
  }

  function renderIconItem(item: INav): JSX.Element {
    if (item.dropdown) {
      return renderDropdown(item);
    }

    return (
      <Link
        key={`nav-item-${item.id || item.title}`}
        to={item.path}
        className={cx("nav-item", "nav-link", {
          active: item.id === state.current || item.id === props.current,
          disabled: item.disabled,
          [item.className]: item.className,
          [item.linkClassName]: item.linkClassName,
        })}
      >
        <i className={`fa fa-${item.icon}`} />
        {item.title}
      </Link>
    );
  }

  function renderDropdown(item: INav): JSX.Element {
    return (
      <div
        key={`nav-item-${item.id || item.title}-dropdown`}
        className={cx("nav-item", "dropdown", {
          open: state.current === item.id,
          [item.className]: item.className,
        })}
      >
        {item.render ? (
          item.render()
        ) : (
          <button
            className={"nav-link dropdown-toggle nav-item"}
            onClick={e => {
              onToggle(e, item.id);
            }}
            tabIndex={-1}
          >
            {item.icon && <i className={`fa fa-${item.icon}`} />}
            {item.title}
          </button>
        )}
        <div className={"dropdown-menu"}>{item.dropdown.map(renderDropdownItem)}</div>
      </div>
    );
  }

  const getItemChildren = (navItem: INav) => {
    return (
      <>
        {navItem.icon && <i className={`fa fa-${navItem.icon}`} />}
        {navItem.title}
        <p id={`description-id-${navItem.id || navItem.title}`}>{navItem.description}</p>
      </>
    );
  };

  const sharedProps = (item: INav) => {
    return {
      "aria-describedby": `description-id-${item.id || item.title}`,
      key: `nav-item-${item.id || item.title}`,
      className: "dropdown-item nav-link",
      onClick: item.onClick,
    };
  };

  function renderDropdownItem(item: INav): JSX.Element {
    if (item.render) {
      return item.render();
    }

    if (item.isExternal) {
      return <a {...{ ...sharedProps(item), href: item.path, id: "external-link" }}>{getItemChildren(item)}</a>;
    }
    return <Link {...{ ...sharedProps(item), to: item.path }}>{getItemChildren(item)}</Link>;
  }

  const switchFromDarkToLightTheme = theme === "transparent-dark-theme" && state.scrolled;

  const showLightTheme = theme === "light-theme" || switchFromDarkToLightTheme;

  const themeClass = cx(
    { [theme]: theme !== "transparent-dark-theme" },
    { [theme]: theme === "transparent-dark-theme" && !state.scrolled },
    { "light-theme": switchFromDarkToLightTheme }
  );

  const navClasses = cx("navbar", "navbar-desktop", "navbar-fixed-top", themeClass, {
    [className]: className,
    disabled: disabled,
  });

  return (
    <nav className={navClasses} role="navigation" aria-label="navigation menu">
      <Link
        to={brand.path}
        onClick={brand.onClick}
        className="nav-logo-link tw-mr-[15px] tw-border-r tw-border-r-[#fff]/10 tw-pr-[20px]"
        aria-label="Shorthand homepage"
      >
        {showLightTheme ? (
          <StaticImage
            src="../../../static/media/logos/logo-black-horiz.svg"
            alt="Shorthand Logo"
            aria-hidden="true"
            className="nav-logo tw-w-[120px]"
            placeholder="blurred"
          />
        ) : (
          <StaticImage
            src="../../../static/media/logos/shorthand-white_logo_horizontal.svg"
            alt="Shorthand Logo"
            aria-hidden="true"
            className="nav-logo-white tw-w-[120px]"
            placeholder="blurred"
          />
        )}
      </Link>

      {props.children}
      {renderNavigation()}
    </nav>
  );
}
