import jwt from "jsonwebtoken";

export function getAuthToken(): any {
  const windowGlobal = typeof window !== "undefined" && window;
  if (windowGlobal) {
    return windowGlobal.localStorage.getItem("token");
  }
  return null;
}

export function getAuthCredentials(): any {
  const token = getAuthToken();
  if (token) {
    return jwt.decode(token);
  }
  return null;
}

export function getHostname(sub: string, forceInsecure?: boolean): string {
  const { hostname } = window.location;
  let { protocol } = window.location;
  if (sub === "preview" && forceInsecure) {
    protocol = "http:";
  }
  if (hostname === "localhost" && sub === "api") {
    return `${protocol}//app.dylan.local/api`;
  }
  if (hostname === "localhost" && sub === "api") {
    return `${protocol}//localhost:8370/api`;
  }
  const split = hostname.split(".");
  if (split.length === 2) {
    return `${protocol}//${sub}.${hostname}`;
  } else {
    // This isn't super elegant, but it's explicit and simple
    if (sub === "api") {
      if (hostname === "staging.shorthand.com") {
        return `${protocol}//staging-api.shorthand.com`;
      } else {
        // Note: Change this to your test branch and deploy to the Gatsby test branch to change where Gatsby sends Contact/Webinar API calls
        return `${protocol}//staging-api.shorthand.com`;
      }
    }
  }
}
