export interface IFeatures {
  "page-settings-password-protect-previews": boolean;
  "story-envelope-no-metadata": boolean;
  "page-settings-always-show-publish-url": boolean;
  "story-amp": boolean;
  "media-cyclops": boolean;
  "organisation-is-bbc": boolean;
  "organisation-secure-previews": boolean;
  "page-settings-custom-colors": boolean;
  "publish-configurations-scorm": boolean;
  "akamai-netstorage-hosting-enabled": boolean;
  "publish-shadow-embed": boolean;
  "early-access-program": boolean;
  "custom-hosting-limit": number;
  "developer-tools": boolean;
  "hide-preview-feedback-footer": boolean;
  "iframely-external-cmp-control": boolean;
  "iframely-data-src": boolean;
  "internal-access-program": boolean;
  "organisation-collaboration": boolean;
  "organisation-manage-publish-configurations": boolean;
  "organisation-member-limit": number;
  "organisation-team-leader-view": boolean;
  "organisation-team-limit": number;
  "organisation-team-member-limit": number;
  "page-settings-amp-enabled": boolean;
  "page-settings-analytics-ga": boolean;
  "page-settings-analytics-gtm": boolean;
  "page-settings-custom-head": boolean;
  "page-settings-html-assets": boolean;
  "page-settings-override-social-cover-url": boolean;
  prime3: boolean;
  "preview-banner": boolean;
  "publish-configurations-download": boolean;
  "publish-configurations-pull": boolean;
  "publish-embed-ui": boolean;
  "s3-hosting-enabled": boolean;
  "sftp-hosting-enabled": boolean;
  "shorthand-analytics-tracking": boolean;
  "shorthand-hosting-custom-domains-enabled": boolean;
  "shorthand-hosting-enabled": boolean;
  "story-credits": number;
  "story-credits-required": boolean;
  "story-ga": boolean;
  "story-hide-footer": boolean;
  "story-promotional-footer": boolean;
  "story-publish-json": boolean;
  "story-publish-segments": boolean;
  "story-section-limit": number;
  "template-background-scrollmation": boolean;
  "template-collection": boolean;
  "template-grid": boolean;
  "template-media": boolean;
  "template-reveal": boolean;
  "template-scrollmation": boolean;
  "template-text": boolean;
  "template-text-over-media": boolean;
  "template-title": boolean;
  "themes-basic": boolean;
  "themes-demo": boolean;
}

export interface IMarketing {
  label: string;
  className: string;
  benefits: string[];
  prices?: IPrice[];
}

export interface IPrice {
  currency: string;
  monthly: number;
  yearly: number;
}

export interface IPlan {
  id: string;
  name: string;
  description: string;
  chargebee?: Set<string>;
  features: Partial<IFeatures>;
  marketing?: IMarketing;
}

export const mockFeatures = {
  "organisation-manage-publish-configurations": true,
  "organisation-team-leader-view": true,
  "organisation-team-limit": 1,
  "page-settings-amp-enabled": false,
  "page-settings-analytics-ga": true,
  "page-settings-override-social-cover-url": true,
  "shorthand-analytics-tracking": true,
  "story-credits-required": false,
  "story-ga": true,
  "themes-demo": false,
};
export const starter: IPlan = {
  id: "starter",
  name: "Starter",
  description: "Unlimited Users. \nIncludes 1 live story. \nAdditional live stories $1/month",

  features: mockFeatures,
  marketing: {
    label: "Discover courageous content creation",
    className: "starter",
    benefits: ["Core Section Types", "TeamCollaboration", "Shorthand Analytics", "Shorthand hosting and embed publishing"],
    prices: [
      { currency: "USD", monthly: 0, yearly: 0 },
      { currency: "AUD", monthly: 0, yearly: 0 },
      { currency: "GBP", monthly: 0, yearly: 0 },
      { currency: "EUR", monthly: 0, yearly: 0 },
    ],
  },
};

export const pro: IPlan = {
  id: "pro",
  name: "Pro",
  description: "Per active user. Minimum of one active user. Includes 3 published stories. Additional published stories: $10/month.",

  features: mockFeatures,
  marketing: {
    label: "Premium publishing at an introductory price",
    className: "pro",
    benefits: [
      "Create your own themes",
      "Video background media",
      "Video media section",
      "Collection sections",
      "Save section templates",
      "Copy stories",
      "Media asset library",
      "Download story archives in zip format",
      "Email support",
      "Includes 100k views per month",
    ],
    prices: [
      { currency: "USD", monthly: 30, yearly: 360 },
      { currency: "AUD", monthly: 30, yearly: 360 },
      { currency: "GBP", monthly: 30, yearly: 360 },
      { currency: "EUR", monthly: 30, yearly: 360 },
    ],
  },
};

export const business: IPlan = {
  id: "business",
  name: "Business",
  description:
    "Per active user. Minimum of one active user. Includes 5 published stories. Additional published stories: $25/month for the next 50 stories then $5/month.",

  features: mockFeatures,
  marketing: {
    label: "The perfect digital content solution for most orgs",
    className: "pro",
    benefits: [
      "Custom HTML section",
      "Make bespoke changes to your stories using CSS and JavaScript developer tools",
      "Integrate 3rd-party analytics like Google Analytics, Chartbeat and Mixpanel",
      "Control editing, reviewing and publishing permissions",
      "Password-protected story previews",
      "Team onboarding",
      "Have your first story reviewed by an expert Editorial Success Manager",
      "Includes 5 published stories",
      "Includes 5 team spaces",
      "Includes 250k views per month",
    ],
    prices: [
      { currency: "USD", monthly: 150, yearly: 1800 },
      { currency: "AUD", monthly: 150, yearly: 1800 },
      { currency: "GBP", monthly: 150, yearly: 1800 },
      { currency: "EUR", monthly: 150, yearly: 1800 },
    ],
  },
};

export const enterprise: IPlan = {
  id: "enterprise",
  name: "Enterprise",
  description: "Tailored to your needs",
  features: mockFeatures,
  marketing: {
    label: "Enterprise-grade controls, security & SLAs",
    className: "enterprise",
    benefits: [
      "A hand-crafted branded theme",
      "Enable Single Sign On using SAML",
      "SCIM - System for cross-domain identity management",
      "Have your first three stories reviewed by an expert Editorial Success Manager",
      "Export story analytics in CSV format for advanced analysis",
      "Team leader view",
      "Tailored Service Level Agreement",
      "Priority technical support",
      "Email, phone & Zoom support",
      "Custom published story limit",
      "Custom publishing destination limit",
      "Custom team space limit",
      "Custom views per month limit",
      "No Shorthand footer watermark",
    ],
    prices: [],
  },
};

export const HEADLINES = {
  marketing: "Features you'll love:",
  starter: "Starter features, plus:",
  business: "Team features plus:",
  enterprise: "Business features plus:",
};
export const FREE_TRIAL = "request free trial";
export const CONTACT_SALES_CTA = "contact sales";
export const CONTACT_SALES = "Contact sales for unlimited publishing and other increased limits";
