import { filterListByCurrentIndustryCode } from "../../Utils";
import { Footer } from "../../components/Footer";
import { LayoutNew } from "../../components/LayoutNew";
import { RelatedCaseStudies } from "../../components/RelatedCaseStudies";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default function CaseStudy({ data, location }) {
  return (
    <LayoutNew location={location} data={data}>
      <div id="main" className="marketing-content container-fluid">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-secondary body-marketing-case-studies hide-elevio",
          }}
        />
        <div className="row section-title">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6 mx-auto section-inner">
            <h1>
              <span className="shorthand-logo">Shorthand</span>
              Smarthinking Inc.
            </h1>
            <h2>How Smarthinking Inc. uses Shorthand to achieve overwhelming success for their clients.</h2>
          </div>
          <div aria-hidden="true" className="background-media">
            <img
              src="/media/case-studies/case-studies-smarthinking-blur.png"
              data-object-fit="cover"
              data-autoscale-content-ratio="1.77777777778"
              alt=""
            />
          </div>
        </div>
        <div className="row section">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto marketing-main-column">
            <h2 className="intro">How does a brand marketing agency achieve overwhelming success for their clients?</h2>
            <div className="inline-image featured">
              <img alt="" src="/media/case-studies/case-studies-smarthinking.png" />
            </div>
            <p>
              <a href="https://www.smarthinkinginc.com/" target="_blank" className="btn-link" rel="noopener noreferrer">
                Smarthinking Inc.
              </a>{" "}
              is a brand marketing agency located in Miami, Florida, that specialises in building brands for luxury real estate,
              boutique hospitality, and private clubs.
            </p>
            <p>
              For the last two years, Smarthinking Inc. has been using Shorthand to create beautiful content for their clients — with
              exceptional results.
            </p>
            <p>
              We chatted with Mariajose Mercado, Marketing Content Coordinator at Smarthinking Inc. to learn about the secrets behind
              their agency’s success.
            </p>
            <h3 className="subheading after:!tw-bg-[#D9D9D9]">Endless possibilities</h3>
            <figure className="block quote-block offset left">
              <blockquote className="!tw-text-[#303030] !tw-opacity-70">
                <p>
                  “We started using Shorthand to tell compelling stories that immerse readers in an unforgettable experience with
                  us...”
                </p>
              </blockquote>
              <figcaption className="quote-cite !tw-text-[#333333]">Mariajose Mercado</figcaption>
            </figure>
            <p>
              Smarthinking Inc. has been working for 16 years to build remarkable brands around the world. Throughout its history, the
              agency has strived to differentiate itself from others in the market.
            </p>
            <p>
              As Mariajose explains, this was why the team started using Shorthand. “We started using Shorthand to tell compelling
              stories that immerse readers in an unforgettable experience with us. We quickly learned that Shorthand allowed us to
              differentiate ourselves and our clients from other competitors in the digital space.”
            </p>
            <p>
              Shortly after achieving their original goal, the team realised that there were many more use cases for Shorthand, which
              meant many more ways to get results for their clients.
            </p>
            <p>
              “When we started digging in, we realised that the possibilities were endless. Whether showcasing photos, film, or copy,
              we are now able to spin up captivating web content.”
            </p>
            <p>
              The team has even started using Shorthand for print campaigns. Mariajose explains that they have been using QR codes,
              which “allow readers to dive into a journey alongside us. They can go so much deeper into the campaign.”
            </p>
            <p>
              “Whether it's with our blogs or newsletters or emails and so forth, there's really no limitation to using Shorthand.”
            </p>

            <div className="photo-grid flex featured">
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/smarthinking-01.jpg" />
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/smarthinking-02.jpg" />
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/smarthinking-03.jpg" />
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/smarthinking-04.jpg" />
            </div>
            <div className="caption">A selection of sections from Smarthinking Inc’s content</div>

            <h3 className="subheading after:!tw-bg-[#D9D9D9]">Limited only by imagination</h3>
            <figure className="block quote-block offset right">
              <blockquote className="!tw-text-[#303030] !tw-opacity-70">
                <p>
                  “Shorthand’s only limitation seems to reside in our lack of imagination. With the full suite of tools and effects, we
                  are constantly impressed with what we can achieve. Shorthand has become a vital tool that we need to properly tell
                  our stories.”
                </p>
              </blockquote>
              <figcaption className="quote-cite !tw-text-[#333333]">Mariajose Mercado</figcaption>
            </figure>
            <p>Fast forward to today, and the Smarthinking Inc. team uses Shorthand for many impressive campaigns. </p>
            <p>
              As Mariajose puts it, Shorthand is a critical part of their marketing strategy for building engagement for their clients.
              “Quickly making engaging and effective landing pages? Check. Taking the social media audience down a riveting rabbit
              hole? Check. Working with your team on new training initiatives? Check.”
            </p>
            <p>
              One recent example is Smarthinking Inc.’s campaign with{" "}
              <a
                href="https://whitetailclub.shorthandstories.com/introducing-mountain-time-from-the-design-build-collection/index.html"
                target="_blank"
                className="btn-link"
                rel="noopener noreferrer"
              >
                Whitetail Club
              </a>
              . Using an impressive mix of multimedia assets and Shorthand features, such as Scrollpoints, the story brings to life the
              astonishing mountain homes buyers can build at the award-wining private club.
            </p>
            <p>
              “Shorthand’s only limitation seems to reside in our lack of imagination,” Mariajose says. “With the full suite of tools
              and effects, we are constantly impressed with what we can achieve. Shorthand has become a vital tool that we need to
              properly tell our stories.”
            </p>

            <h3 className="subheading after:!tw-bg-[#D9D9D9]">Astronomical results</h3>

            <figure className="block quote-block offset left">
              <blockquote className="!tw-text-[#303030] !tw-opacity-70">
                <p>
                  “Our clients are extremely happy to see all the positive outcomes and feedback we've received on our Shorthand
                  content. It's overwhelming to see all the accomplishments we've been able to achieve using Shorthand.”
                </p>
              </blockquote>
              <figcaption className="quote-cite !tw-text-[#333333]">Mariajose Mercado</figcaption>
            </figure>
            <p>So, Smarthinking Inc. is creating exceptional content with Shorthand. But what about the outcomes for their clients?</p>
            <p>
              According to Mariajose, the results have been consistently impressive. “Our clients are extremely happy to see all the
              positive outcomes and feedback we've received on our Shorthand content. It's overwhelming to see all the accomplishments
              we've been able to achieve using Shorthand.”
            </p>
            <p>
              This includes more than just feedback. The Smarthinking Inc. team have been able to garner thousands of views of their
              client’s content, with astonishing completion rates. As Mariajose says, “The average time spent per user on a page has
              been astronomical. We're super excited to see how we can continue pushing the envelope with Shorthand!”
            </p>
            <p>The feeling is mutual. We look forward to many more years of astronomical results for Smarthinking Inc.’s clients.</p>
          </div>
        </div>
        <RelatedCaseStudies studies={filterListByCurrentIndustryCode(data, "smarthinking")} />
        <Footer />
      </div>
    </LayoutNew>
  );
}

export const query = graphql`
  query smarthinkingQuery {
    allCaseStudiesJson {
      edges {
        node {
          jsonId
          image
          industry
          industryCode
          organisation
          summary
        }
      }
    }
  }
`;
