import { Theme } from ".";
import cx from "classnames";
import { Link } from "gatsby-link";
import * as React from "react";

const FocusTrap = require("focus-trap-react");

type NavItem = {
  title?: string | (() => void);
  path?: string;
  isExternal?: boolean;
  dropdown?: any[];
  onClick?: () => null;
  render?: () => null;
  id?: string;
  className?: string;
  target?: string;
};

type IBrand = { onClick?: () => void; path: string; title: string };

interface INavBarMobileProps extends Theme {
  brand?: IBrand;
  className?: string;
  navs?: any;
}

export function Mobile(props: INavBarMobileProps): JSX.Element {
  const [isOpen, setIsOpen] = React.useState(false);

  const { navs, className, brand, theme } = props;

  React.useEffect(() => {
    function close(e: KeyboardEvent) {
      if (e.key === "Escape") {
        onClose();
      }
    }

    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  function generateClasses() {
    return {
      nav: cx("navbar", "navbar-fixed-top", "navbar-mobile", {
        ["dark-theme"]: theme === "dark-theme",
        "navbar-mobile-open": isOpen,
        "navbar-mobile": !isOpen,
        [className]: className,
      }),
      brand: "navbar-brand-mobile",
      close: "navbar-close",
      toggler: "navbar-toggler",
      togglerIcon: "navbar-toggler-icon",
    };
  }

  function onOpen() {
    setIsOpen(true);
  }

  function onClose() {
    setIsOpen(false);
  }

  function renderButton(classes) {
    return (
      <button
        className={classes.toggler}
        style={{ cursor: "pointer" }}
        onClick={onOpen}
        aria-expanded={isOpen}
        aria-label="Open navigation"
      >
        <i className="fa fa-bars" aria-hidden={true} />
      </button>
    );
  }

  const classes = generateClasses();
  return (
    <nav className={classes.nav} role="navigation" aria-label="navigation menu">
      {renderButton(classes)}
      {isOpen && <Navigation onClose={onClose} classes={classes} navs={navs} brand={brand} />}
    </nav>
  );
}

interface INavigationProps {
  classes: { close: string; brand: string };
  onClose: () => void;
  brand?: { onClick?: () => void; path: string; title: string };
  navs?: { left: NavItem[]; right: NavItem[] };
}

function Navigation(props: INavigationProps) {
  const { brand, classes, navs, onClose } = props;
  const leftNav = !Array.isArray(navs) ? navs.left : navs;
  const rightNav = !Array.isArray(navs) ? navs.right : [];
  const navArray = leftNav.concat(rightNav);

  function onClick() {
    if (brand?.onClick) {
      brand.onClick();
    }
    onClose();
  }

  return (
    <FocusTrap focusTrapOptions={{ allowOutsideClick: true }}>
      <div role="dialog" aria-modal="true" aria-label="navigation">
        <button key={"nav-button"} className={classes?.close} onClick={onClose} aria-label="Close navigation">
          <i className="fa fa-times" />
        </button>
        {brand && (
          <Link key={"nav-link"} to={brand.path} onClick={onClick} className={classes?.brand}>
            <img src="/media/logos/logo-white.svg" alt="Shorthand Logo" />
          </Link>
        )}
        {navArray.map(navItem => (
          <NavItem nav={navItem} onClose={onClose} key={navItem.title} />
        ))}
      </div>
    </FocusTrap>
  );
}

function NavItem(props: { nav: NavItem; onClose: () => void }): JSX.Element {
  const { nav, onClose } = props;

  if (nav.render) {
    return nav.render();
  }

  if (nav.dropdown) {
    return (
      <>
        {nav.dropdown.map(item => (
          <NavItem nav={item} onClose={onClose} />
        ))}
      </>
    );
  }

  function onClick() {
    if (nav.onClick) {
      nav.onClick();
    }
    onClose();
  }

  if (nav.isExternal) {
    return (
      <a key={`nav-item-${nav.id || nav.title}`} className={nav.className} href={nav.path} target={nav.target} onClick={onClick}>
        <>{nav.title}</>
      </a>
    );
  }

  return (
    <Link key={`nav-item-${nav.id || nav.title}`} className={nav.className} to={nav.path} onClick={onClick}>
      <>{nav.title}</>
    </Link>
  );
}
