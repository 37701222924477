export { BackButton } from "./BackButton";
export { BlackBg } from "./BlackBg";
export { FlexibleTextDisplay, VisualHeading } from "./FlexibleTextDisplay";
export { HeroContent } from "./HeroContent";
export { IconsRow, IconsRowVertical } from "./IconsRow";
export { MobileView } from "./MobileView";
export { MoreTemplates } from "./MoreTemplates";
export { Pill } from "./Pill";
export { SharedLayout } from "./SharedLayout";
export { StickyToolBar } from "./StickyToolBar";
export { TabletDesktopView } from "./TabletDesktopView";
export { TemplateDescription } from "./TemplateDescription";
export { TemplateTile } from "./TemplateTile";
export { TemplatesFAQ } from "./TemplatesFAQ";
export { Testimonials } from "./Testimonials";
export const FULL_WIDTH_STYLES = "tw-flex tw-flex-wrap !tw-w-[100%] !tw-justify-center !tw-pt-[36px] max-sm:!tw-pt-4";

export const COLUMN_STYLES =
  "max-sm:!tw-w-[83%] max-container:!tw-w-[64%] max-xxxxl:!tw-w-[64%] tw-flex tw-flex-col text-center justify-center";

export const CONTAINER_STYLES =
  "!tw-w-[100%] relative z-2 tw-flex max-xl:tw-pt-[50px] tw-justify-center !tw-flex-col !tw-items-center ";
