import { Footer } from "../components/Footer";
import { Layout } from "../components/Layout";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { Helmet } from "react-helmet";

export default class Features extends React.Component<any, any> {
  render() {
    return (
      <Layout location={this.props.location}>
        <main id="main" className="marketing-content container-fluid">
          <Helmet
            bodyAttributes={{
              class: "body-shorthand body-marketing body-marketing-secondary body-marketing-thankyou hide-elevio",
            }}
          />
          <div className="row section-title">
            <div className="col-11 col-md-8  mx-auto section-inner">
              <h1>
                <Link to="/" className="shorthand-logo">
                  Shorthand
                </Link>
                Welcome, thanks for signing up.
              </h1>
              <h2>
                You can look forward to a biweekly email of stunning Shorthand stories, as well as our guides, interviews, and ideas
                for content creators.
              </h2>
              <div className="cta-container">
                <a href="/the-craft" className="btn btn-lg btn-inverse btn-secondary">
                  Go back to <em>The Craft</em>
                </a>
                <a href="https://app.shorthand.com/signup" className="btn btn-lg btn-primary">
                  Try Shorthand for free
                </a>
              </div>
            </div>

            <div aria-hidden="true" className="background-media background-media-fixed">
              <StaticImage
                src="../../static/media/thankyou/thankyou-bg.jpg"
                className="ObjectFit--cover"
                alt=""
                aria-hidden="true"
                placeholder="blurred"
                layout="fixed"
                width={2560}
                height={1440}
              />
              <div className="background-overlay" />
            </div>
            <Footer className="footer-dark" />
          </div>
        </main>
      </Layout>
    );
  }
}
