import { Enquiry } from "../Enquiry";
import { FAQAccordion } from "../FAQ/FAQAccordion";
import { Theme } from "../NavBar";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { twMerge } from "tailwind-merge";

export const TemplatesFAQ = (props: Theme): JSX.Element => {
  const [showPortal, setShowPortal] = React.useState(false);

  const enquiryPortalProps = {
    type: "general-enquiry",
    subject: "general-enquiry",
    source: "marketing/home",
    onClose: () => setShowPortal(false),
  };

  const themeClasses = props.theme === "dark-theme" ? "tw-bg-black" : "tw-bg-white";
  const buttonClasses = props.theme === "dark-theme" ? "!tw-text-white hover:!tw-text-black" : "tw-text-black  ";
  return (
    <div>
      {showPortal && ReactDOM.createPortal(<Enquiry {...enquiryPortalProps} />, document.getElementById("modal") as HTMLElement)}

      <FAQAccordion theme={props.theme} heading="More about Shorthand" questionGroup="templates" />

      <div className={twMerge("tw-flex tw-justify-center  tw-p-[40px]", themeClasses)}>
        <button className={twMerge("btn btn-secondary mx-auto", buttonClasses)} onClick={() => setShowPortal(true)}>
          More questions? Contact us!
        </button>
      </div>
    </div>
  );
};
