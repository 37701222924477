import { colorMap } from "../PricingPlan/ColorBar/ColorBar";
import * as React from "react";

type TickProps = { planName: string };
export const Tick = (props: TickProps): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
      <path
        stroke={colorMap[props.planName]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m7.5 12 3 3 6-6m5.5 3c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
      />
    </svg>
  );
};
