import { filterListByCurrentIndustryCode } from "../../Utils";
import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { RelatedCaseStudies } from "../../components/RelatedCaseStudies";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default function CaseStudy({ data, location }) {
  return (
    <Layout location={location}>
      <main id="main" className="marketing-content container-fluid">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-secondary body-marketing-case-studies hide-elevio",
          }}
        />
        <div className="row section-title">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6 mx-auto  section-inner">
            <h1>
              <span className="shorthand-logo">Shorthand</span>
              <span className="subsection-label">Case Study:</span>
              British Council
            </h1>
            <h2>How British Council uses Shorthand to take readers behind the scenes of exhibitions</h2>
          </div>
          <div aria-hidden="true" className="background-media">
            <img
              src="/media/case-studies/case-studies-bc-title.jpg"
              data-object-fit="cover"
              data-autoscale-content-ratio="1.77777777778"
              alt=""
            />
          </div>
        </div>
        <div className="row section">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto marketing-main-column">
            <h3 className="intro">
              British Council added Shorthand to its toolset in 2014&mdash;introducing an opportunity to bring together the range of
              media they already produced for online, into a single rich and engaging narrative.
            </h3>

            <div className="inline-image featured">
              <img
                src="/media/case-studies/case-studies-bc.jpg"
                alt="Saints Alive, by The British Council, renders responsively across all devices"
              />
            </div>

            <p>
              “It’s completely opened up a whole new area of putting content online for us,” according to Shakia Stewart, content
              manager, digital, at British Council. “We hadn't done anything like this previously so it really has changed the breadth
              and width of everything we have available now.”
            </p>

            <figure className="block quote-block offset left">
              <blockquote>
                <p>“It’s completely opened up a whole new area of putting content online for us.”</p>
              </blockquote>
              <figcaption className="quote-cite">Shakia Stewart</figcaption>
            </figure>

            <p>
              One particular area of success has been their delivery of virtual exhibitions, having brought to life events such as{" "}
              <a
                href="https://www.britishcouncil.org/arts/heatherwick/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                Inside Heatherwick Studio
              </a>
              &nbsp;and&nbsp;
              <a
                href="https://www.britishcouncil.org/arts/michael-landy-saints-alive/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                Saints Alive
              </a>
              , media-rich explorations of exhibitions, bolstered by background story, behind-the-scenes narrative and engaging
              visuals. In some cases they also harnessed interactive techniques in Shorthand to animate exhibits as the reader scrolls.
            </p>

            <p>
              Creating an experience that can tell the story of an exhibition before, during and after the official running date adds
              to the story’s shelf-life, and the long-term impact of these stories.
            </p>

            <div className="photo-grid flex featured">
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-bc-sml-01.jpg"
                alt="A section from one of the British Council's stories"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-bc-sml-02.jpg"
                alt="A section from one of the British Council's stories"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-bc-sml-04.jpg"
                alt="A section from one of the British Council's stories"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-bc-sml-03.jpg"
                alt="A section from one of the British Council's stories"
              />
            </div>
            <div className="caption">A selection of sections from British Council's stories</div>

            <p>
              “Typically an exhibition has a start date and an end date and then that’s it. This is generating interest before it
              happens, whilst it’s on and then it’s a legacy for that exhibition afterwards,” Shakia said.
            </p>

            <figure className="block quote-block offset right">
              <blockquote>
                <p>"If you have great content to display, Shorthand allows you to make the best of it."</p>
              </blockquote>
              <figcaption className="quote-cite">Shakia Stewart</figcaption>
            </figure>

            <p>
              In the case of Inside Heatherwick Studio, at the time of writing the story had an average time on page of more than 3.5
              minutes and around 20,000 social media shares – the latter being one of British Council’s most important measures of
              success.
            </p>

            <p>
              "Visually, it's just great. We don't currently have functionality to allow a display of full-screen images, scrolling,
              videos. If you have great content to display, Shorthand allows you to make the best of it."
            </p>

            <div className="related-stories">
              <h4>More Shorthand stories by The British Council:</h4>
              <ul>
                <li>
                  <a
                    href="https://www.shakespearelives.org/get-involved/play-your-part/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-link"
                  >
                    Shakespeare Lives: Play your part
                  </a>
                </li>
                <li>
                  <a href="https://www.britishcouncil.org/80moments/" target="_blank" rel="noopener noreferrer" className="btn-link">
                    80 Moments
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <RelatedCaseStudies studies={filterListByCurrentIndustryCode(data, "british-council")} />
        <Footer />
      </main>
    </Layout>
  );
}

export const query = graphql`
  query bcQuery {
    allCaseStudiesJson {
      edges {
        node {
          jsonId
          image
          industry
          industryCode
          organisation
          summary
        }
      }
    }
  }
`;
