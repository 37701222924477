import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image";
import * as React from "react";

interface StickyToolBarProps {
  show: boolean;
  template: {
    id: string;
    heading: string;
    name: string;
    image: { src: IGatsbyImageData; alt: string };
  };

  setShowPortal: (value: boolean) => void;
  handlePreview: () => void;
  goToPlayGround?: () => void;
}

export const StickyToolBar = ({ show, template, setShowPortal, handlePreview, goToPlayGround }: StickyToolBarProps): JSX.Element => {
  const { heading, image, name } = template;

  const toolbarButtonLabels = useStaticQuery(graphql`
    query toolbarButtonLabelsQuery {
      allTemplateCtaLabelsJson {
        nodes {
          preview
          mobileCta
          desktopCta
        }
      }
    }
  `);
  const labels = toolbarButtonLabels.allTemplateCtaLabelsJson.nodes[0];
  const ctaId = `${name.toLowerCase().replace(/\s+/g, "-")}-cta`;
  return (
    <div role="region" style={{ position: "relative", top: "0px", zIndex: "200" }}>
      <section
        className={`${
          show
            ? "tw-fixed tw-top-[45px] max-xxxxl:tw-top-[45px] max-xl:tw-top-[45px] max-lg:tw-top-[0px] max-sm:tw-top-[0px]"
            : "tw-fixed tw-top-[-130px] "
        }  tw-flex tw-w-full tw-justify-center tw-bg-white  tw-p-6 tw-text-white !tw-shadow-default tw-transition-all tw-duration-200 tw-ease-in-out`}
      >
        <div className="tw-flex tw-items-center !tw-justify-between  tw-gap-4 max-xxxxl:!tw-w-[73%] max-container:!tw-w-[68%] max-lg:!tw-w-full max-md:!tw-justify-between max-sm:!tw-w-full max-sm:!tw-justify-center min-xxxxl:!tw-w-[1400px] ">
          <div className="!tw-flex  tw-items-center tw-gap-4 max-sm:!tw-hidden">
            <div className="overflow-hidden tw-w-[50px] tw-w-[87px] tw-rounded-lg">
              <GatsbyImage image={getImage(image.src)} alt={image.alt} className="tw-w-[100%]" />
            </div>
            <span className="tw-font-CircularXXSub tw-text-xl tw-font-medium tw-text-black">{heading}</span>
          </div>

          <div className="tw-flex tw-gap-4">
            <button tabIndex={-1} className="btn btn-lg btn-secondary !tw-m-0" onClick={handlePreview}>
              {labels.preview}
            </button>

            <div className="!tw-hidden  max-md:!tw-flex max-sm:!tw-flex">
              <button tabIndex={-1} className="btn btn-lg btn-primary !tw-m-0" onClick={() => setShowPortal(true)}>
                {labels.mobileCta}
              </button>
            </div>

            <div className="max-xxl:!tw-flex max-md:!tw-hidden  max-sm:!tw-hidden">
              {/* only show the go to playground button on desktop */}
              <button tabIndex={-1} className="btn btn-lg btn-primary !tw-m-0" onClick={goToPlayGround} id={ctaId}>
                {labels.desktopCta}
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
