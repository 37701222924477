import { filterListByCurrentIndustryCode } from "../../Utils";
import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { RelatedCaseStudies } from "../../components/RelatedCaseStudies";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default function CaseStudy({ data, location }) {
  return (
    <Layout location={location}>
      <main id="main" className="marketing-content container-fluid">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-secondary body-marketing-case-studies hide-elevio",
          }}
        />
        <div className="row section-title">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6 mx-auto section-inner">
            <h1>
              <span className="shorthand-logo">Shorthand</span>
              <span className="subsection-label">Case Study:</span>
              Sydney Opera House
            </h1>
            <h2>How the Sydney Opera House uses Shorthand</h2>
          </div>
          <div aria-hidden="true" className="background-media">
            <img
              src="/media/case-studies/case-studies-soh-title.jpg"
              data-object-fit="cover"
              data-autoscale-content-ratio="1.77777777778"
              alt=""
            />
          </div>
        </div>
        <div className="row section">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto marketing-main-column">
            <h3 className="intro">
              How does the Sydney Opera House content team produce world-leading interactive stories, from gorgeously designed photo
              essays to hugely imaginative virtual escape rooms?
            </h3>
            <div className="inline-image featured">
              <img
                alt="Sydney Opera House at Rest, by the Sydney Opera House, renders responsively across all devices"
                src="/media/case-studies/case-studies-soh.jpg"
              />
            </div>
            <p>That was the question we put to Dominic Ellis, Online Content Editor at the Sydney Opera House.</p>
            <p>
              They’ve been using Shorthand since 2018, originally to create immersive visual stories not possible with a standard
              website content management system.
            </p>
            <p>
              “We started using Shorthand for more visually-oriented stories, which a regular CMS probably couldn't bring to life. We
              wanted those full bleed images, nice transitions and interactive elements that are only possible with Shorthand.”
            </p>
            <p>
              The team at the Sydney Opera House, as Dominic explains, decided to “turn to a service that is exclusively built for
              visual, interactive storytelling. That's what we wanted to accomplish, so we could push the boundaries in terms of what
              our content looked like and how people engaged with it.”
            </p>
            <h3 className="subheading">‘The perfect experience for online content’</h3>
            <p>
              Since starting with Shorthand, the Sydney Opera House has published a range of gorgeous visual stories. Some striking
              stories from recent years include{" "}
              <a
                href="https://stories.sydneyoperahouse.com/sydney-opera-house-at-rest/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                The Sydney Opera House at rest
              </a>
              , an arresting showcase of photography taken during lockdown in mid-2020, and{" "}
              <a
                href="https://stories.sydneyoperahouse.com/stage-direction-phantom-of-the-opera/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                Stage Direction: The Phantom of the Opera
              </a>
              .
            </p>
            <p>
              ‘Stage Direction’ is an editorial series from the Opera House about stage productions that were originally adapted from
              books. The piece on The Phantom of the Opera focuses on how that story has been adapted over the years, from its origins
              as a novella, to various forms of film, TV, and theatre.
            </p>
            <p>
              As Dominic explains, “I think our piece tells the story of The Phantom of the Opera in such a visually interesting and
              chronological way that would only be possible on Shorthand. We displayed all the incredible visual assets that have come
              over a hundred or so years of adaptations.”
            </p>
            <p>
              Importantly, the piece served three different purposes for the team. “First, there is obviously the marketing element
              &mdash; showcasing what the show is and how it came to be. Second, it's supplementing that theatrical experience for
              those who already have tickets.
            </p>
            <p>
              “And then it's also an educational piece. It's telling a story that a lot of people probably didn't know. And that's the
              perfect experience for online content. So that's a piece I'm particularly proud of.”
            </p>
            <div className="case-study-video-container">
              <video className="case-study-video-inline" muted loop playsInline autoPlay>
                <source src="/media/case-studies/case-studies-soh.mp4" type="video/mp4" />
              </video>
            </div>
            <h3 className="subheading">An imaginative sandbox</h3>
            <p>
              After using Shorthand to create immersive visual stories, the team at the Sydney Opera House started using Shorthand as
              what Dominic calls an “imaginative sandbox.”
            </p>
            <p>
              Using Shorthand’s templates and section types as a starting off point, Dominic and the team used Shorthand to “try and
              push our storytelling ideas and do things a little bit more imaginatively.”
            </p>
            <p>With the pandemic interrupting live performances for much of 2020, the team pushed themselves even further.</p>
            <p>
              “We aimed to create content that was even more imaginative and even more interactive. And that's where we came up with
              the idea for our most boundary pushing type of story we've published so far: an online escape room.”
            </p>
            <p>
              For the uninitiated, an ‘escape room’ is a popular adventure game. It usually involves a group of people attempting to
              escape a room by finding clues, solving puzzles, and cracking codes.
            </p>
            <p>
              The Sydney Opera House’s first online escape room,{" "}
              <a
                href="https://stories.sydneyoperahouse.com/trial-of-wisdom-escape-room/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                Trial of wisdom
              </a>{" "}
              went live in July 2020 and was a collaboration with the podcasters from{" "}
              <a href="https://www.escapethispodcast.com/" target="_blank" rel="noopener noreferrer">
                Escape This Podcast
              </a>
              . Virtual participants had to escape from the underbelly of the Sydney Opera House.
            </p>
            <div className="photo-grid flex featured">
              <img
                alt="A section from one of the Syndey Opera House's stories"
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-soh-sml-01.jpg"
              />
              <img
                alt="A section from one of the Syndey Opera House's stories"
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-soh-sml-03.jpg"
              />
              <img
                alt="A section from one of the Syndey Opera House's stories"
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-soh-sml-02.jpg"
              />
              <img
                alt="A section from one of the Syndey Opera House's stories"
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-soh-sml-04.jpg"
              />
            </div>
            <div className="caption">A selection of sections from the Sydney Opera House's stories</div>
            <h3 className="subheading">‘It’s very exciting’</h3>
            <figure className="block quote-block offset right">
              <blockquote>
                <p>“The engagement for our escape rooms has been out of this world.”</p>
              </blockquote>
              <figcaption className="quote-cite">Dominic Ellis</figcaption>
            </figure>
            <p>As Dominic explains, the escape room concept proved to be a hit during lockdown.</p>
            <p>
              “Initially it was pretty successful. We had some success locally and overseas, as we weren't the only ones in lockdown
              back then, including pickup from international media outlets like{" "}
              <a href="https://nerdist.com/article/sydney-opera-house-digital-escape-room/" target="_blank" rel="noopener noreferrer">
                Nerdist
              </a>
              . But as lockdowns across the world started fading, international interest dropped off somewhat.
            </p>
            <p>
              “And then when New South Wales and Victoria went back down into lockdown in 2021, it went nuts again. We had a lot of
              interest &mdash; Facebook messages, tweets and everything asking us for tips on how to finish it.”
            </p>
            <p>
              With this interest, the team published a second escape room,{" "}
              <a
                href="https://stories.sydneyoperahouse.com/disappearing-act-escape-room/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                The disappearing act
              </a>
              , in September 2021. Within the first two weeks, there were over 10,000 ‘attempts’ to escape. “It’s very exciting. And
              we've been inundated with requests for help on how to finish the challenges. The engagement for our escape rooms has been
              out of this world.
            </p>
            <p>
              “And it wasn't just popular for two or three weeks. And it wasn't just one of those many lockdown fads where interest
              fades over time. Across the two escape rooms, the engagement's sustained over almost a year and a half.”
            </p>
            <p>
              The numbers bear this out. The average time-on-page for the first virtual escape room is an astonishing{" "}
              <em>13 and a half minutes</em>.
            </p>
            <h3 className="subheading">World-leading content</h3>
            <figure className="block quote-block offset left">
              <blockquote>
                <p>“We could really bring our stories to life... thanks to Shorthand.”</p>
              </blockquote>
              <figcaption className="quote-cite">Dominic Ellis</figcaption>
            </figure>
            <p>With such success, you might assume that the Sydney Opera House has an enormous team of content producers.</p>
            <p>
              But in fact, Dominic is the only member of the online content team at the Sydney Opera House, though he is part of a
              wider digital content team. Using Shorthand, he is able to decide how much additional resource — such as design or custom
              development — he uses for each piece of content they produce.
            </p>
            <p>
              Dominic explains that he can use Shorthand to create compelling content without leaning on their designers or writing
              code. “Shorthand’s great. On the one hand, you can use the templates and layouts that Shorthand has produced, and you can
              create visually interesting stories very easily, so long as you've got the assets.”
            </p>
            <p>
              But Dominic points out that Shorthand also allows content teams with more resources or expertise to create stunningly
              original content (such as online escape rooms).
            </p>
            <p>
              “On the other hand, it's a sandbox. “It gives you free rein to do a little bit of HTML, CSS and JavaScript or to push it
              in whatever direction you want. And that's great. You can push yourself a little bit more and use the platform in
              interesting ways, if you've got a little bit of development resource.
            </p>
            <p>
              “I think that's why it has suited our situation perfectly as well. There are only a few internal content producers, but
              we do have access to a talented{" "}
              <a href="https://www.behance.net/soh-creativestudio" target="_blank" rel="noopener noreferrer">
                creative studio
              </a>{" "}
              and a few in-house developers. And so with just a little bit of resource, we could really bring our stories to life in
              terms of illustrations and development while having that foundation, thanks to Shorthand.”
            </p>

            <h3 className="subheading">A growing platform</h3>
            <figure className="block quote-block offset right">
              <blockquote>
                <p>
                  “One thing that's really impressed me is that we've been using Shorthand for three and a half years now, but it keeps
                  iterating.”
                </p>
              </blockquote>
              <figcaption className="quote-cite">Dominic Ellis</figcaption>
            </figure>
            <p>
              The Sydney Opera House team considered several other options before choosing Shorthand. One factor that tipped the scales
              for Shorthand was how Shorthand stories performed on smaller screens.
            </p>
            <p>
              “What we particularly liked about Shorthand is how well it looked on mobile. Our engagement on mobile for our written
              content is really high. Around 65% of our readers are reading on mobile, and that's usually coming from clicks on social
              media platforms. We liked that it was a responsive platform and you could also curate it with both mobile and desktop in
              mind.”
            </p>
            <p>
              Over the last three years using Shorthand, Dominic and his colleagues at the Opera House have noticed the platform
              continues to grow and evolve.
            </p>
            <p>
              “One thing that's really impressed me is that we've been using it for three and a half years now, but it keeps iterating.
              I feel like every time I log in, or every month I log in, there's a new tool, or you guys are experimenting with a new
              section style that has really interesting visual storytelling methods behind them.”
            </p>
            <p>
              The upshot for Dominic is that these new features allow the Opera House to produce new and innovative types of stories.
            </p>
            <p>
              “I like that there's been a lot of progression in the time that we've been using the platform. This allows us to develop
              our way of telling our stories as well.”
            </p>
            <h3 className="subheading">“Our content has just been a lot better”</h3>
            <p>Since using Shorthand, the Opera House has seen noticeable changes in audience engagement.</p>
            <p>
              This is because, as Dominic puts it, “our content has just been a lot better. There are stories that we've published that
              we could never be able to publish previously. We've been able to use our visual assets, our illustrators and our
              photographers, the content they create in a way that we couldn't previously and bring it to life.”
            </p>
            <p>The success of their Shorthand stories has led to other use cases for their online stories.</p>
            <p>
              “We’ve started to use our Shorthand stories as an exhibition space of sorts. The Opera House is traditionally a
              performing arts space, but we also have a contemporary art program. And we have access to some incredible artists, and
              Shorthand has created an appetite to exhibit their works in a way that we couldn't previously.”
            </p>
            <p>
              Dominic has also seen other teams at the Sydney Opera House start to experiment with Shorthand, including their
              educational programmes.
            </p>
            <p>
              We ended our discussion with Dominic by asking if he had any advice for content teams looking to replicate his success.
            </p>
            <p>
              “There are thousands of great examples of Shorthand stories, but don’t be limited to copying what already exists. The
              beauty of Shorthand is it's a sandbox, and you can do with it what you want.”
            </p>
          </div>
        </div>
        <RelatedCaseStudies studies={filterListByCurrentIndustryCode(data, "sydney-opera-house")} />
        <Footer />
      </main>
    </Layout>
  );
}

export const query = graphql`
  query SydneyOperaHouseQuery {
    allCaseStudiesJson {
      edges {
        node {
          jsonId
          image
          industry
          industryCode
          organisation
          summary
        }
      }
    }
  }
`;
