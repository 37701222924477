import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { Link, graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default class IndustriesBrandPublishing extends React.Component<any, any> {
  render() {
    const { allBrandsJson } = this.props.data;

    const brands = allBrandsJson.edges;

    return (
      <Layout location={this.props.location}>
        <main id="main" className="marketing-content container-fluid">
          <Helmet
            bodyAttributes={{
              class: "body-shorthand body-marketing body-marketing-secondary body-marketing-features body-light hide-elevio",
            }}
          ></Helmet>
          <div className="row section-title title-light">
            <div className="col-11 col-sm-8  mx-auto section-inner">
              <h1>
                <span className="shorthand-logo dark" aria-hidden="true">
                  Shorthand
                </span>
                Brand publishing
              </h1>
              <h2>Create beautifully immersive content that engages readers with your most valuable asset: your brand.</h2>
            </div>
          </div>
          <section className="row section-brands section-border-top">
            <div className="brands-list">
              <ul>
                <li>
                  <img src="/media/logos/clients/lg-honda.png" loading="lazy" width="348" height="241" alt="Honda logo" />
                </li>
                <li>
                  <img src="/media/logos/clients/lg-penguin.png" loading="lazy" width="348" height="241" alt="Penguin logo" />
                </li>
                <li>
                  <img src="/media/logos/clients/lg-mancity.png" loading="lazy" width="348" height="241" alt="Manchester City logo" />
                </li>
                <li>
                  <img src="/media/logos/clients/lg-christies.png" loading="lazy" width="348" height="241" alt="Christie's logo" />
                </li>
                <li>
                  <img src="/media/logos/clients/lg-tripadvisor.png" loading="lazy" width="348" height="241" alt="Tripadvisor logo" />
                </li>
              </ul>
            </div>
          </section>

          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Your brand, your theme</h3>
                  <p>
                    On applicable plans, Shorthand’s expert team will build a custom theme at your direction &mdash; with your fonts,
                    colors, styles, default header logos, and more.
                  </p>
                  <p>This means that anyone on your team can easily publish dynamic and engaging content that is always on brand.</p>
                  <a className="btn btn-secondary" href="/features/branding/">
                    Learn more about themes &amp; branding
                  </a>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/industries/penguin-right-grey.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-right">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Built for marketing teams</h3>
                  <p>
                    Shorthand is built for marketers, writers, and content producers. Your whole team can get started in minutes
                    &mdash; no code or web design required.
                  </p>
                  <ul className="features-list">
                    <li>Simple, human-friendly text editing, and drag-and-drop media uploads.</li>
                    <li>
                      Add beautiful, high resolution images and video &mdash; and stunning no-code animation effects &mdash; all with
                      your existing team.
                    </li>
                    <li>
                      Regular free training is available for all team members, with white-glove onboarding and editorial support on
                      applicable plans.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/industries/engine-left-white.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Proven results</h3>
                  <p>
                    Shorthand stories are a proven solution with measurable outcomes &mdash; fewer bounces, more engagement, higher
                    dwell-times, and better click-through-rates.
                  </p>
                  <ul className="features-list">
                    <li>Measure the impact of your digital stories with Shorthand’s built-in analytics system.</li>
                    <li>Easily integrate with your existing analytics platforms, such as Google Analytics.</li>
                    <li>
                      <Link to="/case-studies/" className="btn-link">
                        Read our case studies
                      </Link>{" "}
                      to find out more about the real-world impact of Shorthand.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/industries/analytics-ipad-grey.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-right">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Loved by your CFO</h3>
                  <p>
                    Say goodbye to unpredictable and ballooning marketing spend. With Shorthand, invite as many team members as you
                    need, with unlimited story creation and publishing on a fixed-price subscription.
                  </p>
                  <ul className="features-list">
                    <li>We offer plans that enable publishing to multiple websites.</li>
                    <li>
                      Training comes free! Join us for regular free live training webinars and masterclasses, or take a plan that
                      includes personalised onboarding with white-glove support from our industry-leading customer success team.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/industries/team-left-white.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section  section-strip section-strip-quote ">
            <div className="container container-sm">
              <div className="section-strip-col-single section-strip-single-quote">
                <div className="section-strip-quote-inner">
                  <blockquote>
                    &ldquo;Shorthand allows our content team to quickly create engaging, interactive features that work across all
                    devices without the need for developers. The platform is packed with powerful storytelling features, yet it’s easy
                    to use.&rdquo;
                  </blockquote>
                  <cite>Nick Bennett | Digital Content and Social Media Section Manager | Honda UK</cite>
                </div>
              </div>
            </div>
          </section>

          <section className="row section  section-strip-stories section-border-top">
            <div className="container flex-column section-inner text-center">
              <h3>Take a look at these great brand stories, built with Shorthand</h3>
              <div className="featured-stories">
                <ul>
                  <li className="tw-w-[33%]">
                    <a
                      className="card-border card-shadow"
                      href="https://www.honda.co.uk/engineroom/electric/hybrid/behind-the-wheel-of-the-all-new-jazz/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/honda-jazz.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        Behind the wheel of the all new Jazz
                        <span className="featured-story-brand">Honda</span>
                      </h4>
                    </a>
                  </li>

                  <li className="tw-w-[33%]">
                    <a
                      className="card-border card-shadow"
                      href="https://www.christies.com/features/The-Private-Collection-of-Jayne-Wrightsman-10882-7.aspx?lid=1"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/christies-jayne.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        The Private Collection of Jayne Wrightsman
                        <span className="featured-story-brand">Christies</span>
                      </h4>
                    </a>
                  </li>

                  <li className="tw-w-[33%]">
                    <a
                      className="card-border card-shadow"
                      href="https://www.mancity.com/features/city-v-newcastle-match-prog-july-20/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/man-newcastle.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        City vs Newcastle
                        <span className="featured-story-brand">Manchester City</span>
                      </h4>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="row section section-dark section-half section-trial !tw-bg-[rgba(249,249,249,0.3)]">
            <div className="container section-inner text-center">
              <h2 className="!tw-text-black">
                Ready to try the digital storytelling platform used by Honda, University of Cambridge, and the BBC?
              </h2>

              <div className="cta-container">
                <a href="https://app.shorthand.com/signup" className="btn btn-lg !tw-bg-black hover:!tw-opacity-70">
                  Start creating
                </a>
              </div>
              <div className="brands">
                <div className="brands-container">
                  <h3 className="as-used">Join these iconic brands</h3>
                  <ul>
                    {brands.map((brand, index) => (
                      <li key={`brand-${brand.node.label}-${index}`} className={`brand`}>
                        <img src={brand.node.logo} alt={`${brand.node.label} logo`} />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div aria-hidden="true" className="background-media background-media-fixed">
              <img src="/media/home/stories.jpg" className="ObjectFit--cover" alt="" />
            </div>
          </section>

          <Footer className="mt-0" />
        </main>
      </Layout>
    );
  }
}

export const query = graphql`
  query IndustriesBrandPublishingQuery {
    site {
      siteMetadata {
        name
        title
        tagline
        description
      }
    }
    allBrandsJson {
      edges {
        node {
          logo
          name
          label
        }
      }
    }
  }
`;
