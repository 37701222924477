import "./styles.scss";
import * as React from "react";
import ReactSelect, { GroupBase, StylesConfig, Theme } from "react-select";
import ReactCreatableSelect from "react-select/creatable";
import { SelectComponents } from "react-select/dist/declarations/src/components";

type TOption<ValueType> = {
  label: string;
  value: ValueType;
  field?: string;
  order?: string;
};

type SingleOrMulti<ValueType, IsMulti extends boolean> = IsMulti extends true ? Array<ValueType> : ValueType | null;

interface ISelectBaseProps {
  "aria-label"?: string;
  "aria-labelledby"?: string;
  className?: string;
  controlShouldRenderValue?: boolean;
  disabled?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  inputId?: string;
  placeholder?: React.ReactNode;
  name?: string;
  styles?: StylesConfig<any, false, GroupBase<any>>;
  children?: React.ReactNode;
  components?: Partial<SelectComponents<any, false, GroupBase<any>>>;
  menuIsOpen?: boolean;
}

interface ISimpleSelectProps<ValueType, IsMulti extends boolean = false> extends ISelectBaseProps {
  isMulti?: IsMulti;
  options?: Readonly<Array<TOption<ValueType>>>;
  value?: SingleOrMulti<ValueType, IsMulti>;
  onChange?: (nextValue: SingleOrMulti<ValueType, IsMulti>) => void;
}

interface ISelectProps<ValueType extends TOption<unknown>, IsMulti extends boolean = false> extends ISelectBaseProps {
  isMulti?: IsMulti;
  options?: Readonly<Array<ValueType>>;
  value?: SingleOrMulti<ValueType, IsMulti>;
  onChange?: (nextValue: SingleOrMulti<ValueType, IsMulti>) => void;
}

const baseStyles: StylesConfig<unknown, any, GroupBase<any>> = {
  clearIndicator: provided => ({
    ...provided,
    "&:hover": { color: "#EC3F3F" },
    padding: 0,
    paddingRight: "2px",
  }),
  container: (provided, state) => ({
    ...provided,
    width: "100%",
    padding: 0,
    fontSize: "14px",
    backgroundColor: state.isFocused ? "#FFFFFF" : "none",
  }),
  control: provided => ({
    ...provided,
    borderColor: "#AAAAAA",
    cursor: "pointer",
  }),
  input: provided => ({
    ...provided,
    input: {
      boxShadow: "none",
    },
  }),
  menu: provided => ({
    ...provided,
    marginTop: 2,
    fontSize: "inherit",
    zIndex: 1000,
  }),
  menuList: provided => ({
    ...provided,
    padding: 0,
  }),
  multiValueLabel: provided => ({
    ...provided,
    fontSize: "13px",
    color: "#fff",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected || state.isFocused ? "#EEEEEE" : "#FFFFFF",
    color: "#424242",
    fontWeight: 500,
    cursor: "pointer",
    textOverflow: "ellipsis",
    overflowX: "hidden",
  }),
  singleValue: provided => ({
    ...provided,
    color: "#424242",
    margin: "2px",
    padding: "2px",
  }),
  multiValueRemove: (provided, state) => {
    return {
      ...provided,
      svg: {
        fill: state.isFocused ? "#EC3F3F" : "#fff",
      },
      "&:hover": {
        svg: {
          fill: "#EC3F3F",
        },
      },
    };
  },
};

function DropdownIndicator(): JSX.Element {
  return (
    <span className="select-arrow-zone">
      <span className="select-arrow"></span>
    </span>
  );
}

function getThemeColours(theme: Theme): Theme {
  return {
    ...theme,
    borderRadius: 4,
    colors: {
      ...theme.colors,
      primary: "#12bf9c",
      primary25: "#EEEEEE",
      primary50: "rgba(18, 191, 156, 0.1)",
    },
  };
}

/**
 * Convenience wrapper for Select that accepts (and returns in onChange) an unwrapped value type
 * directly, instead of a wrapped TOption.
 */
export function SimpleSelect<ValueType, IsMulti extends boolean = false>(props: ISimpleSelectProps<ValueType, IsMulti>) {
  const { value, onChange, options, isMulti } = props;
  if (isMulti) {
    return Select<TOption<ValueType>, true>({
      ...props,
      isMulti: true,
      value: (value as ValueType[] | undefined)?.map(value => options.find(opt => opt.value === value)),
      onChange: (newValue: TOption<ValueType>[]) =>
        onChange((newValue ? newValue.map(opt => opt.value) : []) as SingleOrMulti<ValueType, IsMulti>),
    });
  } else {
    return Select<TOption<ValueType>, false>({
      ...props,
      isMulti: false,
      value: props.options.find(opt => opt.value === props.value),
      onChange: (newValue: TOption<ValueType> | undefined) =>
        onChange((newValue ? newValue.value : null) as SingleOrMulti<ValueType, IsMulti>),
    });
  }
}

export function Select<ValueType extends TOption<unknown>, IsMulti extends boolean = false, IsSimple extends boolean = false>(
  props: ISelectProps<ValueType, IsMulti>
): JSX.Element {
  const { disabled, isMulti, options, components, styles, value, onChange, ...otherProps } = props;

  const selectComponents = {
    IndicatorSeparator: () => null,
    DropdownIndicator: () => <DropdownIndicator />,
    ...components,
  };

  return (
    <ReactSelect
      {...otherProps}
      value={value}
      classNamePrefix="react-select"
      components={selectComponents}
      isDisabled={disabled}
      isMulti={isMulti}
      onChange={onChange}
      options={options}
      theme={getThemeColours}
      styles={{ ...baseStyles, ...styles }}
    />
  );
}

interface ICreatableSelectProps<ValueType extends TOption<unknown>, IsMulti extends boolean = false>
  extends ISelectProps<ValueType, IsMulti> {
  formatCreateLabel?: (inputValue: string) => React.ReactNode;
  isValidNewOption?: (inputValue: string) => boolean;
}

export function CreatableSelect<ValueType extends TOption<unknown>, IsMulti extends boolean = false>(
  props: ICreatableSelectProps<ValueType, IsMulti>
): JSX.Element {
  const { components, disabled, options, isMulti, styles, value, onChange, ...otherProps } = props;

  const selectComponents = {
    IndicatorSeparator: () => null,
    DropdownIndicator: () => <DropdownIndicator />,
    ...components,
  };
  return (
    <ReactCreatableSelect
      {...otherProps}
      classNamePrefix="react-creatable-select"
      components={selectComponents}
      isDisabled={disabled}
      onChange={onChange}
      options={options}
      styles={{ ...baseStyles, ...styles }}
      theme={getThemeColours}
      value={value}
    />
  );
}

export default Select;
