import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default class FeaturesCustomisation extends React.Component<any, any> {
  render() {
    const { allBrandsJson } = this.props.data;

    const brands = allBrandsJson.edges;

    return (
      <Layout location={this.props.location}>
        <main id="main" className="marketing-content container-fluid">
          <Helmet
            bodyAttributes={{
              class: "body-shorthand body-marketing body-marketing-secondary body-marketing-features body-light hide-elevio",
            }}
          ></Helmet>
          <div className="row section-title title-light">
            <div className="col-11 col-sm-8  mx-auto section-inner">
              <h1>
                <span className="shorthand-logo dark" aria-hidden="true">
                  Shorthand
                </span>
                Customisation & Integration
              </h1>
              <h2>Integrate third-party tools and your CMS.</h2>
            </div>
          </div>

          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Custom sections</h3>
                  <p>Add custom JavaScript, CSS, and HTML to your stories to build out new story sections.</p>
                  <ul className="features-list">
                    <li>Paste JavaScript into your story’s custom HEAD to add custom analytics to your story.</li>
                    <li>Combine our flexible story types with custom CSS to create truly original stories.</li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/brand-code.png" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>
          <section className="row section section-strip section-strip-right">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Add interactive data and maps</h3>
                  <p>
                    Using custom JavaScript, integrate third party maps, charts, or data visualisations to create a truly interactive
                    story.
                  </p>
                  <ul className="features-list">
                    <li>
                      Add a rich, interactive webmap for your readers — common implementations include Mapbox, Carto, Leaflet, and
                      StoryMaps.
                    </li>
                    <li>Embed a dynamic chart or visualisation from data platforms like Tableau and Flourish.</li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/dev-map.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>
          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Complete documentation</h3>
                  <p>Full, up-to-date, and human-readable documentation for Shorthand is available at Shorthand Support.</p>
                  <a
                    className="btn btn-secondary"
                    href="https://support.shorthand.com/en/articles/50-custom-css"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View support documentation
                  </a>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/dev-support.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-right">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Story API</h3>
                  <p>Use our Story API to programmatically access your stories and integrate with your CMS and custom plugins.</p>
                  <a
                    className="btn btn-secondary"
                    href="https://support.shorthand.com/en/articles/62-programmatic-publishing-with-the-shorthand-api"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View API documentation
                  </a>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/dev-api.png" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section  section-strip-features-sm">
            <div className="container section-inner text-center">
              <div className="features features-cards icon-cards">
                <ul>
                  <li>
                    <div className="feature-card icon-card card-border card-shadow">
                      <div className="card-icon">
                        <i className="fas fa-file-alt"></i>
                      </div>
                      <div className="card-text">
                        <h3>Inline custom HTML</h3>
                        <p>Add a custom HTML block right in a column of text.</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="feature-card icon-card card-border card-shadow">
                      <div className="card-icon">
                        <i className="fas fa-code"></i>
                      </div>
                      <div className="card-text">
                        <h3>Custom {"<head>"} code</h3>
                        <p>Add custom metadata tags to your story, or JavaScript you want to load first.</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="feature-card icon-card card-border card-shadow">
                      <div className="card-icon">
                        <i className="fas fa-hand-holding-heart"></i>
                      </div>
                      <div className="card-text">
                        <h3>Custom development</h3>
                        <p>Our team can build out new themes and custom features for your stories.</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <section className="row section  section-strip section-strip-quote ">
            <div className="container container-sm">
              <div className="section-strip-col-single section-strip-single-quote">
                <div className="section-strip-quote-inner">
                  <blockquote>
                    &ldquo;Using Shorthand has brought development time down from several weeks to a day or less. This means we can do
                    many more projects than before. And time on page is always better than normal CMS stories. We’re getting some great
                    results.&rdquo;
                  </blockquote>
                  <cite>Matt Martel | Photos and Presentation Director | Stuff</cite>
                </div>
              </div>
            </div>
          </section>

          <section className="row section  section-strip-stories section-border-top">
            <div className="container flex-column section-inner text-center">
              <h3>Immersive storytelling in action</h3>
              <div className="featured-stories">
                <ul>
                  <li>
                    <a className="card-border card-shadow" href="http://stateofmind.vision/" target="_blank" rel="noopener noreferrer">
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/knoso-state.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        State of mind <span className="featured-story-brand">Knowledge Society</span>
                      </h4>
                    </a>
                  </li>

                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://interactives.stuff.co.nz/2020/coronavirus-covid-19-clusters/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/stuff-cluster.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        The cluster paradox <span className="featured-story-brand">Stuff</span>
                      </h4>
                    </a>
                  </li>

                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://www.nbcnews.com/news/world/notre-dame-fire-what-was-damaged-n995371"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/nbc-icon.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        An icon in flames <span className="featured-story-brand">NBC News</span>
                      </h4>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="row section section-dark section-half section-trial !tw-bg-[rgba(249,249,249,0.3)]">
            <div className="container section-inner text-center">
              <h2 className="!tw-text-black">
                Ready to try the digital storytelling platform used by Honda, University of Cambridge, and the BBC?
              </h2>

              <div className="cta-container">
                <a href="https://app.shorthand.com/signup" className="btn btn-lg !tw-bg-black hover:!tw-opacity-70">
                  Start creating
                </a>
              </div>
              <div className="brands">
                <div className="brands-container">
                  <h3 className="as-used">Join these iconic brands</h3>
                  <ul>
                    {brands.map((brand, index) => (
                      <li key={`brand-${brand.node.label}-${index}`} className={`brand`}>
                        <img src={brand.node.logo} alt={`${brand.node.label} logo`} />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div aria-hidden="true" className="background-media background-media-fixed">
              <img alt="" src="/media/home/stories.jpg" className="ObjectFit--cover" />
            </div>
          </section>
          <section className="row section section-gray-lighest section-all-features">
            <div className="container section-inner text-center">
              <h2>
                <a className="unstyled" href="/features/">
                  More Shorthand features
                </a>
              </h2>

              <div className="features features-cards">
                <ul>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/scrollytelling/">
                      <div className="card-text">
                        <h3>Scrollytelling</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/visual-storytelling/">
                      <div className="card-text">
                        <h3>Visual Storytelling</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/collaboration/">
                      <div className="card-text">
                        <h3>Collaboration</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/branding/">
                      <div className="card-text">
                        <h3>Themes & Branding</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/sharing-and-analytics/">
                      <div className="card-text">
                        <h3>Sharing & Analytics</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/publishing-and-hosting/">
                      <div className="card-text">
                        <h3>Publishing & Hosting</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/support/">
                      <div className="card-text">
                        <h3>Support</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/security/">
                      <div className="card-text">
                        <h3>Security</h3>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <Footer className="mt-0" />
        </main>
      </Layout>
    );
  }
}

export const query = graphql`
  query FeaturesCustomisationQuery {
    site {
      siteMetadata {
        name
        title
        tagline
        description
      }
    }
    allBrandsJson {
      edges {
        node {
          logo
          name
          label
        }
      }
    }
  }
`;
