import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default class FeaturesBranding extends React.Component<any, any> {
  render() {
    const { allBrandsJson } = this.props.data;

    const brands = allBrandsJson.edges;

    return (
      <Layout location={this.props.location}>
        <main id="main" className="marketing-content container-fluid">
          <Helmet
            bodyAttributes={{
              class: "body-shorthand body-marketing body-marketing-secondary body-marketing-features body-light hide-elevio",
            }}
          ></Helmet>
          <div className="row section-title title-light">
            <div className="col-11 col-sm-8  mx-auto section-inner">
              <h1>
                <span className="shorthand-logo dark" aria-hidden="true">
                  Shorthand
                </span>
                Themes &amp; Branding
              </h1>
              <h2>Stay on brand and build beautiful content with themes.</h2>
            </div>
          </div>

          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Your brand, your theme</h3>
                  <p>
                    On select plans, Shorthand&apos;s expert team will build a bespoke custom theme at your direction &mdash; with your
                    fonts, colors, styles, default header logos, and more.
                  </p>
                  <ul className="features-list">
                    <li>As part of your onboarding, you&apos;ll be able to detail your brand requirements.</li>
                    <li>
                      We&apos;ll have your theme up and running as you start your subscription, meaning you can start publishing
                      stories immediately.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/features-brand.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-right">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Customise your themes</h3>
                  <p>Using custom JavaScript, HTML, and CSS to add new sections or elements to your existing themes.</p>
                  <ul className="features-list">
                    <li>With custom code sections, create truly attention-grabbing feature stories.</li>
                    <li>
                      Build on our section types and themes to create a unique visual treatment &mdash; without having to start from
                      scratch.
                    </li>
                    <li>Add third-party JavaScript for analytics, ads, and other services.</li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/brand-code.png" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Out-of-the-box themes</h3>
                  <p>
                    On plans without a custom theme, use our professionally designed built-in styles to create beautiful, immersive
                    stories in minutes.
                  </p>
                  <p>On select plans, you can also create your own basic themes using our theme builder.</p>
                  <ul className="features-list">
                    <li>
                      Ensure your stories are published to a professional standard, without building your own theme from scratch.
                    </li>
                    <li>Use our standard theme elements, including colours, fonts, and header.</li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/brand-default.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section  section-strip section-strip-quote ">
            <div className="container container-sm">
              <div className="section-strip-col-single section-strip-single-quote">
                <div className="section-strip-quote-inner">
                  <blockquote>
                    &ldquo;The team behind Shorthand is outstanding. From the sales process, to onboarding and continued support
                    ⁠⁠&mdash; they are knowledgeable, efficient and will do anything they can to help us succeed.&rdquo;
                  </blockquote>
                  <cite>Nick Bennett | Digital Content and Social Media Section Manager | Honda UK</cite>
                </div>
              </div>
            </div>
          </section>

          <section className="row section  section-strip-stories section-border-top">
            <div className="container flex-column section-inner text-center">
              <h3>Immersive Storytelling in action</h3>
              <div className="featured-stories">
                <ul>
                  <li>
                    <a
                      className="card-border card-shadow"
                      href="http://s.telegraph.co.uk/graphics/projects/Africa-in-100-years/index.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/tele-africa.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        What will Africa look like in 100 years? <span className="featured-story-brand">The Telegraph</span>
                      </h4>
                    </a>
                  </li>

                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://feature.arabnews.com/prince-sultan-in-space/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/arab-space.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        When a Saudi went to space <span className="featured-story-brand">Arab News</span>
                      </h4>
                    </a>
                  </li>

                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://www.mancity.com/features/city-v-newcastle-match-prog-july-20/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/man-newcastle.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        City versus Newcastle <span className="featured-story-brand">Manchester City</span>
                      </h4>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="row section section-dark section-half section-trial !tw-bg-[rgba(249,249,249,0.3)]">
            <div className="container section-inner text-center">
              <h2 className="!tw-text-black">
                Ready to try the digital storytelling platform used by Honda, University of Cambridge, and the BBC?
              </h2>

              <div className="cta-container">
                <a href="https://app.shorthand.com/signup" className="btn btn-lg !tw-bg-black hover:!tw-opacity-70">
                  Start creating
                </a>
              </div>
              <div className="brands">
                <div className="brands-container">
                  <h3 className="as-used">Join these iconic brands</h3>
                  <ul>
                    {brands.map((brand, index) => (
                      <li key={`brand-${brand.node.label}-${index}`} className={`brand`}>
                        <img src={brand.node.logo} alt={`${brand.node.label} logo`} />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div aria-hidden="true" className="background-media background-media-fixed">
              <img src="/media/home/stories.jpg" className="ObjectFit--cover" alt="" />
            </div>
          </section>
          <section className="row section section-gray-lighest section-all-features">
            <div className="container section-inner text-center">
              <h2>
                <a className="unstyled" href="/features/">
                  More Shorthand features
                </a>
              </h2>

              <div className="features features-cards">
                <ul>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/scrollytelling/">
                      <div className="card-text">
                        <h3>Scrollytelling</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/visual-storytelling/">
                      <div className="card-text">
                        <h3>Visual Storytelling</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/collaboration/">
                      <div className="card-text">
                        <h3>Collaboration</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/customisation/">
                      <div className="card-text">
                        <h3>Customisation & Integration</h3>
                      </div>
                    </a>
                  </li>
                  {/* <li>
                  <a className="feature-card card-border card-shadow unstyled" href="/features/branding/">
                    <div className="card-text">
                      <h3>Themes & Branding</h3>
                    </div>
                  </a>
                </li> */}
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/sharing-and-analytics/">
                      <div className="card-text">
                        <h3>Sharing & Analytics</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/publishing-and-hosting/">
                      <div className="card-text">
                        <h3>Publishing & Hosting</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/support/">
                      <div className="card-text">
                        <h3>Support</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/security/">
                      <div className="card-text">
                        <h3>Security</h3>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <Footer className="mt-0" />
        </main>
      </Layout>
    );
  }
}

export const query = graphql`
  query FeaturesBrandingQuery {
    site {
      siteMetadata {
        name
        title
        tagline
        description
      }
    }
    allBrandsJson {
      edges {
        node {
          logo
          name
          label
        }
      }
    }
  }
`;
