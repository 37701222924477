import { Footer } from "../components/Footer";
import { LayoutNew } from "../components/LayoutNew";
import { Banner, Cards, FooterBanner, ImageText, Logos, Testimonial } from "../components/Sections";
import { graphql } from "gatsby";
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image";
import * as React from "react";
import { Helmet } from "react-helmet";

export default function UseCases({ data: { useCasesJson }, location }) {
  const {
    banner: bannerSectionData,
    brands: logosSectionData,
    quotes: testimonialSectionData,
    cards: featureSectionData,
    learnMore: learnMoreSectionData,
    mainContent: imageTextSectionData,
    footer: footerSectionData,
  } = useCasesJson;

  return (
    <LayoutNew location={location}>
      <Helmet
        bodyAttributes={{
          class: "body-marketing body-marketing-features",
        }}
      ></Helmet>
      <section className="tw-my-10">
        <div className="tw-container">
          <Banner
            title={bannerSectionData.title}
            description={bannerSectionData.description}
            cta={{
              link: bannerSectionData.cta.url,
              text: bannerSectionData.cta.text,
            }}
            img={bannerSectionData.img}
          />
        </div>
      </section>
      <section className="row section-brands section-border-top">
        <div className="brands-list">
          <ul>
            {logosSectionData.logos.map((img: any, index: number) => {
              return (
                <li key={index}>
                  <GatsbyImage image={getImage(img.url)} alt={img.alt} />
                </li>
              );
            })}
          </ul>
        </div>
      </section>

      {imageTextSectionData.content.map(
        (
          section: {
            title: string;
            description: string;
            video: any;
            image: { alt: string; url: IGatsbyImageData };
          },
          index: number
        ) => {
          return (
            <section className="row section section-strip" key={index}>
              <div className="tw-container tw-mb-[8vh] tw-mt-[8vh]">
                {index % 2 === 0 && (
                  <ImageText
                    mediaType={section.image ? "image" : "video"}
                    img={section.image}
                    video={section.video}
                    title={section.title}
                    content={section.description}
                  />
                )}
                {index % 2 !== 0 && (
                  <ImageText
                    firstContentType="text"
                    mediaType={section.image ? "image" : "video"}
                    img={section.image}
                    video={section.video}
                    title={section.title}
                    content={section.description}
                  />
                )}
              </div>
            </section>
          );
        }
      )}
      <section className="row section section-strip">
        <div className="tw-container tw-mb-[8vh] tw-mt-[8vh]">
          {featureSectionData && (
            <Cards
              title={featureSectionData.title}
              cards={featureSectionData.cards}
              cta={{
                link: featureSectionData.cta.link,
                text: featureSectionData.cta.text,
              }}
            />
          )}
        </div>
      </section>
      <section className="row section section-strip">
        <div className="tw-container tw-mb-[8vh] tw-mt-[8vh]">
          <Testimonial
            firstContentType="image"
            testimonials={testimonialSectionData.quotes}
            cta={{
              link: testimonialSectionData.cta.url,
              text: testimonialSectionData.cta.text,
            }}
            img={testimonialSectionData.img}
          />
        </div>
      </section>
      <section className="row section section-strip">
        <div className="tw-container tw-mb-[8vh] tw-mt-[8vh]">
          {learnMoreSectionData && <Cards title={learnMoreSectionData.title} cards={learnMoreSectionData.cards} />}
        </div>
      </section>
      <div className="tw-container tw-py-[6.25rem] max-lg:tw-py-20">
        <FooterBanner
          cta={{
            link: footerSectionData.cta.url,
            text: footerSectionData.cta.text,
          }}
          title={footerSectionData.title}
        />
      </div>
      <Footer />
    </LayoutNew>
  );
}

export const query = graphql`
  query ($slug: String!) {
    useCasesJson(page: { eq: $slug }) {
      banner {
        title
        description
        cta {
          url
          text
        }
        img {
          alt
          url {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      brands {
        title
        logos {
          alt
          url {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      mainContent {
        content {
          description
          title
          image {
            alt
            url {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
        cta {
          text
          url
        }
      }
      cards {
        cards {
          cta {
            text
            link
          }
          img {
            alt
            url {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          title
        }
        title
        cta {
          text
          link
        }
      }
      quotes {
        cta {
          url
          text
        }
        quotes {
          author
          content
        }
        img {
          alt
          url {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      learnMore {
        title
        cards {
          cta {
            link
            text
          }
          title
          img {
            alt
            url {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
      footer {
        cta {
          text
          url
        }
        title
      }
    }
  }
`;
