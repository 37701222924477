import * as styles from "./ColorBar.module.scss";
import * as React from "react";

interface ColorBarProps {
  /**
   * plan id maps to a color value where we produce a colored gradient bar
   */
  id: string;
}

export const ColorBar = ({ id }: ColorBarProps): JSX.Element => {
  const colorForPlanId = {
    starter: { stopColor1: "#12BF9C", stopColor2: "#FFFFFF" },
    team: { stopColor1: "#00A7CF", stopColor2: "#FFFFFF" },
    business: { stopColor1: "#30404E", stopColor2: "#FFFFFF" },
    enterprise: { stopColor1: "#CCB87E", stopColor2: "#FFFAEA" },
  }[id];

  return (
    <div className={styles.colorBar}>
      <svg width={135} height={15} fill="none">
        <rect width={135} height={15} fill={`url(#${id})`} rx={7.5} />
        <defs>
          <linearGradient id={`${id}`} x1={0} x2={174} y1={15} y2={15} gradientUnits="userSpaceOnUse">
            <stop stopColor={colorForPlanId.stopColor1} />
            <stop offset={1} stopColor={colorForPlanId.stopColor2} />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};
