import * as colorStyles from "../PricingPlan.module.scss";
import * as styles from "./Description.module.scss";
import * as React from "react";
import { IPlan } from "src/lib/src/common/plans-old";

let classNames = require("classnames");

export const RichTextDescriptions = (props: {
  /**
   * plan used to determine which plan descriptions and prices for display
   * shorthand plans supported are starter, team, business, enterprise
   */
  plan: IPlan;
  /**
   * theme is used to determine which theme is being displayed
   */
  theme: string;
  /**
   * is used to determine how many stories are included in the plan description
   */
  dynamicStoryCount?: number;
  /**
   * tooltips is used to determine which tooltips are being displayed
   * they are displayed in the order of the descriptions
   * some tooltips have dynamic data, so they are passed in as props
   */
  tooltips?: string[];
}): JSX.Element => {
  const { plan, theme, tooltips = [] } = props;

  // commenting this out beaving this here as there is a chance that the dynamicStoryCount will be used in the future
  // const starterDisplayCount = `${Math.min(30, 1 + dynamicStoryCount)}${1 + dynamicStoryCount > 30 ? "+" : ""}`;
  // const teamDisplayCount = `${Math.max(5, Math.min(20, 1 + dynamicStoryCount))}${1 + dynamicStoryCount >= 21 ? "+" : ""}`;
  // const businessDisplayCount = `${Math.max(10, Math.min(30, 1 + dynamicStoryCount))}${1 + dynamicStoryCount > 30 ? "+" : ""}`;

  const getBullet = (fill: string): JSX.Element => {
    return (
      <svg width={5} height={5} fill="none" style={{ position: "relative", top: "9px" }}>
        <rect width="5" height="5" rx={2.5} fill={fill} />
      </svg>
    );
  };

  const descriptions = {
    starter: [
      <>
        Starts at <strong>1</strong> published story
      </>,
      <>
        <strong>Unlimited</strong> draft stories
      </>,
      <>
        Limited to <strong>1</strong> publishing destination
      </>,
      <>
        <strong>Unlimited</strong> users
      </>,
      <>
        Limited to <strong>1</strong> team
      </>,
    ],
    team: [
      <>
        Starts at <strong>5</strong> published stories
      </>,
      <>
        <strong>Unlimited</strong> draft stories
      </>,
      <>
        Limited to <strong>1</strong> publishing destination
      </>,
      <>
        Starts at <strong>3</strong> users
      </>,
      <>
        Starts at <strong>3</strong> teams
      </>,
    ],
    business: [
      <>
        Starts at <strong>10</strong> published stories
      </>,
      <>
        <strong>Unlimited</strong> draft stories
      </>,
      <>
        Starts at <strong>5</strong> publishing destinations
      </>,
      <>
        Starts at <strong>5</strong> users
      </>,
      <>
        Starts at <strong>5</strong> teams
      </>,
    ],
    enterprise: [
      <>Custom published stories</>,
      <>
        <strong>Unlimited</strong> draft stories
      </>,
      <>Custom publishing destinations</>,
      <>Custom users</>,
      <>Custom teams</>,
    ],
  };

  const getDescription = (planId: string): JSX.Element => {
    const toolTipStyle = planId == "business" ? styles.darkToolTip : styles.lightToolTip;
    const content = descriptions[planId].map((item, index) => (
      <li key={index} className={styles.listItem}>
        {tooltips[index] && <span className={toolTipStyle}>{tooltips[index]}</span>}
        {getBullet(planId == "business" ? "#FFF" : "#000")}
        <span key={`${planId}${index}`}>{item}</span>
      </li>
    ));

    return <ul className={styles.list}>{content}</ul>;
  };
  const richTextForPlan = getDescription(plan.id);
  return (
    <div
      className={classNames(
        styles.description,
        { [colorStyles.planDarkDescription]: theme === "dark" },
        { [colorStyles.planLightDescription]: theme === "light" }
      )}
    >
      <span>What&apos;s included:</span>
      <br /> <br />
      {richTextForPlan}
      <br />
    </div>
  );
};
