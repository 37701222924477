import { Footer } from "../../components/Footer";
import { Icon } from "../../components/Icon";
import { Layout } from "../../components/Layout";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default class IndustriesGovernment extends React.Component<any, any> {
  render() {
    const { allBrandsJson } = this.props.data;

    const brands = allBrandsJson.edges;

    return (
      <Layout location={this.props.location}>
        <main id="main" className="marketing-content container-fluid">
          <Helmet
            bodyAttributes={{
              class: "body-shorthand body-marketing body-marketing-secondary body-marketing-features body-light hide-elevio",
            }}
          ></Helmet>
          <div className="row section-title title-light">
            <div className="col-11 col-sm-8  mx-auto section-inner">
              <h1>
                <span className="shorthand-logo dark" aria-hidden="true">
                  Shorthand
                </span>
                Government communications
              </h1>
              <h2>Engage the public with immersive digital storytelling.</h2>
            </div>
          </div>
          <section className="row section-brands section-border-top">
            <div className="brands-list">
              <ul>
                <li>
                  <img src="/media/logos/clients/lg-who.png" loading="lazy" width="348" height="241" alt="WHO logo" />
                </li>
                <li>
                  <img src="/media/logos/clients/lg-hoc.png" loading="lazy" width="348" height="241" alt="House of Commons logo" />
                </li>
                <li>
                  <img
                    src="/media/logos/clients/lg-london-mayor.png"
                    loading="lazy"
                    width="348"
                    height="241"
                    alt="London Mayor logo"
                  />
                </li>
                <li>
                  <img src="/media/logos/clients/lg-eu.png" loading="lazy" width="348" height="241" alt="Council of the EU logo" />
                </li>

                <li>
                  <img
                    src="/media/logos/clients/lg-british-council.png"
                    loading="lazy"
                    width="348"
                    height="241"
                    alt="British Council logo"
                  />
                </li>
                <li className="hide-sm">
                  <img src="/media/logos/clients/lg-undp.png" loading="lazy" width="348" height="241" alt="UNDP logo" />
                </li>
              </ul>
            </div>
          </section>

          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Built for communicators</h3>
                  <p>Communicate policy, publicise events, and tell the stories that matter.</p>
                  <ul className="features-list">
                    <li>
                      Shorthand is built for communications professionals. Get started immediately &mdash; no developers required.
                    </li>
                    <li>Add rich imagery, video, and no-code animations with our built in story sections.</li>
                    <li>
                      Training comes free! Join us for regular free live training webinars and masterclasses, or take a plan that
                      includes personalised onboarding with white-glove support from our industry-leading customer success team.
                    </li>
                  </ul>
                  <a className="btn btn-secondary" href="https://shorthand.com/training/">
                    Join a free webinar
                  </a>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/industries/gov-grid-2-grey.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-right">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Your brand identity, your domain</h3>
                  <p>
                    On applicable plans, Shorthand&apos;s expert team will build a bespoke custom branded theme at your direction —
                    with your fonts, colors, styles, default header logos, and more.
                  </p>
                  <a className="btn btn-secondary" href="/features/branding/">
                    Learn more about branding
                  </a>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/industries/palette.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>A secure solution</h3>
                  <p>
                    Trusted by companies, universities, and governments the world over, Shorthand has industry-leading data security
                    and privacy policies and procedures.
                  </p>
                  <a className="btn btn-secondary" href="/features/security/">
                    Learn more about security
                  </a>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/industries/padlock-left-grey.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-right">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Budget-friendly</h3>
                  <p>
                    Say goodbye to unpredictable IT and consultant spend. With Shorthand, control your costs and unleash your
                    creativity with pricing that ensures you only pay for what you need.
                  </p>
                  <ul className="features-list">
                    <li>
                      Build amazing digital stories from within your existing communications team &mdash; no developers or web
                      designers required.
                    </li>
                    <li>On applicable plans, publish stories to multiple subdomains across your department or agency.</li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/industries/support-left-white2.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Accessible everywhere</h3>
                  <p>Shorthand stories support compliance with Web Content Accessibility Guidelines.</p>
                  <ul className="features-list">
                    <li>Shorthand stories use well-defined markup for screen readers.</li>
                    <li>Easily add additional metadata to describe visual information.</li>
                    <li>Shorthand stories natively adapt to work across multiple device types and screen resolutions.</li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/industries/transformed-right-grey.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section  section-strip section-strip-quote ">
            <div className="container container-sm">
              <div className="section-strip-col-single section-strip-single-quote">
                <div className="section-strip-quote-inner">
                  <blockquote>
                    &ldquo;For ease in creating custom multimedia storytelling experiences, Shorthand is the way to go. But what has
                    been as impressive as their platform is their customer support. They are always quick, efficient, and treat me and
                    my team with personal professionalism that is often lacking from tech companies.&rdquo;
                  </blockquote>
                  <div className="section-strip-quote-rating">
                    <ul>
                      <li>
                        <Icon icon="star" />
                      </li>
                      <li>
                        <Icon icon="star" />
                      </li>
                      <li>
                        <Icon icon="star" />
                      </li>
                      <li>
                        <Icon icon="star" />
                      </li>
                      <li>
                        <Icon icon="star" />
                      </li>
                    </ul>
                  </div>
                  <cite>Reviewer of G2</cite>
                  <a
                    href="https://www.g2.com/products/shorthand/reviews"
                    target="_blank"
                    rel="norefferer noopener"
                    className="btn btn-secondary"
                  >
                    Read more reviews on G2
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section className="row section  section-strip-stories section-border-top">
            <div className="container flex-column section-inner text-center">
              <h3>Take a look at these great stories from government comms teams, built with Shorthand</h3>
              <div className="featured-stories">
                <ul>
                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://www.mfat.govt.nz/en/trade/free-trade-agreements/free-trade-agreements-under-negotiation/new-zealand-united-kingdom-free-trade-agreement/new-zealand-united-kingdom-free-trade-agreement-overview/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/mfat-trade.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        New Zealand-United Kingdom Free Trade Agreement Overview
                        <span className="featured-story-brand">MFAT</span>
                      </h4>
                    </a>
                  </li>

                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://www.britishcouncil.org/work/partner/coding-mauritius"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/bc-bit.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        Do your :Bit
                        <span className="featured-story-brand">British Council</span>
                      </h4>
                    </a>
                  </li>

                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://www.consilium.europa.eu/en/eu-budget-story/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/eu-future.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        A blueprint for the future
                        <span className="featured-story-brand">Council of the European Union</span>
                      </h4>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="row section section-dark section-half section-trial !tw-bg-[rgba(249,249,249,0.3)]">
            <div className="container section-inner text-center">
              <h2 className="!tw-text-black">
                Ready to try the digital storytelling platform used by Honda, University of Cambridge, and the BBC?
              </h2>

              <div className="cta-container">
                <a href="https://app.shorthand.com/signup" className="btn btn-lg !tw-bg-black hover:!tw-opacity-70">
                  Start creating
                </a>
              </div>
              <div className="brands">
                <div className="brands-container">
                  <h3 className="as-used">Join these iconic brands</h3>
                  <ul>
                    {brands.map((brand, index) => (
                      <li key={`brand-${brand.node.label}-${index}`} className={`brand`}>
                        <img src={brand.node.logo} alt={`${brand.node.label} logo`} />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div aria-hidden="true" className="background-media background-media-fixed">
              <img src="/media/home/stories.jpg" className="ObjectFit--cover" alt="" />
            </div>
          </section>

          <Footer className="mt-0" />
        </main>
      </Layout>
    );
  }
}

export const query = graphql`
  query IndustriesGovernmentQuery {
    site {
      siteMetadata {
        name
        title
        tagline
        description
      }
    }
    allBrandsJson {
      edges {
        node {
          logo
          name
          label
        }
      }
    }
  }
`;
