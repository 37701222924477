import * as colorStyles from "../PricingPlan.module.scss";
import * as styles from "./Price.module.scss";
import * as React from "react";

let classNames = require("classnames");

interface PriceProps {
  period: "monthly" | "yearly";
  userCurrency: { name: string; label: string };
  theme?: "light" | "dark";
  dynamicPrice?: number;
  children?: React.ReactNode;
}

export const symbolForPlanCurrency = {
  USD: "$",
  AUD: "$",
  GBP: "£",
  EUR: "€",
};

export const Price = ({ period, userCurrency, theme, dynamicPrice, children }: PriceProps): JSX.Element => {
  const payCycle = { ["monthly"]: "month", ["yearly"]: "year" }[period];

  let payCycleStyles = colorStyles.planLightPayCycle;
  if (theme == "dark") {
    payCycleStyles = colorStyles.planDarkPayCycle;
  }

  return (
    <div className={styles.wrapper}>
      <div>
        <span
          className={classNames(styles.price, {
            [colorStyles.planDarkPrice]: theme == "dark",
          })}
        >
          {symbolForPlanCurrency[userCurrency.label]}
          {dynamicPrice}
        </span>

        <span className={classNames(styles.payCycle, payCycleStyles)}>/{payCycle}</span>
      </div>
      <div>{children}</div>
    </div>
  );
};
