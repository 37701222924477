import { GatsbyImage, IGatsbyImageData, StaticImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import { twMerge } from "tailwind-merge";

export default function FooterBanner2({
  image,
  title = "",
  cta = { text: "", link: "" },
  fontSize = "normal",
}: {
  title: string;
  cta: { text: string; link: string };
  image?: { url: IGatsbyImageData; alt: string };
  fontSize?: "small" | "normal";
}) {
  return (
    <div className="tw-bg-black tw-py-24 max-sm:tw-py-20">
      <div
        className="tw-container tw-grid tw-grid-cols-[minmax(0,6fr)_minmax(0,4fr)] tw-items-center 
      max-lg:tw-grid-cols-1 max-lg:tw-gap-y-10"
      >
        {image ? (
          <GatsbyImage
            image={getImage(image.url)}
            alt={image.alt}
            objectFit="contain"
            className="max-lg:tw-mx-auto max-lg:tw-w-[80%]"
          />
        ) : (
          <StaticImage
            src="../../../static/media/digital-magazine/footer.png"
            alt=""
            aria-hidden="true"
            placeholder="blurred"
            width={720}
            height={480}
            className="max-lg:tw-mx-auto max-lg:tw-w-[80%]"
            objectFit="contain"
          />
        )}
        <div className="tw-pl-[20%] max-lg:tw-pl-0 max-lg:tw-text-center">
          <h2 className={twMerge("!tw-h2 tw-text-white", fontSize === "small" && "!tw-h4 !tw-font-normal")}>{title}</h2>
          <a href={cta.link} className="btn tw-button  btn-white !tw-mt-12 hover:!tw-bg-black hover:!tw-text-white">
            {cta.text}
          </a>
        </div>
      </div>
    </div>
  );
}
