import { filterListByCurrentIndustryCode } from "../../Utils";
import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { RelatedCaseStudies } from "../../components/RelatedCaseStudies";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default function CaseStudy({ data, location }) {
  return (
    <Layout location={location}>
      <main id="main" className="marketing-content container-fluid">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-secondary body-marketing-case-studies hide-elevio",
          }}
        ></Helmet>
        <div className="row section-title">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6 mx-auto  section-inner">
            <h1>
              <span className="shorthand-logo">Shorthand</span>
              <span className="subsection-label">Case Study:</span>
              University of Queensland
            </h1>
            <h2>
              How Shorthand has revolutionised the University of Queensland’s online storytelling across communications, marketing and
              e-learning
            </h2>
          </div>
          <div aria-hidden="true" className="background-media">
            <img
              src="/media/case-studies/case-studies-uq-title.jpg"
              data-object-fit="cover"
              data-autoscale-content-ratio="1.77777777778"
              alt=""
            />
          </div>
        </div>
        <div className="row section">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto marketing-main-column">
            <h3 className="intro">
              From sharing research to delivering online learning, the University of Queensland has harnessed the power of visual
              storytelling through Shorthand.
            </h3>

            <div className="inline-image featured">
              <img
                src="/media/case-studies/case-studies-uq.jpg"
                alt="So long super bugs - crowdsourcing is here, by the University of Queensland, renders responsively across all devices"
              />
            </div>

            <p>
              They are now able to create a rich variety of stories, in a short space of time, and draw people into the university’s
              activities through compelling online experiences.
            </p>
            <p>
              It all started with UQ’s Small Change blog. Looking to transform its online articles, the team turned to Shorthand
              &mdash; which coincidentally is led by UQ alumnus Ricky Robinson.
            </p>
            <p>
              Use of Shorthand has since spread to other university publications, such as{" "}
              <a href="https://www.uq.edu.au/research/impact" target="_blank" rel="noopener noreferrer" className="btn-link">
                Research Impact
              </a>
              , which features Shorthand article releases once every month.
            </p>
            <p>
              “Shorthand has provided a slick, modern, interactive and media-rich interface to liven up UQ’s magazine content,” UQ
              corporate publications manager Camille Layt said.
            </p>

            <figure className="block quote-block offset left">
              <blockquote>
                <p>“Shorthand has provided a slick, modern, interactive and media-rich interface to liven up UQ’s magazine content”</p>
              </blockquote>
              <figcaption className="quote-cite">Camille Layt</figcaption>
            </figure>
            <p>
              Other stories by the marketing and communications team include articles to promote the university’s{" "}
              <a href="https://www.uq.edu.au/changemakers" target="_blank" rel="noopener noreferrer" className="btn-link">
                ChangeMakers
              </a>
              , as well as taking audiences behind the scenes.
            </p>
            <p>
              “It’s a dream for our feature stories in online publications,” Camille said. “We’ve found ways to standardise stories and
              give each publication its own distinct identity, with embedded mastheads, and consistent sections across stories, without
              interfering with the quality of the content or imagery.”
            </p>
            <p>
              “Shorthand has opened new doors when it comes to image-rich material,” UQ’s corporate relations manager Carolyn Varley
              added. “And we are learning more all the time, just as Shorthand is being refined to give us greater flexibility and more
              options.
            </p>
            <figure className="block quote-block offset right">
              <blockquote>
                <p>
                  "Shorthand is playful and colourful and allows a more intimate connectivity to the text, and liberates the images to
                  play a major role in telling the story."
                </p>
              </blockquote>
              <figcaption className="quote-cite">Camille Layt</figcaption>
            </figure>
            <p>
              “It’s playful and colourful and allows a more intimate connectivity to the text, and liberates the images to play a major
              role in telling the story.”
            </p>
            <p>
              Because the teams do not require a design or developer resource to create the pages, Shorthand has sped up the creation
              and approval process – which is particularly valuable when compiling an online magazine of up to 20 stories at once.
            </p>
            <p>“The stories can be remarkably quick to produce once the images and text have been finalised,” Carolyn said.</p>
            <div className="photo-grid flex featured">
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-uq-sml-01.jpg"
                alt="A section from one of the University of Queensland's stories"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-uq-sml-02.jpg"
                alt="A section from one of the University of Queensland's stories"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-uq-sml-04.jpg"
                alt="A section from one of the University of Queensland's stories"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-uq-sml-03.jpg"
                alt="A section from one of the University of Queensland's stories"
              />
            </div>
            <div className="caption">A selection of sections from the University of Queensland's stories</div>

            <p>
              “The speed of the production process is great for tight deadlines and also for that last-minute story that we need to get
              up before a conference, or just as new research breaks. In most cases we will use Shorthand on our blog – if the images
              warrant it.”
            </p>
            <p>
              The assembling of a story in Shorthand takes UQ around two to four hours in total, but as well as a fast turnaround, the
              end result is beautifully compelling.
            </p>
            <p>
              “It’s certainly more efficient than our previous method of sending copy and images to a web designer for upload,” Carolyn
              said, “and produces a much more impressive visual that we have complete creative control over (and ability to easily
              correct errors as well, which is a godsend!)”
            </p>

            <figure className="block quote-block offset left">
              <blockquote>
                <p>
                  “I’d call it revolutionary. It’s completely changed the way we create digital content, and our engagement has
                  increased dramatically for the better, with people going out of their way to tell us how beautiful the Shorthand
                  stories are.”
                </p>
              </blockquote>
              <figcaption className="quote-cite">Camille Layt</figcaption>
            </figure>

            <p>
              Engagement has been a key measure of success for the team, but as well as that, the team is enjoying the chance to get
              creative.
            </p>

            <p>
              “I’d call it revolutionary,” Camille said, “It’s completely changed the way we create digital content, and our engagement
              has increased dramatically for the better, with people going out of their way to tell us how beautiful the Shorthand
              stories are.”
            </p>
            <p>
              Beyond marketing and communications, the university is also using Shorthand to apply visual storytelling techniques to
              the e-learning arena.
            </p>
            <p>
              Their masterclass stories feature bite-size modules, driven by videos and animations that develop as the reader scrolls,
              as well as links out to wider reading.
            </p>
            <p>
              “We normally create online courses with lots of different media and interactivity,” UQx course project manager Catherine
              Bennett said. “Shorthand lets us use the same types of media but present them in a different and engaging way.”
            </p>
            <p>
              The top-to-bottom scrolling of a Shorthand story allows the team to deliver an easy-to-follow route through the
              information that the reader can travel through at their own pace.
            </p>
            <p>
              “The presentation and navigation is very attractive, easy to use and responsive to different device types and sizes. The
              interface is minimalistic which is good for learning as there are fewer distractions when engaging with the content,”
              Catherine explained.
            </p>
            <p>
              The e-learning narratives are kept relatively trim, offering an introduction to a concept, theory or strategy, and the
              analytics show that the materials are resonating with readers, with average dwell times of more than five minutes.
            </p>
            <p>
              “The format encourages users to dip in and out, and its optimisation for mobile is a big selling point for our target
              market of undergraduate students,” Catherine said.
            </p>
            <p>“It’s incredibly user-friendly and there are lots of best-practice guidelines to help you make a good story.”</p>
          </div>
        </div>
        <RelatedCaseStudies studies={filterListByCurrentIndustryCode(data, "uq")} />
        <Footer />
      </main>
    </Layout>
  );
}

export const query = graphql`
  query uqQuery {
    allCaseStudiesJson {
      edges {
        node {
          jsonId
          image
          industry
          industryCode
          organisation
          summary
        }
      }
    }
  }
`;
