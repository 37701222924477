import { filterListByCurrentIndustryCode } from "../../Utils";
import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { RelatedCaseStudies } from "../../components/RelatedCaseStudies";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default function CaseStudy({ data, location }) {
  return (
    <Layout location={location}>
      <main id="main" className="marketing-content container-fluid">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-secondary body-marketing-case-studies hide-elevio",
          }}
        />
        <div className="row section-title">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6 mx-auto  section-inner">
            <h1>
              <span className="shorthand-logo">Shorthand</span>
              <span className="subsection-label">Case Study:</span>
              WWF
            </h1>
            <h2>How WWF used Shorthand to empower staff in their storytelling and reward supporters</h2>
          </div>
          <div aria-hidden="true" className="background-media">
            <img
              src="/media/case-studies/case-studies-wwf-title.jpg"
              data-object-fit="cover"
              data-autoscale-content-ratio="1.77777777778"
              alt=""
            />
          </div>
        </div>
        <div className="row section">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto marketing-main-column">
            <h3 className="intro">
              Since starting to use Shorthand in 2014, WWF has diversified its use of Shorthand to bring a range of stories to life,
              from public campaigns to internal communications.
            </h3>
            <div className="inline-image featured">
              <img
                src="/media/case-studies/case-studies-wwf.jpg"
                alt="Living Blue Planet, by WWF, renders responsively across all devices"
              />
            </div>
            <p>
              By using Shorthand they have turned end-of-year reports into media-rich round-ups of the events of the past 12 months,
              and the achievements of those supporting the charity.
            </p>
            <p>
              Studies and research findings are also transformed with compelling media alongside stark statistics, such as the
              charity’s{" "}
              <a
                href="https://assets.wwf.org.uk/custom/stories/living_blue_planet/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                Living Blue Planet report
              </a>
              , which took readers underwater to raise awareness of the plight of its inhabitants.
            </p>
            <p>
              The story was brought to life with stunning full-screen images and autoplay video, and the team also used Shorthand's
              developer tools to embed an adoption interactive at the end to encourage ongoing engagement with the charity.
            </p>
            <figure className="quote-block offset left">
              <blockquote>
                <p>
                  “Shorthand allows us to engage our supporters through captivating and immersive stories about our work around the
                  globe”.
                </p>
              </blockquote>
              <figcaption className="quote-cite">Joe Dix</figcaption>
            </figure>
            <p>
              At the same time they have also experimented with using visual storytelling in Shorthand to turn around shorter-form
              stories, such as their list of{" "}
              <a
                href="https://assets.wwf.org.uk/custom/stories/climatechangemyths/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                10 weird climate change myths
              </a>
              .
            </p>
            <p>
              “Shorthand allows us to engage our supporters through captivating and immersive stories about our work around the globe”,
              Joe Dix, digital brand and design specialist at WWF told Shorthand.
            </p>
            <p>
              And the simplicity of the Shorthand editor has enabled more staff at WWF to get involved in storytelling, without any
              technical restrictions.
            </p>
            <div className="photo-grid flex featured">
              <img
                alt="A section from one of WWF's stories"
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-wwf-sml-01.jpg"
              />
              <img
                alt="A section from one of WWF's stories"
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-wwf-sml-02.jpg"
              />
              <img
                alt="A section from one of WWF's stories"
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-wwf-sml-04.jpg"
              />
              <img
                alt="A section from one of WWF's stories"
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-wwf-sml-03.jpg"
              />
            </div>
            <div className="caption">A selection of sections from WWF's stories</div>
            <p>
              “The great design and simple to use interface has empowered staff across the organisation to easily generate content they
              know will interest our visitors", Joe added.
            </p>
            <div className="related-stories">
              <h4>More Shorthand stories from WWF:</h4>
              <ul>
                <li>
                  <a
                    href="https://assets.wwf.org.uk/custom/stories/thanksfor2015/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-link"
                  >
                    You’ve made this year great
                  </a>
                </li>
                <li>
                  <a
                    href="https://assets.wwf.org.uk/custom/stories/jacksgap/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-link"
                  >
                    Jack and Finn's Arctic mission
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <RelatedCaseStudies studies={filterListByCurrentIndustryCode(data, "wwf")} />
        <Footer />
      </main>
    </Layout>
  );
}

export const query = graphql`
  query wwfQuery {
    allCaseStudiesJson {
      edges {
        node {
          jsonId
          image
          industry
          industryCode
          organisation
          summary
        }
      }
    }
  }
`;
