declare const cookiehub;

declare function gtag(type: string, name: string, data?: any);

interface TrackData {
  [key: string]: unknown;
}

export const GA_ID = "UA-42055132-1";

/*
 |--------------------------------------------------------------------------------
 | Google Tag Manager tracking
 | - Marketing's version is slightly different to app as it uses Gatsby's GTM
 |   plugin.  Note that marketing doesn't have useCase, orgSize, plans so
 |   we track enquiry with a smaller set of values.
 |--------------------------------------------------------------------------------
 */
declare global {
  interface Window {
    dataLayer: Array<Record<string, unknown>>;
  }
}

/**
 * Pushes tracking data to Google Tag Manager's dataLayer.
 * @param data - The tracking data to be sent.
 * @param event - The event name to be sent with the data.
 */
function pushToDataLayer(data: TrackData, event: string): void {
  window.dataLayer.push({ ...data, event });
}

/**
 * Tracks the Google Tag Manager event.
 * @param data - The tracking data to be sent.
 * @param event - The event name to be sent with the data.
 */
export function trackGtm(data: TrackData, event: string): void {
  pushToDataLayer(data, event);
}

export function trackEnquiry(enquiryData: {
  email: string;
  text: string;
  hearAbout: string;
  firstName: string;
  lastName: string;
  company: string;
  contactPhone: string;
  jobTitle: string;
  enquiryType: string;
  interestedIn: string;
  location: string;
}) {
  trackGtm(
    {
      email: enquiryData.email,
      text: enquiryData.text,
      hearAbout: enquiryData.hearAbout,
      firstName: enquiryData.firstName,
      lastName: enquiryData.lastName,
      company: enquiryData.company,
      contactPhone: enquiryData.contactPhone,
      jobTitle: enquiryData.jobTitle,
      enquiryType: enquiryData.enquiryType,
      interestedIn: enquiryData.interestedIn,
      location: enquiryData.location,
    },
    "enquiry-marketing"
  );
}

/// -- Google Analytics -- ///

/**
 * Load the GA (the GTM version)
 */
export function loadGTag(): void {
  if (!hasConsentedToAnalytics()) return;

  const doesExist = document.querySelector(`script[src^="https://www.googletagmanager.com/gtag/js?id"]`);
  if (!doesExist) {
    const refScript = document.createElement("script");
    refScript.async = true;
    refScript.src = `https://www.googletagmanager.com/gtag/js?id=${GA_ID}`;
    document.head.appendChild(refScript);

    const script = document.createElement("script");
    script.innerText = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);}gtag('js',new Date());gtag('config','${GA_ID}');`;
    document.head.appendChild(script);
  } else {
    trackPage();
  }
}

/**
 * Check whether the user has consented to analytics cookies
 */
export function hasConsentedToAnalytics(): boolean {
  return typeof cookiehub !== "undefined" && cookiehub.hasConsented("analytics");
}

/**
 * Open cookie consent settings
 */
export function openCookieSettings(): void {
  cookiehub.openSettings();
}

/**
 * Track a page view
 */
export function trackPage() {
  if (!hasConsentedToAnalytics()) return;

  gtag("config", GA_ID);
}

/**
 * Track a GA event
 * @param name
 * @param event_category
 * @param label
 */
export function trackEvent(name: string, event_category: string, label: string = ""): void {
  if (!hasConsentedToAnalytics()) return;

  gtag("event", name, { event_category, label });
}

/**
 Saves last viewed utm data to local storage
 @returns localStorage object "sh_utm"
 */
export const getUTMData = (): void => {
  const utm = new URLSearchParams(location.search);
  const utmData = {
    source: utm.get("utm_source"),
    medium: utm.get("utm_medium"),
    campaign: utm.get("utm_campaign"),
    content: utm.get("utm_content"),
    term: utm.get("utm_term"),
  };
  const sh_utm = {};

  if (typeof window !== "undefined") {
    for (const key in utmData) {
      if (utmData[key]) {
        sh_utm[key] = utmData[key];
      }
    }
    // update local storage if not empty
    if (utmData.source || utmData.medium || utmData.campaign || utmData.content || utmData.term) {
      return window.localStorage.setItem("sh_utm", JSON.stringify(sh_utm));
    }
  }
  return null;
};
