import { Header } from "./Header";
import { IMainTemplateProps, getEdge, getMetaField, getMetaFields } from "./Layout";
import { StaticQuery, graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";
import { twMerge } from "tailwind-merge";

export const LayoutNew: React.FC<IMainTemplateProps> = ({ children, location, header = true }) => {
  return (
    <StaticQuery
      query={layoutQuery}
      render={data => {
        const edge = getEdge(data.allSeoJson.edges, location.pathname);
        return (
          <div>
            <Helmet title={getMetaField("title", edge).content} meta={getMetaFields(edge)} />
            <div className="body-shorthand body-marketing body-marketing-home body-light hide-elevio">
              {header && <Header theme="light-theme" />}
            </div>
            <div className={twMerge(header && "tw-pt-[46px] max-lg:tw-pt-0")}>{children}</div>
          </div>
        );
      }}
    />
  );
};

const layoutQuery = graphql`
  query LayoutQuery {
    site {
      siteMetadata {
        name
        title
        tagline
        description
      }
    }
    allSeoJson {
      edges {
        node {
          pathname
          title
          description
          image
          ogImage
          ogTitle
          ogDescription
          twitter
        }
      }
    }
  }
`;
