import { IPlan, IPriceStructure } from ".";

export const business: IPlan = {
  id: "business",
  name: "Business",
  description:
    "Per active user. Minimum of one active user. Includes 5 published stories. Additional published stories: $25/month for the next 50 stories then $5/month.",
  chargebee: new Set([
    "business-monthly-aud",
    "business-monthly-usd",
    "business-monthly-eur",
    "business-monthly-gbp",
    "business-annual-aud",
    "business-annual-usd",
    "business-annual-eur",
    "business-annual-gbp",
  ]),
  features: {
    "organisation-manage-publish-configurations": true,
    "organisation-team-leader-view": true,
    "organisation-team-limit": 5,
    "organisation-member-limit": 200,
    "organisation-team-member-limit": 200,
    "organisation-sites": true,
    "page-settings-amp-enabled": false,
    "page-settings-analytics-ga": true,
    "page-settings-override-social-cover-url": true,
    "shorthand-analytics-tracking": true,
    "story-credits-required": true,
    "themes-demo": false,
    "publish-embed-ui": true,
    "story-comments": true,
    "theme-builder": true,
    "page-settings-password-protect-previews": true,
    "custom-hosting-limit": 5,
    "publish-configurations-download": true,
    "page-settings-analytics-gtm": true,
    "developer-tools": true,
    "page-settings-custom-head": true,
    "shorthand-secure-hosting-enabled": true,
    "organisation-story-analytics-export": true,
    "organisation-workspace-analytics-export": true,
    "project-search-nav": true,
  },
  marketing: {
    label: "Access publishing integrations & handcrafted branded themes.",
    className: "plan-business",
    benefits: [
      "Volume publishing discounts",
      "Self-hosted publishing options",
      "Secure publishing destinations",
      "Advanced integrations & 3rd party analytics",
      "Workspace analytics exports",
      "Custom HTML & developer tools",
      "Bespoke custom branded theme",
      "Team onboarding",
      "Story reviews",
    ],

    prices: {
      planPrice: {
        monthly: { USD: 800, AUD: 666, GBP: 666, EUR: 666 },
        yearly: { USD: 640, AUD: 666, GBP: 666, EUR: 666 },
        nfp: { USD: 480, AUD: 666, GBP: 666, EUR: 666 },
      },
      userAddonPrice: {
        monthly: { USD: 100, AUD: 666, GBP: 666, EUR: 666 },
        yearly: { USD: 80, AUD: 666, GBP: 666, EUR: 666 },
        nfp: { USD: 60, AUD: 666, GBP: 666, EUR: 666 },
      },
      publishAddonPrice: {
        monthly: { USD: 15, AUD: 666, GBP: 666, EUR: 666 },
        yearly: { USD: 12, AUD: 666, GBP: 666, EUR: 666 },
        nfp: { USD: 9, AUD: 666, GBP: 666, EUR: 666 },
      },
    } as IPriceStructure,
  },
};
