import * as React from "react";

/**
 * determine if the screen is mobile
 * @param {number} breakpoint the size that this hook will look for to determine if the screen is mobile
 * @returns
 */
export const useIsMobile = (breakpoint: number) => {
  const [isMobile, setIsMobile] = React.useState(false);
  React.useEffect(() => {
    function handleResize() {
      if (window.innerWidth < breakpoint) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
};
