// extracted by mini-css-extract-plugin
export var btnPrimary = "PricingPlan-module--btn-primary--6ab77";
export var btnSecondary = "PricingPlan-module--btn-secondary--bc0ff";
export var btnWhite = "PricingPlan-module--btn-white--e5737";
export var plan = "PricingPlan-module--plan--6f6db";
export var planDark = "PricingPlan-module--plan--dark--61683";
export var planDarkByline = "PricingPlan-module--plan--dark--byline--4ab30";
export var planDarkDescription = "PricingPlan-module--plan--dark--description--52c2b";
export var planDarkFeaturesListItem = "PricingPlan-module--plan--dark--features--listItem--cf6c0";
export var planDarkFeaturesTitle = "PricingPlan-module--plan--dark--features--title--f9944";
export var planDarkPayCycle = "PricingPlan-module--plan--dark--payCycle--5c82d";
export var planDarkPerStoryPrice = "PricingPlan-module--plan--dark--perStoryPrice--24a87";
export var planDarkPrice = "PricingPlan-module--plan--dark--price--95284";
export var planDarkSubTitle = "PricingPlan-module--plan--dark--subTitle--5d03f";
export var planDarkTitle = "PricingPlan-module--plan--dark--title--6da29";
export var planLight = "PricingPlan-module--plan--light--62b3f";
export var planLightByline = "PricingPlan-module--plan--light--byline--aaacf";
export var planLightDescription = "PricingPlan-module--plan--light--description--0e5d7";
export var planLightFeaturesListItem = "PricingPlan-module--plan--light--features--listItem--04bb1";
export var planLightFeaturesTitle = "PricingPlan-module--plan--light--features--title--56a3e";
export var planLightPayCycle = "PricingPlan-module--plan--light--payCycle--9dcb0";
export var planLightPerStoryPrice = "PricingPlan-module--plan--light--perStoryPrice--1e2b9";
export var planLightPrice = "PricingPlan-module--plan--light--price--d215c";
export var planLightSubTitle = "PricingPlan-module--plan--light--subTitle--1a651";
export var planLightTitle = "PricingPlan-module--plan--light--title--54eb5";
export var subTitle = "PricingPlan-module--subTitle--58ef4";