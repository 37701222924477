import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { LegalNav } from "../../components/LegalNav";
import { Link } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default class TermsArchivedView extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <main id="main" className="marketing-content container-fluid">
          <Helmet
            bodyAttributes={{
              class: "body-shorthand body-marketing body-marketing-secondary body-light body-marketing-legal hide-elevio",
            }}
          >
            <title>Terms | Shorthand</title>
            <meta name="description" content="Shorthand terms of service." />
          </Helmet>
          <div className="row section-title title-light">
            <div className="col-12 col-sm-10 col-lg-8 col-xxl-6 mx-auto">
              <div className="alert alert-danger text-center mb-4">
                <p>
                  This is an archived version of our Customer Terms of Service.{" "}
                  <Link to="/legal/customer-terms">View the current Customer Terms of Service here</Link>.
                </p>
              </div>
              <h1>
                <span className="shorthand-logo dark" aria-hidden="true">
                  Shorthand
                </span>{" "}
                Customer Terms of Service
              </h1>

              <p>
                <small className="small-text text-muted">Last Modified: 19 September 2019</small>
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="d-none d-lg-block col-lg-2">
              <LegalNav />
            </div>
            <div className="col-12 col-sm-10 col-lg-8 col-xxl-6">
              <section>
                <h2>1. Acceptance</h2>
                <ol className="letters">
                  <li>
                    Thank you so much for using Shorthand's products! Shorthand Pty Ltd ABN 88 162 625 470 ("<strong>we</strong>" or "
                    <strong>us</strong>") owns and provides online digital content creation and collaboration Services, which are
                    accessible at https://shorthand.com/ ("<strong>Site</strong>
                    ").
                  </li>
                  <li>
                    These Shorthand Terms of Service (these "<strong>Terms</strong>"):
                    <ol className="numbers">
                      <li>set out the terms and conditions upon which we agree to grant you a right to access the Services; and</li>
                      <li>
                        are binding on you from the date ("
                        <strong>Effective Date</strong>") on which we provide you with an account to enable you to access and use the
                        Services ("<strong>Account</strong>") until the date on which your Account is terminated in accordance with
                        these Terms (the "<strong>Term</strong>").
                      </li>
                    </ol>
                  </li>
                  <li>
                    You accept these Terms by:
                    <ol className="numbers">
                      <li>clicking a button or checking a box indicating acceptance; or</li>
                      <li>signing and returning an order form we provide to you.</li>
                    </ol>
                  </li>
                  <li>
                    By accepting these Terms, creating an Account and/or accessing and/or using the Services, you:
                    <ol className="numbers">
                      <li>
                        warrant to us that you have reviewed these Terms, including our Privacy Policy, available on the Site and that
                        you understand them;
                      </li>
                      <li>warrant to us that you have the legal capacity to enter into a legally binding agreement;</li>
                      <li>
                        warrant to us that you have the authority to act on behalf of any person or entity for whom you are using the
                        Services, and you are deemed to have agreed to these Terms on behalf of any entity for whom you use the
                        Services;
                      </li>
                      <li>
                        warrant to us that you have all hardware, software and services which are necessary to access and use the
                        Services; and
                      </li>
                      <li>agree to use the Services in accordance with these Terms.</li>
                    </ol>
                  </li>
                </ol>
              </section>

              <section>
                <h2>2. Term</h2>
                <ol className="letters">
                  <li>
                    These Terms are effective as of the Effective Date and expire on the date your Account is terminated in accordance
                    with these Terms.
                  </li>
                </ol>
              </section>

              <section>
                <h2>3. Set Up Service and Accounts</h2>
                <ol className="letters">
                  <li>
                    As part of the set up process, you will create an Account, in order for you and your Authorised Users to access and
                    use the Services. You must ensure that any information you provide to us, or we request from you as part of the
                    set-up process is complete and accurate.
                  </li>
                  <li>
                    It is your responsibility to keep your Account details confidential. You are responsible for all activity on your
                    Account, including activity by Authorised Users and for ensuring that any activities on your Account comply with
                    these Terms.
                  </li>
                  <li>
                    Account Administrators: You may nominate certain users to be administrators with permissions to exercise control
                    over your user Accounts and configure certain aspects of the Services. These permissions include enabling Services,
                    adding and removing Authorised Users, and setting permissions for these Authorised Users. The first user in your
                    customer Account is a special administrator called the owner. The owner is responsible for inviting the initial set
                    of Authorised Users to the Services, and nominating additional administrators. You are responsible for who becomes
                    an administrator, including the owner, and the actions they take. Although we may, on occasion, offer to take
                    administration actions on your behalf, you agree that we are not responsible for administering or managing your
                    customer account for you.
                  </li>
                  <li>
                    Third Party Administrators: You are solely responsible for the actions of any third party, including but not
                    limited to resellers of the Services, who acts as an administrator on your behalf.
                  </li>
                  <li>You are responsible for ensuring that all administrators and Authorised Users are at least 16 years old.</li>
                  <li>
                    You agree that we are a software as a service provider and we are not responsible for the management or
                    administration of your Account.
                  </li>
                </ol>
              </section>

              <section>
                <h2>4. Services</h2>
                <ol className="letters">
                  <li>
                    In consideration for payment of the Fees, we grant you a non-exclusive, non-transferrable (except as otherwise
                    permitted under these Terms), personal and revocable right to access the Services for the Term.
                  </li>
                  <li>
                    Subject to the restrictions of your chosen Plan, we grant you a non-exclusive, non-transferrable (except as
                    otherwise permitted under these Terms), personal and revocable licence to download content created by you using the
                    Services, including any of our proprietary code contained in that content, for the sole purpose of serving that
                    content to your audience via your own server infrastructure. You may not modify the proprietary code contained in
                    the downloaded content in any way.
                  </li>
                  <li>
                    We may make software components available, via app stores or other channels, as part of the Services. We grant to
                    you a non-sublicensable, non-transferable, non-exclusive, limited license to use the object code version of these
                    components, but solely as necessary to use the Services and in accordance with these Terms. All of our rights not
                    expressly granted by this license are hereby retained.
                  </li>
                </ol>
              </section>

              <section>
                <h2>5. Restrictions</h2>
                <ol className="letters">
                  <li>
                    You must not (and must ensure your Authorised Users do not) access or use the Services except as permitted by these
                    Terms and you must not and must not permit any other person to:
                    <ol className="numbers">
                      <li>
                        use the Services in any way which is in breach of any applicable Laws or which infringes any person's rights,
                        including Intellectual Property rights;
                      </li>
                      <li>
                        use the Services to transmit, publish or communicate material that is defamatory, offensive, abusive, indecent
                        or menacing;
                      </li>
                      <li>use the Services in any way that damages, interferes with or interrupts the supply of the Services;</li>
                      <li>
                        remove, obfuscate or obscure any proprietary notices contained in the platform or in the content created by you
                        using the Services, including but not limited to the "Fixed Footer" containing our logo and associated text
                        that appears in published Shorthand stories
                      </li>
                      <li>remove or alter any tracking codes included by us in the content created by you using the Services;</li>
                      <li>attempt to undermine the security of the Services;</li>
                      <li>
                        introduce malicious programs into our hardware and software or Systems, including viruses, worms, trojan horses
                        and e-mail bombs;
                      </li>
                      <li>
                        reveal your Account’s password to others or allow others to use your Account (other than Authorised Users);
                      </li>
                      <li>
                        use the Services to carry out security breaches or disruptions of a network. Security breaches include
                        accessing data where you are not the intended recipient or logging into a server or account that you are not
                        expressly authorised to access or corrupting any data (including network sniffing/monitoring, pinged floods,
                        packet spoofing, denial of service and forged routing information for malicious purposes); or
                      </li>
                      <li>
                        use any program/script/command, or send messages of any kind, with the intent to interfere with, or disable,
                        any person’s use of the Services.
                      </li>
                    </ol>
                  </li>
                </ol>
              </section>

              <section>
                <h2>6. Third Parties</h2>
                <ol className="letters">
                  <li>
                    You acknowledge and agree that:
                    <ol className="numbers">
                      <li>
                        the provision of the Services may be contingent on, or impacted by, third parties, other customers’ use of our
                        services, suppliers, other subcontractors (Third Party Inputs); and
                      </li>
                      <li>
                        despite anything to the contrary, to the maximum extent permitted by law, we will not be responsible, and will
                        have no Liability, for any default or breach of these Terms or law, if such default or breach was caused or
                        contributed to by any Third-Party Inputs.
                      </li>
                      <li>
                        You may choose to integrate the Services with other tools or platforms via our API or other methods. Although
                        we may provide advice and/or support regarding integration of the Services with other tools or platforms, you
                        agree that we are not responsible for carrying out or managing any integration. To the extent that you choose
                        to integrate the Services with other tools or platforms via our API or other methods, you are responsible for:
                        (i) the purchase of, (ii) the ancillary requirements related to, and (iii) the licensing obligations related to
                        the applicable third party software and services. It is your responsibility to ensure the requirements are met
                        in order for you to benefit from the specific functionality made available to you.
                      </li>
                    </ol>
                  </li>
                  <li>This clause will survive the termination or expiry of these Terms.</li>
                </ol>
              </section>

              <section>
                <h2>7. Development Services</h2>
                <ol className="letters">
                  <li>
                    If, during the Term, you require any changes to the scope, functionality or nature of the Services or any bespoke
                    customisations to the Services, you must notify us of the changes you require. Following notification, at our
                    discretion, we may provide you with written notice in the form of a statement of work covering (i) the changes
                    required to the Service, (ii) the development services required in respect of such changes (Development Services)
                    and (iii) the fee for required for us to undertake such Development Services (Development Fee). If you agree to the
                    changes to the Services, the Development Services and the Development Fee then, subject to the Parties signing a
                    statement of work and these Terms, we will provide the Development Services to you in consideration for payment of
                    the Development Fee.
                  </li>
                </ol>
              </section>

              <section>
                <h2>8. Support Services</h2>
                <ol className="letters">
                  <li>
                    During the Term, we will provide you with the support services for your chosen Plan set out on the Site during the
                    support hours set out on the Site, provided that (i) you notify us in accordance with any applicable systems and
                    processes set out on the Site and (ii) where required, you assist us in investigating and ascertaining the cause of
                    the fault and provide us with access to all necessary information relevant to the fault (including what you or your
                    personnel have done in relation to the fault). The Shorthand helpdesk can be reached by sending email to
                    help@shorthand.com.
                  </li>
                  <li>
                    We may also offer premium support add-ons at an additional cost, the specifications of which are made available in
                    the description of the add-on on the Site and/or in separate policy documents for the add-on.
                  </li>
                </ol>
              </section>

              <section>
                <h2>9. Privacy</h2>
                <ol className="letters">
                  <li>
                    We take certain measures to keep your data secure, and implement data and privacy protection policies in compliance
                    with the laws of those jurisdictions in which we operate, including, but not limited to, the USA, United Kingdom,
                    EU, Australia and New Zealand. Our Privacy Policy, which forms part of these Terms, describes what Personal Data
                    and information we collect from you and what we do with that data. Our Privacy Policy also sets out our obligations
                    under the General Data Protection Regulation. Where we transfer the Personal Data of individuals, we will do so in
                    accordance with the Data Processing Schedule on the Site, which is incorporated by reference to these Terms.
                  </li>
                  <li>
                    You acknowledge and agree that you are responsible for the collection, use, storage and otherwise dealing with
                    Personal Data related to your business and all matters relating to the Data.
                  </li>
                  <li>
                    You will comply and ensure that all of your Personnel and Authorised Users comply with the requirements of the
                    Privacy Laws applicable to you in respect of all Personal Data collected, used, stored or otherwise dealt with
                    under or in connection with these Terms.
                  </li>
                  <li>
                    Without limiting clause 9(b), you must:
                    <ol className="numbers">
                      <li>
                        notify your Authorised Users, Personnel, or other natural persons from whom Personal Data is collected about
                        any matter prescribed by the applicable Privacy Laws in relation to the collection, use and storage of their
                        Personal Data;
                      </li>
                      <li>ensure that any Personal Data transferred to us is complete, accurate and up to date; and</li>
                      <li>
                        notify us immediately upon becoming aware of any breach of the applicable Privacy Laws that may be related to
                        the use of the Personal Data under these Terms.
                      </li>
                    </ol>
                  </li>
                </ol>
              </section>

              <section>
                <h2>10. Warranties</h2>
                <ol className="letters">
                  <li>
                    We warrant and agree that:
                    <ol className="numbers">
                      <li>we are properly constituted and have the right and authority to enter into these Terms;</li>
                      <li>we will provide the Services in accordance with all applicable Laws;</li>
                      <li>the Services will be supplied with all reasonable skill and care;</li>
                      <li>the Services will not infringe the Intellectual Property Rights of any third party.</li>
                    </ol>
                  </li>
                  <li>
                    You warrant and agree that:
                    <ol className="numbers">
                      <li>there are no legal restrictions preventing you from entering into these Terms;</li>
                      <li>you are not subject to an Insolvency Event;</li>
                      <li>
                        you will cooperate with us and provide us with all assistance, resources, data, access and documentation that
                        is reasonably necessary to enable us to perform the Services, and as otherwise requested by us, from time to
                        time, and in a timely manner;
                      </li>
                      <li>
                        all information and documentation that you provide to us in connection with these Terms is true, correct and
                        complete and you acknowledge and agree that we will rely on such information and documentation in order to
                        provide the Services;
                      </li>
                      <li>
                        you will inform us if you have reasonable concerns relating to our provision of Services under these Terms,
                        with the aim that the Parties will use all reasonable efforts to resolve your concerns;
                      </li>
                      <li>
                        you are responsible for obtaining any consents, licences, authorities and permissions from other parties
                        necessary for the Services to be provided in accordance with these Terms, at your cost, and for providing us
                        with the necessary consents, licences, authorities and permissions;
                      </li>
                      <li>you hold a valid company tax identification number which has been advised to us; and</li>
                      <li>if applicable, you are registered for GST purposes.</li>
                    </ol>
                  </li>
                </ol>
              </section>

              <section>
                <h2>11. Payment</h2>
                <ol className="letters">
                  <li>
                    You must pay us, or our authorised reseller from whom you purchased the Services, the Fee for your Plan and any
                    other amount payable to us under these Terms, without set off or delay, via credit card or any other payment method
                    set out on the Site.
                  </li>
                  <li>
                    The Fee for your Plan is payable in advance of the next billing cycle for your Plan and any additional charges will
                    be billed in arrears on a monthly basis (unless otherwise agreed).
                  </li>
                  <li>
                    You are responsible for reviewing the pricing schedule, features and limits associated with your Plan, which are
                    available on the Site. The Fee for your Plan may also include a separate volume usage charge.
                  </li>
                  <li>
                    Your Plan will automatically renew at the end of the Plan period (and for the same Plan cycle) unless you terminate
                    your Account in accordance with these Terms.
                  </li>
                  <li>
                    If there is a variation to your Plan during the Term, we will include the fees due and payable for the variation
                    performed in invoice(s) subsequent to the performance of the variation.
                  </li>
                  <li>
                    Resellers: If you are entitled to a refund under these Terms and you have purchased the Services via our authorised
                    reseller, we will refund the payment to the reseller and the reseller will be solely responsible for refunding the
                    appropriate amounts to you in accordance with the agreement between you and the reseller.
                  </li>
                  <li>
                    We agree to refund your payment should you choose to terminate your initial subscription for any reason no later
                    than thirty (30) days after the invoice date of the subscription. Refunds do not apply to renewals, additional
                    Plans or Addons purchased after the initial subscription.
                  </li>
                  <li>
                    Subject to clause 11(g), the Fee is non-refundable. To the maximum extent permitted by law, there will be no
                    refunds or credits for any unused Plan (or part thereof), Plan downgrade or unused Accounts. Downgrading your Plan
                    may result in the loss of content, features or capacity of your Account. We do not accept any liability for any
                    losses or damages that may arise in such cases.
                  </li>
                  <li>
                    We may offer you a free or trial Account with limited features designed to allow you to evaluate the Services and
                    make sure it is right for you before signing up for a paid Plan. Any trial period can change at any time without
                    notice. We have the right to terminate any trial Account if you are found to be misusing the Services.
                  </li>
                  <li>
                    You agree that we may bill your credit card or other payment method for renewals, upgrades, overages to set limits
                    or scopes of use, expenses, and unpaid fees, as applicable. You will remain responsible for paying any outstanding
                    amounts, which will accrue to your Account until we receive a cancellation notice, and any outstanding Fees remains
                    payable to us beyond termination of this agreement.
                  </li>
                  <li>
                    If a purchase order number is required, you may provide one during the order process or by emailing it to:
                    accounts@shorthand.com. We cannot and do not accept any terms contained in a purchase order form.
                  </li>
                  <li>
                    All Fees exclude taxes. You are responsible for all taxes, levies or duties imposed by taxing authorities in your
                    own country, and you shall be responsible for payment of them. We have no responsibility to them on your behalf,
                    however to the extent that we are responsible for collecting and paying taxes in the jurisdiction where payment is
                    made or received, you must pay to us the amount of taxes owed in addition to your fees for the Services. If you
                    have an exemption from such taxes, you may provide us with appropriate evidence of your exemption such that we can
                    record your exemption on the invoices we issue to you.
                  </li>
                  <li>
                    The Fee is subject to change and will apply to the next Plan billing cycle. Notice of changes to the Fees will be
                    provided in accordance with clause 16(g). If you do not agree to the Fee change, you may cancel your Account in
                    accordance with clause 15(a).
                  </li>
                  <li>
                    If any payment for Services payable in arrears has not been made in accordance with our payment terms, we may (in
                    our absolute discretion):
                    <ol className="numbers">
                      <li>
                        immediately cease providing the Services to you or suspend the provision of the Services, and recover as a debt
                        due and immediately payable from you any additional costs of doing so;
                      </li>
                      <li>
                        charge interest at a rate equal to the Reserve Bank of Australia’s cash rate from time to time plus 5% per
                        month, calculated daily and compounding monthly, on any such amounts unpaid after the due date; and
                      </li>
                      <li>engage debt collection services and/or commence legal proceedings in relation to any such amounts.</li>
                    </ol>
                  </li>
                  <li>
                    If you rectify such non-payment after the Services have been suspended, then we will recommence the provision of
                    the Services as soon as reasonably practicable.
                  </li>
                </ol>
              </section>

              <section>
                <h2>12. Intellectual Property Rights</h2>
                <h4>12.1. Our Intellectual Property</h4>
                <ol className="letters">
                  <li>
                    All Intellectual Property in the Services and that Intellectual Property developed, adapted, modified or created by
                    us or our Personnel (including in connection with these Terms and the Services and any machine learning algorithms
                    output from the Services) is and will remain owned exclusively by us or our third-party service providers.
                  </li>
                  <li>
                    You must not, without our prior written consent:
                    <ol className="numbers">
                      <li>copy or use, in whole or in part, any of our Intellectual Property;</li>
                      <li>
                        reproduce, retransmit, distribute, disseminate, sell, publish, broadcast or circulate any of our Intellectual
                        Property to any third party;
                      </li>
                      <li>reverse assemble, reverse engineer, reverse compile or enhance the Services;</li>
                      <li>
                        breach any Intellectual Property Rights connected with the Services, including altering or modifying any of our
                        Intellectual Property;
                      </li>
                      <li>
                        cause any of our Intellectual Property to be framed or embedded in another website; or creating derivative
                        works from any of our Intellectual Property;
                      </li>
                      <li>resell, assign, transfer, distribute or make available the Services to third parties;</li>
                      <li>
                        “frame”, “mirror” or serve any of the Services on any web server or other computer server over the Internet or
                        any other network;
                      </li>
                      <li>
                        alter, remove or tamper with any trademarks, any patent or copyright notices, any confidentiality legend or
                        notice, any numbers or any other means of identification used on or in relation to the Services;
                      </li>
                    </ol>
                  </li>
                  <li>
                    Notwithstanding anything to the contrary in these Terms or elsewhere, we may monitor, analyse and compile
                    statistical and performance information based on and/or related to your use of the Services, including through the
                    use of your personal data, in an aggregated and anonymized format (Analytics). You agree that we may make such
                    Analytics publicly available, provided that it:
                    <ol className="numbers">
                      <li>does not contain identifying information;</li>
                      <li>is not compiled using a sample size small enough to make the underlying data identifiable.</li>
                    </ol>
                  </li>
                  <li>
                    We and/or our licensors own all right, title and interest in and to the Analytics and all related software,
                    technology, documentation and content provided in connection with the Analytics, including all Intellectual
                    Property rights in the foregoing.
                  </li>
                </ol>
                <h4>12.2. Your Intellectual Property</h4>
                <ol className="letters">
                  <li>
                    As between you and us, (i) all Data is and remains your property, and (ii) you retain any and all rights, title and
                    interest in and to the Data, including all copies, modifications, extensions and derivative works thereof.
                  </li>
                  <li>
                    Licence: You grant us a limited licence to copy, transmit, store and back-up or otherwise access the Data during
                    the Term solely to:
                    <ol className="numbers">
                      <li>supply the Services to you (including to enable you and your Personnel to access and use the Services);</li>
                      <li>diagnose problems with the Services;</li>
                      <li>enhance and otherwise modify the Services;</li>
                      <li>develop other services, provided we de-identify the Data; and</li>
                      <li>as reasonably required to perform our obligations under these Terms.</li>
                    </ol>
                  </li>
                  <li>
                    You agree to indemnify and hold harmless us and our affiliates, and our respective owners, directors, officers,
                    employees, contractors, advisors, agents and representatives (“Related Persons”) against all actions, claims,
                    charges, costs (including reasonable legal costs), expenses, losses, damages and other liability that it may
                    sustain or incur, directly or indirectly, as a result or as a consequence of any third party claims alleging that:
                    <ol className="numbers">
                      <li>
                        the content or material created by you using the Services, or inputted to the Services, in accordance with
                        these Terms infringes such third party’s Intellectual Property rights; and
                      </li>
                      <li>
                        the content or material created by you using the Platform, or inputted to the Platform, is defamatory,
                        scandalous or otherwise unlawful as a result or as a consequence of any act or omission by you or on your
                        behalf in connection with the Services or as a result or as a consequence of your breach of these Terms or any
                        other terms or policies referenced herein.
                      </li>
                    </ol>
                  </li>
                  <li>
                    General: You must, at all times, ensure the integrity of the Data and that your use of the Data is compliant with
                    all Laws. You represent and warrant that: (i) you have obtained all necessary rights, releases and permissions to
                    provide all your Data to us and to grant the rights granted to us in these Terms; and (ii) the Data and its
                    transfer to and use by us, as authorised by you under these Terms do not violate any Laws (including those relating
                    to export control and electronic communications) or rights of any third party, including any Intellectual Property
                    rights, rights of privacy, or rights of publicity, and any use, collection and disclosure authorised in these Terms
                    is not inconsistent with the terms of any applicable privacy policies.
                  </li>
                  <li>
                    We assume no responsibility or Liability for the Data. You are solely responsible for the Data and the consequences
                    of using, disclosing, storing or transmitting it.
                  </li>
                  <li>
                    To the extent permitted by law, you agree to indemnify, defend and hold us, our affiliates, officers, directors,
                    employees and agents, harmless from all claims, damages, liabilities, losses, costs and expenses (including legal
                    fees) arising out of any use or disclosure of Your Data in connection with the provision of the Services or to
                    comply with any legal, regulatory or similar requirement or investigation.
                  </li>
                  <li>This clause will survive termination or expiry of these Terms.</li>
                </ol>
              </section>

              <section>
                <h2>13. Confidential Information</h2>
                <ol className="letters">
                  <li>
                    Each Party (Recipient) must keep confidential, and not disclose, any Confidential Information of the other party
                    (Discloser) except:
                    <ol className="numbers">
                      <li>where permitted by these Terms;</li>
                      <li>with the prior written consent of the Discloser;</li>
                      <li>
                        where the Confidential Information is received from a third party, except where there has been a breach of
                        confidence;
                      </li>
                      <li>
                        on a confidential, “needs to know” basis to the Recipient’s Personnel, auditors, insurers, agents and
                        professional advisors; or
                      </li>
                      <li>
                        where the Recipient is compelled to do so by Law, provided that it gives the other party written notice prior
                        to disclosure.
                      </li>
                    </ol>
                  </li>
                  <li>
                    The Recipient must only use the Confidential Information of the Discloser for the purpose for which it was
                    disclosed and in connection with these Terms.
                  </li>
                  <li>This clause will survive termination or expiry of these Terms.</li>
                </ol>
              </section>

              <section>
                <h2>14. Liability</h2>
                <ol className="letters">
                  <li>
                    Despite anything to the contrary, to the maximum extent permitted by law:
                    <ol className="numbers">
                      <li>
                        our maximum aggregate Liability arising from or in connection with these Terms (including the Services or the
                        subject matter of these Terms) will be limited to, and must not exceed in the aggregate for all claims the
                        portion of the Fees paid by you to us for the Services the subject of the relevant claim; and
                      </li>
                      <li>we will not be liable to you for any Consequential Loss,</li>
                    </ol>
                    whether under statute, contract, equity, tort (including negligence), indemnity or otherwise.
                  </li>
                  <li>
                    Despite anything to the contrary, to the maximum extent permitted by law, we will have no Liability, and you waive
                    and release us from and against, all Liability (whether under statute, contract, negligence or other tort,
                    indemnity, or otherwise) arising from or in connection with any:
                    <ol className="numbers">
                      <li>loss of, or damage to, any property or any injury to or loss to any person;</li>
                      <li>failure or delay in providing the Services;</li>
                      <li>breach of these Terms or any Laws; or</li>
                      <li>unavailability, outage or interruption of the Computing Environment,</li>
                    </ol>
                    where caused or contributed to by any:
                    <ol className="roman">
                      <li>Force Majeure Event;</li>
                      <li>fault, defect, error or omission in your Computing Environment or Data; or</li>
                      <li>
                        act or omission of you, your related parties, Authorised Users, Personnel or any third party (including
                        customers, end users, suppliers, providers or subcontractors),
                      </li>
                    </ol>
                    and, in any event, any error, omission or lack of suitability (or the absence of, or reduction in, any anticipated
                    result, outcome or benefit) with respect to the Services.
                  </li>
                  <li>
                    To the maximum extent permitted by law, you indemnify and continue to indemnify us against all Liability we suffer
                    or incur arising from or as a consequence of a breach of clause 9 (Privacy), clause 11 (Intellectual Property) ,
                    clause 13 (Confidentiality) and your Authorised Users’ use of the Services contrary to these Terms, including from
                    any claim relating to the Data.
                  </li>
                  <li>
                    Subject to clause 14(e), we will use commercially reasonable efforts, at no charge to you, to correct reported
                    non-conformities with the Services (Service Warranty). If we determine corrections to be impracticable, either
                    party may terminate the applicable subscription term. In this case, you will receive a refund of any fees you have
                    pre-paid for use of the Services for the terminated portion of the applicable subscription term. The Service
                    Warranty will not apply: (i) unless you make a claim within thirty (30) days of the date on which you first noticed
                    the non-conformity, (ii) if the non-conformity was caused by misuse, unauthorized modifications or third-party
                    products, software, services or equipment or (iii) to free-to-the-user Apps. Our sole liability, and your sole and
                    exclusive remedy, for any breach of the Service Warranty are set forth in this clause 14.
                  </li>
                  <li>
                    Nothing in these Terms attempts to exclude, restrict or modify any statutory rights you may have which cannot be
                    excluded or modified by law. Any and all other warranties or conditions which are not guaranteed by statute are
                    expressly excluded where permitted, except to the extent such warranties and conditions are fully expressed in
                    these Terms.
                  </li>
                  <li>
                    You acknowledge and agree that:
                    <ol className="numbers">
                      <li>you are responsible for all users using the Services, including your Personnel and any Authorised Users;</li>
                      <li>you use the Services and any associated programs and files at your own risk;</li>
                      <li>we do not warrant that the Services are error-free or will be uninterrupted;</li>
                      <li>
                        any information, recommendations, guidance or reports published by us ("<strong>Content</strong>") is general
                        in nature and that the Content does not take into account your specific needs or circumstances. It is not
                        advice, it does not constitute an express or implied warranty and you must not rely on it. We do not assume any
                        liability for the accuracy, completeness, usefulness of any Content, or your reliance on any Content.
                      </li>
                      <li>
                        the technical processing and transmission of the Services, including your Data, may be transferred unencrypted
                        and involve (i) transmissions over various networks; and (ii) changes to conform and adapt to technical
                        requirements of connecting networks or devices;
                      </li>
                      <li>
                        we may use third party service providers to host the Services. If the providers of third party applications or
                        services cease to make their services or programs available on reasonable terms, we may cease providing any
                        affected features;
                      </li>
                      <li>
                        the Services may use third party products, facilities or services. We do not make any warranty or
                        representation in respect of the third-party products, facilities or services;
                      </li>
                      <li>
                        we do not guarantee that any file or program available for download and/or execution from or via the Services
                        is free from viruses or other conditions which could damage or interfere with Data, hardware or software with
                        which it might be used;
                      </li>
                      <li>
                        we are not responsible for the integrity or existence of any Data on the Computing Environment, network or any
                        device controlled by you or your Authorised Users; and
                      </li>
                      <li>
                        we may pursue any available equitable or other remedy against you if you breach any provision of these Terms.
                      </li>
                    </ol>
                  </li>
                  <li>This clause will survive termination or expiry of these Terms.</li>
                </ol>
              </section>

              <section>
                <h2>15. Termination</h2>
                <ol className="letters">
                  <li>
                    You may terminate these Terms via written notice to us. No refunds will be given upon termination in accordance
                    with this clause.
                  </li>
                  <li>
                    To the extent permitted by law, either Party may terminate these Terms, if the other Party:
                    <ol className="numbers">
                      <li>
                        has breached a material term of these Terms and has failed to remedy such breach within 20 Business Days of
                        receiving notice to do so, subject to any other express right of termination;
                      </li>
                      <li>is unable to pay its debts as they fall due.</li>
                    </ol>
                  </li>
                  <li>
                    Upon termination of these Terms, you must cease using the Services and delete or return any Confidential
                    Information or other materials in your possession. You will no longer have access to your Data, and we may delete
                    your Data unless we are prohibited to do so by law, when these Terms expire or terminate. If you terminate these
                    Terms in accordance with clause 15(b) for our breach, then we will refund you any prepaid fees covering the
                    remainder of the terminated subscription term. If we terminate these Terms in accordance with clause 15(b) for your
                    breach, then you will pay any unpaid fees covering the remainder of the terminated subscription term. For the
                    avoidance of doubt, in no event will termination relieve you of your obligation to pay any fees payable to us prior
                    to the date of termination.
                  </li>
                </ol>
              </section>

              <section>
                <h2>16. General</h2>
                <ol className="letters">
                  <li>Our Services will be provided to you on a non-exclusive basis.</li>
                  <li>
                    We reserve the right at any time and from time to time to change or remove features of the Services provided that,
                    where there is any material alteration to the functionality of the Services in accordance with this clause, we will
                    notify you in accordance with clause 16(g).
                  </li>
                  <li>Subcontracting: We may engage subcontractors to perform the Services on our behalf.</li>
                  <li>
                    Publicity: We may identify you as a Shorthand customer in our promotional materials. We will stop doing so upon
                    your emailed request to help@shorthand.com.
                  </li>
                  <li>
                    Force Majeure: If we are delayed from performing our obligations due to such a circumstance for a period of at
                    least two months, we may terminate our agreement with you by giving you five Business Days' notice in writing.
                  </li>
                  <li>
                    Disputes: Neither Party may commence court proceedings relating to any dispute arising from, or in connection with,
                    these Terms without first meeting with a senior representative of the other Party to seek (in good faith) to
                    resolve that dispute (unless that Party is seeking urgent interlocutory relief or the dispute relates to compliance
                    with this provision). If the parties do not reach settlement within a period of 60 days, any unresolved dispute
                    arising out of or in connection with the present contract shall be finally settled under the Rules of Arbitration
                    of the International Chamber of Commerce by one or more arbitrators appointed in accordance with the said Rules.
                    Arbitration will take place in Brisbane (Australia).
                  </li>
                  <li>
                    Notices: Any notice required or permitted to be given by either Party to the other under these conditions will be
                    in writing addressed the Party, to you, at the address in your Account, and, for us, to the address on our Site. A
                    Party may change its notice details by written notice to the other Parties. Any notice may be sent by standard post
                    or email, and notice will be deemed to have been served on the expiry of 48 hours in the case of post within
                    Australia, or 7 Business Days for international post, or at the time of transmission in the case of transmission.
                  </li>
                  <li>
                    Delay: If the provision of the Services depends upon or includes the provision of information, materials, consents
                    or approvals by you or your Personnel, or requires your cooperation, action or response (or that of your
                    Personnel), you must provide that information, cooperation and those materials, consents or approvals in a timely
                    manner. If you breach this obligation, we will be entitled to an extension of time in respect any deadline or
                    milestone to the extent of the delay caused by you and we will have no Liability for a failure to perform the
                    Services caused by you.
                  </li>
                  <li>
                    Waiver: Any failure or delay by a Party in exercising a power or right (either wholly or partly) in relation to
                    these Terms does not operate as a waiver or prevent a Party from exercising that power or right or any other power
                    or right. A waiver must be in writing.
                  </li>
                  <li>
                    Relationship of Parties: These Terms are not intended to create a partnership, joint venture or agency relationship
                    between the Parties. Nothing in these Terms gives a Party authority to bind the other Party in any way.
                  </li>
                  <li>
                    Severance: If a provision of these Terms is held to be void, invalid, illegal or unenforceable, that provision is
                    to be read down as narrowly as necessary to allow it to be valid or enforceable, failing which, that provision (or
                    that part of that provision) will be severed from these Terms without affecting the validity or enforceability of
                    the remainder of that provision or the other provisions.
                  </li>
                  <li>
                    Assignment: We may assign, transfer or otherwise deal with all or any of our rights or obligations under these
                    Terms without your prior written consent. You may not assign, transfer or otherwise deal with all or any of your
                    rights or obligations under these Terms without our prior written consent. Any purported dealing in breach of this
                    clause by you is of no force or effect.
                  </li>
                  <li>
                    Entire agreement: These Terms contain the entire understanding between the Parties, and supersede all previous
                    discussions, communications, negotiations, understandings, representations, warranties, commitments and agreements,
                    in respect of its subject matter.
                  </li>
                  <li>
                    Amendment: We may modify these Terms at any time. We will make every effort to communicate any changes to these
                    Terms through the Site. Your continued use of these Services after these modifications constitutes your acceptance
                    and agreement to the modified Agreement.
                  </li>
                  <li>
                    Counterparts: These Terms may be executed in any number of counterparts that together will form one instrument.
                  </li>
                  <li>
                    Governing law: The Agreement is governed by the laws of England and Wales. Subject to clause 16(e) (Disputes) you
                    irrevocably and unconditionally submit to the exclusive jurisdiction of the courts operating in England and Wales
                    and any courts entitled to hear appeals from those courts and waive any rights to object to proceedings being
                    brought in those courts. If you access the Services from outside Australia, you do so at your own risk and are
                    responsible for complying with the laws in the place you access the Services.
                  </li>
                  <li>
                    Rights of Third Parties: The Agreement is not intended to, and does not, give to any person who is not a party to
                    it any rights to enforce any provisions contained within it.
                  </li>
                  <li>This clause will survive termination or expiry of these Terms.</li>
                </ol>
              </section>

              <section>
                <h2>17. Definitions and interpretation</h2>
                <ol className="letters">
                  <li>
                    Definitions
                    <p>Unless defined in an Order Form or a Schedule, the following words will mean:</p>
                    <dl>
                      <dt>Account</dt>
                      <dd>has the meaning given in clause 3(a);</dd>
                      <dt>Authorised User</dt>
                      <dd>means a user permitted to use the Services;</dd>
                      <dt>Business Day</dt>
                      <dd>means a day which is not a Saturday, Sunday or bank or public holiday in Brisbane, Australia.</dd>
                      <dt>Business Hours</dt>
                      <dd>means 9am to 5pm on a Business Day;</dd>
                      <dt>Computing Environment</dt>
                      <dd>
                        means your computing environment including all hardware, software, information technology and
                        telecommunications services and Systems;
                      </dd>
                      <dt>Confidential Information</dt>
                      <dd>
                        includes confidential information about a Party’s business, structure, programs, processes, methods, operating
                        procedures, activities, products and services, trade secrets, financial, accounting, marketing and technical
                        information, customer and supplier lists (including prospective customer and supplier information), ideas,
                        concepts, know-how, Intellectual Property, technology, and other information whether or not such information is
                        reduced to a tangible form or marked in writing as "confidential" but does not include any information which is
                        in the public domain other than through a breach of confidence. Our Confidential Information includes our
                        Intellectual Property including in the Services. Your Confidential Information includes the Data; Consequential
                        Loss includes any indirect, incidental or consequential loss, loss of profits, revenue, production,
                        opportunity, access to markets, goodwill, reputation, use or any remote, abnormal or unforeseeable loss, loss
                        of use and/or loss or corruption of data or any loss or damage relating to business interruption, or otherwise,
                        suffered or incurred by a person, arising out of or in connection with these Terms (whether involving a third
                        party or a Party to these Terms or otherwise);
                      </dd>
                      <dt>Data</dt>
                      <dd>
                        means the information, documents and other data inputted by you, your Personnel or Authorised Users into the
                        Service or stored by the Services or, subject to clause 12(a), generated by the Services, including story
                        content, as a result of your use of the Services;
                      </dd>
                      <dt>Development Fee</dt>
                      <dd>has the meaning given in clause 7(a);</dd>
                      <dt>Development Services</dt>
                      <dd>has the meaning given in clause 7(a);</dd>
                      <dt>Fees</dt>
                      <dd>means the fees set out on the Site, and any Development Fee;</dd>
                      <dt>Force Majeure Event</dt>
                      <dd>
                        means an event which is beyond a Party's reasonable control including a fire, storm, flood, earthquake,
                        explosion, accident, act of the public enemy, terrorist act, war, rebellion, insurrection, sabotage, epidemic,
                        quarantine restriction, transportation embargo, and strike by employees of a third person;
                      </dd>
                      <dt>Insolvency Event</dt>
                      <dd>
                        means the occurrence of any one or more of the following events in relation to either Party:
                        <ol className="numbers">
                          <li>
                            it is or states that it is insolvent or is deemed or presumed to be insolvent under any applicable laws;
                          </li>
                          <li>
                            an application or order is made for its winding up, bankruptcy or dissolution or a resolution is passed or
                            any steps are taken to pass a resolution for its winding up or dissolution;
                          </li>
                          <li>
                            an administrator, provisional liquidator, liquidator or person having a similar or analogous function under
                            the laws of any relevant jurisdiction is appointed in respect of it or any action is taken to appoint any
                            such person and the action is not stayed, withdrawn or dismissed within 10 Business Days;
                          </li>
                          <li>a controller is appointed in respect of any of its property;</li>
                          <li>
                            it is deregistered under the Corporations Act or other legislation or notice of its proposed deregistration
                            is given to it;
                          </li>
                          <li>
                            a distress, attachment or execution is levied or becomes enforceable against it or any of its property;
                          </li>
                          <li>
                            it enters into or takes action to enter into an arrangement, composition or compromise with, or assignment
                            for the benefit of, all or any class of its creditors or members or a moratorium involving any of them;
                          </li>
                          <li>
                            a receiver or manager (or both) or trustee in bankruptcy is appointed in respect of it or its property;
                          </li>
                          <li>
                            a petition for the making of a sequestration order against its estate is presented and the petition is not
                            stayed, withdrawn or dismissed within 10 Business Days or it presents a petition against itself; or
                          </li>
                          <li>
                            anything analogous to or of a similar effect to anything described above under the law of any relevant
                            jurisdiction occurs in respect of the relevant Party.
                          </li>
                        </ol>
                      </dd>
                      <dt>Intellectual Property</dt>
                      <dd>
                        includes any and all intellectual and industrial property rights throughout the world, whether subsisting now
                        or in the future and includes all copyright and analogous rights, all rights in relation to inventions
                        (including patent rights), registered and unregistered trademarks, designs (whether or not registered or
                        registrable), circuit layouts, trade names, trade secrets, business names, customer names or internet domain
                        names. Our Intellectual Property includes the Service;
                      </dd>
                      <dt>Laws</dt>
                      <dd>
                        means all applicable local, state, federal and international laws, regulations and conventions, including those
                        related to data privacy and data transfer, international communications and the exportation of technical or
                        personal data;
                      </dd>
                      <dt>Liability</dt>
                      <dd>means any loss, liability, cost, payment, damages, debt or expense (including reasonable legal fees);</dd>
                      <dt>Party</dt>
                      <dd>means either party to these Terms;</dd>
                      <dt>Personal Data</dt>
                      <dd>has the meaning given to it under the EU General Data protective Regulation (Regulation (EU) 2016/679).</dd>
                      <dt>Personnel</dt>
                      <dd>
                        means, in relation to a Party, the officers, employees, contractors, sub-contractors and agents of that Party;
                      </dd>
                      <dt>Plan</dt>
                      <dd>
                        means the applicable monthly, quarterly or annual plan which provides for certain Service features tiers set
                        out on the Site.
                      </dd>
                      <dt>Privacy Laws</dt>
                      <dd>means any laws applicable to the protection of Personal Data;</dd>
                      <dt>Services</dt>
                      <dd>mean the Services and any other services we provide to you under these Terms;</dd>
                      <dt>Statutory Rights</dt>
                      <dd>
                        means any statutory rights you may have in the jurisdiction in which the Services are provided to you; and
                      </dd>
                      <dt>System</dt>
                      <dd>
                        means all hardware, software, networks and other IT systems used by a Party from time to time, including a
                        network.
                      </dd>
                    </dl>
                  </li>
                  <li>
                    Interpretation
                    <p>In these Terms, unless the context otherwise requires:</p>
                    <ol className="numbers">
                      <li>the singular includes the plural and vice versa;</li>
                      <li>headings are for convenience only and do not affect interpretation;</li>
                      <li>
                        a reference to these Terms or any other document includes the document, all schedules and all annexures as
                        novated, amended, supplemented, varied or replaced from time to time;
                      </li>
                      <li>
                        if any act which must be done under these Terms is to be done on a day that is not a Business Day then the act
                        must be done on or by the next Business Day;
                      </li>
                      <li>the word "month" means calendar month and the word "year" means 12 months;</li>
                      <li>
                        the words "in writing" include any communication sent by letter or email or any other form of communication
                        capable of being read by the recipient;
                      </li>
                      <li>
                        a reference to any legislation or law includes subordinate legislation or law and all amendments,
                        consolidations, replacements or re-enactments from time to time;
                      </li>
                      <li>includes and similar words mean includes without limitation;</li>
                      <li>
                        a reference to any agency or body, if that agency or body ceases to exist or is reconstituted, renamed or
                        replaced or has its powers or functions removed (defunct body), means the agency or body that performs most
                        closely the functions of the defunct body; and
                      </li>
                      <li>
                        no clause will be interpreted to the disadvantage of a Party merely because that Party drafted the clause or
                        would otherwise benefit from it.
                      </li>
                    </ol>
                  </li>
                </ol>
              </section>
            </div>
          </div>
          <Footer />
        </main>
      </Layout>
    );
  }
}
