import * as React from "react";

interface PillProps {
  label: string;
}

export const Pill = ({ label }: PillProps): JSX.Element => {
  return (
    <div className="tw-rounded-2xl tw-border tw-border-grey_200 tw-bg-grey_50 tw-p-4 tw-pb-[4px] tw-pt-[4px] !tw-text-sm !tw-text-grey_700">
      {label}
    </div>
  );
};
