import { filterListByCurrentIndustryCode } from "../../Utils";
import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { RelatedCaseStudies } from "../../components/RelatedCaseStudies";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default function CaseStudy({ data, location }) {
  return (
    <Layout location={location}>
      <main id="main" className="marketing-content container-fluid">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-secondary body-marketing-case-studies hide-elevio",
          }}
        >
          <title>Honda | Visual storytelling</title>
          <meta
            name="description"
            content="The UK's iconic university has been using Shorthand since 2017 to create impactful and visually compelling stories about its world-changing research and 800-year history"
          />
        </Helmet>
        <div className="row section-title">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto  section-inner">
            <h1>
              <span className="shorthand-logo">Shorthand</span>
              <span className="subsection-label">Case Study:</span>
              Honda
            </h1>
            <h2>Honda uses Shorthand to publish engaging, interactive brand stories on its content hub, Engine Room.</h2>
          </div>
          <div aria-hidden="true" className="background-media">
            <img
              src="/media/case-studies/case-studies-honda-title.jpg"
              data-object-fit="cover"
              data-autoscale-content-ratio="1.77777777778"
              alt=""
            />
          </div>
        </div>
        <div className="row section">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto marketing-main-column">
            <h3 className="intro">
              One of the largest automotive companies in the world has seen the average site dwell time increase by 85% since
              transitioning Engine Room to Shorthand.
            </h3>
            <div className="inline-image featured">
              <img
                src="/media/case-studies/case-studies-honda.jpg"
                alt="HONDA LEGENDS:
              AYRTON SENNA, by Honda UK, renders responsively across all devices"
              />
            </div>
            <p>
              The Honda marketing team first launched Engine Room in July 2016. Intended as a way to generate leads and bring new
              products to market, the project was initially stymied by its archaic content management system (CMS).
            </p>
            <p>
              While the team was eager to rapidly implement new content ideas, the limitations of their CMS made it difficult to
              innovate. Often, it took the team weeks to implement a seemingly small change.
            </p>
            <p>
              Nick Bennett is the Digital Content and Social Media Section Manager at Honda UK, and is responsible for managing the
              Engine Room project. As Nick explains, the implementation of GDPR, the CMS issues, and a looming event all catalysed a
              change of approach.
            </p>
            <figure className="block quote-block offset left">
              <blockquote>
                <p>
                  "It’s very easy to just do what others are doing… However, doing something different allows you to stand out in a
                  very crowded marketplace."
                </p>
              </blockquote>
              <figcaption className="quote-cite">Nick Bennett</figcaption>
            </figure>
            <p>
              “We had two months to move Engine Room from its online magazine format to an online content platform and deliver
              compelling content, keep the brand awareness, plus attract new subscribers to our database after GDPR reduced our
              numbers,” he says.
            </p>
            <p>
              Faced with this challenge, Nick and the team decided not to simply replicate the legacy marketing tactics and
              technologies used by competitors. Where others were zigging, Nick and the team at Honda UK decided to zag.
            </p>
            <p>
              “It’s very easy to just do what others are doing,” Nick says. “However, doing something different allows you to stand out
              in a very crowded marketplace.”
            </p>
            <p>
              In May 2018, Nick put the wheels in motion for a bold relaunch with the Shorthand platform. His content team was tasked
              with repurposing some existing content in the new Shorthand format, plus building brand new articles from scratch.
            </p>
            <p>
              Shorthand’s ease of use was a driving factor for Nick and his team from day one. “What attracted us to the tool was how
              easy it is to use. We wanted to be able to take locally produced content lying in front of us and have it on the web
              within 15 minutes,” says Nick. “We worked very closely with Shorthand to better understand how the tool could work, and
              the team received comprehensive training.”
            </p>
            <p>
              Just two months after signing up for a Shorthand subscription, the old site was switched off and the new Honda Engine
              Room site went live. Engine Room was relaunched without a speed bump — but as Nick explains, the real test of the new
              content hub was its scrutiny at Honda’s upcoming live event.
            </p>

            <div className="case-study-video-container">
              <video className="case-study-video-inline" muted loop playsInline autoPlay>
                <source src="/media/case-studies/case-studies-honda.mp4" type="video/mp4" />
              </video>
            </div>

            <h3 className="subheading">From nought to sixty</h3>

            <p>
              The Goodwood Festival of Speed is an annual event that takes place in West Sussex, about an hour outside of London. For
              Honda, it represented a huge opportunity to address over 200,000 visitors interested in motorsport and car culture.
            </p>
            <p>
              With the relaunch and Shorthand training complete, the content team recorded key Honda highlights throughout the four-day
              event. They then showcased those poignant moments in Shorthand stories that they were able to publish immediately — all
              from the field, using 4G.
            </p>
            <p>
              “We wanted to make sure the event reached people not only in the UK, but globally,” Nick says. “Those stories from the
              Goodwood Festival of Speed delivered an immersive and interactive experience that makes the content relevant to a wide
              audience for years to come.”
            </p>

            <div className="photo-grid flex featured">
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-honda-sml-01.jpg"
                alt="Background Scrollmation section from HONDA LEGENDS:
              AYRTON SENNA, by Honda UK"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-honda-sml-02.jpg"
                alt="Text Over Media section from HONDA LEGENDS:
              AYRTON SENNA, by Honda UK"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-honda-sml-04.jpg"
                alt="Text Over Media section from THE CIVIC
              Our Honda hero through the years, by Honda UK"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-honda-sml-03.jpg"
                alt="Background Scrollmation section from BACK TO SCHOOL
              WITH THE CR-V, by Honda UK"
              />
            </div>
            <div className="caption">A selection of sections from Honda's stories</div>

            <h3 className="subheading">A clear winner</h3>

            <p>
              The transition to Shorthand delivered some big benefits to the Honda team in a matter of months. The first annual data
              review was also a cause for collective celebration, with return on investment — measured in terms of traffic and value to
              the business — up by 32%.
            </p>

            <figure className="block quote-block offset right">
              <blockquote>
                <p>
                  "We know that most people look at two or more articles. They may be coming through via a story that interests them
                  but the quality means they are more likely to stay longer and look at others."
                </p>
              </blockquote>
              <figcaption className="quote-cite">Nick Bennett</figcaption>
            </figure>

            <p>
              “ROI is everything,” Nick says. “You want to make sure you are delivering commercially to support the needs and
              requirements of the business.”
            </p>
            <p>
              The average site dwell time had also increased by 85%. “We know that most people look at two or more articles. They may
              be coming through via a story that interests them, but the quality means they are more likely to stay longer and look at
              others,” says Nick.
            </p>
            <p>
              The new Honda Engine Room became an inspiring space for social media audiences to visit, to learn about the brand and its
              product before visiting the main website. The click-through rate to honda.co.uk increased by 47%.
            </p>
            <p>Finally, Honda also saw a 600% rise in subscriptions, having reset all their data in a post-GDPR world.</p>

            <h3 className="subheading">Accelerate your brand’s storytelling</h3>

            <p>
              “We are never shy of stories in Honda. There are lots of interesting stories and many fascinating ways customers use our
              products,” Nick says.
            </p>

            <figure className="block quote-block offset left">
              <blockquote>
                <p>
                  "Shorthand gives you a blank canvas and tools to drag and drop, allowing you to build a story that stands out and
                  performs perfectly on mobile, desktop and tablet."
                </p>
              </blockquote>
              <figcaption className="quote-cite">Nick Bennett</figcaption>
            </figure>

            <p>
              Nick had explored a multitude of other platforms before he decided on Shorthand. “There are a lot of websites and
              platforms that template and restrict you,” Nick says. “Shorthand gives you a blank canvas and tools to drag and drop,
              allowing you to build a story that stands out and performs perfectly on mobile, desktop and tablet. You don’t have to
              think about things like ‘Will it technically perform? Will it behave in the way it should?’ Instead you can simply focus
              on the storytelling.”
            </p>
            <p>
              One of Nick’s favourite stories is about the late F1 legend{" "}
              <a
                href="https://www.honda.co.uk/engineroom/world/honda-legends-ayrton-senna/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                Ayrton Senna
              </a>
              . Taking you on a journey through the racer’s life, it shows what he represented for a brand like Honda and how he
              impacted the sport. “This article was actually written by a member of our SEO team, not one of our regular content
              creators. We ran a competition with our team and this story won. It shows how easy the Shorthand editor is to use,” Nick
              explains.
            </p>
            <p>
              Another of Nick’s favourite stories,{" "}
              <a
                href="https://www.honda.co.uk/engineroom/world/the-englishman-who-conquered-the-mountain/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                The Englishman who conquered the mountain
              </a>
              , tells the tale of Robin Shute, the first Briton to win the Pike’s Peak International Hillclimb in Colorado, USA. The
              story starts with an autoplay video featuring an animation of the twisting tracks, uses a Scrollmation section for an
              interactive flipbook effect, and ends with plenty of CTAs for continued engagement.
            </p>

            <figure className="block quote-block offset right">
              <blockquote>
                <p>
                  "The team behind Shorthand is outstanding. From the sales process, to onboarding and continued support ⁠⁠— they are
                  knowledgeable, efficient and will do anything they can to help us succeed."
                </p>
              </blockquote>
              <figcaption className="quote-cite">Nick Bennett</figcaption>
            </figure>

            <p>
              There are now over 250 multimedia-rich stories published to the Honda Engine Room site, including{" "}
              <a
                href="https://www.honda.co.uk/engineroom/cars/back-to-school-with-the-honda-cr-v/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                Back to school with CR-V
              </a>
              , which was crowned Shorthand’s 2019 Best Brand Story; interactive{" "}
              <a
                href="https://www.honda.co.uk/engineroom/events/goodwood-2019/gfos-day-two/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                2019 Goodwood event coverage
              </a>
              ; and exciting news about a{" "}
              <a href="https://www.honda.co.uk/engineroom/cog/" target="_blank" rel="noopener noreferrer" className="btn-link">
                national UK television advert competition
              </a>
              .
            </p>
            <p>
              This is only the beginning. Nick and the team are publishing new stories to Engine Room regularly, and are now exploring
              its uses for other campaigns.
            </p>
            <p>
              “We are constantly looking to expand our use of Shorthand,” says Nick. “We’re easily able to embed other interactive
              tools into our stories, and the Shorthand team have introduced some great new storytelling features since we’ve been
              using the platform.
            </p>
            <p>
              “The team behind Shorthand is outstanding. From the sales process, to onboarding and continued support ⁠⁠— they are
              knowledgeable, efficient and will do anything they can to help us succeed.”
            </p>

            <div className="related-stories">
              <h4>Read more from Honda:</h4>
              <ul>
                <li>
                  <a
                    href="https://www.honda.co.uk/engineroom/goodwood-2018/day-one/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-link"
                  >
                    Goodwood Festival of Speed 2018 - day one
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.honda.co.uk/engineroom/events/goodwood-2019/gfos-day-one/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-link"
                  >
                    Goodwood Festival of Speed 2019 - day one
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.honda.co.uk/engineroom/cars/civic-the-iconic-hatchback/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-link"
                  >
                    The Civic: Our Honda hero through the years
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <RelatedCaseStudies studies={filterListByCurrentIndustryCode(data, "honda")} />
        <Footer />
      </main>
    </Layout>
  );
}

export const query = graphql`
  query HondaQuery {
    allCaseStudiesJson {
      edges {
        node {
          jsonId
          image
          industry
          industryCode
          organisation
          summary
        }
      }
    }
  }
`;
