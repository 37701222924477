import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";

interface BrandsProps {
  heading?: string;
}

export const Brands = ({ heading }: BrandsProps): JSX.Element => {
  const data = useStaticQuery(graphql`
    query TemplatesQuery {
      allBrandsJson {
        edges {
          node {
            logo
            name
            label
          }
        }
      }
    }
  `);

  return (
    <div>
      {heading && <h3 className="as-used">{heading}</h3>}
      <ul className="tw-mb-0 tw-grid tw-justify-items-center tw-gap-5  tw-gap-y-10 tw-opacity-50  max-xxxl:tw-grid-cols-4 max-md:tw-grid-cols-2 max-md:tw-grid-rows-4 max-sm:tw-grid-cols-2 min-xxxl:!tw-grid-cols-4">
        {data.allBrandsJson.edges.map((brand, index) => (
          <li key={`brand-${brand.node.label}-${index}`} className="tw-max-w-[150px]">
            <img src={brand.node.logo} alt={`${brand.node.label} logo`} />
          </li>
        ))}
      </ul>
    </div>
  );
};
