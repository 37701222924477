import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default class FeaturesScrollytelling extends React.Component<any, any> {
  render() {
    const { allBrandsJson } = this.props.data;

    const brands = allBrandsJson.edges;

    return (
      <Layout location={this.props.location}>
        <main id="main" className="marketing-content container-fluid">
          <Helmet
            bodyAttributes={{
              class: "body-shorthand body-marketing body-marketing-secondary body-marketing-features body-light hide-elevio",
            }}
          ></Helmet>
          <div className="row section-title title-light">
            <div className="col-11 col-sm-8  mx-auto section-inner">
              <h1>
                <span className="shorthand-logo dark" aria-hidden="true">
                  Shorthand
                </span>
                Scrollytelling
              </h1>
              <h2>Immersive digital stories that your readers will love.</h2>
            </div>
          </div>

          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Tell stories with scrolling</h3>
                  <p>Create stunning scroll-based image transitions with our Scrollmation and Reveal story sections.</p>
                  <ul className="features-list">
                    <li>Create rich no-code data visualisations, including interactive maps, charts, and graphs.</li>
                    <li>Bring your media to life with rich full-screen image transitions.</li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <video className="features-video-inline" muted loop playsInline autoPlay poster="/media/features/scrolly-scroll.jpg">
                  <source src="/media/features/scrolly-scroll.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
          </section>
          <section className="row section section-strip section-strip-right">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Simple story creation</h3>
                  <p>With simple ‘drag and drop’ media uploads and section ordering, anyone can build a Shorthand story.</p>
                  <ul className="features-list">
                    <li>Choose from a range of flexible and easy-to-use section types.</li>
                    <li>Anyone can build a story from scratch, including writers, marketers, editors, designers, and developers.</li>
                    <li>
                      Don’t worry about fonts and formatting &mdash; the Shorthand editor will make your copy consistent, on-brand, and
                      responsive.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/scrolly-edit.png" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>
          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Scrollpoints</h3>
                  <p>Take your audience on an interactive journey using an uploaded image, such a map, photo, or illustration.</p>
                  <ul className="features-list">
                    <li>
                      After uploading your image, simply choose which sections you want to highlight, and then optionally annotate them
                      with short sections of text.
                    </li>
                    <li>
                      As the reader scrolls down your Scrollpoints section, your Shorthand story will automatically pan and zoom into
                      each point in turn.
                    </li>
                    <li>
                      Like all Shorthand features, Scrollpoints can be created by anyone, without needing any help from a web designer
                      or developer.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <video
                  className="features-video-inline"
                  muted
                  loop
                  playsInline
                  autoPlay
                  poster="/media/features/scrollpoints-left.jpg"
                >
                  <source src="/media/features/scrollpoints-left.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
          </section>
          <section className="row section section-strip section-strip-right">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>Build with templates</h3>
                  <p>Get started immediately with our wide range of built-in templates.</p>
                  <ul className="features-list">
                    <li>Get a head-start on the most common story types, without reinventing the wheel.</li>
                    <li>Make it your own with a custom theme, which will set your brand fonts, colours, logo, and more.</li>
                  </ul>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/scrolly-templates.png" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section  section-strip-features-sm">
            <div className="container section-inner text-center">
              <div className="features features-cards icon-cards">
                <ul>
                  <li>
                    <div className="feature-card icon-card card-border card-shadow">
                      <div className="card-icon">
                        <i className="fas fa-photo-video"></i>
                      </div>
                      <div className="card-text">
                        <h3>Rich media</h3>
                        <p>Incorporate video, images, and sound directly into your story.</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="feature-card icon-card card-border card-shadow">
                      <div className="card-icon">
                        <i className="far fa-images"></i>
                      </div>
                      <div className="card-text">
                        <h3>Text over media</h3>
                        <p>Integrate words, images, and video to create truly immersive digital stories. </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="feature-card icon-card card-border card-shadow">
                      <div className="card-icon">
                        <i className="fas fa-universal-access"></i>
                      </div>
                      <div className="card-text">
                        <h3>Accessibility</h3>
                        <p>Build accessible stories with Shorthand, including alt-text options and accessible themes.</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <section className="row section  section-strip section-strip-quote ">
            <div className="container container-sm">
              <div className="section-strip-col-single section-strip-single-quote">
                <div className="section-strip-quote-inner">
                  <blockquote>
                    &ldquo;Shorthand allows our editors and art directors the flexibility to dream up, execute, and design beautiful
                    multimedia stories — without relying on developers.&rdquo;
                  </blockquote>
                  <cite>Michael Chu | Executive Director for Content Operations & Creative Services | Source Media</cite>
                </div>
              </div>
            </div>
          </section>

          <section className="row section  section-strip-stories section-border-top">
            <div className="container flex-column section-inner text-center">
              <h3>Scrollytelling in action</h3>
              <div className="featured-stories">
                <ul>
                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://www.bbc.co.uk/news/extra/CLQYZENMBI/amazon-data"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/bbc-amazon.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        Why Amazon knows so much about you <span className="featured-story-brand">BBC News</span>
                      </h4>
                    </a>
                  </li>

                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://www.honda.co.uk/engineroom/electric/hybrid/behind-the-wheel-of-the-all-new-jazz/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/honda-jazz.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        Behind the wheel of the all new Jazz <span className="featured-story-brand">Honda</span>
                      </h4>
                    </a>
                  </li>

                  <li>
                    <a
                      className="card-border card-shadow"
                      href="https://www.nbcnews.com/specials/china-illegal-fishing-fleet/index.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="featured-story-media">
                        <img src="/media/featured-stories/nbc-china.jpg" alt="" />
                      </div>
                      <h4 className="featured-story-title">
                        The deadly secret of China’s invisible Armada <span className="featured-story-brand">NBC News</span>
                      </h4>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="row section section-dark section-half  section-trial !tw-bg-[rgba(249,249,249,0.3)]">
            <div className="container section-inner text-center">
              <h2 className="!tw-text-black">
                Ready to try the digital storytelling platform used by Honda, University of Cambridge, and the BBC?
              </h2>

              <div className="cta-container">
                <a href="https://app.shorthand.com/signup" className="btn btn-lg !tw-bg-black hover:!tw-opacity-70">
                  Start creating
                </a>
              </div>
              <div className="brands">
                <div className="brands-container">
                  <h3 className="as-used">Join these iconic brands</h3>
                  <ul>
                    {brands.map((brand, index) => (
                      <li key={`brand-${brand.node.label}-${index}`} className={`brand`}>
                        <img src={brand.node.logo} alt={`${brand.node.label} logo`} />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="background-media background-media-fixed">
              <img src="/media/home/stories.jpg" className="ObjectFit--cover" alt="" />
            </div>
          </section>
          <section className="row section section-gray-lighest section-all-features">
            <div className="container section-inner text-center">
              <h2>
                <a className="unstyled" href="/features/">
                  More Shorthand features
                </a>
              </h2>

              <div className="features features-cards">
                <ul>
                  {/* <li>
                  <a className="feature-card card-border card-shadow unstyled" href="/features/scrollytelling/">
                    <div className="card-text">
                      <h3>Scrollytelling</h3>
                    </div>
                  </a>
                </li> */}
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/visual-storytelling/">
                      <div className="card-text">
                        <h3>Visual Storytelling</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/collaboration/">
                      <div className="card-text">
                        <h3>Collaboration</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/customisation/">
                      <div className="card-text">
                        <h3>Customisation & Integration</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/branding/">
                      <div className="card-text">
                        <h3>Themes & Branding</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/sharing-and-analytics/">
                      <div className="card-text">
                        <h3>Sharing & Analytics</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/publishing-and-hosting/">
                      <div className="card-text">
                        <h3>Publishing & Hosting</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/support/">
                      <div className="card-text">
                        <h3>Support</h3>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="feature-card card-border card-shadow unstyled" href="/features/security/">
                      <div className="card-text">
                        <h3>Security</h3>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <Footer className="mt-0" />
        </main>
      </Layout>
    );
  }
}

export const query = graphql`
  query FeaturesScrollytellingQuery {
    site {
      siteMetadata {
        name
        title
        tagline
        description
      }
    }
    allBrandsJson {
      edges {
        node {
          logo
          name
          label
        }
      }
    }
  }
`;
