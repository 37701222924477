import { filterListByCurrentIndustryCode } from "../../Utils";
import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { RelatedCaseStudies } from "../../components/RelatedCaseStudies";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default function CaseStudy({ data, location }) {
  return (
    <Layout location={location}>
      <div id="main" className="marketing-content container-fluid">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-secondary body-marketing-case-studies hide-elevio",
          }}
        />
        <div className="row section-title">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6 mx-auto section-inner">
            <h1>
              <span className="shorthand-logo">Shorthand</span>
              <span className="subsection-label">Case Study:</span>
              Harpoon
            </h1>
            <h2>How Harpoon uses Shorthand</h2>
          </div>
          <div aria-hidden="true" className="background-media">
            <img
              src="/media/case-studies/case-studies-harpoon-title.jpg"
              data-object-fit="cover"
              data-autoscale-content-ratio="1.77777777778"
              alt=""
            />
          </div>
        </div>
        <div className="row section">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto marketing-main-column">
            <h2 className="intro">How does a small digital agency produce some of the most engaging visual stories on the web?</h2>
            <div className="inline-image featured">
              <img alt="" src="/media/case-studies/case-studies-harpoon.jpg" />
            </div>
            <p>
              <a href="https://www.harpoon.productions/" target="_blank" className="btn-link" rel="noopener noreferrer">
                Harpoon
              </a>{" "}
              is a digital agency based in London with an impressive roster of clients, including Honda, the European Council,
              Christies, and the Football Association.
            </p>
            <p>
              The team at Harpoon is responsible for some of the most acclaimed digital stories on the web, including many of the
              stories in the{" "}
              <a href="https://www.honda.co.uk/engineroom/" target="_blank" className="btn-link" rel="noopener noreferrer">
                Honda Engine Room
              </a>
              .
            </p>
            <p>
              We caught up with Harpoon’s passionate founder and editor Giles Wilson to learn more about their success. We started by
              asking about Giles’ long history working with Shorthand, which stretches back to 2014.
            </p>
            <h3 className="subheading">A gift from heaven</h3>
            <figure className="block quote-block offset left">
              <blockquote>
                <p>“When Shorthand came, it was just like a gift from heaven. It put control into the hands of the journalists.”</p>
              </blockquote>
              <figcaption className="quote-cite">Giles Wilson</figcaption>
            </figure>
            <p>
              When Giles first encountered Shorthand, he was Features Editor at the BBC. “We obviously produced a lot of stories at the
              BBC. But we didn't have any ability to make them stand out, to indicate to the reader that this was something special. We
              were stuck — as so many people are — within the constraints of our CMS.”
            </p>
            <p>
              Following the publication of ‘Snowfall’ by the <em>New York Times</em>, many journalists at the BBC and elsewhere were
              feeling incredibly ambitious about visual storytelling. But nearly every organisation lacked the <em>Times’</em>{" "}
              development resources.
            </p>
            <p>
              As Giles explains, “We had so much ambition. With that story, people suddenly realised what you could do with digital
              storytelling. And so we had to find a way of being able to play on the same field as that.
            </p>
            <p>
              “When Shorthand came, it was just like a gift from heaven. It put control into the hands of the journalists. Although we
              had developers at the BBC, Shorthand meant we didn't have to rely on them to be working on production stories, everyday
              stories. And so that was a huge advantage for us.”
            </p>
            <p>
              While at the BBC, Giles and his Magazine team produced a range of outstanding visual stories, including{" "}
              <a
                href="https://www.bbc.co.uk/news/special/2014/newsspec_7141/index.html"
                target="_blank"
                className="btn-link"
                rel="noopener noreferrer"
              >
                Arms wide open
              </a>
              . His legacy lives on, as the BBC continue to publish powerful work using Shorthand to this day.
            </p>
            <h3 className="subheading">The rise of Harpoon</h3>
            <figure className="block quote-block offset right">
              <blockquote>
                <p>“It's our passion to do interesting, immersive, and beautiful work with Shorthand.”</p>
              </blockquote>
              <figcaption className="quote-cite">Giles Wilson</figcaption>
            </figure>
            <p>After leaving the BBC, Giles was passionate about continuing his work on narrative and longform non-fiction.</p>
            <p>
              “Longform was by far the most exciting area of journalism for me. And I wanted to do more of it. I just love making
              stories. I love getting things published.
            </p>
            <p>
              “If I have a choice between spending a day in meetings or just getting stories published, then I know what I want to do.
              And that's why I decided to set up Harpoon.”
            </p>
            <p>
              Harpoon is the world’s first digital agency that specialises in Shorthand stories. As Giles explains, “It's not
              everything we do, but we love specialising in it. It's our passion to do interesting, immersive, and beautiful work with
              it.”
            </p>
            <p>
              Harpoon has developed a stunning track record of immersive digital content. One of Giles’ favourite stories from the last
              year is the{" "}
              <a href="https://stories.msc.org/uk/blue-cookbook/" target="_blank" className="btn-link" rel="noopener noreferrer">
                Blue Cookbook
              </a>{" "}
              from the Marine Stewardship Council, a sustainable fish cookbook built entirely in Shorthand.
            </p>
            <p>
              Another favourite is{" "}
              <a
                href="https://www.consilium.europa.eu/en/covid-vaccine-journey/"
                target="_blank"
                className="btn-link"
                rel="noopener noreferrer"
              >
                Stronger Together
              </a>
              , a story about vaccine development produced in collaboration with the European Council.
            </p>
            <p>
              As Giles explains, “That was a beautiful bit of work. The text, graphics, and imagery really came together to explain the
              different vaccines. Until we worked on that story I wasn't quite aware of the difference in the science between the
              vaccines. It was really educational.
            </p>
            <p>“But the piece is also beautiful. And it has performed extremely well for the Council.”</p>
            <div className="photo-grid flex featured">
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/case-studies-harpoon-sml-01.jpg" />
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/case-studies-harpoon-sml-03.jpg" />
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/case-studies-harpoon-sml-04.jpg" />
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/case-studies-harpoon-sml-02.jpg" />
            </div>
            <div className="caption">A selection of sections from Harpoon's stories</div>

            <h3 className="subheading">A proper journalistic discipline</h3>

            <p>
              Leaving the BBC to found a digital agency might seem like an enormous departure, but many of the skills involved in
              producing Shorthand stories are essentially journalistic.
            </p>
            <p>
              “If you work on a magazine or if you're a journalist on a newspaper, the handling of pictures — the layout of pictures —
              is a proper journalistic discipline.
            </p>
            <p>
              “There's no reason why that shouldn't transfer to the digital world as well. This is basic journalism we're talking about
              here. And just because it's not out with a notebook getting details or on the phone ringing somebody, I still think this
              is journalism production. And that's why I love it so much.”
            </p>
            <p>
              How does the team at Harpoon keep producing so many innovative stories? Giles’ method is to keep a scratchpad of ideas to
              try out when the right project comes along.
            </p>
            <p>
              “Just this morning I was admiring a particular way video was integrated into a website. And it struck me: I could do that
              in Shorthand. I’ll often see things that people have done in bespoke websites or high-end design websites — or sometimes
              even in TV and video graphics.
            </p>
            <p>
              “Then I think, ‘how could we do that? How can we achieve that effect?’ Nine times out of 10, there is a way to achieve
              that effect in Shorthand just by overlaying images or using clever transitions, or maybe using some custom HTML.”
            </p>
            <h3 className="subheading">The best there is</h3>
            <figure className="block quote-block offset left">
              <blockquote>
                <p>“So much good work has been put in by the Shorthand development team… It's the best there is.”</p>
              </blockquote>
              <figcaption className="quote-cite">Giles Wilson</figcaption>
            </figure>
            <p>As a longtime partner, what makes Giles keep working with Shorthand?</p>
            <p>
              One reason for Giles is that the flexibility of the Shorthand platform means that he can keep experimenting and trying
              out new storytelling techniques.
            </p>
            <p>
              “I'm an experienced Shorthand user, and I'm still learning how to do things. It's completely flexible in what we can do
              with it. This is great for clients as well, because if there are things that they particularly want to achieve, then we
              love to try and make that happen.”
            </p>
            <p>More than the flexibility, though, is the continual development of the platform by the Shorthand team.</p>
            <p>
              “I've often thought, what is it that makes Shorthand different and special? There are lots of different tools you can use
              on the web. I always come back to the fact that so much good work has been put in by the Shorthand development team on
              making the tool as good as it is. It's the best there is.”
            </p>
          </div>
        </div>
        <RelatedCaseStudies studies={filterListByCurrentIndustryCode(data, "harpoon")} />
        <Footer />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query HarpoonQuery {
    allCaseStudiesJson {
      edges {
        node {
          jsonId
          image
          industry
          industryCode
          organisation
          summary
        }
      }
    }
  }
`;
