import { filterListByCurrentIndustryCode } from "../../Utils";
import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { RelatedCaseStudies } from "../../components/RelatedCaseStudies";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default function CaseStudy({ data, location }) {
  return (
    <Layout location={location}>
      <main id="main" className="marketing-content container-fluid">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-secondary body-marketing-case-studies hide-elevio",
          }}
        />
        <div className="row section-title">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto  section-inner">
            <h1>
              <span className="shorthand-logo">Shorthand</span>
              <span className="subsection-label">Case Study:</span>
              Sky News
            </h1>
            <h2>How Sky News use Shorthand</h2>
          </div>
          <div aria-hidden="true" className="background-media">
            <img
              src="/media/case-studies/case-studies-skynews-title.jpg"
              data-object-fit="cover"
              data-autoscale-content-ratio="1.77777777778"
              alt=""
            />
          </div>
        </div>
        <div className="row section">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto marketing-main-column">
            <h3 className="intro">
              How can a leading media company produce stunning visual stories &mdash; on demand and at scale &mdash; without hiring
              teams of developers and web designers?
            </h3>
            <div className="inline-image featured">
              <img
                alt="Brexit by numbers, by Sky News, renders responsively across all devices"
                src="/media/case-studies/case-studies-skynews.jpg"
              />
            </div>
            <p>This was the question faced by Ronan Hughes, Output Editor at Sky News, in early 2018.</p>
            <p>As he explains, “As an organisation, we wanted to do more long-form, high-impact, and visually rich storytelling.”</p>
            <p>
              The problem was that bespoke immersive stories &mdash; the type increasingly produced by competitors like the{" "}
              <em>New York Times</em> &mdash; were too expensive and time-consuming to produce, sometimes requiring up to three months
              of lead time.
            </p>
            <p>
              “These bespoke stories take two-to-three months of developer time to build. We just haven't got the scope to do that. It
              was clear that it was far too time-consuming to have developers build these stories every single time.”
            </p>
            <p>
              After researching a range of digital content platforms, Ronan landed on Shorthand. “I thought the product looked really
              smart, and we haven't looked back from there. Shorthand has transformed how we produce our digital longform journalism.”
            </p>
            <h3 className="subheading">Longform feature stories</h3>
            <figure className="block quote-block offset right">
              <blockquote>
                <p>“Shorthand has transformed how we produce our digital longform journalism.”</p>
              </blockquote>
              <figcaption className="quote-cite">Ronan Hughes</figcaption>
            </figure>
            <p>
              The team at Sky News primarily use Shorthand to create longform feature stories. One particular speciality for the team —
              and the source of some of their most astonishing stories &mdash; is foreign reporting.
            </p>
            <p>
              As Ronan says, “Journalists like Alex Crawford and Stuart Ramsay &mdash; they go to remote places in the world and film
              really rich visual stories.”
            </p>
            <p>
              One stunning example from recent years is{" "}
              <a
                href="https://news.sky.com/story/who-owns-antarctica-claims-and-pressures-facing-the-continent-11900409"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                Who owns Antarctica?
              </a>
              , a story full of historical background, illustrated maps, and photographs.
            </p>
            <p>
              Another impressive story is{" "}
              <a
                href="https://news.sky.com/story/brazil-amazon-the-rainforest-is-back-under-attack-from-jair-bolsonaros-government-11779465"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                Brazil’s rainforest: Back under attack
              </a>
              , which details the rapid increase in deforestation under President Jair Bolsonaro with data-driven maps, rich
              illustrations, and still photography.
            </p>
            <p>
              Following the success of these stories, news-gathering teams are increasingly thinking about how their content will look
              in Shorthand.
            </p>
            <p>
              “They news-gather content thinking specifically about how it will manifest itself in the digital sphere. So they're now
              thinking, ‘We need to film the piece for TV and for YouTube. We need to gather the content for digital.’”
            </p>
            <div className="case-study-video-container">
              <video className="case-study-video-inline" muted loop playsInline autoPlay>
                <source src="/media/case-studies/case-studies-skynews.mp4" type="video/mp4" />
              </video>
            </div>
            <h3 className="subheading">Multimedia and data storytelling</h3>
            <figure className="block quote-block offset left">
              <blockquote>
                <p>
                  “Our Brit Awards story is a classic example of Shorthand allowing us to create visually engaging stories extremely
                  quickly.”
                </p>
              </blockquote>
              <figcaption className="quote-cite">Ronan Hughes</figcaption>
            </figure>
            <p>Another strong use case for the Sky News team is longform analysis and data storytelling.</p>
            <p>“Shorthand is great for when we are doing additional analysis or diving deep into a particular issue,” Ronan says.</p>
            <p>
              One example of Sky News’s data storytelling is their award-winning story{" "}
              <a
                href="https://news.sky.com/story/better-for-brexit-how-uk-has-changed-since-leave-vote-11920143"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                Brexit by Numbers
              </a>
              . With compelling illustrations and accessible charts, the story gives a comprehensive introduction to the impact of
              Brexit on the United Kingdom’s economy and society.
            </p>
            <p>
              For Ronan, a particular strength of the story &mdash; enabled by Shorthand &mdash; is that it is effective on mobile
              devices. “What I really loved about this story is that it felt like a proper mobile-native experience, flowing smoothly
              down the screen.”
            </p>
            <p>
              The Sky News team also use Shorthand for multimedia stories that include multiple images, videos, and interactive
              elements. One great example of their multimedia storytelling is{" "}
              <a
                href="https://news.sky.com/story/the-plastic-nile-why-pollution-in-africas-greatest-river-threatens-all-our-futures-11997479"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                The Plastic Nile
              </a>
              , an investigation into the enormous amounts of plastic choking the world’s longest river.
            </p>
            <p>
              “This story was an extremely effective vertical experience. We used the shape of the map of the Nile to carry the reader
              down the story. I’m also proud of how we integrated video into the experience without slowing down the load time. It was
              a really strong way of telling what was a fantastic original story.”
            </p>
            <div className="photo-grid flex featured">
              <img
                alt="A section from one of Sky News stories"
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-skynews-sml-01.jpg"
              />
              <img
                alt="A section from one of Sky News stories"
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-skynews-sml-02.jpg"
              />
              <img
                alt="A section from one of Sky News stories"
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-skynews-sml-03.jpg"
              />
              <img
                alt="A section from one of Sky News stories"
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-skynews-sml-04.jpg"
              />
            </div>
            <div className="caption">A selection of sections from Sky News' stories</div>
            <h3 className="subheading">“A lot more impact”</h3>
            <figure className="block quote-block offset right">
              <blockquote>
                <p>
                  “We can create premium stories in a couple of days &mdash; or even a day if we want to &mdash; and it just has a lot
                  more impact.”
                </p>
              </blockquote>
              <figcaption className="quote-cite">Ronan Hughes</figcaption>
            </figure>
            <p>
              Ronan and the team love Shorthand for the flexibility it offers. “The beauty of Shorthand is that it’s flexible. From the
              beginning, we were able to produce stories which were far more visually engaging than our own CMS allows us to do.”
            </p>
            <p>
              As Ronan explains, their usual CMS &mdash; like most &mdash; isn’t able to produce immersive, interactive stories. “Our
              CMS gives us templates for producing standard articles which have text and an image, but it’s quite rigid. There's not
              much flexibility to build outside of that.”
            </p>
            <p>
              With Shorthand, the team can produce immersive, interactive stories on demand. “We can create premium stories in a couple
              of days &mdash; or even a day if we want to &mdash; and it just has a lot more impact. We can do stand-out pieces
              whenever we want to, really.”
            </p>
            <p>
              Ronan gives the example of the{" "}
              <a
                href="https://news.sky.com/story/brit-awards-2021-fashion-all-the-best-outfits-as-the-red-carpet-returns-12303629"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                2021 Brit Awards
              </a>
              , which had taken place the night before he spoke with the Shorthand team for this piece. “We turned around a piece on
              the red carpet, with multiple images and text in different formats, in ninety minutes. It was a really engaging,
              visually-rich piece, and we produced it in no time.
            </p>
            <p>
              “There was no bespoke design. It was all built completely using Shorthand’s built-in features. It’s a classic example of
              Shorthand allowing us to create visually engaging stories extremely quickly.”
            </p>
            <h3 className="subheading">Pushing the boundaries</h3>
            <p>
              Ronan describes the stories published using Shorthand as premium content. “We use Shorthand to make the most of our
              premium content. It will be something that we'll discuss in terms of the treatment of a story: Is it a long read with a
              standard CMS template or is it a Shorthand?
            </p>
            <p>
              “It's become common parlance within the newsroom now. You hear foreign correspondents in the field saying, "Oh, I'm going
              to have to do such and such. I really want to do a Shorthand on this."
            </p>
            <p>As Ronan explains, “Our correspondents see how great their colleagues’ pieces look and want the same treatment too.”</p>
            <p>
              Over the years, Ronan and the team have continued to evolve and expand their digital storytelling. “I like the fact that
              we can experiment with Shorthand. I like the challenge of making something which nobody else has done. We've pushed the
              boundaries of what we can do with Shorthand, and we continue to push them further.”
            </p>
          </div>
        </div>
        <RelatedCaseStudies studies={filterListByCurrentIndustryCode(data, "sky-news")} />
        <Footer />
      </main>
    </Layout>
  );
}

export const query = graphql`
  query SkyNewsQuery {
    allCaseStudiesJson {
      edges {
        node {
          jsonId
          image
          industry
          industryCode
          organisation
          summary
        }
      }
    }
  }
`;
