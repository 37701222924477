import { IPlan } from "./index";

export const enterprise: IPlan = {
  id: "enterprise",
  name: "Enterprise",
  description: "Enterprise",
  chargebee: new Set([
    "enterprise-monthly-aud",
    "enterprise-monthly-usd",
    "enterprise-monthly-eur",
    "enterprise-monthly-gbp",
    "enterprise-annual-aud",
    "enterprise-annual-usd",
    "enterprise-annual-eur",
    "enterprise-annual-gbp",
  ]),
  features: {
    "organisation-manage-publish-configurations": true,
    "organisation-team-leader-view": true,
    "organisation-team-limit": 50,
    "organisation-member-limit": 1000,
    "organisation-team-member-limit": 250,
    "organisation-sites": true,
    "page-settings-amp-enabled": false,
    "page-settings-analytics-ga": true,
    "page-settings-override-social-cover-url": true,
    "shorthand-analytics-tracking": true,
    "story-credits-required": true,
    "themes-demo": false,
    "publish-embed-ui": true,
    "story-comments": true,
    "theme-builder": true,
    "page-settings-password-protect-previews": true,
    "custom-hosting-limit": 5,
    "publish-configurations-download": true,
    "page-settings-analytics-gtm": true,
    "developer-tools": true,
    "page-settings-custom-head": true,
    "shorthand-secure-hosting-enabled": true,
    "story-hide-footer": true,
    "organisation-story-analytics-export": true,
    "organisation-workspace-analytics-export": true,
    "project-search-nav": true,
  },
  marketing: {
    label: "Important stories at scale with best-in-class compliance.",
    className: "plan-enterprise",
    benefits: [
      "Bespoke custom branded theme",
      "White labelling",
      "SAML Single Sign-On (SSO)",
      "Custom technical support tiers",
      "Custom strategic success tiers",
    ],
  },
};
