import cx from "classnames";
import * as React from "react";

export const Paragraph = ({
  children,
  className,
  htmlText,
}: {
  htmlText?: string;
  children?: React.ReactNode;
  className?: string;
}): JSX.Element => {
  const modifiers = cx("tw-leading-[1.3] !tw-grey_800 tw-text-xl !tw-font-CircularXXSub !tw-m-0 tw-text-wrap", className);
  if (htmlText) {
    return <p className={modifiers} dangerouslySetInnerHTML={{ __html: htmlText }} />;
  }
  return <p className={modifiers}>{children}</p>;
};
