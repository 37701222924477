import { filterListByCurrentIndustryCode } from "../../Utils";
import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { RelatedCaseStudies } from "../../components/RelatedCaseStudies";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default function CaseStudy({ data, location }) {
  return (
    <Layout location={location}>
      <main id="main" className="marketing-content container-fluid">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-secondary body-marketing-case-studies hide-elevio",
          }}
        />
        <div className="row section-title">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto  section-inner">
            <h1>
              <span className="shorthand-logo">Shorthand</span>
              <span className="subsection-label">Case Study:</span>
              Perform Group
            </h1>
            <h2>How Perform Group is using Shorthand &amp; visual storytelling to expand its commercial &amp; editorial portfolio</h2>
          </div>
          <div aria-hidden="true" className="background-media">
            <img
              src="/media/case-studies/case-studies-perform-title.jpg"
              data-object-fit="cover"
              data-autoscale-content-ratio="1.77777777778"
              alt=""
            />
          </div>
        </div>
        <div className="row section">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto marketing-main-column">
            <h3 className="intro">
              The digital sports content and media company has been using Shorthand across its suite of brands for over a year to tell
              stories - that might not otherwise be told - in an engaging way across desktop, tablet and mobile.
            </h3>
            <div className="inline-image featured">
              <img
                src="/media/case-studies/case-studies-perform.jpg"
                alt="The Olympian, by Perform Group, renders responsively across all devices"
              />
            </div>
            <p>
              Before Perform Group started using Shorthand, they had a set of article templates. Tweaking the roadmap and committing
              developer resource to alter those was a project of its own - a hurdle that is always difficult to jump, given ongoing
              product and commercial needs. Sound familiar?
            </p>
            <figure className="block quote-block offset left">
              <blockquote>
                <p>
                  "The ability to turn around content very quickly from a start to finish perspective made [Shorthand] attractive. It
                  got around our product and development roadmap challenges."
                </p>
              </blockquote>
              <figcaption className="quote-cite">Shaun Koiner &ndash; Chief product and content officer, Perform Media</figcaption>
            </figure>
            <p>
              “There’s always an overhead to create content with a different look and feel. Sometimes it requires custom work and
              generally commercial deals will drive this,” said Shaun Koiner, chief product and content officer for Perform Media, a
              division of Perform Group.
            </p>

            <p>
              Wire frames and designs around high-end templates for longform articles had been discussed, but kept getting pushed back.
              So, when Shaun found Shorthand he was excited about its features and started using Shorthand for Goal’s editorial team.
              The first story they launched was{" "}
              <a
                href="http://www.goal.com/story/zidane-a-galactico-great-for-real-madrids-champions-league-finalists/index.html"
                target="_blank"
                className="btn-link"
              >
                Zidane - a Galactico for Galacticos
              </a>
              .
            </p>
            <p>
              “The ability to turn around content very quickly from a start to finish perspective made it attractive. It got around our
              product and development roadmap challenges,” said Shaun.
            </p>
            <figure className="block quote-block offset right">
              <blockquote>
                <p>
                  "Shorthand makes content that is limited in our traditional article template feel special. Previously, our showcase
                  features looked the same as our everyday news stories."
                </p>
              </blockquote>
              <figcaption className="quote-cite">Shaun Koiner</figcaption>
            </figure>
            <p>
              As well as the time it took to create stories, Shaun and other colleagues were immediately impressed with the look of
              their new content and the engagement they were getting.
            </p>
            <p>
              “Our Shorthands typically have a longer engagement time,” Shaun explained - the knowledge of which is made available by
              the direct integration of Google Analytics in the tool. “The mobile point was important too. It provided us a fast,
              well-performing experience on mobile, which accounts for upwards of 75% of our traffic,” Shaun continued.
            </p>

            <div className="photo-grid flex featured">
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-perform-sml-01.jpg"
                alt="A section from one of Perform Group's stories"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-perform-sml-02.jpg"
                alt="A section from one of Perform Group's stories"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-perform-sml-04.jpg"
                alt="A section from one of Perform Group's stories"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-perform-sml-03.jpg"
                alt="A section from one of Perform Group's stories"
              />
            </div>
            <div className="caption">A selection of sections from Perform Media's stories</div>
            <p>
              “Shorthand makes content that is limited in our traditional article template feel special. Previously, our showcase
              features looked the same as our everyday news stories.”
            </p>
            <h3 className="subheading">Seeing the benefits company-wide</h3>
            <p>
              Goal - the largest football focused site in the world, predominantly focused on breaking news and social sharing pieces -
              began using Shorthand regularly for more in-depth editorial, and commercially sponsored stories. Within six months,
              Perform’s other titles were taking note.
            </p>
            <figure className="block quote-block offset left">
              <blockquote>
                <p>
                  "The immediate aftermath was a desire to turn it from a Goal proposition to something we use across all of our
                  products and portals. We now use Shorthand across Sporting News, Spox and Voetbalzone, as well as multiple languages
                  across all brands."
                </p>
              </blockquote>
              <figcaption className="quote-cite">Shaun Koiner</figcaption>
            </figure>
            <p>
              “The immediate aftermath was a desire to turn it from a Goal proposition to something we use across all of our products
              and portals. We now use Shorthand across Sporting News, Spox and Voetbalzone, as well as multiple languages across all
              brands,” Shaun said.
            </p>
            <p>
              One of the ambassadors of Shorthand internally is now Paul Howe, design manager for the Americas & Australia at Sporting
              News - a leading US, and now global, sports website and former print magazine.
            </p>
            <p>
              “Unless it came through the commercial team in the US, we were not prioritizing high-end storytelling from a
              look-and-feel perspective. I felt we were not making the most out of a very talented design team for editorial content.”
              Paul commented.
            </p>

            <p>
              “We’d work on social graphics or header graphics for articles, some infographics, but we were never taking it to the
              level that we are now with Shorthand. Hence, we ended up doing so many as soon as we got access to it!”
            </p>
            <p>
              As well as being easy to use, Paul has welcomed the fact that Shorthand has transformed the way the teams work. “I
              actually think it has made people work harder. We now have an alignment in the editorial, design, social and marketing
              teams that we never had before.
            </p>
            <p>
              “
              <a
                href="https://www.sportingnews.com/faces-of-2018-year-in-preview"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                The Faces of 2018
              </a>{" "}
              story we did - that idea came from one of our designers who had been using Shorthand and found a new way of using it.
              That’s never happened before!”
            </p>
            <h3 className="subheading">Journalism at its best</h3>
            <p>
              Shaun pointed out that there has been a shift in mindset and workflow because of Shorthand too. The longer-form articles
              require more planning than their typical social posts, but are now a crucial aspect of brand building. Plus, the
              journalists and designers want to tell and work on memorable stories that have a long shelf life.
            </p>
            <p>
              He recalled the{" "}
              <a
                href="http://www.sportingnews.com/2018-winter-olympics-usa-hockey-roster-jordan-greenway-the-olympian"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                story of Jordan Greenway
              </a>
              , the first African-American hockey player to play on the US olympic team. “The small local newspaper from the community
              from which he came sent over 100 eyeballs a day when it was published – which is probably half of the population of that
              town!” Shaun said.
            </p>
            <p>
              “Rather than just being a destination where you can read quality thought leadership or the highlight of last night’s
              game, we’ve returned to a site where you can find interesting stories, told in an immersive way, that might not otherwise
              be told.”
            </p>
            <div className="related-stories">
              <h4>Read more from Perform Group:</h4>
              <ul>
                <li>
                  <a
                    href="https://www.sportingnews.com/ca/athletics/features/commonwealth-games-2018-canada-top-athletes-medals-preview-penny-oleksiak-kylie-masse-damian-warner-how-watch-stream-dazn"
                    target="_blank"
                    className="btn-link"
                  >
                    Sporting News: Commonwealth Games 2018: Canada’s Top Medal Hopefuls
                  </a>
                </li>
                <li>
                  <a href="https://www.goal.com/story/goal-50-english/" target="_blank" rel="noopener noreferrer" className="btn-link">
                    Goal 50
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.goal.com/story/messi-ronaldo-children-of-football/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-link"
                  >
                    Goal: Ronaldo and Messi
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.spox.com/story/nba-awards-mvp-james-harden-lebron-james/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-link"
                  >
                    Spox: NBA Awards
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <RelatedCaseStudies studies={filterListByCurrentIndustryCode(data, "perform-group")} />
        <Footer />
      </main>
    </Layout>
  );
}

export const query = graphql`
  query PerformGroupQuery {
    allCaseStudiesJson {
      edges {
        node {
          jsonId
          image
          industry
          industryCode
          organisation
          summary
        }
      }
    }
  }
`;
