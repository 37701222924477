import UnderlineLink from "../Atoms/UnderlineLink";
import cx from "classnames";
import * as React from "react";

export default function ChooseShorthandReasons({
  title,
  content,
}: {
  title: string;
  content: {
    title?: string;
    content?: string;
    cta?: {
      text: string;
      link: string;
    };
  }[];
}) {
  const isEven = (index: number) => index % 2 === 1;
  return (
    <>
      <div className="text-center  tw-flex tw-justify-center">
        <h2 className="tw-h1  tw-mb-6 tw-w-[70%] tw-text-black max-sm:tw-w-9/12 max-sm:!tw-text-3xl">{title}</h2>
      </div>
      <section className="tw-flex  tw-w-full tw-justify-center ">
        <div className="tw-flex tw-w-[80%] tw-flex-col tw-justify-center tw-gap-12 max-md:tw-mt-[8vh] max-md:tw-h-auto max-sm:tw-mt-0  max-sm:!tw-w-[100%] max-sm:tw-gap-0 ">
          {content.map((indiContent, index) => {
            return (
              <div
                className={cx(`list-item-transition tw-relative tw-flex tw-min-h-[500px]  tw-items-center tw-justify-between tw-overflow-hidden tw-rounded-2xl tw-p-8 tw-pl-20 tw-pr-20 !tw-shadow-default max-md:tw-block max-md:!tw-min-h-fit max-sm:!tw-min-h-fit max-sm:tw-rounded-none 
          max-sm:tw-pl-8 max-sm:tw-pr-8  max-sm:tw-shadow-none ${
            isEven(index) ? "tw-flex-row-reverse tw-bg-text_black !tw-text-white" : " tw-border-text_black tw-bg-white !tw-text-[#000]"
          }`)}
                key={index}
              >
                <div className="tw-w-[35%] tw-text-center max-md:tw-hidden">
                  <span className={`tw-block tw-font-CircularXXSub tw-text-[200px] tw-font-bold `}>{index + 1}</span>
                  {/* <div
                    id="rounded-graphic"
                    className={`tw-absolute
                    ${
                      isEven(index)
                        ? "tw-bg-[rgba(255,_255,_255,_0.02)] tw-rounded-l-[1000px] tw-right-0 tw-top-[5%] tw-h-[90%] tw-w-[40%]"
                        : "tw-bg-[rgba(0,_0,_0,_0.02)] tw-rounded-r-[1000px] tw-left-0 tw-top-[5%] tw-h-[90%] tw-w-[40%]"
                    }
                    
                    max-md:tw-hidden `}
                  ></div> */}
                </div>
                <div className="tw-w-[55%] max-md:tw-w-full">
                  <h3 className="tw-h2">
                    <span className={`tw-hidden max-md:tw-inline-block  `}>{index + 1}.</span>
                    {indiContent.title}
                  </h3>
                  <p
                    className="tw-h4 tw-mb-0"
                    dangerouslySetInnerHTML={{
                      __html: indiContent.content,
                    }}
                  ></p>
                  {indiContent.cta?.text && (
                    <UnderlineLink
                      arrow={true}
                      cta={indiContent.cta}
                      className={cx(
                        "tw-mt-6 tw-border-white hover:!tw-opacity-50",
                        `${isEven(index) ? "!tw-text-[#fff]" : "!tw-text-[#000]"}`,
                        "tw-normal-case first-letter:tw-capitalize"
                      )}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
}
