import { filterListByCurrentIndustryCode } from "../../Utils";
import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { RelatedCaseStudies } from "../../components/RelatedCaseStudies";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default function CaseStudy({ data, location }) {
  return (
    <Layout location={location}>
      <main id="main" className="marketing-content container-fluid">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-secondary body-marketing-case-studies hide-elevio",
          }}
        >
          <title>RELX | Visual storytelling</title>
          <meta
            name="description"
            content="Using Shorthand, RELX achieved a tenfold increase in reads for their internal communications — and saw employee Net Promoter Scores soar by 20 percentage points to record levels."
          />
        </Helmet>
        <div className="row section-title">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto  section-inner">
            <h1>
              <span className="shorthand-logo">Shorthand</span>
              <span className="subsection-label">Case Study:</span>
              RELX
            </h1>
            <h2>
              Using Shorthand, RELX achieved a tenfold increase in reads for their internal communications — and saw employee Net
              Promoter Scores soar by 20 percentage points to record levels.
            </h2>
          </div>
          <div aria-hidden="true" className="background-media">
            <img
              src="/media/case-studies/case-studies-relx-title.jpg"
              data-object-fit="cover"
              data-autoscale-content-ratio="1.77777777778"
              alt=""
            />
          </div>
        </div>
        <div className="row section">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto marketing-main-column">
            <h3 className="intro">
              Here’s an internal communications challenge: How do you engage 33,000 diverse employees, working across four different
              verticals in over 40 countries around the world?
            </h3>
            <div className="inline-image featured">
              <img
                src="/media/case-studies/case-studies-relx.jpg"
                alt="RELX Environmental challenge, by RELX, renders responsively across all devices"
              />
            </div>
            <p>
              This was the challenge faced by Paul Abrahams, Head of Corporate Communications at RELX, when he came across a Shorthand
              story published by the BBC. “I just started reading it, and came to the end at no time at all. After finding the
              Shorthand website, I found some other stories and was blown away.”
            </p>
            <p>Paul and his team began to publish with Shorthand soon after, and immediately saw great results. </p>
            <p>
              “The first story we did was on The Lancet, which is one of our publications. After posting that story, we immediately saw
              a threefold increase in the number of reads and a 50% increase in dwell time.”
            </p>

            <h3 className="subheading">“Whoa, that's amazing”</h3>
            <p>
              While RELX’s initial stories were aimed at the wider public, the team soon began to publish stories for the group’s more
              than 33,000 staff around the world.
            </p>
            <p>As Paul explains, the initial results were astonishing.</p>
            <figure className="block quote-block offset left">
              <blockquote>
                <p>
                  “We saw a more than tenfold increase in the number of reads… Our reaction was, ‘Whoa, that's amazing. How do we do
                  that again?’”
                </p>
              </blockquote>
              <figcaption className="quote-cite">Paul Abrahams</figcaption>
            </figure>
            <p>
              “To help drive employee advocacy across RELX we decided to deploy Shorthand to tell human interest stories across our
              business. The first story we published was on flexible working at RELX. We saw a more than tenfold increase in the number
              of reads, as compared with previous internal comms stories. Our reaction was, ‘Whoa, that's amazing. How do we do that
              again?’ That was when we knew we had to double down and actually put together a systematic campaign.”
            </p>

            <p>
              Since then, the RELX team has radically increased the number of stories they publish with Shorthand. “We’re now
              publishing one per week. We’ve got plenty of great stories coming through. The internal stories — which we run so people
              can read them externally — have proven very successful.”
            </p>
            <p>
              According to Paul, the success of their Shorthand stories has inspired the team to produce more and more great content.
              “Previously on the website, it had been a bit of a chore. But now, everyone is coming up with great ideas and wanting to
              publish. The team gets really excited when the stories come out. It's become much, much easier to extract stories from
              the business than in the past.”
            </p>

            <div className="case-study-video-container">
              <video
                className="case-study-video-inline"
                muted
                loop
                playsInline
                autoPlay
                poster="/media/case-studies/case-studies-relx-poster.jpg"
              >
                <source src="/media/case-studies/case-studies-relx.mp4" type="video/mp4" />
              </video>
            </div>

            <h3 className="subheading">The heart of our media strategy</h3>

            <p>Seeing this early success, the team began to use social media campaigns to promote the stories more widely.</p>

            <p>
              “Although RELX is a FTSE 15 company, it’s a low profile business to business group. Prior to Shorthand, we were getting
              around 800 views per story,” Paul explains. “But using Shorthand and social media campaigns, we began to consistently get
              over 10,000 views per story.”
            </p>
            <p>
              Laure Lagrange, RELX’s Media Relations Manager, helps produce many of the Shorthand stories published by the team. “I’m
              on Shorthand daily. I find it inspiring to use — it just works for all sorts of different stories. We’re always trying to
              tell new stories, and every time we put out a new format, it seems to be even better than the previous one.”
            </p>
            <p>
              Most recently, the team experimented with a ‘listicle’ format — and it turned out to be the most successful story they
              had ever published.
            </p>
            <p>
              “I get really inspired by the possibilities and I think it's so scalable,” Laure says. “Shorthand is at the heart of our
              owned media strategy.”
            </p>

            <div className="photo-grid flex featured">
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-relx-sml-01.jpg"
                alt="Text Over Media section from RELX Environmental challenge, by RELX"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-relx-sml-02.jpg"
                alt="Gallery section from 9RELX Environmental challenge, by RELX"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-relx-sml-03.jpg"
                alt="Text Over Media section from Amazing people: amazing lives, by RELX"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-relx-sml-04.jpg"
                alt="Background Scrollmation section from Shaping a just future, by RELX"
              />
            </div>
            <div className="caption">A selection of sections from RELX's stories</div>

            <h3 className="subheading">Measuring success</h3>

            <p>To measure return on investment for using Shorthand, the team developed a novel metric: cost per read.</p>
            <figure className="block quote-block offset right">
              <blockquote>
                <p>“The target we had for this year was to go from 30,000 to 60,000 reads. We hit that in late April.”</p>
              </blockquote>
              <figcaption className="quote-cite">Paul Abrahams</figcaption>
            </figure>
            <p>
              “Cost per read is a great way for us to compare ROI across platforms,” Paul says. “So, for Shorthand, the cost-per-read
              might be slightly under a penny. Whereas for our video platform, it’s closer to a pound. We’re driving down that number
              all of the time.”
            </p>

            <p>
              Using Shorthand, the team has easily exceeded their annual KPIs. “The target we had for this year was to go from 30,000
              to 60,000 reads. We hit that in late April. And we are now comfortably on target to reach 200,000 reads for the year.
            </p>
            <p>“With Laure’s latest story, we hit 15,000 reads in about four days.”</p>

            <h3 className="subheading">“Those are record levels”</h3>

            <p>Every week, the RELX team sends out a single email to all 33,000 staff — dubbed ‘the Friday update.’</p>

            <p>
              “The lead story most weeks is a Shorthand story,” Paul says. “And the response has been really, really good. We’re
              getting people from around the company contacting us and responding to stories. The content published with Shorthand
              gives people more of a feeling of working for RELX itself, and not just one of our verticals.”
            </p>
            <p>
              During the COVID-19 crisis, the team has been publishing even more stories with Shorthand. “At one point, 97% of our
              33,000 employees were working from home, so we’ve been publishing a lot of stories around that theme, supporting and
              informing our employees.”
            </p>
            <p>
              As a result, Paul says, “Net Promoter Scores within RELX have soared. Across the businesses, we've had a 20 point
              increase in people saying they would recommend RELX as a place to work. Those are record levels.”
            </p>

            <h3 className="subheading">“Shorthand has been transformational”</h3>

            <p>Shorthand helped RELX bridge the gap between earned media and owned media.</p>
            <p>
              Two decades ago, successful companies like RELX would have no problem placing stories at major publications. But, as Paul
              explains, these days,“it’s very difficult for publications to do longform journalism, especially about businesses.”
            </p>

            <figure className="block quote-block offset left">
              <blockquote>
                <p>
                  "The stories we’re publishing now look simply beautiful. And now we're getting as many as 15 times the number of
                  reads that we used to get."
                </p>
              </blockquote>
              <figcaption className="quote-cite">Paul Abrahams</figcaption>
            </figure>

            <p>
              For RELX, this used to mean that many of their amazing stories — including their transformation from print to digital,
              and the fact that the company had outperformed the FTSE for nine consecutive years — weren’t reaching some of its core
              audiences.”
            </p>

            <p>
              The solution for Paul was to find a way to tell those stories for themselves. “Shorthand provided that platform for us.
              We’re now telling the sort of stories that journalists would have written 20 years ago, but they don't anymore.
            </p>
            <p>
              “But these stories are of huge interest to the various audiences that we're trying to reach. These audiences include
              investors, politicians and policy makers, customers, employees — and potential employees. Shorthand allows us to do that
              in a way that we just couldn't before.”
            </p>

            <p>
              As Paul explains, “The content on our old website was interesting — but it wasn’t inspiring. The stories were flat and
              didn’t have much imagery. It just wasn’t fit for purpose for our organisation. And it didn’t express the talent we had in
              our team.
            </p>
            <p>
              “Compare that with what we can do now with Shorthand. The stories we’re publishing now look simply beautiful. And now
              we're getting as many as 15 times the number of reads that we used to get.
            </p>
            <p>
              “There's a general genuine buzz in the team about what we’re publishing. There’s no way that was happening two and a half
              years ago on the old website. Shorthand has been transformational.”
            </p>

            <div className="related-stories">
              <h4>Read more from RELX:</h4>
              <ul>
                <li>
                  <a
                    href="https://stories.relx.com/amazing-people/index.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-link"
                  >
                    Amazing people: amazing lives
                  </a>
                </li>
                <li>
                  <a
                    href="https://stories.relx.com/relx-heroes/index.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-link"
                  >
                    RELX Heroes
                  </a>
                </li>
                <li>
                  <a
                    href="https://stories.relx.com/relx-environmental-challenge/index.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-link"
                  >
                    RELX Environmental challenge
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <RelatedCaseStudies studies={filterListByCurrentIndustryCode(data, "relx")} />
        <Footer />
      </main>
    </Layout>
  );
}

export const query = graphql`
  query RelxQuery {
    allCaseStudiesJson {
      edges {
        node {
          jsonId
          image
          industry
          industryCode
          organisation
          summary
        }
      }
    }
  }
`;
