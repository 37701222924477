import { trackEnquiry, trackEvent } from "../../services/Tracking";
import { getAuthCredentials, getAuthToken, getHostname } from "../../services/Utils";
import Select from "../Select";
import "./styles.scss";
import axios from "axios";
import cx from "classnames";
import * as objectPath from "object-path";
import * as React from "react";

const FocusTrap = require("focus-trap-react");

/*
 * From app/src/Services/Utils/index.ts
 */
const EMAIL_RE =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const isValidEmail = (email: string): boolean => EMAIL_RE.test(email);

const locations = [
  { label: "Americas", value: "americas" },
  { label: "Europe/Middle East/Africa", value: "emea" },
  { label: "Asia Pacific", value: "apac" },
  { label: "Other", value: "other" },
];

const interestedInOptions = [
  {
    label: "plans and pricing",
    value: "plans-and-pricing",
  },
  {
    label: "setting up a trial",
    value: "setting-up-a-trial",
  },
  {
    label: "a demo",
    value: "demo",
  },
  {
    label: "getting help with a technical or customer support issue",
    value: "support",
  },
  { label: "partnership opportunities", value: "partnership" },
  {
    label: "billing and invoicing help",
    value: "billing-and-invoicing",
  },
  {
    label: "other",
    value: "other",
  },
];

interface IProps {
  onClose: () => void;
  subject?: string;
  type?: string;
  prePopulatedFields?: any;
}

export function Enquiry(props: IProps): JSX.Element | null {
  const { onClose, subject, type, prePopulatedFields = {} } = props;
  const [form, setForm] = React.useState({
    organisationId: "",
    id: "new",
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    position: "",
    number: "",
    hearAbout: "",
    message: "",
    interestedIn: "",
    location: "",
    ...prePopulatedFields,
  });

  const [isProcessing, setIsProcessing] = React.useState(false);
  const [hasLoaded, setHasLoaded] = React.useState(false);
  const [hasSubmitted, setHasSubmitted] = React.useState(false);
  const [submitErr, setSubmitErr] = React.useState("");
  const [validEmail, setValidEmail] = React.useState(true);
  const modalEl = React.useRef(null);

  const { firstName, lastName, email, company, position, number, hearAbout, message, interestedIn, location } = form;

  function setSelectValue(name: string, value: string) {
    setForm({
      ...form,
      [name]: value,
    });
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    /*
     * Remove any errors from the email field while user is typing. This
     * matches the in-app enquiry form behaviour.
     */
    if (name === "email") {
      setValidEmail(true);
    }

    setForm({
      ...form,
      [name]: value,
    });
  }

  /*
   * Validate email when the email input loses focus. This matches the in-app
   * enquiry form behaviour.
   */
  function emailBlur(event: React.ChangeEvent<HTMLInputElement>): void {
    const value = event.target.value;
    setValidEmail(isValidEmail(value));
  }

  async function handleSubmit(e: React.FormEvent): Promise<void> {
    e.preventDefault();
    setIsProcessing(true);

    const fields = [
      "id",
      "firstName",
      "lastName",
      "email",
      "company",
      "number",
      "position",
      "hearAbout",
      "message",
      "interestedIn",
      "location",
    ];

    const enquiry = Object.assign({ subject, type }, ...fields.map(prop => ({ [prop]: form[prop] })));
    enquiry.utmData = JSON.parse(localStorage.getItem("sh_utm"));

    const uri = `${getHostname("api")}/enquiry/${enquiry.id}`;
    const windowGlobal = typeof window !== "undefined" && window;
    const token = windowGlobal && windowGlobal.localStorage.getItem("token");
    const options: any = {};
    if (token) {
      options.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    const location = locations.find(l => l.value === enquiry.location)?.label ?? "";

    try {
      const res = await axios.post(uri, { ...enquiry, location }, options);
      setHasSubmitted(true);
      setSubmitErr("");
      trackEnquiry({
        email: enquiry.email,
        hearAbout: enquiry.hearAbout,
        firstName: enquiry.firstName,
        lastName: enquiry.lastName,
        company: enquiry.company,
        contactPhone: enquiry.number,
        jobTitle: enquiry.position,
        enquiryType: enquiry.type,
        interestedIn: enquiry.interestedIn,
        text: enquiry.message,
        location,
      });
      trackEvent(enquiry.type, "User");
      return res.data;
    } catch (err) {
      if (err.response) {
        setSubmitErr(err.response.data?.message);
      } else {
        setSubmitErr("There was an error submitting the enquiry");
      }
    } finally {
      setIsProcessing(false);
    }
  }

  const isComplete = interestedIn && firstName && lastName && isValidEmail(email) && hearAbout && location;

  React.useEffect(() => {
    const credentials = getAuthCredentials();

    setHasSubmitted(false);
    setSubmitErr("");

    async function fetchCredentials(): Promise<void> {
      try {
        const res = await axios.get(`${getHostname("api")}/users/${credentials.id}`, {
          headers: {
            Authorization: `Bearer ${getAuthToken()}`,
          },
        });
        const user = objectPath.get(res, "data.data.users.0");
        if (user) {
          setForm({
            ...form,
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
          });
        }
      } catch (err) {
        console.log(err, "err");
      } finally {
        setHasLoaded(true);
      }
    }

    if (credentials) {
      fetchCredentials();
    } else {
      setHasLoaded(true);
    }
  }, []);

  React.useEffect(() => {
    function close(e: KeyboardEvent): void {
      if (e.key === "Escape") {
        onClose();
      }
    }

    window.addEventListener("keydown", close);
    document.body.style.overflow = "hidden";

    return () => {
      window.removeEventListener("keydown", close);
      document.body.style.overflow = "unset";
    };
  }, []);

  React.useEffect(() => {
    if (hasSubmitted) {
      modalEl.current.focus();
    }
  }, [hasSubmitted]);

  if (!hasLoaded) {
    return null; // need to load user first
  }

  /*
   * Show an error on the email input if:
   * - we submitted a valid email address, but were rejected by the backend
   *   (ie. after post to the API); or
   * - the value in the email field isn't a valid email address.
   */
  const isEmailErr = (typeof submitErr === "string" && submitErr.toLowerCase().includes("email")) || (email && !validEmail);

  return (
    <FocusTrap focusTrapOptions={{ allowOutsideClick: true }}>
      <div className="modal modal-white-bg  modal-overlay-transition tw-animate-fadeIn">
        <div className="modal-overlay" onClick={onClose} />
        <div className="modal-dialog modal-md">
          <div
            className="modal-content modal-enquiry-form v-centered-global"
            role="dialog"
            aria-label="Contact us"
            aria-modal={true}
            tabIndex={0}
            ref={modalEl}
          >
            <div className="modal-body">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-lg-4 enquiry-text-column general-enquiry">
                    <div className="container-fluid">
                      <button
                        onClick={onClose}
                        className="tw-relative tw-left-[50%] tw-top-[-25px] tw-ml-4 max-lg:tw-visible min-lg:!tw-invisible"
                      >
                        <i className="fa fa-close" />
                      </button>
                      <h3 className="enquiry-text-column-title">
                        <div className="shorthand-logo">
                          <img src="/media/logos/logo-black.svg" alt="" />
                        </div>
                        How can we help?
                      </h3>
                      <br />
                      <br />
                      <hr />
                      <br />
                      <p>
                        Contact us to learn more about creating stunning, immersive reading experiences in record time; to subscribe to
                        a Shorthand plan; or to discuss partnership opportunities.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-lg-8 enquiry-form-column">
                    <div className="container-fluid">
                      <button
                        onClick={onClose}
                        className="tw-relative   -tw-top-10 tw-left-[97%] tw-ml-4 tw-mr-10 max-lg:tw-invisible min-lg:!tw-visible"
                      >
                        <i className="fa fa-close" />
                      </button>
                      <div className="container-fluid enquiry-form-container">
                        {hasSubmitted ? (
                          <div className="alert alert-success alert-has-icon alert-lg text-center" role="alert">
                            <h3 className="alert-title">Thanks for your enquiry {firstName}, we'll be in touch shortly!</h3>
                          </div>
                        ) : (
                          <form className="form-full-width">
                            <div className="row">
                              <div className="col-12">
                                <div className="form-group">
                                  <label htmlFor="interestedIn" className="control-label">
                                    I'm interested in:
                                  </label>
                                  <Select
                                    className="Select--single"
                                    options={interestedInOptions}
                                    inputId="interestedIn"
                                    onChange={option => {
                                      if (!option) {
                                        return setSelectValue("interestedIn", "");
                                      }
                                      setSelectValue("interestedIn", option.value);
                                    }}
                                    isClearable
                                    value={interestedInOptions.find(option => option.value === interestedIn)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <div className="form-group is-empty">
                                  <label htmlFor="message" className="control-label">
                                    I'd like help with: <span>Optional</span>
                                  </label>
                                  <textarea
                                    id="message"
                                    name="message"
                                    className="form-control"
                                    required={false}
                                    value={message}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 col-md-6">
                                <div className="form-group">
                                  <label htmlFor="firstName" className="control-label">
                                    First Name
                                  </label>
                                  <input
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    className="form-control"
                                    value={firstName}
                                    onChange={handleChange}
                                    placeholder="Paige"
                                    required={true}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-6">
                                <div className="form-group">
                                  <label htmlFor="lastName" className="control-label">
                                    Last Name
                                  </label>
                                  <input
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    className="form-control"
                                    value={lastName}
                                    onChange={handleChange}
                                    placeholder="Turner"
                                    required={true}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 col-md-6">
                                <div className="form-group">
                                  <label htmlFor="email" className="control-label">
                                    Email
                                  </label>
                                  <input
                                    type="text"
                                    id="email"
                                    name="email"
                                    className={cx("form-control", {
                                      "has-error": isEmailErr,
                                    })}
                                    value={email}
                                    onChange={handleChange}
                                    onBlur={emailBlur}
                                    placeholder="name@company.com"
                                    required={true}
                                  />
                                  {isEmailErr && (
                                    <div className="help-block" role="alert">
                                      Please enter a valid email address
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-12 col-md-6">
                                <div className="form-group is-empty">
                                  <label htmlFor="number" className="control-label">
                                    Contact Number <span>Optional</span>
                                  </label>
                                  <input
                                    type="text"
                                    id="number"
                                    name="number"
                                    className="form-control"
                                    value={number}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-12 col-md-6">
                                <div className="form-group is-empty">
                                  <label htmlFor="company" className="control-label">
                                    Company Name <span>Optional</span>
                                  </label>
                                  <input
                                    type="text"
                                    id="company"
                                    name="company"
                                    className="form-control"
                                    value={company}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-6">
                                <div className="form-group is-empty">
                                  <label htmlFor="position" className="control-label">
                                    Job Title <span>Optional</span>
                                  </label>
                                  <input
                                    type="text"
                                    id="position"
                                    name="position"
                                    className="form-control"
                                    value={position}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <div className="form-group">
                                  <label htmlFor="location" className="control-label">
                                    Your location
                                  </label>
                                  <Select
                                    className="Select--single"
                                    inputId="location"
                                    options={locations}
                                    isClearable
                                    onChange={option => {
                                      if (!option) {
                                        return setSelectValue("location", "");
                                      }
                                      setSelectValue("location", option.value);
                                    }}
                                    value={locations.find(option => option.value === location)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <div className="form-group is-empty">
                                  <label htmlFor="hearAbout" className="control-label">
                                    How did you hear about us?
                                  </label>
                                  <textarea
                                    id="hearAbout"
                                    name="hearAbout"
                                    className="form-control"
                                    required={true}
                                    value={hearAbout}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-12 text-center">
                                <button id="cancel" type="button" className="btn btn-secondary" onClick={onClose}>
                                  Cancel
                                </button>
                                <button
                                  aria-label="Submit"
                                  id="send-enquiry"
                                  type="submit"
                                  onClick={handleSubmit}
                                  disabled={!isComplete || isProcessing}
                                  className={cx("btn btn-primary", {
                                    disabled: !isComplete || isProcessing,
                                  })}
                                >
                                  Get in touch
                                </button>
                              </div>
                            </div>
                          </form>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FocusTrap>
  );
}
