import { Footer } from "../components/Footer";
import { Layout } from "../components/Layout";
import { Link, graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default class Features extends React.Component<any, any> {
  render() {
    const { allBrandsJson } = this.props.data;

    const brands = allBrandsJson.edges;

    return (
      <Layout location={this.props.location}>
        <main id="main" className="marketing-content container-fluid">
          <Helmet
            bodyAttributes={{
              class: "body-shorthand body-marketing body-marketing-secondary body-marketing-features body-light hide-elevio",
            }}
          ></Helmet>
          <div className="row section-title title-light">
            <div className="col-11 col-sm-8  mx-auto section-inner">
              <h1>
                <span className="shorthand-logo dark" aria-hidden="true">
                  Shorthand
                </span>
                Product Features
              </h1>
              <h2>Build beautiful digital stories that get results, without web designers or developers.</h2>
            </div>
          </div>
          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>
                    <a href="./scrollytelling/" className="unstyled">
                      Scrollytelling
                    </a>
                  </h3>
                  <p>Build stories with audio and video, with animation effects triggered as the reader scrolls.</p>

                  <a className="btn btn-secondary" href="/features/scrollytelling/">
                    Learn more
                    <span className="hide-sm"> about Scrollytelling</span>
                  </a>
                </div>
              </div>
              <div className="section-strip-col">
                <video className="features-video-inline" muted loop playsInline autoPlay poster="/media/features/features-scrolly.jpg">
                  <source src="/media/features/features-scrolly.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
          </section>
          <section className="row section section-strip section-strip-right">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>
                    <a href="./collaboration/" className="unstyled">
                      Collaborate with your team
                    </a>
                  </h3>
                  <p>
                    With concurrent editing, clear access controls, and in-editing commenting, easily collaborate with the entire team
                    on your digital stories.
                  </p>

                  <a className="btn btn-secondary" href="/features/collaboration/">
                    Learn more
                    <span className="hide-sm"> about collaboration</span>
                  </a>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/features-team.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>
          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>
                    <a href="./branding/" className="unstyled">
                      Always on brand
                    </a>
                  </h3>
                  <p>
                    At your direction, Shorthand will build a bespoke custom branded theme with your fonts, colours, logos, and more.
                  </p>

                  <Link className="btn btn-secondary" to="/features/branding/">
                    Learn more
                    <span className="hide-sm"> about themes & branding</span>
                  </Link>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/features-brand.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>
          <section className="row section section-strip section-strip-right">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>
                    <a href="./security/" className="unstyled">
                      An enterprise solution
                    </a>
                  </h3>
                  <p>
                    Digital storytelling for every department in your enterprise, from brand marketing to internal comms deployed
                    behind your firewall. Custom plans available.
                  </p>

                  <Link className="btn btn-secondary" to="/features/security/">
                    Learn more<span className="hide-sm"> about security</span>
                  </Link>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/features-enterprise.png" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>
          <section className="row section section-strip section-strip-left">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>
                    <a href="./publishing-and-hosting/" className="unstyled">
                      Flexible publishing options
                    </a>
                  </h3>
                  <p>Export your stories to S3, embed in your CMS, export a ZIP, or publish directly to your Shorthand subdomain.</p>

                  <a className="btn btn-secondary" href="/features/publishing-and-hosting/">
                    Learn more
                    <span className="hide-sm"> about publishing options</span>
                  </a>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/features-publishing.jpg" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>

          <section className="row section section-strip section-strip-right">
            <div className="container container-sm">
              <div className="section-strip-col section-strip-col-text">
                <div className="section-strip-col-text-inner">
                  <h3>
                    <a href="./support/" className="unstyled">
                      White-glove support
                    </a>
                  </h3>
                  <p>
                    Our expert team is here to help you along your digital storytelling journey, from onboarding and training to custom
                    development and editorial assistance.
                  </p>

                  <Link className="btn btn-secondary" to="/features/support/">
                    Learn more<span className="hide-sm"> about support</span>
                  </Link>
                </div>
              </div>
              <div className="section-strip-col">
                <img src="/media/features/features-support.png" alt="" aria-hidden="true" />
              </div>
            </div>
          </section>
          <section className="row section section-dark section-half section-trial !tw-bg-[rgba(249,249,249,0.3)]">
            <div className="container section-inner text-center">
              <h2 className="!tw-text-black">
                Ready to try the digital storytelling platform used by Honda, University of Cambridge, and the BBC?
              </h2>

              <div className="cta-container">
                <a href="https://app.shorthand.com/signup" className="btn btn-lg !tw-bg-black hover:!tw-opacity-70">
                  Start creating
                </a>
              </div>
              <div className="brands">
                <div className="brands-container">
                  <h3 className="as-used">Join these iconic brands</h3>
                  <ul>
                    {brands.map((brand, index) => (
                      <li key={`brand-${brand.node.label}-${index}`} className={`brand`}>
                        <img src={brand.node.logo} alt={`${brand.node.label} logo`} />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div aria-hidden="true" className="background-media background-media-fixed">
              <img src="/media/home/stories.jpg" className="ObjectFit--cover" alt="" />
            </div>
          </section>
          <section className="row section section-gray-lighest section-all-features">
            <div className="container section-inner text-center">
              <h2>Browse all Shorthand features</h2>

              <div className="features features-cards">
                <ul>
                  <li>
                    <Link className="feature-card card-border card-shadow  unstyled" to="/features/scrollytelling/">
                      <div className="card-text">
                        <h3>Scrollytelling</h3>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link className="feature-card card-border card-shadow  unstyled" to="/features/visual-storytelling/">
                      <div className="card-text">
                        <h3>Visual Storytelling</h3>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link className="feature-card card-border card-shadow unstyled" to="/features/collaboration/">
                      <div className="card-text">
                        <h3>Collaboration</h3>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link className="feature-card card-border card-shadow  unstyled" to="/features/customisation/">
                      <div className="card-text">
                        <h3>Customisation & Integration</h3>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link className="feature-card card-border card-shadow  unstyled" to="/features/branding/">
                      <div className="card-text">
                        <h3>Themes & Branding</h3>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link className="feature-card card-border card-shadow  unstyled" to="/features/sharing-and-analytics/">
                      <div className="card-text">
                        <h3>Sharing & Analytics</h3>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link className="feature-card card-border card-shadow  unstyled" to="/features/publishing-and-hosting/">
                      <div className="card-text">
                        <h3>Publishing & Hosting</h3>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link className="feature-card card-border card-shadow  unstyled" to="/features/support/">
                      <div className="card-text">
                        <h3>Support</h3>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link className="feature-card card-border card-shadow  unstyled" to="/features/security/">
                      <div className="card-text">
                        <h3>Security</h3>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <Footer className="mt-0" />
        </main>
      </Layout>
    );
  }
}

export const query = graphql`
  query FeaturesQuery {
    site {
      siteMetadata {
        name
        title
        tagline
        description
      }
    }
    allBrandsJson {
      edges {
        node {
          logo
          name
          label
        }
      }
    }
  }
`;
