import { Heading } from "../Heading/Heading";
import * as styles from "./FAQ.module.scss";
import { pricingFAQ_SEO, templatesFAQ_SEO } from "./Questions";
import * as Accordion from "@radix-ui/react-accordion";
import cx from "classnames";
import * as React from "react";
import { ChevronDown } from "react-feather";
import { twMerge } from "tailwind-merge";

export type AccordionItem = {
  question: JSX.Element;
  answer: JSX.Element;
};

interface IFAQAccordionProps {
  questionGroup: "templates" | "pricing";
  heading: string;
  value?: string;
  theme: "dark-theme" | "light-theme" | "transparent-dark-theme";
  handleChange: (value: string) => void;
}

export const FAQAccordionControlled = ({
  questionGroup = "pricing",
  heading = "Frequently Asked Questions",
  value,
  handleChange,
  theme,
}: IFAQAccordionProps): JSX.Element => {
  const questions = questionGroup === "pricing" ? pricingFAQ_SEO : templatesFAQ_SEO;

  const textColor = theme === "dark-theme" ? "tw-text-white" : "tw-text-black";
  const itemStyles = theme === "dark-theme" ? styles.itemDark : styles.itemLight;
  const triggerStyles = theme === "dark-theme" ? styles.triggerDark : styles.triggerLight;
  const contentStyles = theme === "dark-theme" ? styles.contentDark : styles.contentLight;
  const chevronStyles = theme === "dark-theme" ? styles.chevronDark : styles.chevronLight;

  return (
    <div className="faq tw-flex tw-flex-col  tw-gap-10 tw-pt-[80px] tw-text-center">
      <Heading
        headerType={2}
        className={twMerge(
          "!tw-mb-0 tw-text-center !tw-text-5xl !tw-leading-[1.1] tw-text-white max-container:tw-w-full max-container:!tw-text-4xl max-sm:!tw-text-3xl",
          textColor
        )}
      >
        {heading}
      </Heading>
      <div className={styles.wrapper}>
        <Accordion.Root
          className={styles.AccordionRoot}
          type="single"
          value={value}
          onValueChange={value => handleChange(value)}
          collapsible
        >
          {questions.map((item, index) => (
            <Accordion.Item key={`item_${index}`} value={`item_${index}`} id={`item_${index}`} className={itemStyles}>
              <Accordion.Header>
                <Accordion.Trigger className={cx(triggerStyles, styles.AccordionTrigger)}>
                  <div
                    dangerouslySetInnerHTML={{ __html: item.question }}
                    className="tw-text-left tw-text-base tw-font-semibold"
                  ></div>
                  <ChevronDown size={15} className={cx(chevronStyles, styles.AccordionChevron)} aria-hidden />
                </Accordion.Trigger>
              </Accordion.Header>

              <Accordion.Content className={cx(contentStyles, styles.AccordionContent)}>
                <div
                  dangerouslySetInnerHTML={{ __html: item.answer }}
                  className="!tw-grey_800 !tw-mx-0 tw-flex !tw-w-full tw-flex-col tw-gap-4  tw-text-left !tw-font-CircularXXSub !tw-text-[20px] !tw-text-base !tw-leading-[1.3]  max-sm:!tw-text-sm"
                ></div>
                <br />
              </Accordion.Content>
            </Accordion.Item>
          ))}
        </Accordion.Root>
      </div>
    </div>
  );
};
