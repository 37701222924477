import { SVGLogoProps } from ".";
import * as React from "react";

export const Nikkei = (props: SVGLogoProps): JSX.Element => {
  return (
    <svg width="100%" className={props.className} viewBox="0 0 128 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.8699 0L21.1384 17.6534L15.3166 0H7.30004L0 27.2385H6.65922L11.7254 8.31937L17.9403 27.2385H25.2224L32.5225 0H25.8699ZM120.847 0L113.547 27.2385H120.2L127.5 0H120.847ZM36.5115 0L29.2075 27.2385H35.8594L43.1641 0H36.5115ZM110.984 16.0557L112.341 10.9902H101.007L102.468 5.53563H115.164L116.649 0H97.3004L90.1631 26.6289L84.913 12.6039H82.8464L95.3043 0H87.4517L75.824 11.5646L78.9238 0H72.2706L65.0934 26.7803L59.7882 12.6039H57.7216L70.1794 0H62.3269L50.6992 11.5646L53.799 0H47.1484L39.8438 27.2385H46.4983L50.4209 12.6039H52.7737L57.5623 27.2385H71.6231L75.5457 12.6039H77.8985L82.6871 27.2385H109.536L111.018 21.7109H98.1352L99.6505 16.0557H110.984Z" />
    </svg>
  );
};
