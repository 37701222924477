import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { LegalNav } from "../../components/LegalNav";
import { Link } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default class TermsView extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <main id="main" className="marketing-content container-fluid">
          <Helmet
            bodyAttributes={{
              class: "body-shorthand body-marketing body-marketing-secondary body-light body-marketing-legal hide-elevio",
            }}
          >
            <title>Acceptable Use Policy | Shorthand</title>
            <meta name="description" content="Shorthand terms of service." />
          </Helmet>
          <div className="row section-title title-light">
            <div className="col-12 col-sm-10 col-lg-8 col-xxl-6 mx-auto">
              <h1>
                <span className="shorthand-logo dark" aria-hidden="true">
                  Shorthand
                </span>{" "}
                Acceptable Use Policy
              </h1>
              <p>
                <small className="small-text text-muted">Effective: 26 March 2024</small>
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="d-none d-lg-block col-lg-2">
              <LegalNav />
            </div>
            <div className="col-12 col-sm-10 col-lg-8 col-xxl-6">
              <section>
                <p className="intro">
                  This Acceptable Use Policy <strong>(AUP)</strong> forms part of the licence granted by Shorthand Pty Ltd ABN 88 162
                  625 470 (<strong>we</strong>, <strong>us</strong> or <strong>our</strong>) to you for the use of the Shorthand
                  digital storytelling platform <strong>(Platform)</strong> and applies to all users of the Platform. This AUP may be
                  changed by us at our discretion from time to time. Any changed version of this AUP applies from the date that it is
                  posted to our Platform.
                </p>
              </section>
              <section>
                <h2>Purpose</h2>
                <p>
                  The primary purpose of this AUP is to ensure the intended, efficient, lawful and ethical use of the Platform and its
                  hosting infrastructure for the benefit of all of our users. It sets out the things that you can and cannot do with
                  the Platform and applies in addition to the terms and conditions contained in the{" "}
                  <Link className="btn-link" to="/legal/customer-terms">
                    Customer Terms
                  </Link>{" "}
                  and/or{" "}
                  <Link className="btn-link" to="/legal/user-terms">
                    User Terms
                  </Link>{" "}
                  that relates to your use of the Platform.
                </p>
              </section>

              <section>
                <h2>Your responsibilities</h2>

                <h3>You must:</h3>
                <ul>
                  <li>Only use the Platform in good faith and for its intended purpose.</li>
                  <li>Use the services in compliance with the laws of any country where services are being offered or received.</li>
                  <li>
                    Comply with any third party’s intellectual property rights, including copyright (including moral rights), trade
                    mark, design, patent, trade or other proprietary rights, or any rights of registration of such rights in the
                    countries in which you operate.
                  </li>
                  <li>Treat personal information of others in accordance with the provisions of the relevant privacy legislation.</li>
                  <li>
                    Use the Platform in a manner that does not interfere with or harm other users of the Platform, other users of the
                    internet or the hosting infrastructure for the Platform.
                  </li>
                  <li>
                    Use every effort to use commercially available anti-virus software on the device that connects to our Platform.
                  </li>
                  <li>
                    Comply with any instruction from us to delete or remove any material that, in our opinion:
                    <ul>
                      <li>contravenes this AUP, our privacy policy, or the applicable Customer Terms or User Terms;</li>
                      <li>
                        is the result of our lawful instruction to remove content, for example where a third party has issued a Take
                        Down Notice under the relevant copyright and trademark protection laws.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Comply with any request from us to assist in preventing any breach of this AUP, or in respect of any legal action
                    that is taken in respect of the Platform, our content or our website. We will reimburse you for any out-of-pocket
                    expenses where you provide such assistance, provided the cause of the breach or the initiator of the legal action
                    is not you, and we have agreed to reimburse those expenses in writing in advance.
                  </li>
                </ul>

                <h3>You must not:</h3>
                <ul>
                  <li>Use any automated scripting tools or software in connection with your use of the Platform.</li>
                  <li>
                    Frame or mirror the Platform or any content on any other service or wireless internet device unless specifically
                    authorised to do so.
                  </li>
                  <li>Circumvent storage space limits.</li>
                  <li>
                    Circumvent usage charges or limits, including the limits of our free plans, by creating multiple workspaces or by
                    any other means.
                  </li>
                  <li>Create workspaces on behalf of other parties or using credentials that are not your own.</li>
                  <li>
                    Perform a disproportionate number of actions, either manually or through automation, such as creating multiple
                    workspaces, uploading disproportionate amounts of media, or generating comment spam on stories or the Shorthand
                    user community.
                  </li>
                  <li>
                    Offer for sale, lease, rental or other arrangement, or invite offers for, goods or services that cannot lawfully be
                    acquired by persons in the countries in which you operate.
                  </li>
                  <li>
                    Load or publish any material that, in our opinion:
                    <ul>
                      <li>
                        substantially duplicates third party content either by scraping, reposting, aggregating, or any other means;
                      </li>
                      <li>is spam or content created specifically for web crawlers or other non-human audiences;</li>
                      <li>
                        contains any virus, trojan horse, worm or other programs that have an adverse effect on the Platform, the
                        hosting infrastructure or the internet;
                      </li>
                      <li>
                        threatens the integrity and security of the Platform, the hosting infrastructure, the internet, or other users
                        of the Platform and their systems;
                      </li>
                      <li>is threatening, abusive, offensive, endangers any person, unlawfully discriminatory or immoral;</li>
                      <li>
                        contains any information that breaches any code of conduct, whether voluntary or otherwise, including any
                        advertising code of conduct, or industry standard;
                      </li>
                      <li>is false, misleading or deceptive or is likely to mislead or deceive;</li>
                      <li>includes malicious data or links, such as phishing links;</li>
                      <li>
                        breaches any third party's intellectual property rights or discloses a third party’s confidential information
                        without their prior consent.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Engage in any act which we believe, in our opinion:
                    <ul>
                      <li>breaches the provisions of any legislation preventing spam or unsolicited emails;</li>
                      <li>
                        is an unsolicited broadcast of commercial emails that are sent to persons who you cannot demonstrate has
                        positively consented to the receipt of that type of email;
                      </li>
                      <li>
                        results in the collection or re-direction of responses from unsolicited emails from accounts of other service
                        providers;
                      </li>
                      <li>
                        provides misleading information as to the origin of a message, or alters headings, return email information
                        and/or internet protocol addresses in order to conceal the origin of a message;
                      </li>
                      <li>provides misleading information as to the identity or authority of the person making the posting;</li>
                      <li>
                        distributes any virus, trojan horse, worm or other programs that has an adverse effect on the Platform, the
                        hosting infrastructure or the internet;
                      </li>
                      <li>
                        is hacking or facilitates hacking (including the illegal or unauthorised access to any computer, including our
                        infrastructure, network or system through the internet);{" "}
                      </li>
                      <li>is phishing or facilitates phishing;</li>
                      <li>is unlawful in any country in which you operate;</li>
                      <li>contravenes this AUP.</li>
                    </ul>
                  </li>
                  <li>Disclose information that is confidential to another person without their consent.</li>
                  <li>Aid, abet, counsel or procure any of the above.</li>
                </ul>
              </section>

              <section>
                <h2>If you do not comply with this AUP</h2>
                <p>
                  We do not actively monitor the Platform or the content that is loaded into it, nor do we exercise active supervision
                  over the Platform or content that is in the hosted infrastructure or that is transmitted over the internet, nor do we
                  monitor our Platform to determine whether there has been any failure to comply with this AUP. If we become aware of a
                  breach, or threatened breach of this AUP, then we may take any action that we deem, in our discretion, necessary to
                  prevent or remedy that breach, including immediately and without notice:{" "}
                </p>
                <ul>
                  <li>preventing access to any user or group of users; and/or</li>
                  <li>intercepting, removing or altering any content stored in the Platform or in our infrastructure.</li>
                </ul>
                <p>
                  If you breach this AUP you will be in breach of our{" "}
                  <Link className="btn-link" to="/legal/customer-terms">
                    Customer Terms
                  </Link>{" "}
                  and/or{" "}
                  <Link className="btn-link" to="/legal/user-terms">
                    User Terms
                  </Link>{" "}
                  and we may, in addition, exercise any right or remedy set out in our{" "}
                  <Link className="btn-link" to="/legal/customer-terms">
                    Customer Terms
                  </Link>{" "}
                  and/or{" "}
                  <Link className="btn-link" to="/legal/user-terms">
                    User Terms
                  </Link>
                  .
                </p>
              </section>

              <section>
                <h2>Questions or comments</h2>
                <p>
                  If you have any questions on this AUP please contact us via the{" "}
                  <Link className="btn-link" to="/contact">
                    contact form
                  </Link>{" "}
                  on the Platform.
                </p>
              </section>
            </div>
          </div>
          <Footer />
        </main>
      </Layout>
    );
  }
}
