import { SVGLogoProps } from ".";
import * as React from "react";

export const Bbc = (props: SVGLogoProps): JSX.Element => {
  return (
    <svg width="100%" className={props.className} viewBox="0 0 84 26" fill="none">
      <path
        fillRule="evenodd"
        d="M83.739 25.406V.594H57.865v24.812h25.873ZM77.841 7.72c-1.992-1.146-3.986-1.74-5.853-1.74-1.993 0-3.647.637-4.92 1.91-1.314 1.23-1.992 2.756-1.992 4.665 0 1.823.678 3.392 1.95 4.666 1.357 1.186 2.97 1.78 4.92 1.78 2.079 0 4.114-.635 6.108-1.908v2.672c-1.867 1.018-3.945 1.527-6.235 1.527-1.951 0-3.563-.382-4.92-1.145a9.523 9.523 0 0 1-3.182-3.181c-.763-1.442-1.187-2.884-1.187-4.411 0-2.545.89-4.623 2.714-6.32 1.782-1.697 3.988-2.587 6.575-2.587 1.866 0 3.86.466 6.022 1.442v2.63ZM54.983.594H29.11v24.812h25.873V.594Zm-17.941 3.69h4.496c1.442 0 2.545.17 3.35.551.806.425 1.4.933 1.782 1.57.423.679.636 1.442.636 2.332 0 1.697-.849 2.884-2.63 3.606 1.272.212 2.29.72 3.012 1.569.72.763 1.06 1.74 1.102 2.842-.042.848-.254 1.653-.678 2.333-.467.678-1.103 1.23-1.994 1.696-.89.425-1.951.636-3.266.636h-5.81V4.284Z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M41.11 11.622c1.315 0 2.248-.255 2.8-.721.551-.552.848-1.188.848-1.994 0-1.611-1.06-2.46-3.139-2.46H39.5v5.175h1.612Zm.68 2.205H39.5v5.386h2.46c1.144 0 2.078-.084 2.67-.254.552-.297.934-.636 1.188-1.018.255-.424.425-.89.425-1.4-.043-.508-.17-.976-.467-1.399-.255-.467-.678-.806-1.273-1.018-.55-.212-1.484-.34-2.714-.297ZM26.137.594H.266v24.812h25.872V.594ZM8.155 21.42V4.284h4.496c1.442 0 2.502.17 3.308.551.806.382 1.442.933 1.824 1.612.424.636.636 1.4.679 2.29-.043 1.696-.89 2.884-2.672 3.647 1.315.213 2.29.721 3.011 1.527.721.764 1.103 1.74 1.103 2.842 0 .89-.212 1.696-.678 2.333-.467.72-1.103 1.272-1.951 1.696-.891.425-1.994.637-3.309.637h-5.81Z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12.738 6.447h-2.12v5.175h1.653c1.273 0 2.205-.254 2.757-.721a2.6 2.6 0 0 0 .848-1.951c0-1.654-1.06-2.503-3.138-2.503Zm.17 7.38h-2.29v5.386h2.416c1.273 0 2.206-.084 2.757-.254a2.724 2.724 0 0 0 1.145-.933c.255-.467.382-.934.424-1.484-.042-.595-.212-1.06-.466-1.4a2.266 2.266 0 0 0-1.273-.976c-.593-.254-1.526-.339-2.714-.339Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
