import { filterListByCurrentIndustryCode } from "../../Utils";
import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { RelatedCaseStudies } from "../../components/RelatedCaseStudies";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default function CaseStudy({ data, location }) {
  return (
    <Layout location={location}>
      <main id="main" className="marketing-content container-fluid">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-secondary body-marketing-case-studies hide-elevio",
          }}
        >
          <title>Imperial College London | Visual storytelling</title>
          <meta
            name="description"
            content="Imperial College London uses Shorthand to publish immersive feature stories from across the university, including their alumni magazine"
          />
        </Helmet>
        <div className="row section-title">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto  section-inner">
            <h1>
              <span className="shorthand-logo">Shorthand</span>
              <span className="subsection-label">Case Study:</span>
              Imperial College London
            </h1>
            <h2>
              Imperial College London uses Shorthand to publish immersive feature stories from across the university, including their
              alumni magazine.
            </h2>
          </div>
          <div aria-hidden="true" className="background-media">
            <img
              src="/media/case-studies/case-studies-imperial-title.jpg"
              data-object-fit="cover"
              data-autoscale-content-ratio="1.77777777778"
              alt=""
            />
          </div>
        </div>
        <div className="row section">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto marketing-main-column">
            <h3 className="intro">
              After nine months with Shorthand, Imperial College London feature stories have seen 142% higher average unique pageviews
              and 50% higher average time on page.
            </h3>
            <div className="inline-image featured">
              <img
                src="/media/case-studies/case-studies-imperial.jpg"
                alt="Mission to the Sun, by Imperial College London, renders responsively across all devices"
              />
            </div>
            <p>
              Andrew Youngson is the News and Campaigns Content Manager at Imperial College London, and led the editorial aspects of
              bringing Shorthand to the university.
            </p>
            <p>
              Before using Shorthand, Imperial published their feature stories on a bespoke CMS used by the university’s news site.
            </p>
            <p>As Andrew explains, the CMS was great for news, but wasn’t necessarily suitable for longer feature stories.</p>
            <p>
              “The stories inevitably looked the same as our shorter stories, and didn’t provide that immersive reading experience. By
              immersive, I mean not interrupted by all the skyscrapers, headers and banners that you would have on any of our other
              platforms. For our feature stories, we were looking for an uninterrupted experience.”
            </p>

            <h3 className="subheading">A proven solution</h3>
            <p>
              After trialling a few options, Andrew and the team landed on Shorthand, a company he’d been personally following for
              years. Shorthand were well known for customers like the BBC and Sky News, and Andrew was interested to see how the
              platform could work for content produced by the university.
            </p>
            <p>
              “We ended up choosing Shorthand for a few reasons. I subscribe to the Shorthand newsletter and knew that the platform
              could be used to make some amazing immersive stories.”
            </p>
            <figure className="block quote-block offset left">
              <blockquote>
                <p>
                  "The support that the Shorthand team gave us through the procurement process was incredibly important to getting the
                  project over the line."
                </p>
              </blockquote>
              <figcaption className="quote-cite">Andrew Youngson</figcaption>
            </figure>
            <p>
              “Then there was the platform’s ease of use. This was critical for us, given how many different people produce stories
              across multiple teams.
            </p>

            <p>
              “Our IT team was also pleased that we could easily host and publish stories from our own servers. It’s also important for
              us to work with a company with longevity, and Shorthand just seems to be on a real upward trajectory.
            </p>
            <p>
              “Finally, there was the support that the Shorthand team gave us through the procurement process. This was incredibly
              important to getting the project over the line, and gave us confidence in the platform.”
            </p>
            <p>
              After managing a complex internal process, Andrew was delighted to get the project up and running. “It was absolutely
              brilliant to be involved in something from the ground up, and that we were determined to do right.”
            </p>

            <div className="case-study-video-container">
              <video className="case-study-video-inline" muted loop playsInline autoPlay>
                <source src="/media/case-studies/case-studies-imperial.mp4" type="video/mp4" />
              </video>
            </div>

            <h3 className="subheading">Absolute gold dust</h3>

            <p>
              After Imperial College London joined Shorthand, the team were given ‘white glove’ onboarding to help them get up and
              running immediately. For Andrew, this made a potentially complex process “really, really easy. We had a lot of specific
              requirements, but the team at Shorthand bent over backwards for us so many times.”
            </p>
            <figure className="block quote-block offset right">
              <blockquote>
                <p>
                  "The fact that Shorthand offers regular training sessions is absolute gold dust to somebody in my position. The
                  training is great. Nobody's ever come to me confused."
                </p>
              </blockquote>
              <figcaption className="quote-cite">Andrew Youngson</figcaption>
            </figure>
            <p>
              Andrew notes that there were many stakeholders involved in the procurement process at Imperial. “The Shorthand team knew
              exactly how to keep the process moving forward. It absolutely made the difference for us. We were appreciative of the
              flexibility and also the structure that they offered in the process of onboarding.”
            </p>

            <p>
              Andrew’s a big fan of the training provided by Shorthand’s team, and cites it as a key reason for the success of the
              university’s Shorthand stories to date.
            </p>
            <p>
              “The fact that Shorthand offers regular training sessions is absolute gold dust to somebody in my position. The training
              is great. Nobody's ever come to me confused.
            </p>
            <p>
              “People can immediately start learning about the platform, without having to wait for us to offer our own internal
              training. It means people can maintain that enthusiasm they have for telling great stories.”
            </p>

            <div className="photo-grid flex featured">
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-imperial-sml-01.jpg"
                alt="Reveal section from Mission to the Sun, by Imperial College London"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-imperial-sml-02.jpg"
                alt="Text Over Media section from 9 things you can do about climate change, by Imperial College London"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-imperial-sml-03.jpg"
                alt="Background Scrollmation section from Frontline insights, by Imperial College London"
              />
              <img
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-imperial-sml-04.jpg"
                alt="Custom HTML section from Pandemics and privacy, by Imperial College London"
              />
            </div>
            <div className="caption">A selection of sections from Imperial College London's stories</div>

            <h3 className="subheading">A better digital magazine</h3>

            <p>
              While Andrew was interested in a better reading experience for his feature stories, the design team were looking for a
              better way to publish their biannual magazine.
            </p>
            <p>
              “The magazine goes out to a huge distribution list of our alumni,” Andrew explains. “It’s a major piece of work for the
              College, with a huge investment in resources. The work we publish in that magazine is top-level storytelling with bespoke
              photography and illustrations.”
            </p>
            <figure className="block quote-block offset left">
              <blockquote>
                <p>
                  "We knew we needed to find a better way to digitise this content and make an enjoyable, engaging experience.
                  Shorthand was the perfect tool."
                </p>
              </blockquote>
              <figcaption className="quote-cite">Andrew Youngson</figcaption>
            </figure>
            <p>
              However, the team producing the magazine had one major problem. “It was almost entirely in print. While our audience
              loves the print experience, there were no great ways to replicate this experience on our existing channels.”
            </p>
            <p>
              As Andrew explains, the content was too visually rich to simply publish on Imperial’s news site or main website. And
              publishing the content with a PDF magazine reader “wasn’t the same at all. It wasn’t even a scratch on the proper reading
              experience.
            </p>
            <p>
              “We knew we needed to find a better way to digitise this content and make an enjoyable, engaging experience. Shorthand
              was the perfect tool.”
            </p>

            <h3 className="subheading">A flexible digital storytelling platform</h3>

            <p>
              A key benefit of Shorthand, Andrew says, is that it allows anyone in the team to make great content, without being a
              designer or developer. “There's a really strong structure in terms of the content blocks that you can choose from. These
              really strong, tried-and-tested building blocks allow anybody to come in from scratch and tell great stories. The real
              creativity with our digital storytelling comes out of that foundation.”
            </p>

            <p>
              Andrew uses the example of Shorthand’s Reveal section. “It’s a really simple and easy to use feature, but it has so many
              different applications. Our team is really able to bring their creativity to Shorthand and flourish.”
            </p>
            <p>
              This has made it easy for Andrew to onboard new people to Shorthand. “There's a confidence there in the platform that
              even brand new people immediately have. And that makes my job so much easier.”
            </p>

            <h3 className="subheading">The results are in</h3>

            <p>
              How do Imperial’s Shorthand stories perform? When benchmarked against stories published on Imperial’s news site, Andrew
              says that the team has seen “a significant bump on the average time on page. Since our October launch to June 2020, the
              average read time on Shorthand stories is 3:41, as compared to 2:38 for stories on our news site. It's over a minute
              longer that we're getting on average for time-on-page. So that's fantastic.
            </p>

            <figure className="block quote-block offset right">
              <blockquote>
                <p>
                  "Our Shorthand stories are performing on every metric that we've been measuring ourselves on. The targets and stretch
                  targets have been met on all our content."
                </p>
              </blockquote>
              <figcaption className="quote-cite">Andrew Youngson</figcaption>
            </figure>

            <p>
              “We're seeing really good engagement across the board and especially good engagement on our community content,” Andrew
              says. “This is also true with our popular science content, such as our pieces on gravity or solar system exploration.
              We're achieving really great engagement with that content because it's published in an engaging, immersive way. People
              seem to be really enjoying that.
            </p>

            <p>
              “Our Shorthand stories are performing on every metric that we've been measuring ourselves on, such as scroll depth,
              clicks on page, and click-throughs on social media. The targets and stretch targets have been met on all our content.”
            </p>
            <p>
              Andrew recommends other organisations pay attention to their analytics, as it is immensely helpful in reporting to budget
              owners and other decision makers. “It’s important to be armed with the facts to justify the investment we’re making in
              this content.”
            </p>

            <h3 className="subheading">More stories to tell</h3>

            <p>
              Andrew says one of the secrets to the success of Imperial College London’s Shorthand stories is the great process the
              team has in place. “We developed a content strategy, a mission statement, and a process for how stories are developed.
              And that continues right through from publishing to analytics and reporting.
            </p>
            <p>
              “Shorthand is an easy platform to work with — and even quickly produced stories look great. But that doesn't mean you
              should take shortcuts. The best stories on Shorthand are always the ones you've spent time loving and crafting.”
            </p>
            <p>
              Andrew still feels like he has a lot more to achieve. “We’ve done some great work, but there’s still so much great work
              to do with Shorthand. There are new features coming out that we’re excited to use, and the entire team is learning all
              the time. That’s the great thing about digital storytelling — there’s always more exciting ideas to explore.”
            </p>

            <div className="related-stories">
              <h4>Read more from Imperial College London:</h4>
              <ul>
                <li>
                  <a
                    href="https://www.imperial.ac.uk/stories/mission-to-the-sun/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-link"
                  >
                    Mission to the Sun
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.imperial.ac.uk/stories/climate-action/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-link"
                  >
                    9 things you can do about climate change
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.imperial.ac.uk/stories/frontline-insights/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-link"
                  >
                    Frontline insights
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <RelatedCaseStudies studies={filterListByCurrentIndustryCode(data, "imperial-college-london")} />
        <Footer />
      </main>
    </Layout>
  );
}

export const query = graphql`
  query ImperialCollegeLondonQuery {
    allCaseStudiesJson {
      edges {
        node {
          jsonId
          image
          industry
          industryCode
          organisation
          summary
        }
      }
    }
  }
`;
