import { filterListByCurrentIndustryCode } from "../../Utils";
import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { RelatedCaseStudies } from "../../components/RelatedCaseStudies";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default function CaseStudy({ data, location }) {
  return (
    <Layout location={location}>
      <div id="main" className="marketing-content container-fluid">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-secondary body-marketing-case-studies hide-elevio",
          }}
        />
        <div className="row section-title">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6 mx-auto section-inner">
            <h1>
              <span className="shorthand-logo">Shorthand</span>
              <span className="subsection-label">Case Study:</span>
              mothers2mothers
            </h1>
            <h2>How mothers2mothers uses Shorthand</h2>
          </div>
          <div aria-hidden="true" className="background-media">
            <img
              src="/media/case-studies/case-studies-m2m-title.jpg"
              data-object-fit="cover"
              data-autoscale-content-ratio="1.77777777778"
              alt=""
            />
          </div>
        </div>
        <div className="row section">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto marketing-main-column">
            <h2 className="intro">
              mothers2mothers uses Shorthand to create impactful, engaging annual reports for their stakeholders.
            </h2>
            <div className="inline-image featured">
              <img alt="" src="/media/case-studies/case-studies-m2m.jpg" />
            </div>
            <p>
              mothers2mothers (m2m) is an African non-profit organisation that unlocks the power of women to eliminate paediatric AIDS
              and create healthy families. Since 2001, m2m has employed over 11,000 women living with HIV as frontline health workers
              in sub-Saharan Africa. Together, these women have reached 13.5 million individuals with life-changing health services and
              education.
            </p>
            <p>
              We talked to Andrea Hahn, Graphic Designer at m2m, about how the m2m team has used Shorthand to produce their immersive
              digital annual reports, alongside a growing number of other marketing materials.
            </p>
            <h3 className="subheading">A better way</h3>
            <figure className="block quote-block offset left">
              <blockquote>
                <p>“We receive so much great feedback on our Shorthand stories, which is exciting.”</p>
              </blockquote>
              <figcaption className="quote-cite">Andrea Hahn</figcaption>
            </figure>
            <p>
              m2m’s first use case for Shorthand was to create a digital version of their annual report. Previously, m2m had worked
              almost exclusively in print, and their annual report had been a document that was physically mailed — or even handed — to
              donors.
            </p>
            <p>
              As Andrea explains, “We wanted to see if there was a digital way to showcase our work, so we could reach more people. In
              the past, we'd print all of these annual reports, and then we'd distribute them when we could.
            </p>
            <p>
              “But that always presented challenges since they were printed in bulk in Cape Town, and we had to figure out creative
              ways to get them to our offices in the U.K., U.S., and across Africa—which usually entailed m2m staff hand delivering
              them when they travelled. We just knew there had to be a better way.”
            </p>
            <p>
              When deciding on a digital format to pursue, the m2m team considered using PDFs — a common format for annual reports, but{" "}
              <a
                href="https://shorthand.com/the-craft/the-pdf-is-in-terminal-decline/index.html"
                target="_blank"
                className="btn-link"
                rel="noopener noreferrer"
              >
                one with major limitations
              </a>
              . In the end, they found Shorthand to be a much more flexible, functional, accessible, and responsive option.
            </p>
            <p>
              And their results for mothers2mothers have been stunning. Their{" "}
              <a href="https://annualreport.m2m.org/2020AnnualReport/" target="_blank" className="btn-link" rel="noopener noreferrer">
                2020 Annual Report
              </a>
              , for example, used photography and illustrations to produce a beautiful and engaging digital experience.
            </p>
            <p>
              As Andrea says, “I am really proud of all of the stories we produce with Shorthand. I loved last year's annual report,
              but I also love this year's annual report, and I like the first one we built with Shorthand, too. Every time we have the
              initial conversation about the annual report, I'm always like, ‘How the heck am I going to top what I did last year?’"
            </p>
            <p>
              Feedback from m2m’s supporters has also been impressive. “Whenever our annual reports come out, people say, ‘This is
              amazing. It's the best thing we've ever seen.’ We receive so much great feedback on them, which is exciting.”
            </p>
            <h3 className="subheading">‘We’re using Shorthand instead.’</h3>
            <p>
              Following the success of their digital annual reports, the m2m team has branched out to produce a range of additional
              digital campaigns.
            </p>
            <p>
              One highlight is{" "}
              <a href="https://m2m.org/20YearsOfImpact/" target="_blank" className="btn-link" rel="noopener noreferrer">
                20 years of impact
              </a>
              , a gorgeous and informative story celebrating m2m’s twentieth anniversary. Another is{" "}
              <a href="https://m2m.org/cycle2zero-/fundraisingpack/" target="_blank" className="btn-link" rel="noopener noreferrer">
                Cycle2Zero
              </a>
              , an immersive fundraising pack for the m2m community to help with their fundraising efforts.
            </p>
            <p>Andrea is increasingly promoting Shorthand for projects that would otherwise be produced as PDFs.</p>
            <p>
              “Now, as much as possible, we're saying, ‘Why do you need this? A one-pager PDF is so boring. Can we not do that?’ And
              so, we’re using Shorthand instead. This has helped the wider team see how great Shorthand can be and how great we can
              make their campaigns look.”
            </p>
            <div className="photo-grid flex featured">
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/case-studies-m2m-sml-01.jpg" />
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/case-studies-m2m-sml-02.jpg" />
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/case-studies-m2m-sml-04.jpg" />
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/case-studies-m2m-sml-03.jpg" />
            </div>
            <div className="caption">A selection of sections from mothers2mothers' stories</div>
            <h3 className="subheading">No barrier to entry</h3>

            <p>
              When deciding which platform to use, Andrea and the m2m team had two main requirements. First, their annual reports had
              to look beautiful. “We wanted to keep it as beautiful and as engaging as our printed versions were, which is what we’ve
              managed with Shorthand,” Andrea says.
            </p>
            <p>
              Second, their chosen content platform had to be easy to use. “We chose Shorthand because it seemed like it was easy to
              learn. Many of the other tools were much more complicated, or required more bespoke design and building.
            </p>
            <p>
              “And since then, we’ve learned a lot more about all the capabilities of Shorthand — but it’s all been easy to learn. We
              check out Shorthand stories built by others, which gives us inspiration about how we can create our own engaging
              stories.”
            </p>
            <h3 className="subheading">Let’s get it done</h3>
            <figure className="block quote-block offset right">
              <blockquote>
                <p>“The Shorthand team always figures out a way to get the result we need.”</p>
              </blockquote>
              <figcaption className="quote-cite">Andrea Hahn</figcaption>
            </figure>
            <p>Andrea notes that the quality of support has been one of the most important features of Shorthand.</p>
            <p>
              “Shorthand’s support is always really helpful. Basically, the Shorthand team always figures out a way to get the result
              we need.”
            </p>
            <p>
              This has enabled Andrea and the team to use Shorthand for more types of content, and has given her the confidence to
              continue to suggest Shorthand stories instead of PDFs.
            </p>
            <p>
              “That’s a big reason why we’re so happy to keep working with Shorthand. There's always been help. There’s always been an
              attitude from Shorthand of, ‘let's get it done, let's find the best way to do it, we'll make a plan.’”
            </p>
            <p>
              Andrea says she would recommend that all new Shorthand users get in touch with the support team if they run into
              problems. “I know that if I'm struggling with something, I can email, and the Shorthand team will get back to me with a
              really helpful solution.”
            </p>
            <h3 className="subheading">Engaging, fun, exciting, different</h3>

            <figure className="block quote-block offset left">
              <blockquote>
                <p>“We're always proud of the stuff that we create on Shorthand, which is amazing.”</p>
              </blockquote>
              <figcaption className="quote-cite">Andrea Hahn</figcaption>
            </figure>
            <p>
              Ultimately, the m2m team is delighted with the work they build with Shorthand, and they’re proud to show it off to their
              community. As Andrea explains, communicating such vital work in a manner that captivates audiences, especially donors, is
              crucial to the m2m’s mission.
            </p>
            <p>
              “I think Shorthand has been such a creative space for us. We're always proud of the stuff that we create on Shorthand,
              which is amazing,” Andrea says.
            </p>
            <p>
              “If I had to show off something I had created at m2m, it would be one of my Shorthand stories. because I think that
              they're cool, and I think that they're engaging, they're fun, they're exciting and they're different.”
            </p>
          </div>
        </div>
        <RelatedCaseStudies studies={filterListByCurrentIndustryCode(data, "mothers2mothers")} />
        <Footer />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query Mothers2MothersQuery {
    allCaseStudiesJson {
      edges {
        node {
          jsonId
          image
          industry
          industryCode
          organisation
          summary
        }
      }
    }
  }
`;
