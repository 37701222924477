import { filterListByCurrentIndustryCode } from "../../Utils";
import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { RelatedCaseStudies } from "../../components/RelatedCaseStudies";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default function CaseStudy({ data, location }) {
  return (
    <Layout location={location}>
      <div id="main" className="marketing-content container-fluid">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-secondary body-marketing-case-studies hide-elevio",
          }}
        />
        <div className="row section-title">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6 mx-auto section-inner">
            <h1>
              <span className="shorthand-logo">Shorthand</span>
              <span className="subsection-label">Case Study:</span>
              Remedy Health Media
            </h1>
            <h2>How Remedy Health Media uses Shorthand</h2>
          </div>
          <div aria-hidden="true" className="background-media">
            <img
              src="/media/case-studies/case-studies-remedy-title.jpg"
              data-object-fit="cover"
              data-autoscale-content-ratio="1.77777777778"
              alt=""
            />
          </div>
        </div>
        <div className="row section">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto marketing-main-column">
            <h2 className="intro">
              Remedy Health Media is a leading healthcare publisher, with titles including HealthCentral, Patient Power, and
              EndocrineWeb.
            </h2>
            <div className="inline-image featured">
              <img alt="" src="/media/case-studies/case-studies-remedy.jpg" />
            </div>
            <p>Their mission is to empower patients and caregivers to navigate the healthcare landscape.</p>
            <p>
              They are also the publisher of visually stunning, award-winning stories, such as{" "}
              <a
                href="https://www.healthcentral.com/experience/diabetes-race/"
                target="_blank"
                className="btn-link"
                rel="noopener noreferrer"
              >
                Deeply Rooted
              </a>
              , as well as the groundbreaking storytelling campaign, Live Bold, Live Now.
            </p>
            <p>
              We chatted with Becky Allen, previously Senior Product Manager at Remedy Health Media, and Jens Bishop, VP of Multimedia,
              to talk about why they invested in immersive digital storytelling for their web publications.
            </p>
            <h3 className="subheading">More modern, more flexible</h3>
            <figure className="block quote-block offset left">
              <blockquote>
                <p>“Shorthand provided a lot of the multimedia features we needed in a more modern way, with more flexibility.”</p>
              </blockquote>
              <figcaption className="quote-cite">Becky Allen</figcaption>
            </figure>
            <p>
              Before using Shorthand, the team at Remedy Health Media used a tool that had been developed in-house. But as the team's
              storytelling grew, it was no longer fit for purpose.
            </p>
            <p>
              The tool was unable to provide mobile templates, and the templates that it could provide were too rigid for the ambitions
              of the Remedy Health Media team.
            </p>
            <p>
              As Jens explains, “We had started to create more comprehensive multimedia packages initially developed through a tool we
              built in-house. But as the tool no longer supported the continued growth and expansion of our storytelling programme, we
              wanted to identify a solution like Shorthand — instead of allocating internal development resources to updating the
              existing tool or building a new one.”
            </p>
            <p>
              The team had two options: either completely rebuild their internal tool — and potentially face the same problems with
              maintenance in years ahead — or look for an alternative.
            </p>
            <p>
              Jens notes that in 2019, the Remedy Health Media team “identified Shorthand as a solution to scale our patient
              storytelling content and elevate the UX and design around the high-production value multimedia content we create to
              engage our audiences.”
            </p>
            <p>
              They were able to create over 300 stories for the Live Bold, Live Now franchise, as well as identify “other uses for
              Shorthand as we developed new content types including self-care guides and special reports.”
            </p>
            <p>
              As Becky explains, “the platform provided a lot of the multimedia features we needed in a more modern way, with more
              flexibility. It also removed the tool maintenance from our team, so we have been able to continue to focus on our core
              content and utilise Shorthand for special projects that can incite more impact.”
            </p>
            <h3 className="subheading">Building multimedia experiences</h3>
            <p>The main Shorthand use case for Remedy Health Media is to create multimedia experiences.</p>
            <p>
              As Becky explains, “These experiences feature a person living with a specific condition, or a person and their doctor
              talking together. The stories stitch together prose, video, images, and audio to tell the story of what it’s like to live
              with a given health condition.”
            </p>
            <p>
              Jens shares more on the Live Bold, Live Now series, saying it “utilised immersive multimedia to elevate the voices of
              people living exciting lives while navigating chronic health conditions like Crohn’s disease, psoriasis, and rheumatoid
              arthritis.”
            </p>
            <p>
              Some examples include Hillel Katzeff on how{" "}
              <a
                href="https://www.healthcentral.com/experience/psoriasis-game-changer-hillel-katzeff"
                target="_blank"
                className="btn-link"
                rel="noopener noreferrer"
              >
                swimming eased his psoriasis
              </a>
              , Dionna Koval on{" "}
              <a
                href="https://www.healthcentral.com/experience/mbc-bridging-the-gap-dionna-koval"
                target="_blank"
                className="btn-link"
                rel="noopener noreferrer"
              >
                facing her diagnosis
              </a>{" "}
              of metastatic breast cancer, and Alisha Bridges on{" "}
              <a
                href="https://www.healthcentral.com/experience/psoriasis-bridging-the-gap-alisha-bridges"
                target="_blank"
                className="btn-link"
                rel="noopener noreferrer"
              >
                finding a dermatologist who respected who she was as a patient
              </a>
              .
            </p>
            <p>
              Additionally, the Remedy Health Media team have built a wide range of other impressive stories, including the
              award-winning{" "}
              <a
                href="https://www.healthcentral.com/experience/diabetes-race/"
                target="_blank"
                className="btn-link"
                rel="noopener noreferrer"
              >
                Deeply Rooted
              </a>{" "}
              project on EndocrineWeb, a recent{" "}
              <a
                href="https://www.healthcentral.com/experience/obesity-chronic-connection-special-report"
                target="_blank"
                className="btn-link"
                rel="noopener noreferrer"
              >
                report on obesity
              </a>{" "}
              on their flagship site, HealthCentral, and a{" "}
              <a
                href="https://www.thebody.com/experience/hiv-40th-anniversary-doula-poetry-party"
                target="_blank"
                className="btn-link"
                rel="noopener noreferrer"
              >
                retrospective on the AIDS pandemic
              </a>{" "}
              on TheBody.com.
            </p>
            <p>
              For “Deeply Rooted,” the team commissioned illustrations and used scrollmation effects to capture and keep the attention
              of the reader.
            </p>
            <p>
              “We wanted to make sure users would be engaged and stay for such a read. The site where this special project currently
              lives is in need of a redesign, and we wanted this piece to not only have a modern look but a better UX than other pieces
              of content on the site.”
            </p>
            <p>After winning Gold at the 2021 Digital Health Awards, the team certainly achieved their goal.</p>
            <div className="photo-grid flex featured">
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/case-studies-remedy-sml-01.jpg" />
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/case-studies-remedy-sml-02.jpg" />
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/case-studies-remedy-sml-03.jpg" />
              <img alt="" className="col-12 col-sm-6" src="/media/case-studies/case-studies-remedy-sml-04.jpg" />
            </div>
            <div className="caption">A selection of sections from the Remedy Health Media's stories</div>

            <h3 className="subheading">Build multimedia content at scale</h3>
            <figure className="block quote-block offset right">
              <blockquote>
                <p>
                  “Shorthand lets our editors get creative with graphic treatments and imagery. They can make every feature feel
                  bespoke.”
                </p>
              </blockquote>
              <figcaption className="quote-cite">Becky Allen</figcaption>
            </figure>
            <p>
              Remedy Health Media has produced many impressive reports in Shorthand. How does the team decide what gets produced in
              Shorthand, and what gets produced in their standard CMS?
            </p>
            <p>
              “Our teams know they have Shorthand available when they are brainstorming new content. An editor or producer will often
              say, ‘this is a great idea, but let’s make it a little more impactful…’ and they’ll decide to put it in Shorthand.”
            </p>
            <p>
              As Becky explains, once the decision is made, “the editorial team will work with either the multimedia team or a
              production designer to get the content into Shorthand and make sure it looks great — which is easy to do!”
            </p>
            <p>
              Once the story is ready to go, Remedy Health Media’s ad-ops team gets to work. “They make sure we can drop in the basic
              code for ads and have them picked up by our system correctly. Shorthand’s custom HTML option lets us cut and paste those
              ads according to what the specific project needs.”
            </p>
            <p>The editors at Remedy Health Media like Shorthand because it offers them flexibility.</p>
            <p>
              “Shorthand lets our editors get creative with graphic treatments and imagery. Unlike the old in-house app we had built,
              there are no limits on text length or the order in which these elements must fall.”
            </p>
            <p>
              This means that editors can create beautiful, multimedia content, without relying on external developers. As Becky says,
              “they can make every feature feel bespoke, since all of Shorthand’s sections can be moved around and customized.”
            </p>
            <h3 className="subheading">More engagement</h3>
            <figure className="block quote-block offset left">
              <blockquote>
                <p>“We definitely see more engagement from our Shorthand stories!”</p>
              </blockquote>
              <figcaption className="quote-cite">Becky Allen</figcaption>
            </figure>
            <p>Becky says that the team has seen excellent results from their Shorthand stories.</p>
            <p>
              “We have seen an increase in the time users spend reading stories in the Shorthand format compared to the old, in-house
              app — the content is more engaging, so users view more of it.”
            </p>
            <p>
              According to Becky, Remedy Health Media’s users “read further into the pages built with Shorthand, and spend more time on
              watching our videos. That is especially true on mobile – we hadn’t had a real mobile experience, and now that Shorthand
              handles all those particular details, we can make sure mobile readers have the best experience possible.”
            </p>
            <p>
              As Becky explains, engagement is a critical metric for an ad-supported media company. “With better engagement, we have
              also increased the number of ads each user is likely to view and have been able to remove some interstitial ad placements
              that existed only for that purpose in our old system.
            </p>
            <p>“With Shorthand, we don’t need those extra pages because the ads can fit into the flow so much better.”</p>
            <p>We look forward to Remedy Health Media creating more stunning multimedia stories in the years ahead!</p>
          </div>
        </div>
        <RelatedCaseStudies studies={filterListByCurrentIndustryCode(data, "remedy-health-media")} />
        <Footer />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query RemedyHealthMediaQuery {
    allCaseStudiesJson {
      edges {
        node {
          jsonId
          image
          industry
          industryCode
          organisation
          summary
        }
      }
    }
  }
`;
