export const enterpriseTooltips = [
  "A handcrafted theme that styles all the standard Shorthand elements according to your brand guidelines.",
  'Remove all Shorthand branding from your content, including the "Built with Shorthand" footer.',
  "Enable your team members to sign in with your existing authentication system.",
  "Select a support offering with a priority response and tailored SLA. ",
  "Select a customer success tier with tailored onboarding, training, workshops, and quarterly progress reviews for your team.",
];
export const businessTooltips = [
  "Discounted rates for your 21st and subsequent published stories, and for annual precommitments.",
  "For an additional fee, host your own content via Drupal and WordPress plugins, Amazon S3, SFTP, API publishing, and more.",
  "Control who views your content with restricted-access publishing. Grant access to readers via email, and they'll be able to view the content using a One-Time Password.",
  "Add tag managers, 3rd party analytics and tracking codes, marketing pixels, cookie consent managers, and any other code you require to integrate with existing software and tools.",
  "Understand the big picture of how your content is performing with workspace-level CSV data exports. Compile reports and perform analysis in 3rd party tools with ease.",
  "Add custom components and layouts that we don't support natively with HTML sections and inline HTML. Add custom CSS and JavaScript for complete control over styling and integrations.",
  "A handcrafted theme that styles all the standard Shorthand elements according to your brand guidelines.",
  "Schedule a 30-minute kick off with one of our expert Editorial Success Managers to help you get started with Shorthand.",
  "An Editorial Success Manager will be assigned to review and provide advice on your first story.",
];
export const teamTooltips = [
  "Download your stories in ZIP format for archival purposes.",
  "Control access to previews of unpublished content.",
  "Add Google Analytics 4 tracking to stories and projects.",
  "Export Shorthand Analytics data for individual stories in CSV format for reporting and analysis purposes.",
  "Keep your content on-brand and visually cohesive by creating reusable basic themes. Add your choice of fonts, colours and logos.",
  "Use our in-app ticketing system or email help@shorthand.com to submit questions to our support team.",
];
export const starterTooltips = [
  "Publishing is simple with Shorthand Hosting. Use a custom subdomain (e.g., stories.yourdomain.com) or a shorthandstories.com subdomain.",
  "Easily embed your content in any web page by copying and pasting the embed code snippet.",
  "Track story views and completions with simple, built-in analytics.",
  "Learn how to get the most from Shorthand by attending our regular webinars and training sessions.",
];
