import { SVGLogoProps } from ".";
import * as React from "react";

export const DowJones = (props: SVGLogoProps): JSX.Element => {
  return (
    <svg width="100%" className={props.className} viewBox="0 0 164 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 12.5171V0L6.88918 0.0172495L13.7784 0.0344958L14.3677 0.182093C17.2249 0.897725 19.3206 2.72272 20.3462 5.38836C20.593 6.03004 20.8194 7.06969 20.861 7.75245L20.895 8.3093H18.8134H16.7317L16.6993 8.04271C16.5917 7.15682 16.3123 6.45108 15.8116 5.80033C15.3108 5.14947 14.5572 4.62176 13.7222 4.33722L13.2732 4.18421L8.74126 4.16828L4.20928 4.15236V12.5167V20.881H6.28586H8.36243V22.9576V25.0342H4.18122H0V12.5171Z" />
      <path d="M11.5039 22.9751V20.899L12.2476 20.8612C12.6566 20.8404 13.1511 20.7858 13.3466 20.74C14.5463 20.4584 15.7081 19.541 16.2492 18.448C16.7018 17.5337 16.6854 17.6779 16.7089 14.3856L16.7298 11.4531H18.8065H20.8833L20.8621 14.6381L20.8409 17.8232L20.6957 18.4125C20.2877 20.0686 19.5795 21.3358 18.4065 22.5088C17.4544 23.4609 16.4023 24.1237 15.1608 24.5533C14.2337 24.8742 13.6235 24.9808 12.5001 25.0181L11.5039 25.0512L11.5039 22.9751Z" />
      <path d="M55.1093 4.86658C54.1725 4.88696 53.2363 5.00089 52.6471 5.20111C51.4236 5.61684 50.1605 6.66644 49.5828 7.74784C49.1895 8.48388 49.144 8.88781 49.0844 12.1749C49.0413 14.5534 49.0973 16.0532 49.2475 16.5546C49.751 18.2357 51.0562 19.541 52.8002 20.1076C53.3521 20.2869 54.2915 20.3624 55.5033 20.3249C58.4176 20.2346 60.0705 19.2905 61.0035 17.184C61.3841 16.3244 61.4578 15.7668 61.5254 13.2319C61.616 9.83759 61.4041 8.47178 60.6002 7.27468C59.9639 6.32721 58.7235 5.43355 57.5745 5.09418C56.9834 4.91959 56.0461 4.84624 55.1093 4.86658ZM55.3681 7.78907C56.2032 7.81256 57.0269 8.10347 57.5783 8.67846L58.1427 9.26693L58.198 12.4315C58.2608 16.0173 58.1156 16.511 56.8165 17.1274C55.4055 17.797 53.5391 17.3863 52.6823 16.2174C52.3134 15.7143 52.2775 15.3919 52.2775 12.6003C52.2775 9.91678 52.3233 9.46745 52.6471 8.98969C53.2023 8.1706 54.2945 7.75887 55.3681 7.78907Z" />
      <path d="M95.5333 20.2044C93.9369 19.7237 92.9298 18.5592 92.6657 16.8888L92.5098 15.9021H94.1187C95.7265 15.9021 95.7277 15.9025 95.9736 16.4962C96.4026 17.532 97.642 17.8017 98.5806 17.0633L99.0928 16.6604L99.1445 10.8556L99.1963 5.05078H100.76H102.324L102.376 10.8071C102.437 17.5405 102.337 18.1796 101.054 19.2783C100.625 19.6451 99.9338 20.0474 99.5172 20.1722C98.6612 20.4287 96.3405 20.4475 95.5333 20.2044Z" />
      <path d="M112.396 4.86135C110.776 4.8599 110.388 4.92872 109.366 5.40171C107.917 6.07172 107.416 6.52898 106.762 7.77888C106.279 8.70144 106.245 8.94547 106.175 12.1116C106.116 14.8056 106.164 15.6836 106.421 16.5677C106.926 18.3047 108.487 19.7527 110.343 20.2066C111.465 20.4811 113.883 20.4001 114.963 20.052C116.457 19.5707 117.923 18.1108 118.34 16.6884C118.763 15.2491 118.777 9.93492 118.363 8.53919C117.956 7.16857 116.967 6.09805 115.487 5.42461C114.406 4.93296 114.022 4.86291 112.396 4.86135ZM112.44 7.80332C113.096 7.8158 113.754 7.95958 114.192 8.23025C115.219 8.86513 115.328 9.29708 115.328 12.7475C115.328 15.624 115.298 15.8779 114.904 16.3791C114.671 16.6755 114.19 17.0392 113.835 17.1875C112.382 17.7946 110.536 17.3576 109.859 16.2462C109.509 15.6723 109.464 15.2627 109.464 12.5993C109.464 9.27836 109.591 8.81834 110.699 8.1638C111.129 7.90966 111.783 7.79083 112.44 7.80332Z" />
      <path d="M156.021 20.1062C154.894 19.7546 153.783 18.962 153.183 18.0833C152.652 17.3051 152.183 15.8112 152.373 15.5038C152.438 15.3988 153.174 15.3128 154.008 15.3128C155.524 15.3128 155.526 15.3136 155.805 15.9343C156.279 16.9866 156.999 17.3658 158.523 17.3658C160.103 17.3658 160.679 16.9998 160.679 15.9972C160.679 15.08 160.177 14.7556 157.81 14.1461C155.085 13.4445 154.797 13.3219 153.848 12.4648C152.167 10.9456 152.033 8.21201 153.558 6.52397C155.816 4.0249 161.122 4.3369 162.934 7.07526C163.208 7.48942 163.484 8.14854 163.548 8.53999L163.663 9.25172H162.09C160.631 9.25172 160.49 9.21567 160.155 8.75597C159.042 7.22785 155.792 7.59223 155.792 9.24514C155.792 10.0836 156.478 10.4991 158.84 11.0911C161.22 11.6874 161.886 11.9822 162.786 12.8365C163.453 13.4696 164.004 14.8949 164.002 15.9838C163.998 17.8709 162.735 19.5324 160.887 20.0814C159.612 20.4601 157.194 20.4724 156.021 20.1062Z" />
      <path d="M33.1082 5.05078L33.057 12.6272L33.0059 20.2036H36.7587C41.2443 20.2036 42.3135 19.9842 43.6762 18.7845C45.2539 17.3957 45.4127 16.891 45.4889 13.0194C45.5423 10.3048 45.4933 9.46252 45.2391 8.69653C44.7845 7.32758 43.8105 6.29033 42.3581 5.62741C41.128 5.06598 41.1103 5.06382 37.1161 5.05727L33.1082 5.05078ZM36.334 8.08134H38.4124C40.1378 8.08134 40.5971 8.14685 41.1132 8.46588C42.1636 9.11509 42.297 9.59171 42.297 12.6925C42.297 15.8231 42.1477 16.2494 40.8166 16.9282C40.3142 17.1844 39.6686 17.2702 38.2402 17.2704L36.334 17.2708V12.676V8.08134Z" />
      <path d="M67.3193 19.7461C66.998 18.401 64.4844 5.57942 64.4844 5.2855C64.4844 4.97856 64.6751 4.93542 66.0322 4.93542H67.5801L67.7707 5.86414C67.8756 6.37494 68.2951 8.61687 68.703 10.8462C69.1109 13.0755 69.4712 14.873 69.5036 14.8405C69.536 14.8081 70.0096 13.2921 70.556 11.4715C71.1024 9.65102 71.77 7.43127 72.0396 6.53876L72.5297 4.91602L73.5308 4.97462L74.532 5.03321L75.9924 9.97016C76.7957 12.6855 77.478 14.8819 77.5088 14.8511C77.5396 14.8203 77.9655 12.6553 78.4551 10.04C78.9448 7.42477 79.394 5.20636 79.4534 5.11023C79.5837 4.89946 82.4101 4.87368 82.5375 5.08211C82.5868 5.16276 81.9553 8.59414 81.1342 12.7074L79.6413 20.186H77.8744H76.1075L74.8604 16.1779C74.1746 13.9734 73.5609 12.1714 73.4967 12.1735C73.4324 12.1756 73.1008 13.0774 72.7598 14.1776C72.4187 15.2777 71.8571 17.0797 71.5117 18.182L70.8838 20.186H69.1541C67.5218 20.186 67.4185 20.1612 67.3193 19.7461Z" />
      <path d="M122.399 12.6054L122.45 5.02896L124.014 4.97542L125.578 4.92188L128.608 9.76378L131.639 14.6057L131.691 9.76843L131.743 4.93119H133.304H134.865V12.5565V20.1818L133.252 20.181L131.639 20.1803L128.706 15.4979L125.774 10.8154L125.721 15.4986L125.669 20.1817H124.008H122.348L122.399 12.6054Z" />
      <path d="M138.973 12.5505V4.92188L144.007 4.97408L149.041 5.02628V6.49268V7.95908L145.669 8.01255L142.296 8.06602V9.52271V10.9794L145.278 11.0334L148.259 11.0874L148.317 12.5049L148.374 13.9224H145.335H142.296V15.5843V17.2463H145.718H149.139V18.7127V20.1791H144.056H138.973V12.5505Z" />
    </svg>
  );
};
