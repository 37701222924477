import { filterListByCurrentIndustryCode } from "../../Utils";
import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { RelatedCaseStudies } from "../../components/RelatedCaseStudies";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default function CaseStudy({ data, location }) {
  return (
    <Layout location={location}>
      <main id="main" className="marketing-content container-fluid">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-secondary body-marketing-case-studies hide-elevio",
          }}
        />
        <div className="row section-title">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6 mx-auto section-inner">
            <h1>
              <span className="shorthand-logo">Shorthand</span>
              <span className="subsection-label">Case Study:</span>
              ActionAid
            </h1>
            <h2>How ActionAid uses Shorthand</h2>
          </div>
          <div aria-hidden="true" className="background-media">
            <img
              src="/media/case-studies/case-studies-actionaid-title.jpg"
              data-object-fit="cover"
              data-autoscale-content-ratio="1.77777777778"
              alt=""
            />
          </div>
        </div>
        <div className="row section">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto marketing-main-column">
            <h3 className="intro">
              Using Shorthand, ActionAid’s content receives higher engagement rates, lower bounce rates, and better results for their
              organisation.
            </h3>
            <div className="inline-image featured">
              <img
                alt="Women by Women, by the Action Aid, renders responsively across all devices"
                src="/media/case-studies/case-studies-actionaid.jpg"
              />
            </div>
            <p>
              ActionAid is a global nonprofit with 45 country offices, putting the rights of women and girls at the heart of their
              mission to fight poverty and injustice. ActionAid uses Shorthand to amplify the experiences of women and girls and share
              immersive content with their child sponsors.
            </p>
            <p>The team at ActionAid UK started using Shorthand after coming across an immersive feature story from the BBC.</p>
            <p>
              Hollie Pycroft is ActionAid's Senior Digital Content Editor. As she explains, “It was a really beautifully illustrated
              piece. Someone found it and sent it around the team, and we all thought it was amazing. And we thought, wouldn't it be so
              great if we could do something like this?”
            </p>
            <p>
              In October 2018, ActionAid published their first Shorthand story, and their content has since gone from strength to
              strength, with highlights including{" "}
              <a href="https://voices.actionaid.org.uk/women-by-women/" target="_blank" rel="noopener noreferrer" className="btn-link">
                Women by Women
              </a>
              &nbsp; and&nbsp;
              <a
                href="https://voices.actionaid.org.uk/unmasked-how-covid-19-is-affecting-the-worlds-poorest-countries/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                Unmasked
              </a>
              .
            </p>
            <h3 className="subheading">Engaging content at scale</h3>
            <figure className="block quote-block offset right">
              <blockquote>
                <p>
                  “We would try to come up with custom templates and layouts… but it was a lot of work. Shorthand has really solved
                  that problem.”
                </p>
              </blockquote>
              <figcaption className="quote-cite">Hollie Pycroft</figcaption>
            </figure>
            <p>
              Before using Shorthand, the ActionAid team created custom content in their CMS, relying on the efforts of an in-house
              developer.
            </p>
            <p>
              As Hollie says, “We would try to come up with custom templates and layouts on our website that could handle having that
              much content and still be engaging. But it was a lot of work. Shorthand has really solved that problem.”
            </p>
            <p>
              Because Shorthand allows the ActionAid content team to bypass custom development, it is a cheaper option than building
              custom content in-house.
            </p>
            <p>
              “It’s really difficult for us to come up with a great looking custom template that would be nearly as easy to use as
              Shorthand, and not require any coding or anything on the part of the editor. This makes Shorthand a less expensive option
              for us.”
            </p>
            <div className="case-study-video-container">
              <video className="case-study-video-inline" muted loop playsInline autoPlay>
                <source src="/media/case-studies/case-studies-actionaid.mp4" type="video/mp4" />
              </video>
            </div>
            <h3 className="subheading">Community storytelling</h3>
            <figure className="block quote-block offset left">
              <blockquote>
                <p>
                  “One of the main reasons we got Shorthand was to do away with all that custom coding, knowing it would save so much
                  time.”
                </p>
              </blockquote>
              <figcaption className="quote-cite">Hollie Pycroft</figcaption>
            </figure>
            <p>One of the ways ActionAid uses Shorthand is to give information to donors who sponsor children.</p>
            <p>
              As Hollie says, “We use Shorthand to share content with supporters about sponsored children. We get lots of photos and
              stories back from all the different communities where sponsored children live. So we use Shorthand to share that amazing
              content with sponsors.”
            </p>
            <p>
              The feedback from sponsors on these stories is extremely positive. “We get lots of fantastic feedback from sponsors every
              time we send these stories out, like, ‘This was amazing, I loved reading the stories,’ and comments like that.”
            </p>
            <p>
              Prior to Shorthand, the ActionAid team provided this information using a bespoke grid they had developed on their
              website. “We developed that, but it was incredibly time consuming to build, really not very easy to use, just because it
              was all custom code,” Hollie says.
            </p>
            <p>
              “That was one of the reasons why we got Shorthand as well, that we could just do away with all that custom coding, use
              this tool, and it would save so much time.”
            </p>
            <p>
              As Hollie explains, this outcome &mdash; and being able to say, “Look, this is actually going to save loads of time”
              &mdash; was one of the key reasons for ActionAid subscribing to Shorthand.
            </p>
            <div className="photo-grid flex featured">
              <img
                alt="A section from one of Action Aid's stories"
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-actionaid-sml-01.jpg"
              />
              <img
                alt="A section from one of Action Aid's stories"
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-actionaid-sml-02.jpg"
              />
              <img
                alt="A section from one of Action Aid's stories"
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-actionaid-sml-03.jpg"
              />
              <img
                alt="A section from one of Action Aid's stories"
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-actionaid-sml-04.jpg"
              />
            </div>
            <div className="caption">A selection of sections from ActionAid's stories</div>
            <h3 className="subheading">Women by women</h3>
            <p>
              One significant Shorthand project for ActionAid was their virtual exhibition&nbsp;
              <a href="https://voices.actionaid.org.uk/women-by-women/" target="_blank" rel="noopener noreferrer" className="btn-link">
                Women by Women
              </a>
              , which preceded a physical exhibition.
            </p>
            <p>
              “Women by Women is a photography campaign that's all about showcasing women photographers from around the world, who tell
              stories of women in their communities in a new way. The campaign is focused on telling in-depth stories and giving agency
              and power to the women whose stories are being told.
            </p>
            <p>
              “We use Shorthand pages as a kind of digital gallery, as it were, to show the different photos. Shorthand is the best way
              of doing that that we're aware of. It works really nicely.”
            </p>
            <p>While Hollie isn’t a trained designer, Shorthand gives her the confidence to produce visually stunning stories.</p>
            <p>
              “I think that the main point is it's not essential to be a trained graphic designer to make it look good at all. One of
              the other things is I always go on the Shorthand blog, and there the Shorthand newsletter, which gives us brilliant
              examples. That's great for taking inspiration. I love it.”
            </p>
            <p>
              (You can{" "}
              <a
                href="https://shorthand.com/the-craft/add-the-craft-to-your-inbox/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                sign up for Shorthand’s newsletter here.
              </a>
              )
            </p>
            <h3 className="subheading">Immersive landing pages</h3>
            <p>A more recent use case for ActionAid is landing pages for their display ad campaigns.</p>
            <p>
              Before using Shorthand, ActionAid used to send ad traffic to their website homepage. But, as Hollie explains, this didn’t
              get the results they wanted.
            </p>
            <p>
              “Traffic from our display ads is cold. It's people just clicking from around the internet. We found that sending them
              straight to our website homepage results in really high bounce rates and really low dwell times. It just wasn’t getting
              us anywhere.”
            </p>
            <p>
              Instead, Hollie and the team have started creating immersive landing pages using Shorthand. “We've been trying out
              creating engaging pieces on Shorthand that we can send that traffic to. We've tried it a few times now, and we found that
              it is improving engagement rates and lowering bounce rate. It’s doing just what we wanted it to do.”
            </p>
            <p>
              One recent campaign called ‘She is the Answer’ got great results for ActionAid. “We had this gorgeous video and
              photography, so we thought: let's use it in Shorthand. We used ads on paid social to retarget people who've landed on
              this page, which worked really nicely.”
            </p>
            <h3 className="subheading">A growing platform</h3>
            <figure className="block quote-block offset right">
              <blockquote>
                <p>
                  “Shorthand has got better and better over time. There's been so many new things that have been implemented over the
                  last three years.”
                </p>
              </blockquote>
              <figcaption className="quote-cite">Hollie Pycroft</figcaption>
            </figure>
            <p>
              One of the additional benefits of Shorthand for Hollie is that it allows her creative freedom, without being difficult to
              use.
            </p>
            <p>
              “I think it's really fun to use. You can play around so easily. You don't have to stick with anything. It's really fun to
              use, and there are so many different features.”
            </p>
            <p>
              Hollie also appreciates the ongoing development and improvement of the Shorthand platform. “I think one of the things
              that's been so great using it over the past three years: It’s become better and better over time. There's been so many
              new things that have been implemented over the last three years.”
            </p>
            <p>
              “It seems like your team, the development team, are working really hard to find out where there are problems, and create
              new solutions.”
            </p>
            <p>
              Hollie also praises the customer success team at Shorthand. “Everyone I’ve spoken to at Shorthand has been really helpful
              throughout. It's been good!”
            </p>

            <h3 className="subheading">Great results</h3>
            <p>
              Over the years, Hollie and the ActionAid team have developed a reliable workflow for creating content with Shorthand, and
              are seeing great results.
            </p>
            <p>
              “We do find that the dwell time is usually significantly higher with Shorthand pieces, and the bounce rate is lower,
              which I guess is down to that beautiful first page. It just kind of grabs you, doesn't it?
            </p>
            <p>Beyond metrics, Hollie is happy to give her donors and community a great reading experience.</p>
            <p>
              “There's something about just knowing that you're giving supporters something beautiful and well-thought-out as a piece
              of content, and the qualitative feedback that we get is great.”
            </p>
          </div>
        </div>
        <RelatedCaseStudies studies={filterListByCurrentIndustryCode(data, "action-aid")} />
        <Footer />
      </main>
    </Layout>
  );
}

export const query = graphql`
  query ActionAidQuery {
    allCaseStudiesJson {
      edges {
        node {
          jsonId
          image
          industry
          industryCode
          organisation
          summary
        }
      }
    }
  }
`;
