import { LayoutNew } from "../components/LayoutNew";
import { BannerSimple, Feature, FooterBanner2, ImageText, Logos, Testimonial } from "../components/Sections";
import { graphql } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";
import * as React from "react";

export default function CaseStudy({ data: { digitalMagazineJson }, location }) {
  const { banner, logos, mainContent, feature, footer, quotes } = digitalMagazineJson;
  return (
    <LayoutNew location={location} header={false}>
      <section className="tw-container tw-my-10 tw-mb-6">
        <BannerSimple
          clickableLogo={true}
          title={banner.title}
          description={banner.description}
          cta={banner.cta}
          lists={banner.lists}
          textSize="normal"
        />
      </section>
      <section className="tw-container  tw-mb-40 tw-mt-6 max-lg:tw-mb-[6.25rem]">
        <Logos title={logos.title} imgs={logos.logos} />
      </section>
      <section>
        <Feature {...feature} />
      </section>
      {mainContent.content.map(
        (
          section: {
            title: string;
            description: string;
            video: any;
            image: { alt: string; url: IGatsbyImageData };
          },
          index: number
        ) => {
          return (
            <section className="tw-container tw-my-40 max-lg:tw-my-[6.25rem]" key={index}>
              {index % 2 === 0 && (
                <ImageText
                  mediaType={section.image ? "image" : "video"}
                  img={section.image}
                  video={{
                    ...section.video,
                    frame: "pad",
                  }}
                  title={section.title}
                  content={section.description}
                />
              )}
              {index % 2 !== 0 && (
                <ImageText
                  firstContentType="text"
                  mediaType={section.image ? "image" : "video"}
                  img={section.image}
                  video={{
                    ...section.video,
                    frame: "pad",
                  }}
                  title={section.title}
                  content={section.description}
                />
              )}
            </section>
          );
        }
      )}
      <section className="tw-container tw-my-40  max-lg:tw-my-[6.25rem]  max-sm:tw-my-20 ">
        <Testimonial
          firstContentType="testimonial"
          testimonials={quotes.quotes}
          cta={{
            link: quotes.cta.url,
            text: quotes.cta.text,
          }}
          img={quotes.img}
        />
      </section>
      <section>
        <FooterBanner2 title={footer.title} cta={footer.cta} image={footer.image} />
      </section>
    </LayoutNew>
  );
}

export const query = graphql`
  query MyQuery {
    digitalMagazineJson {
      logos {
        title
        logos {
          alt
          url {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      banner {
        cta {
          text
          url
        }
        description
        lists
        title
      }

      mainContent {
        content {
          description
          video {
            videoWebm {
              publicURL
            }
            videoMp4 {
              publicURL
            }
          }
          image {
            alt
            url {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          title
        }
      }
      quotes {
        cta {
          text
          url
        }
        img {
          alt
          url {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        quotes {
          author
          content
        }
      }
      feature {
        description
        images {
          url {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          alt
        }
        mobileImage {
          alt
          url {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        title
      }
      footer {
        cta {
          text
          url
        }
        image {
          alt
          url {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        title
      }
    }
  }
`;
