import * as styles from "./LabelCell.module.scss";
import * as Tooltip from "@radix-ui/react-tooltip";
import * as React from "react";

type LabelCellProps = { label: string; tooltip: string };
export const LabelCell = (props: LabelCellProps): JSX.Element => {
  return (
    <div className={styles.labelCell}>
      <p className={styles.labelCell__paragraph}>{props.label}</p>
      <Tooltip.Provider delayDuration={800} skipDelayDuration={500}>
        <Tooltip.Root delayDuration={0}>
          <Tooltip.Trigger>
            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
              <g clipPath="url(#a)">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.333}
                  d="M6.06 6a2 2 0 0 1 3.887.667c0 1.333-2 2-2 2M8 11.333h.007M14.667 8A6.667 6.667 0 1 1 1.333 8a6.667 6.667 0 0 1 13.334 0Z"
                />
              </g>
              <defs>
                <clipPath id="a">
                  <path fill="#fff" d="M0 0h16v16H0z" />
                </clipPath>
              </defs>
            </svg>
          </Tooltip.Trigger>
          <Tooltip.Content className={styles.TooltipContent}>
            {props.tooltip}
            <Tooltip.Arrow className={styles.TooltipArrow} />
          </Tooltip.Content>
        </Tooltip.Root>
      </Tooltip.Provider>
    </div>
  );
};
