import { Link } from "gatsby";
import * as React from "react";

export class LegalNav extends React.Component<any, any> {
  render() {
    return (
      <aside className="legalNav">
        <h2>Our policies:</h2>
        <nav className="legalNav-inner">
          <ul>
            <li>
              <Link to="/legal/customer-terms">Customer Terms</Link>
            </li>
            <li>
              <Link to="/legal/user-terms">User Terms</Link>
            </li>
            <li>
              <Link to="/legal/privacy-policy">Privacy</Link>
            </li>
            <li>
              <Link to="/legal/acceptable-use-policy">Acceptable Use</Link>
            </li>
            <li>
              <Link to="/legal/copyright-and-trademark-policy">Copyright & Trademarks</Link>
            </li>
            <li>
              <Link to="/legal/cookie-policy">Cookies</Link>
            </li>
          </ul>
        </nav>
      </aside>
    );
  }
}
