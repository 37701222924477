import * as React from "react";

interface ITemaplateIconsrops {
  icons: { value: string; label: string }[];
}

const iconForKeyword = {
  Responsive:
    "M21.5 15.833c-.83 0-1.5.67-1.5 1.5v18c0 .83.67 1.5 1.5 1.5h9c.83 0 1.5-.67 1.5-1.5v-18c0-.83-.67-1.5-1.5-1.5h-9Zm-3 1.5c0-1.654 1.345-3 3-3h9c1.655 0 3 1.346 3 3v18c0 1.655-1.345 3-3 3h-9c-1.655 0-3-1.345-3-3v-18Zm6 15.75h3c.413 0 .75.338.75.75 0 .413-.337.75-.75.75h-3a.752.752 0 0 1-.75-.75c0-.412.337-.75.75-.75Z",
  Tables:
    "M23 16v4.5h13.5v-3c0-.83-.67-1.5-1.5-1.5H23Zm-1.5 0H17c-.83 0-1.5.67-1.5 1.5v3h6V16Zm-6 6v6h6v-6h-6Zm0 7.5v3c0 .83.67 1.5 1.5 1.5h4.5v-4.5h-6ZM23 34h12c.83 0 1.5-.67 1.5-1.5v-3H23V34Zm13.5-6v-6H23v6h13.5ZM14 17.5c0-1.655 1.345-3 3-3h18c1.655 0 3 1.345 3 3v15c0 1.655-1.345 3-3 3H17c-1.655 0-3-1.345-3-3v-15Z",
  ModernStyle:
    "M16 18c0-.83.67-1.5 1.5-1.5h18c.83 0 1.5.67 1.5 1.5v2.25c0 .413-.337.75-.75.75a.752.752 0 0 1-.75-.75V18h-8.25v18h3c.413 0 .75.337.75.75s-.337.75-.75.75h-7.5a.752.752 0 0 1-.75-.75c0-.413.337-.75.75-.75h3V18H17.5v2.25c0 .413-.337.75-.75.75a.752.752 0 0 1-.75-.75V18Z",
  ScrollEffects:
    "M27.03 14.22a.747.747 0 0 0-1.04-.018l-4.875 4.5a.749.749 0 1 0 1.017 1.101l3.618-3.342v18.98l-3.22-3.22a.753.753 0 0 0-1.06 0 .753.753 0 0 0 0 1.059l4.5 4.5c.291.29.77.29 1.06 0l4.5-4.5a.753.753 0 0 0 0-1.06.753.753 0 0 0-1.06 0l-3.22 3.22V16.56l3.22 3.22c.291.29.77.29 1.06 0a.753.753 0 0 0 0-1.06l-4.5-4.5Z",
  Media:
    "M17 18c-.83 0-1.5.67-1.5 1.5v10.94l3.173-3.173a1.879 1.879 0 0 1 2.654 0l3.173 3.174 6.173-6.174a1.879 1.879 0 0 1 2.654 0l3.173 3.174V19.5c0-.83-.67-1.5-1.5-1.5H17Zm-1.5 14.56v1.94c0 .83.67 1.5 1.5 1.5h1.94l4.5-4.5-3.173-3.173a.376.376 0 0 0-.53 0L15.5 32.559Zm16.767-7.233a.376.376 0 0 0-.53 0L21.06 36H35c.83 0 1.5-.67 1.5-1.5v-4.94l-4.233-4.233ZM14 19.5c0-1.655 1.345-3 3-3h18c1.655 0 3 1.345 3 3v15c0 1.655-1.345 3-3 3H17c-1.655 0-3-1.345-3-3v-15Zm7.5 2.25a.75.75 0 1 0-1.5 0 .75.75 0 0 0 1.5 0Zm-3 0a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0Z",
  Customisable:
    "M37.055 14.94a1.502 1.502 0 0 0-2.124 0l-7.715 7.716 2.123 2.124 7.716-7.72a1.502 1.502 0 0 0 0-2.124v.005Zm-10.899 8.776-1.715 1.715-1.913 1.913 2.124 2.123.215-.215 1.692-1.693 1.716-1.715-2.119-2.128Zm-4.973 4.401a3.749 3.749 0 0 0-4.664 4.022c.08.783-.14 1.575-.586 2.19-.45.632-1.214 1.171-2.208 1.171h6.525a3.749 3.749 0 0 0 3.633-4.683l-2.705-2.704.005.004Zm16.936-9.994L25.42 30.822c.052.3.08.614.08.928A5.247 5.247 0 0 1 20.25 37H13.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h.225c.825 0 1.388-.886 1.303-1.706A5.247 5.247 0 0 1 20.25 26.5c.314 0 .623.028.923.08l12.704-12.703a2.999 2.999 0 1 1 4.242 4.242v.005Z",
  ChartsGraphs:
    "M15.5 15.25a.752.752 0 0 0-.75-.75.752.752 0 0 0-.75.75v16.5a3.749 3.749 0 0 0 3.75 3.75h19.5c.413 0 .75-.337.75-.75a.752.752 0 0 0-.75-.75h-19.5a2.25 2.25 0 0 1-2.25-2.25v-16.5Zm20.78 5.03a.753.753 0 0 0 0-1.06.753.753 0 0 0-1.06 0L29 25.44l-3.97-3.97a.753.753 0 0 0-1.06 0l-5.25 5.25a.753.753 0 0 0 0 1.06c.29.29.77.29 1.06 0l4.72-4.72 3.97 3.97c.29.29.77.29 1.06 0l6.75-6.75Z",
  MediaGallery:
    "M20.667 17.667h14.666c.738 0 1.334.596 1.334 1.333v4.392L34.275 21a1.335 1.335 0 0 0-1.887 0l-5.721 5.725-3.059-3.058a1.335 1.335 0 0 0-1.887 0l-2.388 2.391V19c0-.737.596-1.333 1.334-1.333ZM38 19a2.67 2.67 0 0 0-2.667-2.666H20.667A2.67 2.67 0 0 0 18 19v9.333A2.67 2.67 0 0 0 20.667 31h14.666A2.67 2.67 0 0 0 38 28.334V19Zm-4.667 2.942 3.334 3.333v3.059c0 .737-.596 1.333-1.334 1.333h-9.725l1.53-1.53 6.195-6.195Zm-7.608 5.725-2 2h-3.058a1.332 1.332 0 0 1-1.334-1.334v-.391l3.334-3.334 3.058 3.059Zm-10.392-8c0-.367-.3-.667-.666-.667-.367 0-.667.3-.667.667v10A5.333 5.333 0 0 0 19.333 35h14c.367 0 .667-.3.667-.666 0-.367-.3-.667-.667-.667h-14c-2.208 0-4-1.792-4-4v-10Zm9.667 2a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z",
  Photography:
    "M22.958 15.5c-.97 0-1.828.619-2.133 1.538l-.492 1.462H17c-1.655 0-3 1.345-3 3v12c0 1.655 1.345 3 3 3h18c1.655 0 3-1.345 3-3v-12c0-1.655-1.345-3-3-3h-3.333l-.487-1.462a2.254 2.254 0 0 0-2.138-1.538h-6.084Zm-.713 2.01a.752.752 0 0 1 .713-.51h6.084c.324 0 .61.206.713.51l.656 1.974c.103.305.389.511.712.511H35c.83 0 1.5.67 1.5 1.5V33.5c0 .83-.67 1.5-1.5 1.5H17c-.83 0-1.5-.67-1.5-1.5v-12c0-.83.67-1.5 1.5-1.5h3.877c.323 0 .609-.206.712-.51l.656-1.974v-.005ZM26 32.75a5.251 5.251 0 1 0 0-10.502 5.251 5.251 0 0 0 0 10.502Zm-3.75-5.25a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0Z",
  Illustrations:
    "M32.553 15.856a1.12 1.12 0 0 1 1.589 0l1.997 1.997c.44.44.44 1.153 0 1.59l-2.564 2.572-3.591-3.59 2.569-2.569Zm-3.628 3.628 3.59 3.59L22.25 33.34v-.59a.752.752 0 0 0-.75-.75H20v-1.5a.752.752 0 0 0-.75-.75h-.591l10.266-10.266ZM17.135 31.79c.057-.188.127-.37.22-.54H18.5v1.5c0 .413.337.75.75.75h1.5v1.145a3.077 3.077 0 0 1-.544.22l-4.35 1.28 1.28-4.35v-.005Zm18.07-16.992a2.623 2.623 0 0 0-3.712 0L16.831 29.455a4.478 4.478 0 0 0-1.134 1.912l-1.665 5.672a.746.746 0 0 0 .929.928l5.671-1.669a4.525 4.525 0 0 0 1.913-1.134l14.658-14.658a2.623 2.623 0 0 0 0-3.712l-1.997-1.997ZM29.53 23.5a.753.753 0 0 0 0-1.059.753.753 0 0 0-1.06 0l-6 6a.753.753 0 0 0 0 1.06c.292.29.77.29 1.06 0l6-6Z",
  Pictorial:
    "M32.553 15.856a1.12 1.12 0 0 1 1.589 0l1.997 1.997c.44.44.44 1.153 0 1.59l-2.564 2.572-3.591-3.59 2.569-2.569Zm-3.628 3.628 3.59 3.59L22.25 33.34v-.59a.752.752 0 0 0-.75-.75H20v-1.5a.752.752 0 0 0-.75-.75h-.591l10.266-10.266ZM17.135 31.79c.057-.188.127-.37.22-.54H18.5v1.5c0 .413.337.75.75.75h1.5v1.145a3.077 3.077 0 0 1-.544.22l-4.35 1.28 1.28-4.35v-.005Zm18.07-16.992a2.623 2.623 0 0 0-3.712 0L16.831 29.455a4.478 4.478 0 0 0-1.134 1.912l-1.665 5.672a.746.746 0 0 0 .929.928l5.671-1.669a4.525 4.525 0 0 0 1.913-1.134l14.658-14.658a2.623 2.623 0 0 0 0-3.712l-1.997-1.997ZM29.53 23.5a.753.753 0 0 0 0-1.059.753.753 0 0 0-1.06 0l-6 6a.753.753 0 0 0 0 1.06c.292.29.77.29 1.06 0l6-6Z",
  Dynamic:
    "M33.284 22.227A1.499 1.499 0 0 1 32 24.495h-9c-.54 0-1.04-.29-1.303-.76a1.51 1.51 0 0 1 .019-1.508l4.5-7.5a1.497 1.497 0 0 1 2.568 0l4.5 7.5ZM27.5 15.5 23 23h9l-4.5-7.5Zm8.625 12.75h-6.75a.376.376 0 0 0-.375.375v6.75c0 .206.169.375.375.375h6.75a.376.376 0 0 0 .375-.375v-6.75a.376.376 0 0 0-.375-.375Zm-6.75-1.5h6.75c1.036 0 1.875.84 1.875 1.875v6.75c0 1.036-.84 1.875-1.875 1.875h-6.75a1.875 1.875 0 0 1-1.875-1.875v-6.75c0-1.036.84-1.875 1.875-1.875ZM24.5 32a4.501 4.501 0 0 0-8.658-1.722 4.501 4.501 0 0 0 8.315 3.444A4.501 4.501 0 0 0 24.5 32ZM14 32a6 6 0 1 1 12 0 6 6 0 0 1-12 0Z",
  Animated:
    "M35.333 17.667c.738 0 1.334.596 1.334 1.333v13.334c0 .737-.596 1.333-1.334 1.333H16.667a1.332 1.332 0 0 1-1.334-1.334V19c0-.737.596-1.333 1.334-1.333h18.666Zm-18.666-1.334A2.67 2.67 0 0 0 14 19v13.334A2.67 2.67 0 0 0 16.667 35h18.666A2.67 2.67 0 0 0 38 32.334V19a2.67 2.67 0 0 0-2.667-2.666H16.667Zm10 5.334c-.367 0-.667.3-.667.666V29c0 .367.3.667.667.667.366 0 .666-.3.666-.667v-6.666c0-.367-.3-.667-.666-.667Zm2 .666V29c0 .367.3.667.666.667.367 0 .667-.3.667-.667v-2.666h2.667c.366 0 .666-.3.666-.667 0-.367-.3-.667-.666-.667H30v-2h3.333c.367 0 .667-.3.667-.666 0-.367-.3-.667-.667-.667h-4c-.366 0-.666.3-.666.666Zm-9.334 3.334a2.67 2.67 0 0 1 4.267-2.134.665.665 0 1 0 .8-1.067 4.001 4.001 0 1 0-2.4 7.2c.9 0 1.733-.295 2.4-.8a.67.67 0 0 0 .267-.532v-2.667c0-.367-.3-.667-.667-.667h-2c-.367 0-.667.3-.667.667 0 .366.3.666.667.666h1.333v1.642a2.67 2.67 0 0 1-4-2.308Z",
  Engaging:
    "M18.883 20.667c1.796-1.667 4.184-3 7.117-3s5.32 1.333 7.117 3c1.795 1.666 2.995 3.666 3.55 5-.555 1.333-1.755 3.333-3.55 5-1.796 1.666-4.184 3-7.117 3s-5.32-1.334-7.117-3c-1.796-1.667-2.996-3.667-3.55-5 .554-1.334 1.754-3.334 3.55-5ZM26 16.333c-3.367 0-6.063 1.534-8.025 3.359-1.95 1.808-3.254 3.975-3.87 5.462-.138.33-.138.696 0 1.025.616 1.488 1.92 3.655 3.87 5.463C19.937 33.467 22.633 35 26 35s6.062-1.533 8.025-3.358c1.95-1.813 3.254-3.975 3.875-5.463.137-.329.137-.695 0-1.025-.62-1.487-1.925-3.654-3.875-5.462-1.963-1.825-4.658-3.358-8.025-3.358Zm-4 9.334a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm9.333 0a5.333 5.333 0 1 0-10.666 0 5.333 5.333 0 0 0 10.666 0Z",
  Shareable:
    "M33.5 16.072a.374.374 0 0 0-.647-.258l-1.116 1.181a14.277 14.277 0 0 1-7.237 4.107v8.296a14.244 14.244 0 0 1 7.238 4.107l1.115 1.176c.234.249.647.08.647-.258V16.072ZM23 29.145v-7.79a15.64 15.64 0 0 1-.82.07l-1.388.075H17c-.83 0-1.5.67-1.5 1.5v4.5c0 .83.67 1.5 1.5 1.5H20.788l1.392.075c.272.014.548.037.82.07Zm8.766-14.362C32.933 13.55 35 14.375 35 16.072v18.356c0 1.697-2.072 2.522-3.234 1.29L30.65 34.54a12.754 12.754 0 0 0-7.645-3.88v4.715A2.627 2.627 0 0 1 20.38 38h-.755A2.627 2.627 0 0 1 17 35.375V30.5c-1.655 0-3-1.345-3-3V23c0-1.655 1.345-3 3-3h3.731l1.369-.075a12.765 12.765 0 0 0 8.55-3.961l1.116-1.181ZM18.5 30.5v4.875c0 .623.502 1.125 1.125 1.125h.75c.623 0 1.125-.502 1.125-1.125v-4.833l-.769-.042H18.5ZM37.25 23c.413 0 .75.337.75.75v3c0 .412-.337.75-.75.75a.752.752 0 0 1-.75-.75v-3c0-.413.337-.75.75-.75Z",
};
export const IconsRow = ({ icons }: ITemaplateIconsrops): JSX.Element => {
  return (
    <div className="tw-mb-[40px] tw-mt-[40px] tw-flex tw-w-[87%] tw-justify-between tw-gap-4  max-md:tw-grid  max-md:!tw-w-[80%] max-md:tw-grid-cols-2 max-md:tw-grid-rows-3 ">
      {icons.map(item => (
        <div className="tw-flex tw-flex-col tw-items-center tw-gap-3.5" key={item.label}>
          <svg width={52} height={52} fill="none">
            <circle cx={26} cy={26} r={26} fill="#EEE" />
            <path fill="#000" d={`${iconForKeyword[item.value]}`} />
          </svg>
          <span className="!tw-font-CircularXXSub !tw-text-[14px] tw-text-grey">{item.label}</span>
        </div>
      ))}
    </div>
  );
};

export const IconsRowMobile = ({ icons }: ITemaplateIconsrops): JSX.Element => {
  return (
    <>
      {icons.map((item, index) => (
        <div className={`tw-flex tw-items-center tw-gap-[9px] `} key={item.label}>
          <svg width={24} height={24} fill="none">
            <circle cx={12} cy={12} r={12} fill="#EEE" />
            <path fill="#000" d={`${iconForKeyword[item.value]}`} style={{ transform: "scale(0.46)" }} />
          </svg>
          <span className="!tw-font-CircularXXSub !tw-text-[15px] tw-text-grey">{item.label}</span>
        </div>
      ))}
    </>
  );
};

export const IconsRowVertical = ({ icons }: ITemaplateIconsrops): JSX.Element => {
  return (
    <>
      {icons.map((item, index) => (
        <div className={`tw-flex tw-items-center tw-gap-[17px] tw-mb-${index < icons.length - 1 ? "5" : "0"}`} key={item.label}>
          <svg width={40} height={40} fill="none">
            <circle cx={20} cy={20} r={20} fill="#EEE" />
            <path fill="#000" d={`${iconForKeyword[item.value]}`} style={{ transform: "scale(0.76)" }} />
          </svg>
          <span className="!tw-font-CircularXXSub !tw-text-[15px] tw-text-grey">{item.label}</span>
        </div>
      ))}
    </>
  );
};
