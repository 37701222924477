import * as styles from "./GTwoReviews.module.scss";
import cx from "classnames";
import * as React from "react";

type IGTwoReviews = Readonly<{
  rightCol: string[];
  leftCol: string[];
  name: string;
}>;

export default function GTwoReviews({ rightCol, leftCol, name }: IGTwoReviews): React.JSX.Element | null {
  if (rightCol?.length < 1 && leftCol?.length < 1) return null;

  const headingClasseNames = "tw-mb-[5px] tw-font-bold";
  const wrapperClasseNames = "tw-mb-4";

  return (
    <div className={cx(styles.container, "max-sm:tw-flex-col", "mx-md:tw-flex-row")}>
      {leftCol.length < 1 ? null : (
        <div className={cx(styles.column, styles.columnThin)}>
          <div>
            <div className={wrapperClasseNames}>
              <h2 className={headingClasseNames}>{name}</h2>
              <Label />
            </div>

            {leftCol.map(review => (
              <div className={cx(styles.card, styles.cardLight)} key={review}>
                <Review review={review} key={review} />
                <br />
                <span>{name} review</span>
              </div>
            ))}
          </div>
        </div>
      )}
      {rightCol.length < 1 ? null : (
        <div className={cx(styles.column, styles.columnWide)}>
          <div>
            <div className={wrapperClasseNames}>
              <h2 className={headingClasseNames}>Shorthand</h2>
              <Label />
            </div>
            {rightCol.map(review => (
              <div className={cx(styles.card, styles.cardDark)} key={review}>
                <Review review={review} key={review} />
                <br />
                <span>Shorthand review</span>
              </div>
            ))}
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" width="158" height="32" viewBox="0 0 158 32" fill="none">
            <path
              d="M15.8 0.22998L19.3473 11.1475H30.8267L21.5397 17.8949L25.087 28.8125L15.8 22.065L6.51299 28.8125L10.0603 17.8949L0.773307 11.1475H12.2527L15.8 0.22998Z"
              fill="#3FB2A5"
            />
            <path
              d="M47.4001 0.22998L50.9474 11.1475H62.4268L53.1398 17.8949L56.6871 28.8125L47.4001 22.065L38.1131 28.8125L41.6604 17.8949L32.3734 11.1475H43.8528L47.4001 0.22998Z"
              fill="#3FB2A5"
            />
            <path
              d="M79.0002 0.22998L82.5475 11.1475H94.0269L84.7399 17.8949L88.2872 28.8125L79.0002 22.065L69.7132 28.8125L73.2605 17.8949L63.9735 11.1475H75.4529L79.0002 0.22998Z"
              fill="#3FB2A5"
            />
            <path
              d="M110.6 0.22998L114.147 11.1475H125.626L116.339 17.8949L119.887 28.8125L110.6 22.065L101.313 28.8125L104.86 17.8949L95.5731 11.1475H107.052L110.6 0.22998Z"
              fill="#3FB2A5"
            />
            <path
              d="M142.2 0.22998L145.747 11.1475H157.227L147.94 17.8949L151.487 28.8125L142.2 22.065L132.913 28.8125L136.46 17.8949L127.173 11.1475H138.653L142.2 0.22998Z"
              fill="#3FB2A5"
            />
          </svg>
        </div>
      )}
    </div>
  );
}

const Label = (): React.JSX.Element => {
  return <span>Verified reviews on G2.com</span>;
};

const Review = (review: { review: string }): JSX.Element => (
  <div>
    <span className="tw-mr-[1px] tw-text-[20px]">&quot;</span>
    <span className="tw-text-[18px] tw-font-bold">{review.review}</span>
    <span className="tw-ml-[1px] tw-text-[20px]">&quot;</span>
  </div>
);
