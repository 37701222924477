import * as styles from "./FormLink.module.scss";
import * as React from "react";

interface FormLinkProps {
  formLink: { text: string; ctaFunction: () => void };
}

export const FormLink = ({ formLink }: FormLinkProps): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper}>
        <button className={styles.formLink} onClick={formLink.ctaFunction}>
          {formLink.text}
        </button>
      </div>
    </div>
  );
};
