import * as React from "react";

export const BackButton = (): JSX.Element => {
  return (
    <a
      href={"/templates"}
      aria-label="back to templates home page"
      className="back-button tw-h-[32px]  tw-w-[32px] tw-rounded-full tw-bg-tones_darker_grey tw-transition-colors   tw-duration-200 hover:!tw-bg-grey_50"
    >
      <svg width={32} height={32} fill="none" stroke="white" className="tw-transition-colors   tw-duration-200 hover:!tw-stroke-black">
        <path
          className=""
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity={0.7}
          strokeWidth={2}
          d="M21.833 16H10.167m0 0L16 21.833M10.166 16 16 10.167"
        />
      </svg>
    </a>
  );
};
