import * as styles from "./ColorBar.module.scss";
import * as React from "react";

export const colorMap = {
  starter: "#12BF9C",
  team: "#00A7CF",
  business: "#84408B",
  enterprise: "#C69748",
};
interface ColorBarProps {
  /**
   * plan id maps to a color value where we produce a colored gradient bar
   */
  id: string;
}

export const ColorBar = ({ id }: ColorBarProps): JSX.Element => {
  return (
    <div className={styles.colorBar}>
      <svg width={135} height={15} fill="none">
        <rect width={135} height={15} fill={`url(#${id})`} rx={7.5} />
        <defs>
          <linearGradient id={`${id}`} x1={0} x2={174} y1={15} y2={15} gradientUnits="userSpaceOnUse">
            <stop stopColor={colorMap[id]} />
            <stop offset={1} stopColor={"000000"} />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};
