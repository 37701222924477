import { StyledLink } from "../Atoms";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { Check } from "react-feather";
import { twMerge } from "tailwind-merge";

export default function Banner({
  clickableLogo = false,
  title = "",
  description = "",
  cta,
  lists,
  textSize = "large",
}: {
  clickableLogo?: boolean;
  title: string;
  description: string;
  cta?: {
    url: string;
    text: string;
  };
  lists?: string[];
  textSize?: "normal" | "large";
}) {
  return (
    <div className="tw-text-center">
      <div
        className={twMerge(
          textSize === "large" && "tw-container",
          textSize === "normal" && "tw-mx-auto tw-w-[750px] max-md:tw-w-full"
        )}
      >
        {clickableLogo ? (
          <Link to="/">
            <StaticImage
              src="../../../static/media/logos/logo-black.svg"
              alt=""
              aria-hidden="true"
              placeholder="blurred"
              width={198}
              height={150}
              className={twMerge(textSize === "large" && "tw-h-[115px]", textSize === "normal" && "tw-h-[100px]")}
              objectFit="contain"
            />
          </Link>
        ) : (
          <StaticImage
            src="../../../static/media/logos/logo-black.svg"
            alt=""
            aria-hidden="true"
            placeholder="blurred"
            width={198}
            height={150}
            className={twMerge(textSize === "large" && "tw-h-[115px]", textSize === "normal" && "tw-h-[100px]")}
            objectFit="contain"
          />
        )}

        <h1
          className={twMerge(
            "tw-mt-12 tw-font-semibold  tw-text-black max-lg:tw-mt-8",
            textSize === "large" && "tw-title tw-mb-4",
            textSize === "normal" && "tw-h1 tw-mb-2"
          )}
        >
          {title}
        </h1>
        <p
          className={twMerge(
            " !tw-mb-0 tw-font-CircularXXSub !tw-font-normal tw-text-dark_grey",
            textSize === "large" && "tw-subtitle",
            textSize === "normal" && "tw-h4"
          )}
        >
          {description}
        </p>
        {cta && (
          <StyledLink
            className={twMerge(textSize === "large" && "tw-mt-12 max-lg:tw-mt-6", textSize === "normal" && "tw-mt-4")}
            cta={{
              link: cta.url,
              text: cta.text,
            }}
          />
        )}
        {lists && lists.length > 0 && (
          <div className="tw-mt-6 max-lg:tw-mt-4">
            <div className="tw-flex tw-justify-center tw-gap-x-9 max-sm:tw-grid max-sm:tw-grid-cols-1 max-sm:tw-gap-y-1 ">
              {lists.map((list, index) => {
                return (
                  <div className="tw-flex tw-items-center tw-gap-x-2 max-sm:tw-justify-center">
                    <Check className="tw-h-5 tw-w-5 tw-stroke-black" />
                    <span className="tw-flex-1 max-sm:tw-flex-none">{list}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
