import { filterListByCurrentIndustryCode } from "../../Utils";
import { Footer } from "../../components/Footer";
import { Layout } from "../../components/Layout";
import { RelatedCaseStudies } from "../../components/RelatedCaseStudies";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export default function CaseStudy({ data, location }) {
  return (
    <Layout location={location}>
      <main id="main" className="marketing-content container-fluid">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-secondary body-marketing-case-studies hide-elevio",
          }}
        />
        <div className="row section-title">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto  section-inner">
            <h1>
              <span className="shorthand-logo">Shorthand</span>
              <span className="subsection-label">Case Study:</span>
              Bonhams
            </h1>
            <h2>How Bonhams uses Shorthand</h2>
          </div>
          <div aria-hidden="true" className="background-media">
            <img
              src="/media/case-studies/case-studies-bonhams-title.jpg"
              data-object-fit="cover"
              data-autoscale-content-ratio="1.77777777778"
              alt=""
            />
          </div>
        </div>
        <div className="row section">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6  mx-auto marketing-main-column">
            <h3 className="intro">
              After twelve months with Shorthand, Bonhams has increased engagement and organic traffic to their stories, meeting or
              exceeding all of their KPIs.
            </h3>
            <div className="inline-image featured">
              <img
                alt="Asian Art in July, by Bonhams, renders responsively across all devices"
                src="/media/case-studies/case-studies-bonhams.jpg"
              />
            </div>
            <p>
              World-leading auction house Bonhams has no shortage of amazing stories to tell. Bonhams have auctioned everything from
              the paintings of Salvador Dali and the sculptures of Les Lalanne to the Gibson Les Paul guitar owned by Eric Clapton.
            </p>
            <p>
              But before using Shorthand, they had a problem: their existing publishing solution pulled content from their ERP
              (Enterprise Resource Planning software). This was great for producing standardised, on-brand content, but made it
              difficult to create the attention-grabbing content their incredible auction items deserved.
            </p>
            <p>
              Humfrey Brandes is Product Manager at Bonhams. Working with Marlene Verde, Digital Marketing Manager, and the rest of the
              Bonhams team, they landed on Shorthand as an ideal solution.
            </p>
            <p>
              Bonhams started using Shorthand in April 2020, and have gone from strength to strength, publishing over 490 stories —
              including some seriously beautiful and engaging features, such as{" "}
              <a href="https://www.bonhams.com/video/30447/" target="_blank" rel="noopener noreferrer" className="btn-link">
                High-wire act
              </a>
              &nbsp;and&nbsp;
              <a href="https://www.bonhams.com/video/30462/" target="_blank" rel="noopener noreferrer" className="btn-link">
                Asian Art in July
              </a>
              .
            </p>
            <h3 className="subheading">A powerful and flexible solution</h3>
            <figure className="block quote-block offset right">
              <blockquote>
                <p>“Shorthand allows us the flexibility to really tailor the content that we're creating.”</p>
              </blockquote>
              <figcaption className="quote-cite">Humfrey Brandes</figcaption>
            </figure>
            <p>
              As Humfrey explains, Shorthand offered “a lot more functionality than our current system allows for, without requiring
              any additional development resources.”
            </p>
            <p>
              “Shorthand allows us the flexibility to really tailor the content that we're creating, which is really great. This just
              wasn’t possible to do from our existing solution. We can really make the most of our imagery for specific stories, but we
              can also keep the stories consistent using standardized fonts and layout.”
            </p>
            <p>This means that the marketing team can build and publish content themselves, without relying on developers.</p>
            <p>
              Using their custom theme — built by the Shorthand team at Bonhams’ direction — Bonhams were able to ensure all of their
              stories were consistent with their brand. “We can publish stories that are coherent with our brand and style guidelines,
              while still giving the marketing team the freedom they need to do great work.”
            </p>
            <div className="case-study-video-container">
              <video className="case-study-video-inline" muted loop playsInline autoPlay>
                <source src="/media/case-studies/case-studies-bonhams.mp4" type="video/mp4" />
              </video>
            </div>
            <h3 className="subheading">“Much easier than we thought”</h3>
            <figure className="block quote-block offset left">
              <blockquote>
                <p>“It took us by surprise how easy the whole process was.”</p>
              </blockquote>
              <figcaption className="quote-cite">Humfrey Brandes</figcaption>
            </figure>
            <p>
              Prior to getting started with Shorthand, the Bonhams team were preparing for a lengthy onboarding process — as is typical
              with most enterprise software solutions.
            </p>
            <p>
              “It took us by surprise how easy the whole process was. We were prepared to do multiple training sessions. We also
              thought we’d to have to go back and do multiple revisions of our branded theme. There was a lot of stress about how we
              were going to integrate it into our website. And in the end, actually, it all just turned out to be much easier than we
              thought.”
            </p>
            <p>The Bonhams team were especially happy with how easy it was to develop a custom branded theme for their stories.</p>{" "}
            <p>
              “The branded theme was, I think, perfect or close to perfect the first time. The Shorthand team just looked at our
              website and did it. This told us that the Shorthand team must have some kind of design sensibility themselves, which is
              great.”
            </p>
            <div className="photo-grid flex featured">
              <img
                alt="A section from one of Bonham's stories"
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-bonhams-sml-01.jpg"
              />
              <img
                alt="A section from one of Bonham's stories"
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-bonhams-sml-02.jpg"
              />
              <img
                alt="A section from one of Bonham's stories"
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-bonhams-sml-03.jpg"
              />
              <img
                alt="A section from one of Bonham's stories"
                className="col-12 col-sm-6"
                src="/media/case-studies/case-studies-bonhams-sml-04.jpg"
              />
            </div>
            <div className="caption">A selection of sections from Bonhams stories</div>
            <h3 className="subheading">“Training was almost unnecessary.”</h3>
            <p>
              According to Humfrey, other training offered by Shorthand was almost unnecessary, because “the team picked up how to use
              Shorthand immediately. It just worked.”
            </p>
            <p>
              Marlene confirms this experience. “It was surprisingly straightforward. I think it only really took one session for
              everyone to understand how it worked and pick it up really. I had assumed as well that we would need multiple training
              sessions, but that was actually unnecessary.”
            </p>
            <p>This ease-of-use has meant that Marlene and the team can bring additional team members into Shorthand.</p>{" "}
            <p>
              “At most, I've had single half-hour sessions to get a new member of staff on the Shorthand platform. And then, pretty
              much everyone who has been using Shorthand has continued to use it with confidence and without experiencing any issues.”
            </p>
            <h3 className="subheading">“It’s just really, really user-friendly”</h3>
            <p>
              Initially, the Bonhams team focused on editorial content. This included interviews with their world-leading specialists,
              their collecting guides, and magazine articles.
            </p>
            <p>Shortly after signing up, though, the team realised that they could use Shorthand for other types of content.</p>
            <p>
              “After we started using Shorthand, we saw the possibilities,” says Marlene. “That's when we decided to also use it to
              build custom landing pages. And that just comes from the ease of using Shorthand and the functionality that it offers. We
              can easily pull content that lives elsewhere on the website and have it live in one place. It’s just really, really
              user-friendly.”
            </p>
            <p>
              The team is continuing to expand their use of Shorthand. “We've now started discussing with our press team, for example,
              how we can use Shorthand to do weekly roundups of the top news at Bonhams, as that's really the easiest way to get all
              that information in one place and share it on the website and through internal communications.”
            </p>
            <h3 className="subheading">Exceeding expectations</h3>
            <figure className="block quote-block offset right">
              <blockquote>
                <p>“With Shorthand, we have exceeded our targets pretty much across the line, which is great.”</p>
              </blockquote>
              <figcaption className="quote-cite">Humfrey Brandes</figcaption>
            </figure>
            <p>
              When Bonhams started using Shorthand, the team set a range of KPIs related to story performance, using their existing
              website content as a baseline.
            </p>
            <p>
              After a recent review of their KPIs, the team were delighted with the results. As Humfrey explains, “in terms of the KPIs
              that we set for ourselves when we started using the platform, we have met and exceeded those in the first cycle.”
            </p>
            <p>
              Not only did Shorthand improve the on-page performance of their stories — including time-on-page and exit rates — but it
              also led to an increase in organic traffic.
            </p>
            <p>
              As Marlene explains, “we wanted to see if creating more content with Shorthand led to sustained traffic organically over
              time, even after the auction or event we were promoting had ended.
            </p>
            <p>
              “And one thing that's been really positive is that the use of Shorthand has made our content more discoverable. It has
              been bringing new users to the site over time — whether it's a week after an article has been published or three months
              later — which is really good news for us.”
            </p>
            <p>Humfrey agrees. “With Shorthand, we have exceeded our targets pretty much across the line, which is great.”</p>
          </div>
        </div>
        <RelatedCaseStudies studies={filterListByCurrentIndustryCode(data, "bonhams")} />
        <Footer />
      </main>
    </Layout>
  );
}

export const query = graphql`
  query bonhamsQuery {
    allCaseStudiesJson {
      edges {
        node {
          jsonId
          image
          industry
          industryCode
          organisation
          summary
        }
      }
    }
  }
`;
