import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { Waypoint } from "react-waypoint";

export type Video = {
  webM: { publicURL: string };
  mp4: { publicURL: string };
  poster: { src: string };
};

export interface HeroContentProps {
  name: string;
  video: Video;
  videoWidth: number;
  videoHeight: number;
  handlePreview: () => void;
  goToPlayGround: () => void;
  setShowPortal: (value: boolean) => void;
  handleWaypointEnter: () => void;
  handeWayPointLeave: () => void;
}

export const HeroContent = ({
  name,
  video,
  videoWidth,
  videoHeight,
  setShowPortal,
  handlePreview,
  goToPlayGround,
  handleWaypointEnter,
  handeWayPointLeave,
}: HeroContentProps): JSX.Element => {
  const ctaLabels = useStaticQuery(graphql`
    query ctaLabelsQuery {
      allTemplateCtaLabelsJson {
        nodes {
          preview
          mobileCta
          desktopCta
        }
      }
    }
  `);

  const labels = ctaLabels.allTemplateCtaLabelsJson.nodes[0];
  const borderOffset = 1;
  const ctaId = `${name.toLowerCase().replace(/\s+/g, "-")}-cta`;
  return (
    <div
      style={{
        minWidth: `${videoWidth}px`,
      }}
      className={`lightBorder safari-ios-overflow-fix !tw-overflow-hidden !tw-rounded-2xl !tw-shadow-default`}
    >
      <div
        style={{
          height: `${videoHeight}px`,
          position: "relative",
        }}
        className="tw-bg-black"
      >
        <Waypoint scrollableAncestor={null} onEnter={handleWaypointEnter} onLeave={handeWayPointLeave}>
          <video
            id="video-top-ls"
            autoPlay={true}
            loop={true}
            muted={true}
            playsInline={true}
            preload="auto"
            poster={video.poster.src}
            width={videoWidth}
            height={videoHeight}
          >
            <source src={video.webM?.publicURL} type="video/webm" />
            <source src={video.mp4?.publicURL} type="video/mp4" />
            <track label="English" kind="captions" srcLang="en" src="" />
          </video>
        </Waypoint>
        <div
          className="imageOverlay"
          style={{
            height: `${videoHeight + borderOffset}px`,
            width: `${videoWidth}px`,
          }}
        />
      </div>
      <div
        style={{
          minWidth: `${videoWidth}px`,
        }}
        className={`!tw-flex !tw-items-center !tw-justify-between tw-bg-white !tw-p-6`}
      >
        <div className="tw-flex !tw-items-center tw-gap-4 max-md:tw-hidden max-sm:tw-hidden">
          <svg width={30} height={30} fill="none">
            <path
              fill="#AAA"
              d="M14.605 0C6.567 0 .027 6.54.027 14.578s6.54 14.577 14.578 14.577 14.578-6.54 14.578-14.577C29.183 6.54 22.643 0 14.605 0Zm2.289 21.28c-.927.191-2.343.246-3.324.028a5.22 5.22 0 0 1-1.962-.79c-.164-.11-.436-.273-.654-.245-.409.027-.654.599-.845.871H8.937v-4.85c.382-.054.763-.082 1.172-.109.245 2.071 1.58 3.243 3.46 3.624 1.009.218 2.29.164 3.107-.19.817-.355 1.39-1.172 1.28-2.262-.054-.463-.408-.79-.762-.981-.573-.3-1.308-.436-2.044-.545-2.97-.49-6.022-.872-6.13-4.278-.083-2.507 1.689-3.733 4.032-3.842 1.526-.082 2.643.136 3.624.627.3.136.654.436 1.008.409.436-.028.627-.464.9-.736h.926v4.605c-.41.054-.79.109-1.2.163-.244-1.69-1.035-2.779-2.506-3.297-1.444-.517-4.006-.408-4.605.79-.19.41-.218 1.036-.027 1.417.381.709 1.526 1.036 2.452 1.2 1.09.217 2.098.354 3.052.599 1.88.49 3.46 1.39 3.46 3.814.082 2.371-1.28 3.57-3.242 3.979Z"
            />
          </svg>
          <span className="!tw-font-CircularXXSub !tw-text-base tw-text-grey">Free to use with Shorthand</span>
        </div>
        <div className={"!tw-flex tw-gap-4 max-lg:!tw-flex-row max-md:!tw-w-[100%]  max-md:!tw-flex-col"}>
          <button className="btn btn-lg btn-secondary !tw-m-0" onClick={handlePreview}>
            {labels.preview}
          </button>

          <button
            className="btn btn-lg btn-primary !tw-m-0 !tw-hidden   tw-justify-center  max-md:!tw-flex max-sm:!tw-flex"
            onClick={() => setShowPortal(true)}
            id={ctaId}
          >
            {labels.mobileCta}
          </button>

          <button
            className="btn btn-lg btn-primary !tw-m-0 tw-hidden tw-justify-center  max-xxl:!tw-flex   max-md:!tw-hidden"
            onClick={goToPlayGround}
            id={ctaId}
          >
            {labels.mobileCta}
          </button>
        </div>
      </div>
    </div>
  );
};
