import { SLIDER_MAX } from "../../Toolbar";
import * as styles from "./PricingSlider.module.scss";
import * as React from "react";
import ReactSlider from "react-slider";

interface PricingSliderProps {
  sliderValue: number;
  max: number;
  setSliderValue: (value: number) => void;
}

export const PricingSlider = ({ max, sliderValue, setSliderValue }: PricingSliderProps): JSX.Element => {
  return (
    <ReactSlider
      id="pricingSlider"
      aria-labelledby="pricingSlider"
      className={"slider"}
      value={sliderValue}
      orientation="horizontal"
      max={SLIDER_MAX}
      tipProps={{ visible: true }}
      onChange={val => {
        setSliderValue(val);
      }}
      renderThumb={props => (
        <div
          {...props}
          className={styles.handle}
          data-tooltip
          aria-label="pricingSlider"
          aria-description="See how your price changes as you publish more stories."
        ></div>
      )}
    />
  );
};
