import { sentenceCaseText } from "../../Utils";
import { Video } from "./HeroContent";
import { Pill } from "./Pill";
import cx from "classnames";
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image";
import * as React from "react";

export const TemplateDescription = ({
  tags,
  heading,
  text,
  bullets,
  image,
  alt,
  video,
}: {
  image?: IGatsbyImageData;
  alt?: string;
  video?: Video;
  text: string | TrustedHTML;
  heading: string;
  tags?: string[];
  bullets?: string[];
}): JSX.Element => {
  const sentenceCasedHeading = sentenceCaseText(heading);

  const classNames = "lightBorder !tw-shadow-default tw-rounded-2xl tw-overflow-hidden safari-ios-overflow-fix";
  // safari-ios-overflow-fix This is to fix the ios safari bug where a video element is overflowing its parent

  return (
    <div className="tw-mb-16  tw-flex tw-justify-center tw-gap-16 max-lg:tw-flex-col max-lg:!tw-gap-8  min-xxxxl:tw-justify-center">
      <div className={"tw-w-[43%] max-lg:!tw-w-[100%] min-xxxxl:tw-w-[30%]"}>
        <div className={cx(classNames)}>
          {image && alt && <GatsbyImage image={getImage(image)} alt={alt} className="tw-w-[100%]" />}
          {video && (
            <video
              id="video-top-ls"
              className="mx-auto"
              autoPlay={true}
              loop={true}
              muted={true}
              playsInline={true}
              poster={video.poster.src}
              width="100%"
              height="100%"
            >
              <source src={video.webM?.publicURL} type="video/webm" />
              <source src={video.mp4?.publicURL} type="video/mp4" />
              <track label="English" kind="captions" srcLang="en" src="" />
            </video>
          )}
        </div>
      </div>

      <div className="tw-w-[40%] tw-text-start max-lg:!tw-w-[100%]">
        <div className="!tw-pb-[7px] tw-font-CircularXXSub  !tw-text-[24px] !tw-font-semibold tw-text-grey_800">
          {sentenceCasedHeading}
        </div>
        <div
          className="!tw-pb-6 !tw-font-CircularXXSub !tw-text-lg !tw-leading-7 tw-text-black_900"
          dangerouslySetInnerHTML={{ __html: text }}
        />
        {tags && (
          <div className="flex-wrap !tw-flex !tw-gap-4">
            {tags.map(tag => (
              <Pill label={tag} key={tag} />
            ))}
          </div>
        )}
        {bullets && bullets.length > 0 && (
          <ul className="tw-list-inside tw-list-disc ">
            {bullets.map(bullet => (
              <li key={bullet} className="tw-text-[18px]">
                {bullet}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
