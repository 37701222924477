import * as React from "react";

interface DiscountsProps {
  openContact: () => void;
}

export const Discounts = (props: DiscountsProps): JSX.Element => {
  const { openContact } = props;
  const authLinks = {
    discountApplication: (
      <a href="https://shorthandstories.typeform.com/to/Fhq7Ye" className="btn btn-primary btn-sm" style={{ marginTop: "27px" }}>
        Apply for discount
      </a>
    ),
  };

  return (
    <div className="row section plan-additions text-center" style={{ backgroundColor: "#eee" }}>
      <div className="col-12 col-sm-11 mx-auto section-inner">
        <div className="discounts active" id="discounts">
          <h4 className="plans-subhead">Discounts</h4>
          <ul
            className="plans flex discounts"
            style={{ gap: "1rem", flexWrap: "nowrap", justifyContent: "center", margin: "25px auto 10px", maxWidth: "1800px" }}
          >
            <li
              className="plan-wrapper"
              style={{
                color: "#000",
                display: "flex",
                flexDirection: "column",
                height: "100%",
                padding: "20px 0 25px",
                width: "32%",
                WebkitBackdropFilter: "blur(20px)",
                backdropFilter: "blur(20px)",
                backgroundColor: "#fff",
                borderRadius: ".5em",
                boxShadow: "0 5px 20px rgba(0, 0, 0, .1)",
                listStyle: "none",
                marginBottom: "10px",
                minHeight: "100%",
                overflow: "hidden",
              }}
            >
              <div
                className="plan"
                style={{
                  color: "#000",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  padding: "20px 0 25px",
                  width: "100%",
                }}
              >
                <div className="plan-label">
                  <h4 className="h3-secondary" style={{ fontWeight: 600, fontSize: "120% " }}>
                    Charities &amp; NGOs
                  </h4>
                  <span
                    style={{
                      lineHeight: 1,
                      color: "#424242",
                      fontWeight: "400",
                      fontFamily: "CircularXXSub, sans-serif",
                      fontSize: "0.9em",
                    }}
                  >
                    Inspire readers to take action
                  </span>
                </div>
                <div className="">
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                  >
                    Your go-to publishing solution for
                  </span>
                  <ul style={{ marginTop: "5px" }}>
                    <li style={{ fontSize: "14px" }}>Editorial</li>
                    <li style={{ fontSize: "14px" }}>Campaigns</li>
                    <li style={{ fontSize: "14px" }}>Annual reports</li>
                    <li style={{ fontSize: "14px" }}>So much more!</li>
                  </ul>
                  <button className="btn btn-primary btn-sm " style={{ marginTop: "20px" }} onClick={openContact}>
                    Get in touch
                  </button>
                </div>
              </div>
            </li>

            <li
              className="plan-wrapper"
              style={{
                color: "#000",
                display: "flex",
                flexDirection: "column",
                height: "100%",
                padding: "20px 0 25px",
                width: "32%",
                WebkitBackdropFilter: "blur(20px)",
                backdropFilter: "blur(20px)",
                backgroundColor: "#fff",
                borderRadius: ".5em",
                boxShadow: "0 5px 20px rgba(0, 0, 0, .1)",
                listStyle: "none",
                marginBottom: "10px",
                minHeight: "100%",
                overflow: "hidden",
              }}
            >
              <div
                className="plan"
                style={{
                  color: "#000",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  padding: "20px 0 25px",
                  width: "100%",
                }}
              >
                <div className="plan-label">
                  <h4 className="h3-secondary" style={{ fontWeight: 600 }}>
                    Journalism &amp; comms classes
                  </h4>
                  <span
                    style={{
                      lineHeight: 1,
                      color: "#424242",
                      fontWeight: "400",
                      fontFamily: "CircularXXSub, sans-serif",
                      fontSize: "0.9em",
                    }}
                  >
                    Teach tomorrow's storytellers
                  </span>
                </div>
                <div className="plan-features">
                  <span
                    className=""
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                  >
                    Use Shorthand to teach
                  </span>
                  <ul style={{ marginTop: "5px" }}>
                    <li style={{ fontSize: "14px" }}>Visual storytelling techniques</li>
                    <li style={{ fontSize: "14px" }}>Enterprise journalism workflows</li>
                    <li style={{ fontSize: "14px" }}>Digital publishing strategy</li>
                    <li style={{ fontSize: "14px" }}>Collaborative storytelling</li>
                  </ul>
                  {authLinks.discountApplication}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
