import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

export const Testimonials = (): JSX.Element => {
  return (
    <figure
      className="featureQuote !tw-m-0 tw-items-center tw-text-left"
      data-sal="slide-up"
      data-sal-duration="500"
      data-sal-delay="100"
      data-sal-easing="ease-out"
    >
      <StaticImage
        src="../../../static/media/logos/clients/logo-honda.svg"
        alt="honda logo"
        className="featureQuote__logo"
        placeholder="blurred"
        layout="fixed"
        width={90}
      />
      <div className="featureQuote__inner">
        <blockquote>
          Shorthand delivers amazing results for Honda. In our first year, our dwell time was up 85%, and the click-through rate was up
          47%.
        </blockquote>
        <figcaption>Nick Bennett | Honda UK</figcaption>
      </div>
    </figure>
  );
};
